import {createSlice, createAsyncThunk, PayloadAction} from "@reduxjs/toolkit";
import commentService from "./commentService"
import { CommentState } from "../../types/states";
import HandleAxiosError from "../../utils/AxiosErrorHandler";
import {DefaultThunkApiConfig, Status} from "../../types/slice";
import { CommentCategoryModel, CommentModel } from "@asirisos/types";

const CONTEXT = "Comment"

const initialState: CommentState = {
    comment: undefined,
    comments: [],
    status: Status.Unknown,
    message: ''
}

// Add Comments

export const addComment = createAsyncThunk<any, CommentModel, DefaultThunkApiConfig>('comment/add', async (commentData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await commentService.addComment(commentData, account)
        console.log('Comment Slice - Add Comment - response', response)
        if (response.status !== 201)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})


// Get Comments

export const getComments = createAsyncThunk<CommentModel[], void, DefaultThunkApiConfig>('task/getall', async (_, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await commentService.getComments(account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

// Get Category Comments

export const getCategoryComments = createAsyncThunk<any, CommentCategoryModel, DefaultThunkApiConfig>('Comment/getallcategory', async (commentCategory, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await commentService.getCategoryComments(commentCategory, account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})


//////////////////////////     # 1  //////////////////////////////////////
// Get Connection ID Comments - with post data request 

// export const getConnectionIdComments = createAsyncThunk<any, CommentConnectionIdModel, DefaultThunkApiConfig>('Comment/getAllConnectionId', async (commentConnectionId, thunkAPI) => {
//     try {
//         const account = thunkAPI.getState().account.account;
//         if (!account)
//             return thunkAPI.rejectWithValue();

//         const response = await commentService.getConnectionIdComments(commentConnectionId, account)
//         if (response.status !== 200)
//             return thunkAPI.rejectWithValue();

//         return response.data
//     } catch (error) {
//         HandleAxiosError(error, CONTEXT);
//         return thunkAPI.rejectWithValue()
//     }
// })


//////////////////////////     # 2  //////////////////////////////////////
// Get Connection ID Comments with ID

export const getConnectionIdComments = createAsyncThunk<any, string, DefaultThunkApiConfig>('Comment/getAllConnectionId', async (commentConnectionId: string, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await commentService.getConnectionIdComments(commentConnectionId)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

//////////////////////////     # 2  //////////////////////////////////////


//////////////////////////     # 3  //////////////////////////////////////
///////////////////// Latest - Copy From LiveStock Get Task By Connection Item ID //////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////


// export const getConnectionComments = createAsyncThunk<CommentModel, string, DefaultThunkApiConfig>('ConnectionComment/getConnectionComment/:connectionCommentId', async (connectionCommentId: string, thunkAPI) => {
//     try {
//         const response = await commentService.getConnectionComment(connectionCommentId)
//         if (response.status !== 200)
//             return thunkAPI.rejectWithValue()

//         return response.data
//     } catch (error) {
//         HandleAxiosError(error, CONTEXT);
//         return thunkAPI.rejectWithValue()
//     }
// })

//////////////////////////     # 3  //////////////////////////////////////


// Get Task

export const getComment = createAsyncThunk<CommentModel, string, DefaultThunkApiConfig>('comment/getone/:commentId', async (commentId, thunkAPI) => {
    try {
        const response = await commentService.getComment(commentId)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

// Update Task

export const updateComment = createAsyncThunk<CommentModel, CommentModel, DefaultThunkApiConfig>('comment/update/:commentId', async (commentData, thunkAPI) => {
    try {
        const response = await commentService.updateComment(commentData)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

// Delete Comment
export const deleteComment = createAsyncThunk<any, string, DefaultThunkApiConfig>('comment/delete', async (commentId, thunkAPI) => {
    try {
        const response = await commentService.deleteComment(commentId)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const commentSlice = createSlice ({
    name: 'comment',
    initialState,
    reducers: {
        reset: () => initialState
        },
    extraReducers: (builder) => {
        builder
            .addCase(addComment.pending, (state: CommentState) => {
                state.status = Status.Pending
            })
            .addCase(addComment.fulfilled, (state: CommentState, action: PayloadAction<CommentModel, any, any>) => {
                state.status = Status.Success
                state.comment = action.payload
            })
            .addCase(addComment.rejected, (state: CommentState) => {
                state.status = Status.Failure
            })
            .addCase(getComments.pending, (state: CommentState) => {
                state.status = Status.Pending
            })
            .addCase(getComments.fulfilled, (state: CommentState, action: PayloadAction<CommentModel[], any, any>) => {
                state.status = Status.Success
                state.comments = action.payload
            })
            .addCase(getComments.rejected, (state: CommentState) => {
                state.status = Status.Failure
            })


            .addCase(getCategoryComments.pending, (state: CommentState) => {
                state.status = Status.Pending
            })
            .addCase(getCategoryComments.fulfilled, (state: CommentState, action: PayloadAction<CommentModel[], any, any>) => {
                state.status = Status.Success
                state.comments = action.payload
            })
            .addCase(getCategoryComments.rejected, (state: CommentState) => {
                state.status = Status.Failure
            })
            .addCase(getConnectionIdComments.pending, (state: CommentState) => {
                state.status = Status.Pending
            })
            .addCase(getConnectionIdComments.fulfilled, (state: CommentState, action: PayloadAction<CommentModel[], any, any>) => {
                state.status = Status.Success
                state.comments = action.payload
            })
            .addCase(getConnectionIdComments.rejected, (state: CommentState) => {
                state.status = Status.Failure
            })
            .addCase(getComment.pending, (state: CommentState) => {
                state.status = Status.Pending
            })
            .addCase(getComment.fulfilled, (state: CommentState, action: PayloadAction<CommentModel, any, any>) => {
                state.status = Status.Success
                state.comment = action.payload
            })
            .addCase(getComment.rejected, (state: CommentState) => {
                state.status = Status.Failure
            })
            .addCase(updateComment.pending, (state: CommentState) => {
                state.status = Status.Pending
            })
            .addCase(updateComment.fulfilled, (state: CommentState, action: PayloadAction<CommentModel, any, any>) => {
                state.status = Status.Success
                state.comments.map((comment: CommentModel) => comment._id === action.payload._id ?
                    (state.comment = comment) : comment)
                state.comment = action.payload
            })
            .addCase(updateComment.rejected, (state: CommentState) => {
                state.status = Status.Failure
            })
            .addCase(deleteComment.pending, (state: CommentState) => {
                state.status = Status.Pending
            })
            .addCase(deleteComment.fulfilled, (state: CommentState) => {
                state.status = Status.Success
                state.comment = undefined
            })
            .addCase(deleteComment.rejected, (state: CommentState) => {
                state.status = Status.Failure
            })
    }
})

export const {reset} = commentSlice.actions
export default commentSlice.reducer