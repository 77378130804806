import { CommentModel } from '@asirisos/types'
import {Link} from 'react-router-dom'
import React from 'react'

function ConnectionsDisplay(props: {comment : CommentModel | undefined}) {
  return (
    <>
    {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}
    {/* ///////////////////////////////////    TASK CONNECTION DISPLAY ////////////////////////////////////////// */}
    {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}
    <div className="bg-white mb-10 mt-10 py-5 rounded-lg #drop-shadow-xl #shadow-lg relative px-9 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]  ">
        <div className='flex flex-col' >
        <h1>COMMENT CONNECTION</h1>

        {/* /////////////////// CONNECTION = CROP ///////////////// */}

            {props.comment?.commentConnectionCategory === 'crop' ? (
            <>
            <Link to={`/crop/${props.comment.cropObject?._id}`} className="border-2 rounded-lg border-blue-500 px-2">
                <h1>Connection Crop</h1>
                <h1>{props.comment.cropObject?.cropName}</h1>
                <h1>{props.comment.cropObject?.developmentStage}</h1>
                <h1>{props.comment.cropObject?.stageProgress}</h1>
                <h1>{props.comment.cropObject?.waterNutrition}</h1>
                <h1>{props.comment.cropObject?.nitrogenNutrition}</h1>
                <h1>{props.comment.cropObject?.phosphorusNutrition}</h1>
                <h1>{props.comment.cropObject?.potassiumNutrition}</h1>
            </Link>
            </>) : (<></>) }

        {/* /////////////////// CONNECTION = LIVE STOCK ///////////////// */}

            {props.comment?.commentConnectionCategory === 'live stock' ? (
            <>
            <Link to={`/liveStock/${props.comment.liveStockObject?._id}`} className="border-2 rounded-lg border-blue-500 px-2">
                <h1>Connection Live Stock</h1>
                <h1>{props.comment.liveStockObject?.name}</h1>
                <h1>{props.comment.liveStockObject?.category}</h1>
                <h1>{props.comment.liveStockObject?._id}</h1>
                <h1>{props.comment.liveStockObject?.sex}</h1>
                <h1>{props.comment.liveStockObject?.weight}</h1>
                <h1>{props.comment.liveStockObject?.description}</h1>
            </Link>
            </>) : (<></>) }

        {/* /////////////////// CONNECTION = EMPLOYEE ///////////////// */}

            {props.comment?.commentConnectionCategory === 'employee' ? (
            <>
            <Link to={`/account/${props.comment.employeeObject?._id}`} className="border-2 rounded-lg border-blue-500 px-2">
                <h1>Connection Employee</h1>
                <h1>{props.comment.employeeObject?.name}</h1>
                <h1>{props.comment.employeeObject?.job_title}</h1>
                <h1>{props.comment.employeeObject?.qualifications}</h1>
                <h1>{props.comment.employeeObject?.email}</h1>
                <h1>{props.comment.employeeObject?.phone}</h1>
            </Link>
            </>) : (<></>) }

        {/* /////////////////// CONNECTION = EQUIPMENT ///////////////// */}

        {props.comment?.commentConnectionCategory === 'equipment' ? (
            <>
            <Link to={`/equipment/${props.comment.equipmentObject?._id}`} className="border-2 rounded-lg border-blue-500 px-2">
                <h1>Connection Equipment</h1>
                <h1>{props.comment.equipmentObject?.name}</h1>
                <h1>{props.comment.equipmentObject?.type}</h1>
                <h1>{props.comment.equipmentObject?.mileage}</h1>
            </Link>
            </>) : (<></>) }

        {/* /////////////////// CONNECTION = INVENTORY ///////////////// */}

        {props.comment?.commentConnectionCategory === 'inventory' ? (
            <>
            <Link to={`/inventory/${props.comment.inventoryObject?._id}`} className="border-2 rounded-lg border-blue-500 px-2">
                <h1>Connection Inventory</h1>
                <h1>{props.comment.inventoryObject?.name}</h1>
                <h1>{props.comment.inventoryObject?.type}</h1>
                <h1>{props.comment.inventoryObject?.quantity}</h1>
            </Link>
            </>) : (<></>) }
        
        {/* /////////////////// CONNECTION = STORAGE ///////////////// */}

        {props.comment?.commentConnectionCategory === 'storage' ? (
            <>
            <Link to={`/storage/${props.comment.storageObject?._id}`} className="border-2 rounded-lg border-blue-500 px-2">
                <h1>Connection Storage</h1>
                <h1>{props.comment.storageObject?.name}</h1>
                <h1>{props.comment.storageObject?.category}</h1>
                <h1>{props.comment.storageObject?.description}</h1>
                <h1>{props.comment.storageObject?.quantity}</h1>
            </Link>
            </>) : (<></>) }

        {/* /////////////////// CONNECTION = CUSTOMER ///////////////// */}

        {props.comment?.commentConnectionCategory === 'customer' ? (
            <>
            <Link to={`/customer/${props.comment.customerObject?._id}`} className="border-2 rounded-lg border-blue-500 px-2">
                <h1>Connection Customer</h1>
                <h1>{props.comment.customerObject?.firstName}</h1>
                <h1>{props.comment.customerObject?.lastName}</h1>
                <h1>{props.comment.customerObject?.email}</h1>
            </Link>
            </>) : (<></>) }
        </div>

    </div>
    </>
  )
}

export default ConnectionsDisplay