import React, {ChangeEvent} from 'react'
import { Link } from 'react-router-dom'
import SearchBox from '../../../Map/SearchBox-1'
import {useTabPersistence} from "../../../../../hooks/useTabPersistence";
import {useTranslation} from "react-i18next";
import {PageId} from "../../../../../types/pages";
import { useState } from 'react'
import DisplayAllFarmersMarkets from '../../..//Map/DisplayAllFarmersMarkets';
import Button from "../../../../components/shared/Button"
import DataChart from 'UI/pages/TimeSeriesData/DataChart';

function EverythingDb() {

    // const animation = require ('./farm_large.png')
    // const animation2 = require ('./adaptive-icon.png')
    // const vegetables_in_basket = require ('./vegetables_in_basket.jpg')
    // const vegetable_market = require ('./vegetable_market.jpg')

    const {t} = useTranslation(["landing", "translation"])
    const {selection, toggleTab} = useTabPersistence(PageId.Equipment);

    const [formData, setFormData] = useState({
        addressObject: {},
      })

    const {addressObject} = formData

    const [selectedPosition, setSelectPosition] = useState()


    const onSetAddress = (selectedPosition: any) => {
        setFormData((prevState) => ({
            ...prevState,
            addressObject: selectedPosition
        }) )
      }

    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
      }


    const pulseData = {
        _id: "640eb697a36359042449d497",
        dataTopic: "",
        description: "This is Data Topic to test",
        connectedItemId: "63c2df843632becd58658e50",
        timeSeriesData: [
            {data: 68,
            _id: '640f97353ec6b459a5734126',
            createdAt: '2023-03-13T21:35:49.755+00:00' },
            {data: 72,
            _id: '640f97353ec6b459a5734',
            createdAt: '2023-03-15T21:35:49.755+00:00'},
            {data: 76,
            _id: '640f97353ec6b459',
            createdAt: '2023-03-17T21:35:49.755+00:00'},
            {data: 79,
            _id: '640f97353ec6b459',
            createdAt: '2023-03-20T21:35:49.755+00:00'},
            {data: 67,
            _id: '640f97353ec6b459',
            createdAt: '2023-03-23T21:35:49.755+00:00'}
        ]
    }

    const benchData = {
        _id: "640eb697a36359042449d497",
        dataTopic: "",
        description: "This is Data Topic to test",
        connectedItemId: "63c2df843632becd58658e50",
        timeSeriesData: [
            {data: 130,
            _id: '640f97353ec6b459a5734126',
            createdAt: '2023-03-13T21:35:49.755+00:00' },
            {data: 135,
            _id: '640f97353ec6b459a5734',
            createdAt: '2023-03-15T21:35:49.755+00:00'},
            {data: 142,
            _id: '640f97353ec6b459',
            createdAt: '2023-03-17T21:35:49.755+00:00'},
            {data: 139,
            _id: '640f97353ec6b459',
            createdAt: '2023-03-20T21:35:49.755+00:00'},
            {data: 143,
            _id: '640f97353ec6b459',
            createdAt: '2023-03-23T21:35:49.755+00:00'},
            {data: 141,
            _id: '640f97353ec6b459',
            createdAt: '2023-03-23T21:35:49.755+00:00'},
            {data: 144,
            _id: '640f97353ec6b459',
            createdAt: '2023-03-23T21:35:49.755+00:00'},
            {data: 146,
            _id: '640f97353ec6b459',
            createdAt: '2023-03-23T21:35:49.755+00:00'},
            {data: 148,
            _id: '640f97353ec6b459',
            createdAt: '2023-03-23T21:35:49.755+00:00'}
                
        ]
    }


  return (
    <>
    {/********************************************/}
    {/********************************************/}
    {/********************************************/}
    {/********************* MOBILE VERSION ***********************/}
    {/********************************************/}
    {/********************************************/}
    {/********************************************/}

    <div className='lg:hidden sm:visible' >

        <div className='flex flex-col'>

            {/********************************************/}
            {/********************* FIRST PAGE MOBILE - START ***********************/}
            {/********************************************/}

            <div className='w-screen h-screen flex flex-col justify-start align-middle bg-[#fff9e1]'>

                {/********************************************/}
                {/********************* MENU BAR - START ***********************/}
                {/********************************************/}

                <div className='bg-[#16341fec] flex justify-around py-2 drop-shadow-md shadow-[2px_1px_20px_3px_rgba(201,
                    201,201,0.97)] '> 
                    <Link to="/">
                        <div className='flex flex-row justify-center py-2' >
                            <h1 className='text-md font-light text-white' >AsirisOS</h1>                        
                        </div>
                    </Link>
                    <Link className=" text-slate-700 hover:bg-[#FFB100] hover:shadow-lg rounded-sm px-4 py-1 bg-[#FFC500] flex justify-center " to={"/login"} >
                        <h1 className=' text-lg font-normal justify-center align-middle'>Login</h1>
                    </Link>
                </div>

                {/********************************************/}
                {/********************* MENU BAR - END ***********************/}
                {/********************************************/}


                <div className='w-full flex items-center justify-center  '>
                    <div className=' flex  border-t-0 rounded-xl rounded-t-none mb-4 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.67)]'>

                    </div>
                </div>


                {/********************************************/}
                {/********************* FIRST PAGE MOBILE - START ***********************/}
                {/********************************************/}

                <div className='flex flex-col justify-start h-full bg-[#fff9e1] px-2 pt-5'>

                    <div className='flex items-start pb-2 justify-center px-3'>
                        <h1 className=' max-w-xl text-2xl text-start font-semibold text-slate-800'> 
                            Reliable AI engines that provide Speed, Security, Scalability at the Edge
                        </h1>
                    </div>

                    <div className='flex justify-center'>
                        <div className='flex flex-col items-start pb-10'>                    
                            <h1 className='text-center font-semibold text-lg pt-2 text-slate-700'></h1>
                            <div className='flex'>
                                <h1 className='sm:py-1 max-sm:text-lg max-sm:font-normal text-left text-slate-700 px-3 pb-1'>Software for local food producers and consumers to build independent and healthy food systems for all.</h1>
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-col justify-center items-center py-5 pb-14' >
                        {/* <img className='rounded-2xl w-5/6 drop-shadow-2xl' src={vegetables_in_basket} alt="" />        */}
                    </div>

                    <div className=' flex flex-row gap-4 justify-center' >

                        <Link to="/join-the-waitlist">
                            <button className='rounded-md drop-shadow-xl hover:drop-shadow-2xl bg-[#FFC500]  py-3 font-normal text-slate-700 px-4 text-xl hover:bg-[#FFB100] hover:text-slate-700'>
                                Join the waitlist
                            </button>
                        </Link>

                        <Link to="/join-the-team">
                            <button className='rounded-md drop-shadow-xl hover:drop-shadow-2xl bg-[#FFC500]  py-3 font-normal text-slate-700 px-4 text-xl hover:bg-[#FFB100] hover:text-slate-700'>
                                Join the team
                            </button>
                        </Link>

                    </div>

                </div>

            </div>

            {/********************************************/}
            {/********************* FIRST PAGE MOBILE - END ***********************/}
            {/********************************************/}

            {/********************************************/}
            {/********************* SECOND PAGE MOBILE - START ***********************/}
            {/********************************************/}


            <div className='h-screen py-6 flex bg-[#fff9e192] w-full'>

                <div className='flex flex-col justify-center w-full gap-10' >

                    <div className='bg-[#16341fec] text-[#fff9e1] py-4  w-48 rounded-r-xl bg-[#fff9e1] drop-shadow-2xl '>
                        <div className='' >
                            <h1 className='text-2xl font-bold'>6989</h1>
                            <h1 className='text-lg font-light' >Farmers Markets</h1>
                        </div>
                    </div>


                    <div className=' flex flex-row justify-between'>                
                        <div className=' self-center pl-3'>
                            {/* <h1>This is what it is</h1> */}
                        </div>

                        <div className='bg-[#16341fec] text-[#fff9e1] py-4  w-48 rounded-l-xl bg-[#fff9e1] self-end drop-shadow-2xl' >
                            <h1 className='text-2xl font-bold'>1217</h1>
                            <h1 className='text-lg font-light' >Agricultural Co-ops</h1>
                        </div>
                    </div>

                    <div className='bg-[#16341fec] text-[#fff9e1] py-4  w-48 rounded-r-xl bg-[#fff9e1] drop-shadow-2xl '>
                        <div className='' >
                            <h1 className='text-2xl font-bold'>994</h1>
                            <h1 className='text-lg font-light' >CSAs</h1>
                        </div>
                    </div>

                    <div className='bg-[#16341fec] text-[#fff9e1] py-4  w-48 rounded-l-xl bg-[#fff9e1] self-end drop-shadow-2xl '>
                        <div className='' >
                            <h1 className='text-2xl font-bold'>1710</h1>
                            <h1 className='text-lg font-light' >On-Farm Markets</h1>
                        </div>
                    </div>

                    <div className='bg-[#16341fec] text-[#fff9e1] py-4  w-48 rounded-r-xl bg-[#fff9e1] drop-shadow-2xl '>
                        <div className='' >
                            <h1 className='text-2xl font-bold'>230</h1>
                            <h1 className='text-lg font-light' >Food Hubs</h1>
                        </div>
                    </div>

                </div>    

            </div>

            {/********************************************/}
            {/********************* SECOND PAGE MOBILE - END ***********************/}
            {/********************************************/}

        </div>

    </div>

    {/********************************************/}
    {/********************************************/}
    {/********************* MOBILE VERSION END ***********************/}
    {/********************************************/}
    {/********************************************/}




    {/********************************************/}
    {/********************************************/}
    {/********************* DESKTOP VERSION START ***********************/}
    {/********************************************/}
    {/********************************************/}

    <div className='lg:visible invisible max-sm:hidden items-center justify-center ' >

        {/********************************************/}
        {/********************* FIRST PAGE DESKTOP - START ***********************/}
        {/********************************************/}
    <div className='' >

        <div className='w-screen h-full flex flex-col max-sm:justify-start justify-start '>

            <div className=' w-screen h-full max-sm:pb-10 flex flex-row bg-slate-50'>

                    {/* ----------------------- SIDEBAR MENU - START  ----------------------- */}

                    <div className='sticky top-0 border-r-2 h-screen bg-white border-slate-600 flex flex-col justify-between pt-5 text-lg font-light'>

                        <div>
                            <div className=' pb-10'>
                                <h1 className='max-sm:text-2xl max-sm:font-light text-4xl font-semibold text-slate-700 align-middle' >AsirisOS</h1>
                            </div>

                            <Link to='/asiris-v2-demo-family' className='bg-slate-100 drop-shadow-lg py-1 pl-4 pr-16 flex justify-start'  > 
                                    <h1 className=''>Family</h1>
                            </Link>

                            <Link to='/asiris-v2-demo-assets' className='  py-1 pl-4 pr-16 flex justify-start' > 
                                <h1 className=''>Assets</h1>
                            </Link>

                            <Link to='/asiris-v2-demo-community' className='  py-1 pl-4 pr-16 flex justify-start' > 
                                <h1>Community</h1>
                            </Link>
                        </div>

                        <div className='pb-5'>
                            <Link to='/asiris-v2-demo-settings' className='  py-1 pl-4 pr-16 flex justify-start' > 
                                <h1>Settings</h1>
                            </Link>

                            <Link to='/asiris-v2-demo-payments' className='  py-1 pl-4 pr-16 flex justify-start' > 
                                <h1>Payments</h1>
                            </Link>

                            <Link to='/asiris-v2-sign-out' className='  py-1 pl-4 pr-16 flex justify-start' > 
                                <h1>Sign-out</h1>
                            </Link>
                        </div>

                    </div>

                    {/* ----------------------- SIDEBAR MENU - END  ----------------------- */}


                    
                    <div className=' w-full h-full flex flex-col'>

                        <div className='pt-10 pb-3 flex flex-row justify-center '>
                            <div className=' border-0 rounded-lg drop-shadow-lg w-5/12 py-3 bg-white flex flex-row justify-between px-3 '>
                                <div>
                                    <h1 className='text-md font-thin text-gray-600'>What are you looking for?</h1>
                                </div>

                                <div>
                                    <i className="fas fa-search text-gray-500" ></i>                                
                                </div>
                            </div>
                        </div>



                        {/* ----------------------- TOPIC SELECTION - START  ----------------------- */}

                        <div className='h-full flex flex-col items-center justify-start py-5 '>

                            {/* *********************** TAB SELECTIONS *********************** */}

                            <div className='mb-5  border-t-0 w-fit rounded-xl drop-shadow-lg'>

                                <ul className="flex felx-row justify-left cursor-pointer ">
                                    <div onClick={() => toggleTab(1)} className={`${selection === 1 ? 'bg-[#161a7b] text-white  pb-3   w-44 rounded-l-lg' : 'bg-[#c3ceea] text-[#11627]  pb-3   w-44 rounded-l-lg'}`}>
                                        <div className='pt-3' >
                                            <h1 className='text-xl font-bold'>Things</h1>
                                            <h1 className='text-md font-light' ></h1>
                                        </div>
                                    </div>

                                    <div onClick={() => toggleTab(2)} className={`${selection === 2 ? 'bg-[#161a7b] text-white  pb-3  rounded-r-lg   w-44' : 'bg-[#c3ceea] rounded-r-lg text-[#11627] pb-3    w-44 '}`}>
                                        <div className='pt-3' >
                                            <h1 className='text-xl font-bold'>Places</h1>
                                            <h1 className='text-md font-light' ></h1>
                                        </div>
                                    </div>


                                </ul>
                            </div>


                            {/* *********************** TAB SELECTIONS *********************** */}


                            {/* ----------------------- THINGS - START  ----------------------- */}
                            {selection === 1 ? (                                                        
                            <>
                            <div className='px-5 rounded-2xl w-2/3 py-4 ' >

                                {/* ----------------------- ADD NEW THINGS - START  ----------------------- */}                                
                                <Link to="/asiris-v2-demo-add-new-item-everythingDb">
                                    <div className='py-3 hover:bg-[#161a7b] bg-[#c3ceea] hover:text-white text-xl font-normal hover:font-bold'>
                                        <h1 className=''>Add New Item</h1>
                                    </div>
                                </Link>
                                {/* ----------------------- ADD NEW THINGS - END  ----------------------- */}                                                                

                                <div className='bg-white drop-shadow-xl rounded-lg text-sm flex flex-col gap-3 py-2'>

                                    <div className='flex justify-start px-3 font-medium align-middle  items-center '>
                                        <div className='w-1/12 '>
                                            <h1 className='font-semibold text-md'>Image</h1>
                                        </div>

                                        <div className='w-5/12' >
                                            <h1 className='font-semibold text-md'>Name</h1>
                                        </div>

                                        <div className='flex gap-3 w-6/12'>
                                            <h1 className='font-semibold text-md'>Location</h1>                                            
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className='flex justify-between px-3 font-medium align-middle  items-center '>
                                        <div className='w-1/12'>
                                            <img src="https://m.media-amazon.com/images/I/51I0DpppkJL._AC_UF894,1000_QL80_.jpg" className='' alt="" />
                                        </div>

                                        <div className='w-5/12 ' >
                                            <h1 className='font-light'>USB C to HDM Adapter</h1>
                                        </div>

                                        <div className='flex gap-3 w-6/12'>
                                            <h1 className='font-light'>Blue bin on the second shelf of the bedroom closet</h1>                                            
                                        </div>
                                    </div>
                                    <hr/>


                                    <div className='flex justify-between px-3 font-medium align-middle  items-center '>
                                        <div className='w-1/12'>
                                            <img src="https://m.media-amazon.com/images/I/51I0DpppkJL._AC_UF894,1000_QL80_.jpg" className='' alt="" />
                                        </div>

                                        <div className='w-5/12 ' >
                                            <h1 className='font-light'>USB C to HDM Adapter</h1>
                                        </div>

                                        <div className='flex gap-3 w-6/12'>
                                            <h1 className='font-light'>Blue bin on the second shelf of the bedroom closet</h1>                                            
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className='flex justify-between px-3 font-medium align-middle  items-center '>
                                        <div className='w-1/12'>
                                            <img src="https://m.media-amazon.com/images/I/51I0DpppkJL._AC_UF894,1000_QL80_.jpg" className='' alt="" />
                                        </div>

                                        <div className='w-5/12 ' >
                                            <h1 className='font-light'>USB C to HDM Adapter</h1>
                                        </div>

                                        <div className='flex gap-3 w-6/12'>
                                            <h1 className='font-light'>Blue bin on the second shelf of the bedroom closet</h1>                                            
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className='flex justify-between px-3 font-medium align-middle  items-center '>
                                        <div className='w-1/12'>
                                            <img src="https://m.media-amazon.com/images/I/51I0DpppkJL._AC_UF894,1000_QL80_.jpg" className='' alt="" />
                                        </div>

                                        <div className='w-5/12 ' >
                                            <h1 className='font-light'>USB C to HDM Adapter</h1>
                                        </div>

                                        <div className='flex gap-3 w-6/12'>
                                            <h1 className='font-light'>Blue bin on the second shelf of the bedroom closet</h1>                                            
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className='flex justify-between px-3 font-medium align-middle  items-center '>
                                        <div className='w-1/12'>
                                            <img src="https://m.media-amazon.com/images/I/51I0DpppkJL._AC_UF894,1000_QL80_.jpg" className='' alt="" />
                                        </div>

                                        <div className='w-5/12 ' >
                                            <h1 className='font-light'>USB C to HDM Adapter</h1>
                                        </div>

                                        <div className='flex gap-3 w-6/12'>
                                            <h1 className='font-light'>Blue bin on the second shelf of the bedroom closet</h1>                                            
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className='flex justify-between px-3 font-medium align-middle  items-center '>
                                        <div className='w-1/12'>
                                            <img src="https://m.media-amazon.com/images/I/51I0DpppkJL._AC_UF894,1000_QL80_.jpg" className='' alt="" />
                                        </div>

                                        <div className='w-5/12 ' >
                                            <h1 className='font-light'>USB C to HDM Adapter</h1>
                                        </div>

                                        <div className='flex gap-3 w-6/12'>
                                            <h1 className='font-light'>Blue bin on the second shelf of the bedroom closet</h1>                                            
                                        </div>
                                    </div>                                                                                                                                                                              
                                </div>                            
                            </div>
                            </>
                            ) : (<></>)}                        
                            {/* ----------------------- THINGS - START  ----------------------- */}


                            {/* ----------------------- PLACES - START  ----------------------- */}
                            {selection === 2 ? (
                            <>
                            <div className='px-5 rounded-2xl w-2/3 py-4 ' >

                                {/* ----------------------- ADD NEW PLACE - START  ----------------------- */}                                
                                <Link to="/asiris-v2-demo-add-new-place-everythingDb">
                                    <div className='py-3 hover:bg-[#161a7b] bg-[#c3ceea] hover:text-white text-xl font-normal hover:font-bold'>
                                        <h1 className=''>Add New Place</h1>
                                    </div>
                                </Link>
                                {/* ----------------------- ADD NEW PLACE - END  ----------------------- */}    


                                <div className='bg-white drop-shadow-xl rounded-lg text-sm flex flex-col gap-3 py-2'>

                                    <div className='flex justify-start px-3 font-medium align-middle  items-center '>
                                        <div className='w-1/12 '>
                                            <h1 className='font-semibold text-md'>Image</h1>
                                        </div>

                                        <div className='w-5/12' >
                                            <h1 className='font-semibold text-md'>Name</h1>
                                        </div>

                                        <div className='flex gap-3 w-6/12'>
                                            <h1 className='font-semibold text-md'>Location</h1>                                            
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className='flex justify-between px-3 font-medium align-middle  items-center '>
                                        <div className='w-1/12'>
                                            <img src="https://m.media-amazon.com/images/I/51I0DpppkJL._AC_UF894,1000_QL80_.jpg" className='' alt="" />
                                        </div>

                                        <div className='w-5/12 ' >
                                            <h1 className='font-light'>USB C to HDM Adapter</h1>
                                        </div>

                                        <div className='flex gap-3 w-6/12'>
                                            <h1 className='font-light'>Blue bin on the second shelf of the bedroom closet</h1>                                            
                                        </div>
                                    </div>
                                    <hr/>


                                    <div className='flex justify-between px-3 font-medium align-middle  items-center '>
                                        <div className='w-1/12'>
                                            <img src="https://m.media-amazon.com/images/I/51I0DpppkJL._AC_UF894,1000_QL80_.jpg" className='' alt="" />
                                        </div>

                                        <div className='w-5/12 ' >
                                            <h1 className='font-light'>USB C to HDM Adapter</h1>
                                        </div>

                                        <div className='flex gap-3 w-6/12'>
                                            <h1 className='font-light'>Blue bin on the second shelf of the bedroom closet</h1>                                            
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className='flex justify-between px-3 font-medium align-middle  items-center '>
                                        <div className='w-1/12'>
                                            <img src="https://m.media-amazon.com/images/I/51I0DpppkJL._AC_UF894,1000_QL80_.jpg" className='' alt="" />
                                        </div>

                                        <div className='w-5/12 ' >
                                            <h1 className='font-light'>USB C to HDM Adapter</h1>
                                        </div>

                                        <div className='flex gap-3 w-6/12'>
                                            <h1 className='font-light'>Blue bin on the second shelf of the bedroom closet</h1>                                            
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className='flex justify-between px-3 font-medium align-middle  items-center '>
                                        <div className='w-1/12'>
                                            <img src="https://m.media-amazon.com/images/I/51I0DpppkJL._AC_UF894,1000_QL80_.jpg" className='' alt="" />
                                        </div>

                                        <div className='w-5/12 ' >
                                            <h1 className='font-light'>USB C to HDM Adapter</h1>
                                        </div>

                                        <div className='flex gap-3 w-6/12'>
                                            <h1 className='font-light'>Blue bin on the second shelf of the bedroom closet</h1>                                            
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className='flex justify-between px-3 font-medium align-middle  items-center '>
                                        <div className='w-1/12'>
                                            <img src="https://m.media-amazon.com/images/I/51I0DpppkJL._AC_UF894,1000_QL80_.jpg" className='' alt="" />
                                        </div>

                                        <div className='w-5/12 ' >
                                            <h1 className='font-light'>USB C to HDM Adapter</h1>
                                        </div>

                                        <div className='flex gap-3 w-6/12'>
                                            <h1 className='font-light'>Blue bin on the second shelf of the bedroom closet</h1>                                            
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className='flex justify-between px-3 font-medium align-middle  items-center '>
                                        <div className='w-1/12'>
                                            <img src="https://m.media-amazon.com/images/I/51I0DpppkJL._AC_UF894,1000_QL80_.jpg" className='' alt="" />
                                        </div>

                                        <div className='w-5/12 ' >
                                            <h1 className='font-light'>USB C to HDM Adapter</h1>
                                        </div>

                                        <div className='flex gap-3 w-6/12'>
                                            <h1 className='font-light'>Blue bin on the second shelf of the bedroom closet</h1>                                            
                                        </div>
                                    </div>                                                                                                                                                                              
                                </div>                            
                            </div>
                            </>
                            ) : (<></>)}
                            {/* ----------------------- PLACES - END  ----------------------- */}



                        </div>

                        {/* ----------------------- TOPIC SELECTION - END  ----------------------- */}


                    </div>
            </div>

        </div>

        {/********************************************/}
        {/********************* FIRST PAGE DESKTOP - END ***********************/}
        {/********************************************/}

    </div>
    </div>
    {/********************************************/}
    {/********************************************/}
    {/********************* DESKTOP VERSION END ***********************/}
    {/********************************************/}
    {/********************************************/}
    </>
  )
}

export default EverythingDb