import React, {useState, useEffect, Fragment} from 'react'
import {useNavigate, Link, useLocation} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {AppDispatch, RootState} from "../../../app/store";
import {useTranslation} from "react-i18next";
import { subscribeMqttTopic } from '../../../features/mqtt/mqttSubscribe'
import {toast} from 'react-toastify'
import Spinner from 'UI/components/Spinner';
import { getAllDevices } from 'features/devices/deviceSlice';

function MqttDashboard() {


    const {devices, status} = useSelector((state: RootState) => state.device)
    const {t} = useTranslation(['crop','translation']);
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(getAllDevices())
    }, [dispatch])

    const [sensorData, setSeonsorData] = useState('')

    const navigate = useNavigate()

    
  return (
    <>
    {/* MOBILE VERSION */}
    <div className='lg:hidden sm:visible' >

        <div className='h-screen flex flex-col'>

            {/********************************************/}
            {/********************* MENU BAR - START ***********************/}
            {/********************************************/}

            {/********************************************/}
            {/********************* MENU BAR - END ***********************/}
            {/********************************************/}

            <div className='flex flex-col'>

                <div className='w-screen flex flex-col justify-start align-middle bg-[#ffffff] pt-5'>

                    <div className=' pb-6 flex flex-col justify-center items-center px-4'>
                        <Link to="/add-device" className=' w-full bg-[#FFC500] py-2 rounded-md' >
                            <button className=''>
                                <h1 className='text-xl'>Add Device</h1>
                            </button>
                        </Link>
                    </div>


                    <div className='flex flex-col gap-3'>

                        {/* (devices) */}
                        {devices?.map((device) => (
                            <>
                                <Link to={`/device/${device?._id}`}>
                                    <div className='flex justify-between bg-slate-200 py-1 drop-shadow-md mx-4 px-4' >
                                        <div className=''>
                                            <h1 className='text-xl text-left font-semibold'>Name</h1>
                                            {/* <h1 className='text-xl text-left font-semibold'>{device?.deviceName}</h1> */}
                                            <h1 className='text-sm font-light'>Device Type</h1>
                                            {/* <h1 className='text-xl text-left font-semibold'>{device?.deviceType}</h1> */}
                                        </div>
        
                                        <div className='self-center'>
                                            <div className='px-3 py-1 rounded-md justify-center'>
                                                <h1 className='text-sm'>Device Description</h1>
                                                {/* <h1 className='text-xl text-left font-semibold'>{device?.deviceDescription}</h1> */}
                                            </div>                            
                                        </div>
                                    </div>
                                </Link>
                            </>
                        ) )}

                        <Link to="/device">
                            <div className='flex justify-between bg-slate-200 py-1 drop-shadow-md mx-4 px-4' >
                                <div className=''>
                                    <h1 className='text-xl text-left font-semibold'>Name</h1>
                                    <h1 className='text-sm font-light'>Device Type</h1>
                                </div>

                                <div className='self-center'>
                                    <div className='px-3 py-1 rounded-md justify-center'>
                                        <h1 className='text-sm'>Device Description</h1>
                                    </div>                            
                                </div>
                            </div>
                        </Link>


                        <div className='flex justify-between bg-slate-200 py-1 drop-shadow-md mx-4 px-4' >
                            <div className=''>
                                <h1 className='text-xl text-left font-semibold'>Name</h1>
                                <h1 className='text-sm font-light'>Device Type</h1>
                            </div>

                            <div className='self-center'>
                                <div className='px-3 py-1 rounded-md justify-center'>
                                    <h1 className='text-sm'>Device Description</h1>
                                </div>                            
                            </div>
                        </div>

                        <div className='flex justify-between bg-slate-200 py-1 drop-shadow-md mx-4 px-4' >
                            <div className=''>
                                <h1 className='text-xl text-left font-semibold'>Name</h1>
                                <h1 className='text-sm font-light'>Device Type</h1>
                            </div>

                            <div className='self-center'>
                                <div className='px-3 py-1 rounded-md justify-center'>
                                    <h1 className='text-sm'>Device Description</h1>
                                </div>                            
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </div>
    </div>


    {/* DESKTOP VERSION */}
    <div className='lg:visible invisible max-sm:hidden items-center justify-center' >

        {/* ----------------------- NAVIGATION BAR  ----------------------- */}

        <div className='flex justify-around py-3 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.67)] bg-[#16341fec]'>    
            <Link to="/">
                <div className='flex justify-center align-middle'>
                    <h1 className='max-sm:text-2xl max-sm:font-light text-4xl font-thin text-white align-middle' >AsirisOS</h1>
                    <h1 className='max-sm:pt-1 max-sm:text-xl max-sm:font-light text-2xl font-thin text-slate-600 pt-1 pl-3' ></h1>
                </div>
            </Link>
            
            <Link className=" text-slate-700 hover:bg-[#FFB100] hover:shadow-lg rounded-sm px-4 py-1 bg-[#FFC500] flex justify-center " to={"/login"} >
                <h1 className=' text-lg font-normal justify-center align-middle'>Login</h1>
            </Link>
        </div>

        {/* ----------------------- NAVIGATION BAR  ----------------------- */}

        <div className='w-screen h-screen flex flex-col max-sm:justify-start justify-start bg-[#ffffff]'>
            <div className='h-screen pb-6 max-sm:pb-10 flex flex-col justify-center items-center px-5'>
                <h1 className='max-sm:py-1 max-sm:text-xl max-sm:font-thin text-center font-bold text-7xl pt-1 underline text-indigo-400 decoration-green-500 animate-bounce'>Congrats!</h1>
                <h2 className='max-sm:py-1 max-sm:text-xl max-sm:font-thin text-center font-light text-3xl pt-8 text-slate-600' >Your form has been successfully submitted</h2>
                {sensorData}
                {/* <button onClick={onAlarmOn} >
                    <h1>Alarm Off</h1>
                </button>
                <button onClick={onAlarmOff} >
                    <h1>Alarm On</h1>
                </button> */}
            </div>
        </div>

    </div>
    </>
  )
}

export default MqttDashboard