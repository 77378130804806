import React, {useState, ChangeEvent, FormEvent} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import {addFarm} from '../../../features/farms/farmSlice'
import {useSelector, useDispatch} from 'react-redux'
import {toast} from 'react-toastify'
import Spinner from '../../components/Spinner'
import {AppDispatch, RootState} from "../../../app/store";
import FormCard from '../../components/shared/FormCard'
import NumberInputInUnits from '../../components/shared/NumberInputInUnits'
import {useTranslation} from "react-i18next";
import {Status} from "../../../types/slice";
import SearchBox from '../Map/SearchBox-1'
import SearchableMap from '../Map/SearchableMap-1'
import {OrganizationModel, FarmModel} from "@asirisos/types";
import CheckBoxButtonSelection from 'UI/components/shared/CheckBoxButtonSelection'
import InlineEditCheckBox from 'UI/components/shared/InlineEditCheckBox'
import LargeTextInput from 'UI/components/shared/LargeTextInput'
import FormTwoInputs from 'UI/components/shared/FormTwoInputs'
import { addOrganization } from 'features/organizations/organizationSlice'

function TransportationForm() {
    const {t} = useTranslation("organization")
    const {status} = useSelector((state: RootState) => state.farm)
    const {user} = useSelector((state:RootState) => state.auth)
    const getUrlPath = useLocation()

    const [selectPosition, setSelectPosition] = useState<any>()


    const [formData, setFormData] = useState({
        organizationName: '',
        location: '',
        description: '',
        offerings: '',
        contactPerson: '',
        contactEmail: '',
        contactPhone: '',
        addressObject: {},
        urlPath: ''
    })

    const {organizationName, location, description, offerings, contactPerson, contactEmail, contactPhone, urlPath,
         addressObject} = formData

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()


    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }


    const onSetAddress = (selectPosition : object) => {
        setFormData((prevState) => ({
            ...prevState,
            addressObject: selectPosition!
        }) )
    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault()

        const organizationData : OrganizationModel = {

            
            organizationName, 
            location, 
            addressObject,
            description, 
            offerings, 
            contactPerson, 
            contactEmail, 
            contactPhone,
            urlPath: getUrlPath.pathname
        }

        dispatch(addOrganization(organizationData))
            .unwrap()
            .then(() => {
                toast.success(t("organization:orgAdded"))
                // navigate('/')
            })
            .catch(() => {
                toast.error(t("organization:orgAddFailed"))
                // navigate('/farm-add-form')
            })
    }

    if (status === Status.Pending) {
        return <Spinner/>
    }

    return (
        <>
            <>
            <div className='pb-4'  >
                <FormCard title={t("organization:addCompany")}>
                    <form onSubmit={onSubmit}>
                        <div className='pb-8 w-1/2 ml-8' >
                            <NumberInputInUnits onChange={(e) => onChange(e)} value={organizationName} id={"organizationName"} type={'text'}
                                                group={"organizationName"} label={"organizationName"} name={"organizationName"} dataTitle={t("organization:companyName")}/>
                        </div>   
                        
                        <h1 className= "pt-3 flex justify-start ml-10 text-md text-slate-500" >Location</h1>      

                        <div style={{ display: "flex", flexDirection: "row",  width: "80vw", height: "30vh" }} >
                        
                            <div className="pl-8 flex flex-col justify-items-start" style={{ width: "50vw" }}>
                                <SearchBox  selectPosition={selectPosition} setSelectPosition={setSelectPosition} onSetAddress={onSetAddress} name={"addressObject"} value={addressObject} onChange={(e : any) => onChange(e) } />
                            </div>
                            <div style={{ width: "50vw", height: "100%" }}>
                                <SearchableMap lat ={selectPosition?.lat} lon={selectPosition?.lon} selectPosition={selectPosition} />
                            </div>

                        </div>

                        <div className='mx-9 pt-10 pb-8' >
                            <LargeTextInput onChange={(e) => onChange(e)} value={description} id={"description"} 
                                // onKeyDown={onKeyDown}
                                type={'text'} group={"description"} label={"description"}
                                name={"description"} dataTitle={t("translation:description")} />

                                <div className='pt-5'></div>
                            <LargeTextInput onChange={(e) => onChange(e)} value={offerings} id={"offerings"} 
                                // onKeyDown={onKeyDown}
                                type={'text'} group={"offerings"} label={"offerings"}
                                name={"offerings"} dataTitle={t("organization:offerings")} />
                        </div>

                        <div className='flex flex-row justify-around pt-3' >
                            <div className='pb-8 w-1/2 ml-9 ' >
                                <NumberInputInUnits onChange={(e) => onChange(e)} value={contactPerson} id={"contactPerson"} type={'text'}
                                                    group={"contactPerson"} label={"contactPerson"} name={"contactPerson"} dataTitle={t("organization:contactPerson")}/>
                            </div>   
                        </div>

                        <div className='flex flex-row justify-around pt-3' >
                            
                            <div className='pb-8 w-1/3 ml-9' >
                                <NumberInputInUnits onChange={(e) => onChange(e)} value={contactEmail} id={"contactEmail"} type={'text'}
                                                    group={"contactEmail"} label={"contactEmail"} name={"contactEmail"} dataTitle={t("organization:contactEmail")}/>
                            </div>   
                                
                            <div className='pb-8 w-1/3 ml-9' >
                                <NumberInputInUnits onChange={(e) => onChange(e)} value={contactPhone} id={"contactPhone"} type={'text'}
                                                    group={"contactPhone"} label={"contactPhone"} name={"contactPhone"} dataTitle={t("organization:contactPhone")}/>
                            </div>
                        
                        </div>

                        <div className='flex justify-center pt-8 p-2'>
                            <button className="btn btn-wide btn-primar hover:bg-black">{t("organization:createAccount")}</button>
                        </div>
                    </form>
                </FormCard>
            </div>
            </>
        </>
    )
}

export default TransportationForm