import React, {ChangeEvent} from 'react'
import { Link } from 'react-router-dom'
import SearchBox from '../Map/SearchBox-1'
import {useTabPersistence} from "../../../hooks/useTabPersistence";
import {useTranslation} from "react-i18next";
import {PageId} from "../../../types/pages";
import { useState } from 'react'
import DisplayAllFarmersMarkets from '../Map/DisplayAllFarmersMarkets';
import Button from "../../components/shared/Button"
import NumberInputInUnits from 'UI/components/shared/NumberInputInUnits';

function CombatLanding() {

    const animation = require ('./farm_large.png')
    const animation2 = require ('./adaptive-icon.png')
    const vegetables_in_basket = require ('./vegetables_in_basket.jpg')
    const vegetable_market = require ('./vegetable_market.jpg')

    const {t} = useTranslation(["landing", "translation"])
    const {selection, toggleTab} = useTabPersistence(PageId.Equipment);

    const [formData, setFormData] = useState({
        addressObject: {},
      })

    const {addressObject} = formData

    const [fighterData, setFighterData] = useState({
        fighterPay: '',
      })
    
    const {fighterPay} = fighterData

    const [selectedPosition, setSelectPosition] = useState()


    const onSetAddress = (selectedPosition: any) => {
        setFormData((prevState) => ({
            ...prevState,
            addressObject: selectedPosition
        }) )
      }

    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
      }


  return (
    <>
    {/********************************************/}
    {/********************************************/}
    {/********************************************/}
    {/********************* MOBILE VERSION ***********************/}
    {/********************************************/}
    {/********************************************/}
    {/********************************************/}

    <div className='lg:hidden sm:visible' >

        <div className='flex flex-col'>

            {/********************************************/}
            {/********************* FIRST PAGE MOBILE - START ***********************/}
            {/********************************************/}

            <div className='w-screen h-screen flex flex-col justify-start align-middle '>

                {/********************************************/}
                {/********************* MENU BAR - START ***********************/}
                {/********************************************/}

                <div className='bg-black flex justify-around py-2 drop-shadow-md shadow-[2px_1px_20px_3px_rgba(201,201,201,0.97)] '> 
                
                    <Link to="/combat-fighter-profile">
                        <div className='flex flex-col justify-center py-2' >
                            <h1 className='text-xl font-semibold text-white font-sofachrome' >Combat Link</h1>   
                        </div>
                    </Link>

                    <Link className=" text-white hover:bg-[#e50f0fa3] hover:shadow-lg rounded-lg px-4 items-center bg-[#e50f0f] flex justify-center " to={""} >
                        <h1 className=' text-lg font-normal justify-center align-middle'>Login</h1>
                    </Link>
                </div>

                {/********************************************/}
                {/********************* MENU BAR - END ***********************/}
                {/********************************************/}


                {/* <div className='w-full flex items-center justify-center  '>
                    <div className=' flex  border-t-0 rounded-xl rounded-t-none mb-4 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.67)]'>

                    </div>
                </div> */}


                {/********************************************/}
                {/********************* FIRST PAGE MOBILE - START ***********************/}
                {/********************************************/}

                <div className='flex flex-col justify-start h-full bg-[#f1f1f1] px-2 pt-10'>


                    <div className='flex items-start pb-9 justify-start px-3'>
                        <h1 className=' max-w-xl text-xl font-sofachrome text-[#e50f0f]'> 
                            Find your Fight
                        </h1>
                    </div>

                    <div className='flex items-start pb-2 justify-center px-3'>
                        <h1 className=' max-w-xl text-2xl text-start font-light  text-slate-700'> 
                            We bring together fighters and promoters to make organizing combat events easy
                        </h1>
                    </div>

                    <div className='flex justify-center'>
                        <div className='flex flex-col items-start pb-5'>                    
                            <h1 className='text-center font-semibold text-lg pt-2 text-slate-700'></h1>
                            <div className='flex'>
                                {/* <h1 className='sm:py-1 max-sm:text-lg max-sm:font-normal text-left text-slate-700 px-3 pb-1'>Organize combat events, negotiate athlete contracts, and find sponsors</h1> */}
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-col justify-center items-center py-2 pb-14 px-3' >
                        <img className='' src="https://static.photocrowd.com/upl/kH/cms.ri08QsQFymLBw_7VmvxQ-collection_cover.jpeg" alt="" />
                    </div>

                    <div className=' flex flex-row gap-4 justify-center' >

                        <Link to="">
                            <button className='rounded-md drop-shadow-xl hover:drop-shadow-2xl bg-[#ca1010]  py-3 font-normal text-white px-4 text-xl hover:bg-black hover:text-white'>
                                Join the team
                            </button>
                        </Link>

                    </div>

                </div>

            </div>

            {/********************************************/}
            {/********************* FIRST PAGE MOBILE - END ***********************/}
            {/********************************************/}

            {/********************************************/}
            {/********************* SECOND PAGE MOBILE - START ***********************/}
            {/********************************************/}


            <div className='h-full py-2 flex flex-col bg-white w-full'>

                <div className='pb-4'>
                    <h1 className='text-xl font-sofachrome text-[#ca1010]'>The Process</h1>
                </div>


                <div className='flex flex-col justify-center w-full gap-4' >


                    {/* PROMOTER */}

                    <div className=' py-4' >

                        <div className='bg-black text-white py-2 mb-4 w-72 rounded-r-xl drop-shadow-2xl'>
                            <div className='' >
                                <h1 className='text-2xl font-bold'>1. Event</h1>
                                <h1 className='text-lg font-light' >Promoter creates an event</h1>
                            </div>
                        </div>

                        

                        <div className=' text-slate-700 py-5 bg-white drop-shadow-2x '>
                            <div className='flex flex-col x-1 bg-white rounded-xl mx-2 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.97)] '>
                                <div className='flex'>
                                    <div className='pr-2' >
                                        <img className=' rounded-tl-xl w-24' src="https://www.t-mobilearena.com/assets/img/tma-default-thumb-dcd0b81e41.jpg" alt="" />
                                    </div>

                                    <div className='px-2 flex flex-col justify-start'>
                                        <h1 className='text-2xl font-semibold self-start'>UFC 234</h1>
                                        <h1 className='text-md font-light self-start'>T-Mobile Arena</h1>
                                        <h1 className='text-xs font-light self-start'>February 9 2024</h1>
                                        <h1 className='text-xs font-light self-start'>Zuffa LLC </h1>
                                    </div>

                                </div>
                                <div className='border-2'></div>

                                <div className='py-4 flex justify-around'>
                                    <Link to="">
                                        <button className='w-28 rounded-md drop-shadow-2xl hover:drop-shadow-2xl bg-[#ca1010] py-1 font-normal text-white px-1 text-lg hover:bg-[#FFB100] hover:text-white'>
                                            Fight
                                        </button>
                                    </Link>

                                    <Link to="">
                                        <button className='w-28 rounded-md drop-shadow-2xl hover:drop-shadow-2xl bg-[#ca1010] py-1 font-normal text-white px-1 text-lg hover:bg-[#FFB100] hover:text-white'>
                                            Sponsor
                                        </button>
                                    </Link>
                                </div>

                            </div>

                        </div>

                    </div>

                    {/* PROMOTER END */}


                    {/* ATHLETES */}


                    <div className=' py-4 flex flex-col' >

                        <div className='bg-[#ca1010] text-white py-2 mb-4 w-72 rounded-l-xl self-end drop-shadow-2xl' >
                            <h1 className='text-2xl font-bold'>2. Athletes</h1>
                            <h1 className='text-lg font-light' >Athletes request to participate</h1>
                        </div>

                        <div className=' text-slate-700 py-4  drop-shadow-2xl '>
                            <div className='flex flex-col x-1 bg-white rounded-2xl mx-2 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.97)]'>

                                <div className='flex' >
                                    <div className='pr-2' >
                                        <img className=' rounded-tl-xl w-24' src="https://www.sportico.com/wp-content/uploads/2019/10/ufc-200x200-6.png?w=200" alt="" />
                                    </div>
                                    <div className='px-2 flex flex-col justify-start'>
                                        <h1 className='text-2xl font-semibold self-start'>UFC 234</h1>
                                        <h1 className='text-md font-light self-start'>T-Mobile Arena</h1>
                                        <h1 className='text-xs font-light self-start'>February 9 2024</h1>
                                        <h1 className='text-xs font-light self-start'>Zuffa LLC </h1>
                                    </div>
                                </div>

                                <div className='border-2' ></div>

                                {/* <div className='border-2 border-slate-700' ></div> */}
                                <div className='py-3 flex flex-col  ' >
                                    <h1 className='text-md font-bold self-start px-2'>Weight Class: 145 LB</h1>
                                    <div className='px-2 bg-slate-100 pb-4'>
                                    <NumberInputInUnits onChange={(e) => onChange(e)} value={fighterPay} id={"fighterPay"}
                                                        type={'text'} group={"fighterPay"} label={"fighterPay"} name={"fighterPay"}
                                                        dataTitle="Requested Compensation"/>
                                    </div>
                                </div>

                                <div className='py-3 flex justify-around'>
                                    <Link to="">
                                        <button className='rounded-md w-40 drop-shadow-2xl hover:drop-shadow-2xl bg-[#ca1010] py-1 font-normal text-white px-1 text-lg hover:bg-[#FFB100] hover:text-white'>
                                            Request to Fight
                                        </button>
                                    </Link>
                                </div>
                            </div>

                        </div>
                    
                    </div>

                    {/* ATHLETES */}


                    <div className=' py-4  flex flex-col' >
                        <div className='bg-black text-white py-2 mb-4 w-72 rounded-r-xl drop-shadow-2xl'>
                            <div className='' >
                                <h1 className='text-2xl font-bold'>3. Review</h1>
                                <h1 className='text-lg font-light' >Promoter reviews offers</h1>
                            </div>
                        </div>

                        <div className=' text-slate-700 py-5  drop-shadow-2xl '>
                            <div className='flex flex-col x-1 bg-white rounded-xl mx-2 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.97)]'>
                                <div className='flex'>
                                    <div className='pr-2' >
                                        <img className='rounded-tl-xl w-32' src="https://a.espncdn.com/combiner/i?img=/i/headshots/mma/players/full/3332412.png&w=350&h=254" alt="" />
                                    </div>

                                    <div className='px-2 flex flex-col justify-start'>
                                        <h1 className='text-xl font-semibold self-start'>Islam Makhachev</h1>
                                        <h1 className='text-sm font-light self-start'>Event: UFC 234</h1>
                                        <h1 className='text-sm font-light self-start'>Requested Compensation:</h1>
                                        <h1 className='text-md font-bold self-start'>$237,000</h1>
                                    </div>
                                </div>
                                <div className='border-2' ></div>

                                <div className='py-4 flex justify-around'>
                                    <Link to="">
                                        <button className='w-24 rounded-md drop-shadow-2xl hover:drop-shadow-2xl bg-[#ca1010] py-1 font-normal text-white px-1 text-lg hover:bg-[#FFB100] hover:text-white'>
                                            Accept
                                        </button>
                                    </Link>

                                    <Link to="">
                                        <button className='w-24 rounded-md drop-shadow-2xl hover:drop-shadow-2xl bg-[#ca1010] py-1 font-normal text-white px-1 text-lg hover:bg-[#FFB100] hover:text-white'>
                                            Counter
                                        </button>
                                    </Link>

                                    <Link to="">
                                        <button className='w-24 rounded-md drop-shadow-2xl hover:drop-shadow-2xl bg-[#ca1010] py-1 font-normal text-white px-1 text-lg hover:bg-[#FFB100] hover:text-white'>
                                        Reject
                                        </button>
                                    </Link>
                                </div>
                            </div>


                            <div className='flex flex-col x-1 bg-white rounded-xl mx-2 my-5 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.97)]'>
                                <div className='flex'>
                                    <div className='pr-2' >
                                        <img className='rounded-tl-xl w-32' src="https://a.espncdn.com/i/headshots/mma/players/full/2504169.png" alt="" />
                                    </div>

                                    <div className='px-2 flex flex-col justify-start'>
                                        <h1 className='text-xl font-semibold self-start'>Charles Oliveira</h1>
                                        <h1 className='text-sm font-light self-start'>Event: UFC 234</h1>
                                        <h1 className='text-sm font-light self-start'>Requested Compensation:</h1>
                                        <h1 className='text-md font-bold self-start'>$175,000</h1>
                                    </div>
                                </div>
                                <div className='border-2' ></div>

                                <div className='py-4 flex justify-around'>
                                    <Link to="">
                                        <button className='w-24 rounded-md drop-shadow-2xl hover:drop-shadow-2xl bg-[#ca1010] py-1 font-normal text-white px-1 text-lg hover:bg-[#FFB100] hover:text-white'>
                                            Accept
                                        </button>
                                    </Link>

                                    <Link to="">
                                        <button className='w-24 rounded-md drop-shadow-2xl hover:drop-shadow-2xl bg-[#ca1010] py-1 font-normal text-white px-1 text-lg hover:bg-[#FFB100] hover:text-white'>
                                            Counter
                                        </button>
                                    </Link>

                                    <Link to="">
                                        <button className='w-24 rounded-md drop-shadow-2xl hover:drop-shadow-2xl bg-[#ca1010] py-1 font-normal text-white px-1 text-lg hover:bg-[#FFB100] hover:text-white'>
                                        Reject
                                        </button>
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </div>





                    {/* CONTRACT */}




                    <div className='bg-[#ca1010] text-white py-2 w-72 rounded-l-xl self-end drop-shadow-2xl'>
                        <div className='' >
                            <h1 className='text-2xl font-bold'>4. Contract</h1>
                            <h1 className='text-lg font-light' >Contract negotiations and signing</h1>
                        </div>
                    </div>


                    <div className=' text-slate-700 py-5  drop-shadow-2xl '>

                        <div className='flex flex-col justify-around x-1 bg-white rounded-xl mx-2 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.97)]'>

                            <div className='  text-lg flex self-start pl-5 py-2' >
                                <h1>Event:</h1>
                                <h1 className='pl-2 text-lg font-bold'>UFC 234</h1>
                            </div>

                            <div className='flex flex-row justify-around py-2' >
                                

                                {/* FIGHTER */}

                                <div className='flex flex-col w-5/12 rounded-lg bg-slate-100 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.97)] py-2' >

                                    <div className=' self-center' >
                                        <img className='rounded-tl-xl w-32' src="https://a.espncdn.com/combiner/i?img=/i/headshots/mma/players/full/3332412.png&w=350&h=254" alt="" />
                                        <div className='border-2 w-full'></div>
                                    </div>

                                    <div className='px-2 flex flex-col justify-start'>
                                        <h1 className='text-lg font-normal self-start pt-2 '>Athlete:</h1>
                                        <h1 className='text-sm font-semibold self-start'>Islam Makhachev</h1>
                                    </div>
                                </div>

                                {/* PROMOTION */}

                                <div className='flex flex-col w-5/12 rounded-lg bg-slate-100 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.97)] py-2' >

                                    <div className='py-2 self-center' >
                                        <img className='rounded-lg  w-20' src="https://www.sportico.com/wp-content/uploads/2019/10/ufc-200x200-6.png?w=200" alt="" />
                                        <div className=''></div>
                                    </div>

                                    <div className='px-2 flex flex-col justify-start'>
                                        <h1 className='text-lg font-normal self-start pt-2 '>Promotion:</h1>
                                        <h1 className='text-sm font-semibold self-start'>Zuffa LLC</h1>
                                    </div>

                                </div>

                            </div>


                            <div className='  text-md flex self-start pl-5 py-1' >
                                <h1>Location:</h1>
                                <h1 className='pl-2 text-md font-bold'>T-Mobile Arena</h1>
                            </div>

                            <div className=' flex self-start pl-5 py-1' >
                                <h1 className='text-md'>Final Compensation:</h1>
                                <h1 className='pl-2 text-md font-bold'>$220,000</h1>
                            </div>

                            <div className='py-4 flex justify-around'>
                                <Link to="">
                                    <button className='w-28 rounded-md drop-shadow-2xl hover:drop-shadow-2xl bg-[#ca1010] py-1 font-normal text-white px-1 text-lg hover:bg-[#FFB100] hover:text-white'>
                                        Sign <i className="fas fa-check-square"></i>
                                    </button>
                                </Link>

                                <Link to="">
                                    <button className='w-28 rounded-md drop-shadow-2xl hover:drop-shadow-2xl bg-[#ca1010] py-1 font-normal text-white px-1 text-lg hover:bg-[#FFB100] hover:text-white'>
                                        Sign <i className="fas fa-check-square"></i>
                                    </button>
                                </Link>
                            </div>


                                

                        </div>
                    </div>






                {/* SPONSORS */}


                    <div className='bg-black text-white py-2 w-72 rounded-r-xl drop-shadow-2xl'>
                        <div className='' >
                            <h1 className='text-2xl font-bold'>Sponsors</h1>
                            <h1 className='text-lg font-light' >Sponsors support fighters & events</h1>
                        </div>
                    </div>



                    <div className=' text-slate-700 py-5  drop-shadow-2xl  '>
                        <div className='flex flex-col x-1 bg-white rounded-xl mx-2 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.97)]'>
                            <div className='flex'>
                                <div className='pr-2' >
                                    <img className='rounded-tl-xl w-32' src="https://a.espncdn.com/combiner/i?img=/i/headshots/mma/players/full/3332412.png&w=350&h=254" alt="" />
                                </div>

                                <div className='px-2 flex flex-col justify-start'>
                                    <h1 className='text-xl font-semibold self-start'>Islam Makhachev</h1>
                                    <h1 className='text-sm font-light self-start'>Standing: #1</h1>
                                </div>
                            </div>
                            <div className='border-2' ></div>

                            <div className='py-4 flex flex-col justify-around'>

                                <div className='flex flex-col self-start pl-2 w-full'>
                                    <h1 className='self-start font-bold text-md' >Sponsorship Type:</h1>

                                    <div className='flex justify-around py-2'>

                                        <div className='flex'>
                                            <h1>Starter</h1>
                                            <i className="fas fa-square pl-4 text-xl"></i>
                                        </div>

                                        <div className='flex'>
                                            <h1>Mid-tier</h1>
                                            <i className="fas fa-check-square text-xl pl-4"></i>
                                        </div>

                                        <div className='flex'>
                                            <h1>Top-tier</h1>
                                            <i className="fas fa-square text-xl pl-4"></i>
                                        </div>
                                    </div>
                                </div>


                                <div className='flex flex-col self-start pl-2 w-full'>
                                    <h1 className='self-start font-bold text-md' >Description:</h1>

                                    <div className='flex flex-col py-2 self-start pl-2' >
                                        <h1 className='text-sm self-start' >- 2 brand event appearances.</h1>
                                        <h1 className='text-sm' >- 1 medium brand space on team merch.</h1>
                                        <h1 className='text-sm self-start' >- 1 Video add presence.</h1>
                                    </div>
                                </div>

                                <div className='flex flex-col self-start pl-2 w-full'>
                                    <h1 className='self-start font-bold text-md' >Offer:</h1>

                                    <div className='flex flex-col py-2 self-start pl-2' >
                                        <h1 className='text-md self-start' >$23,000</h1>
                                    </div>
                                </div>


                                <Link to="">
                                    <button className='w-32 rounded-md drop-shadow-2xl hover:drop-shadow-2xl bg-[#ca1010] py-1 font-normal text-white px-1 text-lg hover:bg-[#FFB100] hover:text-white'>
                                        Send Request
                                    </button>
                                </Link>
                            </div>
                        </div>                       

                    </div>















                </div>    

            </div>

            {/********************************************/}
            {/********************* SECOND PAGE MOBILE - END ***********************/}
            {/********************************************/}

        </div>

    </div>

    {/********************************************/}
    {/********************************************/}
    {/********************* MOBILE VERSION END ***********************/}
    {/********************************************/}
    {/********************************************/}







    {/********************************************/}
    {/********************************************/}
    {/********************* DESKTOP VERSION START ***********************/}
    {/********************************************/}
    {/********************************************/}

    <div className='lg:visible invisible max-sm:hidden items-center justify-center' >

        {/********************************************/}
        {/********************* FIRST PAGE DESKTOP - START ***********************/}
        {/********************************************/}

        <div className='w-screen h-screen flex flex-col max-sm:justify-start justify-center bg-[#fefefe]'>

            <h1>Desktop Version Coming Soon ...</h1>

        
        </div>

        {/********************************************/}
        {/********************* SECOND PAGE DESKTOP - END ***********************/}
        {/********************************************/}

    </div>
    {/********************************************/}
    {/********************************************/}
    {/********************* DESKTOP VERSION END ***********************/}
    {/********************************************/}
    {/********************************************/}
    </>
  )
}

export default CombatLanding