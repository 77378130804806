import React, {useState, useEffect, ChangeEvent} from 'react'
import NumberInputInUnits from 'UI/components/shared/NumberInputInUnits'
import LegacyDropDownInput from 'UI/components/shared/LegacyDropDownInput';
import {InputSelection} from "../../../types/ui";
import {useTabPersistence} from "../../../hooks/useTabPersistence";
import {PageId} from "../../../types/pages";
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import {AppDispatch, RootState} from "../../../app/store";
import { addJoinOrgWaitlist, addJoinShopperWaitlist } from 'features/waitlist/waitlistSlice';
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {useTranslation} from "react-i18next";
import { addDevice } from 'features/devices/deviceSlice';

function AddDevice() {

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const {t} = useTranslation('crop');


    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setDeviceData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
      }))
    }

    const [deviceData, setDeviceData] = useState({
        // deviceId: '',
        deviceType: '',
        deviceDataDescription: '',
        deviceMAC: '',
        deviceName: '',
        deviceDescription: ''
      })

      const {
        // deviceId,
         deviceType, deviceDataDescription, deviceMAC, deviceName, deviceDescription} = deviceData


      const categorySelection = [new InputSelection('gateway', 'gateway'), new InputSelection('sensor', 'sensor'), new InputSelection('controller', 'controller')]


    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        if ( deviceType === '' || deviceDataDescription === '' || deviceMAC === '' )  {
          toast.error('please complete all fields')
        } else {

          const deviceData = {
            // deviceId,
            deviceType,
            deviceDataDescription,
            deviceMAC,
            deviceName,
            deviceDescription
          }
          dispatch(addDevice(deviceData))
          .unwrap()
          .then(() => {
              toast.success(t("success"));
              navigate("/mqtt-dashboard")
          })
          .catch(() => {
            toast.error(t("fail"))
        })
        }
    }
      

  return (
    <>
    {/********************* MOBILE VERSION - START ***********************/}
    {/********************************************/}
    {/********************************************/}

    <div className='lg:hidden sm:visible '>

      <div className='flex flex-col '>

        {/********************************************/}
        {/********************* FIRST PAGE MOBILE - START ***********************/}
        {/********************************************/}

        <div className='w-screen h-screen flex flex-col justify-middle align-middle'>

          {/********************* MENU BAR - START ***********************/}
          {/********************************************/}
          {/********************* MENU BAR - END ***********************/}
          {/********************************************/}


          <div className='flex flex-col justify-center items-center h-full bg-slate-50' >

              <div className='flex flex-col justify-evenly bg-slate-50 pb-10'>

                <div className='pb-5'>
                  <h1 className='text-3xl font-thin pb-2' >Add Your Device</h1>
                </div>

                <form onSubmit={onSubmit}>

                  <div className='flex justify-center ' >

                    <div className='max-w-xl px-5'>
                        <div className='' ><NumberInputInUnits onChange={(e) => onChange(e)} value={deviceName} id={"deviceName"} type={'text'} group={"deviceName"} label={"deviceName"} name={"deviceName"} dataTitle="Device Name" /></div>
                        <div className='pt-2' ><NumberInputInUnits onChange={(e) => onChange(e)} value={deviceDescription} id={"deviceDescription"} type={'text'} group={"deviceDescription"} label={"deviceDescription"} name={"deviceDescription"} dataTitle="Description" /></div>
                        <div className='pt-2' ><LegacyDropDownInput onChange={(e) => onChange(e)} selections={categorySelection} value={deviceType} id={"deviceType"} type={'text'} group={"deviceType"} label={"deviceType"} name={"deviceType"} dataTitle="Device Type" /></div>
                        <div className='pt-2' ><NumberInputInUnits onChange={(e) => onChange(e)} value={deviceDataDescription} id={"deviceDataDescription"} type={'text'} group={"deviceDataDescription"} label={"deviceDataDescription"} name={"deviceDataDescription"} dataTitle="Data Category" /></div>
                        <div className='pt-2' ><NumberInputInUnits onChange={(e) => onChange(e)} value={deviceMAC} id={"deviceMAC"} type={'text'} group={"deviceMAC"} label={"deviceMAC"} name={"deviceMAC"} dataTitle="Device MAC" /></div>
                    </div>

                  </div>

                  <div className='flex justify-center pt-8 p-2' >
                    <button className="btn btn-wide btn-primar hover:bg-black">Add</button>
                  </div>

                </form>
              </div>

          </div>
        </div>

        {/********************************************/}
        {/********************* FIRST PAGE MOBILE - END ***********************/}
        {/********************************************/}

      </div>
    </div>

    {/********************* MOBILE VERSION - END ***********************/}
    {/********************************************/}
    {/********************************************/}



    {/*********************** DESKTOP VERSION - START *************************/}
    {/********************************************/}
    {/********************************************/}

    <div className='lg:visible invisible max-sm:hidden w-screen h-screen flex flex-col max-sm:justify-start justify-start bg-slate-50'>

    </div>
    {/*********************** DESKTOP VERSION - START *************************/}
    {/********************************************/}
    {/********************************************/}
    </>
  )
}

export default AddDevice