import axios from "axios";
import {AccountModel, DataTopicModel, TimeSeriesDataModel} from "@asirisos/types";
import RequestBodyFactory from "../../factories/requestBodyFactory";

const API_URL = '/api/data'

// Add Data Topic

const addTimeSeriesData= async (timeSeriesData: DataTopicModel, account: AccountModel) => {
    const body = new RequestBodyFactory(timeSeriesData).WithAccount(account).Build();
    return await axios.post(API_URL + '/add', body)
}

// Add Data Point

const addTimeSeriesDataPoint = async (dataPoint: TimeSeriesDataModel, account: AccountModel) => {
    const body = new RequestBodyFactory(dataPoint).WithAccount(account).Build();
    return await axios.post(API_URL + '/adddatapoint', body)
}

// Get Data

const getAllTimeSeriesData= async (account: AccountModel) => {
    return await axios.get(API_URL + '/all/' + account.farm._id)
}

// Get Data by Item ID

const getTimeSeriesDataByItemId= async (dataTopicId: string, account: AccountModel) => {
    return await axios.get(API_URL + '/allbyitem/' + account.farm._id + '/' + dataTopicId)
}

// Get Single Data

const getTimeSeriesData= async (dataTopicId: string) => {
    return await axios.get(API_URL + '/' + dataTopicId)
}

// Update Data

const updateTimeSeriesData= async (data: DataTopicModel, account: AccountModel) => {
    const {_id} = data
    const body = new RequestBodyFactory(data).WithAccount(account).Build();
    return await axios.put(API_URL + '/update/' + _id, body)
}

// Delete Storage

const deleteTimeSeriesData= async (dataTopicId: string[], account: AccountModel) => {
    const ids = dataTopicId
    const config = {
        data: {ids, account}
    }
    console.log('delete data config', config)
    return await axios.delete(API_URL + '/' + dataTopicId, config)
}

const timeSeriesDataService = {
    addTimeSeriesData,
    addTimeSeriesDataPoint,
    getTimeSeriesDataByItemId,
    getAllTimeSeriesData,
    getTimeSeriesData,
    updateTimeSeriesData,
    deleteTimeSeriesData
}

export default timeSeriesDataService