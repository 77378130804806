import React, {ChangeEvent, useRef, FormEvent, useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {getMyFarm} from '../../../features/farms/farmSlice'
import {getAllFarmJoin, updateFarmJoin} from '../../../features/farm-join/farm-joinSlice'
import {toast} from 'react-toastify'
import { Link } from 'react-router-dom'
import Spinner from '../../components/Spinner'
import InlineEdit from '../../components/shared/InlineEdit'
import DisplaySearchableMap from '../Map/DisplaySearchableMap-1'
import DisplayMaps from '../Map/DisplayFarmLocation'
import {AnyAction, ThunkDispatch} from "@reduxjs/toolkit";
import {FarmState} from "../../../types/states";
import {RootState} from "../../../app/store";
import {useTranslation} from "react-i18next";
import {Status} from "../../../types/slice";
import FarmPageLeafletPolygon from '../Map/FarmPageLeafletPolygon'
import {FarmJoinModel} from "@asirisos/types";
import InlineEditCheckBox from 'UI/components/shared/InlineEditCheckBox'


function MyFarmPage() {

    const {t} = useTranslation("farm")
    const {account} = useSelector((state: RootState) => state.account)
    const {farm, status} = useSelector((state: RootState) => state.farm)
    const {farmJoins} = useSelector((state: RootState) => state.farmJoin)

    const dispatch: ThunkDispatch<FarmState, any, AnyAction> = useDispatch()


    ///////////////////////////////////////////
    ///////////////////////////////////////////
    //// DEVICE VIDEO ACCESS //////////////////
    ///////////////////////////////////////////
    ///////////////////////////////////////////


    // const videoRef = useRef({
    //     srcObject: {},
    //     play: ()=> HTMLVideoElement,
    // })
    // const photoRef = useRef(null)

    // const [hasPhoto, setHasPhoto] = useState(false)

    // const getVideo = () => {
    //     navigator.mediaDevices.getUserMedia({video: {width: 1920, height: 1080}})
    //     .then(stream => {
    //         let video = videoRef
    //         video.srcObject = stream
    //         video.play()
    //     })
    //     .catch(err => {
    //         console.error(err)
    //     })
    // }

    // useEffect(() => {
    //     getVideo()
    // }, [videoRef] )

    ///////////////////////////////////////////
    ///////////////////////////////////////////

    useEffect(() => {
        dispatch(getMyFarm())
        dispatch(getAllFarmJoin(account?.farm))
    }, [dispatch])

    if (status === Status.Failure) {
        toast.error(t("farm:farmGetFailed"))
    }

    const FarmId = farm?._id!
    const [permissionsView, setPermissionsView] = useState(false)

    const onPermissionClick = () => {
        setPermissionsView(!permissionsView)
    }

    const [roleFormData, setRoleFormData] = useState({
        //   FarmId: '',
        farms: 'false',
        crops: 'false',
        joinApprovals: 'false',
        liveStock: 'false',
        employees: 'false',
        equipment: 'false',
        inventory: 'false',
        tasks: 'false',
        activity: 'false',
        customers: 'false',
        sales: 'false',
        storage: 'false',
        joinApprovalsEdit: 'false',
        farmsEdit: 'false',
        cropsEdit: 'false',
        liveStockEdit: 'false',
        employeesEdit: 'false',
        equipmentEdit: 'false',
        inventoryEdit: 'false',
        tasksEdit: 'false',
        activityEdit: 'false',
        customersEdit: 'false',
        salesEdit: 'false',
        storageEdit: 'false'
    })

    const {
        farms,
        crops, liveStock, employees, equipment, inventory, tasks, joinApprovals, activity, customers, sales, storage, joinApprovalsEdit, farmsEdit, cropsEdit,
        liveStockEdit,employeesEdit,equipmentEdit,inventoryEdit,tasksEdit,activityEdit,customersEdit,salesEdit,storageEdit,
    } = roleFormData

    const onRoleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {

        if (e.target.value === 'false') {
            setRoleFormData((prevState) => ({
                ...prevState,
                [e.target.name]: 'true'
            }))
        }

        if (e.target.value === 'true') {
            setRoleFormData((prevState) => ({
                ...prevState,
                [e.target.name]: 'false'
            }))
        }


        console.log(typeof (e.target.value))
        const target = e.target as HTMLInputElement
        console.log(target, 'target')
        console.log(roleFormData)

    }

    const [formData, setFormData] = useState({
        name: '',
        location: '',
        farmID: ''
    })

    const selectPosition = {
        lat: farm?.lat, 
        lon: farm?.lon
    }

    useEffect(() => {
        if (status !== Status.Pending && farm !== undefined) setFormData((prevState) => ({
            ...prevState,
            name: farm.name,
            location: farm.location,
            farmID: farm?._id!
        }));
    }, [status, farm])

    const {name, location, farmID} = formData

    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        console.log(e.target)
        console.log(e)
        console.log('name', e.target.name)
        console.log('value', e.target.value)

        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))

    }

    const onKeyDown = (e: React.KeyboardEvent) => {
        console.log(e)
        if (e.key === "Enter" || e.key === "Escape") {
            console.log('Key Down Hit');
            (e.target as HTMLElement).blur();
        }
    }

    const onBlur = (e: React.FocusEvent) => {
        e.preventDefault()
        console.log('On Blur Hit')
        const farmData = {
            name, location, farmID
        }
    }

    if (status === Status.Pending && farm?.name === '' && farm.location === '' && selectPosition.lat != undefined  && selectPosition.lon != undefined ) {
        return <Spinner/>
    }
    else {
        return (
            <>
            <div className='flex flex-row justify-center' >
            {/* ///////////////////////////////////////////
                //// DEVICE VIDEO ACCESS //////////////////
                /////////////////////////////////////////// */}
                {/* <div>
                    <video ref={videoRef}></video>
                    <button>SNAP!</button>
                </div> */}
            {/* ///////////////////////////////////////////
                //// DEVICE VIDEO ACCESS //////////////////
                /////////////////////////////////////////// */}
                <div className='' >
                    <FarmPageLeafletPolygon/>
                    {/* <DisplaySearchableMap lon={farm?.lon} lat={farm?.lat}  farmId={farm?._id}/> */}
                    {/* {selectPosition.lat != undefined ? 
                        (<>
                        <div style={{ width: "80vw", height: "50vh" }}>
                            <DisplayMaps selectPosition={selectPosition} />
                        </div>
                        </>) : <></> 
                    } */}
                
                    <div className="bg-white py-5 my-10 rounded-lg #drop-shadow-xl #shadow-lg relative px-9 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]  ">

                        <div className="grid grid-cols-3 gap-3">
                            <div className='-my-1 col-span-1 justify-self-center self-center'>
                                <img className="h-36 w-34 bg-indigo-400 shadow-xl rounded-xl"
                                    src="https://www.pngmart.com/files/22/Robot-Arm-PNG-Free-Download.png" alt=""/>
                            </div>
                            
                            <div className='col-span2 justify-start justify-self-start'>
                                <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                            onKeyDown={(e) => onKeyDown(e)} title={"Name"} value={name} id={"name"}
                                            name={"name"}/>
                                <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                            onKeyDown={(e) => onKeyDown(e)} title={"FarmID"} value={farmID} id={"farmID"}
                                            name={"farmID"}/>
                                <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                            onKeyDown={(e) => onKeyDown(e)} title={"location"} value={location}
                                            id={"location"} name={"location"}/>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Link to={"../farm-profile/" + farm?._id} target="_blank" rel="noreferrer" className='text-3xl font-light bg-indigo-300 p-3 rounded-lg text-white ' >Link to your page</Link>
                    </div>
                    <div className='py-10 flex-row '>
                        <h1 className='font-light text-3xl' >Explore Grant Opportunities</h1>
                        <div className='flex flex-row justify-center my-8'>
                            <div className='flex flex-col bg-white justify-start w-3/4 rounded-lg gap-4 py-4 px-4 mb-8 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]'>

                                <div className='grid grid-cols-3 '>

                                    <InlineEditCheckBox onChange={(e) => onRoleChange(e)} value={crops} id={"crops"} name={"crops"} label={"Cattle"} />
                                        
                                    <InlineEditCheckBox onChange={(e) => onRoleChange(e)} value={crops} id={"crops"} name={"crops"} label={"Corn"} />
                                        
                                    <InlineEditCheckBox onChange={(e) => onRoleChange(e)} value={crops} id={"crops"} name={"crops"} label={"Wheat"} />

                                </div>

                                <div className='grid grid-cols-3 '>

                                    <InlineEditCheckBox onChange={(e) => onRoleChange(e)} value={crops} id={"crops"} name={"crops"} label={"Veteran Farmer"} />
                                        
                                    <InlineEditCheckBox onChange={(e) => onRoleChange(e)} value={crops} id={"crops"} name={"crops"} label={"Black Farmer"} />
                                        
                                    <InlineEditCheckBox onChange={(e) => onRoleChange(e)} value={crops} id={"crops"} name={"crops"} label={"Woman Farmer"} />

                                </div>

                                <div className='grid grid-cols-3 '>

                                    <InlineEditCheckBox onChange={(e) => onRoleChange(e)} value={crops} id={"crops"} name={"crops"} label={"New Farmer"} />

                                    <InlineEditCheckBox onChange={(e) => onRoleChange(e)} value={crops} id={"crops"} name={"crops"} label={"Farmers Market"} />

                                    <InlineEditCheckBox onChange={(e) => onRoleChange(e)} value={crops} id={"crops"} name={"crops"} label={"Organic"} />

                                </div>

                            </div>

                        </div>

                        <div className='flex flex-row justify-center my-8'>
                            <div className='grid grid-cols-3'>
                                <div className='flex flex-col bg-white justify-start rounded-lg shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] w-11/12'>
                                    New Farmer Grant
                                </div>

                                <div className='flex flex-col bg-white justify-start rounded-lg shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] w-11/12'>
                                    New Farmer Grant
                                </div>
                                <div className='flex flex-col bg-white justify-start rounded-lg shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] w-11/12'>
                                    New Farmer Grant
                                </div>
                            </div>
                        </div>
                        
                    </div>

                </div>
            </div>
            </>
        )
    }
}


export default MyFarmPage