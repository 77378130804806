import React, {useEffect} from 'react'
import Modal from 'react-modal'
import {useDispatch, useSelector} from 'react-redux'
import {getCrop} from '../../../features/crops/cropSlice'
import {toast} from 'react-toastify'
import Spinner from '../../components/Spinner'
import {useParams} from 'react-router-dom'
import {AppDispatch, RootState} from "../../../app/store";
import {Status} from "../../../types/slice";
import {useTranslation} from "react-i18next";


Modal.setAppElement('#root')

function CropPage() {
    const {crop, status} = useSelector((state:RootState)  => state.crop)
    const {t} = useTranslation("crops")
    const {cropId} = useParams()
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (cropId)
            dispatch(getCrop(cropId))
    },[dispatch, cropId] )

    if (status === Status.Failure) {
        toast.error(t("crop:cropGetFailed"))
    }

    let totalYield = 0;
    let totalProfit = 0;
    let farmLandCoverage = 0;
    let storageValueEstimate = 0;
    let fulfilmentValueEstimate = 0;

    if (crop !== undefined){
        totalYield = crop.numberOfTrees * crop.expectedYield;
        totalProfit = Math.round(totalYield*(crop.salePrice-crop.expectedCost))
        farmLandCoverage = Math.round((crop.landArea/348)*100);
        storageValueEstimate = crop.stockHarvested * crop.salePrice;
        fulfilmentValueEstimate = crop.stockFulfilment * crop.salePrice;
    }

    if (status === Status.Pending) {
        return <Spinner/>
    }

  return (
      <>
      <div className='flex flex-row justify-center mx-5' >
        <button className='w-1/4 py-3 px-6 bg-indigo-400 text-white' >
          
            <h1>List For Sale</h1>
          
        </button >
      </div>
        <div className="mt-14 pb-14 mx-5" >
          <div className="grid grid-cols-1 gap-7" >
          <div className="bg-gray-100 rounded-xl drop-shadow-2xl pt-4" >
            <h1 className="text-3xl pb-2" >{crop?.cropName}</h1>
            <div>
                <div className="rounded-lg mx-3" >
                  <div className="grid grid-cols-6 pb-3 content-center justify-around drop-shadow-md" >
                      <div className= {`rounded-l-lg  ${crop?.developmentStage === '0' ? 'bg-indigo-500 text-white rounded-xl text-md text-center self-center py-3 drop-shadow-2xl shadow-black' : 'bg-gray-200 text-gray-500 text-xs pt-1 my-3' } `}>
                          dormant
                      </div>
                      <div className= {`  ${crop?.developmentStage === '20' ? 'bg-indigo-500 text-white rounded-xl text-md text-center self-center py-3 drop-shadow-2xl shadow-black' : ' bg-gray-200 text-gray-500 text-xs pt-1 my-3' } `}>
                          budding
                      </div>
                      <div className= {`  ${crop?.developmentStage === '40' ? 'bg-indigo-500 text-white rounded-xl text-md text-center self-center py-3 drop-shadow-2xl shadow-black' : ' bg-gray-200 text-gray-500 text-xs pt-1 my-3' } `}>
                          flowering
                      </div>
                      <div className= {`  ${crop?.developmentStage === '60' ? 'bg-indigo-500 text-white rounded-xl text-md text-center self-center py-3 drop-shadow-2xl shadow-black' : ' bg-gray-200 text-gray-500 text-xs pt-1 my-3' } `}>
                          fruit set
                      </div>
                      <div className= {`  ${crop?.developmentStage === '80' ? 'bg-indigo-500 text-white rounded-xl text-md text-center self-center py-3 drop-shadow-2xl shadow-black' : ' bg-gray-200 text-gray-500 text-xs pt-1 my-3' } `}>
                          fruit dev
                      </div>
                      <div className= {`rounded-r-lg  ${crop?.developmentStage === '100' ? 'bg-indigo-500 text-white rounded-xl text-md text-center self-center py-3 drop-shadow-2xl shadow-black' : ' bg-gray-200 text-gray-500 text-xs pt-1 my-3' } `}>
                          harvest
                      </div>
                  </div>
            </div>
            
            <div className="flex px-8 pb-5" >
              <progress className="progress progress-primary bg-gray-300 h-2 mr-5 mt-3" value={crop?.stageProgress} max="100"></progress>
              <h1 className="text-xl" >{crop?.stageProgress}%</h1>
            </div>

            <div className="mx-4 py-2 rounded-lg grid grid-cols-4 drop-shadow-xl mb-8 bg-gray-200 divide-x-2 divide-gray-400">
              <div className="" >
                <div className="text-lg font-normal" >K</div>
                <div className="text-3xl font-bold text-error ">{crop?.potassiumNutrition}</div>
                <div className="text-xs font-light text-gray-500 ">potassium</div>
              </div>

              <div>
                <div className="text-lg font-normal" >N</div>
                <div className="text-3xl font-bold text-error">{crop?.nitrogenNutrition}</div>
                <div className="text-xs font-light text-gray-500 ">nitrogen</div>
              </div>

              <div>
                <div className="text-lg font-normal" >P</div>
                <div className="text-3xl font-bold text-success">{crop?.phosphorusNutrition}</div>
                <div className="text-xs font-light text-gray-500 ">phosphorus</div>
              </div>

              <div>
                <div className="text-lg font-normal" >H2O</div>
                <div className="text-3xl font-bold text-warning">{crop?.waterNutrition}</div>
                <div className="text-xs font-light text-gray-500 ">water</div>
              </div>
            </div>


            
            <div className="col-span-2 row-span-1 rounded-lg drop-shadow-xl flex flex-row justify-center ml-3 mr-3 mb-6">
              <div className="flex justify-center p-2" >
                <button className="px-3 py-2 border-2 bg-white text-black border-indigo-500 border-r-0 rounded-l-lg  hover:bg-indigo-500 hover:text-white  ">
                  Health
                </button>
                <button className="px-3 py-2 border-2 bg-white border-indigo-500 border-r-0 text-black  hover:bg-indigo-500 hover:text-white ">
                  Update
                </button>
                <button className="px-3 py-2 border-2 bg-white border-indigo-500 border-l text-black rounded-r-lg  hover:bg-indigo-500 hover:text-white" >
                  Harvest
                </button>
              </div>
            </div>


            <div className="grid grid-cols-3 grid-rows-3 gap-3 ml-5 rounded-lg ">

                <div className="rounded-lg col-span-1 drop-shadow-md row-span-3 bg-gray-200 flex flex-col justify-center gap-1 divide-x-4" >

                  <div className=" mt-1 text-5xl text-center text-primary" >
                    <i className="fa-solid fa-tree" />
                  </div>

                  <div className="text-3xl text-center mb-2 text-primary" >
                    {crop?.numberOfTrees}
                    <div className="text-sm font-light text-gray-500 ">trees</div>
                  </div>

                </div>

                {/* FIRST DATA ENTRY FOR TREES */}

                <div className="col-span-2 row-span-1 bg-gray-200 rounded-lg drop-shadow-md flex flex-row justify-center ml-3 mr-3" >


                  {/* APPLE LOGO */}
                  <div className="text-3xl pt-3 pl-3 text-center text-primary" >
                    <i className="fa-solid fa-apple-whole" />
                  </div>


                  {/* STAT BODY AND NUMBER */}

                  <div className="flex flex-col" >

                      {/* TREES STAT */}

                  <div className="text-3xl pl-3 text-gray-800 pt-2 flex" >
                    {totalYield}
                    <div className="text-sm font-light text-gray-600 ">kg</div>
                  </div>

                      {/* TITLE OF THE DATA TREE */}

                  <div className="text-sm pl-3 font-light text-gray-600 "> Expected Total Yield</div> 
                  </div>
                </div>



                {/* FIRST DATA ENTRY FOR TREES */}

                <div className="col-span-2 row-span-1 bg-gray-200 rounded-lg drop-shadow-md flex flex-row justify-center ml-3 mr-3" >


                  {/* APPLE LOGO */}
                  <div className="text-3xl pt-3 pl-3 text-center text-primary" >
                    <i className="fa-solid fa-dollar-sign"/>
                  </div>


                  {/* STAT BODY AND NUMBER */}

                  <div className="flex flex-col" >

                      {/* TREES STAT */}

                  <div className="text-3xl pl-3 text-gray-800 pt-2 flex" >

                      {totalProfit}
                  </div>

                      {/* TITLE OF THE DATA TREE */}

                  <div className="text-sm pl-3 font-light text-gray-600 "> Expected Total Profit</div> 
                  </div>
                </div>


                {/* FIRST DATA ENTRY FOR TREES */}

                <div className="col-span-2 row-span-1 bg-gray-200 rounded-lg drop-shadow-md flex flex-row justify-center ml-3 mr-3" >


                  {/* APPLE LOGO */}
                  <div className="text-3xl pt-3 pl-3 text-center text-primary" >
                    <i className="fa-solid fa-percent" />
                  </div>


                  {/* STAT BODY AND NUMBER */}

                  <div className="flex flex-col" >

                      {/* TREES STAT */}
                      {farmLandCoverage}
                  <div className="text-3xl pl-3 text-gray-800 pt-2 flex" >
                    {}
                  </div>

                      {/* TITLE OF THE DATA TREE */}

                  <div className="text-sm pl-3 font-light text-gray-600 ">Farm Land Coverage</div> 
                  </div>
                </div>

            </div>


            {/* STATS ON CROP STORAGE */}

            <div className="my-4 mt-8 py-2 mx-5 rounded-lg grid grid-cols-2 drop-shadow-xl bg-gray-200 divide-x-2 divide-gray-400">
              <div className="flex flex-row justify-evenly" >
                <div>
                  <div className="text-3xl mt-2 text-primary" >
                    <i className="fa-solid fa-warehouse"/>
                  </div>
                  <div className="text-sm font-light text-gray-500" >
                    Storage
                  </div>
                </div>
                <div>
                  <div className="text-2xl text-gray-800 flex" >
                    {crop?.stockHarvested}
                    <div className="text-sm font-light text-gray-600 ">kg</div>
                  </div>
                  <div className="text-2xl text-gray-800 flex" >
                    <div className="text-sm font-light text-gray-600 ">$</div>
                    {storageValueEstimate}
                  </div>                  
                </div>
              </div>
              <div className="flex flex-row justify-evenly" >
                <div>
                  <div className="text-3xl mt-2 text-primary" >
                    <i className="fa-solid fa-truck-arrow-rightfa-solid fa-truck-arrow-right"/>
                  </div>
                  <div className="text-sm font-light text-gray-500" >
                    Fulfillment
                  </div>
                </div>
                <div>
                  <div className="text-2xl text-gray-800 flex" >
                    {crop?.stockFulfilment}
                    <div className="text-sm font-light text-gray-600 ">kg</div>
                  </div>
                  <div className="text-2xl text-gray-800 flex" >
                    <div className="text-sm font-light text-gray-600 ">$</div>
                    {fulfilmentValueEstimate}
                  </div>                  
                </div>
              </div>
            </div>

              <div className=" mt-8 mb-7 mx-4 " >
              <div  className=" " >
              <table className=" w-full table drop-shadow-md" >
                <thead>
                  <tr>
                    <th className="text-sm" >
                      Sickness
                    </th>
                    <th>
                      % infected
                    </th>
                    <th>
                      treatment
                    </th>
                    <th>
                      contact
                    </th>
                  </tr>
                </thead>

                <tbody>
                <tr>
                  <th>
                    Black Dots
                  </th>
                  <th>
                    15%
                  </th>
                  <th>
                    <div className='bg-success w-8 h-4' ></div>
                  </th>
                  <th>
                    Jack
                  </th>
                </tr>

                <tr>
                  <th className="text-sm">
                    Orange Circles
                  </th>
                  <th>
                    30%
                  </th>
                  <th>
                    <div className='bg-warning w-8 h-4' ></div>
                  </th>
                  <th>
                    Jill
                  </th>
                </tr>
                </tbody>
              </table>

                </div>
              </div>
              </div>
            
            </div>
            </div>
            </div>
    </>
  )
}

export default CropPage