import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {reset, getAllTest, getAllColumns, getColumnOrder} from '../../../features/tests/testSlice'
import {DragDropContext, DropResult} from 'react-beautiful-dnd'
import Spinner from '../../components/Spinner'
import Column from './Column'
import Modal from 'react-modal'
import {updateColumn, addColumn} from '../../../features/tests/testSlice'
import {AppDispatch, RootState} from "../../../app/store";
import {ColumnModel} from "@asirisos/types";

Modal.setAppElement('#root')

function Kanban() {

    const dispatch = useDispatch<AppDispatch>();

    ///  Implementing Modal for Add Column ///////////////

    const [modalIsOpen, setModalIsOpen] = useState(false)

    // Open Modal
    const openModal = () => setModalIsOpen(true)
    const closeModal = () => setModalIsOpen(false)

    const [newColumnData, setNewColumnData] = useState({
        title: ''
    })

    const {title} = newColumnData


    //// Handle on Change
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewColumnData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    ///// Handle on Submit
    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        const newColumn: ColumnModel = {
            title
        }

        // const newColumnOrder = {
        //     ...columnOrder,
        // }

        console.log('OnSubmit', newColumn)
        dispatch(addColumn(newColumn))
        closeModal()

    }

    ///////////////////////////////////////

    const {tasks, columns, columnOrder, isError, isSuccess, isLoading} = useSelector((state: RootState) => state.test)


    useEffect(() => {
        dispatch(getAllTest())
        dispatch(getAllColumns())
        dispatch(getColumnOrder())
        if (isSuccess) {
            dispatch(reset())
        }
    }, [dispatch])

    if (tasks === null || columns === null || columnOrder === null || isLoading) {
        return <Spinner/>
    } else {
        const onDragEnd = (result: DropResult) => {
            //reorder column
            const {destination, source, draggableId} = result

            if (!destination) {
                return
            }

            if (
                destination.droppableId === source.droppableId &&
                destination.index === source.index
            ) {
                return
            }

            const columnStart = columns.filter(column => {
                return column._id === source.droppableId
            })
            const columnFinish = columns.filter(column => {
                return column._id === destination.droppableId
            })

            if (columnStart[0]._id === columnFinish[0]._id) {
                // console.log(column[0])
                // console.log('old task ids', column[0].taskIds)

                const newTaskIds = Array.from(columnStart[0].taskIds!)

                newTaskIds.splice(source.index, 1)
                newTaskIds.splice(destination.index, 0, draggableId)
                // console.log('new task Ids', newTaskIds)

                const newColumn = {
                    ...columnStart[0],
                    taskIds: newTaskIds
                }
                console.log('Smae Column newTaskIds', newTaskIds)
                console.log('Same Column', newColumn)
                dispatch(updateColumn(newColumn))
                // dispatch(getAllColumns())
                console.log("dispatch new Column Add")

                return
            }
            //Moving from one Column to another

            //Adjusting Start Column Task IDs
            const startTaskIds = Array.from(columnStart[0].taskIds!)
            startTaskIds.splice(source.index, 1)
            const newStartColumn = {
                ...columnStart[0],
                taskIds: startTaskIds
            }

            //Adjusting Finish Column Task IDs
            const finishTaskIds = Array.from(columnFinish[0].taskIds!)
            finishTaskIds.splice(destination.index, 0, draggableId)
            const newFinishColumn = {
                ...columnFinish[0],
                taskIds: finishTaskIds
            }

            dispatch(updateColumn(newStartColumn))
            dispatch(updateColumn(newFinishColumn))
            dispatch(getAllColumns())
            console.log('dispatched column move task')

        }


        if (isLoading) {

            return <Spinner/>
        }
        {
            let order: Array<string> = [];
            if (columnOrder !== undefined && columnOrder.length > 0)
                order = columnOrder[0].columnIds
            return (
                <>
                <div className='' >
                    <DragDropContext onDragEnd={result => onDragEnd(result)}>
                        <div className="flex flex-row justify-center">

                            {order.map((columnId: string) => {
                                // console.log("columnID", columnId)
                                const columnFiltered = columns.filter(column => {

                                    // console.log('column id',column._id, 'column Order', columnId.toString())
                                    return column._id === columnId.toString()
                                })


                                const taskIds = columnFiltered[0].taskIds
                                const column = columnFiltered[0]

                                const columnTasks2 = taskIds!.map(taskId => tasks.filter(task => {
                                    return task._id === taskId
                                }))

                                const tasks2 = columnTasks2.map(task => {
                                    return task[0]
                                })

                                return <Column key={column._id} column={column} tasks={tasks2}/>
                            })}
                        </div>
                    </DragDropContext>
                    </div>
                    <button onClick={openModal}
                            className="bg-indigo-600 px-3 py-2 mt-6 text-white drop-shadow-lg rounded-lg">Add Column
                    </button>
                    <Modal className="p-15 border-none" isOpen={modalIsOpen} onRequestClose={closeModal}
                           contentLabel="Add Column">
                        <h1 className=" p-10 " onClick={closeModal}>Close</h1>
                        <form onSubmit={onSubmit}>
                            <input
                                type="title"
                                placeholder="title"
                                id="title"
                                name="title"
                                value={title}
                                onChange={onChange}
                                className="input input-bordered w-full max-w-xs"/>
                            <button className="btn mt-5 m-3 btn-wide btn-primar hover:bg-black">Add Column</button>
                        </form>

                    </Modal>
                </>
            )
        }
    }
}

export default Kanban