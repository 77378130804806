import axios from "axios";
import {DeviceModel} from "@asirisos/types";
import RequestBodyFactory from "../../factories/requestBodyFactory";

const API_URL = '/api/devices'

const addDevice = async (deviceData: DeviceModel) => {
    const body = new RequestBodyFactory(deviceData).Build()

    return await axios.post(API_URL + '/add', body)
}

const getDevices = async () => {
    return await axios.get(API_URL + '/all')
}

const getDevice = async (deviceId: string) => {
    return await axios.get(API_URL + '/' + deviceId)
}

const deleteDevice = async (deviceId: string) => {
    return await axios.delete(API_URL + '/' + deviceId)
}

const deviceService = {
    addDevice,
    getDevices,
    getDevice,
    deleteDevice
}

export default deviceService