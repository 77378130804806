import axios from "axios";
import {ApplicationState} from "./applicationState";

function HandleAxiosError(error: unknown, scope: string): void{
    if (axios.isAxiosError(error)){
      console.log(scope+ " ==> " + (error.response && error.response.data && error.response.data.message) || error.message || error.toString())
    }
    else {
        console.log(scope+ " ==> " +"Unknown error")
    }
}

export default HandleAxiosError