import React from 'react'
import {Link} from 'react-router-dom'
import CropTable from './CropsTable'
import {useTranslation} from "react-i18next";
import {Translation} from "react-i18next";
import {useTabPersistence} from "../../../hooks/useTabPersistence";
import {PageId} from "../../../types/pages";
import CropDashboard from './CropDashboard';
import CropTasks from './CropTasks';
import CropActivity from './CropActivity';

function Crops() {
    const {t} = useTranslation(["crop", "translation"])
    const {selection, toggleTab} = useTabPersistence(PageId.Equipment);
    return (
        <>
        <div className='px-5'>
            <div className='pb-8 mb-6 rounded-lg bg-slate-100 drop-shadow-lg'>
                <ul className="flex felx-row justify-left cursor-pointer">
                    <li onClick={() => toggleTab(1)}
                        className={`rounded-t-lg px-12 py-2 ${selection === 1 ? 'bg-indigo-500 text-white' : ''}`}>{t("crop:home")}
                    </li>
                    <li onClick={() => toggleTab(2)}
                        className={`rounded-t-lg px-12 py-2 ${selection === 2 ? 'bg-indigo-500 text-white' : ''}`}>{t("crop:crops")}
                    </li>
                    <li onClick={() => toggleTab(3)}
                        className={`rounded-t-lg px-12 py-2 ${selection === 3 ? 'bg-indigo-500 text-white' : ''}`}>{t("crop:tasks")}
                    </li>
                    <li onClick={() => toggleTab(4)}
                        className={`rounded-t-lg px-12 py-2 ${selection === 4 ? 'bg-indigo-500 text-white' : ''}`}>{t("crop:activity")}
                    </li>
                </ul>

                {selection === 1 ? (
                    <div className='bg-white drop-shadow-lg rounded-tl-none py-8'>
                        <CropDashboard/>
                    </div>
                ) : (<></>)}

                {selection === 2 ? (
                    <div className='bg-white drop-shadow-lg rounded-tl-none py-8'>
                        <CropTable/>
                    </div>
                ) : (<></>)}

                {selection === 3 ? (
                    <div className='bg-white drop-shadow-lg rounded-tl-none py-8'>
                        <CropTasks/>
                        <Link className="btn  mx-9" to={"/task-form"}>
                            Add Task
                        </Link>

                    </div>
                ) : (<></>)}

                {selection === 4 ? (
                    <div className='bg-white drop-shadow-lg rounded-tl-none py-8'>

                      <CropActivity/>
                      <Link className="btn mx-9 mt-6" to={"/activity-form"}>
                          Add Activity
                      </Link>
                    </div>
                ) : (<></>)}

            </div>



            <div className='w-full'>
                <Link className="btn mb-8" to={"/crop-form"}>
                    <Translation ns={"crop"}>
                        {t => t("crop:addCrop")}
                    </Translation>
                </Link>

                <div className="grid grid-cols-2 gap-12">

                </div>
            </div>
        </div>
        </>
    )
}

export default Crops