import axios from "axios";
import {AccountModel, FieldModel} from "@asirisos/types";
import RequestBodyFactory from "../../factories/requestBodyFactory";

const API_URL = '/api/fields'

// Add Field

const addField = async(fieldData: FieldModel, account: AccountModel) => {
    console.log("field service - sending data - add field - field Data", fieldData)
    const body = new RequestBodyFactory(fieldData).WithAccount(account).Build()
    console.log("field service - sending data - add field - body", body)
    return await axios.post(API_URL + '/add', body)
}

// Get Fields
const getFields = async(account: AccountModel) => {
    return await axios.get(API_URL + '/all/' + account.farm._id)
}

// Get Single Storage

const getField = async(fieldId: string) => {
    return await axios.get(API_URL + '/' + fieldId)
}

// Update Storage

const updateField = async(fieldData: FieldModel, account: AccountModel) => {
    const {_id} = fieldData
    const body = new RequestBodyFactory(fieldData).WithAccount(account).Build();

    return await axios.put(API_URL + 'update/' + _id, body)
}

// Delete Storage

const deleteField = async(fieldId: string[], account: AccountModel) => {
    const ids = fieldId
    const config = {
        data: {ids, account}
    }
    console.log('delete storage config',config)
    return await axios.delete(API_URL+ '/' + fieldId, config)
}

const fieldService = {
    addField,
    getFields,
    getField,
    updateField,
    deleteField
}

export default fieldService