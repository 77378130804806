import axios from "axios";
import {AccountModel, OrganizationModel} from "@asirisos/types";
import RequestBodyFactory from "../../factories/requestBodyFactory";

const API_URL = '/api/organizations'

// Add Organization

const addOrganization = async(organizationData: OrganizationModel, account: AccountModel) => {
    console.log("organization service - sending data - add organization - organization Data", organizationData)
    const body = new RequestBodyFactory(organizationData).WithAccount(account).Build()
    console.log("organization service - sending data - add organization - body", body)
    return await axios.post(API_URL + '/add', body)
}

// Get Organizations
const getOrganizations = async(account: AccountModel) => {
    return await axios.get(API_URL + '/all/' + account.farm._id)
}

// Get Single Organization

const getOrganization = async(organizationId: string) => {
    return await axios.get(API_URL + '/' + organizationId)
}

// Update Organization

const updateOrganization = async(organizationData: OrganizationModel, account: AccountModel) => {
    const {_id} = organizationData
    const body = new RequestBodyFactory(organizationData).WithAccount(account).Build();

    return await axios.put(API_URL + 'update/' + _id, body)
}

// Delete Organization

const deleteOrganization = async(organizationId: string[], account: AccountModel) => {
    const ids = organizationId
    const config = {
        data: {ids, account}
    }
    console.log('delete organization config',config)
    return await axios.delete(API_URL+ '/' + organizationId, config)
}

const organizationService = {
    addOrganization,
    getOrganizations,
    getOrganization,
    updateOrganization,
    deleteOrganization
}

export default organizationService