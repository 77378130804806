import React, { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux'
import { FeatureGroup, MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import { Link } from "react-router-dom";
import { getOrgLocations } from "features/orgLocations/orgLocationsSlice";
import { getOrgFarmersMarkets } from "features/farmersMarkets/orgFarmersMarketsSlice";
import Spinner from '../../components/Spinner'
import {Status} from "../../../types/slice";
import { cities } from "data/cities";
import "leaflet/dist/leaflet.css";
import LeafletControlGeocoder from "./LeafletControlGeocoder";
import L from "leaflet";
// Searches
import SearchBox from '../Map/SearchBox-1'
import SearchableMap from '../Map/SearchableMap-1'

const onClick = () => {
  console.log('Pop Up Button Hit' )
}

const DEFAULT_RADIUS = 80

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png",
});

const CustomPopUp = ({data, setRadiusFilter}) => {

  const {listing_name, location_address, specialproductionmethods, listing_id} = data.properties

  return(
    <>
    <Popup className="">
        <div className=" flex flex-col py-6 px-3 rounded-md text-gray-500 text-center justify-center" >
          <div className="flex flex-col text-start">
            <b className="text-lg font-bold">Name: </b>
            <b className="text-xl font-light">{`${listing_name}`}</b>
          </div>
          <div className="flex flex-col text-start pt-4">
            <b className="text-lg font-bold">Address: </b>
            <b className="text-xl font-light" >{`${location_address}`}</b>
          </div>
          <div className="flex flex-col text-start pt-4">
            <b className="text-lg font-bold">Certifications: </b>
            <b className="text-xl font-light" >{`${specialproductionmethods}`}</b>
          </div>
          
          {/* <button onClick={onClick} className="text-white p-4 mt-8 rounded-md bg-indigo-400 shadow-xl" >
            <Link to={`/farmersmarket/${listing_id}`}>Details</Link>
          </button> */}
        </div>
    </Popup>
    </>
  )
}

const inputsIcon = L.icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/512/2776/2776067.png",
  iconSize: [50, 50],
});

function ResetCenterView(props) {
  const { selectPosition } = props;
  const map = useMap();

  useEffect(() => {
    if (selectPosition) {
      map.setView(
        L.latLng(selectPosition?.geometry.coordinates[1], selectPosition?.geometry.coordinates[0]),
        map.getZoom(),
        {
          animate: true
        }
      )
    }
  }, [selectPosition]);

  return null;
}


export default function FarmersMarketsProfileMap(props) {

  const { selectPosition } = props;

  return (
    <>
        <MapContainer center={[41.05181527192488, -73.59345225093897]} zoom={10} minZoom={10}    style={{ width: "100%", height: "100%" }}>
          {/* Light Mode */}
          {/* <TileLayer url='https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.{ext}' minZoom= {10} maxZoom= {20}
            attribution= '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            ext= 'png' /> */}
          <TileLayer minZoom= {10} maxZoom= {20}
            url='https://api.mapbox.com/styles/v1/purpledragons/cl9kzio97001v14pe6ahvafbd/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicHVycGxlZHJhZ29ucyIsImEiOiJjbDlqMGp3cHkwZDJsM25wa2I2dnBxMXU2In0.m0DYm_idO-8_I7OeSBuMnQ'
            />
          {selectPosition && (
            <Marker position={[selectPosition.geometry.coordinates[1], selectPosition.geometry.coordinates[0]]} icon={inputsIcon}>
             <CustomPopUp data={selectPosition}/>
            </Marker>
            // <Marker position={locationSelection} icon={icon}>
            //   <Popup>
            //     Your Location
            //   </Popup>
            // </Marker>
          )}
          <ResetCenterView selectPosition={selectPosition} />
        </MapContainer>
        </>
  );
}