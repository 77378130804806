import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { deleteTask, getTask, updateTask} from '../../../features/task/taskSlice'
import {toast} from 'react-toastify'
import Spinner from '../../components/Spinner'
import {useParams, useNavigate} from 'react-router-dom'
import InlineEdit from '../../components/shared/InlineEdit'
import { getFarmAccounts } from 'features/accounts/accountSlice'
import { getConnectionIdComments } from 'features/comments/commentSlice'
import {AppDispatch, RootState} from "../../../app/store";
import FormSelectionRangeInput from '../../components/shared/card/FormSelectionRangeInput'
import InlineEditDropDown from '../../components/shared/InlineEditDropDown'
import { getCrops } from 'features/crops/cropSlice'
import { getAllEquipment } from 'features/equipment/equipmentSlice'
import { getAllLiveStock } from 'features/live-stock/live-stockSlice'
import { getAllInventory } from 'features/inventory/inventorySlice'
import { getAllStorage } from 'features/storage/storageSlice'
import { getInfrastructures } from 'features/infrastructure/infrastructureSlice'
import { getFields } from 'features/fields/fieldSlice'
import { getCustomers } from 'features/customers/customerSlice'
import {Status} from "../../../types/slice";
import { InputSelection } from 'types/ui'
import ListOfComments from '../Comments/ListOfComments'
import {useTranslation} from "react-i18next";
import TaskConnectionsDisplay from './TaskConnectionsDisplay'
import CommentForm from '../Comments/CommentForm'
import ChangeActivityList from '../Activity/ChangeActivityList'
import ItemPageDeleteButton from 'UI/components/ItemPageDeleteButton'
import DeleteModal from 'UI/components/DeleteModal'
import { getActivityByConnectionId } from 'features/activity/activitySlice'
import {
    CropModel,
    CustomerModel,
    EmployeeModel,
    EquipmentModel,
    InventoryModel,
    LiveStockModel,
    StorageModel, 
    TaskModel,
    YieldModel,
    FieldModel,
    InfrastructureModel
} from "@asirisos/types";
import { features } from 'process'
import { getYields } from 'features/yield/yieldSlice'

function TaskPage() {

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    Initiate Hooks   /////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const {t} = useTranslation("task");
    const {taskId} = useParams()
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    USE EFFECT   /////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    useEffect(() => {
        dispatch(getCrops())
        dispatch(getFarmAccounts())
        dispatch(getAllEquipment())
        dispatch(getAllLiveStock())
        dispatch(getAllInventory())
        dispatch(getAllStorage())
        dispatch(getCustomers())
        dispatch(getYields())
        dispatch(getFields())
        dispatch(getInfrastructures())
    },[dispatch] )

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    USE SELECTOR   /////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const {task, status} = useSelector((state: RootState) => state.taskTask)
    const {crops} = useSelector((state : RootState) => state.crop )
    const {equipments} = useSelector((state : RootState) => state.equipment )
    const {accounts} = useSelector((state: RootState) => state.account)
    const {allLiveStock} = useSelector((state : RootState) => state.livestock )
    const {allInventory} = useSelector((state : RootState) => state.inventory )
    const {allStorage} = useSelector((state: RootState) => state.storage )
    const {yields} = useSelector((state: RootState) => state.yield)
    const {customers} = useSelector((state: RootState) => state.customer )
    const {fields} = useSelector((state: RootState) => state.field)
    const {infrastructures} = useSelector((state: RootState) => state.infrastructure)

    const {allActivity} = useSelector((state: RootState) => state.activity)
    

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  CONNECTION SELECTIONS - DROP DOWN   ////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const cropSelections = crops.map((crop) => { return new InputSelection(crop.cropName, crop._id)})

    const equipmentSelections = equipments.map((equipment) => {return new InputSelection(equipment.name, equipment._id)})

    const employeeSelections = accounts.map((account) => {return {name: account.name, id:account._id!}})

    const liveStockSelections = allLiveStock.map((livestock) => {return {name: livestock.name, id:livestock._id}})

    const inventorySelections = allInventory.map((inventory) => {return {name: inventory.name, id:inventory._id}})

    const storageSelections = allStorage.map((storage) => {return {name: storage.name, id:storage._id}})

    const customerSelections = customers.map((customer) => {return  new InputSelection(customer.firstName, customer._id)})

    const yieldSelections = yields.map((singleYield) => {return  new InputSelection(singleYield.title, singleYield._id)})

    const fieldSelections = fields.map((field) => {return  new InputSelection(field.fieldName, field._id)})

    const infrastructureSelections = infrastructures.map((infrastructure) => {return  new InputSelection(infrastructure.infrastructureName, infrastructure._id)})

    console.log('Task Page - Task Id Param TYPE ', console.log(typeof(task?._id)))

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  USE EFFECTS  ///////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    useEffect(() => {
        if (taskId)
            dispatch(getTask(taskId))
            dispatch(getActivityByConnectionId(taskId!))
    }, [dispatch, taskId])

    useEffect(() => {
        if (task?._id)
            dispatch(getConnectionIdComments(task._id))
    }, [dispatch, task?._id])

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  STATUS ON FAILIURE    //////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    if (status === Status.Failure) {
        toast.error(t("task:taskGetFailed"))
    }


    //////////// CHANGED DATA STATE ///////////

    const [changedData, setChangedData] = useState({
        updatedField: ''
    })

    const {updatedField} = changedData
    let changedField : string


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  USE STATE   ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const storageObj : StorageModel = {
        _id: '',
        name: '',
        description: '',
        quantity: 0,
        comments: '',
        crop: '',
        category: ''
    }

    const cropObj : CropModel = {
        _id: undefined,
        farmId: '',
        cropName: '',
        developmentStage: '',
        stageProgress: '',
        landArea: 0,
        areaDenomination: '',
        numberOfTrees: 0,
        expectedYield: 0,
        expectedYieldDenom: '',
        stockHarvested: 0,
        stockFulfilment: 0,
        salePrice: 0,
        salePriceDenom: '',
        expectedCost: 0,
        expectedCostDenom: '',
        waterNutrition: '',
        nitrogenNutrition: '',
        phosphorusNutrition: '',
        potassiumNutrition: ''
    }

    const liveStockObj : LiveStockModel = {
        breed: "",
        dob: "",
        farmID: "",
        group: "",
        quantity: 0,
        status: "",
        name: '',
        category: '',
        weight: 0,
        description: '',
        _id: '',
        sex: ''
    }
    const employeeObj : EmployeeModel = {
        name: '',
        email: '',
        phone: '',
        job_title: '',
        date_of_hire: '',
        recruitment_details: '',
        salary: '',
        qualifications: '',
        access: [],
    }

    const equipmentObj : EquipmentModel = {
        _id: '',
        farmId: '',
        type: '',
        name: '',
        mileage: '',
        vin: '',
        features: '',
        usageUnit: '',
        value: ''
    }

    const inventoryObj : InventoryModel = {
        _id: '',
        name: '',
        farmId: '',
        description: '',
        type: '',
        quantity: 0,
        comments: ''
    }

    const customerObj : CustomerModel = {
        _id: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
    }

    const yieldObject : YieldModel ={
        _id: '',
        title: '',
        description: '',
        totalArea: '',
        totalAreaUnit: '',
        connectionCategory: '',
        yieldStageGrowing: 'false',
        yieldStageStorage: 'false',
        crop: '',
        liveStock: '',
        farmField: '',
        infrastructure: '',
        storage: '',
    }

    const fieldObj : FieldModel ={
        totalArea: '',
        totalAreaUnit: '',
        assetValue: '',
        latlngs: '',
        _id: '',
        mapSection: '',
        farmId: '',
        coordinates: [],
        description: '',
        connectionCategory: '' ,
        fieldName: '' ,
        fieldCategory: '' ,
        employee: '' ,
        deadline: '' ,
        equipment: '',
        crop: '' ,
        liveStock: '' ,
        inventory: '' ,
        storage: '' ,
        customer: '' ,
        sale: '' ,
        expense: '' ,   
    }

    const infrastructureObj : InfrastructureModel = {
        _id: '',
        latlngs: '',
        mapSection: '',
        farmId: '',
        coordinates: [],
        description: '',
        connectionCategory: '' ,
        infrastructureName: '' ,
        infrastructureCategory: '' ,
        employee: '' ,
        deadline: '' ,
        equipment: '',
        crop: '' ,
        liveStock: '' ,
        inventory: '' ,
        storage: '' ,
        customer: '' ,
        sale: '' ,
        expense: '' ,
    }

    const [formData, setFormData] = useState({
        title: '',
        farmID: '',
        taskStatus: '',
        description: '',
        deadline: '',
        connectionAdded: '',
        connectionCategory: '',
        crop: '',
        yieldConnection: '',
        employee: '',
        equipment: '',
        inventory: '',
        storage: '',
        field: '',
        infrastructure: '',
        customer: '',
        liveStock: '',
        assignedTo: '',
        assignedToObject: {
            job_title: '',
            phone: '',
            name: '',
            email: '',
        },
        yieldObject: yieldObject,
        infrastructureObject: infrastructureObj,
        fieldObject: fieldObj,
        storageObject: storageObj,
        cropObject: cropObj,
        liveStockObject: liveStockObj,
        employeeObject: employeeObj,
        equipmentObject: equipmentObj,
        inventoryObject: inventoryObj,
        customerObject: customerObj,
    })

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  USE EFFECT - FORM DATA FOR ON CHANGE   /////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    useEffect(() => {
        if (status !== Status.Pending && task !== undefined) setFormData((prevState) => ({
            ...prevState,
            title: task.title,
            farmID: task.farmID!,
            taskStatus: task.taskStatus,
            description: task.description,
            deadline: task.deadline,
            connectionAdded: task.connectionAdded,
            connectionCategory: task.connectionCategory,
            crop: task.crop,
            yieldConnection: task.yieldConnection,
            field: task.field,
            infrastructure: task.infrastructure,
            employee: task.employee,
            equipment: task.equipment,
            inventory: task.inventory,
            storage: task.storage,
            customer: task.customer,
            liveStock: task.liveStock,
            assignedTo: task.assignedTo,
            assignedToObject: task.assignedToObject!,
            storageObject: task.storageObject!,
            cropObject: task.cropObject!,
            yieldObject: task.yieldObject!,
            fieldObject: task.fieldObject!,
            infrastructureObject: task.infrastructureObject!,
            liveStockObject: task.liveStockObject!,
            employeeObject: task.employeeObject!,
            equipmentObject: task.equipmentObject!,
            inventoryObject: task.inventoryObject!,
            customerObject: task.customerObject!,
        }));
    }, [status])

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  STATE ENTRIES - DESTRUCTURE   //////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const {
        title,
        farmID,
        taskStatus,
        description,
        deadline,
        connectionAdded,
        connectionCategory,
        crop,
        yieldConnection,
        employee,
        equipment,
        inventory,
        storage,
        field,
        infrastructure,
        customer,
        liveStock,
        assignedTo,
        assignedToObject,
        storageObject,
        cropObject,
        liveStockObject,
        fieldObject,
        infrastructureObject,
        employeeObject,
        equipmentObject,
        inventoryObject,
        customerObject,
    } = formData

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  ON CHANGE   ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setChangedData(() => ({
            updatedField: e.target.name 
        }))
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  HANDLE CHANGE - NOT USED   /////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        e.preventDefault()

        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))

        const taskData: TaskModel = {
            title,
            taskStatus,
            connectionAdded,
            connectionCategory,
            description,
            crop,
            employee,
            equipment,
            field,
            infrastructure,
            inventory,
            yieldConnection,
            storage,
            customer,
            liveStock,
            deadline,
            assignedTo: e.target.value,
            _id:taskId,
            changedField: e.target.id
        }

        // console.log('Task Page - onBlur - Task Update - taskData',taskData)
        dispatch(updateTask(taskData))
            .unwrap()
            .then(() => {
                toast.success(t("task:taskUpdated"))
            })
            .catch(() => {
                toast.error(t("task:taskUpdateFailed"))
            })
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  ON KEY DOWN   //////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const onKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === "Enter" || event.key === "Escape") {
          (event.target as HTMLElement).blur();
        }
      }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  ON BLUR   //////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const onBlur = (e: React.FocusEvent) => {
        e.preventDefault()
        console.log('On Blur Hit')
        const taskData : TaskModel = {
            title,
            taskStatus,
            connectionAdded,
            connectionCategory,
            description,
            crop,
            yieldConnection,
            infrastructure,
            field,
            employee,
            equipment,
            inventory,
            storage,
            customer,
            liveStock,
            assignedTo,
            deadline,
            _id:taskId,
            changedField: changedData.updatedField
      }

    //   console.log('Task Page - onBlur - Task Update - taskData',taskData)
            dispatch(updateTask(taskData))
            .unwrap()
            .then(() => {
                toast.success(t("task:taskUpdated"))
            })
            .catch(() => {
                toast.error(t("task:taskUpdateFailed"))
            })
      }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  USE STATE - COMMENT   //////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      const [addComment, setAddComment] = React.useState('false');

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  ON TOGGLE - COMMENT FORM   /////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      const onToggleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
          const toggleValue = e.target.value
  
          if (toggleValue === 'true') { setAddComment('false') }
          if (toggleValue === 'false') { setAddComment('true') }
          
     }

     const onDelete = () => {
        dispatch(deleteTask([taskId!]))
        .unwrap()
        .then(() => {
            toast.success(t("task:taskDeleted"));
            navigate("/task")
        })
        .catch(() => {
            toast.error(t("task:failedToDeletetask"))
        })
    }

    const [modalIsOpen, setModalIsOpen] = useState(false)

    const onDeleteClick = (e: any) => {
        setModalIsOpen(!modalIsOpen)
        console.log(modalIsOpen)
    }



    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  STATUS CHECK   /////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      if (status === Status.Pending && task?.title != '' && task?.description != '') {
        return <Spinner/>
    }

  return (

    <>

    {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}
    {/* ///////////////////////////////////    LIVE UPDATE SECTION     ////////////////////////////////////////// */}
    {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}

    <div className="bg-white py-5 rounded-lg #drop-shadow-xl #shadow-lg relative px-9 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] mx-5  ">
        <div className="grid grid-cols-3 gap-3">
            <div className='-my-1 col-span-1 justify-self-center self-center'>
                <img className="h-36 w-34 bg-indigo-400 shadow-xl rounded-xl"
                        src="https://www.pngmart.com/files/22/Robot-Arm-PNG-Free-Download.png" alt=""/>
            </div>
            <div className='col-span2 justify-start justify-self-start'>
                <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)} onKeyDown={(e) => onKeyDown(e)} title={t("translation:name")} value={title} id={"title"} name={"title"}/>
                <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)} onKeyDown={(e) => onKeyDown(e)} title={t("task:description")} value={description} id={"description"} name={"description"}/>
                <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)} onKeyDown={(e) => onKeyDown(e)} title={t("task:deadline")} value={deadline} id={"deadline"} name={"deadline"}/>
                {/* <InlineEditDropDown onChange={(e) => onChange(e)} onBlur={(e) => onBlur(e)} onKeyDown={(e) => onKeyDown(e)} selections={liveStockSelections}
                            value={liveStock} id={"liveStock"} title={"Reference Item"} name={"liveStock"}/> */}
                <InlineEditDropDown onChange={(e) => handleChange(e)} onKeyDown={(e) => onKeyDown(e)} selections={employeeSelections} 
                            value={assignedTo} id={"assignedTo"} title={"Task Owner"} name={"assignedTo"}/>

            </div>
        </div>
        <FormSelectionRangeInput
            title="Status"
            range={<input onChange={(e) => onChange(e)}
            onBlur={(e) => onBlur(e)} onKeyDown={(e) => onKeyDown(e)}
            name="taskStatus" id="taskStatus" type="range" min="0" max="100" value={taskStatus} className="range range-sm range-primary" step="25" />}>
                <span><h1 className={`text-base  ${taskStatus === "0" ? "text-primary text-2xl" : "text-gray-400" }`}>created</h1></span>
                <span><h1 className={`text-base  ${taskStatus === "25" ? "text-primary text-2xl" : "text-gray-400" }`} >in progress</h1></span>
                <span><h1 className={`text-base  ${taskStatus === "50" ? "text-primary text-2xl" : "text-gray-400" }`} >completed</h1></span>
                <span><h1 className={`text-base  ${taskStatus === "75" ? "text-primary text-2xl" : "text-gray-400" }`}  >review</h1></span>
                <span><h1 className={`text-base  ${taskStatus === "100" ? "text-primary text-2xl" : "text-gray-400" }`}  >closed</h1></span>
        </FormSelectionRangeInput>
        {/* ////////////////////////////////////////////////////////////////////// */}
        {/* //////////////////////////// DELETE BUTTON /////////////////////////// */}

        <ItemPageDeleteButton onDeleteClick={onDeleteClick} />
    </div>

        {/* ////////////////////////////////////////////////////////////////////// */}
        {/* //////////////////////////// DELETE MODAL //////////////////////////// */}

        <DeleteModal modalIsOpen={modalIsOpen} name={task?.title!} onDeleteClick={onDeleteClick} onDelete={onDelete} />

    {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}
    {/* ///////////////////////////////////    TASK OWNER DISPLAY      ////////////////////////////////////////// */}
    {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}

    <div className="bg-white mt-10 py-5 rounded-lg #drop-shadow-xl #shadow-lg relative px-9 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] mx-5  ">
        <div className='flex flex-col' >
            <h1>TASK OWNER</h1>
            <h1>{assignedToObject.name}</h1>
            <h1>{assignedToObject.email}</h1>
            <h1>{assignedToObject.phone}</h1>
            <h1>{assignedToObject.job_title}</h1>
        </div>
    </div>

    {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}
    {/* ///////////////////////////////////    TASK CONNECTION DISPLAY ////////////////////////////////////////// */}
    {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}

    <TaskConnectionsDisplay task={task} />

    {/* ////////////////////////////////////////////////////////////////////// */}
    {/* //////////////////////////// ACTIVITY LOG //////////////////////////// */}
    
    <ChangeActivityList allActivity={allActivity} page={'Task'} />


    {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}
    {/* ///////////////////////////////////    COMMENT SECTION FORM    ////////////////////////////////////////// */}
    {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}

    <div className='pt-4 my-8 pb-10 bg-white py-5 rounded-lg #drop-shadow-xl #shadow-lg relative px-9 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] mx-5' >
        <label className="relative inline-flex items-center cursor-pointer">
            <input type="checkbox" onChange={(e) => onToggleChange(e)} value={addComment} className="sr-only peer"/>
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <h1 className='ml-3 text-slate-500 text-md' >Add Comment</h1>
        </label>

        {addComment === 'true' ? (<>
        <CommentForm/>
        </>) : (<></>) }
    </div>

    <div className='pt-4 my-8 pb-10 bg-white py-5 rounded-lg #drop-shadow-xl #shadow-lg relative px-9 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] mx-5 ' >
        <ListOfComments/>
    </div>

            </>


 /////////////////////////////////////////////////////////////////////////////////////////////////////////
 ///////////////////////////////////    PREVIOUS UI VERSION     //////////////////////////////////////////
 /////////////////////////////////////////////////////////////////////////////////////////////////////////

//       <>
      
      
//       <div className="bg-white py-5 rounded-lg #drop-shadow-xl #shadow-lg relative px-9 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]  " >
   
//         <div className='position: absolute top-3 right-8 ' >                        
//                         <h1 className='text-md text-right font-thin text-green-500' >{`Task Priority`}</h1>
//                 </div>
      
//     <div className="grid grid-cols-3 gap-3">
//       <div className='-my-1 col-span-1 justify-self-center self-center'>
//         <img className="h-36 w-34 bg-indigo-400 shadow-xl rounded-xl" src="https://www.pngall.com/wp-content/uploads/8/Task-List.png" alt=""/>
//       </div>
//       <div className='col-span-2 justify-start justify-self-start' >
//           <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)} onKeyDown={(e) => onKeyDown(e) } title={"Name"} value={task?.title} id={"name"} name={"name"} />
//           <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)} onKeyDown={(e) => onKeyDown(e) } title={"description"} value={task?.description} id={"description"} name={"description"} />
//           <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)} onKeyDown={(e) => onKeyDown(e) } title={"owner"} value={task?.taskOwner} id={"owner"} name={"owner"} />
//           <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)} onKeyDown={(e) => onKeyDown(e) } title={"deadline"} value={task?.deadline} id={"deadline"} name={"deadline"} />
//       </div>
//       </div>
//     </div>
//       {/* PREVIOUS TASK PAGE */}
//       <div className="grid grid-cols-3 gap-4 pt-8 pb-10 " >
//         <div className=' rounded-lg shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] my-1 pb-6 py-3 col-span-3 bg-base-50 #justify-self-center #self-center'>
//         <div className='grid grid-cols-2' >
//                     <div className='col-span-1' >
//                         <h1 className='text-2xl text-left ml-5 mt-3 font-thin' >{`${task?.title}`}</h1>
//                     </div>
//                     <div className='col-span-1' >
                        
//                             <h1 className='text-md text-right mr-6 my-3 pb-3 font-thin ' >{`Edit`}</h1>
                        
//                     </div>
//                     </div>
//             <h1 className='text-lg text-left ml-5 mt-3 mb-0 font-normal' >{`Description `}</h1>
//             <h1 className='text-md text-left my-0 py-0 ml-8 font-thin' >{`${task?.description}`}</h1>
//             {/* <InlineEdit onChange={onChange} onKeyDown={onKeyDown} value={value.message} /> */}
      
//         </div>
//         <div className=' rounded-lg shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]  my-1 py-1 col-span-1 bg-white #justify-self-center #self-center'>
//             <div>
//             <div className='grid grid-cols-2' >
//                     <div className='col-span-1' >
//                         <h1 className='text-left pl-3 pt-2 ' >Owner</h1>
//                     </div>
//                     <div className='col-span-1' >
//                         <h1 className='text-md text-right mr-6 my-3 pb-3 font-thin ' >{`Edit`}</h1>
//                     </div>
//                     </div>
                
//                 <div className='grid grid-cols-4 ga-2 px-4' >
//                     <div className=' col-span-1 ' >
//                         <img className="h-14 w-14 bg-white shadow-xl rounded-xl" src="https://preview.keenthemes.com/metronic8/react/demo3/media/avatars/300-21.jpg" alt=""/>
//                     </div>
//                     <div className='col-span-3' >
//                     <h1 className='text-md my-0 font-thin text-left pl-5' >{`${task?.taskOwner}`}</h1>
//                     </div>
//                 </div>
//             </div>
            
//             <div>
//                 <h1 className='text-left pl-3 pt-8 ' >Reviewer</h1>
//                 <div className='grid grid-cols-4 ga-2 px-4' >
//                     <div className=' col-span-1 pb-3 ' >
//                         <img className="h-14 w-14 bg-white shadow-xl rounded-xl" src="https://preview.keenthemes.com/metronic8/react/demo3/media/avatars/300-23.jpg" alt=""/>
//                     </div>
//                     <div className='col-span-3' >
//                     <h1 className='text-md my-0 font-thin text-left pl-5' >{`${task?.taskReviewer}`}</h1>
//                     </div>
//                 </div>
//             </div>
//         </div>
        
//         <div className=' rounded-lg shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]  my-1 py-1 col-span-1 bg-white #justify-self-center #self-center'>
//         <div>
//         <div className='grid grid-cols-2' >
//                     <div className='col-span-1' >
//                         <h1 className='text-left pl-3 pt-2 ' >Crop</h1>
//                     </div>
//                     <div className='col-span-1' >
//                         <h1 className='text-md text-right mr-6 my-3 pb-3 font-thin ' >{`Edit`}</h1>
//                     </div>
//                     </div>
//                 <div className='grid grid-cols-4 ga-2 px-4' >
//                     <div className=' col-span-1 ' >
//                         <img className="h-14 w-14 bg-white shadow-xl rounded-xl" src="https://png.pngtree.com/png-vector/20190411/ourlarge/pngtree-vector-apple-icon-png-image_924774.jpg" alt=""/>
//                     </div>
//                     <div className='col-span-3' >
//                         <h1 className='text-md my-0 font-thin text-left pl-5' >{`${task?.taskCrop}`}</h1>
//                     </div>
//                 </div>
//             </div>
            
//             <div>
//                 <h1 className='text-left pl-3 pt-8 ' >Type</h1>
//                 <div className='grid grid-cols-4 ga-2 px-4' >
//                     <div className=' col-span-1 pb-3 ' >
//                         <img className="h-14 w-14 bg-white shadow-xl rounded-xl" src="https://cdn1.iconfinder.com/data/icons/fintech-app-1/64/13-512.png" alt=""/>
//                     </div>
//                     <div className='col-span-3' >
//                     <h1 className='text-md my-0 font-thin text-left pl-5' >{`${task?.taskType}`}</h1>
//                     </div>
//                 </div>
//             </div>   
//         </div>
//         <div className=' rounded-lg shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]  my-1 py-1 col-span-1 bg-white #justify-self-center #self-center'>
//         <div className='grid grid-cols-2' >
//                     <div className='col-span-1' >
//                         <h1 className='text-lg text-left ml-5 mb-3 pt-2 font-normal' >{`Progress`}</h1>
//                     </div>
//                     <div className='col-span-1' >
//                         <h1 className='text-md text-right mr-6 my-3 pb-3 font-thin ' >{`Edit`}</h1>
//                     </div>
//                     </div>
//             {/* <div className="flex px-8 pb-5" >
//               <progress className="progress progress-primary bg-gray-300 h-2 mr-5 mt-3" value={task?.status} max="100"></progress>
//               <h1 className="text-xl" >{task?.status}%</h1>
//             </div> */}
//             <h1 className='text-lg text-left ml-5 my-0 font-normal' >{`Deadline`}</h1>
//             <h1 className='text-md text-left my-0 py-0 ml-8 font-thin' >{`${task?.deadline}`}</h1>
//         </div>
//         <div className=' rounded-lg shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] my-2 py-5 col-span-3 bg-white #justify-self-center #self-center'>
//             <div className='' >
//                 <div className='grid grid-cols-2' >
//                     <div className='col-span-1' >
//                         <h1 className='text-xl text-left ml-6 my-3 pb-3 font-semibold ' >{`Activity`}</h1>
//                     </div>
//                     <div className='col-span-1' >
//                         <h1 className='text-md text-right mr-6 my-3 pb-3 font-thin ' >{`Edit`}</h1>
//                     </div>
//                 </div>
//                 <div className='grid grid-cols-8 pt-1'>
//                     <div className='col-span-2'>
//                         <h1 className='text-md text-center ml-3 my-0 font-bold ' >{`Oct 15 3:15pm`}</h1>
//                     </div>
//                     <div className='col-span-1'>
//                         <h1 className='text-lg text-left ml-5 my-0 font-bold text-indigo-500' >{`O`}</h1>
//                     </div>
//                     <div className='col-span-5 text-left' >
//                         Changed Item
//                     </div>
//                 </div>
//                 <div className='grid grid-cols-8 pt-1' >
//                     <div className='col-span-2'>
//                         <h1 className='text-md text-center ml-3 my-0 font-bold ' >{`Oct 12 5:58pm`}</h1>
//                     </div>
//                     <div className='col-span-1'>
//                         <h1 className='text-lg text-left ml-5 my-0 font-bold text-indigo-500' >{`O`}</h1>
//                     </div>
//                     <div className='col-span-5 text-left' >
//                         Changed Item #2
//                     </div>
//                 </div>
//             </div>
//         </div>
//       </div>
// <div className="flex flex-row justify-center">
//     <div className="card w-96 bg-base-100 shadow-xl ">
//         <div className="card-body">
//             <h2 className="card-title justify-center">{task?.title}</h2>
//              <p>{task?.title}</p>
//             <div className="card-actions justify-center">
//     </div>
//   </div>
// </div>
// </div>
//     </>
  )
}
export default TaskPage