import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import joinTeamService from "./joinTeamService";
import {FixMeLater} from "../../types/dev";
import { JoinTeamState } from "../../types/states";
import HandleAxiosError from "../../utils/AxiosErrorHandler";
import {DefaultThunkApiConfig, Status} from "../../types/slice";
import { JoinTeamModel } from "@asirisos/types";

const CONTEXT = "JoinTeam"

const initialState: JoinTeamState = {
    joinTeam: undefined,
    joinTeams: [],
    status: Status.Unknown,
    message: ''
}

export const addJoinTeam = createAsyncThunk<any, JoinTeamModel, DefaultThunkApiConfig>('joinTeam/add', async (joinTeamData, thunkAPI) => {
    try {

        const response = await joinTeamService.addJoinTeam(joinTeamData)
        if (response.status !== 201)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const joinTeamSlice = createSlice({
    name: 'joinTeam',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(addJoinTeam.pending, (state: JoinTeamState) => {
                state.status = Status.Pending
            })
            .addCase(addJoinTeam.fulfilled, (state: JoinTeamState) => {
                state.status = Status.Success
            })
            .addCase(addJoinTeam.rejected, (state: JoinTeamState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
    }
})

export const {reset} = joinTeamSlice.actions
export default joinTeamSlice.reducer