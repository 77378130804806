import React, {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import { getCategoryTasks } from '../../../features/task/taskSlice';
import Spinner from '../../components/Spinner'
import {DataGrid, GridColumns} from '@mui/x-data-grid';
import {Link} from 'react-router-dom'
import {AppDispatch, RootState} from "../../../app/store";
import {Status} from "../../../types/slice";

const actionColumn : GridColumns = [
    {
        field: 'action',
        headerName: "Action",
        width: 200,
        renderCell: (params) => {
            return (
                <div className='flex flex-row px-2 pl-4 justify-center items-center gap-2' >
                    <Link to={`/task/${params.row._id}`} className="border-2 rounded-lg border-blue-500 px-2">View</Link>
                    <div className='border-2 rounded-lg border-red-400 px-2' >Delete</div>
                </div>
            )
        }
    }
]

const columns : GridColumns = [
  { field: '_id', headerName: 'ID', width: 70 },
  {
    field: 'img',
    headerName: 'Task',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    renderCell:(params)=> {
        return(
            
                <div className='flex flex-row items-center' >
                    <img className=' rounded-xl  w-9 h-9 mr-4'  src="https://pngimage.net/wp-content/uploads/2018/06/prepare-icon-png-4.png" alt="" />
                    <div className='text-indigo' >
                    {params.row.taskName}
                    </div>
                </div>
            
        )
    }
  },
  { field: 'deadline', headerName: 'Deadline', width: 120 },
  { field: 'status', headerName: 'status', width: 120 },
  { field: 'taskType', headerName: 'Category', width: 120 },
  { field: 'taskOwner', headerName: 'Owner', width: 120 },
  { field: 'taskCrop', headerName: 'Crop', width: 120 },
];




function LiveStockTasks() {
    const {tasks, status} = useSelector((state : RootState) => state.taskTask )
    const dispatch = useDispatch<AppDispatch>()

    const taskCategory = { category: 'liveStock'}

    useEffect(() => {
        dispatch(getCategoryTasks(taskCategory))
    },[dispatch] )

    if (status === Status.Pending && tasks.length === 0) {
        return<Spinner/>
    }
  return (
    <>
      
      <div style={{ height: 400, width: 'full' }}>
      <DataGrid
        getRowId={(row) => row._id}
        rows={tasks}
        columns={columns.concat(actionColumn)}
        pageSize={10}
        rowsPerPageOptions={[5]}
        checkboxSelection
      />
    </div>
   
    </>
  )
}

export default LiveStockTasks