import React, {ChangeEventHandler, FocusEventHandler, KeyboardEventHandler} from 'react'

type InlineEditDropDownProps = {
    value : string,
    onChange : ChangeEventHandler<any>,
    name : string,
    id : string | undefined,
    onKeyDown : KeyboardEventHandler<any>,
    // onBlur : FocusEventHandler,
    title : string,
    selections : Array<InlineEditDropDownSelection>
}

type InlineEditDropDownSelection ={
    name: string, id: string | undefined
}

function InlineEditDropDown({value, onChange, name, id, onKeyDown, title, selections} : InlineEditDropDownProps) {
    return (
        <>
        <div className="mt-10 relative rounded-xl shadow-md border-2">
        <label className="absolute left-2 insert-y-0 bottom-14 text-slate-500 text-sm">{title}</label>

        {/*************************************   MAIN INPUT FIELD       **************************************/}
        
            
          <select 
            className="select select-bordered w-full max-w-xs focus:ring-indigo-500 focus:border-indigo-500 pl-9 bg-white pt-4 pb-4 sm:text-sm  border-none rounded-xl"
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
            // onBlur={onBlur}
            >
            
                {selections.map((selection => {
                    return (
                        <option value={selection.id}>{selection.name}</option>
                    )
                }))}
          </select>
          </div>
          
      


      </>
    )
  }

export default InlineEditDropDown