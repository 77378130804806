import React from 'react'

function AboutFarmersMarket() {
  return (
    <>
    {/* LARGE ABOUT TEXT FILLED IN BY THE USER */}
    <div>
        <h1>The title of the about text will go here</h1>
        <p>About text will go here. Share more information about the market, its origin, and history</p>
    </div>
    </>
  )
}

export default AboutFarmersMarket