import React, {ChangeEventHandler, FocusEventHandler, KeyboardEventHandler} from 'react'
// import {FixMeLater} from "../../../types/dev";

type SecondLevelCardProps = {
    title?: string,
    icon?: string,
    text?: string,
    carouselId?: string 
}


function SecondLevelCard({title, icon, text, carouselId} : SecondLevelCardProps ) {
    return (
    <div id={carouselId} className='carousel-item w-5/6 pl-2' >    
        <div className='text-[#16341fec] bg-[#ffffff] w-11/12 drop-shadow-xl pt-2'>
            <div className='flex justify-center'>
                <img className='rounded-t-lg w-20 py-2' src={icon} alt="" />
            </div>

            <div className='flex flex-col '>
                <div className=' pt-2 '>
                    <h1 className='text-xl text-center font-bold'>{title}</h1>
                </div>
                <hr/>
                <div className='self-center  pb-1 rounded-b-lg px-2'>
                    <h1 className='pt-2 text-md font-light text-left' >{text}</h1>
                </div>
            </div>
        </div>
    </div>


    )
  }

  export default SecondLevelCard