import React from 'react'
import {Link} from 'react-router-dom'

function SetUpStart() {
  return (
    <>
    <div className='content-center' >
        <div className='flex justify-center'>    
        <div className='flex flex-row justify-between items-center rounded-lg drop-shadow-xl mt-24 py-8 px-12 bg-slate-200 min-w-md max-w-lg ' >
            <div>
            <Link className="btn mb-4 mr-6" to={"/select-org"} >
                Create Account
            </Link>
            </div>
            <div>
            <Link className="btn ml-6 mb-4" to={"/claim-account"} >
                Claim Account
            </Link>
            </div>
        </div>
        </div>  
    </div>
    </>
  )
}

export default SetUpStart