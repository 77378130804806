import React, {useState} from 'react'
import Modal from 'react-modal'
import {Draggable} from 'react-beautiful-dnd'
import {KanbanModel} from "@asirisos/types";

Modal.setAppElement('#root')
type TaskProps = {
  task: KanbanModel,
  index: number
}

function Task(props: TaskProps) {

  ///  Implementing Modal for Add Column ///////////////

  const [modalIsOpen, setModalIsOpen] = useState(false)

  // Open Modal
const openModal = () => setModalIsOpen(true)
const closeModal = () => setModalIsOpen(false)


  return (
  <>
  {/* <Draggable draggableId={props.task._id} index={props.index} >
      {(provided, snapshot) => (
          <div onClick={openModal}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className={`p-1 px-3 relative pb-8 py-2 mb-4 shadow-sm max-w-md rounded-lg text-black ${snapshot.isDragging ? "bg-indigo-300" : "bg-white"}`} >
          {props.task.title}
          <div className="absolute bottom-0 right-2 text-gray-400 ">
            Karen
          </div>
          <div className="absolute bottom-0 left-2 text-green-400 ">
            apples
          </div>
          </div>
      ) }
      
  </Draggable> */}
  {/*<Modal task={task} className="p-15 border-none bg-white flex flex-row justify-around" isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Add Column">*/}
  {/*  <div>*/}
  {/*              <h1 className=" p-10 "onClick={closeModal}>Close</h1>*/}
  {/*              <form >*/}
  {/*                  <h1>This is {task.title} Card </h1>*/}
  {/*                  <h1>This is {task.title} Card </h1>*/}
  {/*          <input */}
  {/*          type="title" */}
  {/*          placeholder="title" */}
  {/*          id="title"*/}
  {/*          name="title"*/}
  {/*          // value={title}*/}
  {/*          // onChange={onChange}*/}
  {/*          className="input input-bordered w-full max-w-xs"/>*/}
  {/*          <button className="btn mt-5 m-3 btn-wide btn-primar hover:bg-black">Add Task</button>*/}
  {/*          <button className="btn mt-5 m-3 btn-wide btn-primar hover:bg-black">Delete Task</button>*/}
  {/*          </form>*/}
  {/*          </div>*/}
  {/*          </Modal>*/}
  </>  
  )
}

export default Task