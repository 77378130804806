import React, {useState, ChangeEvent, FormEvent} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useNavigate, useLocation} from 'react-router-dom'
import {AppDispatch, RootState} from "../../../app/store";
import NumberInputInUnits from '../../components/shared/NumberInputInUnits'
import {useTranslation} from "react-i18next";
import { emailSignUp } from 'features/auth/authSlice';
import {Link} from 'react-router-dom'
import FormCard from 'UI/components/shared/FormCard';

const animation = require ('./asiris_animation.gif')
const farmCloud = require ('./farm_cloud.png')
const marketplaces = require ('./marketplaces.png')
const singleDataSource = require('./single_data_source.png')

function ThankYouForSignUp() {

    const {t} = useTranslation("organization")



    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()



  return (
    <>
{/********************************************/}
{/********************************************/}
{/********************************************/}
{/********************* MOBILE VERSION ***********************/}
{/********************************************/}
{/********************************************/}
{/********************************************/}
    

    <div className='lg:hidden sm:visible h-full justify-around flex flex-col bg-slate-200' >

        <div className='items-start justify-self-start flex flex-col px-3 pt-3 pb-16'>
            <h1 className='text-start font-bold text-5xl pt-1 text-slate-500'><a className="underline text-indigo-500 decoration-green-500">Thank you!</a> for signing up</h1>
            <h1 className='text-start font-bold text-5xl pt-28 text-slate-500'>We will reach out to you <a className="underline text-indigo-500 decoration-green-500">ASAP</a></h1>
        </div>
    </div>



{/********************************************/}
{/********************************************/}
{/********************************************/}
{/********************* DESKTOP VERSION ***********************/}
{/********************************************/}
{/********************************************/}
{/********************************************/}


    <div className='lg:visible invisible h-full bg-slate-200 flex flex-col justify-center items-center' >
            <h1 className='text-start font-bold text-5xl pt-1 text-slate-500'><a className="underline text-indigo-500 decoration-green-500">Thank you!</a> for signing up</h1>
            <h1 className='text-start font-bold text-5xl pt-24 text-slate-500'>We will reach out to you <a className="underline text-indigo-500 decoration-green-500">ASAP</a></h1>
    </div>
        
        
    </>
  )
}

export default ThankYouForSignUp