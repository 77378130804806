import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import liveStockService from "./live-stockService";
import {LiveStockState} from "../../types/states";
import HandleAxiosError from "../../utils/AxiosErrorHandler";
import {DefaultThunkApiConfig, Status} from "../../types/slice";
import {LiveStockModel} from "@asirisos/types";
import {LiveStockWeightData} from "@asirisos/types/data";

const CONTEXT = "Live stock"

const initialState: LiveStockState = {
    liveStock: undefined,
    allLiveStock: [],
    status: Status.Unknown
}

export const addLiveStockWeight = createAsyncThunk<LiveStockModel, LiveStockWeightData, DefaultThunkApiConfig>('LiveStock/addLiveStockWeight', async (liveStockWeightData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account
        if (!account)
            return thunkAPI.rejectWithValue()


        const response = await liveStockService.addLiveStockWeight(liveStockWeightData, account)
        // console.log('Live Stock Slice - Add Weight - response', response)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        // console.log('Live Stock Slice - Add Weight - catch error', error)
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const addLiveStock = createAsyncThunk<LiveStockModel, LiveStockModel, DefaultThunkApiConfig>('LiveStock/addLiveStock', async (liveStockData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account
        if (!account)
            return thunkAPI.rejectWithValue()

        const response = await liveStockService.addLiveStock(liveStockData, account)
        if (response.status !== 201)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getAllLiveStock = createAsyncThunk<LiveStockModel[], void, DefaultThunkApiConfig>('LiveStock/getAllLiveStock', async (_, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account
        console.log('live-stock feature slice - get all livestock account - account', account)
        if (!account)
            return thunkAPI.rejectWithValue()

        const response = await liveStockService.getAllLiveStock(account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getLiveStock = createAsyncThunk<LiveStockModel, string, DefaultThunkApiConfig>('LiveStock/getLiveStock/:liveStockId', async (liveStockId: string, thunkAPI) => {
    try {
        const response = await liveStockService.getLiveStock(liveStockId)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const updateLiveStock = createAsyncThunk<LiveStockModel, LiveStockModel, DefaultThunkApiConfig>('LiveStock/updateLiveStock/:liveStockId', async (liveStockData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await liveStockService.updateLiveStock(liveStockData, account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const deleteLiveStock = createAsyncThunk<any, string[], DefaultThunkApiConfig>('LiveStock/deleteLiveStock/:liveStockId', async (liveStockId, thunkAPI) => {
    try {
        const response = await liveStockService.deleteLiveStock(liveStockId)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const liveStockSlice = createSlice({
    name: 'livestock',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(addLiveStockWeight.fulfilled, (state, action: PayloadAction<LiveStockModel, any, any>) => {
                state.status = Status.Success;
                state.liveStock = action.payload
            } )
            .addCase(addLiveStock.pending, (state) => {
                state.status = Status.Pending;
            })
            .addCase(addLiveStock.fulfilled, (state) => {
                state.status = Status.Success;
            })
            .addCase(addLiveStock.rejected, (state) => {
                state.status = Status.Failure;
            })
            .addCase(getAllLiveStock.pending, (state) => {
                state.status = Status.Pending;
            })
            .addCase(getAllLiveStock.fulfilled, (state, action: PayloadAction<LiveStockModel[], any, any>) => {
                state.status = Status.Success;
                state.allLiveStock = action.payload
            })
            .addCase(getAllLiveStock.rejected, (state) => {
                state.status = Status.Failure;
            })
            .addCase(getLiveStock.pending, (state) => {
                state.status = Status.Pending;
            })
            .addCase(getLiveStock.fulfilled, (state, action: PayloadAction<LiveStockModel, any, any>) => {
                state.status = Status.Success;
                state.liveStock = action.payload
            })
            .addCase(getLiveStock.rejected, (state) => {
                state.status = Status.Failure;
            })
            .addCase(updateLiveStock.fulfilled, (state, action: PayloadAction<LiveStockModel, any, any>) => {
                state.status = Status.Success
                state.allLiveStock.map((liveStock) => liveStock._id === action.payload._id ? (state.liveStock = liveStock) : liveStock)
            })
            .addCase(deleteLiveStock.fulfilled, (state) => {
                state.status = Status.Success
                state.liveStock = undefined
            })
    }
})

export const {reset} = liveStockSlice.actions
export default liveStockSlice.reducer