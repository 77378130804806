import React, { useCallback, useEffect, useState } from "react";
import { PieChart, Pie, Sector } from "recharts";
import {useDispatch, useSelector} from 'react-redux'
import { getAllLiveStock } from 'features/live-stock/live-stockSlice'
import {toast} from 'react-toastify'
import Spinner from '../../components/Spinner'
import {useParams, useNavigate} from 'react-router-dom'
import {AppDispatch, RootState} from "../../../app/store";
import { Status } from "types/slice";
import { useTranslation } from "react-i18next";

const renderActiveShape = (props: any) => {


  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.category}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

export default function TotalPieChart() {

  const {t} = useTranslation("livestock");

    const {task, status} = useSelector((state : RootState) => state.taskTask)
    const {allLiveStock} = useSelector((state : RootState) => state.livestock)

    const params = useParams()
    const {taskId} = useParams()
    const dispatch = useDispatch<AppDispatch>()
    const naviagate = useNavigate()

    useEffect(() => {
      if (status === Status.Failure) {
        toast.error(t("task:taskGetFailed"))
    }
        if (taskId !== undefined)
            dispatch(getAllLiveStock())
    },[dispatch, taskId] )

    console.log('TotalPieChart - allLiveStock', allLiveStock)

    const countUnique = function (arr : any = []) {
        let res : any = [];
        console.log('arr', arr, typeof(typeof(arr)))
        res = arr?.reduce( (r :any , o: any) => {
        //    let category = o.category;
           (r[o.category])? ++r[o.category] : r[o.category] = 1;
           return r
        }, {})
        res = Object.keys(res).map(function (k) {
           return {"category": k, "count": res[k]};
        });
        return res;
     }
     console.log('counting',countUnique(allLiveStock));
     const data = countUnique(allLiveStock)
     console.log('data', data)


  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_: any, index: any) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  // if (isLoading && data.length !== 0) {
  if (status === Status.Pending && allLiveStock.length === 0 && typeof(allLiveStock) == 'object' && data === 0) {
    <Spinner/>
}

  return (
    <PieChart className="mx-18 pt-0 mt-0" width={460} height={300}>
      <Pie
        activeIndex={activeIndex}
        activeShape={renderActiveShape}
        data={data}
        cx={230}
        cy={150}
        innerRadius={60}
        outerRadius={80}
        fill="#8884d8"
        dataKey="count"
        onMouseEnter={onPieEnter}
      />
    </PieChart>
  );
}
