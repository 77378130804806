import {createSlice} from "@reduxjs/toolkit";
import {UtilsState} from "../../types/states";
import {PageId} from "../../types/pages";

export type SelectedTab = {
    page: PageId;
    tabId: number;
}

const initialState: UtilsState  = {
    selectedTab: {page: PageId.Unknown, tabId: 1}
}

export const utilsSlice = createSlice({
    name: 'utils',
    initialState,
    reducers: {
        reset: () => initialState,
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload
        },
    },
})

export const {reset} = utilsSlice.actions
export default utilsSlice.reducer