import { Link } from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import { getTasks, reset } from '../../../features/task/taskSlice'
import Spinner from '../../components/Spinner'
import TasksTable from './TaskTable'
import {AppDispatch, RootState} from "../../../app/store";
import { Status } from 'types/slice'
import TaskCategoryPieChart from './TaskCategoryPieChart'
import TaskStatusPieChart from './TaskStatusPieChart'
import TaskKanBan from './TaskKanBan'
import TaskActivity from './TaskActivity'



function Task() {

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////    USE SELECTOR   /////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const {user, isError, isSuccess} = useSelector((state : RootState) => state.auth)
const {tasks, status} = useSelector((state : RootState) => state.taskTask )

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////    Initiate Hooks   /////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const dispatch = useDispatch<AppDispatch>()

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////    USE EFFECT   /////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

useEffect(() => {
  dispatch(getTasks())
  if (isSuccess) {
    dispatch(reset())
  }
},[dispatch] )


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////    FILTER BY TASK OWNER - ASSIGNED TO OBJECT NOW   //////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

let FindTasks = tasks.filter(task => task.assignedToObject?.name === "Osman")
console.log("Find Tasks", FindTasks)

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////    FILTER BY TASK STATUS   //////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const tasks0 = tasks.filter((task) => task.taskStatus === '0' )
const tasks25 = tasks.filter((task) => task.taskStatus === '25' )
const tasks50 = tasks.filter((task) => task.taskStatus === '50' )
const tasks75 = tasks.filter((task) => task.taskStatus === '75' )
const tasks100 = tasks.filter((task) => task.taskStatus === '100' )

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////  TOGGLE PAGE - PAGE TABS   /////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const [toggleState, setToggleState] = useState(1)

const toggleTab = (index: any) => {
  setToggleState(index)
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////  STATUS CHECK   /////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

if(status === Status.Pending) {
  return <Spinner/>
}
return (
  <>
  <div className='px-5'>

  {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}
  {/* ///////////////////////////////////    TOGGLE PAGE TABS     ///////////////////////////////////////////// */}
  {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}

  <div className='pb-8 mb-6 rounded-lg bg-slate-100 drop-shadow-lg'>
    <ul className="flex felx-row justify-left cursor-pointer">
      <li onClick={() => toggleTab(1)}className={`rounded-t-lg px-12 py-2 ${toggleState === 1 ? 'bg-indigo-500 text-white' : ''}`}>Home</li>
      <li onClick={() => toggleTab(2)} className={`rounded-t-lg px-12 py-2 ${toggleState === 2 ? 'bg-indigo-500 text-white' : ''}`}>Tasks Table</li>
      <li onClick={() => toggleTab(3)} className={`rounded-t-lg px-12 py-2 ${toggleState === 3 ? 'bg-indigo-500 text-white' : ''}`}>Kan Ban</li>
      <li onClick={() => toggleTab(4)} className={`rounded-t-lg px-12 py-2 ${toggleState === 4 ? 'bg-indigo-500 text-white' : ''}`}>Activity</li>
      <li onClick={() => toggleTab(5)} className={`rounded-t-lg px-12 py-2 ${toggleState === 5 ? 'bg-indigo-500 text-white' : ''}`}>Backlog</li>
      <li onClick={() => toggleTab(6)} className={`rounded-t-lg px-12 py-2 ${toggleState === 6 ? 'bg-indigo-500 text-white' : ''}`}>Archive</li>
    </ul>

    {/* ///////////////////////////////////    TAB 1 - STATS     ///////////////////////////////////////////// */}
    {toggleState === 1 ? (
      <div className='flex flex-row justify-around bg-white drop-shadow-lg rounded-tl-none py-8' >
        <div className=' bg-slate-200 rounded-lg px-10 py-4 shadow-[2px_1px_15px_2px_rgba(201,201,201,0.47)] ' >
          <h1 className='text-xl '>Tasks By Category</h1>
          <TaskCategoryPieChart/>
        </div>

        <div className=' bg-slate-200 rounded-lg px-10 py-4 shadow-[2px_1px_15px_2px_rgba(201,201,201,0.47)] ' >
          <h1 className='text-xl '>Tasks By Status</h1>
          <TaskStatusPieChart/>
        </div>
      </div>
    ) : (<></>)}

    {/* ///////////////////////////////////    TAB 2 - TABLE     ///////////////////////////////////////////// */}

    {toggleState === 2 ? (
        <div className='bg-white drop-shadow-lg rounded-tl-none py-8' >
          <TasksTable/>
        </div>
    ) : (<></>)}

    {/* ///////////////////////////////////    TAB 3 - KANBAN     ///////////////////////////////////////////// */}

    {toggleState === 3 ? (
      <TaskKanBan/>
    ) : (<></>)}

    {/* ///////////////////////////////////    TAB 4 - TASK ACTIVITY     ///////////////////////////////////////////// */}

    {toggleState === 4 ? (
      <div className='bg-white drop-shadow-lg rounded-tl-none py-8' >
        <TaskActivity />
        <Link className="btn mx-9 mt-6" to={"/activity-form"} >
          Add Activity
        </Link>
      </div>
    ) : (<></>)}

    {/* ///////////////////////////////////    TAB 5 - TASK BACKLOG     ///////////////////////////////////////////// */}

    {toggleState === 5 ? (

      <div className='bg-white drop-shadow-lg rounded-tl-none py-8' >
        Task Backlog
        <div className='border-2 border-slate-100 mx-4'>
          {tasks0.map((task) => (
            <>
            <div className=''  >
              <Link to={`/task/${task._id}`} className="bg-white border-2 border-b-0 hover:shadow-[2px_1px_15px_2px_rgba(201,201,201,0.47)] border-slate-200 drop-shadow-sm pt-3 flex flex-col items-start pl-3 pb-2" >
                  <h4 className="text-xl" >{task.title}</h4>
                  <h6 className=' text-gray-500 pt-1 ' >{task.description}</h6>
                  <div>
                    Task Item
                    {task.assignedToObject?.name}
                  </div>  
                  <div>
                    {task.assignedToObject?.name}
                  </div>
                  <div className='absolute bottom-0 right-0 pr-2 pb-2 text-sm text-gray-400' >
                    {task.deadline}
                  </div>
                  <div className='absolute top-0 right-0 pr-2 text-sm pt-4' >
                    
                  </div>
              </Link>
            </div>
            </>
              ) )}
        </div>
      </div>
    ) : (<></>)}

    {/* ///////////////////////////////////    TAB 6 - TASK ARCHIVE     ///////////////////////////////////////////// */}

    {toggleState === 6 ? (
      <div className='bg-white drop-shadow-lg rounded-tl-none py-8' >
        Archive
        <div className='border-2 border-slate-100 mx-4'>
          {tasks0.map((task) => (
            <>
            <div className=''  >
              <Link to={`/task/${task._id}`} className="bg-white border-2 border-b-0 hover:shadow-[2px_1px_15px_2px_rgba(201,201,201,0.47)] border-slate-200 drop-shadow-sm pt-3 flex flex-col items-start pl-3 pb-2" >
                <h4 className="text-xl" >{task.title}</h4>
                <h6 className=' text-gray-500 pt-1 ' >{task.description}</h6>
                <div>
                  Task Item
                  {task.assignedToObject?.name}
                </div>  
                <div>
                  {task.assignedToObject?.name}
                </div>
                <div className='absolute bottom-0 right-0 pr-2 pb-2 text-sm text-gray-400' >
                  {task.deadline}
                </div>
                <div className='absolute top-0 right-0 pr-2 text-sm pt-4' >
                </div>
              </Link>
            </div>
            </>
          ) )}
        </div>
      </div>
      ) : (<></>)}
          
  </div>

  {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}
  {/* ///////////////////////////////////    ADD TASK BUTTON     ///////////////////////////////////////////// */}
  {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}

  <div className='' >
    <Link className="btn ml-2" to={"/task-form"} >
      Add Task
    </Link>
  </div>
  </div>
  </>
  )
}

export default Task

///////////////////////
///// TASK CARDS //////
///////////////////////

// {tasks.map((task) => (
//   <>
//           <Link to={`/task/${task._id}`} className="bg-white  shadow-[2px_1px_15px_2px_rgba(201,201,201,0.47)] rounded-xl drop-shadow-sm pt-3 flex flex-col items-start pl-3 pb-2" >
//               <h4 className="text-xl" >{task.title}</h4>
//               <h6 className=' text-gray-500 pt-1 ' >{task.description}</h6>
//               <div>
//                 {task.taskCrop}
//               </div>  
//               <div className=''>
                
//               </div>
//               <div>
//                 {task.taskOwner}
//               </div>
//               <div className='absolute bottom-0 right-0 pr-2 pb-2 text-sm text-gray-400' >
//                 {task.deadline}
//               </div>
//               <div className='absolute top-0 right-0 pr-2 text-sm pt-4' >
//                 {task.status === '0' ? (
//                   <>
//                     <div className='text-red-500'>
//                       created
//                     </div>
//                   </>
//                 ) : <></> }
//                 {task.status === '25' ? (
//                   <>
//                     <div className='text-orange-500' >
//                       in progress
//                     </div>
//                   </>
//                 ) : <></> }
//                 {task.status === '50' ? (
//                   <>
//                     <div className='text-green-600' >
//                       completed
//                     </div>
//                   </>
//                 ) : <></> }
//                 {task.status === '75' ? (
//                   <>
//                     <div className=' text-green-600 '>
//                       review
//                     </div>
//                   </>
//                 ) : <></> }
//                 {task.status === '100' ? (
//                   <>
//                     <div>
//                       closed
//                     </div>
//                   </>
//                 ) : <></> }
                
//               </div>
//           </Link>
    
//   </>
//    ) )}
