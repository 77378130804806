import axios from "axios"
import {AccountModel, SaleModel} from "@asirisos/types";
import RequestBodyFactory from "../../factories/requestBodyFactory";

const API_URL = '/api/sales'


const addSale = async (saleData: SaleModel, accountData: AccountModel) => {
    const body = new RequestBodyFactory(saleData).WithAccount(accountData).Build()
    return await axios.post(API_URL + '/', body)
}

const getSales = async (account: AccountModel) => {
    return await axios.get(API_URL + '/all/' + account.farm._id + '/' + account.user._id)
}

const getSale = async (saleId: string) => {
    return await axios.get(API_URL + '/' + saleId)
}

const updateSale = async (saleData: SaleModel, account: AccountModel) => {
    const {_id} = saleData
    const body = new RequestBodyFactory(saleData).WithAccount(account).Build()
    return await axios.put(API_URL + '/' + _id, body)
}

const deleteSale = async (saleId: string[]) => {
    const ids = saleId
    const config = {
        data: ids
    }

    return await axios.delete(API_URL + '/' + saleId, config)
}

const saleService = {
    addSale,
    getSales,
    getSale,
    updateSale,
    deleteSale
}

export default saleService