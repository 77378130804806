import React, { useEffect, useSel } from "react";
import {useSelector, useDispatch} from 'react-redux'
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import {cities} from '../../../data/cities'

const MarkerLayer = ({data}) => {
  return data.features.map(feature => {
    const {coordinates} = feature.geometry
     return (
    <Marker key={String(coordinates)} position={[coordinates[1], coordinates[0]]} icon={icon}>
      <Popup>
          A pretty CSS3 popup. <br /> Easily customizable.
      </Popup>
    </Marker>
     )
  }
  )
} 

const icon = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});

const position = [51.505, -0.09];

function ResetCenterView(props) {
  const { selectPosition } = props;
  const map = useMap();

  useEffect(() => {
    if (selectPosition) {
      map.setView(
        L.latLng(selectPosition?.lat, selectPosition?.lon),
        map.getZoom(),
        {
          animate: true
        }
      )
    }
  }, [selectPosition]);

  return null;
}








export default function Maps(props) {
  const {farm} = useSelector((state) => state.farm )
  console.log('Searchable Map - farm selector', farm)

  const { selectPosition } = props;
  console.log('Searchable Map - select position', selectPosition)
  const locationSelection = [selectPosition?.lat, selectPosition?.lon];
  // const locationCoordinatesBackend = [farm?.lat, farm?.lon]
  // const locationCoordinatesBackendObject = {lat: farm?.lat, lon: farm?.lon}

  // console.log('Searchable Map - Coordinates from BackEnd', locationCoordinatesBackend)

  return (
    <MapContainer
      center={position}
      zoom={20}
      style={{ width: "100%", height: "100%" }}
    >
      {/* <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://api.maptiler.com/maps/basic/256/{z}/{x}/{y}.png?key={YOU_KEY}"
      /> */}
       <TileLayer
            url='https://api.mapbox.com/styles/v1/purpledragons/cl9kzio97001v14pe6ahvafbd/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicHVycGxlZHJhZ29ucyIsImEiOiJjbDlqMGp3cHkwZDJsM25wa2I2dnBxMXU2In0.m0DYm_idO-8_I7OeSBuMnQ'
            maxZoom= {13}/>
      {/* <TileLayer
            url='https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
            maxZoom= {20}/> */}
      <MarkerLayer data={cities}/>
      {selectPosition && (
        <Marker position={locationSelection} icon={icon}>
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>
        </Marker>
      )}
      {/* {farm?.lat && (
        <Marker position={locationCoordinatesBackend} icon={icon}>
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>
        </Marker>
      )} */}
      <ResetCenterView selectPosition={selectPosition}  />
      {/* <ResetCenterView selectPosition={selectPosition}  /> */}
    </MapContainer>
  );
}