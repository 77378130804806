import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {deleteCustomer, getCustomers} from '../../../features/customers/customerSlice'
import Spinner from '../../components/Spinner'
import {DataGrid, GridColumns} from '@mui/x-data-grid';
import {Link, useNavigate} from 'react-router-dom'
import {AppDispatch, RootState} from "../../../app/store";
import {Status} from "../../../types/slice";
import {useTranslation} from "react-i18next";
import {toast} from 'react-toastify'
import DeleteModal from 'UI/components/DeleteModal';
import TableMultiDeleteButton from 'UI/components/TableMultiDeleteButton';

const actionColumn : GridColumns = [
    {
        field: 'action',
        headerName: "Action",
        width: 200,
        renderCell: (params) => {
            return (
                <div className='flex flex-row px-2 pl-4 justify-center items-center gap-2' >
                    <Link to={`/customer/${params.row._id}`} className="border-2 rounded-lg border-blue-500 px-2">View</Link>
                </div>
            )
        }
    }
]

const columns : GridColumns = [
  { field: '_id', headerName: 'ID', width: 70 },
  {
    field: 'img',
    headerName: 'User',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 200,
    renderCell:(params)=> {
        return(
            
                <div className='flex flex-row items-center' >
                    <img className=' rounded-xl  w-9 h-9 mr-4'  src="https://cdn-icons-png.flaticon.com/512/6009/6009864.png" alt="" />
                    <div className='text-indigo' >
                    {params.row.firstName.concat(' ').concat(params.row.lastName)}
                    </div>
                </div>
            
        )
    }
  },
  { field: 'email', headerName: 'Email', width: 150 },
  { field: 'phone', headerName: 'Phone', width: 150 },
];




function CustomerTable() {
    const {customers, status} = useSelector((state : RootState) => state.customer )
    const {t} = useTranslation(["customer", "translation"])
    const dispatch = useDispatch<AppDispatch>()


    const [modalIsOpen, setModalIsOpen] = useState(false)

const onDeleteClick = (e: any) => {
    setModalIsOpen(!modalIsOpen)
    console.log(modalIsOpen)
}

const [deleteEvent, setDeleteEvent] = useState<boolean>(true)
const [selectedRows, setSelectedRows] = useState<string[]>([])

const handleSelectionChange = (newSelectionModel : string[]) => {
    setSelectedRows(newSelectionModel)
}

const navigate = useNavigate()

const onDelete = () => {
    dispatch(deleteCustomer(selectedRows))
    .unwrap()
    .then(() => {
        toast.success(t("customer:customerDeleted"));
        navigate("/customers")
        setModalIsOpen(false)
    })
    .catch(() => {
        toast.error(t("customer:failedToDeletecustomer"))
    })
    setDeleteEvent(!deleteEvent)
    setDeleteEvent(!deleteEvent)
    setSelectedRows([])
}

    useEffect(() => {
        if (status !== Status.Pending || deleteEvent === true || deleteEvent === false)
            dispatch(getCustomers())
    }, [dispatch, deleteEvent])


  //   useEffect(() => {
  //     dispatch(getCustomers())
  // }, [dispatch])

  if (status === Status.Pending) {
    return <Spinner/>
}
  return (
    <>

        {selectedRows.length !== 0 ? (<>
        <TableMultiDeleteButton onDeleteClick={onDeleteClick} />
        </>) :(<></>) }
      
      <div style={{ height: 400, width: 'full' }}>
      <DataGrid
        getRowId={(row) => row._id}
        rows={customers}
        columns={columns.concat(actionColumn)}
        pageSize={10}
        rowsPerPageOptions={[5]}
        checkboxSelection
        onSelectionModelChange={(newSelectionModel : any) => {
          handleSelectionChange(newSelectionModel)
        }}
      />
    </div>

    {/* ////////////////////////////////////////////////////////////////////// */}
    {/* //////////////////////////// DELETE MODAL //////////////////////////// */}

    <DeleteModal modalIsOpen={modalIsOpen} onDeleteClick={onDeleteClick} onDelete={onDelete} />
   
    </>
  )
}

export default CustomerTable