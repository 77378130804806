import React, {ReactNode} from 'react'

type FormSelectionRangeInputProps =
    {
        children : ReactNode,
        title: string,
        range: ReactNode
    }

function FormSelectionRangeInput({children, title, range} : FormSelectionRangeInputProps) {

  return (
    <div className="px-9 pt-8"  >
        <h1 className="px-3 text-lg flex text-gray-500">{title}</h1>
        <div className="px-7">
            {range}
        </div>
        <div className="w-full flex justify-between text-xs">
            {children}
        </div>
    </div>
  )
}

export default FormSelectionRangeInput