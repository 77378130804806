import {createSlice, createAsyncThunk, PayloadAction} from "@reduxjs/toolkit";
import saleService from "./saleService";
import HandleAxiosError from "../../utils/AxiosErrorHandler";
import {DefaultThunkApiConfig, Status} from "../../types/slice";
import {SaleState} from "../../types/states";
import {SaleModel} from "@asirisos/types";

const CONTEXT = "Sale"

const initialState: SaleState = {
    sale: undefined,
    sales: [],
    status: Status.Unknown,
    message: ''
}


export const addSale = createAsyncThunk<any, SaleModel, DefaultThunkApiConfig>('sale/add', async (saleData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await saleService.addSale(saleData, account)
        if (response.status !== 201)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getSales = createAsyncThunk<SaleModel[], void, DefaultThunkApiConfig>('sale/getall', async (_, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await saleService.getSales(account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getSale = createAsyncThunk<SaleModel, string, DefaultThunkApiConfig>('sale/getone/:saleId', async (saleId, thunkAPI) => {
    try {
        const response = await saleService.getSale(saleId)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const updateSale = createAsyncThunk<SaleModel, SaleModel, DefaultThunkApiConfig>('sale/update/:saleid', async (saleData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if(!account)
            return thunkAPI.rejectWithValue();

        const response = await saleService.updateSale(saleData, account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const deleteSale = createAsyncThunk<any, string[], DefaultThunkApiConfig>('sale/delete', async (saleId, thunkAPI) => {
    try {
        const response = await saleService.deleteSale(saleId)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})






////////////////////////////////////////////

// export const addSale = createAsyncThunk<SaleData, FixMeLater>('Sales/addSale', async (saleData: SaleData, thunkAPI: FixMeLater) => {
//     try {
//         const token = thunkAPI.getState().auth.user.token
//         return await saleService.addSale(saleData, token)
//     } catch (error) {
//         HandleAxiosError(error, CONTEXT);
//         return thunkAPI.rejectWithValue("message")
//     }
// })

// export const getSales = createAsyncThunk<FixMeLater>('Sales/getSales', async (_, thunkAPI: FixMeLater) => {
//     try {
//         const token = thunkAPI.getState().auth.user.token
//         return await saleService.getSales(token)
//     } catch (error) {
//         HandleAxiosError(error, CONTEXT);
//         return thunkAPI.rejectWithValue("message")
//     }
// })

// export const getSale = createAsyncThunk<SaleData, FixMeLater>('Sales/getSale/:saleId', async (saleId: string, thunkAPI: FixMeLater) => {
//     try {
//         const token = thunkAPI.getState().auth.user.token
//         return await saleService.getSale(saleId, token)
//     } catch (error) {
//         HandleAxiosError(error, CONTEXT);
//         return thunkAPI.rejectWithValue("message")
//     }
// })

// export const updateSale = createAsyncThunk<SaleModel, SaleData, { state: RootState }>('Sales/updateSale/:saleId', async (saleData: SaleData, thunkAPI: FixMeLater) => {
//     try {
//         const token = thunkAPI.getState().auth.user.token
//         return await saleService.updateSale(saleData, token)
//     } catch (error) {
//         HandleAxiosError(error, CONTEXT);
//         return thunkAPI.rejectWithValue("message")
//     }
// })

// export const deleteSale = createAsyncThunk<SaleData, FixMeLater>('Sales/deleteSale/:saleId', async (saleId: string, thunkAPI: FixMeLater) => {
//     try {
//         const token = thunkAPI.getState().auth.user.token
//         return await saleService.deleteSale(saleId, token)
//     } catch (error) {
//         HandleAxiosError(error, CONTEXT);
//         return thunkAPI.rejectWithValue("message")
//     }
// })

export const saleSlice = createSlice({
    name: 'sale',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder
        .addCase(addSale.pending, (state: SaleState) => {
            state.status = Status.Pending
        })
        .addCase(addSale.fulfilled, (state: SaleState) => {
            state.status = Status.Success
        })
        .addCase(addSale.rejected, (state: SaleState) => {
            state.status = Status.Failure
        })
        .addCase(getSales.pending, (state: SaleState) => {
            state.status = Status.Pending
        })
        .addCase(getSales.fulfilled, (state: SaleState, action: PayloadAction<SaleModel[], any, any>) => {
            state.status = Status.Success
            state.sales = action.payload
        })
        .addCase(getSales.rejected, (state: SaleState) => {
            state.status = Status.Failure
        })
        .addCase(getSale.pending, (state: SaleState) => {
            state.status = Status.Pending
        })
        .addCase(getSale.fulfilled, (state: SaleState, action: PayloadAction<SaleModel, any, any>) => {
            state.status = Status.Success
            state.sale = action.payload
        })
        .addCase(getSale.rejected, (state: SaleState) => {
            state.status = Status.Failure
        })
        .addCase(updateSale.pending, (state: SaleState) => {
            state.status = Status.Pending
        })
        .addCase(updateSale.fulfilled, (state: SaleState, action: PayloadAction<SaleModel, any, any>) => {
            state.status = Status.Success
            state.sales.map((sale: SaleModel) => sale._id === action.payload._id ?
                (state.sale = sale) : sale)
            state.sale = action.payload
        })
        .addCase(updateSale.rejected, (state: SaleState) => {
            state.status = Status.Failure
        })
        .addCase(deleteSale.pending, (state: SaleState) => {
            state.status = Status.Pending
        })
        .addCase(deleteSale.fulfilled, (state: SaleState) => {
            state.status = Status.Success
            state.sale = undefined
        })
        .addCase(deleteSale.rejected, (state: SaleState) => {
            state.status = Status.Failure
        })
    }
})

export const {reset} = saleSlice.actions
export default saleSlice.reducer