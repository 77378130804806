import React, {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {getCategoryActivity} from '../../../features/activity/activitySlice'
import Spinner from '../../components/Spinner'
import {DataGrid, GridColumns} from '@mui/x-data-grid';
import {Link} from 'react-router-dom'
import {AppDispatch, RootState} from "../../../app/store";
import {Status} from "../../../types/slice";
import {useTranslation} from "react-i18next";

const actionColumn : GridColumns = [
    {
        field: 'activity',
        headerName: "Action",
        width: 100,
        renderCell: (params) => {
            return (
                <div className='flex flex-row px-2 pl-4 justify-center items-center gap-2' >
                    <Link to={`/activity/${params.row._id}`} className="border-2 rounded-lg text-blue-500 px-2">View</Link>
                </div>
            )
        }
    },
]

const nameColumn : GridColumns = [
    {
        field: 'equipmentObject.name',
        headerName: "Equipment Title",
        width: 200,
        renderCell: (params) => {
            return (
                <div className='flex flex-row px-2 pl-4 justify-center items-center gap-2' >
                    {params.row.equipmentObject.name}
                </div>
            )
        }
    },
]

const ownerColumn : GridColumns = [
    {
        field: 'assignedToObject.name',
        headerName: "User",
        width: 150,
        renderCell: (params) => {
            return (
                <div className='flex flex-row px-2 pl-4 justify-center items-center gap-2' >
                    {params.row.assignedToObject.name}
                </div>
            )
        }
    },
]

const timeColumn : GridColumns = [
    {
        field: 'time',
        headerName: "Time",
        width: 80,
        renderCell: (params) => {
            return (
                <div className='flex flex-row px-2 pl-4 justify-center items-center gap-2' >
                    {params.row.createdAt.substr(11, 5)}
                </div>
            )
        }
    },
]






const columns : GridColumns = [
  { field: '_id', headerName: 'ID', width: 70 },
  { field: 'activityType', headerName: 'Activity Type', width: 150 },
  {
    field: 'date',
    headerName: "Date",
    width: 150,
    renderCell: (params) => {
        return (
            <div className='flex flex-row px-2 pl-4 justify-center items-center gap-2' >
                {params.row.createdAt.substr(0,10)}
            </div>
        )
    }
},
];




function EquipmentActivity() {
    const {t} = useTranslation("activity");
    const {allActivity, status} = useSelector((state : RootState) => state.activity )
    const dispatch = useDispatch<AppDispatch>()

    const activityCategory = {category: "equipment"}

    useEffect(() => {
        dispatch(getCategoryActivity(activityCategory))
    }, [dispatch])

    if (status === Status.Pending) {
        return <Spinner/>
    }
  return (
    <>
      
      <div className='mx-5 drop-shadow-lg' style={{ height: 500, width: 'full' }}>
      <DataGrid
        getRowId={(row) => row._id}
        rows={allActivity}
        columns={columns.concat(actionColumn).concat(nameColumn).concat(ownerColumn)}
        pageSize={20}
        rowsPerPageOptions={[20]}
        checkboxSelection
      />
    </div>
   
    </>
  )
}

export default EquipmentActivity