import React from 'react'
import { Link } from 'react-router-dom'
import {toast} from 'react-toastify'
// @ts-ignore
import {animations} from 'react-animation';

const onShare = () => {
  if (navigator.share) {
      navigator.share({
          text: 'Check this thing out',
          url: 'https://asirisos.com/sign-up-v2'
      })
  } else {
      navigator.clipboard.writeText('https://asirisos.com/sign-up-v2')
      toast.success(("Link copied to clipboard"));
  }
}

function CheckoutSuccess() {
  return (
    <div className='lg:hidden sm:visible h-fit' >


    <div className=' flex flex-col w-full items-center align-middle justify-center rounded-lg bg-slate-100 px-5 '>  

        <div className='pt-10' >
            <h1 className=' font-bold text-3xl text-slate-700 max-w-2xl'>
                Congratulations
            </h1>
            <h1 className=' font-light text-lg text-slate-700 max-w-2xl'>
                LifeTime account is secured
            </h1>
        </div>

        <div className='pt-5  flex flex-col justify-center mx-5 'style={{animation: animations.fadeInUp}}>                    
            <h2 className='text-center font-normal text-xl pt-2 text-slate-600 '>Share with your firends and family</h2>
            
            <button className='flex flex-row justify-center py-3 text-lg drop-shadow-xl bg-[#1763db] mt-4 text-white' onClick={onShare}>
                <h1 className='pr-2'>Share</h1>
                <i className="fa-solid fa-arrow-up-from-bracket pt-1"></i>  
            </button>                    
        </div>

    </div>
</div>
  )
}

export default CheckoutSuccess