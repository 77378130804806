import React, {useState, ChangeEvent, FormEvent} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useNavigate, useLocation} from 'react-router-dom'
import {AppDispatch, RootState} from "../../../app/store";
import NumberInputInUnits from '../../components/shared/NumberInputInUnits'
import {useTranslation} from "react-i18next";
import { emailSignUp } from 'features/auth/authSlice';
import {Link} from 'react-router-dom'
import FormCard from 'UI/components/shared/FormCard';
import {toast} from 'react-toastify'
import { EmailSignUpModel } from '@asirisos/types';

const animation = require ('./asiris_animation.gif')
const farmCloud = require ('./farm_cloud.png')
const marketplaces = require ('./marketplaces.png')
const singleDataSource = require('./single_data_source.png')

function SignUpFormPage() {

    const {t} = useTranslation("organization")

    const [formData, setFormData] = useState({
        email: '',
        phone: ''
    })

    const {email, phone} = formData

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()

    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault()

        const emailSignUpData: EmailSignUpModel = {
            email,
            phone
        }

        console.log("Email Data before dispatch",emailSignUpData)

        dispatch(emailSignUp(emailSignUpData))
            .unwrap()
            .then(() => {
                toast.success(t("organization:Sign_UP_Success"))
                navigate('/thanks-for-signup')
            })
            .catch(() => {
                toast.error(t("organization:Sign_UP_Failed"))
                // navigate('/farm-add-form')
            })
    }


  return (
    <>
{/********************************************/}
{/********************************************/}
{/********************************************/}
{/********************* MOBILE VERSION ***********************/}
{/********************************************/}
{/********************************************/}
{/********************************************/}
    

    <div className='lg:hidden sm:visible h-full ustify-around flex flex-col bg-slate-200' >

        <div className='items-start justify-self-start flex flex-col px-3 pt-3 pb-16'>
            <h1 className='text-start font-bold text-5xl pt-1 text-slate-500'>Sign up for a <a className="underline text-indigo-500 decoration-green-500">free</a> account <i className="fa-solid fa-arrow-down fa-beat text-4xl text-green-500 pl-3"></i></h1>
        </div>

        <div className='align-center pt-28'>
                
                <div className='bg-slate-200 rounded-md rounded-t-none flex flex-col'>
                    <form onSubmit={e => onSubmit(e)} className='flex flex-col justify-around '> 
                        <div className='py-8'>
                            <input className="focus:ring-indigo-500 max-sm:w-3/4 max-sm:py-3 max-sm:rounded-md max-sm:mx-10 focus:border-indigo-500 w-11/12 py-4 pl-7 rounded-md text-xl border-none 
                            shadow-[2px_1px_10px_3px_rgba(201,201,201,0.47)]" 
                            placeholder='Email' onChange={onChange} name="email" value={email} id='email'/>    
                        </div>

                        <div className='py-3'>
                            <input className="focus:ring-indigo-500 max-sm:w-3/4 max-sm:py-3 max-sm:rounded-md max-sm:mx-10 focus:border-indigo-500 w-11/12 py-4 pl-7 rounded-md text-xl border-none 
                            shadow-[2px_1px_10px_3px_rgba(201,201,201,0.47)]" 
                            placeholder='Phone' onChange={onChange} name="phone" value={phone} id='phone'/>    
                        </div>
                            
                        <div className='pt-16'>
                            <button className="bg-indigo-500 max-sm:text-2xl max-sm:px-16 rounded-tl-none rounded-br-none border-0 border-green-500 px-10 py-3 w-3/4 rounded-lg text-white text-2xl shadow-[2px_1px_30px_3px_rgba(0,0,0,0.27)] font-medium hover:bg-indigo-700 ">{t("organization:signup")}<i className="fa-solid fa-arrow-right fa-beat text-3xl text-green-500 pl-8"></i></button>
                        </div>     

                    </form>
                </div>

        </div>

    </div>



{/********************************************/}
{/********************************************/}
{/********************************************/}
{/********************* DESKTOP VERSION ***********************/}
{/********************************************/}
{/********************************************/}
{/********************************************/}


    <div className='lg:visible invisible max-sm:hidden h-full bg-slate-200' >
            <div className='items-center justify-self-start flex flex-col px-3 pt-10 pb-24'>
                <h1 className='text-center font-bold text-7xl pt-1 text-slate-500'>Sign up for a <a className="underline text-indigo-500 decoration-green-500">free</a> account <i className="fa-solid fa-arrow-down fa-beat text-5xl text-green-500 pl-3"></i></h1>
            </div>

            
            <div className='items-center justify-center flex flex-col pt-24'>
                
                    <form onSubmit={e => onSubmit(e)} className='w-full'> 
                        <div className='py-8 w-full'>
                            <input className="focus:ring-indigo-500 w-1/2 focus:border-indigo-500 py-4 pl-7 rounded-md text-xl border-none 
                            shadow-[2px_1px_10px_3px_rgba(201,201,201,0.47)]" 
                            placeholder='Email' onChange={onChange} name="email" value={email} id='email'/>    
                        </div>

                        <div className='py-3'>
                            <input className="focus:ring-indigo-500 w-1/2 focus:border-indigo-500 py-4 pl-7 rounded-md text-xl border-none 
                            shadow-[2px_1px_10px_3px_rgba(201,201,201,0.47)]" 
                            placeholder='Phone' onChange={onChange} name="phone" value={phone} id='phone'/>    
                        </div>
                            
                        <div className='pt-16'>
                            <button className="bg-indigo-500 w-1/3 rounded-tl-none rounded-br-none border-0 border-green-500 px-10 py-3 rounded-lg text-white text-2xl shadow-[2px_1px_30px_3px_rgba(0,0,0,0.27)] font-medium hover:bg-indigo-700 ">{t("organization:signup")}<i className="fa-solid fa-arrow-right fa-beat text-3xl text-green-500 pl-8"></i></button>
                        </div>     

                    </form>
                
            </div>
            

    </div>
        
        
    </>
  )
}

export default SignUpFormPage