import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {AppDispatch} from "../../../app/store";
import {toast} from 'react-toastify'
import Spinner from '../../components/Spinner'
import {RootState} from "../../../app/store";
import { claimAccount, searchUnclaimedAccount } from '../../../features/accounts/accountSlice'
import FormCard from '../../components/shared/FormCard'
import {useTranslation} from "react-i18next";
import {Status} from "../../../types/slice";
import NumberInputInUnits from 'UI/components/shared/NumberInputInUnits'
import {AccountClaimData, AccountSearchData} from "@asirisos/types/data";

function ClaimAccount() {
    const {t} = useTranslation("farm")
    const {account, status} = useSelector((state: RootState ) => state.account )

    // const UserId = { UserId : user?._id }
    const [formData, setFormData] = useState({
        email: '',
        name: '',
        farmID: ''
    })

    const {email, name, farmID} = formData

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()

    // useEffect(() => {
    //     if (UserId)
    //         dispatch(getUserFarmJoins(UserId))
    // },[dispatch, status] )

    if(status === Status.Failure) {
        toast.error(t("farm:farmGetFailed")) }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }) )
    }

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
            const accountSearchData : AccountSearchData =
                {
                    name,
                    farmID,
                    email
                }

            dispatch(searchUnclaimedAccount(accountSearchData))
                .unwrap()
                .then(() => {
                    toast.success("account:accunt searched");
                })
                .catch(() => {
                    toast.error(t("account:account search failed"))
                })
    }

    // const onSubmit = (e: React.FormEvent) => {
    //     e.preventDefault()
    //     const accountSearchData : AccountSearchData = {
    //         farmAlias,
    //         name,
    //         email
    //     }
    //     dispatch(searchUnclaimedAccount(accountSearchData))
    //     if(status === Status.Success) {
    //         toast.success('Farm Searched')
    //     }
    // }

    const onSubmitClaim = (e: React.MouseEvent) => {
        e.preventDefault()

        const accountClaimData: AccountClaimData =
            {
                accountID : (e.target as HTMLButtonElement).value,
            }
        console.log((e.target as HTMLButtonElement).value)

        dispatch(claimAccount(accountClaimData))
        toast.success('Account Claim Request Sent')
    }
  

    if(status === Status.Pending) {
        return <Spinner/>
    }
  return (
      <>

<FormCard title={t("farm:addFarm")}>
                    <form onSubmit={onSubmit}>
                        <NumberInputInUnits onChange={(e) => onChange(e)} value={name} id={"name"} type={'text'}
                                            group={"name"} label={"name"} name={"name"} dataTitle={t("farm:Name")}/>
                        <NumberInputInUnits onChange={(e) => onChange(e)} value={farmID} id={"farmID"} type={'text'}
                                            group={"farmID"} label={"farmID"} name={"farmID"}
                                            dataTitle={t("farm:FarmID")}/>
                        <NumberInputInUnits onChange={(e) => onChange(e)} value={email} id={"email"} type={'text'}
                                            group={"email"} label={"email"} name={"email"}
                                            dataTitle={t("farm:Email")}/>
                        <div className='flex justify-center pt-8 p-2'>
                            <button className="btn btn-wide btn-primar hover:bg-black">{t("Account:Search_Account")}</button>
                        </div>
                    </form>
                </FormCard>

        {account?.accountType === 'Unclaimed' ? (
    
            <>
                <div className='mt-12 bg-white py-5 rounded-lg #drop-shadow-xl #shadow-lg relative px-4 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]'> 
                Search Result
                    <div className='flex mt-4 flex-row items-center justify-around bg-white py-4 rounded-lg #drop-shadow-xl #shadow-lg relative px-4 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]   '>{account?.name} ({account?.email})
                        <div>
                            <button onClick={onSubmitClaim} value={account?._id}  className="px-3 py-2 border-2 border-indigo-500 text-black rounded-lg disabled:hover:bg-gray-100 disabled:text-gray-300 hover:bg-indigo-500 hover:text-white ">Claim Account</button>
                        </div>
                    </div>
                </div>
            </>
        ) : (<> <div className='pt-10' > 
                    No Search Results
                </div>
            
            </>) }

    </>
  )
}

export default ClaimAccount




////////// .   For Several Accounts After Search //////////////////////



// {accounts?.map((account) => {
//     return(
//         <>
//             <div className='mt-12 bg-white py-5 rounded-lg #drop-shadow-xl #shadow-lg relative px-4 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]'> 
//             Search Result
//                 <div className='flex mt-4 flex-row items-center justify-around bg-white py-4 rounded-lg #drop-shadow-xl #shadow-lg relative px-4 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]   '>{account.name} ({account.email})
//                     <div>
//                         {/* <button onClick={onSubmitClaim} value={account._id}  className="px-3 py-2 border-2 border-indigo-500 text-black rounded-lg disabled:hover:bg-gray-100 disabled:text-gray-300 hover:bg-indigo-500 hover:text-white ">Claim Account</button> */}
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// } )}