import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import farmService from "./farmService";
import {FarmState} from "../../types/states";
import {FixMeLater} from "../../types/dev";
import HandleAxiosError from "../../utils/AxiosErrorHandler";
import {DefaultThunkApiConfig, Status} from "../../types/slice";
import {FarmModel} from "@asirisos/types";

const CONTEXT = "Farm"

const initialState: FarmState = {
    farm: undefined,
    farms: [],
    status: Status.Unknown
}

export const addFarm = createAsyncThunk<FarmModel, FarmModel, DefaultThunkApiConfig>('farm/addfarm', async (farmData, thunkAPI) => {
    try {
        const response = await farmService.addFarm(farmData)
        if (response.status !== 201)
            return thunkAPI.rejectWithValue();

        localStorage.setItem('account', JSON.stringify(response.data.account))
        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getFarms = createAsyncThunk<FixMeLater>('farm/getall', async (_, thunkAPI: FixMeLater) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await farmService.getFarms(token)
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue("message")
    }
})

// Get My Farm
export const getMyFarm = createAsyncThunk<FixMeLater>('farm/getmyfarm', async (_, thunkAPI: FixMeLater) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        const account = thunkAPI.getState().account.account
        return await farmService.getMyFarm(account, token)
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue("message")
    }
})

// Get Farm
export const getFarm = createAsyncThunk<FarmModel, string, DefaultThunkApiConfig>('farm/getone/:farmId', async (FarmId, thunkAPI) => {
    try {
        return await farmService.getFarm(FarmId)
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

// Get Farm Public Profile
export const getFarmProfile = createAsyncThunk<FarmModel, string, DefaultThunkApiConfig>('farm/profile/:farmId', async (FarmId, thunkAPI) => {
    try {
        return await farmService.getFarmProfile(FarmId)
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const updateFarm = createAsyncThunk<FarmModel, FarmModel, DefaultThunkApiConfig>('Farm/updateFarm/:farmId', async (farmData, thunkAPI) => {
    try {
        const response = await farmService.updateFarm(farmData)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})


// Search Farm
export const searchFarm = createAsyncThunk<string, FixMeLater>('farm/search/:farmId', async (FarmAlias, thunkAPI: FixMeLater) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await farmService.searchFarm(FarmAlias, token)
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue("message")
    }
})

const farmSlice = createSlice({
    name: 'farm',
    initialState,
    reducers: {
        reset: (state: FarmState) => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(addFarm.pending, (state: FarmState) => {
                state.status = Status.Pending
            })
            .addCase(addFarm.fulfilled, (state: FarmState) => {
                state.status = Status.Success
            })
            .addCase(addFarm.rejected, (state: FarmState) => {
                state.status = Status.Failure
            })
            .addCase(getFarms.pending, (state: FarmState) => {
                state.status = Status.Pending
            })
            .addCase(getFarms.fulfilled, (state: FarmState, action: PayloadAction<FarmModel[]>) => {
                state.status = Status.Success
                state.farms = action.payload
            })
            .addCase(getFarms.rejected, (state: FarmState) => {
                state.status = Status.Failure
            })
            .addCase(getFarm.pending, (state: FarmState) => {
                state.status = Status.Pending
            })
            .addCase(getFarm.fulfilled, (state: FarmState, action: PayloadAction<FarmModel>) => {
                state.status = Status.Success
                state.farm = action.payload
            })
            .addCase(getFarmProfile.pending, (state: FarmState) => {
                state.status = Status.Pending
            })
            .addCase(getFarmProfile.fulfilled, (state: FarmState, action: PayloadAction<FarmModel>) => {
                state.status = Status.Success
                state.farm = action.payload
                console.log('Farm Slice - get Farm Profile Case - action', action.payload)
            })
            .addCase(getMyFarm.fulfilled, (state: FarmState, action: PayloadAction<FarmModel>) => {
                state.status = Status.Success
                state.farm = action.payload
            })
            .addCase(getMyFarm.pending, (state: FarmState) => {
                state.status = Status.Pending
            })
            .addCase(getFarm.rejected, (state: FarmState) => {
                state.status = Status.Failure
            })
            .addCase(searchFarm.pending, (state: FarmState) => {
                state.status = Status.Pending
            })
            .addCase(searchFarm.fulfilled, (state: FarmState, action: FixMeLater) => {
                state.status = Status.Success
                state.farm = action.payload
            })
            .addCase(searchFarm.rejected, (state: FarmState) => {
                state.status = Status.Failure
            })
            .addCase(updateFarm.fulfilled, (state: FarmState, action: PayloadAction<FarmModel, any, any>) => {
                state.status = Status.Success
                state.farm = action.payload
                // state.allFarm.map((inventory: InventoryModel) => inventory._id === action.payload._id ? (state.inventory = inventory) : inventory)
            })
    }
})

export const {reset} = farmSlice.actions
export default farmSlice.reducer