import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import accountService from './accountService'
import {AccountState} from "../../types/states";
import {FixMeLater} from "../../types/dev";
import HandleAxiosError from "../../utils/AxiosErrorHandler";
import {DefaultThunkApiConfig, Status} from "../../types/slice";
import {AccountModel, EmployeeModel} from "@asirisos/types";
import {AccountClaimData, AccountSearchData} from "@asirisos/types/data";

const CONTEXT = "Account"

let account = null;
let localAccount = localStorage.getItem('account');
if (localAccount != null && localAccount != "undefined") {
    account = JSON.parse(localAccount)
}

const initialState: AccountState = {
    account: account ? account : undefined,
    accounts: [],
    employeeAccount: undefined,
    status: Status.Unknown,
    message: ''
}

export const addEmployee = createAsyncThunk<any, EmployeeModel, DefaultThunkApiConfig>('account/addaccount', async (employeeData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await accountService.addEmployee(employeeData, account);
        if (response.status !== 201)
            return thunkAPI.rejectWithValue()

        return response.data;
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getAccounts = createAsyncThunk<AccountModel[], void, DefaultThunkApiConfig>('account/getall', async (_, thunkAPI) => {
    try {
        const response = await accountService.getAccounts()
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data;
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getFarmAccounts = createAsyncThunk<AccountModel[], void, DefaultThunkApiConfig>('account/getallfarm', async (_, thunkAPI) => {
    try {

        const account = thunkAPI.getState().account.account
        if (!account)
            return thunkAPI.rejectWithValue()

        const response = await accountService.getFarmAccounts(account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data;
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const accountLogout = createAsyncThunk<void>('acount/logout', async () => {
    localStorage.removeItem('account');
})

export const getEmployeeAccount = createAsyncThunk<AccountModel, string, DefaultThunkApiConfig>('employeeAccount/getone/:AccountID', async (accountID, thunkAPI) => {
    try {
        const response = await accountService.getEmployeeAccount(accountID)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        // localStorage.setItem('account', JSON.stringify(response.data))
        console.log('ACCOUNT SLICE - get employee acc - got the response', response)
        return response.data;

    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const searchUnclaimedAccount = createAsyncThunk<AccountModel, AccountSearchData, DefaultThunkApiConfig>('account/search', async (accountSearchData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account
        if (!account)
            return thunkAPI.rejectWithValue();
        const response = await accountService.searchUnclaimedAccount(accountSearchData, account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data;

    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const updateEmployeeAccount = createAsyncThunk<any, EmployeeModel, DefaultThunkApiConfig>('employees/update/:accountID', async (employeeData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if(!account)
            return thunkAPI.rejectWithValue();

        const response = await accountService.updateEmployeeAccount(employeeData, account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const claimAccount = createAsyncThunk<any, AccountClaimData, DefaultThunkApiConfig>('employees/claim/:accountID', async (accountClaimData, thunkAPI) => {
    const account = thunkAPI.getState().account.account
        if (!account)
            return thunkAPI.rejectWithValue();
    try {
        const response = await accountService.claimAccount(accountClaimData, account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getAccount = createAsyncThunk<AccountModel, string, DefaultThunkApiConfig>('account/getone/:AccountID', async (accountID, thunkAPI) => {
    try {
        const response = await accountService.getAccount(accountID)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        localStorage.setItem('account', JSON.stringify(response.data))
        return response.data;

    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})






const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(addEmployee.pending, (state) => {
                state.status = Status.Pending
            })
            .addCase(addEmployee.fulfilled, (state) => {
                state.status = Status.Success
            })
            .addCase(addEmployee.rejected, (state: AccountState) => {
                state.status = Status.Failure
            })
            .addCase(claimAccount.pending, (state) => {
                state.status = Status.Pending
            })
            .addCase(claimAccount.fulfilled, (state) => {
                state.status = Status.Success
            })
            .addCase(claimAccount.rejected, (state: AccountState) => {
                state.status = Status.Failure
            })
            .addCase(getAccounts.pending, (state: AccountState) => {
                state.status = Status.Pending
            })
            .addCase(getAccounts.fulfilled, (state: AccountState, action: PayloadAction<AccountModel[]>) => {
                state.status = Status.Success
                state.accounts = action.payload
            })
            .addCase(getAccounts.rejected, (state: AccountState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            .addCase(searchUnclaimedAccount.pending, (state: AccountState) => {
                state.status = Status.Pending
            })
            .addCase(searchUnclaimedAccount.fulfilled, (state: AccountState, action: PayloadAction<AccountModel>) => {
                state.status = Status.Success
                state.account = action.payload
            })
            .addCase(searchUnclaimedAccount.rejected, (state: AccountState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            .addCase(getFarmAccounts.pending, (state: AccountState) => {
                state.status = Status.Pending
            })
            .addCase(getFarmAccounts.fulfilled, (state: AccountState, action: PayloadAction<AccountModel[]>) => {
                state.status = Status.Success
                state.accounts = action.payload
            })
            .addCase(getFarmAccounts.rejected, (state: AccountState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            .addCase(getAccount.pending, (state: AccountState) => {
                state.status = Status.Pending
            })
            .addCase(getAccount.fulfilled, (state: AccountState, action: PayloadAction<AccountModel>) => {
                state.status = Status.Success
                state.account = action.payload
            })
            .addCase(getAccount.rejected, (state: AccountState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            .addCase(getEmployeeAccount.pending, (state: AccountState) => {
                state.status = Status.Pending
            })
            .addCase(getEmployeeAccount.fulfilled, (state: AccountState, action: PayloadAction<AccountModel>) => {
                state.status = Status.Success
                state.employeeAccount = action.payload
            })
            .addCase(getEmployeeAccount.rejected, (state: AccountState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            .addCase(accountLogout.fulfilled, (state: AccountState) => {
                state.account = undefined
                state.accounts = []
                state.status = Status.Unknown
            })
            .addCase(updateEmployeeAccount.pending, (state: AccountState) => {
                state.status = Status.Pending
            })
            .addCase(updateEmployeeAccount.fulfilled, (state: AccountState, action: PayloadAction<AccountModel, any, any>) => {
                state.status = Status.Success
                state.employeeAccount = action.payload
            })
            .addCase(updateEmployeeAccount.rejected, (state: AccountState) => {
                state.status = Status.Failure
            })
    }
})

export const {reset} = accountSlice.actions
export default accountSlice.reducer
