import React from 'react'
import { Link } from 'react-router-dom'

function CheckoutFail() {
  return (
    <div className='lg:hidden sm:visible h-fit' >


        <div className=' flex flex-col w-full items-center align-middle justify-center rounded-lg bg-slate-100 px-5 '>  

            <div className='pt-10' >
                <h1 className=' font-bold text-3xl text-slate-700 max-w-2xl'>
                    ...Sorry
                </h1>
                <h1 className=' font-light text-lg text-slate-700 max-w-2xl'>
                    Checkout Failed
                </h1>
            </div>

            <Link to={'/sign-up-success'} className='w-full pt-10'>
                <button className='  drop-shadow-xl hover:drop-shadow-2xl bg-[#1763db] font-normal text-white w-full px-4 text-xl py-3'>
                    Go Back to Payment
                </button>
            </Link>

        </div>
    </div>
  )
}

export default CheckoutFail