import React from 'react'
import CustomerTable from './CustomerTable'
import {Translation} from "react-i18next";
import {useTranslation} from "react-i18next";
import { useTabPersistence } from 'hooks/useTabPersistence';
import {PageId} from "../../../types/pages";
import { Link } from 'react-router-dom'
import CustomerActivity from './CustomerActivity';
import CustomerDashboard from './CustomerDashboard';
import CustomerTasks from './CustomerTasks';

function Customers() {
  const {t} = useTranslation(["customer", "translation"])
  const {selection, toggleTab} = useTabPersistence(PageId.Equipment);

  return (
    <>
    <div className='px-5'>
     <div className='pb-8 mb-6 rounded-lg bg-slate-100 drop-shadow-lg'>
                <ul className="flex felx-row justify-left cursor-pointer">
                    <li onClick={() => toggleTab(1)}
                        className={`rounded-t-lg px-12 py-2 ${selection === 1 ? 'bg-indigo-500 text-white' : ''}`}>{t("customer:home")}
                    </li>
                    <li onClick={() => toggleTab(2)}
                        className={`rounded-t-lg px-12 py-2 ${selection === 2 ? 'bg-indigo-500 text-white' : ''}`}>{t("customer:customer")}
                    </li>
                    <li onClick={() => toggleTab(3)}
                        className={`rounded-t-lg px-12 py-2 ${selection === 3 ? 'bg-indigo-500 text-white' : ''}`}>{t("customer:tasks")}
                    </li>
                    <li onClick={() => toggleTab(4)}
                        className={`rounded-t-lg px-12 py-2 ${selection === 4 ? 'bg-indigo-500 text-white' : ''}`}>{t("customer:activity")}
                    </li>
                </ul>

                {selection === 1 ? (
                    <div className='bg-white drop-shadow-lg rounded-tl-none py-8'>
                        <CustomerDashboard/>
                    </div>
                ) : (<></>)}

                {selection === 2 ? (
                    <div className='bg-white drop-shadow-lg rounded-tl-none py-8'>
                        <CustomerTable/>
                    </div>
                ) : (<></>)}

                {selection === 3 ? (
                    <div className='bg-white drop-shadow-lg rounded-tl-none py-8'>
                        <CustomerTasks/>
                        <Link className="btn  mx-9" to={"/task-form"}>
                            Add Task
                        </Link>

                    </div>
                ) : (<></>)}

                {selection === 4 ? (
                    <div className='bg-white drop-shadow-lg rounded-tl-none py-8'>

                      <CustomerActivity/>
                      <Link className="btn mx-9 mt-6" to={"/activity-form"}>
                          Add Activity
                      </Link>
                    </div>
                ) : (<></>)}

            </div>
  


    <Link className="btn mb-8" to={"/customer-form"} >
        Add Customer
    </Link>
    </div>
    </>
  )
}

export default Customers