import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useParams, Link, useNavigate } from 'react-router-dom'
import {deleteEquipment, getEquipment, updateEquipment} from '../../../features/equipment/equipmentSlice'
import { getActivityByConnectionId } from 'features/activity/activitySlice'
import {toast} from 'react-toastify'
import Spinner from '../../components/Spinner'
import InlineEdit from '../../components/shared/InlineEdit'
import InlineEditDropDown from '../../components/shared/InlineEditDropDown'
import {AppDispatch, RootState} from "../../../app/store";
import {Status} from "../../../types/slice";
import {useTranslation} from "react-i18next";
import ChangeActivityList from '../Activity/ChangeActivityList'
import {EquipmentModel} from "@asirisos/types";
import ItemPageDeleteButton from 'UI/components/ItemPageDeleteButton'
import DeleteModal from 'UI/components/DeleteModal'


function EquipmentPage() {
    const {t} = useTranslation("equipment");
    const navigate = useNavigate()

    const categorySelection = [

    {name: t("equipment:farmMachinery"),id: 'Farm Machinery'},
    {name: t("equipment:liveStock"),id: 'Livestock'},
    {name: t("equipment:infrastructure"),id: 'Infrastructure'},
    {name: t("equipment:transportation"),id: 'Transportation'},
    {name: t("equipment:workShop"),id: 'Workshop'},
    {name: t("equipment:miscellaneous"),id: 'Miscellaneous'},]


    const {equipmentId} = useParams()
    const dispatch = useDispatch<AppDispatch>()
    const {equipment, status} = useSelector((state: RootState) => state.equipment)
    const {allActivity} = useSelector((state: RootState) => state.activity)



    useEffect(() => {
        
        if (equipmentId) {
            dispatch(getEquipment(equipmentId))
            dispatch(getActivityByConnectionId(equipmentId))
        }
    }, [dispatch, equipmentId])


    if (status === Status.Failure) {
        toast.error(t("equipment:equipmentGetFailed"))
    }

    const [formData, setFormData] = useState({
        name: '',
        farmId: '',
        type: '',
        mileage: '',
        vin: '',
        usageUnit: '',
        value: '',
        features: ''
    })

    const [changedData, setChangedData] = useState({
        field: ''
    })

    const {field} = changedData

    useEffect(() => {
        if (status !== Status.Pending && equipment !== undefined)
            dispatch(getActivityByConnectionId(equipmentId!))
    }, [equipmentId, status])

    useEffect(() => {
        if (status !== Status.Pending && equipment !== undefined) setFormData((prevState) => ({
            ...prevState,
            name: equipment.name,
            mileage: equipment.mileage,
            vin: equipment.vin,
            type: equipment.type,
            usageUnit: equipment.usageUnit,
            value: equipment.value,
            features: equipment.features
        }));
    }, [status])


    const {farmId, type, name, mileage, vin, usageUnit, value, features} = formData
    let changedField : string

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        // console.log('value changed', e.target.value)
        // console.log('field changed', e.target.name)
        setChangedData(() => ({
            field: e.target.name 
        }))

        // changedField = e.target.name
        // console.log('chanegd field assigned', changedField )

        // console.log('value changed data - chanegd data', changedData)

        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))

    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        
        e.preventDefault()
        // setChangedData((prevState) => ({
        //     ...prevState,
        //     field: e.target.name
        // }))
        // console.log('handle Change', e.target.name, e.target.value, e.target.id)
        // console.log('value changed data - chanegd data', changedData)
        changedField = e.target.name

        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))

        const equipmentData: EquipmentModel = {
            farmId,
            type: e.target.value,
            name,
            mileage,
            vin,
            _id: equipmentId,
            changedField: e.target.id,
            usageUnit,
            features,
            value
        }

        dispatch(updateEquipment(equipmentData))
            .unwrap()
            .then(() => {
                toast.success(t("equipment:equipmentUpdated"))
            })
            .catch(() => {
                toast.error(t("equipment:equipmentUpdateFailed"))
            })
    }

    const onKeyDown = (e: React.KeyboardEvent) => {
        console.log(e)
        if (e.key === "Enter" || e.key === "Escape") {
            console.log('Key Down Hit');
            (e.target as HTMLElement).blur();
        }
    }

    const onBlur = (e: React.FocusEvent) => {
        e.preventDefault()
        console.log('On Blur Hit')
        const equipmentData = {
            farmId,
            type,
            name,
            mileage,
            vin,
            usageUnit,
            features,
            value,
            _id: equipmentId,
            changedField: changedData.field,
            
        }

        dispatch(updateEquipment(equipmentData))
            .unwrap()
            .then(() => {
                toast.success(t("equipment:equipmentUpdated"))
            })
            .catch(() => {
                toast.error(t("equipment:equipmentUpdateFailed"))
            })
    }

    const onDelete = () => {
        dispatch(deleteEquipment([equipmentId!]))
        .unwrap()
        .then(() => {
            toast.success(t("equipment:equipmentDeleted"));
            navigate("/equipment")
        })
        .catch(() => {
            toast.error(t("equipment:failedToDeleteEquipment"))
        })
    }

    const [modalIsOpen, setModalIsOpen] = useState(false)

    const onDeleteClick = (e: any) => {
        setModalIsOpen(!modalIsOpen)
        console.log(modalIsOpen)
    }

    if (status === Status.Pending && equipment?.name != '' && equipment?.type != '') {
        return <Spinner/>
    } else {
        return (
            <>
                <div className="bg-white py-5 rounded-lg #drop-shadow-xl #shadow-lg relative px-9 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] mx-5  ">
                    <div className="grid grid-cols-3 gap-3">
                        <div className='-my-1 col-span-1 justify-self-center self-center'>
                            <img className="h-36 w-34 bg-indigo-400 shadow-xl rounded-xl"
                                 src="https://www.pngmart.com/files/22/Robot-Arm-PNG-Free-Download.png" alt=""/>
                        </div>
                        <div className='col-span2 justify-start justify-self-start'>
                            <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                        onKeyDown={(e) => onKeyDown(e)} title={t("translation:name")} value={name} id={"name"}
                                        name={"name"}/>
                            <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                        onKeyDown={(e) => onKeyDown(e)} title={t("equipment:mileage")} value={mileage} id={"mileage"}
                                        name={"mileage"} afterText={usageUnit}/>
                            <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                        onKeyDown={(e) => onKeyDown(e)} title={t("equipment:vin")} value={vin} id={"vin"}
                                        name={"vin"}/>
                            <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                        onKeyDown={(e) => onKeyDown(e)} title={t("equipment:value")} preText={"$"} value={value} id={"value"}
                                        name={"value"}/>  
                            <InlineEditDropDown onChange={(e) => handleChange(e)}
                                                onKeyDown={(e) => onKeyDown(e)} selections={categorySelection}
                                                value={type} id={"type"} title={"Category"} name={t("translation:type")} />
                        </div>
                    </div>

                {/* ////////////////////////////////////////////////////////////////////// */}
                {/* //////////////////////////// DELETE BUTTON /////////////////////////// */}

                <ItemPageDeleteButton onDeleteClick={onDeleteClick} />

                </div>

                {/* ////////////////////////////////////////////////////////////////////// */}
                {/* //////////////////////////// DELETE MODAL //////////////////////////// */}

                <DeleteModal modalIsOpen={modalIsOpen} name={equipment?.name!} onDeleteClick={onDeleteClick} onDelete={onDelete} />

                {/* ////////////////////////////////////////////////////////////////////// */}
                {/* //////////////////////////// ACTIVITY LOG //////////////////////////// */}
                
                <ChangeActivityList allActivity={allActivity} page={'Equipment'} />

            </>
        )
    }
}


export default EquipmentPage