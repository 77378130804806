import React, {useState, useEffect} from 'react'
import NumberInputInUnits from 'UI/components/shared/NumberInputInUnits'
import {InputSelection} from "../../../types/ui";
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import LargeTextInput from 'UI/components/shared/LargeTextInput';
import { addJoinTeam } from 'features/joinTeam/joinTeamSlice';
import {AppDispatch} from "../../../app/store";
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {useTranslation} from "react-i18next";

function ContactLanding() {

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const {t} = useTranslation('crop');

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        skills: ''
      })

      const {name,  email, phone, skills} = formData

      const categorySelection = [new InputSelection('farmer', 'farmer'), new InputSelection('wholesale buyer', 'wholesale buyer'), new InputSelection('retail buyer', 'retail buyer'), new InputSelection('services', 'services')]

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }))
      }

    const onSubmit = (e: React.FormEvent) => {
      e.preventDefault()

      if (email === '' || name === '' || phone === '' || skills === '' )  {
        toast.error('please complete all fields')
      } else {
  
        {
          const joinWaitlistData = {
            name,
            email,
            phone,
            skills
          }
          dispatch(addJoinTeam(joinWaitlistData))
            .unwrap()
            .then(() => {
                toast.success(t("success"));
                navigate("/success")
            })
            .catch(() => {
                toast.error(t("crop:cropAddFailed"))
                navigate("/")
            })
        }
      }
    }
      

  return (
    <>
    {/********************* MOBILE VERSION - START ***********************/}
    {/********************************************/}
    {/********************************************/}

    <div className='lg:hidden sm:visible'>

      <div className='flex flex-col'>

        <div className='w-screen h-screen flex flex-col justify-middle align-middle'>

          {/********************* MENU BAR - START ***********************/}
          {/********************************************/}
          <div className='bg-[#16341fec] flex justify-around py-2 drop-shadow-md '> 
            <Link to="/">
                <div className='flex flex-row justify-center py-2' >
                    <h1 className='text-md font-light text-white' >AsirisOS</h1>                        
                </div>
            </Link>
            <Link className=" text-slate-700 hover:bg-[#FFB100] hover:shadow-lg rounded-sm px-4 py-1 bg-[#FFC500] flex justify-center " to={"/login"} >
                <h1 className=' text-lg font-normal justify-center align-middle'>Login</h1>
            </Link>
          </div>

          {/********************* MENU BAR - END ***********************/}
          {/************************************************************/}

        
          <div className='flex flex-col justify-center items-center h-full bg-[#fff9e1]' >

            <div className='pb-6'>
              <h1 className='text-3xl font-thin pb-2' >Contact</h1>
            </div>

            <div className='flex flex-col justify-center'>
              <form onSubmit={onSubmit}>

                <div className='flex justify-center' >

                  <div className='max-w-2xl px-5'>
                    <NumberInputInUnits onChange={(e) => onChange(e)} value={name} id={"name"} type={'text'} group={"name"} label={"name"} name={"name"} dataTitle="Name" />
                    <NumberInputInUnits onChange={(e) => onChange(e)} value={email} id={"email"} type={'text'} group={"email"} label={"email"} name={"email"} dataTitle="Email" />
                    <NumberInputInUnits onChange={(e) => onChange(e)} value={phone} id={"phone"} type={'phone'} group={"phone"} label={"phone"} name={"phone"} dataTitle="Phone Number" />
                    <LargeTextInput onChange={(e) => onChange(e)} value={skills} id={"skills"} type={'text'} group={"skills"} label={"skills"} name={"skills"} dataTitle="Message (60 char limit)" />
                  </div>

                </div>

                <div className='flex justify-center pt-8 p-2' >
                    <button className="btn btn-wide btn-primar hover:bg-black drop-shadow-md">Contact</button>
                </div>

              </form>
            </div>
          </div>

        </div>
      </div>
    </div>

    {/********************* MOBILE VERSION - END ***********************/}
    {/********************************************/}
    {/********************************************/}




    {/*********************** DESKTOP VERSION - START *************************/}
    {/********************************************/}
    {/********************************************/}


    <div className='lg:visible invisible max-sm:hidden w-screen h-screen flex flex-col max-sm:justify-start justify-start bg-[#fff9e1]'>

      {/* ----------------------- NAVIGATION BAR  ----------------------- */}
      <div className='flex justify-around py-3 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.67)] bg-[#16341fec]'>    
        <Link to="/">
          <div className='flex justify-center align-middle'>
            <h1 className='max-sm:text-2xl max-sm:font-light text-4xl font-thin text-white align-middle' >AsirisOS</h1>
            <h1 className='max-sm:pt-1 max-sm:text-xl max-sm:font-light text-2xl font-thin text-slate-600 pt-1 pl-3' ></h1>
          </div>
        </Link>
        
        <Link className=" text-slate-700 hover:bg-[#FFB100] hover:shadow-lg rounded-sm px-4 py-1 bg-[#FFC500] flex justify-center " to={"/login"} >
            <h1 className=' text-lg font-normal justify-center align-middle'>Login</h1>
        </Link>
      </div>
      {/* ----------------------- NAVIGATION BAR  ----------------------- */}

      <div className=' h-full flex flex-col justify-center items-center'>

        <div className=' px-10 pb-5 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.87)] rounded-xl bg-slate-100' >

          <div className='pb-12' >
            <div className='rounded-b-md text-xl w-full py-2 text-white border-2 border-[#16341fec] drop-shadow-xl '>
              <ul className="flex felx-row justify-around cursor-pointer">
                <li className={`    ' : ''}`}>{("Contact")} </li>
              </ul>
            </div>
          </div>
  
          <form onSubmit={onSubmit}>

            <div className='flex justify-center ' >
              <div className='max-w-2xl px-5'>
                <NumberInputInUnits onChange={(e) => onChange(e)} value={name} id={"name"} type={'text'} group={"name"} label={"name"} name={"name"} dataTitle="Name" />
                <NumberInputInUnits onChange={(e) => onChange(e)} value={email} id={"email"} type={'text'} group={"email"} label={"email"} name={"email"} dataTitle="Email" />
                <NumberInputInUnits onChange={(e) => onChange(e)} value={phone} id={"phone"} type={'text'} group={"phone"} label={"phone"} name={"phone"} dataTitle="Phone Number" />
                <LargeTextInput onChange={(e) => onChange(e)} value={skills} id={"skills"} type={'textarea'} group={"skills"} label={"skills"} name={"skills"} dataTitle="Message (60 character limit)" />
              </div>
            </div>
            <div className='flex justify-center pt-8 p-2' >
                <button className="rounded-xl drop-shadow-xl hover:drop-shadow-2xl bg-[#FFC500] py-3 font-semibold text-slate-700 px-12 text-xl shadow-[2px_1px_20px_3px_rgba(201,201,201,0.87) hover:bg-[#FFB100] hover:text-slate-70">Send</button>
            </div>

          </form>

        </div>
      </div>
    </div>

    {/*********************** DESKTOP VERSION - END *************************/}
    {/********************************************/}
    {/********************************************/}
    </>
  )
}

export default ContactLanding