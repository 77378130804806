import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {RootState} from "../app/store";

export const useAuthStatus = () => {
    const [loggedIn, setLoggedIn] = useState(false)
    const [checkingStatus, setCheckingStatus] = useState(true)

    const { user } = useSelector((state : RootState) => state.auth )

    useEffect(() => {
        if(user) {
            setLoggedIn(true)
        } else {
            setLoggedIn(false)
        }
        setCheckingStatus(false)
    }, [user] )

    return {loggedIn, checkingStatus}
}