import axios from "axios"
import {AccountModel, LiveStockModel} from "@asirisos/types";
import {LiveStockWeightData} from "@asirisos/types/data";
import RequestBodyFactory from "../../factories/requestBodyFactory";

const API_URL = '/api/livestock'

const addLiveStockWeight = async (liveStockWeightData: LiveStockWeightData, account: AccountModel) => {
    const body = new RequestBodyFactory(liveStockWeightData).WithAccount(account).Build()

    const {liveStockId} = liveStockWeightData
    return await axios.post(API_URL + '/' + liveStockId, body)
}

const addLiveStock = async (liveStockData: LiveStockModel, account: AccountModel) => {
    const body = new RequestBodyFactory(liveStockData).WithAccount(account).Build()
    return await axios.post(API_URL + '/', body)
}

const getAllLiveStock = async (account: AccountModel) => {
    return await axios.get(API_URL + '/all/' + account.farm._id + '/' + account.user._id)
}

const getLiveStock = async (liveStockId: string) => {
    return await axios.get(API_URL + '/' + liveStockId)
}

const updateLiveStock = async(liveStockData: LiveStockModel, account: AccountModel) => {
    const {_id} = liveStockData

    const body = new RequestBodyFactory(liveStockData).WithAccount(account).Build()
    return await axios.put(API_URL + '/' + _id, body)
}

const deleteLiveStock = async(liveStockId: string[]) => {
    const ids = liveStockId
    const config = {
        data: ids
    }
    return await axios.delete(API_URL+'/'+ liveStockId, config)
}

const liveStockService = {
    addLiveStockWeight,
    addLiveStock,
    getAllLiveStock,
    getLiveStock,
    updateLiveStock,
    deleteLiveStock
}

export default liveStockService