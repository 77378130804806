import axios from "axios";
import {AccountModel, YieldModel} from "@asirisos/types";
import RequestBodyFactory from "../../factories/requestBodyFactory";

const API_URL = '/api/yields'

const addYield = async (yieldData: YieldModel, account: AccountModel) => {
    const body = new RequestBodyFactory(yieldData).WithAccount(account).Build()
    return await axios.post(API_URL + '/add', body)
}

const getYields = async (account: AccountModel) => {
    return await axios.get(API_URL + '/all/' + account.farm._id)
}

const getYield = async (yieldId: string) => {
    return await axios.get(API_URL + '/' + yieldId)
}

const updateYield = async (yieldData: YieldModel, account: AccountModel) => {
    const {_id} = yieldData
    const body = new RequestBodyFactory(yieldData).WithAccount(account).Build()
    return await axios.put(API_URL + '/update/' + _id, body)

}

const deleteYield = async (yieldId: string[], account: AccountModel) => {
    const ids = yieldId
    const config = {
        data: {ids, account}
    }

    return await axios.delete(API_URL + '/' + yieldId, config)
}

const yieldService = {
    addYield,
    getYields,
    getYield,
    updateYield,
    deleteYield
}

export default yieldService