import React, {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {getCategoryActivity} from '../../../features/activity/activitySlice'
import Spinner from '../../components/Spinner'
import {DataGrid, GridColumns} from '@mui/x-data-grid';
import {Link} from 'react-router-dom'
import {AppDispatch, RootState} from "../../../app/store";
import {Status} from "../../../types/slice";
import {useTranslation} from "react-i18next";

const actionColumn : GridColumns = [
    {
        field: 'activity',
        headerName: "Activity",
        width: 200,
        renderCell: (params) => {
            return (
                <div className='flex flex-row px-2 pl-4 justify-center items-center gap-2' >
                    <Link to={`/activity/${params.row._id}`} className="border-2 rounded-lg border-blue-500 px-2">View</Link>
                </div>
            )
        }
    }
]

const columns : GridColumns = [
  { field: '_id', headerName: 'ID', width: 70 },
  { field: 'itemName', headerName: 'Item', width: 150 },
  { field: 'action', headerName: 'Action Type', width: 150 },
];




function LiveStockActivity() {
    const {t} = useTranslation("activity");
    const {allActivity, status} = useSelector((state : RootState) => state.activity )
    const dispatch = useDispatch<AppDispatch>()

    const activityCategory = {category: "live stock"}

    useEffect(() => {
        dispatch(getCategoryActivity(activityCategory))
    }, [dispatch])

    if (status === Status.Pending) {
        return <Spinner/>
    }
  return (
    <>
      
      <div className='mx-5 drop-shadow-lg' style={{ height: 400, width: 'full' }}>
      <DataGrid
        getRowId={(row) => row._id}
        rows={allActivity}
        columns={columns.concat(actionColumn)}
        pageSize={10}
        rowsPerPageOptions={[5]}
        checkboxSelection
      />
    </div>


    {/* ////////////////////////////// ACTIVITY DISPLAY ////////////////////////////// */}
    <div className=' rounded-lg shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] my-5 py-5 col-span-3 bg-white #justify-self-center #self-center mx-7'>
            <div className='' >
                <div className='grid grid-cols-2' >
                    <div className='col-span-1' >
                        <h1 className='text-xl text-left ml-6 my-3 pb-3 font-semibold ' >{`Activity`}</h1>
                    </div>
                    <div className='col-span-1' >
                        <h1 className='text-md text-right mr-6 my-3 pb-3 font-thin ' >{`Edit`}</h1>
                    </div>
                </div>


                <div className='grid grid-cols-8 pt-1'>
                    <div className='col-span-2'>
                        <h1 className='text-md text-center ml-3 my-0 font-bold ' >{`Oct 15 3:15pm`}</h1>
                    </div>
                    <div className='col-span-1'>
                        <h1 className='text-lg text-left ml-5 my-0 font-bold text-indigo-500' >{`O`}</h1>
                    </div>
                    <div className='col-span-5 text-left' >
                        Changed Item
                    </div>
                </div>

                <div className='grid grid-cols-8 pt-1' >
                    <div className='col-span-2'>
                        <h1 className='text-md text-center ml-3 my-0 font-bold ' >{`Oct 12 5:58pm`}</h1>
                    </div>
                    <div className='col-span-1'>
                        <h1 className='text-lg text-left ml-5 my-0 font-bold text-indigo-500' >{`O`}</h1>
                    </div>
                    <div className='col-span-5 text-left' >
                        Changed Item #2
                    </div>
                </div>
            </div>
        </div>
        {/* ////////////////////////////// ACTIVITY DISPLAY ////////////////////////////// */}
   
    </>
  )
}

export default LiveStockActivity