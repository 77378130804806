import React, {ChangeEvent} from 'react'
import { Link } from 'react-router-dom'
import SearchBox from '../../Map/SearchBox-1'
import {useTabPersistence} from "../../../../hooks/useTabPersistence";
import {useTranslation} from "react-i18next";
import {PageId} from "../../../../types/pages";
import { useState } from 'react'
import DisplayAllFarmersMarkets from '../..//Map/DisplayAllFarmersMarkets';
import Button from "../../../components/shared/Button"

function AssetsDemo() {

    // const animation = require ('./farm_large.png')
    // const animation2 = require ('./adaptive-icon.png')
    // const vegetables_in_basket = require ('./vegetables_in_basket.jpg')
    // const vegetable_market = require ('./vegetable_market.jpg')

    const {t} = useTranslation(["landing", "translation"])
    const {selection, toggleTab} = useTabPersistence(PageId.Equipment);

    const [formData, setFormData] = useState({
        addressObject: {},
      })

    const {addressObject} = formData

    const [selectedPosition, setSelectPosition] = useState()


    const onSetAddress = (selectedPosition: any) => {
        setFormData((prevState) => ({
            ...prevState,
            addressObject: selectedPosition
        }) )
      }

    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
      }


  return (
    <>
    {/********************************************/}
    {/********************************************/}
    {/********************************************/}
    {/********************* MOBILE VERSION ***********************/}
    {/********************************************/}
    {/********************************************/}
    {/********************************************/}

    <div className='lg:hidden sm:visible' >

        <div className='flex flex-col'>

            {/********************************************/}
            {/********************* FIRST PAGE MOBILE - START ***********************/}
            {/********************************************/}

            <div className='w-screen h-screen flex flex-col justify-start align-middle bg-[#fff9e1]'>

                {/********************************************/}
                {/********************* MENU BAR - START ***********************/}
                {/********************************************/}

                <div className='bg-[#16341fec] flex justify-around py-2 drop-shadow-md shadow-[2px_1px_20px_3px_rgba(201,201,201,0.97)] '> 
                    <Link to="/">
                        <div className='flex flex-row justify-center py-2' >
                            <h1 className='text-md font-light text-white' >AsirisOS</h1>                        
                        </div>
                    </Link>
                    <Link className=" text-slate-700 hover:bg-[#FFB100] hover:shadow-lg rounded-sm px-4 py-1 bg-[#FFC500] flex justify-center " to={"/login"} >
                        <h1 className=' text-lg font-normal justify-center align-middle'>Login</h1>
                    </Link>
                </div>

                {/********************************************/}
                {/********************* MENU BAR - END ***********************/}
                {/********************************************/}


                <div className='w-full flex items-center justify-center  '>
                    <div className=' flex  border-t-0 rounded-xl rounded-t-none mb-4 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.67)]'>

                    </div>
                </div>


                {/********************************************/}
                {/********************* FIRST PAGE MOBILE - START ***********************/}
                {/********************************************/}

                <div className='flex flex-col justify-start h-full bg-[#fff9e1] px-2 pt-5'>

                    <div className='flex items-start pb-2 justify-center px-3'>
                        <h1 className=' max-w-xl text-2xl text-start font-semibold text-slate-800'> 
                            Reliable AI engines that provide Speed, Security, Scalability at the Edge
                        </h1>
                    </div>

                    <div className='flex justify-center'>
                        <div className='flex flex-col items-start pb-10'>                    
                            <h1 className='text-center font-semibold text-lg pt-2 text-slate-700'></h1>
                            <div className='flex'>
                                <h1 className='sm:py-1 max-sm:text-lg max-sm:font-normal text-left text-slate-700 px-3 pb-1'>Software for local food producers and consumers to build independent and healthy food systems for all.</h1>
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-col justify-center items-center py-5 pb-14' >
                        {/* <img className='rounded-2xl w-5/6 drop-shadow-2xl' src={vegetables_in_basket} alt="" />        */}
                    </div>

                    <div className=' flex flex-row gap-4 justify-center' >

                        <Link to="/join-the-waitlist">
                            <button className='rounded-md drop-shadow-xl hover:drop-shadow-2xl bg-[#FFC500]  py-3 font-normal text-slate-700 px-4 text-xl hover:bg-[#FFB100] hover:text-slate-700'>
                                Join the waitlist
                            </button>
                        </Link>

                        <Link to="/join-the-team">
                            <button className='rounded-md drop-shadow-xl hover:drop-shadow-2xl bg-[#FFC500]  py-3 font-normal text-slate-700 px-4 text-xl hover:bg-[#FFB100] hover:text-slate-700'>
                                Join the team
                            </button>
                        </Link>

                    </div>

                </div>

            </div>

            {/********************************************/}
            {/********************* FIRST PAGE MOBILE - END ***********************/}
            {/********************************************/}

            {/********************************************/}
            {/********************* SECOND PAGE MOBILE - START ***********************/}
            {/********************************************/}


            <div className='h-screen py-6 flex bg-[#fff9e192] w-full'>

                <div className='flex flex-col justify-center w-full gap-10' >

                    <div className='bg-[#16341fec] text-[#fff9e1] py-4  w-48 rounded-r-xl bg-[#fff9e1] drop-shadow-2xl '>
                        <div className='' >
                            <h1 className='text-2xl font-bold'>6989</h1>
                            <h1 className='text-lg font-light' >Farmers Markets</h1>
                        </div>
                    </div>


                    <div className=' flex flex-row justify-between'>                
                        <div className=' self-center pl-3'>
                            {/* <h1>This is what it is</h1> */}
                        </div>

                        <div className='bg-[#16341fec] text-[#fff9e1] py-4  w-48 rounded-l-xl bg-[#fff9e1] self-end drop-shadow-2xl' >
                            <h1 className='text-2xl font-bold'>1217</h1>
                            <h1 className='text-lg font-light' >Agricultural Co-ops</h1>
                        </div>
                    </div>

                    <div className='bg-[#16341fec] text-[#fff9e1] py-4  w-48 rounded-r-xl bg-[#fff9e1] drop-shadow-2xl '>
                        <div className='' >
                            <h1 className='text-2xl font-bold'>994</h1>
                            <h1 className='text-lg font-light' >CSAs</h1>
                        </div>
                    </div>

                    <div className='bg-[#16341fec] text-[#fff9e1] py-4  w-48 rounded-l-xl bg-[#fff9e1] self-end drop-shadow-2xl '>
                        <div className='' >
                            <h1 className='text-2xl font-bold'>1710</h1>
                            <h1 className='text-lg font-light' >On-Farm Markets</h1>
                        </div>
                    </div>

                    <div className='bg-[#16341fec] text-[#fff9e1] py-4  w-48 rounded-r-xl bg-[#fff9e1] drop-shadow-2xl '>
                        <div className='' >
                            <h1 className='text-2xl font-bold'>230</h1>
                            <h1 className='text-lg font-light' >Food Hubs</h1>
                        </div>
                    </div>

                </div>    

            </div>

            {/********************************************/}
            {/********************* SECOND PAGE MOBILE - END ***********************/}
            {/********************************************/}

        </div>

    </div>

    {/********************************************/}
    {/********************************************/}
    {/********************* MOBILE VERSION END ***********************/}
    {/********************************************/}
    {/********************************************/}




    {/********************************************/}
    {/********************************************/}
    {/********************* DESKTOP VERSION START ***********************/}
    {/********************************************/}
    {/********************************************/}

    <div className='lg:visible invisible max-sm:hidden items-center justify-center ' >

        {/********************************************/}
        {/********************* FIRST PAGE DESKTOP - START ***********************/}
        {/********************************************/}
    <div className='' >

        <div className='w-screen h-full flex flex-col max-sm:justify-start justify-start'>

            <div className='w-screen h-full flex flex-row'>

                {/* ----------------------- SIDEBAR MENU - START  ----------------------- */}

                    <div className='sticky top-0 border-r-2 h-screen border-slate-600 flex flex-col justify-between pt-5 text-lg font-light'>

                        <div>
                            <div className=' pb-10'>
                                <h1 className='max-sm:text-2xl max-sm:font-light text-4xl font-semibold text-slate-700 align-middle' >AsirisOS</h1>
                            </div>

                            <Link to='/asiris-v2-demo-family' className=' py-1 pl-4 pr-16 flex justify-start'  > 
                                    <h1 className=''>Family</h1>
                            </Link>

                            <Link to='/asiris-v2-demo-assets' className=' bg-slate-100 py-1 pl-4 pr-16 flex justify-start' > 
                                <h1 className=''>Assets</h1>
                            </Link>

                            <Link to='/asiris-v2-demo-community' className='  py-1 pl-4 pr-16 flex justify-start' > 
                                <h1>Community</h1>
                            </Link>
                        </div>

                        <div className='pb-5'>
                            <Link to='/asiris-v2-demo-settings' className='  py-1 pl-4 pr-16 flex justify-start' > 
                                <h1>Settings</h1>
                            </Link>

                            <Link to='/asiris-v2-demo-payments' className='  py-1 pl-4 pr-16 flex justify-start' > 
                                <h1>Payments</h1>
                            </Link>

                            <Link to='/asiris-v2-sign-out' className='  py-1 pl-4 pr-16 flex justify-start' > 
                                <h1>Sign-out</h1>
                            </Link>
                        </div>

                    </div>

                    {/* ----------------------- SIDEBAR MENU - END  ----------------------- */}

                <div className='h-full w-full flex flex-col bg-slate-50'>

                    {/* ----------------------- FAMILY MEMBERS - START  ----------------------- */}



                    {/* ----------------------- EVERYTHING DB - START  ----------------------- */}

                    <Link to="/asiris-v2-demo-everything-db">

                        <div>
                            <div className='flex flex-row  justify-center pt-5'>
                                <h1 className='text-3xl'>Real Estate</h1>
                            </div>

                            <div className='flex flex-row justify-center  gap-16 py-10 '>

                                <div id="item1" className=''>
                                    <div className='text-[#16341fec] bg-[#ffffff] drop-shadow-xl rounded-lg flex'>

                                        <div className='w-32 py-3 px-3 bg-blue-100 rounded-l-lg'>
                                            <img className='rounded-l-lg' src='https://static.thenounproject.com/png/411511-200.png' alt="" />                                
                                        </div>

                                        <div className='flex flex-col w-96 px-5'>
                                            <div className=' pt-2 flex-row text-start '>
                                                <h1 className='text-2xl font-normal'>Everything DB</h1>
                                                <h1 className='text-sm font-semibold' >Keep track of things in your house</h1>
                                            </div>
                                            <hr/>
                                            <div className='flex pb-3 rounded-b-lg'>
                                                <h1 className=' pt-2 text-md font-light'>Find exactly where your stuff is</h1>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>

                    {/* ----------------------- EVERYTHING DB - FINISH  ----------------------- */}                    





                    {/* ----------------------- REAL ESTATE - START  ----------------------- */}

                    <div>
                        <div className='flex flex-row  justify-center pt-5'>
                            <h1 className='text-3xl'>Real Estate</h1>
                        </div>

                        <div className='flex flex-row justify-center  gap-16 py-10 '>

                            <div id="item1" className=''>
                                <div className='text-[#16341fec] bg-[#ffffff] drop-shadow-xl rounded-lg flex'>

                                    <div className='w-52'>
                                        <img className='rounded-l-lg' src='https://media.istockphoto.com/id/1255835530/photo/modern-custom-suburban-home-exterior.jpg?s=612x612&w=0&k=20&c=0Dqjm3NunXjZtWVpsUvNKg2A4rK2gMvJ-827nb4AMU4=' alt="" />                                
                                    </div>

                                    <div className='flex flex-col w-96 px-5'>
                                        <div className=' pt-2 flex-row text-start '>
                                            <h1 className='text-2xl font-normal'>Home</h1>
                                            <h1 className='text-sm font-semibold' >229 Bebee Rd, Round Rock, TX 78640, USA </h1>
                                        </div>
                                        <hr/>
                                        <div className='flex pb-3 rounded-b-lg'>
                                            <h1 className=' pt-2 text-md font-light'>Current Value: $430,000</h1>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* ----------------------- REAL ESTATE - FINISH  ----------------------- */}                    

                    {/* ----------------------- VEHICLES - START  ----------------------- */}

                    <div className=' flex flex-col  justify-center'>

                        <div className='flex flex-row  justify-center pt-5'>
                            <h1 className='text-3xl'>Vehicles</h1>
                        </div>

                        <div className='flex flex-row  justify-center  gap-16 py-10 '>

                            <Link to='/asiris-v2-demo-vehicle-profile' className='w-1/3'>
                                <div id="item1" className=''>
                                    <div className='text-[#16341fec] bg-[#ffffff] py-2 drop-shadow-xl rounded-lg flex'>

                                        <div className='w-52'>
                                            <img className='rounded-l-lg' src='https://www.seegertoyota.com/static/dealer-12152/2020_Highlander_Hybrid_Limited.png' alt="" />                                
                                        </div>

                                        <div className='flex flex-col w-96 px-5'>
                                            <div className=' pt-2 flex-row text-start '>
                                                <h1 className='text-2xl font-normal'>Toyota</h1>
                                                <h1 className='text-sm font-semibold' >Highlander XLE </h1>
                                            </div>
                                            <hr/>
                                            <div className='flex pb-3 rounded-b-lg'>
                                                <h1 className=' pt-2 text-md font-light'>Current Value: $37,000</h1>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </Link>

                            <Link to='/asiris-v2-demo-vehicle-profile' className='w-1/3'>
                                <div id="item2" className=''>
                                    <div className='text-[#16341fec] bg-[#ffffff] py-2 drop-shadow-xl rounded-lg flex'>

                                        <div className='w-52'>
                                            <img className='rounded-l-lg w-48 px-2' src='https://edgecast-img.yahoo.net/mysterio/api/76DF9C02F2BFECCFF9E5ADA1DAE272782927F9181DDCA927C70B2E07A3144E7C/autoblog/resizefill_w660_h372;quality_80;format_webp;cc_31536000;/https://s.aolcdn.com/commerce/autodata/images/USD00LES121A021001.jpg' alt="" />                                
                                        </div>

                                        <div className='flex flex-col w-96 px-5'>
                                            <div className=' pt-2 flex-row text-start '>
                                                <h1 className='text-2xl font-normal'>Lexus</h1>
                                                <h1 className='text-sm font-semibold' >RX 350 </h1>
                                            </div>
                                            <hr/>
                                            <div className='flex pb-3 rounded-b-lg'>
                                                <h1 className=' pt-2 text-md font-light'>Current Value: $35,000</h1>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </Link>

                        </div>
                    </div>

                    {/* ----------------------- VEHICLES - FINISh  ----------------------- */}                    

                    

                    <div>
                        <div className='flex flex-row  justify-center pt-5'>
                            <h1 className='text-3xl'>Appliances</h1>
                        </div>

                        <div className='flex flex-row justify-center  gap-16 py-10 '>

                            <div id="item1" className=''>
                                <div className='text-[#16341fec] bg-[#ffffff] drop-shadow-xl rounded-lg flex'>

                                    <div className='w-52'>
                                        <img className='rounded-l-lg' src='https://media.istockphoto.com/id/1255835530/photo/modern-custom-suburban-home-exterior.jpg?s=612x612&w=0&k=20&c=0Dqjm3NunXjZtWVpsUvNKg2A4rK2gMvJ-827nb4AMU4=' alt="" />                                
                                    </div>

                                    <div className='flex flex-col w-96 px-5'>
                                        <div className=' pt-2 flex-row text-start '>
                                            <h1 className='text-2xl font-normal'>Home</h1>
                                            <h1 className='text-sm font-semibold' >229 Bebee Rd, Round Rock, TX 78640, USA </h1>
                                        </div>
                                        <hr/>
                                        <div className='flex pb-3 rounded-b-lg'>
                                            <h1 className=' pt-2 text-md font-light'>Current Value: $430,000</h1>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>


                    <div>
                        <div className='flex flex-row  justify-center pt-5'>
                            <h1 className='text-3xl'>Electronics</h1>
                        </div>

                        <div className='flex flex-row justify-center  gap-16 py-10 '>

                            <div id="item1" className=''>
                                <div className='text-[#16341fec] bg-[#ffffff] drop-shadow-xl rounded-lg flex'>

                                    <div className='w-52'>
                                        <img className='rounded-l-lg' src='https://media.istockphoto.com/id/1255835530/photo/modern-custom-suburban-home-exterior.jpg?s=612x612&w=0&k=20&c=0Dqjm3NunXjZtWVpsUvNKg2A4rK2gMvJ-827nb4AMU4=' alt="" />                                
                                    </div>

                                    <div className='flex flex-col w-96 px-5'>
                                        <div className=' pt-2 flex-row text-start '>
                                            <h1 className='text-2xl font-normal'>Home</h1>
                                            <h1 className='text-sm font-semibold' >229 Bebee Rd, Round Rock, TX 78640, USA </h1>
                                        </div>
                                        <hr/>
                                        <div className='flex pb-3 rounded-b-lg'>
                                            <h1 className=' pt-2 text-md font-light'>Current Value: $430,000</h1>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className='flex flex-row  justify-center pt-5'>
                            <h1 className='text-3xl'>Furniture</h1>
                        </div>

                        <div className='flex flex-row justify-center  gap-16 py-10 '>

                            <div id="item1" className=''>
                                <div className='text-[#16341fec] bg-[#ffffff] drop-shadow-xl rounded-lg flex'>

                                    <div className='w-52'>
                                        <img className='rounded-l-lg' src='https://media.istockphoto.com/id/1255835530/photo/modern-custom-suburban-home-exterior.jpg?s=612x612&w=0&k=20&c=0Dqjm3NunXjZtWVpsUvNKg2A4rK2gMvJ-827nb4AMU4=' alt="" />                                
                                    </div>

                                    <div className='flex flex-col w-96 px-5'>
                                        <div className=' pt-2 flex-row text-start '>
                                            <h1 className='text-2xl font-normal'>Home</h1>
                                            <h1 className='text-sm font-semibold' >229 Bebee Rd, Round Rock, TX 78640, USA </h1>
                                        </div>
                                        <hr/>
                                        <div className='flex pb-3 rounded-b-lg'>
                                            <h1 className=' pt-2 text-md font-light'>Current Value: $430,000</h1>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className='flex flex-row  justify-center pt-5'>
                            <h1 className='text-3xl'>Tools & Equipment</h1>
                        </div>

                        <div className='flex flex-row justify-center  gap-16 py-10 '>

                            <div id="item1" className=''>
                                <div className='text-[#16341fec] bg-[#ffffff] drop-shadow-xl rounded-lg flex'>

                                    <div className='w-52'>
                                        <img className='rounded-l-lg' src='https://media.istockphoto.com/id/1255835530/photo/modern-custom-suburban-home-exterior.jpg?s=612x612&w=0&k=20&c=0Dqjm3NunXjZtWVpsUvNKg2A4rK2gMvJ-827nb4AMU4=' alt="" />                                
                                    </div>

                                    <div className='flex flex-col w-96 px-5'>
                                        <div className=' pt-2 flex-row text-start '>
                                            <h1 className='text-2xl font-normal'>Home</h1>
                                            <h1 className='text-sm font-semibold' >229 Bebee Rd, Round Rock, TX 78640, USA </h1>
                                        </div>
                                        <hr/>
                                        <div className='flex pb-3 rounded-b-lg'>
                                            <h1 className=' pt-2 text-md font-light'>Current Value: $430,000</h1>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* ----------------------- FAMILY MEMBERS - END  ----------------------- */}

                </div>

            </div>

        </div>

        {/********************************************/}
        {/********************* FIRST PAGE DESKTOP - END ***********************/}
        {/********************************************/}
    </div>
    </div>
    {/********************************************/}
    {/********************************************/}
    {/********************* DESKTOP VERSION END ***********************/}
    {/********************************************/}
    {/********************************************/}
    </>
  )
}

export default AssetsDemo