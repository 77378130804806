import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useParams, useNavigate} from 'react-router-dom'
import {deleteLiveStock, getLiveStock, updateLiveStock} from '../../../features/live-stock/live-stockSlice'
import {toast} from 'react-toastify'
import Spinner from '../../components/Spinner'
import InlineEdit from '../../components/shared/InlineEdit'
import InlineEditDropDown from '../../components/shared/InlineEditDropDown'
import {AppDispatch, RootState} from "../../../app/store";
import {Status} from "../../../types/slice";
import {useTranslation} from "react-i18next";
import ChangeActivityList from '../Activity/ChangeActivityList'
import { getActivityByConnectionId } from 'features/activity/activitySlice'
import AddWeight from './AddWeight'
// import WeightChart from './WeightChart'
import {LiveStockModel} from "@asirisos/types";
import DeleteModal from 'UI/components/DeleteModal'
import Modal from 'react-modal'
import { getTimeSeriesDataByItemId } from 'features/timeSeriesData/timeSeriesDataSlice'
import ItemPageDeleteButton from 'UI/components/ItemPageDeleteButton'
import AddDataTopic from 'UI/components/AddDataTopic'
import DataChart from '../TimeSeriesData/DataChart'

function LiveStockPage() {
    const navigate = useNavigate()

    

    const {liveStock, status} = useSelector((state: RootState) => state.livestock)
    const {t} = useTranslation("livestock")
    const {allActivity} = useSelector((state: RootState) => state.activity)

    const sexSelection = [
        {name: t("livestock:sex-male"), id: 'male'},
        {name: t("livestock:sex-female"), id: 'female'},]

    const categorySelection = [
        {name: t("livestock:category-cattle"), id: 'cattle'},
        {name: t("livestock:category-higs"), id: 'hogs'},
        {name: t("livestock:category-poultry"), id: 'poultry'},
        {name: t("livestock:category-sheep"), id: 'sheep'},
        {name: t("livestock:category-goats"), id: 'goats'},]

    const {liveStockId} = useParams()
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (liveStockId)
            dispatch(getLiveStock(liveStockId))
            dispatch(getActivityByConnectionId(liveStockId!))
            dispatch(getTimeSeriesDataByItemId(liveStockId!))
    }, [dispatch, liveStockId])

    const {allTimeSeriesData} = useSelector((state: RootState) => state.timeSeriesData)
    console.log('Time Series Data', allTimeSeriesData)


    if (status === Status.Failure) {
        toast.error(t("livestock:failedGetLivestock"))
    }

    const [formData, setFormData] = useState({
        name: '',
        description: '',
        category: '',
        quantity: 0,
        dob: '',
        sex: '',
        group: '',
        breed: '',
        state: '',
        weight: 0,
    })

    //////////// CHANGED DATA STATE ///////////

    const [changedData, setChangedData] = useState({
        field: ''
    })

    const {field} = changedData
    let changedField : string


    useEffect(() => {
        if (status === Status.Success && liveStock !== undefined) setFormData((prevState) => ({
            ...prevState,
            name: liveStock.name,
            description: liveStock.description,
            category: liveStock.category,
            quantity: liveStock.quantity,
            dob:  liveStock.dob,
            sex:  liveStock.sex,
            group:  liveStock.group,
            breed:  liveStock.breed,
            state:  liveStock.status,
            weight: liveStock.weight,
        }));
    }, [status])

    const {name, description, category, quantity, dob, sex, group, breed, state, weight } = formData

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setChangedData(() => ({
            field: e.target.name 
        }))
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
        const liveStockData: LiveStockModel = {
            name,
            description,
            category: e.target.value,
            quantity,
            _id:liveStockId,
            dob,
            sex: e.target.value,
            group,
            breed,
            status: state,
            weight,
            changedField: e.target.id
        }

        dispatch(updateLiveStock(liveStockData))
            .unwrap()
            .then(() => {
                toast.success(t("livestock:livestockUpdated"))
            })
            .catch(() => {
                toast.error(t("livestock:failedUpdateLivestock"))
            })
    }

    const onKeyDown = (e: React.KeyboardEvent) => {
        console.log(e)
        if (e.key === "Enter" || e.key === "Escape") {
            (e.target as HTMLElement).blur();
        }
    }

    const onBlur = (e: React.FocusEvent) => {
        e.preventDefault()
        console.log('On Blur Hit')
        const liveStockData: LiveStockModel = {
            name,
            description,
            category,
            quantity,
            _id:liveStockId,
            dob,
            sex,
            group,
            breed,
            status:state,
            weight,
            changedField: changedData.field
        }

        console.log(liveStockData)
        dispatch(updateLiveStock(liveStockData))
            .unwrap()
            .then(() => {
                toast(t("livestock:livestockUpdated"))
            })
            .catch(() => {
                toast(t("livestock:failedUpdateLivestock"))
            })
    }

    ////////////////////////////////////////////
    // Delete and Modal CODE
    ////////////////////////////////////////////
    
    const onDelete = () => {
        dispatch(deleteLiveStock([liveStockId!]))
        .unwrap()
        .then(() => {
            toast.success(t("liveStock:liveStockDeleted"));
            navigate("/livestock")
        })
        .catch(() => {
            toast.error(t("liveStock:failedToDeleteLiveStock"))
        })
    }

    const [modalIsOpen, setModalIsOpen] = useState(false)

    const onDeleteClick = (e: any) => {
        setModalIsOpen(!modalIsOpen)
    }


    if (status === Status.Pending && liveStock?.name != '' && liveStock?.category != '') {
        return <Spinner/>
    } else {
        return (
            <>
                <div className="bg-white py-5 rounded-lg #drop-shadow-xl #shadow-lg relative px-9 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] mx-5 ">
                    <div className="grid grid-cols-3 gap-3">
                        <div className='-my-1 col-span-1 justify-self-center self-center'>
                            <img className="h-36 w-34 bg-indigo-400 shadow-xl rounded-xl"
                                 src="https://cdn-icons-png.flaticon.com/512/2833/2833675.png" alt=""/>
                        </div>
                        <div className='col-span2 justify-start justify-self-start'>
                            <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                        onKeyDown={(e) => onKeyDown(e)} title={"name"} value={name} id={"name"}
                                        name={"name"}/>
                            <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                        onKeyDown={(e) => onKeyDown(e)} title={"description"} value={description}
                                        id={"description"} name={"description"}/>
                            <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                        onKeyDown={(e) => onKeyDown(e)} title={"quantity"} value={quantity.toString()}
                                        id={"quantity"} name={"quantity"}/>
                            <InlineEditDropDown onChange={(e) => handleChange(e)} 
                                                onKeyDown={(e) => onKeyDown(e)} selections={categorySelection}
                                                value={category} id={"category"} title={"category"} name={"category"}/>
                            <InlineEditDropDown onChange={(e) => handleChange(e)} 
                                                onKeyDown={(e) => onKeyDown(e)} selections={sexSelection}
                                                value={sex} id={"sex"} title={"sex"} name={"sex"}/>
                        </div>
                    </div>
                    {/* ////////////////////////////////////////////////////////////////////// */}
                    {/* //////////////////////////// DELETE BUTTON /////////////////////////// */}

                    <ItemPageDeleteButton onDeleteClick={onDeleteClick} />

                </div>

                {/* ////////////////////////////////////////////////////////////////////// */}
                {/* //////////////////////////// DELETE MODAL //////////////////////////// */}

                <DeleteModal modalIsOpen={modalIsOpen} name={liveStock?.name!} onDeleteClick={onDeleteClick} onDelete={onDelete} />

                {/* ////////////////////////////////////////////////////////////////////// */}
                {/* //////////////////////////// ACTIVITY LOG //////////////////////////// */}
                
                <ChangeActivityList allActivity={allActivity} page={'Live Stock'} />

                <AddDataTopic/>


                <div className='pt-5'>
                    {allTimeSeriesData?.map((item) => ( 
                        <>
                            <DataChart chartData={item} />
                        </>
                    ) )}
                </div>



                {/* <div>
                    <AddWeight/>
                </div>
                <div>
                    <WeightChart/>
                </div> */}
            </>
        )
    }
}


export default LiveStockPage