import React, {useState, useEffect, ChangeEvent, FormEvent} from 'react'
import {toast} from 'react-toastify'
import {useSelector, useDispatch} from 'react-redux'
import { reset, forgotpassword } from '../../../features/auth/authSlice'
import Spinner from '../../components/Spinner'
import {AppDispatch, RootState} from "../../../app/store";

function Login() {

  const [formData, setFormData] = useState({
      email:'',

  })

  const {email} = formData

    const dispatch = useDispatch<AppDispatch>()
 
  const {user, isLoading, message} = useSelector((state : RootState)=> state.auth)

  useEffect(() => {
      if(message) {
    toast.success(message) }
    dispatch(reset())

  },[ message ] )

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      setFormData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
      }))}

      const onSubmit = (e: FormEvent) => {
          e.preventDefault()

          const userData = {
              email
          }

          dispatch(forgotpassword(userData))
      }

      if (isLoading) {
          return <Spinner/>
      }
  return (
    <>
        <section className="heading">
            <h1>
                Reset Password
            </h1>
        </section>

        <section className="form">
            <form onSubmit={onSubmit}>
                <div className="form-group">
                    <input 
                    type="email" 
                    className="form-control"
                    id='email'
                    name='email'
                    value={email}
                    onChange={onChange}
                    placeholder='Enter your email'
                    required />
                </div>
                <div className="form-group">
                    <button className="btn btn-block">
                        Submit
                    </button>
                </div>
            </form>
        </section>
    </>
  )
}

export default Login