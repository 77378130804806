import React, {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {getAllActivity} from '../../../features/activity/activitySlice'
import Spinner from '../../components/Spinner'
import {DataGrid, GridColumns} from '@mui/x-data-grid';
import {Link} from 'react-router-dom'
import {AppDispatch, RootState} from "../../../app/store";
import {Status} from "../../../types/slice";
import {useTranslation} from "react-i18next";

const actionColumn : GridColumns = [
    {
        field: 'action',
        headerName: "Action",
        width: 200,
        renderCell: (params) => {
            return (
                <div className='flex flex-row px-2 pl-4 justify-center items-center gap-2' >
                    <Link to={`/activity/${params.row._id}`} className="border-2 rounded-lg border-blue-500 px-2">View</Link>
                    <div className='border-2 rounded-lg border-red-400 px-2' >Delete</div>
                </div>
            )
        }
    }
]

const columns : GridColumns = [
  { field: '_id', headerName: 'ID', width: 70 },
  // {
  //   field: 'img',
  //   headerName: 'User',
  //   description: 'This column has a value getter and is not sortable.',
  //   sortable: false,
  //   width: 160,
  //   renderCell:(params)=> {
  //       return(

  //               <div className='flex flex-row items-center' >
  //                   <img className=' rounded-xl  w-9 h-9 mr-4'  src="https://icons.veryicon.com/png/o/education-technology/passenger-flow-analysis-icon-library/activity-assessment.png" alt="" />
  //                   <div className='text-indigo' >
  //                   {params.row.title}
  //                   </div>
  //               </div>

  //       )
  //   }
  // },

  // { field: 'description', headerName: 'description', width: 230 },
  // {
  //   field: 'equipment',
  //   headerName: 'equipment',
  //   type: 'string',
  //   width: 90,
  // },
  {
    field: 'activityType',
    headerName: 'activity',
    type: 'string',
    width: 90,
  },
  {
    field: 'connectionCategory',
    headerName: 'Category',
    type: 'string',
    width: 120,
  },
  // {
  //   field: 'connectionCategory',
  //   headerName: 'Category',
  //   type: 'string',
  //   width: 120,
  // },

  // {
  //   field: 'date',
  //   headerName: 'Date',
  //   width: 150,
  //   type: 'date',

  //   renderCell:(params)=> {
  //       return(
  //           <div className={``}>
  //                   {params.row.date.slice(0,10)}
  //           </div>

  //       )
  //   }
  // },
];




function ActivityTable() {
    const {allActivity, status} = useSelector((state : RootState) => state.activity )

    const {t} = useTranslation(["activity", "translation"])
    const dispatch = useDispatch<AppDispatch>();

    // useEffect(() => {
    //     dispatch(getAllActivity())
    // }, [dispatch])

    if (status === Status.Pending) {
        return <Spinner/>
    }
  return (
    <>
      
      <div style={{ height: 400, width: 'full' }}>
      <DataGrid
        getRowId={(row) => row._id}
        rows={allActivity}
        columns={columns.concat(actionColumn)}
        pageSize={10}
        rowsPerPageOptions={[5]}
        checkboxSelection
      />
    </div>
   
    </>
  )
}

export default ActivityTable