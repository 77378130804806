import React, {useState} from 'react'
import Modal from 'react-modal'
import {useSelector, useDispatch} from 'react-redux'
import Spinner from '../../components/Spinner'
import {Droppable} from 'react-beautiful-dnd'
import {addTaskToColumn} from '../../../features/tests/testSlice'
import Task from './Task'
import {AppDispatch, RootState} from "../../../app/store";
import {ColumnModel, KanbanModel} from "@asirisos/types";

Modal.setAppElement('#root')

type ColumnProps = {
    column: ColumnModel,
    tasks:  Array<KanbanModel>
}

function Column(props: ColumnProps) {

    const dispatch = useDispatch<AppDispatch>();

    const {columns, columnOrder, isError, isSuccess, isLoading} = useSelector((state: RootState) => state.test)

    ///  Implementing Modal for Add Column ///////////////

    const [modalIsOpen, setModalIsOpen] = useState(false)

    // Open Modal
    const openModal = () => setModalIsOpen(true)
    const closeModal = () => setModalIsOpen(false)

    const [newTaskData, setNewTaskData] = useState({
        title: ''
    })

    const {title} = newTaskData


    //// Handle on Change
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewTaskData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    ///// Handle on Submit
    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        const newTaskData = {
            columnId: props.column._id,
            title: title
        }


        console.log('OnSubmit', newTaskData)
        dispatch(addTaskToColumn(newTaskData))
        closeModal()
        // dispatch(getAllColumns())
        // dispatch(getAllTest())

        // const newColumn = {
        //     title
        // }

        // // const newColumnOrder = {
        // //     ...columnOrder,
        // // }

        // console.log('OnSubmit',newColumn)
        // dispatch(addColumn(newColumn))
        // closeModal()

    }

    ///////////////////////////////////////
    if (props.tasks === null || props.column._id === undefined || props.tasks === undefined || columns === null || columnOrder === null || isLoading) {
        return <Spinner/>
    } else {

        // @ts-ignore
        return (
            <div>
            <>
                        {/* <div>
              <h1 className="absolute top-0 left-0" >X</h1>
              </div> */}
              
                        <h2 className="">{props.column.title}</h2>
                        {console.log(props.column)}
                        <Droppable droppableId={props.column._id}>
                            {(provided, snapshot) => (
                                <>
                                    <div
                                        className={`p-5 mx-3 w-60 min-h-96 h-96 rounded-lg shadow-lg  ${snapshot.isDraggingOver ? "bg-slate-200" : "bg-slate-100"} `}
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}>
                                        {props.tasks.map((task, index) => <Task key={task._id} task={task} index={index}/>)}
                                        {provided.placeholder}
                                    </div>

                                    <button onClick={openModal}
                                            className="bg-indigo-600 px-3 py-2 mt-6 text-white drop-shadow-lg rounded-lg">Add
                                        Task
                                    </button>
                                    {/*<Modal column={column} className="p-15 border-none" isOpen={modalIsOpen}*/}
                                    {/*       onRequestClose={closeModal} contentLabel="Add Column">*/}
                                    {/*    <h1 className=" p-10 " onClick={closeModal}>Close</h1>*/}
                                    {/*    <form onSubmit={onSubmit}>*/}
                                    {/*        <h1>Add Task To {column.title} Column</h1>*/}
                                    {/*        <input*/}
                                    {/*            type="title"*/}
                                    {/*            placeholder="title"*/}
                                    {/*            id="title"*/}
                                    {/*            name="title"*/}
                                    {/*            value={title}*/}
                                    {/*            onChange={onChange}*/}
                                    {/*            className="input input-bordered w-full max-w-xs"/>*/}
                                    {/*        <button className="btn mt-5 m-3 btn-wide btn-primar hover:bg-black">Add Task*/}
                                    {/*        </button>*/}
                                    {/*    </form>*/}

                                    {/*</Modal>*/}
                                </>
                            )}
                        </Droppable>
            </>
            </div>
        )
    }
}

export default Column