import axios from "axios"
import RequestBodyFactory from "../../factories/requestBodyFactory";

const API_URL = '/api/stripe'

type PurchaseItemModel = {
    purchaseItem: string,
    price?: string,
    user_id: string
}


const createCheckoutSession = async (purchaseItemData: PurchaseItemModel | any) => {
    const body = new RequestBodyFactory(purchaseItemData).Build()
    console.log("Stripe Service - Create Session - body", body)
    return await axios.post(API_URL + '/create-checkout-session', body)
}

// const getAllImages = async () => {
//     return await axios.get(API_URL + '/getAllImages')
// }

// const getLiveStock = async (liveStockId: string) => {
//     return await axios.get(API_URL + '/' + liveStockId)
// }

// const updateLiveStock = async(liveStockData: LiveStockModel, account: AccountModel) => {
//     const {_id} = liveStockData

//     const body = new RequestBodyFactory(liveStockData).WithAccount(account).Build()
//     return await axios.put(API_URL + '/' + _id, body)
// }

// const deleteLiveStock = async(liveStockId: string[]) => {
//     const ids = liveStockId
//     const config = {
//         data: ids
//     }
//     return await axios.delete(API_URL+'/'+ liveStockId, config)
// }

const stripeService = {
    createCheckoutSession
}

export default stripeService