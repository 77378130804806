import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import FormCard from '../../components/shared/FormCard'
import FormTwoInputs from '../../components/shared/FormTwoInputs'
import NumberInputInUnits from '../../components/shared/NumberInputInUnits'
import LargeTextInput from '../../components/shared/LargeTextInput'
import LegacyDropDownInput from '../../components/shared/LegacyDropDownInput'
import {addInventory} from '../../../features/inventory/inventorySlice'
import {AppDispatch} from "../../../app/store";
import {InputSelection} from "../../../types/ui";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {InventoryModel} from "@asirisos/types";

function InventoryForm() {
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const {t} = useTranslation(["inventory", "translation"])
    const canSave = true;

    const typeSelection = [
        new InputSelection(t("inventory:categoryFeed"), 'feed'),
        new InputSelection(t("inventory:categoryChemicals"), 'chemicals'),
        new InputSelection(t("inventory:categoryFertilizerAndConditioners"), 'fertilizerAndConditioners'),
        new InputSelection(t("inventory:categoryFuel"), 'fuel'),
        new InputSelection(t("inventory:categoryFarmSupplies"), 'farmSupplies'),
        new InputSelection(t("inventory:categoryEquipmentParts"), 'equipmentParts'),
        new InputSelection(t("inventory:categorySeedsAndPlants"), 'seedsAndPlants'),
        new InputSelection(t("inventory:categoryEquipmentMaintenance"), 'equipmentMaintenance'),
        new InputSelection(t("inventory:categoryInfrastructureMaintenance"), 'infrastructureMaintenance'),
        new InputSelection(t("inventory:categoryLivestockMaintenance"), 'livestockMaintenance'),
        new InputSelection(t("inventory:categoryOther"), 'other'),]

    const unitSelection = [
        new InputSelection(t("inventory:unitsLb"), 'lb'),
        new InputSelection(t("inventory:unitsKg"), 'kg'),
        new InputSelection(t("inventory:unitsPack"), 'pack'),
        new InputSelection(t("inventory:unitsCount"), 'count'),]

    const [formData, setFormData] = useState({
        name: '',
        description: '',
        value: '',
        primaryUse: '',
        unit: '',
        type: '',
        quantity: 0,
    })

    const {name, description, type, quantity, unit, value} = formData

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault()

        if (canSave) {
            const inventoryData : InventoryModel = {
                name, description, type, quantity, unit, value
            }

            dispatch(addInventory(inventoryData))
                .unwrap()
                .then(() => {
                    toast.success(t("inventory:inventoryAdded"));
                    navigate("/inventory")
                })
                .catch(() => {
                    toast.error(t("inventory:inventoryAddFailed"))
                    navigate("/inventory-form")
                })
        }
    }

    return (
        <>
            <FormCard title={t("inventory:addInventory")}>
                <form onSubmit={onSubmit}>
                    <FormTwoInputs>
                        <NumberInputInUnits onChange={(e) => onChange(e)} value={name} id={"name"} type={'text'}
                                            group={"name"} label={"name"} name={"name"} dataTitle={t("translation:title")}/>
                        <LegacyDropDownInput onChange={(e) => onChange(e)} selections={typeSelection} value={type} id={"type"}
                                             type={'text'} group={"type"} label={"type"} name={"type"} dataTitle={t("translation:type")}/>
                    </FormTwoInputs>

                    <FormTwoInputs>
                        <NumberInputInUnits denominations={['select', 'count', 'package', 'kg', 'lb']}
                                                dataTitle={t("inventory:quantity")} dName={"unit"}
                                                dValue={unit} dId={"unit"}
                                                dType={'number'}
                                                onChange={(e) => onChange(e)} value={quantity.toString()}
                                                id={"quantity"} type={'number'} name={"quantity"}/>                    
                        <NumberInputInUnits denominations={['select', 'count', 'package', 'kg', 'lb']}
                                                dataTitle={t("inventory:value")} dName={"unit"}
                                                dValue={unit} dId={"unit"}
                                                dType={'string'}
                                                onChange={(e) => onChange(e)} value={value.toString()}
                                                id={"value"} type={'number'} name={"value"}/>
                    </FormTwoInputs>
                    <div className='mx-16' >
                        <LargeTextInput onChange={(e) => onChange(e)} value={description} id={"description"} type={'text'} group={"description"} label={"description"}
                            name={"description"} dataTitle={t("translation:description")} />
                    </div>

                    <div className='flex justify-center pt-8 p-2'>
                        <button className="btn btn-wide btn-primar hover:bg-black">{t("inventory:addInventory")}</button>
                    </div>
                </form>
            </FormCard>
        </>
    )
}

export default InventoryForm