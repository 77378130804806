import React, {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {getCrops} from '../../../features/crops/cropSlice'
import Spinner from '../../components/Spinner'
import {DataGrid, GridColumns} from '@mui/x-data-grid';
import {AppDispatch, RootState} from "../../../app/store";
import {toast} from "react-toastify";
import {Status} from "../../../types/slice";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const columns = (t: any):GridColumns => [
    {field: '_id', headerName: t("translation:id"), width: 70},
    {
        field: 'img',
        headerName: t("translation:user"),
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        width: 160,
        renderCell: (params) => {
            return (

                <div className='flex flex-row items-center'>
                    <img className=' rounded-xl  w-9 h-9 mr-4'
                         src="https://png.pngtree.com/element_our/png_detail/20181229/sprout-plant-graphic-icon-design-template-png_301529.jpg"
                         alt=""/>
                    <div className='text-indigo'>
                        {params.row.cropName}
                    </div>
                </div>

            )
        }
    },
    {field: 'numberOfTrees', headerName: t("crop:trees"), width: 120},
    {field: 'stockHarvested', headerName: t("crop:harvested"), width: 120},
    {field: 'waterNutrition', headerName: t("crop:waterNutrition"), width: 120},
    {
        field: 'action',
        headerName: t("translation:action"),
        width: 200,
        renderCell: (params) => {
            return (
                <div className='flex flex-row px-2 pl-4 justify-center items-center gap-2'>
                    <Link to={`/crop/${params.row._id}`}
                          className="border-2 rounded-lg border-blue-500 px-2">{t("translation:view")}</Link>
                    <div className='border-2 rounded-lg border-red-400 px-2'>{t("translation:delete")}</div>
                </div>
            )
        }
    }
];


function CropTable() {
    const {crops, status} = useSelector((state: RootState) => state.crop)
    const {t} = useTranslation(['crop','translation']);
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(getCrops())
    }, [dispatch])

    if (status === Status.Failure) {
        toast.error(t("crop:cropGetFailed"))
    }

    if (status === Status.Pending) {
        return <Spinner/>
    }

    return (
        <>

            <div style={{height: 400, width: 'full'}}>
                <DataGrid
                    getRowId={(row) => row._id}
                    rows={crops}
                    columns={columns(t)}
                    pageSize={10}
                    rowsPerPageOptions={[5]}
                    checkboxSelection
                />
            </div>

        </>
    )
}

export default CropTable