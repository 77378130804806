import { access } from 'fs/promises'
import React from 'react'

export const SidebarData = [
    {
        title: "Farm",
        icon: <i className="fas fa-house-chimney-window "></i>,
        link: "/my-farm",
        complete: true,
        access: ['farms', 'farmsEdit']
    },
    {
        title: "Crops",
        icon: <i className="fas fa-seedling"></i>,
        link: "/crops",
        complete: true,
        access: ['crops', 'cropsEdit']
    },
    {
        title: "Live Stock",
        icon: <i className="fas fa-cow"></i>,
        link: "/livestock",
        complete: true,
        access: ['liveStock', 'liveStockEdit']
    },
    {
        title: "Employees",
        icon: <i className="fas fa-user-friends"></i>,
        link: "/employees",
        complete: true,
        access: ['employees', 'employeesEdit']
    },
    {
        title: "Equipment",
        icon: <i className="fas fa-tractor"></i>,
        link: "/equipment",
        complete: true,
        access: ['equipment', 'equipmentEdit']
    },
    {
        title: "Inventory",
        icon: <i className="fas fa-boxes-stacked"></i>,
        link: "/inventory",
        complete: true,
        access: ['inventory', 'inventoryEdit']
    },
    {
        title: "Storage",
        icon: <i className="fas fa-warehouse"></i>,
        link: "/storage",
        complete: true,
        access: ['storage', 'storageEdit']
    },
    {
        title: "Tasks",
        icon: <i className="fa-solid fa-list-check"></i>,
        link: "/tasks",
        complete: true,
        access: ['tasks', 'tasksEdit']
    },
    {
        title: "Activity",
        icon: <i className="fas fa-clipboard-list"></i>,
        link: "/activity",
        complete: true,
        access: ['activity', 'activityEdit']
    },
    {
        title: "Customers",
        icon: <i className="fas fa-address-card"></i>,
        link: "/customers",
        complete: true,
        access: ['customers', 'customersEdit']
    },
    {
        title: "Finances",
        icon: <i className="fas fa-file-invoice-dollar"></i>,
        link: "/finances",
        complete: true,
    },
    {
        title: "Sales",
        icon: <i className="fas fa-hand-holding-usd"></i>,
        link: "/sales",
        complete: true,
        access: ['sales', 'salesEdit']
    },
    {
        title: "Expenses",
        icon: <i className="fas fa-solid fa-circle-dollar-to-slot"></i>,
        link: "/expenses",
        complete: true,
        access: ['sales', 'salesEdit']
    },
    {
        title: "Yields",
        icon: <i className="fas fa-solid fa-carrot"></i>,
        link: "/yield",
        complete: true,
        access: ['sales', 'salesEdit']
    },
    {
        title: "Fields",
        icon: <i className="fas fa-map-location-dot"></i>,
        link: "/field",
        complete: true,
        access: ['sales', 'salesEdit']
    },
    {
        title: "Infrastructure",
        icon: <i className="fas fa-tent-arrow-down-to-line"></i>,
        link: "/infrastructure",
        complete: true,
        access: ['sales', 'salesEdit']
    },
    {
        title: "Markets",
        icon: <i className="fas fa-store"></i>,
        link: "/markets",
        complete: true,
        access: ['sales', 'salesEdit']
    },
    {
        title: "β - Kanban",
        icon: <i className="fa-solid fa-list-check"></i>,
        link: "/kanban",
        complete: false,
        roles: ['admin','hr', 'finance'],
        access: ['general']
    },
]
