import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import FormCard from '../../components/shared/FormCard'
import FormTwoInputs from '../../components/shared/FormTwoInputs'
import NumberInputInUnits from '../../components/shared/NumberInputInUnits'
import LargeTextInput from '../../components/shared/LargeTextInput'
import LegacyDropDownInput from '../../components/shared/LegacyDropDownInput'
import { addStorage } from '../../../features/storage/storageSlice'
import { getCrops } from '../../../features/crops/cropSlice'
import {InputSelection} from "../../../types/ui";
import {toast} from 'react-toastify'
import { getAllLiveStock } from 'features/live-stock/live-stockSlice'
import { StorageModel } from '@asirisos/types'
import { useTranslation } from 'react-i18next'
import {AppDispatch, RootState} from "../../../app/store";
import FormDisplaySearchableMap from '../Map/FormDisplaySearchableMap'
import ConfirmAddress from '../Map/ConfirmAddress'
import SearchableMap from '../Map/SearchableMap-1'
import SearchBox from '../Map/SearchBox-1'


function StorageForm() {
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const {t} = useTranslation('storage');
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    USE SELECTOR   ///////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const {user} = useSelector((state : RootState) => state.auth)

    useEffect(() => {
        dispatch(getCrops())
        dispatch(getAllLiveStock())
    },[dispatch] )


    const {crops} = useSelector((state: RootState) => state.crop )
    const {allLiveStock} = useSelector((state: RootState) => state.livestock )

    const cropSelections = crops.map((crop) => {return {name: crop.cropName, id:crop._id}})
    const liveStockSelections = allLiveStock.map((livestock) => {return {name: livestock.name, id:livestock._id}})

    const categorySelections = [
        new InputSelection('crop', 'crop'),
        new InputSelection('live stock', 'live stock')]
    

    // console.log(cropSelections, 'selections')
    // const emptySelection = [{name: '', id:''}]

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    USE STATE      ///////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const [selectPosition, setSelectPosition] = useState<any>()

    const [formData, setFormData] = useState({
        name: '',
        description: '',
        category: '',
        quantity: 0,
        comments: '',
        crop: '',
        liveStock: '',
        leaseQuantity: '',
        leaseCost: '',
        leaseUnit: '',
        value: '',
        unit: '',
        storageSettings: '',
        lat: '',
        lon: '',
        fullAddress: '',
        addressObject: {},
    })

    const {
        name, description, category, value, unit, quantity, comments, crop,
        liveStock, storageSettings, leaseCost, leaseUnit, leaseQuantity, lat, lon, fullAddress, addressObject} = formData


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }) )
    }

    const onSetAddress = (selectPosition : object) => {
        setFormData((prevState) => ({
            ...prevState,
            addressObject: selectPosition!
        }) )
    }

    const onKeyDown = (e: any) => {
        if (e.code === "Enter") {
            console.log("code equals enter")
            e.preventDefault()
        }
        
    }

    const onSubmit = (e: React.FormEvent | React.KeyboardEvent) => {
        
        e.preventDefault()
        console.log("Form Submit",e)
        
        const storageData : StorageModel = {
            name, description, category, unit, value, quantity, comments, 
            crop, liveStock, storageSettings, leaseCost, leaseUnit, leaseQuantity,
            lat, lon, fullAddress, addressObject, leasedStorage, storageAddress
        }
        // console.log(storageData)
        dispatch(addStorage(storageData))
        .unwrap()
        .then(() => {
            toast.success("storage:storageAdded");
            navigate("/storage")
        })
        .catch(() => {
            toast.error(t("storage:storageAddFailed"))
            navigate("/storage-form")
        })
    }

    const [leasedStorage, setLeasedStorage] = React.useState('false');

    const onToggleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const toggleValue = e.target.value

        if (toggleValue === 'true') { setLeasedStorage('false') }
        if (toggleValue === 'false') { setLeasedStorage('true') }
        
   }

   const [storageAddress, setstorageAddress] = React.useState('false');

   const onToggleAddressChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
       const toggleValue = e.target.value

       if (toggleValue === 'true') { setstorageAddress('false') }
       if (toggleValue === 'false') { setstorageAddress('true') }
       
  }

   const onToggleClick = (e: React.MouseEvent<HTMLButtonElement>) => {

    if (leasedStorage === 'true')
        setLeasedStorage('false')
    if (leasedStorage === 'false')
        setLeasedStorage('true')
    
}
const [data, setData] = useState('');

const childToParent = (selectPosition : any) => {
    console.log('Select Position in Storage Form', selectPosition)
    setData(selectPosition)
    console.log('Set Data in Storage Form', data)
  }

    
  return (
    <>
    
        <FormCard title="Add Storage">
        <form onSubmit={onSubmit}>
            
            <div className='w-3/4 pb-6 pl-8' >
                <NumberInputInUnits onChange={(e) => onChange(e)} value={name} id={"name"} onKeyDown={(e) => onKeyDown(e)}
                type={'text'} group={"name"} label={"name"} name={"name"} dataTitle="Title" />
            </div>
                

                {/* <LegacyDropDownInput onChange={(e) => onChange(e)} selections={categorySelections} value={category} id={"category"} type={'text'}
                                group={"category"} label={"category"} name={"category"} dataTitle="category" /> */}
            
            <div className='my-5 flex flex-col pb-5'>
                <div className='flex flex-col'>
                <h1 className= "pt-3 flex justify-start ml-10 text-md text-slate-500" >Location</h1>

                <div className='mb-6' >
                    <FormTwoInputs>
                        <div className='flex flex-row'>
                            
                                <h1 className={`mr-3 text-slate-500 text-md ${storageAddress === 'true' ? 'text-slate-500' : 'text-blue-500 font-bold'}`}>Farm Address</h1>
                            
                            <label className="relative inline-flex items-center cursor-pointer">
                                <input type="checkbox" onChange={(e) => onToggleAddressChange(e)} value={storageAddress} className="sr-only peer"/>
                                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600"></div>
                                <h1 className={`ml-3 text-slate-500 text-md ${storageAddress === 'false' ? 'text-slate-500' : 'text-blue-500 font-bold'}`} >Other Address</h1>
                            </label>
                        </div>
                    </FormTwoInputs>
                </div>
            </div>


                {/* <FormDisplaySearchableMap childToParent={childToParent}/> */}
                { storageAddress === 'true'  ? ( <>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        // justifyContent: "center",
                        width: "80vw",
                        height: "30vh",
                    }} >
                    
                        <div className="pl-8 flex flex-col justify-items-start" style={{ width: "50vw" }}>
                            <SearchBox  selectPosition={selectPosition} setSelectPosition={setSelectPosition} onSetAddress={onSetAddress} name={"addressObject"} value={addressObject} onChange={(e : any) => onChange(e) } />
                            
                        </div>
                        <div style={{ width: "50vw", height: "100%" }}>
                            <SearchableMap lat ={selectPosition?.lat} lon={selectPosition?.lon} selectPosition={selectPosition} />
                        </div>
                        {/* <button primary onClick={() => props.childToParent(selectPosition)}>Click Child</button> */}
                    
                    </div>
                </>) : (<></>) }
            </div>

            <FormTwoInputs>
                <NumberInputInUnits denominations={['select', 'count', 'package', 'kg', 'lb']}
                                        dataTitle={t("storage:quantity")} dName={"unit"}
                                        onKeyDown={(e) => onKeyDown(e)}
                                        dValue={unit} dId={"unit"}
                                        dType={'number'}
                                        onChange={(e) => onChange(e)} value={quantity.toString()}
                                        id={"quantity"} type={'number'} name={"quantity"}/>                    
                <NumberInputInUnits denominations={['select', 'count', 'package', 'kg', 'lb']}
                                        dataTitle={t("storage:value")} dName={"unit"}
                                        onKeyDown={(e) => onKeyDown(e)}
                                        dValue={unit} dId={"unit"}
                                        dType={'string'}
                                        onChange={(e) => onChange(e)} value={value.toString()}
                                        id={"value"} type={'number'} name={"value"}/>
            </FormTwoInputs>

            <FormTwoInputs>
            <LegacyDropDownInput onChange={(e) => onChange(e)} selections={categorySelections} value={category} id={"category"} type={'text'}
                                group={"category"} label={"category"} name={"category"} dataTitle={t("storage:category")} />
                            
                            {category === 'crop' ? (
                            <>
                                <LegacyDropDownInput onChange={(e) => onChange(e)} selections={cropSelections} value={crop} id={"crop"} type={'text'} group={"crop"} label={"crop"} name={"crop"} dataTitle="Crop" />
                            </>) : (<></>) }

                        {category === 'live stock' ? (
                            <>
                                <LegacyDropDownInput onChange={(e) => onChange(e)} selections={liveStockSelections} value={liveStock} id={"liveStock"} type={'text'} group={"liveStock"} label={"liveStock"} name={"liveStock"} dataTitle="Live Stock" />
                            </>) : (<></>) }
            </FormTwoInputs>

            <FormTwoInputs>
            <div className='pt-4 mt-8 pb-3 flex flex-row' >
                {/* <button onClick={(e) => onToggleClick(e) } > */}
                    <h1 className={`mr-3 text-slate-500 text-md ${leasedStorage === 'true' ? 'text-slate-500' : 'text-blue-500 font-bold'}`}>Personal Storage</h1>
                {/* </button> */}
                <label className="relative inline-flex items-center cursor-pointer">
                    <input type="checkbox" onChange={(e) => onToggleChange(e)} value={leasedStorage} className="sr-only peer"/>
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600"></div>
                    <h1 className={`ml-3 text-slate-500 text-md ${leasedStorage === 'false' ? 'text-slate-500' : 'text-blue-500 font-bold'}`} >Leased Storage</h1>
                </label>
            </div>
            </FormTwoInputs>

            {leasedStorage === 'false' ? (<></>) : (<>
            <FormTwoInputs>
                <NumberInputInUnits denominations={['select', 'count', 'package', 'kg', 'lb']}
                                        dataTitle={t("storage:leaseQuantity")} dName={"leaseUnit"}
                                        onKeyDown={(e) => onKeyDown(e)}
                                        dValue={leaseUnit} dId={"leaseUnit"}
                                        dType={'number'}
                                        onChange={(e) => onChange(e)} value={leaseQuantity.toString()}
                                        id={"leaseQuantity"} type={'number'} name={"leaseQuantity"}/>    
                <NumberInputInUnits denominations={['select', 'count', 'package', 'kg', 'lb']}
                                        dataTitle={t("storage:leaseCost")} dName={"leaseUnit"}
                                        onKeyDown={(e) => onKeyDown(e)}
                                        dValue={leaseUnit} dId={"leaseUnit"}
                                        dType={'string'}
                                        onChange={(e) => onChange(e)} value={leaseCost.toString()}
                                        id={"leaseCost"} type={'number'} name={"leaseCost"}/>
            </FormTwoInputs> </>) }

            <div className='mx-16' >
                <LargeTextInput onChange={(e) => onChange(e)} value={description} id={"description"} 
                    onKeyDown={onKeyDown}
                    type={'text'} group={"description"} label={"description"}
                    name={"description"} dataTitle={t("translation:description")} />

                    <div className='pt-5'></div>
                <LargeTextInput onChange={(e) => onChange(e)} value={storageSettings} id={"storageSettings"} 
                    onKeyDown={onKeyDown}
                    type={'text'} group={"storageSettings"} label={"storageSettings"}
                    name={"storageSettings"} dataTitle={t("storage:storageSettings")} />
            </div>

          

        <div className='flex justify-center pt-8 p-2' >
            <button className="btn btn-wide btn-primar hover:bg-black">Add Storage</button>
        </div>    
        </form>
        </FormCard>
    </>
  )
}

export default StorageForm