import React, {ChangeEvent} from 'react'
import { Link } from 'react-router-dom'
import SearchBox from '../Map/SearchBox-1'
import {useTabPersistence} from "../../../hooks/useTabPersistence";
import {useTranslation} from "react-i18next";
import {PageId} from "../../../types/pages";
import { useState } from 'react'
import DisplayAllFarmersMarkets from '../Map/DisplayAllFarmersMarkets';
import Button from "../../components/shared/Button"
import NumberInputInUnits from 'UI/components/shared/NumberInputInUnits';
import Modal from 'react-modal'

function CombatFighterProfile() {

    const {t} = useTranslation(["landing", "translation"])
    const {selection, toggleTab} = useTabPersistence(PageId.FighterProfile);

    const [formData, setFormData] = useState({
        addressObject: {},
      })

    const {addressObject} = formData

    const [fighterData, setFighterData] = useState({
        fighterPay: '',
      })
    
    const {fighterPay} = fighterData

    const [selectedPosition, setSelectPosition] = useState()


    const onSetAddress = (selectedPosition: any) => {
        setFormData((prevState) => ({
            ...prevState,
            addressObject: selectedPosition
        }) )
      }

    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
      }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////    HANDLE DELETE - DISPATCH DELETE   ///////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////
    // Delete and Modal CODE
    ////////////////////////////////////////////
  
    const [modalIsOpen, setModalIsOpen] = useState(false)
  
    const onDeleteClick = (e: any) => {
        setModalIsOpen(!modalIsOpen)
    }


  return (
    <>
    {/********************************************/}
    {/********************************************/}
    {/********************************************/}
    {/********************* MOBILE VERSION ***********************/}
    {/********************************************/}
    {/********************************************/}
    {/********************************************/}

    <div className='lg:hidden sm:visible' >

        <div className='flex flex-col'>

            {/********************************************/}
            {/********************* FIRST PAGE MOBILE - START ***********************/}
            {/********************************************/}

            <div className='w-screen h-screen flex flex-col justify-start align-middle '>

                {/********************************************/}
                {/********************* MENU BAR - START ***********************/}
                {/********************************************/}

                <div className='bg-black flex justify-around py-2 drop-shadow-md  '> 
                    <Link to="/combat-fighter-profile">
                        <div className='flex flex-col justify-center py-2' >
                            <h1 className='text-xl font-semibold text-white font-sofachrome' >Combat Link</h1>   
                        </div>
                    </Link>
                    <Link className=" text-white hover:bg-[#e50f0fa3] hover:shadow-lg rounded-lg px-4 items-center bg-[#e50f0f] flex justify-center " to={"/combat-login"} >
                        <h1 className=' text-lg font-normal justify-center align-middle'>Login</h1>
                    </Link>
                </div>

                {/********************************************/}
                {/********************* MENU BAR - END ***********************/}
                {/********************************************/}


                {/********************************************/}
                {/********************* FIRST PAGE MOBILE - START ***********************/}
                {/********************************************/}

                <div className='flex flex-col justify-start bg-white'>

                    
                    {/* FIGHTER PROFILE - START */}

                    <div className='flex bg-white shadow-[2px_1px_20px_3px_rgba(201,201,201,0.67)] pt-2'>
                        <div className='flex flex-col' >
                            <img className='rounded-tl-xl w-48' src="https://a.espncdn.com/combiner/i?img=/i/headshots/mma/players/full/2966188.png&w=350&h=254" alt="" />
                            <div className='border-2 w-11/12 self-center bg-white'></div>
                        </div>

                        <div className=' flex flex-col justify-start'>                                    
                            <h1 className='text-lg font-light self-start font-sofachrome text-[#e50f0f]'>THE TIGER</h1>   
                            <h1 className='self-start font-light text-md pb-3 pl-1 text-slate-600' >Bantamweight</h1>                     
                            <h1 className='max-w-xl text-2xl font-normal text-slate-700 self-start pl-2'>Magomed</h1>
                            <h1 className='max-w-xl text-2xl font-normal text-slate-700 self-start pl-2' >Magomedov</h1>                    
                        </div>
                    </div>


                    <div className='grid grid-cols-3 bg-white py-4'>
                        <div>
                            <Link to="/combat-contact">
                                <button className='py-1 bg-white border-red-500 text-slate-700 border-2 px-5 rounded-md'>Contact</button>
                            </Link>
                        </div>
                        <div>
                            <Link to="/combat-sponsor">
                                <button className='py-1 bg-white border-red-500 text-slate-700 border-2 px-5 rounded-md'>Sponsor</button>
                            </Link>
                        </div>
                        <div>
                            <Link to="/combat-join-as-fan">
                                <button className='py-1 bg-white border-red-500 text-slate-700 border-2 px-5 rounded-md'>Follow</button>
                            </Link>
                        </div>
                    </div>            



                    {/* *********************** TAB SELECTIONS *********************** */}

                    <div className='border-2 border-slate-300' ></div>

                    <div className='mb-5  border-t-0 rounded-xl shadow-[2px_0px_10px_3px_rgba(201,201,201,0.57)] '>

                        <ul className="flex felx-row justify-left cursor-pointer">
                            <div onClick={() => toggleTab(1)} className={`${selection === 1 ? 'bg-[#e50f0f] text-white   w-48 py-1 ' : 'bg-white text-slate-700   w-48 py-1 '}`}>
                                <div className='' >
                                    <h1 className='text-2xl font-bold'>Home</h1>
                                </div>
                            </div>

                            <div onClick={() => toggleTab(2)} className={`${selection === 2 ? 'bg-[#e50f0f] text-white   w-48 py-1' : 'bg-white text-slate-700  w-48 py-1 '}`}>
                                <div className='' >
                                    <h1 className='text-2xl font-bold'>Posts</h1>
                                </div>
                            </div>

                            <div onClick={() => toggleTab(3)} className={`${selection === 3 ? 'bg-[#e50f0f] text-white   w-48 py-1' : 'bg-white text-slate-700  w-48 py-1 '}`}>
                                <div className='' >
                                    <h1 className='text-2xl font-bold'>Store</h1>
                                </div>
                            </div>

                        </ul>

                    </div>
                    


                    {/* *********************** TAB SELECTIONS *********************** */}

                    {selection === 1 ? (<>

                        <div className=' flex flex-col justify-around'>


                            <div className='grid grid-cols-3 self-start w-full gap-2 pb-3'>
                                <div className='py-1 border-r-2' >
                                    <h1 className='text-[#e50f0f] text-2xl font-sofachrome' >20</h1>
                                    <h1 className='text-slate-400 text-sm font-bold pt-1' >W</h1>
                                </div>
                                <div className='py-1 border-r-2' >
                                    <h1 className='text-[#e50f0f] text-2xl font-sofachrome' >3</h1>
                                    <h1 className='text-slate-400 text-sm font-bold pt-1' >L</h1>
                                </div>
                                <div className='py-1' >
                                    <h1 className='text-[#e50f0f] text-2xl font-sofachrome' >0</h1>
                                    <h1 className='text-slate-400 text-sm font-bold pt-1' >D</h1>
                                </div>
                            </div>

                            <div className='border-2'></div>

                            <div className='grid grid-cols-2'>
                                <div className='py-2'>
                                    <h1 className='text-slate-500 text-sm font-bold pt-1' >Height</h1>
                                    <h1 className='text-2xl font-sofachrome'>5'7"</h1>
                                </div>
                                <div className='py-2'>                 
                                    <h1 className='text-slate-500 text-sm font-bold pt-1' >Weight</h1>                   
                                    <h1 className='text-2xl font-sofachrome'>125</h1>
                                </div>
                            </div>

                            <div className='border-2'></div>

                            <div className='grid grid-cols-2' >
                                <h1 className='self-center py-1 text-slate-600 font-bold' >Promotion</h1>
                                <div className='flex flex-col'>
                                    <img className=" w-28 self-center" src="https://www.fightersonlymag.com/wp-content/uploads/2019/02/wsi-imageoptim-d6596a47-b971-40cc-af03-738fa7d70286_614571_tablet_landscape_large_16_9.jpg" alt="" />
                                </div>
                            </div>

                            <div className='border-2'></div>

                            <div className=''>
                                <h1 className='self-center py-1 text-slate-600 font-bold' >Sponsors</h1>
                                <div className='grid grid-cols-3 py-2 pb-4' >
                                    <div className='flex flex-col'>
                                        <img className=" w-20 self-center" src="https://smartseo-store-logos.s3.eu-central-1.amazonaws.com/eu.roka.com-ROKA_LOGO_2000x2000.png" alt="" />
                                    </div>

                                    <div className='flex flex-col'>
                                        <img className=" w-16 self-center" src="https://johnfhavel.files.wordpress.com/2012/11/tapout_logo.jpg" alt="" />
                                    </div>

                                    <div className='flex flex-col'>
                                        <img className=" w-20 self-center" src="https://static.wixstatic.com/media/fbe211_50e9069049c64da0945ad2a41c7f25d2~mv2.png/v1/fit/w_2500,h_1330,al_c/fbe211_50e9069049c64da0945ad2a41c7f25d2~mv2.png" alt="" />
                                    </div>

                                    <div className='flex flex-col'>
                                        <img className=" w-10 rounded-full self-center" src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="" />
                                        <h1> Sam Jackson</h1>
                                    </div>

                                    <div className='flex flex-col'>
                                        <img className=" w-10 rounded-full self-center" src="https://images.pexels.com/photos/5792641/pexels-photo-5792641.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="" />
                                        <h1> Rick Danson</h1>
                                    </div>

                                    <div className='flex flex-col'>
                                        <img className=" w-10 rounded-full self-center" src="https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="" />
                                        <h1> Jack Spott</h1>
                                    </div>

                                    
                                </div>
                            </div>

                            



                            <div className='border-2'></div>



                            {/* ***************** FIGHT HISTORY  ***************** */}

                            <div className='flex flex-col'>
                                <h1 className='self-center py-1 text-slate-600 font-bold pt-2'>Fight History</h1>



                                {/* ************** FIGHT 1 ************** */}
                                <div className='grid grid-cols-2 pt-4' >
                                    <div className='flex flex-col justify-center rounded-full' >
                                        <img className=' w-20 rounded-full self-center' src="https://a.espncdn.com/combiner/i?img=/i/headshots/mma/players/full/4606413.png&w=350&h=254" alt="" />
                                    </div>

                                    <div className='flex flex-col'>
                                        <h1 className='text-sm font-semibold self-start'>Danny Sabatello</h1>

                                        <div className='flex'>
                                            <h1 className='text-xs font-bold text-green-500 self-start'>WIN</h1>  
                                            <h1 className='text-xs font-normal self-start pl-1' > - Submission</h1>                                            
                                        </div>

                                        <h1 className='text-xs font-normal self-start' >Guillotine Choke</h1>                                  

                                        <div className='flex'>
                                            <h1 className='text-xs font-bold self-start' >RND: </h1>
                                            <h1 className='text-xs font-normal self-start pl-1' >1 - 3:55 </h1>
                                        </div>
                                    </div>                                                                    
                                </div>

                                <hr />

                                {/* ************** FIGHT 2 ************** */}
                                <div className='grid grid-cols-2 pt-4' >
                                    <div className='flex flex-col justify-center rounded-full' >
                                        <img className=' w-20 rounded-full self-center' src="https://a.espncdn.com/combiner/i?img=/i/headshots/mma/players/full/4350925.png&w=350&h=254" alt="" />
                                    </div>

                                    <div className='flex flex-col'>
                                        <h1 className='text-sm font-semibold self-start'>Patchy Mix</h1>

                                        <div className='flex'>
                                            <h1 className='text-xs font-bold text-red-500 self-start'>LOSS</h1>  
                                            <h1 className='text-xs font-normal self-start pl-1' > - Submission</h1>                                            
                                        </div>

                                        <h1 className='text-xs font-normal self-start' >Guillotine Choke</h1>                                  

                                        <div className='flex'>
                                            <h1 className='text-xs font-bold self-start' >RND: </h1>
                                            <h1 className='text-xs font-normal self-start pl-1' >2 - 2:39 </h1>
                                        </div>
                                    </div>                                                                    
                                </div>

                                <hr />                     

                                {/* ************** FIGHT 3 ************** */}

                                <div className='grid grid-cols-2 pt-4' >
                                    <div className='flex flex-col justify-center rounded-full' >
                                        <img className=' w-20 rounded-full self-center' src="https://a.espncdn.com/i/headshots/mma/players/full/3901014.png" alt="" />
                                    </div>

                                    <div className='flex flex-col'>
                                        <h1 className='text-sm font-semibold self-start'>Enrique Barzola</h1>

                                        <div className='flex'>
                                            <h1 className='text-xs font-bold text-green-500 self-start'>WIN</h1>  
                                            <h1 className='text-xs font-normal self-start pl-1' > - Submission</h1>                                            
                                        </div>

                                        <h1 className='text-xs font-normal self-start' >Guillotine Choke</h1>                                  

                                        <div className='flex'>
                                            <h1 className='text-xs font-bold self-start' >RND: </h1>
                                            <h1 className='text-xs font-normal self-start pl-1' >4 - 1:27 </h1>
                                        </div>
                                    </div>                                                                    
                                </div>

                                <hr />  

                            </div>


                            {/* ***************** FIGHT HISTORY  ***************** */}


                            {/* <Link to="" className='pt-10'>
                                <button className='w-32 rounded-md drop-shadow-2xl hover:drop-shadow-2xl bg-[#ca1010] py-1 font-normal text-white px-1 text-lg hover:bg-[#FFB100] hover:text-white'>
                                    Send Request
                                </button>
                            </Link> */}

                            </div>

                            <div className='py-3'>
                                <Link to="/combat-join-as-fighter">
                                    <button className='bg-[#e50f0f] py-2 px-3 rounded-md'>
                                        <h1 className='text-white text-xl'>Join as a fighter</h1>
                                    </button>
                                </Link>
                            </div>
                    
                    </>

                    ) : (<></>)}


                    {/* ************************* SELECTION 1 - END */}

                    {selection === 2 ? (<>
                    <div className='mx-4  bg-white shadow-[2px_1px_20px_3px_rgba(201,201,201,0.97)]' >

                        <div className='flex justify-center pt-4'>
                            <img className='w-9/12 rounded-t-lg self-center shadow-[2px_1px_20px_3px_rgba(201,201,201,0.97)]' src="https://cdn.vox-cdn.com/thumbor/AO01Tr-PpZt_gNmNFSudyxHiIVw=/1400x1400/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/22268164/magomedov_C07_8921.jpg" alt="" />
                        </div>
                        <div className='border-2'></div>

                        <div className='flex flex-col'>
                            <h1 className='text-md self-center font-normal pt-4'>Great fight - on to the next</h1>
                        </div>
                        
                        <div className='grid grid-cols-2 pt-2'>
                            <div className='bg-slate-100 text-xl font-bold py-3 border-r-2'>
                                <h1>Comment</h1>
                            </div>
                            
                            <div className='bg-slate-100 text-xl font-bold py-3'>
                                <h1>Share</h1>
                            </div>
                        </div>
                    </div>

                    <div className='mt-6 py-3 bg-white shadow-[2px_1px_20px_3px_rgba(201,201,201,0.87)]'>
                        <Link to="/combat-join-as-fan">
                            <button className='text-slate-700 py-2 px-4 bg-[#e50f0f] rounded-md shadow-[2px_1px_20px_3px_rgba(201,201,201,0.67)]' >
                                <h1 className='font-normal text-xl text-white'>Join to see more</h1>
                            </button>
                        </Link>
                    </div>
                    </>
                    ) : (<></>)}

                    {selection === 3 ? (<>
                    <div className='grid grid-cols-2 mx-2 gap-3 pb-5'>

                         {/* ////////////////////////////////////////////////////////////////////// */}
                        {/* //////////////////////////// DELETE BUTTON /////////////////////////// */}

                        {/* <ItemPageDeleteButton onDeleteClick={onDeleteClick} /> */}

                        {/* ////////////////////////////////////////////////////////////////////// */}
                        {/* //////////////////////////// DELETE MODAL //////////////////////////// */}

                        { modalIsOpen ? (<>
                            <>
                            <Modal isOpen={modalIsOpen} className="justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none" >
                                <div className='max-w-1/3 bg-slate-50 w-1/2 h-1/4 rounded-xl flex flex-col justify-center items-center shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]' >
                                    <h1 className='text-2xl font-semibold' >Coming Soon!</h1>
                                    <button className='mt-8 py-2 px-5 bg-[#e50f0f] rounded-md' onClick={() => setModalIsOpen(false)}>
                                        <h1 className='text-white' >Close</h1>
                                    </button>
                                </div>
                            </Modal>
                        </>
                        </>) : (<></>) }
                        


                        <div className='  bg-white pb-2 ' >
                            
                            <div className='flex justify-center pt-2'>
                                <img className='h-40 self-center' src="https://images3.teeshirtpalace.com/images/productImages/ftr4048027-fearless-tiger--rising-sun-japanese-art--kanji-writing--black-yt-garment.webp?width=700" alt="" />
                            </div>
                            

                            <div className='flex flex-col'>
                                <h1 className='text-md self-start font-normal pl-2'>Fearless Tiger Tee</h1>
                                <h1 className='text-sm self-start font-light pl-2'>$34.99</h1>
                            </div>
                            
                            <button onClick={() => setModalIsOpen(true)} className=' self-center border-2 border-[#e50f0f] text-balck bg-white rounded-lg text-sm py-1 my-2 w-8/12 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.67)]' >
                                <div className=''>
                                    <h1>PRE-ORDER</h1>
                                </div>
                            </button>

                        </div>

                        

                        <div className='  bg-white ' >
                            <div className='flex pt-2 justify-center'>
                                <img className='h-40 self-center' src="https://m.media-amazon.com/images/I/61uTMcw1D3L._AC_UY580_.jpg" alt="" />
                            </div>

                            <div className='flex flex-col'>
                                <h1 className='text-md self-start font-normal pl-2'>Fearless Tiger Shorts</h1>
                                <h1 className='text-sm self-start font-light pl-2'>$34.99</h1>
                            </div>
                            
                            <button onClick={() => setModalIsOpen(true)} className=' self-center border-2 border-[#e50f0f] text-balck bg-white rounded-lg text-sm py-1 my-2 w-8/12 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.67)]' >
                                <div className=''>
                                    <h1>PRE-ORDER</h1>
                                </div>
                            </button>
                            

                        </div>

                        <div className='  bg-white ' >
                            <div className='flex justify-center'>
                                <img className='h-40 self-center' src="https://cygnusfashion.com/cdn/shop/products/DESING4ffffff_0b07c6d7-ef63-439a-967c-b94847cf3aed.jpg?v=1680720614" alt="" />
                            </div>

                            <div className='flex flex-col'>
                                <h1 className='text-md self-start font-normal pl-2'>Fearless Tiger Tee</h1>
                                <h1 className='text-sm self-start font-light pl-2'>$24.99</h1>
                            </div>
                            
                            <button onClick={() => setModalIsOpen(true)} className=' self-center border-2 border-[#e50f0f] text-balck bg-white rounded-lg text-sm py-1 my-2 w-8/12 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.67)]' >
                                <div className=''>
                                    <h1>PRE-ORDER</h1>
                                </div>
                            </button>
                            

                        </div>

                        <div className='  bg-white ' >
                            <div className='flex justify-center'>
                                <img className='h-40 self-center' src="https://abilityministry.com/wp-content/uploads/2023/03/unisex-heavy-blend-hoodie-navy-back-6419c3e40aff1.png" alt="" />
                            </div>

                            <div className='flex flex-col'>
                                <h1 className='text-md self-start font-normal pl-2'>Fearless Tiger Hoodie</h1>
                                <h1 className='text-sm self-start font-light pl-2'>$59.99</h1>
                            </div>
                            
                            <button onClick={() => setModalIsOpen(true)} className=' self-center border-2 border-[#e50f0f] text-balck bg-white rounded-lg text-sm py-1 my-2 w-8/12 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.67)]' >
                                <div className=''>
                                    <h1>PRE-ORDER</h1>
                                </div>
                            </button>
                            

                        </div>
                    
                    </div>
                    </>
                    ) : (<></>)}


                

            </div>

            {/********************************************/}
            {/********************* FIRST PAGE MOBILE - END ***********************/}
            {/********************************************/}

            {/********************************************/}
            {/********************* SECOND PAGE MOBILE - START ***********************/}
            {/********************************************/}


                    {/* <div className='h-full py-2 flex flex-col bg-white w-full'>

                    </div> */}

                </div>
            </div>

    </div>

    {/********************************************/}
    {/********************************************/}
    {/********************* MOBILE VERSION END ***********************/}
    {/********************************************/}
    {/********************************************/}







    {/********************************************/}
    {/********************************************/}
    {/********************* DESKTOP VERSION START ***********************/}
    {/********************************************/}
    {/********************************************/}

    <div className='lg:visible invisible max-sm:hidden items-center justify-center' >

        {/********************************************/}
        {/********************* FIRST PAGE DESKTOP - START ***********************/}
        {/********************************************/}

        <div className='w-screen h-screen flex flex-col max-sm:justify-start justify-center bg-[#fefefe]'>

            <h1>Desktop Version Coming Soon ...</h1>

        
        </div>

        {/********************************************/}
        {/********************* SECOND PAGE DESKTOP - END ***********************/}
        {/********************************************/}

    </div>
    {/********************************************/}
    {/********************************************/}
    {/********************* DESKTOP VERSION END ***********************/}
    {/********************************************/}
    {/********************************************/}
    </>
    
  )
}

export default CombatFighterProfile