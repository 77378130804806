import React from 'react'
import InventoryTable from './InventoryData'
import { Link } from 'react-router-dom'
import {Translation} from "react-i18next";
import {useTranslation} from "react-i18next";
import { useTabPersistence } from 'hooks/useTabPersistence';
import {PageId} from "../../../types/pages";
import InventoryActivity from './InventoryActivity';
import InventoryDashboard from './InventoryDashboard';
import InventoryTasks from './InventoryTasks';

function Inventory() {
  const {t} = useTranslation(["inventory", "translation"])
  const {selection, toggleTab} = useTabPersistence(PageId.Equipment);
  return (
    <>
    <div className='px-5'>
    <div className='pb-8 mb-6 rounded-lg bg-slate-100 drop-shadow-lg'>
                <ul className="flex felx-row justify-left cursor-pointer">
                    <li onClick={() => toggleTab(1)}
                        className={`rounded-t-lg px-12 py-2 ${selection === 1 ? 'bg-indigo-500 text-white' : ''}`}>{t("inventory:home")}
                    </li>
                    <li onClick={() => toggleTab(2)}
                        className={`rounded-t-lg px-12 py-2 ${selection === 2 ? 'bg-indigo-500 text-white' : ''}`}>{t("inventory:inventory")}
                    </li>
                    <li onClick={() => toggleTab(3)}
                        className={`rounded-t-lg px-12 py-2 ${selection === 3 ? 'bg-indigo-500 text-white' : ''}`}>{t("inventory:tasks")}
                    </li>
                    <li onClick={() => toggleTab(4)}
                        className={`rounded-t-lg px-12 py-2 ${selection === 4 ? 'bg-indigo-500 text-white' : ''}`}>{t("inventory:activity")}
                    </li>
                </ul>

                {selection === 1 ? (
                    <div className='bg-white drop-shadow-lg rounded-tl-none py-8'>
                        <InventoryDashboard/>
                    </div>
                ) : (<></>)}

                {selection === 2 ? (
                    <div className='bg-white drop-shadow-lg rounded-tl-none py-8'>
                        <InventoryTable/>
                    </div>
                ) : (<></>)}

                {selection === 3 ? (
                    <div className='bg-white drop-shadow-lg rounded-tl-none py-8'>
                        <InventoryTasks/>
                        <Link className="btn  mx-9" to={"/task-form"}>
                            Add Task
                        </Link>

                    </div>
                ) : (<></>)}

                {selection === 4 ? (
                    <div className='bg-white drop-shadow-lg rounded-tl-none py-8'>

                      <InventoryActivity/>
                      <Link className="btn mx-9 mt-6" to={"/activity-form"}>
                          Add Activity
                      </Link>
                    </div>
                ) : (<></>)}

            </div>

      <Link className="btn mb-8" to={"/inventory-form"} >
          <Translation ns={"inventory"}>
              {t => t("inventory:addInventory")}
          </Translation>
      </Link>
    </div>
    </>
  )
}

export default Inventory