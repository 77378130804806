import React, {useState, ChangeEvent} from 'react'
import {useNavigate} from 'react-router-dom'
import { addEmployee } from 'features/accounts/accountSlice'
import {useDispatch} from 'react-redux'
import {toast} from 'react-toastify'
import {AppDispatch} from "../../../app/store";
import FormCard from '../../components/shared/FormCard'
import FormTwoInputs from '../../components/shared/FormTwoInputs'
import NumberInputInUnits from '../../components/shared/NumberInputInUnits'
import {useTranslation} from "react-i18next";
import {EmployeeModel} from "@asirisos/types";

function EmployeeForm() {
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const {t} = useTranslation(['employee', 'translation'])
    const canSave = true;

    const [permissionsView, setPermissionsView] = useState(false)

    const onPermissionClick = () => {
        setPermissionsView(!permissionsView)
    }

    const [roleFormData, setRoleFormData] = useState({
        //   FarmId: '',
        farms: 'false',
        crops: 'false',
        joinApprovals: 'false',
        liveStock: 'false',
        employees: 'false',
        equipment: 'false',
        inventory: 'false',
        tasks: 'false',
        tasksRole: 'false',
        activity: 'false',
        customers: 'false',
        sales: 'false',
        storage: 'false',
        joinApprovalsEdit: 'false',
        farmsEdit: 'false',
        cropsEdit: 'false',
        liveStockEdit: 'false',
        employeesEdit: 'false',
        equipmentEdit: 'false',
        inventoryEdit: 'false',
        tasksEdit: 'false',
        activityEdit: 'false',
        customersEdit: 'false',
        salesEdit: 'false',
        storageEdit: 'false'
    })

    const {
        farms,
        crops,
        liveStock,
        employees,
        equipment,
        inventory,
        tasks,
        joinApprovals,
        activity,
        customers,
        sales,
        storage,
        joinApprovalsEdit,
        farmsEdit,
        cropsEdit,
        liveStockEdit,
        employeesEdit,
        equipmentEdit,
        inventoryEdit,
        tasksEdit,
        activityEdit,
        customersEdit,
        salesEdit,
        storageEdit,
    } = roleFormData

    const onRoleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {


        ////////// I was trying to switch the values for Role Form from String to Booleans so that when we display the data it is easier show a checked state of the checkbox
        //////////

        // console.log(e.target)
        // console.log(e)
        // console.log('name', e.target.name)
        // console.log('value', e.target.value)
        // console.log(e.target.checked)

        // if (e.target.checked === false) {
        //     console.log(e.target.checked, 'checked the check')
        //     setRoleFormData((prevState) => ({
        //         ...prevState,
        //         [e.target.name]: true
        //     }))
        // }

        // if (e.target.checked === true) {
        //     setRoleFormData((prevState) => ({
        //         ...prevState,
        //         [e.target.name]: false
        //     }))
        // }
        
        

        if (e.target.value === 'false') {
            setRoleFormData((prevState) => ({
                ...prevState,
                [e.target.name]: 'true'
            }))
        }

        if (e.target.value === 'true') {
            setRoleFormData((prevState) => ({
                ...prevState,
                [e.target.name]: 'false'
            }))
        }


        console.log(typeof (e.target.value))
        const target = e.target as HTMLInputElement
        console.log(target, 'target')
        console.log(roleFormData)

    }

    const [formData, setFormData] = useState({
        name: '',
        email: '', 
        phone: '',
        job_title: '',
        date_of_hire: '' ,
        recruitment_details: '',
        salary: '',
        qualifications: '',
    })

    const {name, email,  phone, job_title, recruitment_details,date_of_hire, salary, qualifications} = formData

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault()

        if (canSave) {
            const employeeData: EmployeeModel = {
                name,
                email,
                phone,
                job_title,
                recruitment_details,
                salary,
                qualifications,
                date_of_hire,
                access: roleFormData
            }

            dispatch(addEmployee(employeeData))
                .unwrap()
                .then(() => {
                    toast.success(t("employee:employeeAdded"));
                    navigate("/employees")
                })
                .catch(() => {
                    toast.error(t("employee:employeeAddFailed"))
                    navigate("/employee-form")
                })
        }
    }

    return (
        <>
            <div className='my-8' >
            <FormCard title='Add Employee'>
                <form onSubmit={onSubmit}>
                    <FormTwoInputs>
                        <NumberInputInUnits onChange={(e) => onChange(e)} value={name} id={"name"} type={'text'}
                                            group={"name"} label={"name"} name={"name"} dataTitle="Farm Name"/>
                        <NumberInputInUnits onChange={(e) => onChange(e)} value={job_title} id={"job_title"} type={'text'}
                                            group={"job_title"} label={"job_title"} name={"job_title"} dataTitle="Job Title"/>
                    </FormTwoInputs>
                    <FormTwoInputs>
                        <NumberInputInUnits onChange={(e) => onChange(e)} value={email} id={"email"} type={'text'}
                                            group={"email"} label={"email"} name={"email"} dataTitle="Email"/>
                        <NumberInputInUnits onChange={(e) => onChange(e)} value={phone} id={"phone"} type={'text'}
                                            group={"phone"} label={"phone"} name={"phone"} dataTitle="Phone"/>
                    </FormTwoInputs>
                    <FormTwoInputs>
                        <NumberInputInUnits onChange={(e) => onChange(e)} value={recruitment_details} id={"recruitment_details"} type={'text'}
                                            group={"recruitment_details"} label={"recruitment_details"} name={"recruitment_details"} dataTitle="Recruitment Details"/>
                        <NumberInputInUnits onChange={(e) => onChange(e)} value={salary.toString()}
                                            id={"salary"} type={'number'} group={"salary"} label={"salary"} name={"salary"} dataTitle={t("translation:Salary")}/>
                    </FormTwoInputs>
                    <FormTwoInputs>
                        <NumberInputInUnits onChange={(e) => onChange(e)} value={qualifications} id={"qualifications"} type={'text'}
                                            group={"qualifications"} label={"qualifications"} name={"qualifications"} dataTitle="Qualifications"/>
                        <NumberInputInUnits onChange={(e) => onChange(e)} value={date_of_hire} id={"date_of_hire"} type={'date'} 
                                            group={"date_of_hire"} label={"date_of_hire"} name={"date_of_hire"} dataTitle="Date of Hire" />
                    </FormTwoInputs>


                    {/* Employee Access Selections */}
                    <div className='flex justify-center pt-10 '>
                        <div className='flex flex-col bg-white justify-center w-1/2 rounded-lg  px-4 mb-8 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]'>
                            <h1 className='font-regular text-2xl text-start pl-8 self-center py-7'>Select Employee Permisions</h1>
                            <div className='divide-y-2 pb-4'>

                                <div className='grid grid-cols-3 gap-4 pt-4 pb-4  '>
                                    <div>
                                        <h1 className='font-regular text-2xl text-start pl-8'>Crops</h1>
                                    </div>

                                    {/* //////////////////////////////// CROPS /////////////////////////////////////// */}
                                    <div>
                                        <div className="flex flex-row justify-center ">
                                            <input
                                                className="form-check-input appearance-none h-7 w-7 drop-shadow-md border border-gray-300 rounded-md bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                type="checkbox" 
                                                value={crops} name="crops"
                                                id="crops" onChange={onRoleChange}/>
                                            <label
                                                className="form-check-label inline-block self-center text-gray-800 font-regular pl-3 text-xl">
                                                View
                                            </label>
                                        </div>
                                    </div>

                                    {/* //////////////////////////////// CROPS EDIT /////////////////////////////////////// */}
                                    <div>
                                        <div className="flex flex-row justify-center ">
                                            <input
                                                className="form-check-input appearance-none h-7 w-7 drop-shadow-md border border-gray-300 rounded-md bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                type="checkbox" value={cropsEdit} name="cropsEdit"
                                                id="cropsEdit" onChange={onRoleChange}/>
                                            <label
                                                className="form-check-label inline-block self-center text-gray-800 font-regular pl-3 text-xl">
                                                Edit
                                            </label>
                                        </div>
                                    </div>
                                </div>


                                {/* //////////////////////////////// LIVE STOCK /////////////////////////////////////// */}

                                <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                                    <div>
                                        <h1 className='font-regular text-2xl text-start pl-8'>Live
                                            Stock</h1>
                                    </div>

                                    {/* //////////////////////////////// LIVE STOCK /////////////////////////////////////// */}
                                    <div>
                                        <div className="flex flex-row justify-center ">
                                            <input
                                                className="form-check-input appearance-none h-7 w-7 drop-shadow-md border border-gray-300 rounded-md bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                type="checkbox" value={liveStock} name="liveStock"
                                                id="liveStock" onChange={onRoleChange}/>
                                            <label
                                                className="form-check-label inline-block self-center text-gray-800 font-regular pl-3 text-xl">
                                                View
                                            </label>
                                        </div>
                                    </div>

                                    {/* //////////////////////////////// LIVE STOCK EDIT /////////////////////////////////////// */}
                                    <div>
                                        <div className="flex flex-row justify-center ">
                                            <input
                                                className="form-check-input appearance-none h-7 w-7 drop-shadow-md border border-gray-300 rounded-md bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                type="checkbox" value={liveStockEdit}
                                                name="liveStockEdit" id="liveStockEdit"
                                                onChange={onRoleChange}/>
                                            <label
                                                className="form-check-label inline-block self-center text-gray-800 font-regular pl-3 text-xl">
                                                Edit
                                            </label>
                                        </div>
                                    </div>
                                </div>


                                {/* //////////////////////////////// EMPLOYEES /////////////////////////////////////// */}

                                <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                                    <div>
                                        <h1 className='font-regular text-2xl text-start pl-8 '>Employees</h1>
                                    </div>

                                    {/* //////////////////////////////// EMPLOYEES /////////////////////////////////////// */}
                                    <div>
                                        <div className="flex flex-row justify-center ">
                                            <input
                                                className="form-check-input appearance-none h-7 w-7 drop-shadow-md border border-gray-300 rounded-md bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                type="checkbox" value={employees} name="employees"
                                                id="employees" onChange={onRoleChange}/>
                                            <label
                                                className="form-check-label inline-block self-center text-gray-800 font-regular pl-3 text-xl">
                                                View
                                            </label>
                                        </div>
                                    </div>

                                    {/* //////////////////////////////// EMPLOYEES EDIT /////////////////////////////////////// */}
                                    <div>
                                        <div className="flex flex-row justify-center ">
                                            <input
                                                className="form-check-input appearance-none h-7 w-7 drop-shadow-md border border-gray-300 rounded-md bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                type="checkbox" value={employeesEdit}
                                                name="employeesEdit" id="employeesEdit"
                                                onChange={onRoleChange}/>
                                            <label
                                                className="form-check-label inline-block self-center text-gray-800 font-regular pl-3 text-xl">
                                                Edit
                                            </label>
                                        </div>
                                    </div>
                                </div>


                                {/* //////////////////////////////// EQUIPMENT /////////////////////////////////////// */}

                                <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                                    <div>
                                        <h1 className='font-regular text-2xl text-start pl-8'>Equipment</h1>
                                    </div>

                                    {/* //////////////////////////////// EQUIPMENT /////////////////////////////////////// */}
                                    <div>
                                        <div className="flex flex-row justify-center ">
                                            <input
                                                className="form-check-input appearance-none h-7 w-7 drop-shadow-md border border-gray-300 rounded-md bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                type="checkbox" value={equipment} name="equipment"
                                                id="equipment" onChange={onRoleChange}/>
                                            <label
                                                className="form-check-label inline-block self-center text-gray-800 font-regular pl-3 text-xl">
                                                View
                                            </label>
                                        </div>
                                    </div>

                                    {/* //////////////////////////////// EQUIPMENT EDIT /////////////////////////////////////// */}
                                    <div>
                                        <div className="flex flex-row justify-center ">
                                            <input
                                                className="form-check-input appearance-none h-7 w-7 drop-shadow-md border border-gray-300 rounded-md bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                type="checkbox" value={equipmentEdit}
                                                name="equipmentEdit" id="equipmentEdit"
                                                onChange={onRoleChange}/>
                                            <label
                                                className="form-check-label inline-block self-center text-gray-800 font-regular pl-3 text-xl">
                                                Edit
                                            </label>
                                        </div>
                                    </div>
                                </div>


                                {/* //////////////////////////////// INVENTORY /////////////////////////////////////// */}

                                <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                                    <div>
                                        <h1 className='font-regular text-2xl text-start pl-8'>Inventory</h1>
                                    </div>

                                    {/* //////////////////////////////// INVENTORY /////////////////////////////////////// */}
                                    <div>
                                        <div className="flex flex-row justify-center ">
                                            <input
                                                className="form-check-input appearance-none h-7 w-7 drop-shadow-md border border-gray-300 rounded-md bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                type="checkbox" value={inventory} name="inventory"
                                                id="inventory" onChange={onRoleChange}/>
                                            <label
                                                className="form-check-label inline-block self-center text-gray-800 font-regular pl-3 text-xl">
                                                View
                                            </label>
                                        </div>
                                    </div>

                                    {/* //////////////////////////////// INVENTORY EDIT /////////////////////////////////////// */}
                                    <div>
                                        <div className="flex flex-row justify-center ">
                                            <input
                                                className="form-check-input appearance-none h-7 w-7 drop-shadow-md border border-gray-300 rounded-md bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                type="checkbox" value={inventoryEdit}
                                                name="inventoryEdit" id="inventoryEdit"
                                                onChange={onRoleChange}/>
                                            <label
                                                className="form-check-label inline-block self-center text-gray-800 font-regular pl-3 text-xl">
                                                Edit
                                            </label>
                                        </div>
                                    </div>
                                </div>


                                {/* //////////////////////////////// TASKS /////////////////////////////////////// */}

                                <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                                    <div>
                                        <h1 className='font-regular text-2xl text-start pl-8'>Tasks</h1>
                                    </div>

                                    {/* //////////////////////////////// TASKS /////////////////////////////////////// */}
                                    <div>
                                        <div className="flex flex-row justify-center ">
                                            <input
                                                className="form-check-input appearance-none h-7 w-7 drop-shadow-md border border-gray-300 rounded-md bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                type="checkbox" value={tasks} name="tasks"
                                                id="tasks" onChange={onRoleChange}/>
                                            <label
                                                className="form-check-label inline-block self-center text-gray-800 font-regular pl-3 text-xl">
                                                View
                                            </label>
                                        </div>
                                    </div>

                                    {/* //////////////////////////////// TASKS EDIT /////////////////////////////////////// */}
                                    <div>
                                        <div className="flex flex-row justify-center ">
                                            <input
                                                className="form-check-input appearance-none h-7 w-7 drop-shadow-md border border-gray-300 rounded-md bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                type="checkbox" value={tasksEdit} name="tasksEdit"
                                                id="tasksEdit" onChange={onRoleChange}/>
                                            <label
                                                className="form-check-label inline-block self-center text-gray-800 font-regular pl-3 text-xl">
                                                Edit
                                            </label>
                                        </div>
                                    </div>
                                </div>


                                {/* //////////////////////////////// ACTIVITY /////////////////////////////////////// */}

                                <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                                    <div>
                                        <h1 className='font-regular text-2xl text-start pl-8'>Activity</h1>
                                    </div>

                                    {/* //////////////////////////////// ACTIVITY /////////////////////////////////////// */}
                                    <div>
                                        <div className="flex flex-row justify-center ">
                                            <input
                                                className="form-check-input appearance-none h-7 w-7 drop-shadow-md border border-gray-300 rounded-md bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                type="checkbox" value={activity} name="activity"
                                                id="activity" onChange={onRoleChange}/>
                                            <label
                                                className="form-check-label inline-block self-center text-gray-800 font-regular pl-3 text-xl">
                                                View
                                            </label>
                                        </div>
                                    </div>

                                    {/* //////////////////////////////// ACTIVITY EDIT /////////////////////////////////////// */}
                                    <div>
                                        <div className="flex flex-row justify-center ">
                                            <input
                                                className="form-check-input appearance-none h-7 w-7 drop-shadow-md border border-gray-300 rounded-md bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                type="checkbox" value={activityEdit}
                                                name="activityEdit" id="activityEdit"
                                                onChange={onRoleChange}/>
                                            <label
                                                className="form-check-label inline-block self-center text-gray-800 font-regular pl-3 text-xl">
                                                Edit
                                            </label>
                                        </div>
                                    </div>
                                </div>


                                {/* //////////////////////////////// CUSTOMERS /////////////////////////////////////// */}

                                <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                                    <div>
                                        <h1 className='font-regular text-2xl text-start pl-8'>Customers</h1>
                                    </div>

                                    {/* //////////////////////////////// CUSTOMERS /////////////////////////////////////// */}
                                    <div>
                                        <div className="flex flex-row justify-center ">
                                            <input
                                                className="form-check-input appearance-none h-7 w-7 drop-shadow-md border border-gray-300 rounded-md bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                type="checkbox" value={customers} name="customers"
                                                id="customers" onChange={onRoleChange}/>
                                            <label
                                                className="form-check-label inline-block self-center text-gray-800 font-regular pl-3 text-xl">
                                                View
                                            </label>
                                        </div>
                                    </div>

                                    {/* //////////////////////////////// CUSTOMERS EDIT /////////////////////////////////////// */}
                                    <div>
                                        <div className="flex flex-row justify-center ">
                                            <input
                                                className="form-check-input appearance-none h-7 w-7 drop-shadow-md border border-gray-300 rounded-md bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                type="checkbox" value={customersEdit}
                                                name="customersEdit" id="customersEdit"
                                                onChange={onRoleChange}/>
                                            <label
                                                className="form-check-label inline-block self-center text-gray-800 font-regular pl-3 text-xl">
                                                Edit
                                            </label>
                                        </div>
                                    </div>
                                </div>


                                {/* ////////////////////////////// SALES /////////////////////////////////////// */}

                                <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                                    <div>
                                        <h1 className='font-regular text-2xl text-start pl-8'>Sales</h1>
                                    </div>

                                    {/* ////////////////////////////// SALES /////////////////////////////////////// */}
                                    <div>
                                        <div className="flex flex-row justify-center ">
                                            <input
                                                className="form-check-input appearance-none h-7 w-7 drop-shadow-md border border-gray-300 rounded-md bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                type="checkbox" value={sales} name="sales"
                                                id="sales" onChange={onRoleChange}/>
                                            <label
                                                className="form-check-label inline-block self-center text-gray-800 font-regular pl-3 text-xl">
                                                View
                                            </label>
                                        </div>
                                    </div>

                                    {/* ////////////////////////////// SALES EDIT /////////////////////////////////////// */}
                                    <div>
                                        <div className="flex flex-row justify-center ">
                                            <input
                                                className="form-check-input appearance-none h-7 w-7 drop-shadow-md border border-gray-300 rounded-md bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                type="checkbox" value={salesEdit} name="salesEdit"
                                                id="salesEdit" onChange={onRoleChange}/>
                                            <label
                                                className="form-check-label inline-block self-center text-gray-800 font-regular pl-3 text-xl">
                                                Edit
                                            </label>
                                        </div>
                                    </div>
                                </div>


                                {/* //////////////////////////////// STORAGE STOCK /////////////////////////////////////// */}

                                <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                                    <div>
                                        <h1 className='font-regular text-2xl text-start pl-8'>Storage</h1>
                                    </div>

                                    {/* //////////////////////////////// STORAGE STOCK /////////////////////////////////////// */}
                                    <div>
                                        <div className="flex flex-row justify-center ">
                                            <input
                                                className="form-check-input appearance-none h-7 w-7 drop-shadow-md border border-gray-300 rounded-md bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                type="checkbox" value={storage} name="storage"
                                                id="storage" onChange={onRoleChange}/>
                                            <label
                                                className="form-check-label inline-block self-center text-gray-800 font-regular pl-3 text-xl">
                                                View
                                            </label>
                                        </div>
                                    </div>

                                    {/* //////////////////////////////// STORAGE STOCK EDIT /////////////////////////////////////// */}
                                    <div>
                                        <div className="flex flex-row justify-center ">
                                            <input
                                                className="form-check-input appearance-none h-7 w-7 drop-shadow-md border border-gray-300 rounded-md bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                type="checkbox" value={storageEdit}
                                                name="storageEdit" id="storageEdit"
                                                onChange={onRoleChange}/>
                                            <label
                                                className="form-check-label inline-block self-center text-gray-800 font-regular pl-3 text-xl">
                                                Edit
                                            </label>
                                        </div>
                                    </div>
                                </div>


                                {/* //////////////////////////////// FARM /////////////////////////////////////// */}

                                <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                                    <div>
                                        <h1 className='font-regular text-2xl text-start pl-8'>Farm</h1>
                                    </div>

                                    {/* //////////////////////////////// FARM /////////////////////////////////////// */}
                                    <div>
                                        <div className="flex flex-row justify-center ">
                                            <input
                                                className="form-check-input appearance-none h-7 w-7 drop-shadow-md border border-gray-300 rounded-md bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                type="checkbox" value={farms} name="farms"
                                                id="farms" onChange={onRoleChange}/>
                                            <label
                                                className="form-check-label inline-block self-center text-gray-800 font-regular pl-3 text-xl">
                                                View
                                            </label>
                                        </div>
                                    </div>

                                    {/* //////////////////////////////// FARM EDIT /////////////////////////////////////// */}
                                    <div>
                                        <div className="flex flex-row justify-center ">
                                            <input
                                                className="form-check-input appearance-none h-7 w-7 drop-shadow-md border border-gray-300 rounded-md bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                type="checkbox" value={farmsEdit} name="farmsEdit"
                                                id="farmsEdit" onChange={onRoleChange}/>
                                            <label
                                                className="form-check-label inline-block self-center text-gray-800 font-regular pl-3 text-xl">
                                                Edit
                                            </label>
                                        </div>
                                    </div>
                                </div>


                                {/* //////////////////////////////// JOIN REQUEST /////////////////////////////////////// */}

                                <div className='grid grid-cols-3 gap-4 pt-4 pb-4  '>
                                    <div>
                                        <h1 className='font-regular text-2xl text-start pl-8'>Join
                                            Approval</h1>
                                    </div>

                                    {/* //////////////////////////////// JOIN REQUEST /////////////////////////////////////// */}
                                    <div>
                                        <div className="flex flex-row justify-center ">
                                            <input
                                                className="form-check-input appearance-none h-7 w-7 drop-shadow-md border border-gray-300 rounded-md bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                type="checkbox" value={joinApprovals}
                                                name="joinApprovals" id="joinApprovals"
                                                onChange={onRoleChange}/>
                                            <label
                                                className="form-check-label inline-block self-center text-gray-800 font-regular pl-3 text-xl">
                                                View
                                            </label>
                                        </div>
                                    </div>

                                    {/* //////////////////////////////// JOIN REQUEST EDIT /////////////////////////////////////// */}
                                    <div>
                                        <div className="flex flex-row justify-center ">
                                            <input
                                                className="form-check-input appearance-none h-7 w-7 drop-shadow-md border border-gray-300 rounded-md bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                type="checkbox" value={joinApprovalsEdit}
                                                name="joinApprovalsEdit" id="joinApprovalsEdit"
                                                onChange={onRoleChange}/>
                                            <label
                                                className="form-check-label inline-block self-center text-gray-800 font-regular pl-3 text-xl">
                                                Edit
                                            </label>
                                        </div>
                                    </div>

                                </div>

                                {/* {farmJoin.status === 'pending' ? (<></>) : (<>
                                    <div className='py-3'>
                                        <button onClick={onSubmitApprove} value={farmJoin._id}
                                                className="px-3 py-2 border-2 border-indigo-500 text-black rounded-lg disabled:hover:bg-gray-100 disabled:text-gray-300 hover:bg-indigo-500 hover:text-white ">Approve
                                        </button>
                                    </div>
                                </>)} */}


                            </div>
                        </div>
                    </div>
                    {/* Employee Access Selections */}



                    <div className='flex justify-center pt-8 p-2'>
                        <button className="btn btn-wide btn-primar hover:bg-black">Add Employee</button>
                    </div>
                </form>
            </FormCard>






           




            </div>
        </>
    )
}

export default EmployeeForm