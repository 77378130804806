import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import addUserV2Service from "./userV2Service";
import {FixMeLater} from "../../types/dev";
import { JoinWaitlistState } from "../../types/states";
import HandleAxiosError from "../../utils/AxiosErrorHandler";
import {DefaultThunkApiConfig, Status} from "../../types/slice";
import { UserV2State } from "../../types/states";
import { UserV2Model } from "@asirisos/types";
import userV2Service from "./userV2Service";

const CONTEXT = "UserV2"


const initialState: UserV2State = {
    userV2: undefined,
    usersV2: undefined,
    status: Status.Unknown,
    message: ''
}

export const addUserV2 = createAsyncThunk<any, UserV2Model, DefaultThunkApiConfig>('add-user-v2', async (addUserV2Data, thunkAPI) => {
    try {
        const response = await userV2Service.addUserV2(addUserV2Data)
        if (response.status !== 201)
            return thunkAPI.rejectWithValue();
        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const countUserV2 = createAsyncThunk<any, void, DefaultThunkApiConfig>('count-all-user-v2', async (_, thunkAPI) => {
    try {
        const response = await userV2Service.countUserV2()
        if (response.status !== 201)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})


export const userV2Slice = createSlice({
    name: 'addUserV2',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(addUserV2.pending, (state: UserV2State) => {
                state.status = Status.Pending
            })
            .addCase(addUserV2.fulfilled, (state: UserV2State, action: FixMeLater) => {
                state.status = Status.Success
                state.userV2 = action.payload                
            })
            .addCase(addUserV2.rejected, (state: UserV2State, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            .addCase(countUserV2.pending, (state: UserV2State) => {
                state.status = Status.Pending
            })
            .addCase(countUserV2.fulfilled, (state: UserV2State, action: FixMeLater) => {
                state.status = Status.Success
                state.usersV2 = action.payload
            })
            .addCase(countUserV2.rejected, (state: UserV2State, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
    }
})

export const {reset} = userV2Slice.actions
export default userV2Slice.reducer