import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import infrastructureService from "./infrastructureService";
import {FixMeLater} from "../../types/dev";
import {InfrastructureState} from "../../types/states";
import HandleAxiosError from "../../utils/AxiosErrorHandler";
import {DefaultThunkApiConfig, Status} from "../../types/slice";
import { InfrastructureModel } from "@asirisos/types";

const CONTEXT = "Infrastructure"

const initialState: InfrastructureState = {
    infrastructure: undefined,
    infrastructures: [],
    status: Status.Unknown,
    message: ''
}

export const addInfrastructure = createAsyncThunk<any, InfrastructureModel, DefaultThunkApiConfig>('infrastructure/add', async (infrastructureData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await infrastructureService.addInfrastructure(infrastructureData, account)
        if (response.status !== 201)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getInfrastructures = createAsyncThunk<InfrastructureModel[], void, DefaultThunkApiConfig>('infrastructure/getall', async (_, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await infrastructureService.getInfrastructures(account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getInfrastructure = createAsyncThunk<InfrastructureModel, string, DefaultThunkApiConfig>('infrastructure/getone/:infrastructureId', async (infrastructureId, thunkAPI) => {
    try {
        const response = await infrastructureService.getInfrastructure(infrastructureId)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const updateInfrastructure = createAsyncThunk<InfrastructureModel, InfrastructureModel, DefaultThunkApiConfig>('infrastructure/updateinfrastructure/:infrastructureId', async (infrastructureData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if(!account)
            return thunkAPI.rejectWithValue();
            
        const response = await infrastructureService.updateInfrastructure(infrastructureData, account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
    // try {
    //     const token = thunkAPI.getState().auth.user.token
    //     return await infrastructureService.updateInfrastructure(infrastructureData, token)
    // } catch (error) {
    //     HandleAxiosError(error, CONTEXT);
    //     return thunkAPI.rejectWithValue("message")
    // }
})

export const deleteInfrastructure = createAsyncThunk<any, string[], DefaultThunkApiConfig>('infrastructure/delete/:infrastructureId', async (infrastructureId, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if(!account)
            return thunkAPI.rejectWithValue();

        const response = await infrastructureService.deleteInfrastructure(infrastructureId, account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const infrastructureSlice = createSlice({
    name: 'infrastructure',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(addInfrastructure.pending, (state: InfrastructureState) => {
                state.status = Status.Pending
            })
            .addCase(addInfrastructure.fulfilled, (state: InfrastructureState) => {
                state.status = Status.Success
            })
            .addCase(addInfrastructure.rejected, (state: InfrastructureState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            .addCase(getInfrastructures.pending, (state: InfrastructureState) => {
                state.status = Status.Pending
            })
            .addCase(getInfrastructures.fulfilled, (state: InfrastructureState, action: PayloadAction<InfrastructureModel[], any, any>) => {
                state.status = Status.Success
                state.infrastructures = action.payload
            })
            .addCase(getInfrastructures.rejected, (state: InfrastructureState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            .addCase(getInfrastructure.pending, (state: InfrastructureState) => {
                state.status = Status.Pending
            })
            .addCase(getInfrastructure.fulfilled, (state: InfrastructureState, action: PayloadAction<InfrastructureModel, any, any>) => {
                state.status = Status.Success
                state.infrastructure = action.payload
            })
            .addCase(getInfrastructure.rejected, (state: InfrastructureState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            .addCase(updateInfrastructure.pending, (state: InfrastructureState) => {
                state.status = Status.Pending
            })
            // .addCase(updateInfrastructure.fulfilled, (state: InfrastructureState, action: PayloadAction<InfrastructureModel, any, any>) => {
            //     state.status = Status.Success
            //     state.infrastructures.map((infrastructure: InfrastructureModel) => infrastructure._id === action.payload._id ?
            //         (state.infrastructure = infrastructure) : infrastructure)
            //     state.infrastructure = action.payload
            // })
            .addCase(updateInfrastructure.rejected, (state: InfrastructureState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            // .addCase(updateInfrastructure.fulfilled, (state: InfrastructureState, action: FixMeLater) => {
            //     state.isLoading = false
            //     state.infrastructures.map((infrastructure) => infrastructure._id === action.payload._id ? (state.infrastructure = infrastructure) : infrastructure)
            // })
            .addCase(deleteInfrastructure.pending, (state: InfrastructureState) => {
                state.status = Status.Pending
            })
            .addCase(deleteInfrastructure.fulfilled, (state: InfrastructureState) => {
                state.status = Status.Success
                state.infrastructure = undefined
            })
            .addCase(deleteInfrastructure.rejected, (state: InfrastructureState) => {
                state.status = Status.Failure
            })
    }
})

export const {reset} = infrastructureSlice.actions
export default infrastructureSlice.reducer
// export default infrastructureSlice.reducer