import React, {useState} from 'react'
import '../../../index.css'
import {SidebarData} from './SidebarData'
import {Link, useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {RootState} from "../../../app/store";
import { useAuthStatus } from '../../../hooks/useAuthStatus'
import { access } from 'fs'
import Spinner from '../../components/Spinner'


function Sidebar() {

    const {loggedIn, checkingStatus} = useAuthStatus()

    const { user } = useSelector((state : RootState) => state.auth )
    const [isCollapsed, setIsCollapsed] = useState(false)
    const { account } = useSelector((state : RootState) => state.account )
    console.log('account from SideBar', account)

    const location = useLocation()
    console.log("APP - We got the location", location)

    // if (isLoading && account?.userID === '') {
    //     return<Spinner/>
    // }


    if (checkingStatus) {
        return <Spinner/>
    }

    return (
        < >
        
        {isCollapsed ? (<div className='' >
            <div className="sticky top-0 bg-indigo-700 flex pt-9x` flex-col text-center h-full">
            <div className='sticky top-0' >
                <button onClick={() => setIsCollapsed(!isCollapsed)} className='mt-2 py-2 bg-indigo-800 text-lg absolute font-light text-white w-full  top-1 left-0 shadow-lg' >
               <h1 className='text-2xl' >=</h1></button> 
               <div className='mt-12 py-8 self-center' >
                    <img className=" h-10 w-10 bg-white shadow-xl ml-6 rounded-3xl" src="https://cdn-icons-png.flaticon.com/512/843/843349.png" alt=""/>
                </div>
               {SidebarData.map((val, key) => {

            return (
                // user?.roles?.find(role => val.roles.indexOf(role) !== -1) ?
                account?.access?.find(acces => val.access?.includes(acces)) ?
                // user?.roles?.find(role => allowedRoles?.includes(role))
                <Link to={`${val.link}`} className="sidebar_list"  >
                    <li  className= {`  ${val.complete == true ? "" : " "}`}
                    id={window.location.pathname == val.link ? "active" : ""}
                    key={key}
                    onClick={() => {window.location.pathname = val.link}}
                    >
                    <div className='active:bg-white px-8 py-1 sticky flex flex-row justify-start w-full text-xl hover:bg-white text-white hover:text-indigo-700' >
                        <div className={` ${val.complete == true ? "" : "text-slate-400"} ${val.complete == false ? "text-gray-400" : ""}`}>{val.icon}</div>
                    </div>
                </li>
                </Link>
                : <></>

            )
            } )}
            </div> 
        </div> 
        </div>) : 
        
        ( <> 

        {user ? (
        // <div className="sidebar">
        <>
        
        <div className="sticky top-0 bg-indigo-700 flex pt-2 flex-col text-center ">
            <div className='sticky top-0 left-5' >
            <button onClick={() => setIsCollapsed(!isCollapsed)} className=' py-2 bg-indigo-800 text-lg absolute font-light text-white w-full  top-1 left-0 shadow-lg' >
                <div className='flex flex-row justify-between px-4'>
                    <h1 className='text-white text-xl' >{user.name}</h1>
                    {/* <h1>X</h1> */}
                    {/* <img className='h-5 mt-1'  src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Grey_close_x.svg/1200px-Grey_close_x.svg.png" alt="" /> */}
                    <img className='h-7 '  src="https://icons.veryicon.com/png/o/miscellaneous/godserver/shrink-sidebar.png" alt="" />
                </div>
            </button>
            {/* <button onClick={() => setIsCollapsed(!isCollapsed)} className='text-lg absolute font-light text-white  top-1 right-3' >
             X
            </button> */}

            {/* <button onClick={() => setIsCollapsed(!isCollapsed)} className='text-xl self-start text-white py-4' >
                X
            </button> */}
            <div className=' mt-16 mb-6 py-7 sticky ' >
                <img className="h-36 w-34 bg-white shadow-xl rounded-3xl ml-8" src="https://cdn-icons-png.flaticon.com/512/843/843349.png" alt=""/>
                {/* <h1 className='text-white pt-5 text-xl' >{user.name}</h1> */}
            </div>

            <ul className=" " >
                {SidebarData.map((val, key) => {

                    return (
                        // user?.roles?.find(role => val.roles.indexOf(role) !== -1) ?
                        account?.access?.find(acces => val.access?.includes(acces)) ?
                        <Link to={`${val.link}`} className="sidebar_list"  >
                            <li  className= {` ${val.complete == true ? "" : " "}`}
                            id={window.location.pathname == val.link ? "active" : ""}
                            key={key}
                            onClick={() => {window.location.pathname = val.link}}
                            >
                            <div className='active:bg-white px-8 py-3 flex flex-row justify-start w-full text-xl hover:bg-white text-white hover:text-indigo-700' >
                                <div className={` ${val.complete == true ? "" : "text-slate-400"} ${val.complete == false ? "text-gray-400" : ""}`}>{val.icon} </div>{" "}
                                <div className= {`flex flex-row pl-4 ${val.complete == true ? "" : "text-slate-400"} ${val.complete == false ? "text-gray-400" : ""}`} >{val.title} </div>
                            </div>
                        </li>
                        </Link>
                        : <></>

                    )
                } )}
            </ul>
            </div>
        </div>
        </> ) : (
            <div></div>
        ) } </> )  }
        
        
        </>
    )
}

export default Sidebar