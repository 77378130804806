import React, {ChangeEvent} from 'react'
import { Link } from 'react-router-dom'
import SearchBox from '../Map/SearchBox-1'
import {useTabPersistence} from "../../../hooks/useTabPersistence";
import {useTranslation} from "react-i18next";
import {PageId} from "../../../types/pages";
import { useState } from 'react'
import DisplayAllFarmersMarkets from '../Map/DisplayAllFarmersMarkets';
import Button from "../../components/shared/Button"
import NumberInputInUnits from 'UI/components/shared/NumberInputInUnits';
// @ts-ignore
import {animations} from 'react-animation';

function GeneralUserLanding() {

    const animation = require ('./farm_large.png')
    const animation2 = require ('./adaptive-icon.png')
    const vegetables_in_basket = require ('./vegetables_in_basket.jpg')
    const vegetable_market = require ('./vegetable_market.jpg')
    const search_demo = require ('./SearchZipFeatureDemo.gif')

    const {t} = useTranslation(["landing", "translation"])
    const {selection, toggleTab} = useTabPersistence(PageId.Equipment);

    const [formData, setFormData] = useState({
        addressObject: '',
      })

    const {addressObject} = formData

    const [selectedPosition, setSelectPosition] = useState()


    const onSetAddress = (selectedPosition: any) => {
        setFormData((prevState) => ({
            ...prevState,
            addressObject: selectedPosition
        }) )
      }

    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
      }


  return (
    <>

    {/***************************************************************************************************************************************************************************/}
    {/************************************************************************************ MOBILE VERSION START *****************************************************************/}
    {/************************************************************************************ MOBILE VERSION START *****************************************************************/}
    {/************************************************************************************ MOBILE VERSION START *****************************************************************/}
    {/************************************************************************************ MOBILE VERSION START *****************************************************************/}
    {/***************************************************************************************************************************************************************************/}

    <div className='lg:hidden sm:visible' >

        <div className='flex flex-col'>

            {/********************************************/}
            {/********************* FIRST PAGE MOBILE - START ***********************/}
            {/********************************************/}

            <div className='w-screen h-full flex flex-col justify-start align-middle bg-[#F2F0E6]'>

                {/********************************************/}
                {/********************* MENU BAR - START ***********************/}
                {/********************************************/}

                <div className=' bg-[#9ED48E] flex justify-between py-3 px-7 '> 

                    <Link to="/">
                        <div className='flex flex-row justify-center py-1' >
                            <h1 className='text-xl font-semibold text-slate-700' >AsirisOS</h1>                        
                        </div>
                    </Link>

                    <div className='flex gap-2'>
                        {/* <Link className=" text-white px-2 py-1 flex justify-center " to={"/login"} >
                            <h1 className=' text-lg font-light  justify-center align-middle'>Login</h1>
                        </Link> */}
                        <Link className="  px-2 py-1 flex justify-center bg-[#395A30] text-white rounded-md drop-shadow-xl" to={"/sign-up"} >
                            <h1 className=' text-lg font-semibold  justify-center align-middle'>Sign up</h1>
                        </Link>
                    </div>
                </div>

                {/********************************************/}
                {/********************* MENU BAR - END ***********************/}
                {/********************************************/}


                {/********************************************/}
                {/********************* FIRST PAGE MOBILE - START ***********************/}
                {/********************************************/}

                <div className='flex flex-col justify-start h-full bg-[#F2F0E6] pt-5   '>

                    <div className='flex items-start pb-2 justify-center px-5'>
                        <h1 className=' max-w-xl text-3xl text-start font-semibold w-11/12 text-slate-800 pt-4'> 
                            We make it easy to shop local and healthy foods
                        </h1>
                    </div>

                    <div className='flex justify-center px-5'>
                        <div className='flex flex-col items-start pb-3'>                    
                            <h1 className='text-center font-semibold text-lg pt-2 text-slate-700'></h1>
                            <div className='flex'>
                                {/* <h1 className='sm:py-1 max-sm:text-lg max-sm:font-normal text-left text-slate-700 pb-1'>Software for local food producers and consumers to build independent and healthy food systems for all.</h1> */}
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-col justify-end items-center pb-3' >
                        <img className='rounded-sm w-8/12' src='https://cdn.sanity.io/images/ec9j7ju7/production/9cbec0b45904b3f818fa49378dfaa12d35e804ca-800x800.gif?auto=format' alt="" />
                        {/* <img className='rounded-sm w-10/12 drop-shadow-2xl' src='https://media4.giphy.com/media/gdSnOSjIDEvTfcXJlp/giphy.gif?cid=6c09b952yxpji85cn78n5km6fi5gzg8jjpxkp9a915nn6up4&ep=v1_internal_gif_by_id&rid=giphy.gif&ct=s' alt="" />        */}
                    </div>


                    <div className='flex flex-col justify-center items-center pb-6'>    
                        <div className='pb-4'>
                            <h1 className='text-left font-semibold text-md pt-2 max-w-2xl text-slate-700'></h1>
                        </div>

        
                        <div className=' flex self-align-bottom pt-2'>
                            <Link to={'/search-by-zip'}>
                            {/* animate-bounce */}
                                <button className=' rounded-md drop-shadow-xl hover:drop-shadow-2xl bg-[#395A30]  py-3 font-normal text-[#FBFF00] px-14 text-xl '>
                                    Get Started!
                                </button>
                            </Link>
                        </div>
                        
                    </div>

                </div>

            </div>

            {/********************************************/}
            {/********************* FIRST PAGE MOBILE - END ***********************/}
            {/********************************************/}

            {/********************************************/}
            {/********************* SECOND PAGE MOBILE VERSION 2 - START ***********************/}
            {/********************************************/}


            <div className='bg-gradient-to-b bg-[#F2F0E6]'>

                <div className=''>                
                    <div className='mt-4 py-1 w-full drop-shadow-lg bg-[#9ED48E]'>
                        <h1 className='font-semibold text-3xl text-slate-700'>Our Mission</h1>
                    </div>

                    <div className='flex flex-row w-full justify-center pt-3 gap-4 px-2' style={{animation: animations.fadeInUp}}>
                        <div className=' '>
                            <img className='w-14' src="https://cdn-icons-png.flaticon.com/128/812/812319.png" alt="" />
                        </div>
                        <div className='w-2/3 '>
                            <h1 className='text-xl font-medium text-start'>Give Back</h1>
                            <h1 className='text-xs text-start'>Provide free resources to schools and local communities to help as many people as we can</h1>
                        </div>
                    </div>

                    <div className='flex flex-row w-full justify-center pt-5 gap-4 px-2' style={{animation: animations.fadeInUp}}>
                        <div className=' '>
                            <img className='w-14' src="https://static.thenounproject.com/png/3812160-200.png" alt="" />
                        </div>
                        <div className='w-2/3'>
                            <h1 className='text-xl font-medium text-start'>Build the Best Product</h1>
                            <h1 className='text-xs text-start'>Always improve our platform to best serve local shoppers and food producers</h1>
                        </div>
                    </div>
                </div>

                <div className='pt-9'>
                    <h1 className=' text-center font-bold text-3xl text-slate-700 px-2'>
                        Discover 17,927 local organizations
                    </h1>
                </div>

                <div className='pt-5 text-sm px-2 pb-5'>
                    <div className='grid pb-1 grid-cols-2 gap-5 px-8'>
                        <div className=''>
                            <h1 className='bg-[#CED9F5] text-slate-800 drop-shadow-md rounded-md px-1 py-2'>Farmers Markets</h1>
                        </div>
                        <div className=''>
                            <h1 className='bg-[#CED9F5] text-slate-800 drop-shadow-md rounded-md px-1 py-2'>Local Farms</h1>
                        </div>
                    </div>
                    <div className='grid pb-1 grid-cols-3 gap-5 pt-2'>
                        <div className=''>
                            <h1 className='bg-[#CED9F5] text-slate-800 drop-shadow-md rounded-md py-2'>Food Hubs</h1>
                        </div>
                        <div className=''>
                            <h1 className='bg-[#CED9F5] text-slate-800 drop-shadow-md rounded-md px-1 py-2'>CSAs</h1>
                        </div>
                        <div className=''>
                            <h1 className='bg-[#CED9F5] text-slate-800 drop-shadow-md rounded-md px-1 py-2'>CPG Brands</h1>
                        </div>
                    </div>
                    <div className='grid pb-1 grid-cols-2 gap-5 px-8 pt-2'>
                        <div className=''>
                            <h1 className='bg-[#CED9F5] text-slate-800 drop-shadow-md rounded-md px-1 py-2'>Community Gardens</h1>
                        </div>
                        <div className=''>
                            <h1 className='bg-[#CED9F5] text-slate-800 drop-shadow-md rounded-md px-1 py-2'>Co-op Groceries</h1>
                        </div>
                    </div>
                    <div className='grid pb-1 grid-cols-3 gap-5 pt-2'>
                        <div className=''>
                            <h1 className='bg-[#CED9F5] text-slate-800 drop-shadow-md rounded-md py-2'>Seed-Oil Free</h1>
                        </div>
                        <div className=''>
                            <h1 className='bg-[#CED9F5] text-slate-800 drop-shadow-md rounded-md py-2'>Meal Prep</h1>
                        </div>
                        <div className=''>
                            <h1 className='bg-[#CED9F5] text-slate-800 drop-shadow-md rounded-md py-2'>Food Banks</h1>
                        </div>
                    </div>
                </div>

            
                <div className='carousel pb-7 pt-6 ml-5 ' >

                    <div id="item1" className='carousel-item w-5/6 pl-2'>
                        <div className='text-[#16341fec] bg-[#ffffff] w-9/12 drop-shadow-xl rounded-lg    '>
                            <div>
                                {/* <img className='rounded-t-lg' src={vegetables_in_basket} alt="" /> */}
                                <img className='rounded-t-lg' src='https://images.unsplash.com/photo-1567306295427-94503f8300d7?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fGZhcm1lciUyMG1hcmtldHxlbnwwfHwwfHx8MA%3D%3D' alt="" />
                            </div>

                            <div className='flex flex-col '>
                                <div className=' pt-2 '>
                                    <h1 className='text-2xl font-bold'>6989</h1>
                                    <h1 className='text-lg font-semibold' >Farmers Markets</h1>
                                </div>
                                <hr/>
                                <div className='self-center  pb-3 rounded-b-lg px-2'>
                                    <h1 className='pt-2 text-md font-light text-center' >Bring together your local food vendors</h1>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="item2" className='carousel-item w-5/6'>
                        <div className='text-[#16341fec] bg-[#ffffff] w-9/12 drop-shadow-xl rounded-lg    '>
                            <div className=''>
                                {/* <img className='rounded-t-lg' src={vegetables_in_basket} alt="" /> */}
                                <img className='rounded-t-lg' src='https://images.pexels.com/photos/7658822/pexels-photo-7658822.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' alt="" />                                
                            </div>

                            <div className='flex flex-col '>
                                <div className=' pt-2 '>
                                    <h1 className='text-2xl font-bold'>4684</h1>
                                    <h1 className='text-lg font-semibold' >Local Farms</h1>
                                </div>
                                <hr/>
                                <div className='self-center  pb-3 rounded-b-lg px-2'>
                                    <h1 className='pt-2 text-md font-light text-center' >Local farms that sell produce directly to you</h1>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="item3" className='carousel-item w-5/6'>
                        <div className='text-[#16341fec] bg-[#ffffff] w-9/12 drop-shadow-xl rounded-lg    '>
                            <div className=''>
                                {/* <img className='rounded-t-lg' src={vegetables_in_basket} alt="" /> */}
                                <img className='rounded-t-lg' src='https://images.pexels.com/photos/7658811/pexels-photo-7658811.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' alt="" />                                
                            </div>

                            <div className='flex flex-col '>
                                <div className=' pt-2 '>
                                    <h1 className='text-2xl font-bold'>1015</h1>
                                    <h1 className='text-lg font-semibold'>Community Gardens</h1>
                                </div>
                                <hr/>
                                <div className='self-center  pb-3 rounded-b-lg px-2'>
                                    <h1 className='pt-2 text-md font-light text-center'>Local gardens maintained by your neighbours</h1>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div id="item4" className='carousel-item w-5/6'>
                        <div className='text-[#16341fec] bg-[#ffffff] w-9/12 drop-shadow-xl rounded-lg    '>
                            <div className=''>
                                {/* <img className='rounded-t-lg' src={vegetables_in_basket} alt="" /> */}
                                <img className='rounded-t-lg' src='https://www.grocery.coop/sites/default/files/styles/amp_metadata_content_image_min_696px_wide/public/I_own_a_food_coop_with_some_friends.jpg?itok=edJX-VXp' alt="" />                                
                            </div>

                            <div className='flex flex-col '>
                                <div className=' pt-2 '>
                                    <h1 className='text-2xl font-bold'>224</h1>
                                    <h1 className='text-lg font-semibold' >Co-op Groceries</h1>
                                </div>
                                <hr/>
                                <div className='self-center  pb-3 rounded-b-lg px-2'>
                                    <h1 className='pt-2 text-md font-light text-center' >Grocery stores owned and managed by your local community</h1>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div id="item5" className='carousel-item w-5/6'>
                        <div className='text-[#16341fec] bg-[#ffffff] w-9/12 drop-shadow-xl rounded-lg    '>
                            <div className=''>
                                {/* <img className='rounded-t-lg' src={vegetables_in_basket} alt="" /> */}
                                <div className=''>
                                    <img className='rounded-t-lg h-45' src='https://images.squarespace-cdn.com/content/v1/5a7b172d9f07f59b0a29945e/1518108602253-YM3R5ZK4YETUNCXFJZM2/farm-stand-kaisa.jpg' alt="" />                            
                                </div>
                            </div>

                            <div className='flex flex-col '>
                                <div className=' pt-2 '>
                                    <h1 className='text-2xl font-bold'>1710</h1>
                                    <h1 className='text-lg font-semibold' >On-Farm Markets</h1>
                                </div>
                                <hr/>
                                <div className='self-center  pb-3 rounded-b-lg px-2'>
                                    <h1 className='pt-2 text-md font-light text-center' >Farms that let you buy food right where it grows</h1>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div id="item6" className='carousel-item w-5/6'>
                        <div className='text-[#16341fec] bg-[#ffffff] w-9/12 drop-shadow-xl rounded-lg    '>
                            <div>
                                <img className='rounded-t-lg' src='https://www.sustainweb.org/resources/images/food_growing/community_supported_agriculture.jpg' alt="" />                                
                            </div>

                            <div className='flex flex-col '>
                                <div className=' pt-2 '>
                                    <h1 className='text-2xl font-bold'>994</h1>
                                    <h1 className='text-lg font-semibold' >CSAs</h1>
                                </div>
                                <hr/>
                                <div className='self-center  pb-3 rounded-b-lg px-2'>
                                    <h1 className='pt-2 text-md font-light text-center' >Allow you to purchase a share of farm's future harvest</h1>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="item7" className='carousel-item w-5/6'>
                        <div className='text-[#16341fec] bg-[#ffffff] w-9/12 drop-shadow-xl rounded-lg    '>
                            <div>
                                <img className='rounded-t-lg' src='https://miro.medium.com/v2/resize:fit:1358/1*TSjbvVLhANmJjeOhkCErnw.png' alt="" />
                            </div>

                            <div className='flex flex-col '>
                                <div className=' pt-2 '>
                                    <h1 className='text-2xl font-bold'>2141</h1>
                                    <h1 className='text-lg font-semibold' >CPG Brands</h1>
                                </div>
                                <hr/>
                                <div className='self-center  pb-3 rounded-b-lg px-2'>
                                    <h1 className='pt-2 text-md font-light text-center' >Local brands that make unique consumer products</h1>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div id="item8" className='carousel-item w-5/6'>
                        <div className='text-[#16341fec] bg-[#ffffff] w-9/12 drop-shadow-xl rounded-lg    '>
                            <div>
                                <img className='rounded-t-lg' src='https://images.squarespace-cdn.com/content/v1/57f7c21be3df28e37ffbbc26/1535663290930-QOAFS7BYP2I0K1D5YRO0/image-asset.octet-stream?format=500w' alt="" />
                            </div>

                            <div className='flex flex-col '>
                                <div className=' pt-2 '>
                                    <h1 className='text-2xl font-bold'>230</h1>
                                    <h1 className='text-lg font-semibold' >Food Hubs</h1>
                                </div>
                                <hr/>
                                <div className='self-center  pb-3 rounded-b-lg px-2'>
                                    <h1 className='pt-2 text-md font-light text-center' >Help local food producers market and deliver their products</h1>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>

                <div className='flex justify-center w-full py-4 gap-5 '>
                    <a href="#item1" className='bg-[#37774bec] text-lg w-3 h-3 rounded-lg'></a>
                    <a href="#item2" className='bg-[#37774bec] text-lg w-3 h-3 rounded-lg'></a>
                    <a href="#item3" className='bg-[#37774bec] text-lg w-3 h-3 rounded-lg'></a>
                    <a href="#item4" className='bg-[#37774bec] text-lg w-3 h-3 rounded-lg'></a>
                    <a href="#item5" className='bg-[#37774bec] text-lg w-3 h-3 rounded-lg'></a>
                    <a href="#item6" className='bg-[#37774bec] text-lg w-3 h-3 rounded-lg'></a>
                    <a href="#item7" className='bg-[#37774bec] text-lg w-3 h-3 rounded-lg'></a>
                    <a href="#item8" className='bg-[#37774bec] text-lg w-3 h-3 rounded-lg'></a>
                </div>
            </div>

            {/********************************************/}
            {/********************* OLD VERSION OF 3 STEP PROCESS - END ***********************/}
            {/********************************************/}


            {/********************************************/}
            {/********************* SECOND PAGE MOBILE - START ***********************/}
            {/********************************************/}


            <div className='h-full py-6 flex flex-col bg-[#F2F0E6] w-full'>

                <div className='flex flex-col justify-center w-full gap-10' >

                    {/* SECTION TITLE */}
                    <div className='items-center pt-2'>
                        <h1 className=' text-center font-bold text-3xl text-slate-700 px-2'>
                            Start in 3 simple steps
                        </h1>
                    </div>
                    {/* SECTION TITLE */}


                    {/* FARMERS MARKET - START */}
                    <div className=' flex flex-row justify-between  py-2'>
                        <div className='bg-[#37774bec] text-[#fff9e1] py-4  w-5/6 rounded-r-xl bg-[#fff9e1] drop-shadow-2xl '>
                            <div className='' >
                                <h1 className='text-2xl font-bold'>1 - Discover</h1>
                                <h1 className='text-lg font-light' >Find your local food producers</h1>
                            </div>
                        </div>
                        {/* <div className=' self-center pl-2 pr-1 w-1/2'>
                            <h1 className='pt-2 text-sm font-normal text-right' >Bring together your local food vendors</h1>
                        </div> */}
                    </div>
                    <div className='flex justify-center'>
                        <img src={search_demo} alt="" className='w-2/3 rounded-xl' />
                    </div>
                    {/* FARMERS MARKET - END */}


                    {/* CO-OP GROCERIES - START */}
                    <div className=' flex flex-row justify-end  py-2'>                
                        {/* <div className=' self-center pr-2 pl-1 w-1/2'>
                            <h1 className='pt-2 text-sm font-normal text-left' >Stores owned and managed by your local community</h1>
                        </div> */}

                        <div className='bg-[#37774bec] text-[#fff9e1] py-4  w-5/6 rounded-l-xl bg-[#fff9e1] drop-shadow-2xl '>
                            <h1 className='text-2xl font-bold'>2 - Connect</h1>
                            <h1 className='text-lg font-light' >Follow your local food organizations</h1>
                        </div>
                    </div>
                    {/* CO-OP GROCERIES - START */}


                    {/* ON FARM MARKETS - START */}
                    <div className=' flex flex-row justify-between  py-2'>
                        <div className='bg-[#37774bec] text-[#fff9e1] py-4  w-5/6 rounded-r-xl bg-[#fff9e1] drop-shadow-2xl '>
                            <div className='' >
                                <h1 className='text-2xl font-bold'>3 - Shop</h1>
                                <h1 className='text-lg font-light' >Start eating local and healthy foods</h1>
                            </div>
                        </div>

                        {/* <div className=' self-center pl-2 pr-1 w-1/2'>
                            <h1 className='pt-2 text-sm font-normal text-right' >Farms that let you buy food right where it grows</h1>
                        </div> */}
                    </div>
                    {/* ON FARM MARKETS - END */}


                </div>    


                <div className=' bg-[#FFC500]  flex flex-row py-3 justify-center align-middle mt-10  drop-shadow-xl  ' >

                    <div className='text-xl text-slate-800 font-semibold px-4 py-2'>
                        <h1>Take your first step</h1>
                    </div>

                    <Link to={'/search-by-zip'}>
                        <div className=' flex self-align-bottom'>
                            <button className=' rounded-md drop-shadow-xl hover:drop-shadow-2xl bg-[#37774bec]  py-2 font-normal text-slate-50 px-2 text-xl hover:bg-[#2f6942] '>
                                Get Started!
                            </button>
                        </div>
                    </Link>

                </div>

            </div>


            {/********************************************/}
            {/********************* SECOND PAGE MOBILE - END ***********************/}
            {/********************************************/}


            {/********************************************/}
            {/********************* THIRD PAGE MOBILE - END ***********************/}
            {/********************************************/}

            {/********************************************/}
            {/********************* FOOTER - START ***********************/}
            {/********************************************/}

            <div className='  bg-[#37774bec] text-[#fff9e1] flex flex-row py-4 mt-2 justify-between align-middle px-6 ' >
                <div>
                    <Link to={"/"}>
                        <h1 className='text-xl'>AsirisOS</h1>
                    </Link>
                </div>

                <div className='justify-start flex gap-3'>
                    <Link to={"/sign-up-v2"} className='bg-[#FFC500] text-black py-1 px-2 rounded-md' >
                        <h1 className='text-sm'> Sign up</h1>            
                    </Link>
                    {/* <Link to={"/contact"}>
                        <h1 className='text-sm'> Contact</h1>            
                    </Link>
                    <Link to={"/organizations-join"}>
                        <h1 className='text-sm'>Organizations</h1>            
                    </Link>

                    <Link to={"/join-the-team"}>
                        <h1 className='text-sm'>Team</h1>            
                    </Link> */}
                </div>

            </div>

            {/********************************************/}
            {/********************* FOOTER - START ***********************/}
            {/********************************************/}


            {/* THIS OTHER THING SECTION */}

            {/* <Link to={"/asiris-v2-landing"}>
                <div className='  bg-[#1763db] text-[#fff9e1] flex flex-row py-1 justify-around align-middle  ' >
                    <div>
                            <h1 className='text-xl font-light py-1'>The other thing</h1>
                        
                    </div>

                    <div className='justify-start flex gap-3'>
                        <img className='w-7' src="https://media3.giphy.com/media/EtLdAZH3I56suXvQO9/giphy.gif?cid=6c09b95209o4gml4nwi7za7epihn9hf3qtkwiepr1plrd81e&ep=v1_stickers_related&rid=giphy.gif&ct=s" alt=" " />
                    </div>

                </div>
            </Link> */}

        </div>

    </div>

    {/********************************************/}
    {/********************************************/}
    {/************************************************************************************ MOBILE VERSION END *****************************************************************/}
    {/************************************************************************************ MOBILE VERSION END *****************************************************************/}
    {/************************************************************************************ MOBILE VERSION END *****************************************************************/}
    {/************************************************************************************ MOBILE VERSION END *****************************************************************/}
    {/********************************************/}
    {/********************************************/}









    {/********************************************/}
    {/********************************************/}
    {/************************************************************************************ DESKTOP VERSION START *****************************************************************/}
    {/************************************************************************************ DESKTOP VERSION START *****************************************************************/}
    {/************************************************************************************ DESKTOP VERSION START *****************************************************************/}
    {/************************************************************************************ DESKTOP VERSION START *****************************************************************/}
    {/********************************************/}
    {/********************************************/}

    <div className='lg:visible invisible max-sm:hidden items-center justify-center ' >

        {/********************************************/}
        {/********************* FIRST PAGE DESKTOP - START ***********************/}
        {/********************************************/}
    <div className='' >

        <div className='w-screen h-screen flex flex-col max-sm:justify-start justify-start bg-[#fff9e1]  '>

            {/* ----------------------- NAVIGATION BAR  ----------------------- */}

            <div className='flex justify-around py-3 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.67)] bg-[#16341fec]'>    
                <Link to="/">
                    <div className='flex justify-center align-middle'>
                        <h1 className='max-sm:text-2xl max-sm:font-light text-4xl font-thin text-white align-middle' >AsirisOS</h1>
                        <h1 className='max-sm:pt-1 max-sm:text-xl max-sm:font-light text-2xl font-thin text-slate-600 pt-1 pl-3' ></h1>
                    </div>
                </Link>
                
                <div className='flex gap-2'>

                    <Link className=" text-slate-700 hover:bg-[#FFB100] hover:shadow-lg rounded-sm px-4 py-1 bg-[#FFC500] flex justify-center  " to={"/join-the-waitlist"} >
                        <h1 className=' text-lg font-normal justify-center align-middle'>Sign up</h1>                        
                    </Link>

                    <Link className=" text-white hover:text-slate-700 hover:bg-[#FFB100] hover:shadow-lg rounded-sm px-4 py-1 border-2 border-[#FFC500] hover:border-[#FFB100] flex justify-center " to={"/login"} >
                        <h1 className=' text-lg font-normal justify-center align-middle'>Login</h1>
                    </Link>

                </div>

            </div>

            {/* ----------------------- NAVIGATION BAR END  ----------------------- */}

            <div className='  h-screen pb-6 max-sm:pb-10 flex flex-col justify-evenly items-center bg-gradient-to-b from-[#f9edbc] to-[#ffffff]'>
                <div className='w-full flex flex-row justify-center gap-5' >

                    <div className='px-3'>
                        <div className='items-start pb-4'>
                            <h1 className=' text-left font-semibold text-4xl pt-1 text-slate-700 max-w-xl'>
                                We make it easy to shop local and healthy foods
                            </h1>
                        </div>
                    
                        <div className='flex flex-col items-start pb-5'>
                            <h1 className='text-left font-light text-xl pt-2 max-w-2xl text-slate-600'>We create software for local food producers and consumers to build independent and healthy food systems for all.</h1>
                        </div>

                        <div className='pb-2'>
                            <h1 className='text-left font-semibold text-md pt-2 max-w-2xl text-slate-700'>Join 1,000+ local shoppers</h1>
                        </div>

                        <Link to={'/search-by-zip'}>
                            <div className=' flex self-align-bottom'>
                                <button className=' rounded-md drop-shadow-xl hover:drop-shadow-2xl bg-[#FFC500]  py-3 font-normal text-slate-700 px-4 text-xl hover:bg-[#FFB100] hover:text-slate-700'>
                                    Get Started!
                                </button>
                            </div>
                        </Link>
                    </div>

                    <div className='flex px-3'  >
                        <img className='rounded-sm right-0 max-w-lg drop-shadow-2xl' src={vegetable_market} alt="" />
                    </div>

                </div>


                {/********************************************/}
                {/********************* LOGO SECTION ***********************/}
                {/********************************************/}
                <div className='w-full flex justify-around align-middle'>
                    <div>
                        <a className='text-gray-400 font-bold text-4xl drop-shadow-sm '>USDA - Organic</a>
                    </div>

                    <div>
                        <a className='text-gray-400 font-bold text-4xl drop-shadow-sm '>Non-GMO</a>
                    </div>

                    <div>
                        <a className='text-gray-400 font-bold text-4xl drop-shadow-sm '>Pesticide-Free</a>
                    </div>
                </div>

            </div>

        </div>

        {/********************************************/}
        {/********************* FIRST PAGE DESKTOP - END ***********************/}
        {/********************************************/}


        {/********************************************/}
        {/********************* SECOND PAGE DESKTOP - START ***********************/}
        {/********************************************/}


        <div className=' bg-[#ffffff] max-sm:pb-10 flex flex-col ' >

            <div className='w-full h-full flex flex-col items-center justify-around pb-16 '>

                <div className='items-center pt-8'>
                    <h1 className=' text-center font-bold text-3xl text-slate-700 max-w-2xl'>
                        Discover 12,228 local organizations
                    </h1>
                </div>

                {/* *********************** TAB SELECTIONS *********************** */}

                <div className='mb-5  border-t-0 w-fit rounded-xl drop-shadow-lg pt-10 '>

                    <ul className="flex felx-row justify-left cursor-pointer">
                        <div onClick={() => toggleTab(1)} className={`${selection === 1 ? 'bg-[#16341fec] text-[#fff9e1]  pb-7  w-40 rounded-l-lg' : 'bg-[#fff9e1] text-[#16341fec]  pb-7  w-40 rounded-l-lg'}`}>
                            <div className='pt-5' >
                                <h1 className='text-xl font-bold'>6989</h1>
                                <h1 className='text-md font-light' >Farmers Markets</h1>
                            </div>
                        </div>

                        <div onClick={() => toggleTab(2)} className={`${selection === 2 ? 'bg-[#16341fec] text-[#fff9e1]  pb-7  w-40' : 'bg-[#fff9e1] text-[#16341fec] pb-7  w-40 '}`}>
                            <div className='pt-5' >
                                <h1 className='text-xl font-bold'>224</h1>
                                <h1 className='text-md font-light' >Co-op Groceries</h1>
                            </div>
                        </div>

                        <div onClick={() => toggleTab(3)} className={`${selection === 3 ? 'bg-[#16341fec] text-[#fff9e1]  pb-7  w-40' : 'bg-[#fff9e1] text-[#16341fec] pb-7  w-40 '}`}>
                            <div className='pt-5' >
                                <h1 className='text-xl font-bold'>1710</h1>
                                <h1 className='text-md font-light' >On-Farm Markets</h1>
                            </div>
                        </div>

                        <div onClick={() => toggleTab(4)} className={`${selection === 4 ? 'bg-[#16341fec] text-[#fff9e1]  pb-7  w-40' : 'bg-[#fff9e1] text-[#16341fec] pb-7  w-40 '}`}>
                            <div className='pt-5' >
                                <h1 className='text-xl font-bold'>994</h1>
                                <h1 className='text-md font-light' >CSAs</h1>
                            </div>
                        </div>

                        <div onClick={() => toggleTab(5)} className={`${selection === 5 ? 'bg-[#16341fec] text-[#fff9e1]  pb-7  w-40' : 'bg-[#fff9e1] text-[#16341fec] pb-7  w-40 '}`}>
                            <div className='pt-5' >
                                <h1 className='text-xl font-bold'>2141</h1>
                                <h1 className='text-md font-light' >CPG Brands</h1>
                            </div>
                        </div>

                        <div onClick={() => toggleTab(6)} className={`${selection === 6 ? 'bg-[#16341fec] text-[#fff9e1]  pb-7 rounded-r-md   w-40' : 'bg-[#fff9e1] text-[#16341fec] pb-7 rounded-r-md   w-40 '}`}>
                            <div className='pt-5' >
                                <h1 className='text-xl font-bold'>230</h1>
                                <h1 className='text-md font-light' >Food Hubs</h1>
                            </div>
                        </div>

                    </ul>
                </div>


                {/* *********************** TAB SELECTIONS *********************** */}

                {selection === 1 ? (
                <>
                <div className=' py-3 ' >
                    <h1 className='text-2xl font-normal' >Farmers Markets</h1>
                    <div className=''>
                        <h1 className='pt-2 text-lg font-light text-center max-w-2xl' >Discover and connect with your local farmers markets. Follow them to stay up-to date with latest news and your favourite local vendors.</h1>
                    </div>
                </div>
                </>
                ) : (<></>)}



                {selection === 2 ? (
                <>
                <div className=' py-3' >
                    <h1 className='text-2xl font-normal' >Co-op Groceries</h1>
                    <h1 className='pt-2 text-lg font-light text-center max-w-2xl' >Cooperative groceries are owned and managed by your local community to deliver you the best quality produce while supporting local communities.</h1>
                </div>
                </>
                ) : (<></>)}


                {selection === 3 ? (
                <>
                <div className=' py-3' >
                    <h1 className='text-2xl font-normal' >On-Farm Markets</h1>
                    <h1 className='pt-2 text-lg font-light text-center max-w-2xl'>Buy your food right where it grows. Visit your local farms that work hard to grow healthy and nutritious foods for you.</h1>
                </div>
                </>
                ) : (<></>)}



                {selection === 4 ? (
                <>
                <div className=' py-3' >
                    <h1 className='text-2xl font-normal' >CSA</h1>
                    <h1 className='pt-2 text-lg font-light text-center max-w-2xl' >CSAs allow you to purchase a share of farm's harvest to ensure that you get as much locally grown and nutritious food as you need.</h1>
                </div>
                </>
                ) : (<></>)}

                {selection === 5 ? (
                <>
                <div className=' py-3' >
                    <h1 className='text-2xl font-normal' >CPG Brands</h1>
                    <h1 className='pt-2 text-lg font-light text-center max-w-2xl' >Discover local brands that make unique consumer products like healthy snacks and natural cosmetics while supporting local economy</h1>
                </div>
                </>
                ) : (<></>)}

                {selection === 6 ? (
                <>
                <div className=' py-3' >
                    <h1 className='text-2xl font-normal' >Food Hubs</h1>
                    <h1 className='pt-2 text-lg font-light text-center max-w-2xl' >Food Hubs help local farms and food producers market and deliver their products to local consumers, making it easier for you to shop locally</h1>
                </div>
                </>
                ) : (<></>)}

            </div>

            <div className='flex flex-col  items-center bg-gradient-to-t from-[#fff3c4] to-[#ffffff] '   >

                <div className=' justify-self-center pt-6 pb-4'>
                    <h1 className=' text-center font-bold text-3xl text-slate-700 max-w-2xl'>
                        <a className='text-[#16341fec] font-bold '></a>Start in 3 simple steps
                    </h1>
                </div>

                <div className='flex flex-row justify-evenly  gap-3 py-7 pb-12'>

                    <div className='text-[#16341fec] bg-[#ffffff] w-1/6 drop-shadow-2xl rounded-lg pb-2   '>
                        <div>
                            <img className='rounded-t-lg' src={vegetables_in_basket} alt="" />
                        </div>
                        <div className='px-2 pt-3 flex flex-col '>
                            <h1 className='text-xl font-light pb-1'>Discover</h1>
                            <hr/>
                            <h1 className='text-sm font-bold pt-1 '>Find your local food producers</h1>
                        </div>
                    </div>

                    <div className='text-[#16341fec] bg-[#ffffff] w-1/6 drop-shadow-2xl rounded-lg pb-2 ' >
                        <div>
                            <img className='rounded-t-lg' src={vegetables_in_basket} alt="" />
                        </div>
                        <div className='px-2 pt-3 flex flex-col '>
                            <h1 className='text-xl font-light pb-1'>Connect</h1>
                            <hr/>
                            <h1 className='text-sm font-bold pt-1'>Follow your local food organizations</h1>
                        </div>
                    </div>

                    <div className='text-[#16341fec] bg-[#ffffff] w-1/6 drop-shadow-2xl rounded-lg pb-2 ' >
                        <div>
                            <img className='rounded-t-lg' src={vegetables_in_basket} alt="" />
                        </div>
                        <div className='px-2 pt-3 flex flex-col '>
                            <h1 className='text-xl font-light pb-1'>Shop</h1>
                            <hr/>
                            <h1 className='text-sm font-bold pt-1'>Start eating local and healthy food</h1>
                        </div>
                    </div>

                </div>

                <div className=' bg-[#FFC500] w-1/2  flex flex-row py-3 justify-center align-middle mt-3 mb-8 rounded-xl drop-shadow-xl  ' >

                    <div className='text-2xl text-slate-800 font-semibold px-8 py-1'>
                        <h1>Take your first step</h1>
                    </div>

                    <Link to={'/search-by-zip'}>
                        <div className=' flex self-align-bottom'>
                            <button className=' rounded-md drop-shadow-xl hover:drop-shadow-2xl bg-[#37774bec]  py-2 font-normal text-slate-50 px-4 text-xl hover:bg-[#2f6942] '>
                                Get Started!
                            </button>
                        </div>
                    </Link>

                </div>

            </div>
        </div>




        <div className='  bg-[#37774bec] text-[#fff9e1] flex flex-row py-4 justify-around align-middle  ' >
            <div>
                <Link to={"/"}>
                    <h1 className='text-xl py-1'>AsirisOS</h1>
                </Link>
            </div>

            <div className='flex gap-3 justify-start py-2'>
                <Link to={"/contact"}>
                    <h1 className='text-sm'> Contact</h1>            
                </Link>

                <Link to={"/organizations-join"}>
                    <h1 className='text-sm'>Organizations</h1>            
                </Link>

                <Link to={"/join-the-team"}>
                    <h1 className='text-sm'>Team</h1>            
                </Link>
            </div>

        </div>

        {/********************************************/}
        {/********************* SECOND PAGE DESKTOP - END ***********************/}
        {/********************************************/}
    </div>
    </div>
    {/********************************************/}
    {/********************************************/}
    {/************************************************************************************ DESKTOP VERSION END *****************************************************************/}
    {/************************************************************************************ DESKTOP VERSION END *****************************************************************/}
    {/************************************************************************************ DESKTOP VERSION END *****************************************************************/}
    {/************************************************************************************ DESKTOP VERSION END *****************************************************************/}
    {/********************************************/}
    {/********************************************/}
    </>
  )
}

export default GeneralUserLanding