import React, {ChangeEventHandler, FocusEventHandler, KeyboardEventHandler} from 'react'
// import {FixMeLater} from "../../../types/dev";

type SecondLevelMoreToComeProps = {
    carouselId?: string,
    financeClasses: string[]

}


function SecondLevelMoreToCome({ financeClasses, carouselId} : SecondLevelMoreToComeProps ) {

    

    return (
    <div id={carouselId} className='carousel-item w-5/6'>
        <div className='text-[#16341fec] flex flex-col justify-center w-11/12 drop-shadow-xl rounded-lg bg-white   '>
            <div className='flex justify-center'>
                <div className='pb-3'>
                    <h1 className='text-3xl text-white bg-[#1763db] px-3 pb-1 rounded-full'>+</h1>
                </div>
            </div>

            <div className='flex flex-col '>
                <div className=' pt-2 '>
                    <h1 className='text-xl font-bold text-center'>More to Come</h1>                
                    <h1 className='text-lg font-semibold' ></h1>
                </div>
                <hr/>
                <div className='self-center  pb-1 rounded-b-lg px-2 pt-2 flex flex-col text-start'>

                {financeClasses.map((financeClass) => (
                    <h1>{financeClass}</h1>
                ))}
                
                    {/* {!financeClasses?.map((testItem) => {
                        <h1 className=' text-sm font-light text-start' >{testItem}</h1>
                    } )} */}
                </div>
            </div>
        </div>
    </div>


    )
  }

  export default SecondLevelMoreToCome