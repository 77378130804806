import axios from "axios";
import {UserData} from "@asirisos/types/data";
import RequestBodyFactory from "../../factories/requestBodyFactory";
import { EmailSignUpModel } from "@asirisos/types";

const API_URL = '/api/users'

const register = async (userData: UserData) => {
    const body = new RequestBodyFactory(userData).Build()
    const response = await axios.post(API_URL + '/register', body)

    if(response.data) {
        localStorage.setItem('user', JSON.stringify(response.data))
    }
    
    return response.data
}

const emailSignUp = async (emailSignUpData: EmailSignUpModel) => {
    const body = new RequestBodyFactory(emailSignUpData).Build()
    console.log('dispatched', body)
    return await axios.post(API_URL + '/emailsignup', body)
}

const signin = async (userData: UserData) => {
    const body = new RequestBodyFactory(userData).Build()
    return await axios.post(API_URL + '/login', body)
}

const forgotpassword = async (userData: UserData) => {
    const body = new RequestBodyFactory(userData).Build()
    const response = await axios.post(API_URL + '/forgotpassword', body)

    if(response.data) {
        return response.data
    }
}

const authService = {
    register,
    emailSignUp,
    signin,
    forgotpassword
}

export default authService