import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {FaUser} from 'react-icons/fa'
import {toast} from 'react-toastify'
import {useSelector, useDispatch} from 'react-redux'
import {register, reset} from '../../../features/auth/authSlice'
import Spinner from '../../components/Spinner'
import FormCard from '../../components/shared/FormCard'
import {AppDispatch, RootState} from "../../../app/store";
import NumberInputInUnits from 'UI/components/shared/NumberInputInUnits'
import {InputSelection} from "../../../types/ui";



function Register() {

  const [formData, setFormData] = useState({
    name: '',
    // category: '',
    email: '',
    password: '',
    password2: ''
  })

  const categorySelection = [new InputSelection('farmer', 'farmer'), new InputSelection('wholesale buyer', 'wholesale buyer'), new InputSelection('retail buyer', 'retail buyer'), new InputSelection('services', 'services')]
  const {name,  email, password, password2} = formData

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const {user, isLoading, isError, isSuccess, message} = useSelector((state: RootState) => state.auth)

  useEffect(() => {
    dispatch(reset())
  }, [dispatch])

  if (isError) {
    toast.error(message)
  }

  // Redirect when logged in
  if (isSuccess || user) {
    navigate('/setup-start')
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    if (password !== password2) {
      toast.error('passwords do not match')
    } else {
      const userData = {
        name,
        email,
        password,
      }
      dispatch(register(userData))
    }
  }
  if (isLoading) {
    return <Spinner/>
  }
  return (
    <>
    <div className='flex justify-center bg-slate-100 h-full' >
           <FormCard title='Register'>
             <section className="heading">
          <div className="flex justify-center">
            <h1 className=" pt-2 px-6"><FaUser/></h1>
            <h1> Register </h1>
          </div>
          <p>Please create an account</p>
        </section>
        <form onSubmit={onSubmit}>
          <div className='flex justify-center' >
              <div className='max-w-2xl'>
                <NumberInputInUnits onChange={(e) => onChange(e)} value={name} id={"name"} type={'text'} group={"name"} label={"name"} name={"name"} dataTitle="Farm Name" />
                <NumberInputInUnits onChange={(e) => onChange(e)} value={email} id={"email"} type={'text'} group={"email"} label={"email"} name={"email"} dataTitle="Email" />
                <NumberInputInUnits onChange={(e) => onChange(e)} value={password} id={"password"} type={'password'} group={"password"} label={"password"} name={"password"} dataTitle="Password" />
                <NumberInputInUnits onChange={(e) => onChange(e)} value={password2} id={"password2"} type={'password'} group={"password2"} label={"password2"} name={"password2"} dataTitle="Confirm Password" />
                {/* <LegacyDropDownInput onChange={(e) => onChange(e)} selections={roleSelection} value={role} id={"role"} type={'text'} group={"role"} label={"role"} name={"role"} dataTitle="role" /> */}
              </div>
            </div>
            <div className='flex justify-center pt-8 p-2' >
                <button className="btn btn-wide btn-primar hover:bg-black">Register</button>
            </div>
        </form>
      </FormCard>
      </div>


      {/* <FormCard title="Register">
        <section className="heading">
          <div className="flex justify-center">
            <h1 className=" pt-2 px-6"><FaUser/></h1>
            <h1> Register </h1>
          </div>
          <p>Please create an account</p>
        </section>

        <section className="form">
          <form onSubmit={onSubmit}>
            <FormTwoInputs>
              <LegacyDropDownInput onChange={(e) => onChange(e)} selections={categorySelection} value={category}
                             id={"category"} type={'text'} group={"category"} label={"category"} name={"category"}
                             dataTitle="category"/>
            </FormTwoInputs>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id='name'
                name='name'
                value={name}
                onChange={onChange}
                placeholder='Enter your name'
                required/>
            </div>
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                id='email'
                name='email'
                value={email}
                onChange={onChange}
                placeholder='Enter your email'
                required/>
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                id='password'
                name='password'
                value={password}
                onChange={onChange}
                placeholder='Enter your password'
                required/>
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                id='password2'
                name='password2'
                value={password2}
                onChange={onChange}
                placeholder='Confirm Password'
                required/>
            </div>
            <div className="form-group">
              <button className="btn btn-block">
                Submit
              </button>
            </div>
          </form>
        </section>
      </FormCard> */}
    </>
  )
}

export default Register