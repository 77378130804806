import {createSlice, createAsyncThunk, PayloadAction} from "@reduxjs/toolkit";
import expenseService from "./expenseService";
import HandleAxiosError from "../../utils/AxiosErrorHandler";
import {DefaultThunkApiConfig, Status} from "../../types/slice";
import { ExpenseState } from "types/states";
import {ExpenseModel} from "@asirisos/types";

const CONTEXT = "Expense"

const initialState: ExpenseState = {
    expense: undefined,
    expenses: [],
    status: Status.Unknown,
    message: ''
}


export const addExpense = createAsyncThunk<any, ExpenseModel, DefaultThunkApiConfig>('expense/add', async (expenseData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await expenseService.addExpense(expenseData, account)
        if (response.status !== 201)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getExpenses = createAsyncThunk<ExpenseModel[], void, DefaultThunkApiConfig>('expense/getall', async (_, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await expenseService.getExpenses(account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getExpense = createAsyncThunk<ExpenseModel, string, DefaultThunkApiConfig>('expense/getone/:expenseId', async (expenseId, thunkAPI) => {
    try {
        const response = await expenseService.getExpense(expenseId)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const updateExpense = createAsyncThunk<ExpenseModel, ExpenseModel, DefaultThunkApiConfig>('expense/update/:expenseid', async (expenseData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if(!account)
            return thunkAPI.rejectWithValue();

        const response = await expenseService.updateExpense(expenseData, account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const deleteExpense = createAsyncThunk<any, string[], DefaultThunkApiConfig>('expense/delete', async (expenseId, thunkAPI) => {
    try {
        const response = await expenseService.deleteExpense(expenseId)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})


export const expenseSlice = createSlice({
    name: 'expense',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder
        .addCase(addExpense.pending, (state: ExpenseState) => {
            state.status = Status.Pending
        })
        .addCase(addExpense.fulfilled, (state: ExpenseState) => {
            state.status = Status.Success
        })
        .addCase(addExpense.rejected, (state: ExpenseState) => {
            state.status = Status.Failure
        })
        .addCase(getExpenses.pending, (state: ExpenseState) => {
            state.status = Status.Pending
        })
        .addCase(getExpenses.fulfilled, (state: ExpenseState, action: PayloadAction<ExpenseModel[], any, any>) => {
            state.status = Status.Success
            state.expenses = action.payload
        })
        .addCase(getExpenses.rejected, (state: ExpenseState) => {
            state.status = Status.Failure
        })
        .addCase(getExpense.pending, (state: ExpenseState) => {
            state.status = Status.Pending
        })
        .addCase(getExpense.fulfilled, (state: ExpenseState, action: PayloadAction<ExpenseModel, any, any>) => {
            state.status = Status.Success
            state.expense = action.payload
        })
        .addCase(getExpense.rejected, (state: ExpenseState) => {
            state.status = Status.Failure
        })
        .addCase(updateExpense.pending, (state: ExpenseState) => {
            state.status = Status.Pending
        })
        .addCase(updateExpense.fulfilled, (state: ExpenseState, action: PayloadAction<ExpenseModel, any, any>) => {
            state.status = Status.Success
            state.expenses.map((expense: ExpenseModel) => expense._id === action.payload._id ?
                (state.expense = expense) : expense)
            state.expense = action.payload
        })
        .addCase(updateExpense.rejected, (state: ExpenseState) => {
            state.status = Status.Failure
        })
        .addCase(deleteExpense.pending, (state: ExpenseState) => {
            state.status = Status.Pending
        })
        .addCase(deleteExpense.fulfilled, (state: ExpenseState) => {
            state.status = Status.Success
            state.expense = undefined
        })
        .addCase(deleteExpense.rejected, (state: ExpenseState) => {
            state.status = Status.Failure
        })
    }
})

export const {reset} = expenseSlice.actions
export default expenseSlice.reducer