import { ActivityModel } from '@asirisos/types'
import {Link} from 'react-router-dom'
import React from 'react'

function ActivityConnectionsDisplay(props: {activity : ActivityModel | undefined}) {
  return (
    <>
    {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}
    {/* ///////////////////////////////////    TASK CONNECTION DISPLAY ////////////////////////////////////////// */}
    {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}
    <div className="bg-white mb-10 mt-10 py-5 rounded-lg #drop-shadow-xl #shadow-lg relative px-9 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] mx-5 ">
        <div className='flex flex-col' >
        <h1>ACTIVITY CONNECTION</h1>

        {/* /////////////////// CONNECTION = CROP ///////////////// */}

            {props.activity?.connectionCategory === 'crop' ? (
            <>
            <Link to={`/crop/${props.activity?.cropObject?._id}`} className="border-2 rounded-lg border-blue-500 px-2">
                <h1>Connection Crop</h1>
                <h1>{props.activity?.cropObject?.cropName}</h1>
                <h1>{props.activity?.cropObject?.developmentStage}</h1>
                <h1>{props.activity?.cropObject?.stageProgress}</h1>
                <h1>{props.activity?.cropObject?.waterNutrition}</h1>
                <h1>{props.activity?.cropObject?.nitrogenNutrition}</h1>
                <h1>{props.activity?.cropObject?.phosphorusNutrition}</h1>
                <h1>{props.activity?.cropObject?.potassiumNutrition}</h1>
            </Link>
            </>) : (<></>) }

        {/* /////////////////// CONNECTION = LIVE STOCK ///////////////// */}

            {props.activity?.connectionCategory === 'live stock' ? (
            <>
            <Link to={`/liveStock/${props.activity?.liveStockObject?._id}`} className="border-2 rounded-lg border-blue-500 px-2">
                <h1>Connection Live Stock</h1>
                <h1>{props.activity?.liveStockObject?.name}</h1>
                <h1>{props.activity?.liveStockObject?.category}</h1>
                <h1>{props.activity?.liveStockObject?._id}</h1>
                <h1>{props.activity?.liveStockObject?.sex}</h1>
                <h1>{props.activity?.liveStockObject?.weight}</h1>
                <h1>{props.activity?.liveStockObject?.description}</h1>
            </Link>
            </>) : (<></>) }

        {/* /////////////////// CONNECTION = EMPLOYEE ///////////////// */}

            {props.activity?.connectionCategory === 'employee' ? (
            <>
            <Link to={`/account/${props.activity?.employeeObject?._id}`} className="border-2 rounded-lg border-blue-500 px-2">
                <h1>Connection Employee</h1>
                <h1>{props.activity?.employeeObject?.name}</h1>
                <h1>{props.activity?.employeeObject?.job_title}</h1>
                <h1>{props.activity?.employeeObject?.qualifications}</h1>
                <h1>{props.activity?.employeeObject?.email}</h1>
                <h1>{props.activity?.employeeObject?.phone}</h1>
            </Link>
            </>) : (<></>) }

        {/* /////////////////// CONNECTION = EQUIPMENT ///////////////// */}

        {props.activity?.connectionCategory === 'equipment' ? (
            <>
            <Link to={`/equipment/${props.activity?.equipmentObject?._id}`} className="border-2 rounded-lg border-blue-500 px-2">
                <h1>Connection Equipment</h1>
                <h1>{props.activity?.equipmentObject?.name}</h1>
                <h1>{props.activity?.equipmentObject?.type}</h1>
                <h1>{props.activity?.equipmentObject?.mileage}</h1>
            </Link>
            </>) : (<></>) }

        {/* /////////////////// CONNECTION = INVENTORY ///////////////// */}

        {props.activity?.connectionCategory === 'inventory' ? (
            <>
            <Link to={`/inventory/${props.activity?.inventoryObject?._id}`} className="border-2 rounded-lg border-blue-500 px-2">
                <h1>Connection Inventory</h1>
                <h1>{props.activity?.inventoryObject?.name}</h1>
                <h1>{props.activity?.inventoryObject?.type}</h1>
                <h1>{props.activity?.inventoryObject?.quantity}</h1>
            </Link>
            </>) : (<></>) }
        
        {/* /////////////////// CONNECTION = STORAGE ///////////////// */}

        {props.activity?.connectionCategory === 'storage' ? (
            <>
            <Link to={`/storage/${props.activity?.storageObject?._id}`} className="border-2 rounded-lg border-blue-500 px-2">
                <h1>Connection Storage</h1>
                <h1>{props.activity?.storageObject?.name}</h1>
                <h1>{props.activity?.storageObject?.category}</h1>
                <h1>{props.activity?.storageObject?.description}</h1>
                <h1>{props.activity?.storageObject?.quantity}</h1>
            </Link>
            </>) : (<></>) }

        {/* /////////////////// CONNECTION = CUSTOMER ///////////////// */}

        {props.activity?.connectionCategory === 'customer' ? (
            <>
            <Link to={`/customer/${props.activity?.customerObject?._id}`} className="border-2 rounded-lg border-blue-500 px-2">
                <h1>Connection Customer</h1>
                <h1>{props.activity?.customerObject?.firstName}</h1>
                <h1>{props.activity?.customerObject?.lastName}</h1>
                <h1>{props.activity?.customerObject?.email}</h1>
            </Link>
            </>) : (<></>) }
        </div>

    </div>
    </>
  )
}

export default ActivityConnectionsDisplay