import axios from "axios";
import {AccountModel, EmployeeModel} from "@asirisos/types";
import {AccountClaimData, AccountSearchData} from "@asirisos/types/data";
import RequestBodyFactory from "../../factories/requestBodyFactory";
import {FixMeLater} from "../../types/dev";
import {RequestBody} from "@asirisos/types/network";

const API_URL = '/api/accounts'

const addEmployee = async (employeeData: EmployeeModel, account: AccountModel) => {
    if (account && account.farm) {
        const body = new RequestBodyFactory(employeeData).WithFarmId(account?.farm?._id!).Build()
        return await axios.post(API_URL + '/addemployee', body)
    } else
        throw new Error("")

}

const getAccounts = async () => {
    return await axios.get(API_URL + '/all')
}

const getFarmAccounts = async (account: AccountModel) => {
    return await axios.get(API_URL + '/allfarm/' + account?.farm?._id)
}

const getAccount = async (accountID: string) => {
    return await axios.get(API_URL + '/' + accountID)
}

const getEmployeeAccount = async (accountID?: string) => {
    console.log('ACCOUNT SERVICE - get employee acc - checking')
    return await axios.get(API_URL + '/' + accountID)
}

const updateEmployeeAccount = async (employeeData: EmployeeModel, account: AccountModel) => {

    const {_id} = employeeData

    const body = new RequestBodyFactory(employeeData).WithAccount(account!).Build()
    return await axios.put<any, FixMeLater, RequestBody<EmployeeModel>>(API_URL + '/' + _id, body)


    // const data = {
    //     employeeData: employeeData,
    //     account: account
    // }
    // console.log('Account Service - Update Employee - data', data)
    // return await axios.put(API_URL + '/' + _id, data)
}

const searchUnclaimedAccount = async (accountSearchData: AccountSearchData, account: AccountModel) => {
    if (account && account.farm) {
        const body = new RequestBodyFactory(accountSearchData).WithFarmId(account?.farm?._id!).Build()
        return await axios.post(API_URL + '/search', body)
    } else
        throw new Error();
}

const claimAccount = async (accountClaimData: AccountClaimData, account: AccountModel) => {
    if (account && account.farm) {
        const body = new RequestBodyFactory(accountClaimData).WithFarmId(account?.farm?._id!).Build()
        const {accountID} = accountClaimData
        return await axios.post(API_URL + '/claim/' + accountID, body)
    }
    else
        throw new Error()
}

const accountService = {
    claimAccount,
    addEmployee,
    getEmployeeAccount,
    searchUnclaimedAccount,
    updateEmployeeAccount,
    getAccounts,
    getFarmAccounts,
    getAccount,
}

export default accountService