import React from 'react'
import {Link} from 'react-router-dom'
import EmployeeTable from './EmployeeTable';
import {Translation} from "react-i18next";


function Employees() {
    return (
        <>
        <div className='px-5'>
            <div
                className='listContainer  bg-white  rounded-lg #drop-shadow-xl #shadow-lg relative shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]   '>
                <EmployeeTable/>
            </div>
            <Link className="btn mb-8" to={"/employee-form"}>
                <Translation ns={"employee"}>
                    {t => t("employee:addEmployee")}
                </Translation>
            </Link>
        </div>
        </>
    )
}

export default Employees