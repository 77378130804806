import React, {ReactNode} from 'react'
import './Card.css'

type CardProps = {
    children : ReactNode,
    reverse? : boolean
}

function Card({children, reverse} : CardProps) {
    return (
        <div className= {`card ${reverse && 'reverse'} bg-gray-200 py-3 rounded-lg shadow-md relative mx-9`}>
            {children}
        </div>
    )
}

export default Card