import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import FormCard from '../../components/shared/FormCard'
import FormTwoInputs from '../../components/shared/FormTwoInputs'
import NumberInputInUnits from '../../components/shared/NumberInputInUnits'
import LegacyDropDownInput from '../../components/shared/LegacyDropDownInput'
import {addLiveStock, getAllLiveStock} from '../../../features/live-stock/live-stockSlice'
import {AppDispatch} from "../../../app/store";
import {InputSelection} from "../../../types/ui";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import MultiDropDownInput, {DropDownOption} from "../../components/shared/MultiDropDownInput";
import {ActionMeta} from "react-select";
import {LiveStockModel} from "@asirisos/types";

function LiveStockForm() {
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const {t} = useTranslation(['livestock']);


    useEffect(() => {
        dispatch(getAllLiveStock())
    }, [dispatch])


    const typeSelection = [
        new InputSelection(t("livestock:category-cattle"), 'cattle'),
        new InputSelection(t("livestock:category-hogs"), 'hogs'),
        new InputSelection(t("livestock:category-poultry"), 'poultry'),
        new InputSelection(t("livestock:category-sheep"), 'sheep'),
        new InputSelection(t("livestock:category-goats"), 'goats'),]

    // const sexSelection = [
    //     new InputSelection(t("livestock:sex-male"), 'male'),
    //     new InputSelection(t("livestock:sex-female"), 'female'),]
    const sexSelection: DropDownOption[] = [
        {value: 'male', label: t("livestock:sex-male")},
        {value: 'female', label: t("livestock:sex-female")}
    ]

    const [formData, setFormData] = useState({
        name: '',
        description: '',
        category: '',
        dob: '',
        sex: '',
        group: '',
        breed: '',
        status: '',
        quantity: 0,
        weight: 0,
    })

    const {name, description, category, dob, sex, group, breed, status, weight, quantity} = formData

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const onDropDownChange = (option: DropDownOption | null, actionMeta: ActionMeta<DropDownOption>) => {
        if (actionMeta.name) {
            setFormData((prevState) => ({
                ...prevState,
                [actionMeta.name!]: option?.value
            }))
        }
    }

    const canSave = true;

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault()

        if (canSave) {
            const liveStockData : LiveStockModel = {
                name, description, category, dob, sex, group, breed, status, weight, quantity
            }

            dispatch(addLiveStock(liveStockData))
                .unwrap()
                .then(() => {
                    toast.success(t("livestock:liveStockAdded"));
                    navigate("/livestock")
                })
                .catch(() => {
                    toast.error(t("livestock:failedToAddLivestock"))
                    navigate("/livestock-form")
                })
        }
    }
    return (
        <>
            <FormCard title={t("livestock:addLiveStockTitle")}>
                <form onSubmit={onSubmit}>
                    <FormTwoInputs>
                        <NumberInputInUnits onChange={(e) => onChange(e)} value={name} id={"name"} type={'text'}
                                            group={"name"} label={"name"} name={"name"}
                                            dataTitle={t("translation:name")}/>
                        <LegacyDropDownInput onChange={(e) => onChange(e)} selections={typeSelection} value={category}
                                             id={"category"} type={'text'} group={"category"} label={"category"}
                                             name={"category"} dataTitle={t("translation:category")}/>
                    </FormTwoInputs>

                    <FormTwoInputs>
                        <NumberInputInUnits onChange={(e) => onChange(e)} value={dob} id={"dob"} type={'date'}
                                            group={"dob"} label={"dob"} name={"dob"} dataTitle="Date of Birth"/>
                        <MultiDropDownInput dataTitle={t("translation:Sex")} isSingleSelect={true} id={"sex"}
                                            name={"sex"}
                                            value={sex} options={sexSelection} onChange={onDropDownChange}/>
                    </FormTwoInputs>

                    <FormTwoInputs>
                        <NumberInputInUnits onChange={(e) => onChange(e)} value={weight.toString()}
                                            id={"weight"} fixedInputItem={"kg"}
                                            type={'number'} group={"weight"} label={"weight"} name={"weight"}
                                            dataTitle={t("translation:Weight")}/>
                        <NumberInputInUnits onChange={(e) => onChange(e)} value={breed} id={"breed"} type={'text'}
                                            group={"breed"} label={"breed"} name={"breed"}
                                            dataTitle={t("translation:Breed")}/>
                    </FormTwoInputs>

                    <FormTwoInputs>
                        <NumberInputInUnits onChange={(e) => onChange(e)} value={status} id={"status"} type={'text'}
                                            group={"status"} label={"status"} name={"status"}
                                            dataTitle={t("translation:status")}/>
                        <NumberInputInUnits onChange={(e) => onChange(e)} value={group} id={"group"} type={'text'}
                                            group={"group"} label={"group"} name={"group"}
                                            dataTitle={t("translation:Group")}/>
                    </FormTwoInputs>

                    <FormTwoInputs>
                        <NumberInputInUnits onChange={(e) => onChange(e)} value={description} id={"description"}
                                            type={'text'} group={"description"} label={"description"}
                                            name={"description"}
                                            dataTitle={t("translation:description")}/>
                        {/* <NumberInputInUnits onChange={(e) => onChange(e)} value={quantity.toString()}
                                                id={"quantity"}
                                                type={'number'} group={"quantity"} label={"quantity"} name={"quantity"}
                                                dataTitle={t("translation:quantity")}/> */}
                    </FormTwoInputs>


                    <div className='flex justify-center pt-8 p-2'>
                        <button
                            className="btn btn-wide btn-primar hover:bg-black">{t("livestock:addLiveStockTitle")}</button>
                    </div>
                </form>
            </FormCard>
        </>
    )
}

export default LiveStockForm