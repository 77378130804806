import axios from "axios";
import {AccountModel, FarmModel} from "@asirisos/types";
import RequestBodyFactory from "../../factories/requestBodyFactory";

const API_URL = '/api/farms'

const addFarm = async(farmData: FarmModel) => {
    const body = new RequestBodyFactory(farmData).Build()
    return await axios.post(API_URL+'/add', body)
}

const getFarms = async(token: string) => {
    const response = await axios.get(API_URL+'/getall')
    return response.data
} 

// Get My Farm

const getMyFarm = async(account: AccountModel, token: string) => {
    const {farm} =  account
    const response = await axios.get(API_URL+'/getone/'+farm._id)

    return response.data
}

// Get Single Farm

const getFarm = async(farmId: string) => {
    const response = await axios.get(API_URL+'/getone/'+farmId)
    return response.data
}

// Get Farm Public Profile

const getFarmProfile = async(FarmId: string) => {
    const response = await axios.get(API_URL+'/profile/'+FarmId)
    console.log('Farm Service - Get Farm Profile - response', response.data)
    return response.data
}

const updateFarm = async(farmData: FarmModel) => {
    const body = new RequestBodyFactory(farmData).Build()
    return await axios.put(API_URL + '/update/' + farmData._id, body)
}

// Search Farm

const searchFarm = async(farmAlias: string, token: string) => {
    const response = await axios.get(API_URL+'/search/'+farmAlias)
    return response.data
}


const farmService = {
    addFarm,
    getFarms,
    getFarm,
    updateFarm,
    getFarmProfile,
    getMyFarm,
    searchFarm
}

export default farmService