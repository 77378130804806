import React, {useState, useEffect, ChangeEvent} from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import FormCard from '../../components/shared/FormCard'
import FormTwoInputs from '../../components/shared/FormTwoInputs'
import NumberInputInUnits from '../../components/shared/NumberInputInUnits'
import LargeTextInput from '../../components/shared/LargeTextInput'
import DropDownInput from '../../components/shared/LegacyDropDownInput'
import FormSelectionRangeInput from '../../components/shared/card/FormSelectionRangeInput'
import LegacyDropDownInput from '../../components/shared/LegacyDropDownInput'
import { getCrops } from '../../../features/crops/cropSlice'
import { getAllInventory } from 'features/inventory/inventorySlice'
import { getCustomers } from 'features/customers/customerSlice'
import { getAllStorage } from 'features/storage/storageSlice'
import { getFarmAccounts } from 'features/accounts/accountSlice'
import { addTask } from '../../../features/task/taskSlice'
import {AppDispatch, RootState} from "../../../app/store";
import {InputSelection} from "../../../types/ui";
import { getAllLiveStock } from 'features/live-stock/live-stockSlice'
import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import { getAllEquipment } from 'features/equipment/equipmentSlice'
import {TaskModel, YieldModel} from "@asirisos/types";
import CheckBoxButtonSelection from 'UI/components/shared/CheckBoxButtonSelection'
import { getFields } from 'features/fields/fieldSlice'
import { getInfrastructures } from 'features/infrastructure/infrastructureSlice'
import { addYield } from 'features/yield/yieldSlice'

function YieldForm() {

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    Initiate Hooks   /////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    USE EFFECT   /////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    useEffect(() => {
        dispatch(getCrops())
        dispatch(getAllLiveStock())
        dispatch(getAllStorage())
        dispatch(getFields())
        dispatch(getInfrastructures())
    },[dispatch] )

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    USE SELECTOR   ///////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const {t} = useTranslation('task');
    const {crops} = useSelector((state : RootState) => state.crop )    
    const {allLiveStock} = useSelector((state : RootState) => state.livestock )    
    const {allStorage} = useSelector((state: RootState) => state.storage )
    const {fields} = useSelector((state: RootState) => state.field )
    const {infrastructures} = useSelector((state: RootState) => state.infrastructure )
    

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  CONNECTION SELECTIONS   ////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
    const cropSelections = crops.map((crop) => {return {name: crop.cropName, id:crop._id}})
    const fieldSelections = fields.map((field) => {return {name: field.fieldName, id:field._id}})
    const liveStockSelections = allLiveStock.map((livestock) => {return {name: livestock.name, id:livestock._id}})
    const storageSelections = allStorage.map((storage) => {return {name: storage.name, id:storage._id}})
    const infrastructureSelections = infrastructures.map((infrastructure) => {return {name: infrastructure.infrastructureName, id:infrastructure._id}})


    const connectionSelection = [
        new InputSelection('crop', 'crop'),
        new InputSelection('live stock', 'live stock'), 
        new InputSelection('storage', 'storage'),
        new InputSelection('employee', 'employee'),
        new InputSelection('equipment', 'equipment'),
        new InputSelection('inventory', 'inventory'),
        new InputSelection('customer', 'customer')]


    const yieldCategorySelection = [
        new InputSelection(t("yield:crop"), 'crop'),
        new InputSelection(t("yield:liveStock"), 'liveStock')]


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    USE STATE      ///////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const [yieldCategory, setYieldCategory] = useState("")

    const onYieldCategoryChange = (e : any) => {
      setYieldCategory(e.target.value)
    }

    const [formData, setFormData] = useState({
        title: '',
        description: '',
        totalArea: '',
        totalAreaUnit: '',
        connectionCategory: '',
        yieldStageGrowing: 'false',
        yieldStageStorage: 'false',
        crop: '',
        liveStock: '',
        farmField: '',
        infrastructure: '',
        storage: '',
    })

    const {
        title,
        totalArea,
        totalAreaUnit,
        description,
        connectionCategory,
        crop,
        liveStock,
        yieldStageGrowing,
        yieldStageStorage,
        farmField,
        infrastructure,
        storage,
            } = formData

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    CONNECTION TOGGLE      ///////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const [connectionAdded, setConnectionAdded] = React.useState('false');

    const onToggleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const toggleValue = e.target.value

        if (toggleValue === 'true') { setConnectionAdded('false') }
        if (toggleValue === 'false') { setConnectionAdded('true') }
        
   }

   const onRoleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (e.target.value === 'false') {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: 'true'
        }))
    }

    if (e.target.value === 'true') {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: 'false'
        }))
    }
}


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    ON CHANGE & ON SUBMIT      ///////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }) )
    }

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        const yieldData: YieldModel = {
            
            totalArea,
            totalAreaUnit,
            connectionCategory,
            yieldStageGrowing,
            yieldStageStorage,
            farmField,
            title,
            infrastructure,
            description,
            crop,
            liveStock,
            storage,
        }
        dispatch(addYield(yieldData))
            .unwrap()
            .then(() => {
                toast.success(t("yield:yieldAdded"));
                // navigate("/yields")
            })
            .catch(() => {
                toast.error(t("yield:yieldAddFailed"))
                // navigate("/yield-form")
            })
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    COMPONENT      ///////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
  return (
    <>
        <FormCard title="Add Task">
        <form onSubmit={onSubmit}>
            <FormTwoInputs>
                <LegacyDropDownInput onChange={onYieldCategoryChange} selections={yieldCategorySelection} value={yieldCategory}
                                                id={"yieldCategory"} type={'text'} group={"yieldCategory"} label={"yieldCategory"} name={"yieldCategory"}
                                                dataTitle={t("translation:category")}/>
            </FormTwoInputs>


            {yieldCategory === 'crop' ?  (
            <>
                <FormTwoInputs>
                    <NumberInputInUnits onChange={(e) => onChange(e)} value={title} id={"title"} type={'text'} group={"title"} label={"title"} name={"title"} dataTitle="Title" />                
                </FormTwoInputs>

                <FormTwoInputs>
                    <DropDownInput onChange={(e) => onChange(e)} selections={cropSelections} value={crop} id={"crop"} type={'text'} group={"crop"} label={"crop"} name={"crop"} dataTitle="Crop" />
                    <DropDownInput onChange={(e) => onChange(e)} selections={fieldSelections} value={farmField} id={"farmField"} type={'text'} group={"farmField"} label={"farmField"} name={"farmField"} dataTitle="farmField" />
                </FormTwoInputs>
                
                <div className='mx-16' >
                    <LargeTextInput onChange={(e) => onChange(e)} value={description} id={"description"} type={'text'} group={"description"} label={"description"} name={"description"} dataTitle="Description" />
                </div>

                <div className='flex flex-row pt-6' >
                    <CheckBoxButtonSelection onChange={(e) => onRoleChange(e)} value={yieldStageGrowing} id={"yieldStageGrowing"} name={"yieldStageGrowing"} title={t("farm:growing")}   />
                    <CheckBoxButtonSelection onChange={(e) => onRoleChange(e)} value={yieldStageStorage} id={"yieldStageStorage"} name={"yieldStageStorage"} title={t("farm:storage")}   />
                </div>

                <FormTwoInputs>
                    <NumberInputInUnits denominations={['select', 'ac', 'ha', 'sq.ft']}
                                                dataTitle={t("yield: totalArea")} dName={"totalAreaUnit"}
                                                dValue={totalAreaUnit} dId={"totalAreaUnit"}
                                                dType={'string'}
                                                onChange={(e) => onChange(e)} value={totalArea.toString()}
                                                id={"totalArea"} type={'number'} name={"totalArea"}/>

                    <NumberInputInUnits denominations={['select', 'ac', 'ha', 'sq.ft']}
                                                dataTitle={t("yield: expectedYield")} dName={"totalAreaUnit"}
                                                dValue={totalAreaUnit} dId={"totalAreaUnit"}
                                                dType={'string'}
                                                onChange={(e) => onChange(e)} value={totalArea.toString()}
                                                id={"totalArea"} type={'number'} name={"expectedYield"}/>
                </FormTwoInputs>


                {/* //////////////////// CONNECTION ADDED REFERENCE SECTION  ///////////////////////////////// */}

                {/* <div className='pt-4 mt-8 pb-3' >
                    <label className="relative inline-flex items-center cursor-pointer">
                        <input type="checkbox" onChange={(e) => onToggleChange(e)} value={connectionAdded} className="sr-only peer"/>
                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        <h1 className='ml-3 text-slate-500 text-md' >Add Reference</h1>
                    </label>
                    
                {connectionAdded === 'true' ? (
                    <>
                        <div className='' >
                            
                        <FormTwoInputs>
                            <DropDownInput onChange={(e) => onChange(e)} selections={connectionSelection} value={connectionCategory} id={"connectionCategory"} type={'text'} group={"connectionCategory"} label={"connectionCategory"} name={"connectionCategory"} dataTitle="Category" />
                            {connectionCategory === 'crop' ? (
                                <>
                                    <DropDownInput onChange={(e) => onChange(e)} selections={cropSelections} value={crop} id={"crop"} type={'text'} group={"crop"} label={"crop"} name={"crop"} dataTitle="Crop" />
                                </>) : (<></>) }

                            {connectionCategory === 'live stock' ? (
                                <>
                                    <DropDownInput onChange={(e) => onChange(e)} selections={liveStockSelections} value={liveStock} id={"liveStock"} type={'text'} group={"liveStock"} label={"liveStock"} name={"liveStock"} dataTitle="Live Stock" />
                                </>) : (<></>) }
                        </FormTwoInputs>
                        </div>
                    </>) : (<></>) }
                </div> */}


                {/* //////////////////// CONNECTION ADDED REFERENCE SECTION  ///////////////////////////////// */}



            </>    
            ) : (<></>)}

            {yieldCategory === 'liveStock' ?  (
            <>
            <h1>Live Stock Section</h1>
                 <FormTwoInputs>
                    <NumberInputInUnits onChange={(e) => onChange(e)} value={title} id={"title"} type={'text'} group={"title"} label={"title"} name={"title"} dataTitle="Title" />                
                </FormTwoInputs>

                <FormTwoInputs>
                    <DropDownInput onChange={(e) => onChange(e)} selections={liveStockSelections} value={liveStock} id={"liveStock"} type={'text'} group={"liveStock"} label={"liveStock"} name={"liveStock"} dataTitle="liveStock" />
                    <DropDownInput onChange={(e) => onChange(e)} selections={infrastructureSelections} value={infrastructure} id={"infrastructure"} type={'text'} group={"infrastructure"} label={"infrastructure"} name={"infrastructure"} dataTitle="infrastructure" />
                </FormTwoInputs>
                
                <div className='mx-16' >
                    <LargeTextInput onChange={(e) => onChange(e)} value={description} id={"description"} type={'text'} group={"description"} label={"description"} name={"description"} dataTitle="Description" />
                </div>

                <div className='flex flex-row pt-6' >
                    <CheckBoxButtonSelection onChange={(e) => onRoleChange(e)} value={yieldStageGrowing} id={"yieldStageGrowing"} name={"yieldStageGrowing"} title={t("farm:growing")}   />
                    <CheckBoxButtonSelection onChange={(e) => onRoleChange(e)} value={yieldStageStorage} id={"yieldStageStorage"} name={"yieldStageStorage"} title={t("farm:storage")}   />
                </div>

                <FormTwoInputs>
                    <NumberInputInUnits denominations={['select', 'ac', 'ha', 'sq.ft']}
                                                dataTitle={t("yield: totalArea")} dName={"totalAreaUnit"}
                                                dValue={totalAreaUnit} dId={"totalAreaUnit"}
                                                dType={'string'}
                                                onChange={(e) => onChange(e)} value={totalArea.toString()}
                                                id={"totalArea"} type={'number'} name={"totalArea"}/>

                    <NumberInputInUnits denominations={['select', 'ac', 'ha', 'sq.ft']}
                                                dataTitle={t("yield: expectedYield")} dName={"totalAreaUnit"}
                                                dValue={totalAreaUnit} dId={"totalAreaUnit"}
                                                dType={'string'}
                                                onChange={(e) => onChange(e)} value={totalArea.toString()}
                                                id={"totalArea"} type={'number'} name={"expectedYield"}/>
                </FormTwoInputs>
            </>    
            ) : (<></>)}



            

        <div className='flex justify-center pt-8 p-2' >
            <button className="btn btn-wide btn-primar hover:bg-black">Add Yield</button>
        </div>    
        </form>
        </FormCard>
    </>
  )
}

export default YieldForm