import LargeTextInput from '../../components/shared/LargeTextInput'
import FormCard from 'UI/components/shared/FormCard'
import React, {useState, useEffect} from 'react'
import { getConnectionIdComments } from 'features/comments/commentSlice'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { addComment } from 'features/comments/commentSlice'
import {toast} from 'react-toastify'
import { CommentModel } from '@asirisos/types'
import {AppDispatch, RootState} from "../../../app/store";
import {useTranslation} from 'react-i18next'
import SingleComment from './SingleComment'
import DropDownInput from '../../components/shared/LegacyDropDownInput'
import FormTwoInputs from 'UI/components/shared/FormTwoInputs'
import { InputSelection } from 'types/ui'
import { useNavigate } from 'react-router-dom'
import ListOfComments from './ListOfComments'


function CommentForm() {


    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const {taskId} = useParams()

    const {t} = useTranslation('comment');



    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    USE SELECTOR   ///////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const {crops} = useSelector((state : RootState) => state.crop )
    const {accounts} = useSelector((state: RootState) => state.account)
    const {equipments} = useSelector((state: RootState) => state.equipment)
    const {allLiveStock} = useSelector((state : RootState) => state.livestock )
    const {allInventory} = useSelector((state : RootState) => state.inventory ) 
    const {allStorage} = useSelector((state: RootState) => state.storage )
    const {customers} = useSelector((state: RootState) => state.customer )

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  CONNECTION SELECTIONS   ////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
    const cropSelections = crops.map((crop) => {return {name: crop.cropName, id:crop._id}})

    const equipmentSelections = equipments.map((equipment) => {return {name: equipment.name, id:equipment._id}})

    const employeeSelections = accounts.map((account) => {return {name: account.name, id:account._id}})

    const liveStockSelections = allLiveStock.map((livestock) => {return {name: livestock.name, id:livestock._id}})

    const inventorySelections = allInventory.map((inventory) => {return {name: inventory.name, id:inventory._id}})

    const storageSelections = allStorage.map((storage) => {return {name: storage.name, id:storage._id}})

    const customerSelections = customers.map((customer) => {return  new InputSelection(customer.firstName, customer._id)})


    const connectionSelection = [
        new InputSelection('crop', 'crop'),
        new InputSelection('live stock', 'live stock'), 
        new InputSelection('storage', 'storage'),
        new InputSelection('employee', 'employee'),
        new InputSelection('equipment', 'equipment'),
        new InputSelection('inventory', 'inventory'),
        new InputSelection('customer', 'customer')]

    const {comments} = useSelector((state: RootState) => state.comment)


    const [formData, setFormData] = useState({

        // Added 01/19/23 Latest Comment Model
        commentText: '',
        commentItemId: '',
        commentItemCategory: '',
        commenterAccountId: '',
        // commenterAccountObject: '',
        commentConnectionAdded: '',
        commentConnectionId: '',
        commentConnectionCategory: '',
        commentEmployeeConnectionAdded: '',
        commentEmployeeConnectionId: '',
        // commentEmployeeConnectionObject: '',
        crop: '',
        employee: '',
        equipment: '',
        inventory: '',
        storage: '',
        customer: '',
        liveStock: '',
        // cropObject: '',
        // employeeObject: '',
        // equipmentObject: '',
        // inventoryObject: '',
        // storageObject: '',
        // customerObject: '',
        // liveStockObject: '',




        /////////////// Added 01/18/23
        connectionCategory: '',
        // crop: '',
        // employee: '',
        // liveStock: '',
        // equipment: '',
        // inventory: '',
        // storage: '',
        // customer: '',



        //////////////// Previously Added
        taskID: '',
        commentConnectionID: '',
        // commentText: '',
        commentReferenceAdded: '',
        commentRefference: '',
        tagEmployeeAdded: '',
        tagEmployee: '',
        userObject: {
            name: ''
        }
    })

    const {

        ///////////////////////////
         // Added 01/19/23 Latest Comment Model
         commentText,
         commentItemId,
         commentItemCategory,
         commenterAccountId,
        //  commenterAccountObject,
         commentConnectionAdded,
         commentConnectionId,
         commentConnectionCategory,
         commentEmployeeConnectionAdded,
         commentEmployeeConnectionId,
        //  commentEmployeeConnectionObject,
         crop,
         employee,
         equipment,
         inventory,
         storage,
         customer,
         liveStock,
        //  cropObject,
        //  employeeObject,
        //  equipmentObject,
        //  inventoryObject,
        //  storageObject,
        //  customerObject,
        //  liveStockObject,





        /////////////// Added 01/18/23
        connectionCategory,
        // crop,
        // employee,
        // liveStock,
        // equipment,
        // inventory,
        // storage,
        // customer,

        ////////////////////////// Previously Added
        taskID,
        commentConnectionID,
        // commentText,
        commentReferenceAdded,
        commentRefference,
        tagEmployeeAdded,
        userObject,
        } = formData




    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    CONNECTION TOGGLE      ///////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const [connectionAdded, setConnectionAdded] = React.useState('false');

    const onToggleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const toggleValue = e.target.value

        if (toggleValue === 'true') { setConnectionAdded('false') }
        if (toggleValue === 'false') { setConnectionAdded('true') }
        
   }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////   TAG EMPLOYEE TOGGLE      ///////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const [tagEmployee, setTagEmployee] = React.useState('false');

    const onEmployeeTagToggleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const toggleValue = e.target.value

        if (toggleValue === 'true') { setTagEmployee('false') }
        if (toggleValue === 'false') { setTagEmployee('true') }
   }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    ON CHANGE & ON SUBMIT      ///////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }) )
    }

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        const commentData: CommentModel = {
            commentItemId: taskId,
            commentText,
            commentItemCategory: 'task',
            // commenterAccountId,   ----- WILL BE ADDED IN THE BACK END FROM THE ACCOUNT OBJECT PASSED IN FROM THE SERVICE
            // commenterAccountObject,
            commentConnectionAdded: connectionAdded,
            commentConnectionId,
            commentConnectionCategory: connectionCategory,
            commentEmployeeConnectionAdded: tagEmployee,
            commentEmployeeConnectionId,
            // commentEmployeeConnectionObject,
            // crop,
            // employee,
            // equipment,
            // inventory,
            // storage,
            // customer,
            // liveStock,
            // cropObject,
            // employeeObject,
            // equipmentObject,
            // inventoryObject,
            // storageObject,
            // customerObject,
            // liveStockObject,

            
            //////////////////////////////////
            //// Old Selections 01/18/23 ////

            // commentText,
            // commentReferenceAdded,
            // commentRefference,
            // tagEmployeeAdded,
            // userObject,
            // tagEmployee
        }
        console.log('comment', commentData)
        dispatch(addComment(commentData))
            .unwrap()
            .then(() => {
                toast.success(t("cpmment:cpmmentAdded"));
                // navigate("/cpmments")
            })
            .catch(() => {
                toast.error(t("cpmment:cpmmentAddFailed"))
                // navigate("/cpmment-form")
            })
    }





  return (
    <>
        <FormCard title="Add Comment">
            <form onSubmit={onSubmit}>
                <div className='mx-16' >
                    <LargeTextInput onChange={(e) => onChange(e)} value={commentText} id={"commentText"} type={'text'} group={"commentText"} label={"commentText"} name={"commentText"} dataTitle="Comment" />
                </div>




            <div className='pt-5'>
                <label className="relative inline-flex items-center cursor-pointer">
                    <input type="checkbox" onChange={(e) => onEmployeeTagToggleChange(e)} value={tagEmployee} className="sr-only peer"/>
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <h1 className='ml-3 text-slate-500 text-md' >Tag Employee</h1>
                </label>
            </div>    

                {tagEmployee === 'true' ? 
                (<>
                <FormTwoInputs>
                    <DropDownInput onChange={(e) => onChange(e)} selections={employeeSelections} value={commentEmployeeConnectionId} id={"commentEmployeeConnectionId"} type={'text'} group={"commentEmployeeConnectionId"} name={"commentEmployeeConnectionId"} dataTitle="Tag Employee" />         
                </FormTwoInputs>
                </>)

                : (<></>)}


            


            <div className='pt-4 mt-8 pb-3' >
                <label className="relative inline-flex items-center cursor-pointer">
                    <input type="checkbox" onChange={(e) => onToggleChange(e)} value={connectionAdded} className="sr-only peer"/>
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <h1 className='ml-3 text-slate-500 text-md' >Add Reference</h1>
                </label>
                
            {connectionAdded === 'true' ? (
                <>
                    <div className='' >
                        
                    <FormTwoInputs>
                        <DropDownInput onChange={(e) => onChange(e)} selections={connectionSelection} value={connectionCategory} id={"connectionCategory"} type={'text'} group={"connectionCategory"}  name={"connectionCategory"} dataTitle="Category" />
                        {connectionCategory === 'crop' ? (
                            <>
                                <DropDownInput onChange={(e) => onChange(e)} selections={cropSelections} value={commentConnectionId} id={"commentConnectionId"} type={'text'} group={"commentConnectionId"} name={"commentConnectionId"} dataTitle="Crop" />
                            </>) : (<></>) }

                        {connectionCategory === 'live stock' ? (
                            <>
                                <DropDownInput onChange={(e) => onChange(e)} selections={liveStockSelections} value={commentConnectionId} id={"commentConnectionId"} type={'text'} group={"commentConnectionId"}  name={"commentConnectionId"} dataTitle="Live Stock" />
                            </>) : (<></>) }

                        {connectionCategory === 'employee' ? (
                            <>
                                <DropDownInput onChange={(e) => onChange(e)} selections={employeeSelections} value={commentConnectionId} id={"commentConnectionId"} type={'text'} group={"commentConnectionId"}  name={"commentConnectionId"} dataTitle="Employee" />
                            </>) : (<></>) }

                        {connectionCategory === 'equipment' ? (
                            <>
                                <DropDownInput onChange={(e) => onChange(e)} selections={equipmentSelections} value={commentConnectionId} id={"commentConnectionId"} type={'text'} group={"commentConnectionId"}  name={"commentConnectionId"} dataTitle="Equipment" />
                            </>) : (<></>) }

                        {connectionCategory === 'inventory' ? (
                            <>
                                <DropDownInput onChange={(e) => onChange(e)} selections={inventorySelections} value={commentConnectionId} id={"commentConnectionId"} type={'text'} group={"commentConnectionId"}  name={"commentConnectionId"} dataTitle="Inventory" />
                            </>) : (<></>) }

                        {connectionCategory === 'storage' ? (
                            <>
                                <DropDownInput onChange={(e) => onChange(e)} selections={storageSelections} value={commentConnectionId} id={"commentConnectionId"} type={'text'} group={"commentConnectionId"} name={"commentConnectionId"} dataTitle="Storage" />
                            </>) : (<></>) }

                        {connectionCategory === 'customer' ? (
                            <>
                                <DropDownInput onChange={(e) => onChange(e)} selections={customerSelections} value={commentConnectionId} id={"commentConnectionId"} type={'text'} group={"commentConnectionId"} name={"commentConnectionId"} dataTitle="Customer" />
                            </>) : (<></>) }


                    </FormTwoInputs>
                    </div>
                </>) : (<></>) }
            </div>

            <div className='flex justify-center pt-8 p-2' >
                <button className="btn btn-wide btn-primar hover:bg-black">Add Comment</button>
            </div> 
            </form>

        </FormCard>






    {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}
    {/* ///////////////////////////////////    LIST OF ALL COMMENTS    ////////////////////////////////////////// */}
    {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/* <div className='pt-10'>
            <h1>Existing Comments Will be found here</h1>

            {comments.map((comment) => (
                <>
                <SingleComment createdAt={comment.createdAt} commentText={comment.commentText}/>
                </>
            ) )}
        </div> */}


    </>
  )
}

export default CommentForm