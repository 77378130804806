import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getFarms, reset} from '../../../features/farms/farmSlice'
import Spinner from '../../components/Spinner'
import FarmListItem from './FarmListItem'
import {AppDispatch, RootState} from "../../../app/store";
import {Status} from "../../../types/slice";


function FarmList() {
    const {farms, status} = useSelector((state : RootState) => state.farm )
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(getFarms())
        if (status === Status.Success) {
          dispatch(reset())
        }
    },[dispatch] )

    if (status === Status.Pending) {
        return<Spinner/>
    }
  return (
    <>
    <div className="overflow-x-auto">
  <table className="table w-full">
    <thead>
      <tr>
        <th>Farm Name</th>
        <th>Location</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
    {farms.map((farm) => (
            <FarmListItem key={farm._id} farm={farm} />
         ) )}
    </tbody>
  </table>
</div>
    </>
  )
}

export default FarmList