import axios from "axios";
import {AccountModel, InventoryModel} from "@asirisos/types";
import RequestBodyFactory from "../../factories/requestBodyFactory";

const API_URL = '/api/inventory'

const addInventory = async(inventoryData: InventoryModel, account: AccountModel) => {
    const body = new RequestBodyFactory(inventoryData).WithAccount(account).Build()
    return await axios.post(API_URL + '/', body)
}

const getAllInventory = async(account: AccountModel) => {
    return await axios.get(API_URL + '/all/' + account.farm._id + '/' + account.user._id)
}

const getInventory = async(inventoryId: string) => {
    return await axios.get(API_URL + '/' + inventoryId)
}

const updateInventory = async(inventoryData: InventoryModel, account: AccountModel) => {
    const {_id} = inventoryData

    const body = new RequestBodyFactory(inventoryData).WithAccount(account).Build()
    return await axios.put(API_URL + '/' + _id, body)
}

const deleteInventory = async(inventoryId: string[]) => {
    const ids = inventoryId
    const config = {
        data: ids
    }
    return await axios.delete(API_URL+'/'+ inventoryId, config)
}

const inventoryService = {
    addInventory,
    getAllInventory,
    getInventory,
    updateInventory,
    deleteInventory
}

export default inventoryService