export const cities = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Colonia del Sacramento",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Colonia del Sacramento",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Uruguay",
        sov_a3: "URY",
        adm0name: "Uruguay",
        adm0_a3: "URY",
        adm1name: "Colonia",
        iso_a2: "UY",
        note: null,
        latitude: -34.4799990054,
        longitude: -57.8400024734,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 21714,
        pop_min: 21714,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 3443013,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-57.84000247340134, -34.47999900541754],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Trinidad",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Trinidad",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Uruguay",
        sov_a3: "URY",
        adm0name: "Uruguay",
        adm0_a3: "URY",
        adm1name: "Flores",
        iso_a2: "UY",
        note: null,
        latitude: -33.5439989374,
        longitude: -56.9009965602,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 21093,
        pop_min: 21093,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 3439749,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-56.90099656015872, -33.5439989373607],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Fray Bentos",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Fray Bentos",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Uruguay",
        sov_a3: "URY",
        adm0name: "Uruguay",
        adm0_a3: "URY",
        adm1name: "Río Negro",
        iso_a2: "UY",
        note: null,
        latitude: -33.1389990288,
        longitude: -58.3039974719,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 23279,
        pop_min: 23279,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 3442568,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-58.3039974719095, -33.1389990288435],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Canelones",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Canelones",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Uruguay",
        sov_a3: "URY",
        adm0name: "Uruguay",
        adm0_a3: "URY",
        adm1name: "Canelones",
        iso_a2: "UY",
        note: null,
        latitude: -34.5380040067,
        longitude: -56.2840014932,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 19698,
        pop_min: 19698,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 3443413,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-56.28400149324307, -34.538004006675465],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Florida",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Florida",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Uruguay",
        sov_a3: "URY",
        adm0name: "Uruguay",
        adm0_a3: "URY",
        adm1name: "Florida",
        iso_a2: "UY",
        note: null,
        latitude: -34.0990020052,
        longitude: -56.214998448,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 32234,
        pop_min: 32234,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 3442585,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-56.21499844799416, -34.09900200521719],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Bassar",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Bassar",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Togo",
        sov_a3: "TGO",
        adm0name: "Togo",
        adm0_a3: "TGO",
        adm1name: "Kara",
        iso_a2: "TG",
        note: null,
        latitude: 9.26100006832,
        longitude: 0.78900357378,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 61845,
        pop_min: 61845,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 2367568,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [0.789003573778132, 9.26100006831723],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Sotouboua",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Sotouboua",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Togo",
        sov_a3: "TGO",
        adm0name: "Togo",
        adm0_a3: "TGO",
        adm1name: "Centre",
        iso_a2: "TG",
        note: null,
        latitude: 8.55700213306,
        longitude: 0.98499646233,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 21054,
        pop_min: 21054,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 2364079,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [0.98499646233131, 8.557002133057097],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Medenine",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Medemine",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Tunisia",
        sov_a3: "TUN",
        adm0name: "Tunisia",
        adm0_a3: "TUN",
        adm1name: "Médenine",
        iso_a2: "TN",
        note: null,
        latitude: 33.3999990025,
        longitude: 10.4166995576,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 61705,
        pop_min: 61705,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [10.4166995575967, 33.399999002467325],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Kebili",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Kebili",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Tunisia",
        sov_a3: "TUN",
        adm0name: "Tunisia",
        adm0_a3: "TUN",
        adm1name: "Kebili",
        iso_a2: "TN",
        note: null,
        latitude: 33.6899970349,
        longitude: 8.9710025379,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 19875,
        pop_min: 19875,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 2468018,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [8.9710025379012, 33.689997034920566],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Tataouine",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Tataouine",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Tunisia",
        sov_a3: "TUN",
        adm0name: "Tunisia",
        adm0_a3: "TUN",
        adm1name: "Tataouine",
        iso_a2: "TN",
        note: null,
        latitude: 33.0000031543,
        longitude: 10.4667035894,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 62577,
        pop_min: 62577,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 2464701,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [10.466703589415545, 33.00000315432084],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "L'Ariana",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "L'Ariana",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Tunisia",
        sov_a3: "TUN",
        adm0name: "Tunisia",
        adm0_a3: "TUN",
        adm1name: "Manubah",
        iso_a2: "TN",
        note: null,
        latitude: 36.8666731534,
        longitude: 10.1999975475,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 97687,
        pop_min: 97687,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [10.199997547479256, 36.866673153444935],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Jendouba",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Jendouba",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Tunisia",
        sov_a3: "TUN",
        adm0name: "Tunisia",
        adm0_a3: "TUN",
        adm1name: "Jendouba",
        iso_a2: "TN",
        note: null,
        latitude: 36.5000040594,
        longitude: 8.74999861458,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 51408,
        pop_min: 51408,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 2470088,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [8.749998614579113, 36.500004059426146],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Kasserine",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Kasserine",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Tunisia",
        sov_a3: "TUN",
        adm0name: "Tunisia",
        adm0_a3: "TUN",
        adm1name: "Kassérine",
        iso_a2: "TN",
        note: null,
        latitude: 35.2167031048,
        longitude: 8.71669850332,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 76243,
        pop_min: 76243,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [8.716698503320458, 35.216703104824205],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Sdid Bouzid",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Sdid Bouzid",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Tunisia",
        sov_a3: "TUN",
        adm0name: "Tunisia",
        adm0_a3: "TUN",
        adm1name: "Sidi Bou Zid",
        iso_a2: "TN",
        note: null,
        latitude: 35.0166960791,
        longitude: 9.50000448226,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 42098,
        pop_min: 42098,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 2465840,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [9.500004482255633, 35.01669607914971],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Siliana",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Siliana",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Tunisia",
        sov_a3: "TUN",
        adm0name: "Tunisia",
        adm0_a3: "TUN",
        adm1name: "Siliana",
        iso_a2: "TN",
        note: null,
        latitude: 36.0833041252,
        longitude: 9.38330159966,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 26960,
        pop_min: 26960,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 2465030,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [9.383301599658637, 36.083304125235486],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Mahdia",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Mahdia",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Tunisia",
        sov_a3: "TUN",
        adm0name: "Tunisia",
        adm0_a3: "TUN",
        adm1name: "Mahdia",
        iso_a2: "TN",
        note: null,
        latitude: 35.4839130445,
        longitude: 11.0408766153,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 45977,
        pop_min: 45977,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [11.040876615321963, 35.483913044502685],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Monastir",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Monasir",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Tunisia",
        sov_a3: "TUN",
        adm0name: "Tunisia",
        adm0_a3: "TUN",
        adm1name: "Monastir",
        iso_a2: "TN",
        note: null,
        latitude: 35.7307021352,
        longitude: 10.7672945619,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 71546,
        pop_min: 41400,
        pop_other: 0,
        rank_max: 8,
        rank_min: 7,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [10.767294561862002, 35.730702135151205],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Zaghouan",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Zaghouan",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Tunisia",
        sov_a3: "TUN",
        adm0name: "Tunisia",
        adm0_a3: "TUN",
        adm1name: "Zaghouan",
        iso_a2: "TN",
        note: null,
        latitude: 36.3999961613,
        longitude: 10.1469966066,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 16911,
        pop_min: 16911,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 2464041,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [10.146996606592154, 36.39999616127193],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Tay Ninh",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Tay Ninh",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Vietnam",
        sov_a3: "VNM",
        adm0name: "Vietnam",
        adm0_a3: "VNM",
        adm1name: "Tây Ninh",
        iso_a2: "VN",
        note: null,
        latitude: 11.3229991094,
        longitude: 106.146999683,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 126370,
        pop_min: 126370,
        pop_other: 0,
        rank_max: 9,
        rank_min: 9,
        geonameid: 1566559,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [106.14699968305416, 11.322999109363707],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Luan Chau",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Luan Chau",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Vietnam",
        sov_a3: "VNM",
        adm0name: "Vietnam",
        adm0_a3: "VNM",
        adm1name: "Ði?n Biên",
        iso_a2: "VN",
        note: null,
        latitude: 21.7400039919,
        longitude: 103.34300468,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 7335,
        pop_min: 7335,
        pop_other: 0,
        rank_max: 5,
        rank_min: 5,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [103.34300467988777, 21.740003991855076],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Bac Kan",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Bac Kan",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Vietnam",
        sov_a3: "VNM",
        adm0name: "Vietnam",
        adm0_a3: "VNM",
        adm1name: "Ðông B?c",
        iso_a2: "VN",
        note: null,
        latitude: 22.1333333,
        longitude: 105.8333333,
        changed: 4,
        namediff: 0,
        diffnote:
          "Added missing admin-1 capital round B. Population from GeoNames.",
        pop_max: 29227,
        pop_min: 29227,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 1591538,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [105.8333333000001, 22.133333299999947],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Lang Son",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Lang Son",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Vietnam",
        sov_a3: "VNM",
        adm0name: "Vietnam",
        adm0_a3: "VNM",
        adm1name: "L?ng Son",
        iso_a2: "VN",
        note: null,
        latitude: 21.845997103,
        longitude: 106.757001576,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 148000,
        pop_min: 148000,
        pop_other: 0,
        rank_max: 9,
        rank_min: 9,
        geonameid: 1576633,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [106.75700157601534, 21.84599710299534],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Son La",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Son La",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Vietnam",
        sov_a3: "VNM",
        adm0name: "Vietnam",
        adm0_a3: "VNM",
        adm1name: "Son La",
        iso_a2: "VN",
        note: null,
        latitude: 21.3280021387,
        longitude: 103.910004651,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 19054,
        pop_min: 19054,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 1567681,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [103.91000465110193, 21.328002138749298],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Tuyen Quang",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Tuyen Quang",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Vietnam",
        sov_a3: "VNM",
        adm0name: "Vietnam",
        adm0_a3: "VNM",
        adm1name: "Tuyên Quang",
        iso_a2: "VN",
        note: null,
        latitude: 21.8179980953,
        longitude: 105.210999615,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 36430,
        pop_min: 36430,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 1563287,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [105.21099961499738, 21.817998095275925],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Yen Bai",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Yen Bai",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Vietnam",
        sov_a3: "VNM",
        adm0name: "Vietnam",
        adm0_a3: "VNM",
        adm1name: "Yên Bái",
        iso_a2: "VN",
        note: null,
        latitude: 21.7050030395,
        longitude: 104.875002586,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 96540,
        pop_min: 96540,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 1560349,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [104.87500258624527, 21.70500303954708],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Hai Duong",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Hai Duong",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Vietnam",
        sov_a3: "VNM",
        adm0name: "Vietnam",
        adm0_a3: "VNM",
        adm1name: "H?i Duong",
        iso_a2: "VN",
        note: null,
        latitude: 20.9420010782,
        longitude: 106.331004604,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 58030,
        pop_min: 58030,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 1581326,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [106.33100460436708, 20.94200107817835],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Thai Binh",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Thai Binh",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Vietnam",
        sov_a3: "VNM",
        adm0name: "Vietnam",
        adm0_a3: "VNM",
        adm1name: "Thái Bình",
        iso_a2: "VN",
        note: null,
        latitude: 20.4503041151,
        longitude: 106.333029628,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 210000,
        pop_min: 210000,
        pop_other: 0,
        rank_max: 10,
        rank_min: 10,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [106.33302962790475, 20.45030411511891],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Tuy Hoa",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Tuy Hoa",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Vietnam",
        sov_a3: "VNM",
        adm0name: "Vietnam",
        adm0_a3: "VNM",
        adm1name: "Phú Yên",
        iso_a2: "VN",
        note: null,
        latitude: 13.0820040243,
        longitude: 109.315998684,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 69596,
        pop_min: 69596,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 1563281,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [109.31599868366044, 13.082004024264734],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Thu Dau Mot",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Thu Dau Mot",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Vietnam",
        sov_a3: "VNM",
        adm0name: "Vietnam",
        adm0_a3: "VNM",
        adm1name: "Bình Duong",
        iso_a2: "VN",
        note: null,
        latitude: 10.9690740774,
        longitude: 106.652745547,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 244277,
        pop_min: 244277,
        pop_other: 0,
        rank_max: 10,
        rank_min: 10,
        geonameid: 1565022,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [106.65274554684555, 10.969074077424182],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Dong Ha",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Dong Ha",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Vietnam",
        sov_a3: "VNM",
        adm0name: "Vietnam",
        adm0_a3: "VNM",
        adm1name: "Qu?ng Tr?",
        iso_a2: "VN",
        note: null,
        latitude: 16.8499981032,
        longitude: 107.133300661,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 17662,
        pop_min: 17662,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 1582926,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [107.13330066061451, 16.849998103207497],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Cao Lanh",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Cao Lanh",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Vietnam",
        sov_a3: "VNM",
        adm0name: "Vietnam",
        adm0_a3: "VNM",
        adm1name: "Ð?ng Tháp",
        iso_a2: "VN",
        note: null,
        latitude: 10.4670001305,
        longitude: 105.635997562,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 149837,
        pop_min: 149837,
        pop_other: 0,
        rank_max: 9,
        rank_min: 9,
        geonameid: 1586151,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [105.63599756179525, 10.46700013054675],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Truc Giang",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Truc Giang",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Vietnam",
        sov_a3: "VNM",
        adm0name: "Vietnam",
        adm0_a3: "VNM",
        adm1name: "B?n Tre",
        iso_a2: "VN",
        note: null,
        latitude: 10.2349979978,
        longitude: 106.374996615,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 59442,
        pop_min: 59442,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 1587976,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [106.37499661484685, 10.234997997750156],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Tra Vinh",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Tra Vinh",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Vietnam",
        sov_a3: "VNM",
        adm0name: "Vietnam",
        adm0_a3: "VNM",
        adm1name: "Trà Vinh",
        iso_a2: "VN",
        note: null,
        latitude: 9.93400208679,
        longitude: 106.334001679,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 131360,
        pop_min: 131360,
        pop_other: 0,
        rank_max: 9,
        rank_min: 9,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [106.33400167891898, 9.93400208678969],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Vinh Long",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Vinh Long",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Vietnam",
        sov_a3: "VNM",
        adm0name: "Vietnam",
        adm0_a3: "VNM",
        adm1name: "Vinh Long",
        iso_a2: "VN",
        note: null,
        latitude: 10.256003997,
        longitude: 105.964002557,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 103314,
        pop_min: 103314,
        pop_other: 0,
        rank_max: 9,
        rank_min: 9,
        geonameid: 1562693,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [105.9640025572258, 10.25600399699897],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Cao Bang",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Cao Bang",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Vietnam",
        sov_a3: "VNM",
        adm0name: "Vietnam",
        adm0_a3: "VNM",
        adm1name: "Cao B?ng",
        iso_a2: "VN",
        note: null,
        latitude: 22.663998055,
        longitude: 106.268004644,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 41112,
        pop_min: 41112,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 1586185,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [106.26800464433961, 22.66399805495257],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Yoro",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Yoro",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Honduras",
        sov_a3: "HND",
        adm0name: "Honduras",
        adm0_a3: "HND",
        adm1name: "Yoro",
        iso_a2: "HN",
        note: null,
        latitude: 15.059997106,
        longitude: -87.2900005384,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 15774,
        pop_min: 15774,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 3600195,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-87.29000053844396, 15.059997106034928],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "La Esperanza",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "La Esperanza",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Honduras",
        sov_a3: "HND",
        adm0name: "Honduras",
        adm0_a3: "HND",
        adm1name: "Intibucá",
        iso_a2: "HN",
        note: null,
        latitude: 14.3330040579,
        longitude: -88.1666995372,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 5318,
        pop_min: 5318,
        pop_other: 0,
        rank_max: 5,
        rank_min: 5,
        geonameid: 3607966,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-88.16669953722379, 14.33300405794239],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "La Paz",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "La Paz",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Honduras",
        sov_a3: "HND",
        adm0name: "Honduras",
        adm0_a3: "HND",
        adm1name: "La Paz",
        iso_a2: "HN",
        note: null,
        latitude: 14.3199990281,
        longitude: -87.6840025685,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 17555,
        pop_min: 17555,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 3607254,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-87.68400256845393, 14.319999028132543],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Santa Barbara",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Santa Barbara",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Honduras",
        sov_a3: "HND",
        adm0name: "Honduras",
        adm0_a3: "HND",
        adm1name: "Santa Bárbara",
        iso_a2: "HN",
        note: null,
        latitude: 14.9190030426,
        longitude: -88.2359996256,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 15119,
        pop_min: 15119,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 3601691,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-88.235999625641, 14.91900304258661],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Gracias",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Gracias",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Honduras",
        sov_a3: "HND",
        adm0name: "Honduras",
        adm0_a3: "HND",
        adm1name: "Lempira",
        iso_a2: "HN",
        note: null,
        latitude: 14.583303057,
        longitude: -88.5833005122,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 7909,
        pop_min: 7909,
        pop_other: 0,
        rank_max: 5,
        rank_min: 5,
        geonameid: 3609586,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-88.58330051218616, 14.583303057002922],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Nueva Ocotepeque",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Nueva Ocotepeque",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Honduras",
        sov_a3: "HND",
        adm0name: "Honduras",
        adm0_a3: "HND",
        adm1name: "Ocotepeque",
        iso_a2: "HN",
        note: null,
        latitude: 14.4369991194,
        longitude: -89.1819985474,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 8780,
        pop_min: 8780,
        pop_other: 0,
        rank_max: 5,
        rank_min: 5,
        geonameid: 3604384,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-89.18199854735434, 14.43699911938154],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Yuscaran",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Yuscaran",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Honduras",
        sov_a3: "HND",
        adm0name: "Honduras",
        adm0_a3: "HND",
        adm1name: "El Paraíso",
        iso_a2: "HN",
        note: null,
        latitude: 13.9439969867,
        longitude: -86.850998537,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 2371,
        pop_min: 2371,
        pop_other: 0,
        rank_max: 4,
        rank_min: 4,
        geonameid: 3600165,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-86.8509985369858, 13.943996986701904],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Jeremie",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Jeremie",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Haiti",
        sov_a3: "HTI",
        adm0name: "Haiti",
        adm0_a3: "HTI",
        adm1name: "Grand'Anse",
        iso_a2: "HT",
        note: null,
        latitude: 18.6339347272,
        longitude: -74.1184252634,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 30917,
        pop_min: 30917,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 3723593,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-74.11842526340854, 18.63393472721964],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Port-De-Paix",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Port-De-Paix",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Haiti",
        sov_a3: "HTI",
        adm0name: "Haiti",
        adm0_a3: "HTI",
        adm1name: "Nord-Ouest",
        iso_a2: "HT",
        note: null,
        latitude: 19.9317600761,
        longitude: -72.8294845231,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 34657,
        pop_min: 34657,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 3740016,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-72.8294845231228, 19.93176007609145],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Hinche",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Hinche",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Haiti",
        sov_a3: "HTI",
        adm0name: "Haiti",
        adm0_a3: "HTI",
        adm1name: "Centre",
        iso_a2: "HT",
        note: null,
        latitude: 19.1430000867,
        longitude: -72.0039956011,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 18590,
        pop_min: 18590,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 3723841,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-72.00399560107024, 19.1430000867162],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Fort-Liberte",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Fort-Liberte",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Haiti",
        sov_a3: "HTI",
        adm0name: "Haiti",
        adm0_a3: "HTI",
        adm1name: "Nord-Est",
        iso_a2: "HT",
        note: null,
        latitude: 19.6655670335,
        longitude: -71.8448395405,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 11465,
        pop_min: 11465,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 3725170,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-71.8448395405403, 19.66556703349886],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Jacmel",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Jacmel",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Haiti",
        sov_a3: "HTI",
        adm0name: "Haiti",
        adm0_a3: "HTI",
        adm1name: "Sud-Est",
        iso_a2: "HT",
        note: null,
        latitude: 18.2349990264,
        longitude: -72.5370025809,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 33563,
        pop_min: 33563,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 3723779,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-72.53700258094494, 18.234999026379057],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Kirklareli",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Kirklareli",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Turkey",
        sov_a3: "TUR",
        adm0name: "Turkey",
        adm0_a3: "TUR",
        adm1name: "Kirklareli",
        iso_a2: "TR",
        note: null,
        latitude: 41.7429991699,
        longitude: 27.2259996157,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 58223,
        pop_min: 58223,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 743166,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [27.225999615690228, 41.74299916992038],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Bilecik",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Bilecik",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Turkey",
        sov_a3: "TUR",
        adm0name: "Turkey",
        adm0_a3: "TUR",
        adm1name: "Bilecik",
        iso_a2: "TR",
        note: null,
        latitude: 40.1499990223,
        longitude: 29.9829965977,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 40285,
        pop_min: 40285,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 750598,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [29.982996597707256, 40.149999022269185],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Sakarya",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Sakarya",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Turkey",
        sov_a3: "TUR",
        adm0name: "Turkey",
        adm0_a3: "TUR",
        adm1name: "Sakarya",
        iso_a2: "TR",
        note: null,
        latitude: 40.7666611361,
        longitude: 30.4000025112,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 286787,
        pop_min: 286787,
        pop_other: 0,
        rank_max: 10,
        rank_min: 10,
        geonameid: 752850,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [30.400002511183743, 40.76666113606262],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Kastamonu",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Kastamonu",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Turkey",
        sov_a3: "TUR",
        adm0name: "Turkey",
        adm0_a3: "TUR",
        adm1name: "Kastamonu",
        iso_a2: "TR",
        note: null,
        latitude: 41.3890021526,
        longitude: 33.7830034905,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 70402,
        pop_min: 70402,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 743882,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [33.78300349052358, 41.389002152589],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Burdur",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Burdur",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Turkey",
        sov_a3: "TUR",
        adm0name: "Turkey",
        adm0_a3: "TUR",
        adm1name: "Burdur",
        iso_a2: "TR",
        note: null,
        latitude: 37.7166601109,
        longitude: 30.2833355385,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 66158,
        pop_min: 66158,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 320392,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [30.28333553854071, 37.71666011092282],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Kirsehir",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Kirsehir",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Turkey",
        sov_a3: "TUR",
        adm0name: "Turkey",
        adm0_a3: "TUR",
        adm1name: "Kirsehir",
        iso_a2: "TR",
        note: null,
        latitude: 39.1419991654,
        longitude: 34.1710026008,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 94336,
        pop_min: 94336,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 307515,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [34.17100260079587, 39.14199916537905],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Nevsehir",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Nevsehir",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Turkey",
        sov_a3: "TUR",
        adm0name: "Turkey",
        adm0_a3: "TUR",
        adm1name: "Nevsehir",
        iso_a2: "TR",
        note: null,
        latitude: 38.6240040356,
        longitude: 34.7239985173,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 75527,
        pop_min: 75527,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 303831,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [34.72399851734997, 38.62400403564931],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Antioch",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Antioch",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Turkey",
        sov_a3: "TUR",
        adm0name: "Turkey",
        adm0_a3: "TUR",
        adm1name: "Hatay",
        iso_a2: "TR",
        note: null,
        latitude: 36.2333340929,
        longitude: 36.1166765584,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 154803,
        pop_min: 154803,
        pop_other: 0,
        rank_max: 9,
        rank_min: 9,
        geonameid: 323779,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [36.116676558439394, 36.233334092927635],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Giresun",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Giresun",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Turkey",
        sov_a3: "TUR",
        adm0name: "Turkey",
        adm0_a3: "TUR",
        adm1name: "Giresun",
        iso_a2: "TR",
        note: null,
        latitude: 40.9130011491,
        longitude: 38.3900045207,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 98864,
        pop_min: 98864,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 746881,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [38.390004520672335, 40.913001149121726],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Sinop",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Sinop",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Turkey",
        sov_a3: "TUR",
        adm0name: "Turkey",
        adm0_a3: "TUR",
        adm1name: "Sinop",
        iso_a2: "TR",
        note: null,
        latitude: 42.0229980177,
        longitude: 35.1530014997,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 34834,
        pop_min: 34834,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 739600,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [35.15300149966771, 42.02299801774967],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Tokat",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Tokat",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Turkey",
        sov_a3: "TUR",
        adm0name: "Turkey",
        adm0_a3: "TUR",
        adm1name: "Tokat",
        iso_a2: "TR",
        note: null,
        latitude: 40.3059961652,
        longitude: 36.5630045215,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 129702,
        pop_min: 129702,
        pop_other: 0,
        rank_max: 9,
        rank_min: 9,
        geonameid: 738743,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [36.5630045214906, 40.305996165228464],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Artvin",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Coruh",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Turkey",
        sov_a3: "TUR",
        adm0name: "Turkey",
        adm0_a3: "TUR",
        adm1name: "Artvin",
        iso_a2: "TR",
        note: null,
        latitude: 41.1830011433,
        longitude: 41.8179965353,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 32272,
        pop_min: 23527,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [41.8179965353429, 41.18300114329429],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Bingol",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Bingol",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Turkey",
        sov_a3: "TUR",
        adm0name: "Turkey",
        adm0_a3: "TUR",
        adm1name: "Bingöl",
        iso_a2: "TR",
        note: null,
        latitude: 38.8850040355,
        longitude: 40.498002558,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 80568,
        pop_min: 80568,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 321082,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [40.49800255801756, 38.88500403553252],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Bitlis",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Bitlis",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Turkey",
        sov_a3: "TUR",
        adm0name: "Turkey",
        adm0_a3: "TUR",
        adm1name: "Bitlis",
        iso_a2: "TR",
        note: null,
        latitude: 38.394000118,
        longitude: 42.1229976473,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 62811,
        pop_min: 43109,
        pop_other: 0,
        rank_max: 8,
        rank_min: 7,
        geonameid: 321025,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [42.1229976473071, 38.394000118037695],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Kalangala",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Kalangala",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Uganda",
        sov_a3: "UGA",
        adm0name: "Uganda",
        adm0_a3: "UGA",
        adm1name: "Kalangala",
        iso_a2: "UG",
        note: null,
        latitude: -0.3088889,
        longitude: 32.225,
        changed: 4,
        namediff: 0,
        diffnote:
          "Added missing admin-1 capital round B. Population from GeoNames.",
        pop_max: 5200,
        pop_min: 5200,
        pop_other: 0,
        rank_max: 5,
        rank_min: 5,
        geonameid: 232571,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [32.225000000000136, -0.308888899999886],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Cankiri",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Cankiri",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Turkey",
        sov_a3: "TUR",
        adm0name: "Turkey",
        adm0_a3: "TUR",
        adm1name: "Çankiri",
        iso_a2: "TR",
        note: null,
        latitude: 40.6070010123,
        longitude: 33.6210035933,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 71379,
        pop_min: 71379,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 749748,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [33.621003593310206, 40.60700101230674],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Nigde",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Nigde",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Turkey",
        sov_a3: "TUR",
        adm0name: "Turkey",
        adm0_a3: "TUR",
        adm1name: "Nigde",
        iso_a2: "TR",
        note: null,
        latitude: 37.9760041158,
        longitude: 34.6940016254,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 91039,
        pop_min: 91039,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 303827,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [34.694001625412795, 37.97600411582823],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Yozgat",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Yozgat",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Turkey",
        sov_a3: "TUR",
        adm0name: "Turkey",
        adm0_a3: "TUR",
        adm1name: "Yozgat",
        iso_a2: "TR",
        note: null,
        latitude: 39.8179980929,
        longitude: 34.8149974851,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 87881,
        pop_min: 87881,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 296562,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [34.81499748509685, 39.817998092919765],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Gumushane",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Gumushane",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Turkey",
        sov_a3: "TUR",
        adm0name: "Turkey",
        adm0_a3: "TUR",
        adm1name: "Gümüshane",
        iso_a2: "TR",
        note: null,
        latitude: 40.4640001285,
        longitude: 39.4839996159,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 32250,
        pop_min: 32250,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 746425,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [39.48399961590593, 40.46400012852314],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Siirt",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Siirt",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Turkey",
        sov_a3: "TUR",
        adm0name: "Turkey",
        adm0_a3: "TUR",
        adm1name: "Siirt",
        iso_a2: "TR",
        note: null,
        latitude: 37.9440000726,
        longitude: 41.9329985769,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 114034,
        pop_min: 114034,
        pop_other: 0,
        rank_max: 9,
        rank_min: 9,
        geonameid: 300822,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [41.9329985768905, 37.944000072588665],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Tunceli",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Tunceli",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Turkey",
        sov_a3: "TUR",
        adm0name: "Turkey",
        adm0_a3: "TUR",
        adm1name: "Tunceli",
        iso_a2: "TR",
        note: null,
        latitude: 39.1167000236,
        longitude: 39.5333015006,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 29062,
        pop_min: 29062,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 298846,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [39.53330150055899, 39.11670002355953],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Kumi",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Kumi",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Uganda",
        sov_a3: "UGA",
        adm0name: "Uganda",
        adm0_a3: "UGA",
        adm1name: "Kumi",
        iso_a2: "UG",
        note: null,
        latitude: 1.4608333,
        longitude: 33.9361111,
        changed: 4,
        namediff: 0,
        diffnote:
          "Added missing admin-1 capital round B. Population from GeoNames.",
        pop_max: 13000,
        pop_min: 13000,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 230584,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [33.9361111, 1.460833300000104],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Kaberamaido",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Kaberamaido",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Uganda",
        sov_a3: "UGA",
        adm0name: "Uganda",
        adm0_a3: "UGA",
        adm1name: "Kaberamaido",
        iso_a2: "UG",
        note: null,
        latitude: 1.7388889,
        longitude: 33.1594444,
        changed: 4,
        namediff: 0,
        diffnote:
          "Added missing admin-1 capital round B. Population from GeoNames.",
        pop_max: 3400,
        pop_min: 3400,
        pop_other: 0,
        rank_max: 4,
        rank_min: 4,
        geonameid: 233019,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [33.15944439999993, 1.738888900000063],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Kayunga",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Kayunga",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Uganda",
        sov_a3: "UGA",
        adm0name: "Uganda",
        adm0_a3: "UGA",
        adm1name: "Kayunga",
        iso_a2: "UG",
        note: null,
        latitude: 0.7025,
        longitude: 32.8886111,
        changed: 4,
        namediff: 0,
        diffnote:
          "Added missing admin-1 capital round B. Population from GeoNames.",
        pop_max: 21704,
        pop_min: 21704,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 231696,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [32.88861110000005, 0.7025],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Iganga",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Iganga",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Uganda",
        sov_a3: "UGA",
        adm0name: "Uganda",
        adm0_a3: "UGA",
        adm1name: "Iganga",
        iso_a2: "UG",
        note: null,
        latitude: 0.6091667,
        longitude: 33.4686111,
        changed: 4,
        namediff: 0,
        diffnote:
          "Added missing admin-1 capital round B. Population from GeoNames.",
        pop_max: 45024,
        pop_min: 45024,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 233275,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [33.46861110000009, 0.60916670000006],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Kamuli",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Kamuli",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Uganda",
        sov_a3: "UGA",
        adm0name: "Uganda",
        adm0_a3: "UGA",
        adm1name: "Kamuli",
        iso_a2: "UG",
        note: null,
        latitude: 0.9472222,
        longitude: 33.1197222,
        changed: 4,
        namediff: 0,
        diffnote:
          "Added missing admin-1 capital round B. Population from GeoNames.",
        pop_max: 12764,
        pop_min: 12764,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 232397,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [33.119722200000126, 0.947222200000056],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Pallisa",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Pallisa",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Uganda",
        sov_a3: "UGA",
        adm0name: "Uganda",
        adm0_a3: "UGA",
        adm1name: "Pallisa",
        iso_a2: "UG",
        note: null,
        latitude: 1.145,
        longitude: 33.7094444,
        changed: 4,
        namediff: 0,
        diffnote:
          "Added missing admin-1 capital round B. Population from GeoNames.",
        pop_max: 30745,
        pop_min: 30745,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 226823,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [33.70944440000011, 1.145],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Mpigi",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Mpigi",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Uganda",
        sov_a3: "UGA",
        adm0name: "Uganda",
        adm0_a3: "UGA",
        adm1name: "Mpigi",
        iso_a2: "UG",
        note: null,
        latitude: 0.225,
        longitude: 32.3136111,
        changed: 4,
        namediff: 0,
        diffnote:
          "Added missing admin-1 capital round B. Population from GeoNames.",
        pop_max: 11082,
        pop_min: 11082,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 229024,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [32.31361110000012, 0.225000000000136],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Adjumani",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Adjumani",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Uganda",
        sov_a3: "UGA",
        adm0name: "Uganda",
        adm0_a3: "UGA",
        adm1name: "Adjumani",
        iso_a2: "UG",
        note: null,
        latitude: 3.3613889,
        longitude: 31.8097222,
        changed: 4,
        namediff: 0,
        diffnote:
          "Added missing admin-1 capital round B. Population from GeoNames.",
        pop_max: 34700,
        pop_min: 34700,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 235489,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [31.809722199999953, 3.361388900000065],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Nebbi",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Nebbi",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Uganda",
        sov_a3: "UGA",
        adm0name: "Uganda",
        adm0_a3: "UGA",
        adm1name: "Nebbi",
        iso_a2: "UG",
        note: null,
        latitude: 2.4758333,
        longitude: 31.1025,
        changed: 4,
        namediff: 0,
        diffnote:
          "Added missing admin-1 capital round B. Population from GeoNames.",
        pop_max: 30354,
        pop_min: 30354,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 227904,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [31.1025, 2.475833300000033],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Kiboga",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Kiboga",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Uganda",
        sov_a3: "UGA",
        adm0name: "Uganda",
        adm0_a3: "UGA",
        adm1name: "Kiboga",
        iso_a2: "UG",
        note: null,
        latitude: 0.9161111,
        longitude: 31.7741667,
        changed: 4,
        namediff: 0,
        diffnote:
          "Added missing admin-1 capital round B. Population from GeoNames.",
        pop_max: 14512,
        pop_min: 14512,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 231550,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [31.77416670000008, 0.916111100000023],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Nakasongola",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Nakasongola",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Uganda",
        sov_a3: "UGA",
        adm0name: "Uganda",
        adm0_a3: "UGA",
        adm1name: "Nakasongola",
        iso_a2: "UG",
        note: null,
        latitude: 1.3088889,
        longitude: 32.4563889,
        changed: 4,
        namediff: 0,
        diffnote:
          "Added missing admin-1 capital round B. Population from GeoNames.",
        pop_max: 6921,
        pop_min: 6921,
        pop_other: 0,
        rank_max: 5,
        rank_min: 5,
        geonameid: 228418,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [32.45638889999992, 1.3088889],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Bombo",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Bombo",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Uganda",
        sov_a3: "UGA",
        adm0name: "Uganda",
        adm0_a3: "UGA",
        adm1name: "Bamunanika",
        iso_a2: "UG",
        note: null,
        latitude: 0.58329910562,
        longitude: 32.5332995249,
        changed: 4,
        namediff: 1,
        diffnote:
          "Added missing admin-1 capital. Changed feature to Admin-0 region capital.",
        pop_max: 75000,
        pop_min: 21000,
        pop_other: 0,
        rank_max: 8,
        rank_min: 7,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 region capital",
      },
      geometry: {
        type: "Point",
        coordinates: [32.533299524864844, 0.583299105614628],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Masindi",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Masindi",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Uganda",
        sov_a3: "UGA",
        adm0name: "Uganda",
        adm0_a3: "UGA",
        adm1name: "Masindi",
        iso_a2: "UG",
        note: null,
        latitude: 1.6744444,
        longitude: 31.715,
        changed: 4,
        namediff: 0,
        diffnote:
          "Added missing admin-1 capital round B. Population from GeoNames.",
        pop_max: 31486,
        pop_min: 31486,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 229362,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [31.715, 1.674444400000141],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Fort Portal",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Fort Portal",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Uganda",
        sov_a3: "UGA",
        adm0name: "Uganda",
        adm0_a3: "UGA",
        adm1name: "Kabarole",
        iso_a2: "UG",
        note: null,
        latitude: 0.67100412113,
        longitude: 30.275001616,
        changed: 4,
        namediff: 1,
        diffnote:
          "Added missing admin-1 capital. Changed feature to Admin-0 region capital. Population from GeoNames.",
        pop_max: 42670,
        pop_min: 42670,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 233476,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 region capital",
      },
      geometry: {
        type: "Point",
        coordinates: [30.27500161597942, 0.671004121125236],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Kibale",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Kibale",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Uganda",
        sov_a3: "UGA",
        adm0name: "Uganda",
        adm0_a3: "UGA",
        adm1name: "Kibale",
        iso_a2: "UG",
        note: null,
        latitude: 0.8,
        longitude: 31.0666667,
        changed: 4,
        namediff: 0,
        diffnote:
          "Added missing admin-1 capital round B. Population from GeoNames.",
        pop_max: 5200,
        pop_min: 5200,
        pop_other: 0,
        rank_max: 5,
        rank_min: 5,
        geonameid: 231617,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [31.06666670000004, 0.8],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Sironko",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Sironko",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Uganda",
        sov_a3: "UGA",
        adm0name: "Uganda",
        adm0_a3: "UGA",
        adm1name: "Budadiri",
        iso_a2: "UG",
        note: null,
        latitude: 1.25,
        longitude: 34.3,
        changed: 4,
        namediff: 0,
        diffnote:
          "Added missing admin-1 capital round B. Population from GeoNames.",
        pop_max: 14100,
        pop_min: 14100,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 448228,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [34.300000000000125, 1.25],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Busia",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Busia",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Uganda",
        sov_a3: "UGA",
        adm0name: "Uganda",
        adm0_a3: "UGA",
        adm1name: "Busia",
        iso_a2: "UG",
        note: null,
        latitude: 0.4544444,
        longitude: 34.0758333,
        changed: 4,
        namediff: 0,
        diffnote:
          "Added missing admin-1 capital round B. Population from GeoNames.",
        pop_max: 47100,
        pop_min: 47100,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 234077,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [34.07583330000011, 0.454444399999943],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Katakwi",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Katakwi",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Uganda",
        sov_a3: "UGA",
        adm0name: "Uganda",
        adm0_a3: "UGA",
        adm1name: "Usuk",
        iso_a2: "UG",
        note: null,
        latitude: 1.8911111,
        longitude: 33.9661111,
        changed: 4,
        namediff: 0,
        diffnote:
          "Added missing admin-1 capital round B. Population from GeoNames.",
        pop_max: 8400,
        pop_min: 8400,
        pop_other: 0,
        rank_max: 5,
        rank_min: 5,
        geonameid: 231947,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [33.96611110000009, 1.891111100000046],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Veszprem",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Veszprem",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Hungary",
        sov_a3: "HUN",
        adm0name: "Hungary",
        adm0_a3: "HUN",
        adm1name: "Veszprém",
        iso_a2: "HU",
        note: null,
        latitude: 47.0909971373,
        longitude: 17.9109995685,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 62023,
        pop_min: 62023,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 3042929,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [17.910999568505588, 47.09099713733832],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Zalaegerszeg",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Zalaegerszeg",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Hungary",
        sov_a3: "HUN",
        adm0name: "Hungary",
        adm0_a3: "HUN",
        adm1name: "Zala",
        iso_a2: "HU",
        note: null,
        latitude: 46.8440010266,
        longitude: 16.8399995861,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 61898,
        pop_min: 61898,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 3042638,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [16.83999958610434, 46.844001026631986],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Tatabanya",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Tatabanya",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Hungary",
        sov_a3: "HUN",
        adm0name: "Hungary",
        adm0_a3: "HUN",
        adm1name: "Komárom-Esztergom",
        iso_a2: "HU",
        note: null,
        latitude: 47.5499971771,
        longitude: 18.4329995683,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 70541,
        pop_min: 70541,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 3044082,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [18.432999568271782, 47.5499971770771],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Szekszard",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Szekszard",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Hungary",
        sov_a3: "HUN",
        adm0name: "Hungary",
        adm0_a3: "HUN",
        adm1name: "Tolna",
        iso_a2: "HU",
        note: null,
        latitude: 46.3439971148,
        longitude: 18.7129985816,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 34174,
        pop_min: 34174,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 3044760,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [18.712998581584827, 46.34399711484775],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Salgotarjan",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Salgotarjan",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Hungary",
        sov_a3: "HUN",
        adm0name: "Hungary",
        adm0_a3: "HUN",
        adm1name: "Nógrád",
        iso_a2: "HU",
        note: null,
        latitude: 48.1050000794,
        longitude: 19.8260016264,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 39640,
        pop_min: 39640,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 3045643,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [19.82600162636612, 48.10500007944995],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Bekescsaba",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Bekescsaba",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Hungary",
        sov_a3: "HUN",
        adm0name: "Hungary",
        adm0_a3: "HUN",
        adm1name: "Békés",
        iso_a2: "HU",
        note: null,
        latitude: 46.6720021103,
        longitude: 21.1010045684,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 65206,
        pop_min: 65206,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 722437,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [21.101004568360736, 46.6720021102783],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Eger",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Eger",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Hungary",
        sov_a3: "HUN",
        adm0name: "Hungary",
        adm0_a3: "HUN",
        adm1name: "Heves",
        iso_a2: "HU",
        note: null,
        latitude: 47.8950031362,
        longitude: 20.3830025784,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 56647,
        pop_min: 56647,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 721239,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [20.38300257844014, 47.89500313623637],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Ntungamo",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Ntungamo",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Uganda",
        sov_a3: "UGA",
        adm0name: "Uganda",
        adm0_a3: "UGA",
        adm1name: "Ntungamo",
        iso_a2: "UG",
        note: null,
        latitude: -0.8794444,
        longitude: 30.2641667,
        changed: 4,
        namediff: 0,
        diffnote:
          "Added missing admin-1 capital round B. Population from GeoNames.",
        pop_max: 16400,
        pop_min: 16400,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 227593,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [30.26416670000009, -0.879444400000011],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Kisoro",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Kisoro",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Uganda",
        sov_a3: "UGA",
        adm0name: "Uganda",
        adm0_a3: "UGA",
        adm1name: "Kisoro",
        iso_a2: "UG",
        note: null,
        latitude: -1.3538889,
        longitude: 29.6983333,
        changed: 4,
        namediff: 0,
        diffnote:
          "Added missing admin-1 capital round B. Population from GeoNames.",
        pop_max: 12900,
        pop_min: 12900,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 230993,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [29.698333300000115, -1.353888900000015],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 0,
        name: "Gikongoro",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Gikongoro",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Rwanda",
        sov_a3: "RWA",
        adm0name: "Rwanda",
        adm0_a3: "RWA",
        adm1name: "Southern",
        iso_a2: "RW",
        note: null,
        latitude: -2.48329794474,
        longitude: 29.566701602,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 15000,
        pop_min: 15000,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 202239,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [29.566701602030776, -2.483297944739661],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 0,
        name: "Kibuye",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Kibuye",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Rwanda",
        sov_a3: "RWA",
        adm0name: "Rwanda",
        adm0_a3: "RWA",
        adm1name: "Western",
        iso_a2: "RW",
        note: null,
        latitude: -2.05000198533,
        longitude: 29.3499995919,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 48024,
        pop_min: 48024,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 202065,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [29.34999959191356, -2.050001985334575],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 0,
        name: "Kibungo",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Kibungo",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Rwanda",
        sov_a3: "RWA",
        adm0name: "Rwanda",
        adm0_a3: "RWA",
        adm1name: "Eastern",
        iso_a2: "RW",
        note: null,
        latitude: -2.16669593181,
        longitude: 30.5333015845,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 46240,
        pop_min: 46240,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 202068,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [30.533301584478863, -2.16669593181399],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Targu Jiu",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Targu Jiu",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Romania",
        sov_a3: "ROU",
        adm0name: "Romania",
        adm0_a3: "ROU",
        adm1name: "Gorj",
        iso_a2: "RO",
        note: null,
        latitude: 45.0450000352,
        longitude: 23.2740006154,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 97179,
        pop_min: 97179,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 665010,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [23.274000615434716, 45.045000035170006],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Slatina",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Slatina",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Romania",
        sov_a3: "ROU",
        adm0name: "Romania",
        adm0_a3: "ROU",
        adm1name: "Olt",
        iso_a2: "RO",
        note: null,
        latitude: 44.4349981422,
        longitude: 24.3710015559,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 78988,
        pop_min: 78988,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 666767,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [24.371001555854207, 44.434998142208656],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Alexandria",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Alexandria",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Romania",
        sov_a3: "ROU",
        adm0name: "Romania",
        adm0_a3: "ROU",
        adm1name: "Teleorman",
        iso_a2: "RO",
        note: null,
        latitude: 43.9016310699,
        longitude: 25.2867065425,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 49346,
        pop_min: 49346,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 686502,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [25.286706542462184, 43.90163106989155],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Targoviste",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Targoviste",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Romania",
        sov_a3: "ROU",
        adm0name: "Romania",
        adm0_a3: "ROU",
        adm1name: "Dâmbovita",
        iso_a2: "RO",
        note: null,
        latitude: 44.9379991285,
        longitude: 25.459002502,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 88435,
        pop_min: 88435,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 665024,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [25.459002501984173, 44.93799912854496],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Giurgiu",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Giurgiu",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Romania",
        sov_a3: "ROU",
        adm0name: "Romania",
        adm0_a3: "ROU",
        adm1name: "Giurgiu",
        iso_a2: "RO",
        note: null,
        latitude: 43.9299991062,
        longitude: 25.8399995022,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 69067,
        pop_min: 69067,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 677106,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [25.83999950218447, 43.92999910617101],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Slobozia",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Slobozia",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Romania",
        sov_a3: "ROU",
        adm0name: "Romania",
        adm0_a3: "ROU",
        adm1name: "Ialomita",
        iso_a2: "RO",
        note: null,
        latitude: 44.5699980566,
        longitude: 27.3819965932,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 52693,
        pop_min: 52693,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 666715,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [27.381996593165866, 44.56999805655312],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Dar'a",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Dar'a",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Syria",
        sov_a3: "SYR",
        adm0name: "Syria",
        adm0_a3: "SYR",
        adm1name: "Dar`a",
        iso_a2: "SY",
        note: null,
        latitude: 32.6250001377,
        longitude: 36.1050035066,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 146481,
        pop_min: 97969,
        pop_other: 0,
        rank_max: 9,
        rank_min: 8,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [36.10500350660266, 32.625000137740756],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Delemont",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Delemont",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Switzerland",
        sov_a3: "CHE",
        adm0name: "Switzerland",
        adm0_a3: "CHE",
        adm1name: "Jura",
        iso_a2: "CH",
        note: null,
        latitude: 47.3699971258,
        longitude: 7.34499948764,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 11315,
        pop_min: 11315,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 2661035,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [7.344999487643292, 47.36999712580081],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Neuchatel",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Neuchatel",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Switzerland",
        sov_a3: "CHE",
        adm0name: "Switzerland",
        adm0_a3: "CHE",
        adm1name: "Neuchâtel",
        iso_a2: "CH",
        note: null,
        latitude: 46.9989991447,
        longitude: 6.9229986154,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 31270,
        pop_min: 31270,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 2659496,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [6.922998615397546, 46.99899914474071],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Aarau",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Aarau",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Switzerland",
        sov_a3: "CHE",
        adm0name: "Switzerland",
        adm0_a3: "CHE",
        adm1name: "Aargau",
        iso_a2: "CH",
        note: null,
        latitude: 47.3900041002,
        longitude: 8.03400361048,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 15501,
        pop_min: 15501,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 2661881,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [8.034003610477498, 47.390004100198894],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Stans",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Stans",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Switzerland",
        sov_a3: "CHE",
        adm0name: "Switzerland",
        adm0_a3: "CHE",
        adm1name: "Nidwalden",
        iso_a2: "CH",
        note: null,
        latitude: 46.9500030739,
        longitude: 8.38330254672,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 7475,
        pop_min: 7475,
        pop_other: 0,
        rank_max: 5,
        rank_min: 5,
        geonameid: 2658504,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [8.383302546723826, 46.95000307389006],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Sion",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Sion",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Switzerland",
        sov_a3: "CHE",
        adm0name: "Switzerland",
        adm0_a3: "CHE",
        adm1name: "Valais",
        iso_a2: "CH",
        note: null,
        latitude: 46.2390030286,
        longitude: 7.35399948193,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 28045,
        pop_min: 28045,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 2658576,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [7.353999481933045, 46.239003028557875],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Herisau",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Herisau",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Switzerland",
        sov_a3: "CHE",
        adm0name: "Switzerland",
        adm0_a3: "CHE",
        adm1name: "Appenzell Ausserrhoden",
        iso_a2: "CH",
        note: null,
        latitude: 47.3832990333,
        longitude: 9.28330247214,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 15438,
        pop_min: 15438,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 2660365,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [9.283302472138416, 47.38329903329509],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Saint Gallen",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Saint Gallen",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Switzerland",
        sov_a3: "CHE",
        adm0name: "Switzerland",
        adm0_a3: "CHE",
        adm1name: "Sankt Gallen",
        iso_a2: "CH",
        note: null,
        latitude: 47.4229980667,
        longitude: 9.36199855724,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 70572,
        pop_min: 70572,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 2658822,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [9.361998557241634, 47.42299806668797],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Bellinzona",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Bellinzona",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Switzerland",
        sov_a3: "CHE",
        adm0name: "Switzerland",
        adm0_a3: "CHE",
        adm1name: "Ticino",
        iso_a2: "CH",
        note: null,
        latitude: 46.1970001317,
        longitude: 9.01999860875,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 16572,
        pop_min: 16572,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 2661567,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [9.019998608751848, 46.19700013166164],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Glarus",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Glarus",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Switzerland",
        sov_a3: "CHE",
        adm0name: "Switzerland",
        adm0_a3: "CHE",
        adm1name: "Glarus",
        iso_a2: "CH",
        note: null,
        latitude: 47.0500020359,
        longitude: 9.06669958673,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 5681,
        pop_min: 5681,
        pop_other: 0,
        rank_max: 5,
        rank_min: 5,
        geonameid: 2660594,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [9.066699586732966, 47.050002035926695],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Schaffhausen",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Schaffhausen",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Switzerland",
        sov_a3: "CHE",
        adm0name: "Switzerland",
        adm0_a3: "CHE",
        adm1name: "Schaffhausen",
        iso_a2: "CH",
        note: null,
        latitude: 47.7060030907,
        longitude: 8.63299852333,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 33863,
        pop_min: 33863,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 2658761,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [8.632998523330116, 47.70600309067038],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Schwyz",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Schwyz",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Switzerland",
        sov_a3: "CHE",
        adm0name: "Switzerland",
        adm0_a3: "CHE",
        adm1name: "Schwyz",
        iso_a2: "CH",
        note: null,
        latitude: 47.0199960424,
        longitude: 8.64800160284,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 14177,
        pop_min: 14177,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 2658665,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [8.648001602841134, 47.0199960423883],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Frauenfeld",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Frauenfeld",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Switzerland",
        sov_a3: "CHE",
        adm0name: "Switzerland",
        adm0_a3: "CHE",
        adm1name: "Thurgau",
        iso_a2: "CH",
        note: null,
        latitude: 47.5679971657,
        longitude: 9.10800050195,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 21979,
        pop_min: 21979,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 2660727,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [9.108000501947004, 47.56799716565638],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Altdorf",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Altdorf",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Switzerland",
        sov_a3: "CHE",
        adm0name: "Switzerland",
        adm0_a3: "CHE",
        adm1name: "Uri",
        iso_a2: "CH",
        note: null,
        latitude: 46.8790021444,
        longitude: 8.6380025837,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 8678,
        pop_min: 8678,
        pop_other: 0,
        rank_max: 5,
        rank_min: 5,
        geonameid: 2661780,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [8.638002583700825, 46.87900214442362],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Zug",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Zug",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Switzerland",
        sov_a3: "CHE",
        adm0name: "Switzerland",
        adm0_a3: "CHE",
        adm1name: "Zug",
        iso_a2: "CH",
        note: null,
        latitude: 47.1789990305,
        longitude: 8.48700056499,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 23435,
        pop_min: 23435,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 2657908,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [8.487000564994787, 47.178999030533475],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Falun",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Falun",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Sweden",
        sov_a3: "SWE",
        adm0name: "Sweden",
        adm0_a3: "SWE",
        adm1name: "Dalarna",
        iso_a2: "SE",
        note: null,
        latitude: 60.6130020356,
        longitude: 15.6470045537,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 36477,
        pop_min: 36477,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 2715459,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [15.647004553684724, 60.613002035558],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Nykoping",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Nykoping",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Sweden",
        sov_a3: "SWE",
        adm0name: "Sweden",
        adm0_a3: "SWE",
        adm1name: "Södermanland",
        iso_a2: "SE",
        note: null,
        latitude: 58.7639971778,
        longitude: 17.0150045131,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 27582,
        pop_min: 27582,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 2687700,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [17.015004513127508, 58.76399717776047],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Harnosand",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Harnosand",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Sweden",
        sov_a3: "SWE",
        adm0name: "Sweden",
        adm0_a3: "SWE",
        adm1name: "Västernorrland",
        iso_a2: "SE",
        note: null,
        latitude: 62.6339970391,
        longitude: 17.9340036175,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 17016,
        pop_min: 17016,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 2707684,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [17.934003617455744, 62.633997039075155],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Karlskrona",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Karlskrona",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Sweden",
        sov_a3: "SWE",
        adm0name: "Sweden",
        adm0_a3: "SWE",
        adm1name: "Blekinge",
        iso_a2: "SE",
        note: null,
        latitude: 56.2030021859,
        longitude: 15.2960046109,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 35212,
        pop_min: 35212,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [15.296004610905186, 56.20300218589796],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Alba Lulia",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Alba Lulia",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Romania",
        sov_a3: "ROU",
        adm0name: "Romania",
        adm0_a3: "ROU",
        adm1name: "Alba",
        iso_a2: "RO",
        note: null,
        latitude: 46.0770031313,
        longitude: 23.5800005868,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 66085,
        pop_min: 66085,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 686578,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [23.58000058676606, 46.077003131344384],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Bistrita",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Bistrita",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Romania",
        sov_a3: "ROU",
        adm0name: "Romania",
        adm0_a3: "ROU",
        adm1name: "Bistrita-Nasaud",
        iso_a2: "RO",
        note: null,
        latitude: 47.1380040946,
        longitude: 24.5130035803,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 81318,
        pop_min: 81318,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 684657,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [24.51300358027089, 47.138004094605435],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Deva",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Deva",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Romania",
        sov_a3: "ROU",
        adm0name: "Romania",
        adm0_a3: "ROU",
        adm1name: "Hunedoara",
        iso_a2: "RO",
        note: null,
        latitude: 45.8833230424,
        longitude: 22.9166735704,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 67802,
        pop_min: 67802,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 679452,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [22.916673570429055, 45.88332304241237],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Zalau",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Zalau",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Romania",
        sov_a3: "ROU",
        adm0name: "Romania",
        adm0_a3: "ROU",
        adm1name: "Salaj",
        iso_a2: "RO",
        note: null,
        latitude: 47.1750030966,
        longitude: 23.0630044819,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 63232,
        pop_min: 63232,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 662334,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [23.063004481886935, 47.17500309661466],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Satu Mare",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Satu Mare",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Romania",
        sov_a3: "ROU",
        adm0name: "Romania",
        adm0_a3: "ROU",
        adm1name: "Satu Mare",
        iso_a2: "RO",
        note: null,
        latitude: 47.7919981635,
        longitude: 22.8850024803,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 112490,
        pop_min: 112490,
        pop_other: 0,
        rank_max: 9,
        rank_min: 9,
        geonameid: 667873,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [22.88500248031181, 47.791998163530366],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Rimnicu Vilcea",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Rimnicu Vilcea",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Romania",
        sov_a3: "ROU",
        adm0name: "Romania",
        adm0_a3: "ROU",
        adm1name: "Vâlcea",
        iso_a2: "RO",
        note: null,
        latitude: 45.1099980449,
        longitude: 24.3829986247,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 107558,
        pop_min: 107558,
        pop_other: 0,
        rank_max: 9,
        rank_min: 9,
        geonameid: 668872,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [24.382998624695688, 45.109998044898646],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Sfintu-Gheorghe",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Sfintu-Gheorghe",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Romania",
        sov_a3: "ROU",
        adm0name: "Romania",
        adm0_a3: "ROU",
        adm1name: "Covasna",
        iso_a2: "RO",
        note: null,
        latitude: 45.8679961114,
        longitude: 25.793001481,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 60677,
        pop_min: 60677,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 667303,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [25.793001481034935, 45.86799611138031],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Miercurea Cuic",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Miercurea-Cuic",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Romania",
        sov_a3: "ROU",
        adm0name: "Romania",
        adm0_a3: "ROU",
        adm1name: "Harghita",
        iso_a2: "RO",
        note: null,
        latitude: 46.3609980786,
        longitude: 25.5240005117,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 42029,
        pop_min: 37980,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [25.524000511712984, 46.3609980785763],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Piatra-Neamt",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Piatra-Neamt",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Romania",
        sov_a3: "ROU",
        adm0name: "Romania",
        adm0_a3: "ROU",
        adm1name: "Neamt",
        iso_a2: "RO",
        note: null,
        latitude: 46.9400040547,
        longitude: 26.3829965651,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 102688,
        pop_min: 102688,
        pop_other: 0,
        rank_max: 9,
        rank_min: 9,
        geonameid: 670889,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [26.38299656508184, 46.94000405474975],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Braila",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Braila",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Romania",
        sov_a3: "ROU",
        adm0name: "Romania",
        adm0_a3: "ROU",
        adm1name: "Braila",
        iso_a2: "RO",
        note: null,
        latitude: 45.2919961459,
        longitude: 27.9690035388,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 213569,
        pop_min: 213569,
        pop_other: 0,
        rank_max: 10,
        rank_min: 10,
        geonameid: 683902,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [27.96900353877851, 45.291996145876226],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Vaslui",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Vaslui",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Romania",
        sov_a3: "ROU",
        adm0name: "Romania",
        adm0_a3: "ROU",
        adm1name: "Vaslui",
        iso_a2: "RO",
        note: null,
        latitude: 46.6333290756,
        longitude: 27.7333385907,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 69225,
        pop_min: 69225,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 663118,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [27.73333859066895, 46.6333290755727],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Salyan",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Sallyan",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Nepal",
        sov_a3: "NPL",
        adm0name: "Nepal",
        adm0_a3: "NPL",
        adm1name: "Rapti",
        iso_a2: "NP",
        note: null,
        latitude: 28.3500000369,
        longitude: 82.1833025536,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 15000,
        pop_min: 15000,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [82.18330255361178, 28.350000036941594],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Mae Hong Son",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Mae Hong Son",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Thailand",
        sov_a3: "THA",
        adm0name: "Thailand",
        adm0_a3: "THA",
        adm1name: "Mae Hong Son",
        iso_a2: "TH",
        note: null,
        latitude: 19.30100405,
        longitude: 97.9689966529,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 9109,
        pop_min: 9109,
        pop_other: 0,
        rank_max: 5,
        rank_min: 5,
        geonameid: 1152222,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [97.96899665285014, 19.301004050010818],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Phangnga",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Phangnga",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Thailand",
        sov_a3: "THA",
        adm0name: "Thailand",
        adm0_a3: "THA",
        adm1name: "Phangnga",
        iso_a2: "TH",
        note: null,
        latitude: 8.4510000858,
        longitude: 98.5339985744,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 9676,
        pop_min: 9676,
        pop_other: 0,
        rank_max: 5,
        rank_min: 5,
        geonameid: 1151464,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [98.53399857436312, 8.451000085799024],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Ranong",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Ranong",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Thailand",
        sov_a3: "THA",
        adm0name: "Thailand",
        adm0_a3: "THA",
        adm1name: "Ranong",
        iso_a2: "TH",
        note: null,
        latitude: 9.96200109451,
        longitude: 98.6380025719,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 24561,
        pop_min: 24561,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 1150965,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [98.63800257192008, 9.962001094509105],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Krabi",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Krabi",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Thailand",
        sov_a3: "THA",
        adm0name: "Thailand",
        adm0_a3: "THA",
        adm1name: "Krabi",
        iso_a2: "TH",
        note: null,
        latitude: 8.05200309702,
        longitude: 98.9119986655,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 31219,
        pop_min: 31219,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 1152633,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [98.9119986654951, 8.052003097019451],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Phatthalung",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Phatthalung",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Thailand",
        sov_a3: "THA",
        adm0name: "Thailand",
        adm0_a3: "THA",
        adm1name: "Phatthalung",
        iso_a2: "TH",
        note: null,
        latitude: 7.61499897978,
        longitude: 100.08099956,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 43522,
        pop_min: 43522,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 1607779,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [100.08099956023182, 7.614998979778875],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Satun",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Satun",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Thailand",
        sov_a3: "THA",
        adm0name: "Thailand",
        adm0_a3: "THA",
        adm1name: "Satun",
        iso_a2: "TH",
        note: null,
        latitude: 6.61670109886,
        longitude: 100.066698628,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 34544,
        pop_min: 34544,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 1606376,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [100.06669862788681, 6.616701098860688],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Lamphun",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Lamphun",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Thailand",
        sov_a3: "THA",
        adm0name: "Thailand",
        adm0_a3: "THA",
        adm1name: "Lamphun",
        iso_a2: "TH",
        note: null,
        latitude: 18.5030011363,
        longitude: 99.0739985627,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 14030,
        pop_min: 14030,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 1152468,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [99.07399856270865, 18.503001136334092],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Kamphaeng Phet",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Kamphaeng Phet",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Thailand",
        sov_a3: "THA",
        adm0name: "Thailand",
        adm0_a3: "THA",
        adm1name: "Kamphaeng Phet",
        iso_a2: "TH",
        note: null,
        latitude: 16.4729970369,
        longitude: 99.5290026685,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 58787,
        pop_min: 58787,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 1153090,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [99.5290026685285, 16.472997036926074],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Phichit",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Phichit",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Thailand",
        sov_a3: "THA",
        adm0name: "Thailand",
        adm0_a3: "THA",
        adm1name: "Phichit",
        iso_a2: "TH",
        note: null,
        latitude: 16.4390040456,
        longitude: 100.34900167,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 35760,
        pop_min: 35760,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 1607725,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [100.34900167018702, 16.439004045586273],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Phetchabun",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Phetchabun",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Thailand",
        sov_a3: "THA",
        adm0name: "Thailand",
        adm0_a3: "THA",
        adm1name: "Phetchabun",
        iso_a2: "TH",
        note: null,
        latitude: 16.4189970712,
        longitude: 101.159001653,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 50656,
        pop_min: 50656,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 1607737,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [101.15900165270511, 16.418997071188187],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Supham Buri",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Supham Buri",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Thailand",
        sov_a3: "THA",
        adm0name: "Thailand",
        adm0_a3: "THA",
        adm1name: "Suphan Buri",
        iso_a2: "TH",
        note: null,
        latitude: 14.4710010468,
        longitude: 100.128996606,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 53399,
        pop_min: 53399,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 1606033,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [100.12899660623191, 14.471001046838808],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Ang Thong",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Ang Thong",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Thailand",
        sov_a3: "THA",
        adm0name: "Thailand",
        adm0_a3: "THA",
        adm1name: "Ang Thong",
        iso_a2: "TH",
        note: null,
        latitude: 14.583303057,
        longitude: 100.449999657,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 13738,
        pop_min: 13738,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 1621035,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [100.44999965707427, 14.583303057002922],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Chainat",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Chainat",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Thailand",
        sov_a3: "THA",
        adm0name: "Thailand",
        adm0_a3: "THA",
        adm1name: "Chai Nat",
        iso_a2: "TH",
        note: null,
        latitude: 15.1790040176,
        longitude: 100.125999697,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 15469,
        pop_min: 15469,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 1611416,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [100.12599969716382, 15.179004017619036],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Nakhon Nayok",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Nakhon Nayok",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Thailand",
        sov_a3: "THA",
        adm0name: "Thailand",
        adm0_a3: "THA",
        adm1name: "Nakhon Nayok",
        iso_a2: "TH",
        note: null,
        latitude: 14.2000020278,
        longitude: 101.215998693,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 21309,
        pop_min: 21309,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 1608539,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [101.2159986929949, 14.200002027815458],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Sing Buri",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Sing Buri",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Thailand",
        sov_a3: "THA",
        adm0name: "Thailand",
        adm0_a3: "THA",
        adm1name: "Sing Buri",
        iso_a2: "TH",
        note: null,
        latitude: 14.8869989993,
        longitude: 100.401003586,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 20046,
        pop_min: 20046,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 1606270,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [100.40100358622362, 14.886998999346929],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Nakhon Pathom",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Nakhon Pathom",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Thailand",
        sov_a3: "THA",
        adm0name: "Thailand",
        adm0_a3: "THA",
        adm1name: "Nakhon Pathom",
        iso_a2: "TH",
        note: null,
        latitude: 13.8179970666,
        longitude: 100.063998597,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 117927,
        pop_min: 117927,
        pop_other: 0,
        rank_max: 9,
        rank_min: 9,
        geonameid: 1608534,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [100.06399859650327, 13.817997066647024],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Prachuap Khiri Khan",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Prachuap Khiri Khan",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Thailand",
        sov_a3: "THA",
        adm0name: "Thailand",
        adm0_a3: "THA",
        adm1name: "Prachuap Khiri Khan",
        iso_a2: "TH",
        note: null,
        latitude: 11.8029960468,
        longitude: 99.8000016876,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 33521,
        pop_min: 33521,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 1151074,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [99.80000168755203, 11.802996046750025],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Samut Sakhon",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Samut Sakhon",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Thailand",
        sov_a3: "THA",
        adm0name: "Thailand",
        adm0_a3: "THA",
        adm1name: "Samut Sakhon",
        iso_a2: "TH",
        note: null,
        latitude: 13.5360000036,
        longitude: 100.274004641,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 63498,
        pop_min: 63498,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 1606588,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [100.27400464131813, 13.536000003632807],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Samut Songkhram",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Samut Songkhram",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Thailand",
        sov_a3: "THA",
        adm0name: "Thailand",
        adm0_a3: "THA",
        adm1name: "Samut Songkhram",
        iso_a2: "TH",
        note: null,
        latitude: 13.4129969926,
        longitude: 100.000998636,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 35065,
        pop_min: 35065,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 1606586,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [100.00099863647586, 13.412996992646015],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Yasothon",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Yasothon",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Thailand",
        sov_a3: "THA",
        adm0name: "Thailand",
        adm0_a3: "THA",
        adm1name: "Yasothon",
        iso_a2: "TH",
        note: null,
        latitude: 15.7879981151,
        longitude: 104.150997677,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 21643,
        pop_min: 21643,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 1604769,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [104.15099767658705, 15.787998115095832],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Chachoengsao",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Chachoengsao",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Thailand",
        sov_a3: "THA",
        adm0name: "Thailand",
        adm0_a3: "THA",
        adm1name: "Chachoengsao",
        iso_a2: "TH",
        note: null,
        latitude: 13.6790010529,
        longitude: 101.076003654,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 49741,
        pop_min: 49741,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 1611439,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [101.0760036543972, 13.679001052899878],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Trat",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Trat",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Thailand",
        sov_a3: "THA",
        adm0name: "Thailand",
        adm0_a3: "THA",
        adm1name: "Trat",
        iso_a2: "TH",
        note: null,
        latitude: 12.2370030894,
        longitude: 102.509001624,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 21590,
        pop_min: 21590,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 1605279,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [102.50900162356879, 12.237003089438701],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Kalasin",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Kalasin",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Thailand",
        sov_a3: "THA",
        adm0name: "Thailand",
        adm0_a3: "THA",
        adm1name: "Kalasin",
        iso_a2: "TH",
        note: null,
        latitude: 16.4279970655,
        longitude: 103.509000677,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 55102,
        pop_min: 55102,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 1610469,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [103.50900067650377, 16.42799706547794],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Maha Sarakham",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Maha Sarakham",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Thailand",
        sov_a3: "THA",
        adm0name: "Thailand",
        adm0_a3: "THA",
        adm1name: "Maha Sarakham",
        iso_a2: "TH",
        note: null,
        latitude: 16.1839980293,
        longitude: 103.298004543,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 51584,
        pop_min: 51584,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 1608900,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [103.29800454295582, 16.183998029323504],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Roi Et",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Roi Et",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Thailand",
        sov_a3: "THA",
        adm0name: "Thailand",
        adm0_a3: "THA",
        adm1name: "Roi Et",
        iso_a2: "TH",
        note: null,
        latitude: 16.0509959992,
        longitude: 103.654998635,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 39328,
        pop_min: 39328,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 1607001,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [103.65499863483916, 16.050995999196516],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Pattani",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Pattani",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Thailand",
        sov_a3: "THA",
        adm0name: "Thailand",
        adm0_a3: "THA",
        adm1name: "Pattani",
        iso_a2: "TH",
        note: null,
        latitude: 6.86400302337,
        longitude: 101.25000062,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 150000,
        pop_min: 43631,
        pop_other: 0,
        rank_max: 9,
        rank_min: 7,
        geonameid: 1607978,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [101.25000062045217, 6.864003023369264],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Piggs Peak",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Piggs Peak",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Swaziland",
        sov_a3: "SWZ",
        adm0name: "Swaziland",
        adm0_a3: "SWZ",
        adm1name: "Hhohho",
        iso_a2: "SZ",
        note: null,
        latitude: -25.9610039877,
        longitude: 31.2470014957,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 5750,
        pop_min: 5750,
        pop_other: 0,
        rank_max: 5,
        rank_min: 5,
        geonameid: 934881,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [31.247001495710947, -25.961003987691868],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Siteki",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Siteki",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Swaziland",
        sov_a3: "SWZ",
        adm0name: "Swaziland",
        adm0_a3: "SWZ",
        adm1name: "Lubombo",
        iso_a2: "SZ",
        note: null,
        latitude: -26.4549960436,
        longitude: 31.9519986213,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 6152,
        pop_min: 6152,
        pop_other: 0,
        rank_max: 5,
        rank_min: 5,
        geonameid: 934851,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [31.951998621305506, -26.454996043620838],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Manzini",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Manzini",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Swaziland",
        sov_a3: "SWZ",
        adm0name: "Swaziland",
        adm0_a3: "SWZ",
        adm1name: "Manzini",
        iso_a2: "SZ",
        note: null,
        latitude: -26.4950010563,
        longitude: 31.3880044953,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 110537,
        pop_min: 110537,
        pop_other: 0,
        rank_max: 9,
        rank_min: 9,
        geonameid: 934995,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [31.388004495277073, -26.495001056299657],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Hlatikulu",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Hlatikulu",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Swaziland",
        sov_a3: "SWZ",
        adm0name: "Swaziland",
        adm0_a3: "SWZ",
        adm1name: "Shiselweni",
        iso_a2: "SZ",
        note: null,
        latitude: -26.9999999269,
        longitude: 31.4166965486,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 2748,
        pop_min: 2748,
        pop_other: 0,
        rank_max: 4,
        rank_min: 4,
        geonameid: 935081,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [31.416696548561276, -26.999999926853548],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Somoto",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Somoto",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Nicaragua",
        sov_a3: "NIC",
        adm0name: "Nicaragua",
        adm0_a3: "NIC",
        adm1name: "Madriz",
        iso_a2: "NI",
        note: null,
        latitude: 13.4759971182,
        longitude: -86.5829965925,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 20316,
        pop_min: 20316,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 3616232,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-86.58299659251429, 13.475997118157238],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Ocotal",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Ocotal",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Nicaragua",
        sov_a3: "NIC",
        adm0name: "Nicaragua",
        adm0_a3: "NIC",
        adm1name: "Nueva Segovia",
        iso_a2: "NI",
        note: null,
        latitude: 13.6299960459,
        longitude: -86.4729986113,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 33928,
        pop_min: 33928,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 3617448,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-86.47299861133735, 13.629996045931591],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Aveiro",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Aveiro",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Portugal",
        sov_a3: "PRT",
        adm0name: "Portugal",
        adm0_a3: "PRT",
        adm1name: "Aveiro",
        iso_a2: "PT",
        note: null,
        latitude: 40.6410031052,
        longitude: -8.65099753409,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 54162,
        pop_min: 54162,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 2742611,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-8.650997534094358, 40.64100310524765],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Leiria",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Leiria",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Portugal",
        sov_a3: "PRT",
        adm0name: "Portugal",
        adm0_a3: "PRT",
        adm1name: "Leiria",
        iso_a2: "PT",
        note: null,
        latitude: 39.7389960285,
        longitude: -8.80499646187,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 45112,
        pop_min: 45112,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 2267095,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-8.804996461868768, 39.738996028530494],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Viana Do Castelo",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Viana Do Castelo",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Portugal",
        sov_a3: "PRT",
        adm0name: "Portugal",
        adm0_a3: "PRT",
        adm1name: "Viana do Castelo",
        iso_a2: "PT",
        note: null,
        latitude: 41.6962351427,
        longitude: -8.84413748436,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 15555,
        pop_min: 15555,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 2732773,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-8.844137484362761, 41.696235142665046],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 3,
        name: "Merida",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Merida",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Kingdom of Spain",
        sov_a3: "ESP",
        adm0name: "Spain",
        adm0_a3: "ESP",
        adm1name: "Extremadura",
        iso_a2: "ES",
        note: null,
        latitude: 38.9120040184,
        longitude: -6.33799751231,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 52423,
        pop_min: 52423,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 2513917,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-6.337997512305094, 38.912004018401376],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Beja",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Beja",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Portugal",
        sov_a3: "PRT",
        adm0name: "Portugal",
        adm0_a3: "PRT",
        adm1name: "Beja",
        iso_a2: "PT",
        note: null,
        latitude: 38.0140021427,
        longitude: -7.86300241019,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 35854,
        pop_min: 21658,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 2270985,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-7.863002410191882, 38.014002142688014],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Evora",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Evora",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Portugal",
        sov_a3: "PRT",
        adm0name: "Portugal",
        adm0_a3: "PRT",
        adm1name: "Évora",
        iso_a2: "PT",
        note: null,
        latitude: 38.5599961147,
        longitude: -7.9059955613,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 55620,
        pop_min: 55620,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 2268406,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-7.905995561304906, 38.5599961146537],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Portalegre",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Portalegre",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Portugal",
        sov_a3: "PRT",
        adm0name: "Portugal",
        adm0_a3: "PRT",
        adm1name: "Portalegre",
        iso_a2: "PT",
        note: null,
        latitude: 39.2900041095,
        longitude: -7.42300154937,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 15581,
        pop_min: 15581,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 2264508,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-7.423001549366745, 39.290004109533356],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Santarem",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Santarem",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Portugal",
        sov_a3: "PRT",
        adm0name: "Portugal",
        adm0_a3: "PRT",
        adm1name: "Santarém",
        iso_a2: "PT",
        note: null,
        latitude: 39.2310000834,
        longitude: -8.68200255248,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 29385,
        pop_min: 29385,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 2263480,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-8.682002552483425, 39.23100008342476],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Braganca",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Braganca",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Portugal",
        sov_a3: "PRT",
        adm0name: "Portugal",
        adm0_a3: "PRT",
        adm1name: "Bragança",
        iso_a2: "PT",
        note: null,
        latitude: 41.8079970142,
        longitude: -6.75500342578,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 34375,
        pop_min: 34375,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 2742027,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-6.755003425781467, 41.80799701416538],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Castelo Branco",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Castelo Branco",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Portugal",
        sov_a3: "PRT",
        adm0name: "Portugal",
        adm0_a3: "PRT",
        adm1name: "Castelo Branco",
        iso_a2: "PT",
        note: null,
        latitude: 39.8109961483,
        longitude: -7.4879995591,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 33479,
        pop_min: 33479,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 2269514,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-7.487999559095442, 39.810996148331355],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Guarda",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Guarda",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Portugal",
        sov_a3: "PRT",
        adm0name: "Portugal",
        adm0_a3: "PRT",
        adm1name: "Guarda",
        iso_a2: "PT",
        note: null,
        latitude: 40.5410041432,
        longitude: -7.26200051152,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 32111,
        pop_min: 32111,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 2738785,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-7.262000511520284, 40.54100414321101],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Viseu",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Viseu",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Portugal",
        sov_a3: "PRT",
        adm0name: "Portugal",
        adm0_a3: "PRT",
        adm1name: "Viseu",
        iso_a2: "PT",
        note: null,
        latitude: 40.656996108,
        longitude: -7.91000043134,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 26364,
        pop_min: 26364,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 2732265,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-7.910000431341302, 40.656996108008],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Vila Real",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Vila Real",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Portugal",
        sov_a3: "PRT",
        adm0name: "Portugal",
        adm0_a3: "PRT",
        adm1name: "Vila Real",
        iso_a2: "PT",
        note: null,
        latitude: 41.2939981493,
        longitude: -7.73700249014,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 17001,
        pop_min: 17001,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 2732438,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-7.737002490137002, 41.293998149321794],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Fatick",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Fatick",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Senegal",
        sov_a3: "SEN",
        adm0name: "Senegal",
        adm0_a3: "SEN",
        adm1name: "Fatick",
        iso_a2: "SM",
        note: null,
        latitude: 14.3440021019,
        longitude: -16.4159995209,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 24243,
        pop_min: 24243,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 2251911,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-16.415999520858463, 14.344002101933313],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "An Nabk",
        namepar: "Qurayyat",
        namealt: null,
        diffascii: 0,
        nameascii: "An Nabk",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Saudi Arabia",
        sov_a3: "SAU",
        adm0name: "Saudi Arabia",
        adm0_a3: "SAU",
        adm1name: "Al Jawf",
        iso_a2: "SA",
        note: null,
        latitude: 31.3333020458,
        longitude: 37.3332965345,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 10000,
        pop_min: 5000,
        pop_other: 0,
        rank_max: 5,
        rank_min: 4,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [37.333296534498515, 31.333302045819607],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Assen",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Assen",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Kingdom of the Netherlands",
        sov_a3: "NLD",
        adm0name: "Netherlands",
        adm0_a3: "NLD",
        adm1name: "Drenthe",
        iso_a2: "NL",
        note: null,
        latitude: 53.0000010924,
        longitude: 6.55000258464,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 62237,
        pop_min: 62237,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 2759633,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [6.550002584636275, 53.0000010923506],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Arnhem",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Arnhem",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Kingdom of the Netherlands",
        sov_a3: "NLD",
        adm0name: "Netherlands",
        adm0_a3: "NLD",
        adm1name: "Gelderland",
        iso_a2: "NL",
        note: null,
        latitude: 51.9879960345,
        longitude: 5.92299956246,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 141674,
        pop_min: 141674,
        pop_other: 0,
        rank_max: 9,
        rank_min: 9,
        geonameid: 2759661,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [5.922999562462564, 51.98799603445673],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Maastricht",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Maastricht",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Kingdom of the Netherlands",
        sov_a3: "NLD",
        adm0name: "Netherlands",
        adm0_a3: "NLD",
        adm1name: "Limburg",
        iso_a2: "NL",
        note: null,
        latitude: 50.8529970672,
        longitude: 5.67700247661,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 122378,
        pop_min: 122378,
        pop_other: 0,
        rank_max: 9,
        rank_min: 9,
        geonameid: 2751283,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [5.677002476606958, 50.852997067177284],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Zwolle",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Zwolle",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Kingdom of the Netherlands",
        sov_a3: "NLD",
        adm0name: "Netherlands",
        adm0_a3: "NLD",
        adm1name: "Overijssel",
        iso_a2: "NL",
        note: null,
        latitude: 52.5240000889,
        longitude: 6.09699652852,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 111805,
        pop_min: 111805,
        pop_other: 0,
        rank_max: 9,
        rank_min: 9,
        geonameid: 2743477,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [6.096996528517536, 52.52400008888333],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Middelburg",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Middelburg",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Kingdom of the Netherlands",
        sov_a3: "NLD",
        adm0name: "Netherlands",
        adm0_a3: "NLD",
        adm1name: "Zeeland",
        iso_a2: "NL",
        note: null,
        latitude: 51.5019961773,
        longitude: 3.60999954067,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 46485,
        pop_min: 46485,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 2750896,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [3.609999540673243, 51.50199617733273],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "'s-Hertogenbosch",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "'s-Hertogenbosch",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Kingdom of the Netherlands",
        sov_a3: "NLD",
        adm0name: "Netherlands",
        adm0_a3: "NLD",
        adm1name: "Noord-Brabant",
        iso_a2: "NL",
        note: null,
        latitude: 51.6833371427,
        longitude: 5.31666048481,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 134520,
        pop_min: 134520,
        pop_other: 0,
        rank_max: 9,
        rank_min: 9,
        geonameid: 2747351,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [5.316660484814179, 51.68333714269971],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Arendal",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Arendal",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Kingdom of Norway",
        sov_a3: "NOR",
        adm0name: "Norway",
        adm0_a3: "NOR",
        adm1name: "Aust-Agder",
        iso_a2: "NO",
        note: null,
        latitude: 58.4647560555,
        longitude: 8.76600055346,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 30916,
        pop_min: 30916,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 3162955,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [8.766000553457104, 58.464756055522116],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Vossavangen",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Vossavangen",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Kingdom of Norway",
        sov_a3: "NOR",
        adm0name: "Norway",
        adm0_a3: "NOR",
        adm1name: "Hordaland",
        iso_a2: "NO",
        note: null,
        latitude: 60.6300031648,
        longitude: 6.44100346283,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 5571,
        pop_min: 5571,
        pop_other: 0,
        rank_max: 5,
        rank_min: 5,
        geonameid: 3131329,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [6.441003462826245, 60.63000316477013],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Leikanger",
        namepar: "Systrond",
        namealt: "Hermansverk",
        diffascii: 0,
        nameascii: "Hermansverk",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Kingdom of Norway",
        sov_a3: "NOR",
        adm0name: "Norway",
        adm0_a3: "NOR",
        adm1name: "Sogn og Fjordane",
        iso_a2: "NO",
        note: null,
        latitude: 61.1832961245,
        longitude: 6.84999947075,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 1965,
        pop_min: 1965,
        pop_other: 0,
        rank_max: 3,
        rank_min: 3,
        geonameid: 3152884,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [6.849999470745956, 61.183296124492415],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Bærum",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Baerum",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Kingdom of Norway",
        sov_a3: "NOR",
        adm0name: "Norway",
        adm0_a3: "NOR",
        adm1name: "Akershus",
        iso_a2: "NO",
        note: null,
        latitude: 59.9134860597,
        longitude: 11.3472365136,
        changed: 40,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 113659,
        pop_min: 113659,
        pop_other: 0,
        rank_max: 9,
        rank_min: 9,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [11.34723651361196, 59.91348605972365],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Hamar",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Hamar",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Kingdom of Norway",
        sov_a3: "NOR",
        adm0name: "Norway",
        adm0_a3: "NOR",
        adm1name: "Hedmark",
        iso_a2: "NO",
        note: null,
        latitude: 60.8200020697,
        longitude: 11.0690015561,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 29479,
        pop_min: 29479,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 3154084,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [11.069001556106116, 60.820002069703264],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Tønsberg",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Tonsberg",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Kingdom of Norway",
        sov_a3: "NOR",
        adm0name: "Norway",
        adm0_a3: "NOR",
        adm1name: "Vestfold",
        iso_a2: "NO",
        note: null,
        latitude: 59.2640010895,
        longitude: 10.4210014708,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 38914,
        pop_min: 38914,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 3134331,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [10.4210014708014, 59.26400108954488],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Baglung",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Baglung",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Nepal",
        sov_a3: "NPL",
        adm0name: "Nepal",
        adm0_a3: "NPL",
        adm1name: "Dhawalagiri",
        iso_a2: "NP",
        note: null,
        latitude: 28.2666960593,
        longitude: 83.5832976202,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 23296,
        pop_min: 23296,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 1283711,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [83.58329762017695, 28.26669605934768],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Jumla",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Jumla",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Nepal",
        sov_a3: "NPL",
        adm0name: "Nepal",
        adm0_a3: "NPL",
        adm1name: "Karnali",
        iso_a2: "NP",
        note: null,
        latitude: 29.2500001278,
        longitude: 82.2167016241,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 9073,
        pop_min: 9073,
        pop_other: 0,
        rank_max: 5,
        rank_min: 5,
        geonameid: 1283285,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [82.21670162409868, 29.25000012783977],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Bhairawa",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Bhairawa",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Nepal",
        sov_a3: "NPL",
        adm0name: "Nepal",
        adm0_a3: "NPL",
        adm1name: "Lumbini",
        iso_a2: "NP",
        note: null,
        latitude: 27.5333040891,
        longitude: 83.3832995306,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 63367,
        pop_min: 63367,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 1283621,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [83.38329953061987, 27.533304089120975],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Dandeldhura",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Dandeldhura",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Nepal",
        sov_a3: "NPL",
        adm0name: "Nepal",
        adm0_a3: "NPL",
        adm1name: "Mahakali",
        iso_a2: "NP",
        note: null,
        latitude: 29.3000041597,
        longitude: 80.6000045474,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 19014,
        pop_min: 19014,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 1283499,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [80.60000454741635, 29.300004159658783],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Dhangarhi",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Dhangarhi",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Nepal",
        sov_a3: "NPL",
        adm0name: "Nepal",
        adm0_a3: "NPL",
        adm1name: "Achham",
        iso_a2: "NP",
        note: null,
        latitude: 28.69499706,
        longitude: 80.5930026028,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 92294,
        pop_min: 92294,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 1283467,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [80.59300260282794, 28.69499705998328],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Ramechhap",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Ramechhap",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Nepal",
        sov_a3: "NPL",
        adm0name: "Nepal",
        adm0_a3: "NPL",
        adm1name: "Janakpur",
        iso_a2: "NP",
        note: null,
        latitude: 27.3259980757,
        longitude: 86.0869985286,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 15000,
        pop_min: 15000,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [86.08699852858143, 27.325998075689824],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Bhimphedi",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Bhimphedi",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Nepal",
        sov_a3: "NPL",
        adm0name: "Nepal",
        adm0_a3: "NPL",
        adm1name: "Narayani",
        iso_a2: "NP",
        note: null,
        latitude: 27.5509980984,
        longitude: 85.1300015629,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 15000,
        pop_min: 15000,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [85.13000156287723, 27.550998098414254],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Rajbiraj",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Rajbiraj",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Nepal",
        sov_a3: "NPL",
        adm0name: "Nepal",
        adm0_a3: "NPL",
        adm1name: "Sagarmatha",
        iso_a2: "NP",
        note: null,
        latitude: 26.5332961001,
        longitude: 86.7332976074,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 33061,
        pop_min: 33061,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 1282884,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [86.73329760735317, 26.533296100068526],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Ilam",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Ilam",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Nepal",
        sov_a3: "NPL",
        adm0name: "Nepal",
        adm0_a3: "NPL",
        adm1name: "Mechi",
        iso_a2: "NP",
        note: null,
        latitude: 26.9080020735,
        longitude: 87.9260045327,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 17491,
        pop_min: 17491,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 1283333,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [87.92600453272223, 26.908002073480247],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Wabag",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Wabag",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Papua New Guinea",
        sov_a3: "PNG",
        adm0name: "Papua New Guinea",
        adm0_a3: "PNG",
        adm1name: "Enga",
        iso_a2: "PG",
        note: null,
        latitude: -5.49000000496,
        longitude: 143.718003738,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 3958,
        pop_min: 3958,
        pop_other: 0,
        rank_max: 4,
        rank_min: 4,
        geonameid: 2084246,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [143.71800373801102, -5.490000004964259],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Vanimo",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Vanimo",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Papua New Guinea",
        sov_a3: "PNG",
        adm0name: "Papua New Guinea",
        adm0_a3: "PNG",
        adm1name: "Sandaun",
        iso_a2: "PG",
        note: null,
        latitude: -2.69000093572,
        longitude: 141.303996628,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 11204,
        pop_min: 11204,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 2084442,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [141.30399662773323, -2.690000935716682],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Kundiawa",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Kundiawa",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Papua New Guinea",
        sov_a3: "PNG",
        adm0name: "Papua New Guinea",
        adm0_a3: "PNG",
        adm1name: "Chimbu",
        iso_a2: "PG",
        note: null,
        latitude: -6.02299788324,
        longitude: 144.960012714,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 9383,
        pop_min: 9383,
        pop_other: 0,
        rank_max: 5,
        rank_min: 5,
        geonameid: 2093059,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [144.9600127135168, -6.022997883237622],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Kerema",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Kerema",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Papua New Guinea",
        sov_a3: "PNG",
        adm0name: "Papua New Guinea",
        adm0_a3: "PNG",
        adm1name: "Gulf",
        iso_a2: "PG",
        note: null,
        latitude: -7.92700189711,
        longitude: 145.837998679,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 5646,
        pop_min: 5646,
        pop_other: 0,
        rank_max: 5,
        rank_min: 5,
        geonameid: 2094144,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [145.83799867871426, -7.927001897107175],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Arawa",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Arawa",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Papua New Guinea",
        sov_a3: "PNG",
        adm0name: "Papua New Guinea",
        adm0_a3: "PNG",
        adm1name: "North Solomons",
        iso_a2: "PG",
        note: null,
        latitude: -6.22800003317,
        longitude: 155.565990668,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 40266,
        pop_min: 40266,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 2100633,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [155.56599066773765, -6.228000033165301],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Lorengau",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Lorengau",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Papua New Guinea",
        sov_a3: "PNG",
        adm0name: "Papua New Guinea",
        adm0_a3: "PNG",
        adm1name: "Manus",
        iso_a2: "PG",
        note: null,
        latitude: -2.03200199676,
        longitude: 147.279996642,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 5806,
        pop_min: 5806,
        pop_other: 0,
        rank_max: 5,
        rank_min: 5,
        geonameid: 2092164,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [147.27999664217543, -2.032001996755184],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Kimbe",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Kimbe",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Papua New Guinea",
        sov_a3: "PNG",
        adm0name: "Papua New Guinea",
        adm0_a3: "PNG",
        adm1name: "West New Britain",
        iso_a2: "PG",
        note: null,
        latitude: -5.55000289044,
        longitude: 150.143013709,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 18847,
        pop_min: 18847,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 2093967,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [150.14301370916962, -5.550002890439828],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Dubasari",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Dubasari",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Moldova",
        sov_a3: "MDA",
        adm0name: "Moldova",
        adm0_a3: "MDA",
        adm1name: "Transnistria",
        iso_a2: "MD",
        note: null,
        latitude: 47.2630556,
        longitude: 29.1608333,
        changed: 4,
        namediff: 0,
        diffnote:
          "Added missing admin-1 capital round B. Population from GeoNames.",
        pop_max: 23254,
        pop_min: 23254,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 618365,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [29.160833300000036, 47.26305560000014],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 3,
        name: "Qacha's Nek",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Qacha's Nek",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "South Africa",
        sov_a3: "ZAF",
        adm0name: "South Africa",
        adm0_a3: "ZAF",
        adm1name: "Eastern Cape",
        iso_a2: "ZA",
        note: null,
        latitude: -30.1172669153,
        longitude: 28.7019995066,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 25573,
        pop_min: 25573,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 932218,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [28.701999506608843, -30.117266915271443],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Hudur",
        namepar: null,
        namealt: "Xuddur",
        diffascii: 0,
        nameascii: "Xuddur",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Somalia",
        sov_a3: "SOM",
        adm0name: "Somalia",
        adm0_a3: "SOM",
        adm1name: "Bakool",
        iso_a2: "SO",
        note: null,
        latitude: 4.18329897276,
        longitude: 43.866702605,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 1639,
        pop_min: 1639,
        pop_other: 0,
        rank_max: 3,
        rank_min: 3,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [43.86670260501256, 4.183298972756461],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Garbahaarey",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Garbahaarey",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Somalia",
        sov_a3: "SOM",
        adm0name: "Somalia",
        adm0_a3: "SOM",
        adm1name: "Gedo",
        iso_a2: "SO",
        note: null,
        latitude: 3.32700311625,
        longitude: 42.2270016449,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 12652,
        pop_min: 12652,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 58994,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [42.227001644864, 3.3270031162549],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Bu'aale",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Bu'aale",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Somalia",
        sov_a3: "SOM",
        adm0name: "Somalia",
        adm0_a3: "SOM",
        adm1name: "Jubbada Dhexe",
        iso_a2: "SO",
        note: null,
        latitude: 1.0833030174,
        longitude: 42.5833025257,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 1490,
        pop_min: 1490,
        pop_other: 0,
        rank_max: 3,
        rank_min: 3,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [42.58330252569874, 1.083303017399032],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Dhuusa Mareeb",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Dhuusa Mareeb",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Somalia",
        sov_a3: "SOM",
        adm0name: "Somalia",
        adm0_a3: "SOM",
        adm1name: "Galguduud",
        iso_a2: "SO",
        note: null,
        latitude: 5.7419999843,
        longitude: 46.5080044999,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 447,
        pop_min: 447,
        pop_other: 0,
        rank_max: 2,
        rank_min: 2,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [46.508004499917206, 5.741999984298388],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Trincomalee",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Trincomalee",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Sri Lanka",
        sov_a3: "LKA",
        adm0name: "Sri Lanka",
        adm0_a3: "LKA",
        adm1name: "Trincomalee",
        iso_a2: "LK",
        note: null,
        latitude: 8.56899903641,
        longitude: 81.2330015532,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 108420,
        pop_min: 108420,
        pop_other: 0,
        rank_max: 9,
        rank_min: 9,
        geonameid: 1226260,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [81.23300155321004, 8.568999036414937],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Puttalan",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Puttalan",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Sri Lanka",
        sov_a3: "LKA",
        adm0name: "Sri Lanka",
        adm0_a3: "LKA",
        adm1name: "Puttalam",
        iso_a2: "LK",
        note: null,
        latitude: 8.03300408359,
        longitude: 79.8260045421,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 45661,
        pop_min: 45661,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 1229293,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [79.8260045420567, 8.03300408358956],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Ratnapura",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Ratnapura",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Sri Lanka",
        sov_a3: "LKA",
        adm0name: "Sri Lanka",
        adm0_a3: "LKA",
        adm1name: "Ratnapura",
        iso_a2: "LK",
        note: null,
        latitude: 6.69300313187,
        longitude: 80.3860025687,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 47832,
        pop_min: 47832,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 1228730,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [80.38600256868261, 6.693003131866192],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 2,
        name: "Umuahia",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Umuahia",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Nigeria",
        sov_a3: "NGA",
        adm0name: "Nigeria",
        adm0_a3: "NGA",
        adm1name: "Abia",
        iso_a2: "NG",
        note: null,
        latitude: 5.53200304108,
        longitude: 7.48600248721,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 264662,
        pop_min: 264662,
        pop_other: 0,
        rank_max: 10,
        rank_min: 10,
        geonameid: 2320576,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [7.486002487209248, 5.532003041084806],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 2,
        name: "Uyo",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Uyo",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Nigeria",
        sov_a3: "NGA",
        adm0name: "Nigeria",
        adm0_a3: "NGA",
        adm1name: "Akwa Ibom",
        iso_a2: "NG",
        note: null,
        latitude: 5.0079960555,
        longitude: 7.84999852368,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 554906,
        pop_min: 436606,
        pop_other: 0,
        rank_max: 11,
        rank_min: 10,
        geonameid: 2319480,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [7.849998523680938, 5.007996055499859],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 2,
        name: "Owerri",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Owerri",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Nigeria",
        sov_a3: "NGA",
        adm0name: "Nigeria",
        adm0_a3: "NGA",
        adm1name: "Imo",
        iso_a2: "NG",
        note: null,
        latitude: 5.49299705326,
        longitude: 7.0260035881,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 215038,
        pop_min: 215038,
        pop_other: 0,
        rank_max: 10,
        rank_min: 10,
        geonameid: 2325330,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [7.026003588103549, 5.492997053256886],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Sariwon",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Sariwon",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Korea, North",
        sov_a3: "PRK",
        adm0name: "North Korea",
        adm0_a3: "PRK",
        adm1name: "Hwanghae-bukto",
        iso_a2: "KP",
        note: null,
        latitude: 38.5070041099,
        longitude: 125.762004687,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 154942,
        pop_min: 154942,
        pop_other: 0,
        rank_max: 9,
        rank_min: 9,
        geonameid: 1870883,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [125.76200468696481, 38.50700410988418],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Sin-Ni",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Sin-Ni",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Korea, North",
        sov_a3: "PRK",
        adm0name: "North Korea",
        adm0_a3: "PRK",
        adm1name: "P'yongan-namdo",
        iso_a2: "KP",
        note: null,
        latitude: 39.4880041494,
        longitude: 125.463996583,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 19463,
        pop_min: 19463,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 1870413,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [125.46399658347138, 39.4880041493891],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Suchboatar",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Suchboatar",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Mongolia",
        sov_a3: "MNG",
        adm0name: "Mongolia",
        adm0_a3: "MNG",
        adm1name: "Selenge",
        iso_a2: "MN",
        note: null,
        latitude: 50.2499971188,
        longitude: 106.200000624,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 24235,
        pop_min: 24235,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 2029156,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [106.20000062394132, 50.249997118804345],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Dzuunmod",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Dzuunmod",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Mongolia",
        sov_a3: "MNG",
        adm0name: "Mongolia",
        adm0_a3: "MNG",
        adm1name: "Töv",
        iso_a2: "MN",
        note: null,
        latitude: 47.7109980494,
        longitude: 106.947000646,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 17738,
        pop_min: 17738,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 2031517,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [106.94700064643206, 47.71099804943998],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Omaruru",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Omaruru",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Namibia",
        sov_a3: "NAM",
        adm0name: "Namibia",
        adm0_a3: "NAM",
        adm1name: "Erongo",
        iso_a2: "NA",
        note: null,
        latitude: -21.436001931,
        longitude: 15.9509975392,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 11547,
        pop_min: 11547,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 3354540,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [15.95099753919709, -21.43600193100059],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Karibib",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Karibib",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Namibia",
        sov_a3: "NAM",
        adm0name: "Namibia",
        adm0_a3: "NAM",
        adm1name: "Erongo",
        iso_a2: "NA",
        note: null,
        latitude: -21.9390029173,
        longitude: 15.8529964614,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 6898,
        pop_min: 6898,
        pop_other: 0,
        rank_max: 5,
        rank_min: 5,
        geonameid: 3356332,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [15.852996461377984, -21.939002917336893],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Otavi",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Otavi",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Namibia",
        sov_a3: "NAM",
        adm0name: "Namibia",
        adm0_a3: "NAM",
        adm1name: "Otjozondjupa",
        iso_a2: "NA",
        note: null,
        latitude: -19.6399980141,
        longitude: 17.3420015476,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 4562,
        pop_min: 4562,
        pop_other: 0,
        rank_max: 4,
        rank_min: 4,
        geonameid: 3353934,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [17.342001547590087, -19.639998014090395],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Gobabis",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Gobabis",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Namibia",
        sov_a3: "NAM",
        adm0name: "Namibia",
        adm0_a3: "NAM",
        adm1name: "Omaheke",
        iso_a2: "NA",
        note: null,
        latitude: -22.4549999974,
        longitude: 18.9630005375,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 16321,
        pop_min: 16321,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 3357247,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [18.963000537477114, -22.45499999736535],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Wete",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Wete",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "United Republic of Tanzania",
        sov_a3: "TZA",
        adm0name: "Tanzania",
        adm0_a3: "TZA",
        adm1name: "Kaskazini-Pemba",
        iso_a2: "TZ",
        note: null,
        latitude: -5.06346395918,
        longitude: 39.7257990016,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 26450,
        pop_min: 26450,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 148842,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [39.725799001631856, -5.063463959181377],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Kibaha",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Kibaha",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "United Republic of Tanzania",
        sov_a3: "TZA",
        adm0name: "Tanzania",
        adm0_a3: "TZA",
        adm1name: "Pwani",
        iso_a2: "TZ",
        note: null,
        latitude: -6.76670395349,
        longitude: 38.9167026015,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 23651,
        pop_min: 23651,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 158027,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [38.91670260152341, -6.766703953491913],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Mkokotoni",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Mkokotoni",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "United Republic of Tanzania",
        sov_a3: "TZA",
        adm0name: "Tanzania",
        adm0_a3: "TZA",
        adm1name: "Kaskazini-Unguja",
        iso_a2: "TZ",
        note: null,
        latitude: -5.8789979746,
        longitude: 39.259998618,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 2572,
        pop_min: 2572,
        pop_other: 0,
        rank_max: 4,
        rank_min: 4,
        geonameid: 153585,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [39.259998618032114, -5.878997974603521],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Fribourg",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Fribourg",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Switzerland",
        sov_a3: "CHE",
        adm0name: "Switzerland",
        adm0_a3: "CHE",
        adm1name: "Fribourg",
        iso_a2: "CH",
        note: null,
        latitude: 46.80000008,
        longitude: 7.14999652234,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 32827,
        pop_min: 32827,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 2660718,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [7.149996522339677, 46.80000008003458],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Liestal",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Liestal",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Switzerland",
        sov_a3: "CHE",
        adm0name: "Switzerland",
        adm0_a3: "CHE",
        adm1name: "Basel-Landschaft",
        iso_a2: "CH",
        note: null,
        latitude: 47.4830011176,
        longitude: 7.73700346795,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 12832,
        pop_min: 12832,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 2659891,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [7.737003467952093, 47.48300111764712],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Solothurn",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Solothurn",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Switzerland",
        sov_a3: "CHE",
        adm0name: "Switzerland",
        adm0_a3: "CHE",
        adm1name: "Solothurn",
        iso_a2: "CH",
        note: null,
        latitude: 47.2120020986,
        longitude: 7.53699660776,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 14853,
        pop_min: 14853,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 2658564,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [7.536996607761182, 47.21200209862383],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Sarnen",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Sarnen",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Switzerland",
        sov_a3: "CHE",
        adm0name: "Switzerland",
        adm0_a3: "CHE",
        adm1name: "Obwalden",
        iso_a2: "CH",
        note: null,
        latitude: 46.8990000172,
        longitude: 8.24300152884,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 9410,
        pop_min: 9410,
        pop_other: 0,
        rank_max: 5,
        rank_min: 5,
        geonameid: 2658786,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [8.243001528840296, 46.89900001722043],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Mariestad",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Mariestad",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Sweden",
        sov_a3: "SWE",
        adm0name: "Sweden",
        adm0_a3: "SWE",
        adm1name: "Västra Götaland",
        iso_a2: "SE",
        note: null,
        latitude: 58.7050020878,
        longitude: 13.8279965878,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 14891,
        pop_min: 14891,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 2692613,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [13.827996587824487, 58.705002087769515],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Vannersborg",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Vannersborg",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Sweden",
        sov_a3: "SWE",
        adm0name: "Sweden",
        adm0_a3: "SWE",
        adm1name: "Västra Götaland",
        iso_a2: "SE",
        note: null,
        latitude: 58.3630021393,
        longitude: 12.3300006089,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 21835,
        pop_min: 21835,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 2665171,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [12.330000608924081, 58.36300213927973],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 7,
        name: "Appenzell",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Appenzell",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Switzerland",
        sov_a3: "CHE",
        adm0name: "Switzerland",
        adm0_a3: "CHE",
        adm1name: "Appenzell Innerrhoden",
        iso_a2: "CH",
        note: null,
        latitude: 47.3333041031,
        longitude: 9.41670050466,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 5649,
        pop_min: 5649,
        pop_other: 0,
        rank_max: 5,
        rank_min: 5,
        geonameid: 2661740,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [9.41670050466189, 47.33330410307747],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Ros Comain",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Ros Comain",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Ireland",
        sov_a3: "IRL",
        adm0name: "Ireland",
        adm0_a3: "IRL",
        adm1name: "Roscommon",
        iso_a2: "IE",
        note: null,
        latitude: 53.6333333,
        longitude: -8.1833333,
        changed: 4,
        namediff: 0,
        diffnote:
          "Added missing admin-1 capital round B. Population from GeoNames.",
        pop_max: 4860,
        pop_min: 4860,
        pop_other: 0,
        rank_max: 4,
        rank_min: 4,
        geonameid: 2961732,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-8.183333300000015, 53.63333330000012],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Cacheu",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Cacheu",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Guinea Bissau",
        sov_a3: "GNB",
        adm0name: "Guinea Bissau",
        adm0_a3: "GNB",
        adm1name: "Cacheu",
        iso_a2: "GW",
        note: null,
        latitude: 12.2689980311,
        longitude: -16.1649985401,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 10490,
        pop_min: 10490,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 2374311,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-16.164998540115675, 12.26899803107699],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Farim",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Farim",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Guinea Bissau",
        sov_a3: "GNB",
        adm0name: "Guinea Bissau",
        adm0_a3: "GNB",
        adm1name: "Oio",
        iso_a2: "GW",
        note: null,
        latitude: 12.492999029,
        longitude: -15.2270004224,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 6792,
        pop_min: 6792,
        pop_other: 0,
        rank_max: 5,
        rank_min: 5,
        geonameid: 2372647,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-15.227000422357548, 12.492999028950976],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Fulacunda",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Fulacunda",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Guinea Bissau",
        sov_a3: "GNB",
        adm0name: "Guinea Bissau",
        adm0_a3: "GNB",
        adm1name: "Quinara",
        iso_a2: "GW",
        note: null,
        latitude: 11.7729989893,
        longitude: -15.1949965446,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 1311,
        pop_min: 1311,
        pop_other: 0,
        rank_max: 3,
        rank_min: 3,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-15.194996544601622, 11.772998989329096],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Gabu",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Gabu",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Guinea Bissau",
        sov_a3: "GNB",
        adm0name: "Guinea Bissau",
        adm0_a3: "GNB",
        adm1name: "Gabú",
        iso_a2: "GW",
        note: null,
        latitude: 12.2799960751,
        longitude: -14.2340034795,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 14430,
        pop_min: 14430,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 2372532,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-14.234003479494788, 12.279996075067857],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Catio",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Catio",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Guinea Bissau",
        sov_a3: "GNB",
        adm0name: "Guinea Bissau",
        adm0_a3: "GNB",
        adm1name: "Tombali",
        iso_a2: "GW",
        note: null,
        latitude: 11.2167000189,
        longitude: -15.1667004937,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 9898,
        pop_min: 9898,
        pop_other: 0,
        rank_max: 5,
        rank_min: 5,
        geonameid: 2373526,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-15.166700493713734, 11.216700018937445],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Barclayville",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Barclayville",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Liberia",
        sov_a3: "LBR",
        adm0name: "Liberia",
        adm0_a3: "LBR",
        adm1name: "GrandKru",
        iso_a2: "LR",
        note: null,
        latitude: 4.7999969965,
        longitude: -8.16669851802,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 2733,
        pop_min: 2733,
        pop_other: 0,
        rank_max: 4,
        rank_min: 4,
        geonameid: 2278889,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-8.1666985180197, 4.799996996503978],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Voinjama",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Voinjama",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Liberia",
        sov_a3: "LBR",
        adm0name: "Liberia",
        adm0_a3: "LBR",
        adm1name: "Lofa",
        iso_a2: "LR",
        note: null,
        latitude: 8.41670111517,
        longitude: -9.74999652422,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 26594,
        pop_min: 26594,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 2273312,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-9.749996524215192, 8.416701115173566],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Bensonville",
        namepar: "Bentol",
        namealt: null,
        diffascii: 0,
        nameascii: "Bentol",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Liberia",
        sov_a3: "LBR",
        adm0name: "Liberia",
        adm0_a3: "LBR",
        adm1name: "Montserrado",
        iso_a2: "LR",
        note: null,
        latitude: 6.43329903452,
        longitude: -10.6000015194,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 4089,
        pop_min: 4089,
        pop_other: 0,
        rank_max: 4,
        rank_min: 4,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-10.600001519412046, 6.433299034518313],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Kakata",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Kakata",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Liberia",
        sov_a3: "LBR",
        adm0name: "Liberia",
        adm0_a3: "LBR",
        adm1name: "Margibi",
        iso_a2: "LR",
        note: null,
        latitude: 6.5259990088,
        longitude: -10.3490005387,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 33945,
        pop_min: 33945,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 2276086,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-10.349000538669088, 6.525999008798351],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Sanniquellie",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Sanniquellie",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Liberia",
        sov_a3: "LBR",
        adm0name: "Liberia",
        adm0_a3: "LBR",
        adm1name: "Nimba",
        iso_a2: "LR",
        note: null,
        latitude: 7.37100010911,
        longitude: -8.68499946155,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 11415,
        pop_min: 11415,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 2274129,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-8.684999461551683, 7.371000109108024],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Rivercess",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Rivercess",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Liberia",
        sov_a3: "LBR",
        adm0name: "Liberia",
        adm0_a3: "LBR",
        adm1name: "River Cess",
        iso_a2: "LR",
        note: null,
        latitude: 5.46499804554,
        longitude: -9.57799744238,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 2578,
        pop_min: 2578,
        pop_other: 0,
        rank_max: 4,
        rank_min: 4,
        geonameid: 2274288,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-9.577997442377807, 5.464998045537243],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 3,
        name: "Potenza",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Potenza",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Italy",
        sov_a3: "ITA",
        adm0name: "Italy",
        adm0_a3: "ITA",
        adm1name: "Basilicata",
        iso_a2: "IT",
        note: null,
        latitude: 40.6420021301,
        longitude: 15.7989964956,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 69060,
        pop_min: 69060,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 3170027,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 region capital",
      },
      geometry: {
        type: "Point",
        coordinates: [15.798996495640267, 40.642002130098206],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 3,
        name: "Campobasso",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Campobasso",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Italy",
        sov_a3: "ITA",
        adm0name: "Italy",
        adm0_a3: "ITA",
        adm1name: "Molise",
        iso_a2: "IT",
        note: null,
        latitude: 41.5629991186,
        longitude: 14.6559965589,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 50762,
        pop_min: 50762,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 3180991,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 region capital",
      },
      geometry: {
        type: "Point",
        coordinates: [14.655996558921856, 41.56299911864397],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Al Mafraq",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Al Mafraq",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Jordan",
        sov_a3: "JOR",
        adm0name: "Jordan",
        adm0_a3: "JOR",
        adm1name: "Mafraq",
        iso_a2: "JO",
        note: null,
        latitude: 32.2832970669,
        longitude: 36.2332985195,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 57118,
        pop_min: 57118,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 250582,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [36.23329851952707, 32.283297066935575],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "At Tafilah",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "At Tafilah",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Jordan",
        sov_a3: "JOR",
        adm0name: "Jordan",
        adm0_a3: "JOR",
        adm1name: "Tafilah",
        iso_a2: "JO",
        note: null,
        latitude: 30.833334044,
        longitude: 35.600004636,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 25429,
        pop_min: 25429,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 250198,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [35.600004636048595, 30.83333404398934],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Ma'an",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Ma'an",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Jordan",
        sov_a3: "JOR",
        adm0name: "Jordan",
        adm0_a3: "JOR",
        adm1name: "Ma`an",
        iso_a2: "JO",
        note: null,
        latitude: 30.1920031156,
        longitude: 35.7360035752,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 50350,
        pop_min: 50350,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [35.73600357524384, 30.19200311563435],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 3,
        name: "Aosta",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Aosta",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Italy",
        sov_a3: "ITA",
        adm0name: "Italy",
        adm0_a3: "ITA",
        adm1name: "Valle d'Aosta",
        iso_a2: "IT",
        note: null,
        latitude: 45.7370010671,
        longitude: 7.31500259571,
        changed: 4,
        namediff: 1,
        diffnote:
          "Added missing admin-1 capital. Population from GeoNames. Changed feature class.",
        pop_max: 34062,
        pop_min: 34062,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 3182997,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 region capital",
      },
      geometry: {
        type: "Point",
        coordinates: [7.315002595706176, 45.7370010670723],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Diekirch",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Diekirch",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Luxembourg",
        sov_a3: "LUX",
        adm0name: "Luxembourg",
        adm0_a3: "LUX",
        adm1name: "Diekirch",
        iso_a2: "LU",
        note: null,
        latitude: 49.8833010509,
        longitude: 6.16670155545,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 6242,
        pop_min: 6242,
        pop_other: 0,
        rank_max: 5,
        rank_min: 5,
        geonameid: 2960657,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [6.166701555448867, 49.88330105094923],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Grevenmacher",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Grevenmacher",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Luxembourg",
        sov_a3: "LUX",
        adm0name: "Luxembourg",
        adm0_a3: "LUX",
        adm1name: "Grevenmacher",
        iso_a2: "LU",
        note: null,
        latitude: 49.6999981113,
        longitude: 6.33330057452,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 3958,
        pop_min: 3958,
        pop_other: 0,
        rank_max: 4,
        rank_min: 4,
        geonameid: 2960515,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [6.33330057451883, 49.69999811131868],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Kangar",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Kangar",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Malaysia",
        sov_a3: "MYS",
        adm0name: "Malaysia",
        adm0_a3: "MYS",
        adm1name: "Perlis",
        iso_a2: "MY",
        note: null,
        latitude: 6.43300199135,
        longitude: 100.189998682,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 63869,
        pop_min: 63869,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 1736278,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [100.18999868204185, 6.433001991350125],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Kuala Lipis",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Kuala Lipis",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Malaysia",
        sov_a3: "MYS",
        adm0name: "Malaysia",
        adm0_a3: "MYS",
        adm1name: "Pahang",
        iso_a2: "MY",
        note: null,
        latitude: 4.18400111992,
        longitude: 102.042000614,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 15448,
        pop_min: 15448,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 1733023,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [102.0420006143911, 4.184001119922641],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 6,
        name: "Shah Alam",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Shah Alam",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Malaysia",
        sov_a3: "MYS",
        adm0name: "Malaysia",
        adm0_a3: "MYS",
        adm1name: "Selangor",
        iso_a2: "MY",
        note: null,
        latitude: 3.06669599645,
        longitude: 101.549997672,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 481654,
        pop_min: 481654,
        pop_other: 0,
        rank_max: 10,
        rank_min: 10,
        geonameid: 1732903,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [101.54999767204566, 3.066695996452836],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 5,
        name: "Nyeri",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Nyeri",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Kenya",
        sov_a3: "KEN",
        adm0name: "Kenya",
        adm0_a3: "KEN",
        adm1name: "Central",
        iso_a2: "KE",
        note: null,
        latitude: -0.41699699049,
        longitude: 36.9510036318,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 51084,
        pop_min: 51084,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 182701,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [36.95100363176289, -0.416996990488428],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Teyateyaneng",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Teyateyaneng",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Lesotho",
        sov_a3: "LSO",
        adm0name: "Lesotho",
        adm0_a3: "LSO",
        adm1name: "Berea",
        iso_a2: "LS",
        note: null,
        latitude: -29.1529979356,
        longitude: 27.7530035103,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 5115,
        pop_min: 5115,
        pop_other: 0,
        rank_max: 5,
        rank_min: 5,
        geonameid: 932035,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [27.75300351034366, -29.152997935647022],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Mohales Hoek",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Mohales Hoek",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Lesotho",
        sov_a3: "LSO",
        adm0name: "Lesotho",
        adm0_a3: "LSO",
        adm1name: "Mohale's Hoek",
        iso_a2: "LS",
        note: null,
        latitude: -30.1589999083,
        longitude: 27.4799975055,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 24992,
        pop_min: 24992,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 932438,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [27.47999750550133, -30.158999908319572],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Moyeni",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Moyeni",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Lesotho",
        sov_a3: "LSO",
        adm0name: "Lesotho",
        adm0_a3: "LSO",
        adm1name: "Quthing",
        iso_a2: "LS",
        note: null,
        latitude: -30.4109999139,
        longitude: 27.7160045083,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 24130,
        pop_min: 24130,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 932183,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [27.716004508334265, -30.410999913912974],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Hlotse",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Hlotse",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Lesotho",
        sov_a3: "LSO",
        adm0name: "Lesotho",
        adm0_a3: "LSO",
        adm1name: "Leribe",
        iso_a2: "LS",
        note: null,
        latitude: -28.8780029827,
        longitude: 28.0559976365,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 47675,
        pop_min: 47675,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 932698,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [28.055997636489053, -28.878002982704743],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Butha-Buthe",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Butha-Buthe",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Lesotho",
        sov_a3: "LSO",
        adm0name: "Lesotho",
        adm0_a3: "LSO",
        adm1name: "Leribe",
        iso_a2: "LS",
        note: null,
        latitude: -28.7499959113,
        longitude: 28.2500015769,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 16330,
        pop_min: 16330,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 932886,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [28.25000157694211, -28.749995911347355],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Mokhotlong",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Mokhotlong",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Lesotho",
        sov_a3: "LSO",
        adm0name: "Lesotho",
        adm0_a3: "LSO",
        adm1name: "Mokhotlong",
        iso_a2: "LS",
        note: null,
        latitude: -29.2910040261,
        longitude: 29.078001548,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 8809,
        pop_min: 8809,
        pop_other: 0,
        rank_max: 5,
        rank_min: 5,
        geonameid: 932416,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [29.07800154803965, -29.29100402614472],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Ahuachapan",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Ahuachapan",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "El Salvador",
        sov_a3: "SLV",
        adm0name: "El Salvador",
        adm0_a3: "SLV",
        adm1name: "Ahuachapán",
        iso_a2: "SV",
        note: null,
        latitude: 13.9190039897,
        longitude: -89.8450015467,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 34102,
        pop_min: 34102,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 3587426,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-89.84500154668643, 13.919003989651856],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Cojutepeque",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Cojutepeque",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "El Salvador",
        sov_a3: "SLV",
        adm0name: "El Salvador",
        adm0_a3: "SLV",
        adm1name: "Cuscatlán",
        iso_a2: "SV",
        note: null,
        latitude: 13.7167020366,
        longitude: -88.9333015956,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 48411,
        pop_min: 48411,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 3586977,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-88.9333015955986, 13.716702036591414],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Nueva San Salvador",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Nueva San Salvador",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "El Salvador",
        sov_a3: "SLV",
        adm0name: "El Salvador",
        adm0_a3: "SLV",
        adm1name: "La Libertad",
        iso_a2: "SV",
        note: null,
        latitude: 13.6739969925,
        longitude: -89.2899984788,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 124694,
        pop_min: 124694,
        pop_other: 0,
        rank_max: 9,
        rank_min: 9,
        geonameid: 3584257,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-89.28999847882994, 13.673996992529169],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Zacatecoluca",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Zacatecoluca",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "El Salvador",
        sov_a3: "SLV",
        adm0name: "El Salvador",
        adm0_a3: "SLV",
        adm1name: "La Paz",
        iso_a2: "SV",
        note: null,
        latitude: 13.5080009959,
        longitude: -88.8679976066,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 39613,
        pop_min: 39613,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 3582805,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-88.86799760658403, 13.508000995913164],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "La Union",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "La Union",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "El Salvador",
        sov_a3: "SLV",
        adm0name: "El Salvador",
        adm0_a3: "SLV",
        adm1name: "La Unión",
        iso_a2: "SV",
        note: null,
        latitude: 13.331997044,
        longitude: -87.8390005211,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 26807,
        pop_min: 26807,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 3584772,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-87.83900052107902, 13.331997044039213],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "San Francisco Gotera",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "San Francisco Gotera",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "El Salvador",
        sov_a3: "SLV",
        adm0name: "El Salvador",
        adm0_a3: "SLV",
        adm1name: "Morazán",
        iso_a2: "SV",
        note: null,
        latitude: 13.699998116,
        longitude: -88.100000521,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 16152,
        pop_min: 16152,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 3583747,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-88.10000052096211, 13.699998116031054],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "San Vicente",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "San Vicente",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "El Salvador",
        sov_a3: "SLV",
        adm0name: "El Salvador",
        adm0_a3: "SLV",
        adm1name: "San Vicente",
        iso_a2: "SV",
        note: null,
        latitude: 13.641003026,
        longitude: -88.7849996083,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 37326,
        pop_min: 37326,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 3583178,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-88.78499960827611, 13.641003026040096],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Usulutan",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Usulutan",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "El Salvador",
        sov_a3: "SLV",
        adm0name: "El Salvador",
        adm0_a3: "SLV",
        adm1name: "Usulután",
        iso_a2: "SV",
        note: null,
        latitude: 13.3460010987,
        longitude: -88.4320016158,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 51910,
        pop_min: 51910,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 3582883,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-88.43200161579529, 13.346001098699674],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Chalatenango",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Chalatenango",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "El Salvador",
        sov_a3: "SLV",
        adm0name: "El Salvador",
        adm0_a3: "SLV",
        adm1name: "Chalatenango",
        iso_a2: "SV",
        note: null,
        latitude: 14.0720040581,
        longitude: -89.0939964887,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 29271,
        pop_min: 29271,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-89.09399648867554, 14.072004058059235],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Lucea",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Lucea",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Jamaica",
        sov_a3: "JAM",
        adm0name: "Jamaica",
        adm0_a3: "JAM",
        adm1name: "Hanover",
        iso_a2: "JM",
        note: null,
        latitude: 18.4429980854,
        longitude: -78.1790036163,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 6289,
        pop_min: 6289,
        pop_other: 0,
        rank_max: 5,
        rank_min: 5,
        geonameid: 3489657,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-78.17900361633639, 18.44299808537494],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Mandeville",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Mandeville",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Jamaica",
        sov_a3: "JAM",
        adm0name: "Jamaica",
        adm0_a3: "JAM",
        adm1name: "Manchester",
        iso_a2: "JM",
        note: null,
        latitude: 18.0330030526,
        longitude: -77.4999985126,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 47115,
        pop_min: 47115,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 3489577,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-77.4999985126426, 18.033003052604442],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Black River",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Black River",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Jamaica",
        sov_a3: "JAM",
        adm0name: "Jamaica",
        adm0_a3: "JAM",
        adm1name: "Saint Elizabeth",
        iso_a2: "JM",
        note: null,
        latitude: 18.0309960668,
        longitude: -77.8519974803,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 4229,
        pop_min: 4229,
        pop_other: 0,
        rank_max: 4,
        rank_min: 4,
        geonameid: 3491355,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-77.85199748027264, 18.03099606678569],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Falmouth",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Falmouth",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Jamaica",
        sov_a3: "JAM",
        adm0name: "Jamaica",
        adm0_a3: "JAM",
        adm1name: "Trelawny",
        iso_a2: "JM",
        note: null,
        latitude: 18.4795830473,
        longitude: -77.6560496033,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 7779,
        pop_min: 7779,
        pop_other: 0,
        rank_max: 5,
        rank_min: 5,
        geonameid: 3490520,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-77.65604960327488, 18.479583047268818],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Savanna-la-Mar",
        namepar: null,
        namealt: "Sav-la-Mar, Savanna La Mar",
        diffascii: 0,
        nameascii: "Savanna La Mar",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Jamaica",
        sov_a3: "JAM",
        adm0name: "Jamaica",
        adm0_a3: "JAM",
        adm1name: "Westmoreland",
        iso_a2: "JM",
        note: null,
        latitude: 18.1639980969,
        longitude: -77.9480005084,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 30000,
        pop_min: 20521,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-77.94800050839041, 18.16399809691262],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Port Antonio",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Port Antonio",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Jamaica",
        sov_a3: "JAM",
        adm0name: "Jamaica",
        adm0_a3: "JAM",
        adm1name: "Portland",
        iso_a2: "JM",
        note: null,
        latitude: 18.1590031381,
        longitude: -76.3800026249,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 14400,
        pop_min: 14400,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 3489005,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-76.38000262487424, 18.15900313814302],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "St. Ann's Bay",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "St. Anns Bay",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Jamaica",
        sov_a3: "JAM",
        adm0name: "Jamaica",
        adm0_a3: "JAM",
        adm1name: "Saint Ann",
        iso_a2: "JM",
        note: null,
        latitude: 18.4326391393,
        longitude: -77.1995245371,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 13671,
        pop_min: 13671,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-77.19952453714319, 18.432639139275977],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Port Maria",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Port Maria",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Jamaica",
        sov_a3: "JAM",
        adm0name: "Jamaica",
        adm0_a3: "JAM",
        adm1name: "Saint Mary",
        iso_a2: "JM",
        note: null,
        latitude: 18.3770010508,
        longitude: -76.8999954733,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 7906,
        pop_min: 7906,
        pop_other: 0,
        rank_max: 5,
        rank_min: 5,
        geonameid: 3488985,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-76.89999547333792, 18.37700105079557],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Half Way Tree",
        namepar: null,
        namealt: "Halfway Tree",
        diffascii: 0,
        nameascii: "Halfway Tree",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Jamaica",
        sov_a3: "JAM",
        adm0name: "Jamaica",
        adm0_a3: "JAM",
        adm1name: "Saint Andrew",
        iso_a2: "JM",
        note: null,
        latitude: 18.0333000958,
        longitude: -76.7999965113,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 96494,
        pop_min: 96494,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-76.79999651130129, 18.03330009577286],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Port Morant",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Port Morant",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Jamaica",
        sov_a3: "JAM",
        adm0name: "Jamaica",
        adm0_a3: "JAM",
        adm1name: "Saint Thomas",
        iso_a2: "JM",
        note: null,
        latitude: 17.8910010282,
        longitude: -76.3289995682,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 11536,
        pop_min: 11536,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-76.32899956820472, 17.891001028187986],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Sabaneta",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Sabaneta",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Dominican Republic",
        sov_a3: "DOM",
        adm0name: "Dominican Republic",
        adm0_a3: "DOM",
        adm1name: "Santiago Rodríguez",
        iso_a2: "DO",
        note: null,
        latitude: 19.5049980735,
        longitude: -71.3449885357,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 16380,
        pop_min: 16380,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 3493383,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-71.34498853565697, 19.504998073486604],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Mao",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Mao",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Dominican Republic",
        sov_a3: "DOM",
        adm0name: "Dominican Republic",
        adm0_a3: "DOM",
        adm1name: "Valverde",
        iso_a2: "DO",
        note: null,
        latitude: 19.5519960946,
        longitude: -71.0749974776,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 48297,
        pop_min: 48297,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 3496831,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-71.07499747760187, 19.55199609463608],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Cotui",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Cotui",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Dominican Republic",
        sov_a3: "DOM",
        adm0name: "Dominican Republic",
        adm0_a3: "DOM",
        adm1name: "Sánchez Ramírez",
        iso_a2: "DO",
        note: null,
        latitude: 19.0590030636,
        longitude: -70.1520026048,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 41641,
        pop_min: 41641,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 3509207,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-70.15200260483857, 19.059003063557554],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Evinayong",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Evinayong",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Equatorial Guinea",
        sov_a3: "GNQ",
        adm0name: "Equatorial Guinea",
        adm0_a3: "GNQ",
        adm1name: "Centro Sur",
        iso_a2: "GQ",
        note: null,
        latitude: 1.44999908525,
        longitude: 10.5667025515,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 8462,
        pop_min: 8462,
        pop_other: 0,
        rank_max: 5,
        rank_min: 5,
        geonameid: 2308994,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [10.566702551452181, 1.449999085254319],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Luba",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Luba",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Equatorial Guinea",
        sov_a3: "GNQ",
        adm0name: "Equatorial Guinea",
        adm0_a3: "GNQ",
        adm1name: "Bioko Sur",
        iso_a2: "GQ",
        note: null,
        latitude: 3.44999702564,
        longitude: 8.55000052502,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 8655,
        pop_min: 8655,
        pop_other: 0,
        rank_max: 5,
        rank_min: 5,
        geonameid: 2309528,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [8.550000525022256, 3.4499970256403],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Sibenik",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Sibenik",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Croatia",
        sov_a3: "HRV",
        adm0name: "Croatia",
        adm0_a3: "HRV",
        adm1name: "ibensko-Kninska",
        iso_a2: "HR",
        note: null,
        latitude: 43.7272222,
        longitude: 15.9058333,
        changed: 4,
        namediff: 0,
        diffnote:
          "Added missing admin-1 capital round B. Population from GeoNames.",
        pop_max: 37112,
        pop_min: 37112,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 3190941,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [15.905833299999983, 43.72722219999997],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 3,
        name: "Shibin el Kom",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Shibin el Kom",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Egypt",
        sov_a3: "EGY",
        adm0name: "Egypt",
        adm0_a3: "EGY",
        adm1name: "Al Minufiyah",
        iso_a2: "EG",
        note: null,
        latitude: 30.5919991293,
        longitude: 30.8999974869,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 182900,
        pop_min: 182900,
        pop_other: 0,
        rank_max: 9,
        rank_min: 9,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [30.89999748685051, 30.59199912926448],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 3,
        name: "Benha",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Benha",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Egypt",
        sov_a3: "EGY",
        adm0name: "Egypt",
        adm0_a3: "EGY",
        adm1name: "Al Qalyubiyah",
        iso_a2: "EG",
        note: null,
        latitude: 30.4666651155,
        longitude: 31.1833356294,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 167029,
        pop_min: 167029,
        pop_other: 0,
        rank_max: 9,
        rank_min: 9,
        geonameid: 359280,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [31.183335629439057, 30.46666511545419],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 3,
        name: "Zagazig",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Zagazig",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Egypt",
        sov_a3: "EGY",
        adm0name: "Egypt",
        adm0_a3: "EGY",
        adm1name: "Ash Sharqiyah",
        iso_a2: "EG",
        note: null,
        latitude: 30.5833320881,
        longitude: 31.5166596006,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 285097,
        pop_min: 285097,
        pop_other: 0,
        rank_max: 10,
        rank_min: 10,
        geonameid: 359493,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [31.516659600643948, 30.583332088097222],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 3,
        name: "Kafr el Sheikh",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Kafr el Sheikh",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Egypt",
        sov_a3: "EGY",
        adm0name: "Egypt",
        adm0_a3: "EGY",
        adm1name: "Kafr ash Shaykh",
        iso_a2: "EG",
        note: null,
        latitude: 31.1090040048,
        longitude: 30.9359976295,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 143970,
        pop_min: 143970,
        pop_other: 0,
        rank_max: 9,
        rank_min: 9,
        geonameid: 354502,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [30.93599762949259, 31.109004004777432],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Dikhil",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Dikhil",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Djibouti",
        sov_a3: "DJI",
        adm0name: "Djibouti",
        adm0_a3: "DJI",
        adm1name: "Dikhil",
        iso_a2: "DJ",
        note: null,
        latitude: 11.1040020064,
        longitude: 42.3720005783,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 12043,
        pop_min: 12043,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 223890,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [42.372000578348775, 11.104002006376959],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Tadjoura",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Tadjoura",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Djibouti",
        sov_a3: "DJI",
        adm0name: "Djibouti",
        adm0_a3: "DJI",
        adm1name: "Tadjourah",
        iso_a2: "DJ",
        note: null,
        latitude: 11.7833039878,
        longitude: 42.9000034979,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 22193,
        pop_min: 22193,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 220782,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [42.90000349785265, 11.783303987755232],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Haapsalu",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Haapsalu",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Estonia",
        sov_a3: "EST",
        adm0name: "Estonia",
        adm0_a3: "EST",
        adm1name: "Lääne",
        iso_a2: "EE",
        note: null,
        latitude: 58.9430556,
        longitude: 23.5413889,
        changed: 4,
        namediff: 0,
        diffnote:
          "Added missing admin-1 capital round B. Population from GeoNames.",
        pop_max: 11805,
        pop_min: 11805,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 592225,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [23.54138890000013, 58.94305560000009],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Hawalli",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Hawalli",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Kuwait",
        sov_a3: "KWT",
        adm0name: "Kuwait",
        adm0_a3: "KWT",
        adm1name: "Hawalli",
        iso_a2: "KW",
        note: null,
        latitude: 29.3333400154,
        longitude: 47.9999975591,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 164212,
        pop_min: 164212,
        pop_other: 0,
        rank_max: 9,
        rank_min: 9,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [47.999997559079816, 29.333340015387762],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 3,
        name: "Buluko",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Buluko",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Congo (Kinshasa)",
        sov_a3: "COD",
        adm0name: "Congo (Kinshasa)",
        adm0_a3: "COD",
        adm1name: "Nord-Kivu",
        iso_a2: "CD",
        note: null,
        latitude: -0.75699888928,
        longitude: 28.5280025406,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 1192,
        pop_min: 1192,
        pop_other: 0,
        rank_max: 3,
        rank_min: 3,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [28.52800254055387, -0.756998889276929],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Mzimba",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Mzimba",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Malawi",
        sov_a3: "MWI",
        adm0name: "Malawi",
        adm0_a3: "MWI",
        adm1name: "Mzimba",
        iso_a2: "MW",
        note: null,
        latitude: -11.9,
        longitude: 33.6,
        changed: 4,
        namediff: 0,
        diffnote:
          "Added missing admin-1 capital round B. Population from GeoNames.",
        pop_max: 19308,
        pop_min: 19308,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 925498,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [33.600000000000136, -11.9],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Machinga",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Machinga",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Malawi",
        sov_a3: "MWI",
        adm0name: "Malawi",
        adm0_a3: "MWI",
        adm1name: "Machinga",
        iso_a2: "MW",
        note: null,
        latitude: -14.9666667,
        longitude: 35.5166667,
        changed: 4,
        namediff: 0,
        diffnote:
          "Added missing admin-1 capital round B. Population from GeoNames.",
        pop_max: 1418,
        pop_min: 1418,
        pop_other: 0,
        rank_max: 3,
        rank_min: 3,
        geonameid: 927643,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [35.51666669999997, -14.966666699999962],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Dedza",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Dedza",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Malawi",
        sov_a3: "MWI",
        adm0name: "Malawi",
        adm0_a3: "MWI",
        adm1name: "Dedza",
        iso_a2: "MW",
        note: null,
        latitude: -14.3666667,
        longitude: 34.3333333,
        changed: 4,
        namediff: 0,
        diffnote:
          "Added missing admin-1 capital round B. Population from GeoNames.",
        pop_max: 15608,
        pop_min: 15608,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 930025,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [34.333333299999936, -14.36666669999994],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Mchinji",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Mchinji",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Malawi",
        sov_a3: "MWI",
        adm0name: "Malawi",
        adm0_a3: "MWI",
        adm1name: "Mchinji",
        iso_a2: "MW",
        note: null,
        latitude: -13.8,
        longitude: 32.9,
        changed: 4,
        namediff: 0,
        diffnote:
          "Added missing admin-1 capital round B. Population from GeoNames.",
        pop_max: 18305,
        pop_min: 18305,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 926747,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [32.9, -13.8],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Ntcheu",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Ntcheu",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Malawi",
        sov_a3: "MWI",
        adm0name: "Malawi",
        adm0_a3: "MWI",
        adm1name: "Ntcheu",
        iso_a2: "MW",
        note: null,
        latitude: -14.8166667,
        longitude: 34.6333333,
        changed: 4,
        namediff: 0,
        diffnote:
          "Added missing admin-1 capital round B. Population from GeoNames.",
        pop_max: 10445,
        pop_min: 10445,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 924492,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [34.63333329999995, -14.816666699999985],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Chiradzulu",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Chiradzulu",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Malawi",
        sov_a3: "MWI",
        adm0name: "Malawi",
        adm0_a3: "MWI",
        adm1name: "Chiradzulu",
        iso_a2: "MW",
        note: null,
        latitude: -15.7,
        longitude: 35.1833333,
        changed: 4,
        namediff: 0,
        diffnote:
          "Added missing admin-1 capital round B. Population from GeoNames.",
        pop_max: 1580,
        pop_min: 1580,
        pop_other: 0,
        rank_max: 3,
        rank_min: 3,
        geonameid: 930580,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [35.18333330000013, -15.7],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Nsanje",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Nsanje",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Malawi",
        sov_a3: "MWI",
        adm0name: "Malawi",
        adm0_a3: "MWI",
        adm1name: "Nsanje",
        iso_a2: "MW",
        note: null,
        latitude: -16.9166667,
        longitude: 35.2666667,
        changed: 4,
        namediff: 0,
        diffnote:
          "Added missing admin-1 capital round B. Population from GeoNames.",
        pop_max: 21774,
        pop_min: 21774,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 924572,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [35.26666670000003, -16.91666669999995],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Mwanza",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Mwanza",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Malawi",
        sov_a3: "MWI",
        adm0name: "Malawi",
        adm0_a3: "MWI",
        adm1name: "Mwanza",
        iso_a2: "MW",
        note: null,
        latitude: -15.6166667,
        longitude: 34.5166667,
        changed: 4,
        namediff: 0,
        diffnote:
          "Added missing admin-1 capital round B. Population from GeoNames.",
        pop_max: 11379,
        pop_min: 11379,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 925596,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [34.51666670000003, -15.6166667],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Mulanje",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Mulanje",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Malawi",
        sov_a3: "MWI",
        adm0name: "Malawi",
        adm0_a3: "MWI",
        adm1name: "Mulanje",
        iso_a2: "MW",
        note: null,
        latitude: -16.0333333,
        longitude: 35.5,
        changed: 4,
        namediff: 0,
        diffnote:
          "Added missing admin-1 capital round B. Population from GeoNames.",
        pop_max: 16483,
        pop_min: 16483,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 925789,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [35.5, -16.033333299999924],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Salama",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Salama",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Guatemala",
        sov_a3: "GTM",
        adm0name: "Guatemala",
        adm0_a3: "GTM",
        adm1name: "Baja Verapaz",
        iso_a2: "GT",
        note: null,
        latitude: 15.1029990278,
        longitude: -90.3140006056,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 40000,
        pop_min: 40000,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-90.31400060556541, 15.102999027781948],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Retalhuleu",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Retalhuleu",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Guatemala",
        sov_a3: "GTM",
        adm0name: "Guatemala",
        adm0_a3: "GTM",
        adm1name: "Retalhuleu",
        iso_a2: "GT",
        note: null,
        latitude: 14.5370970406,
        longitude: -91.6770145424,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 36656,
        pop_min: 36656,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 3590858,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-91.6770145423564, 14.537097040646358],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "San Marcos",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "San Marcos",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Guatemala",
        sov_a3: "GTM",
        adm0name: "Guatemala",
        adm0_a3: "GTM",
        adm1name: "San Marcos",
        iso_a2: "GT",
        note: null,
        latitude: 14.9660010637,
        longitude: -91.7999995156,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 25088,
        pop_min: 25088,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 3589805,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-91.79999951562428, 14.966001063736144],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Chimaltenango",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Chimaltenango",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Guatemala",
        sov_a3: "GTM",
        adm0name: "Guatemala",
        adm0_a3: "GTM",
        adm1name: "Chimaltenango",
        iso_a2: "GT",
        note: null,
        latitude: 14.6619991421,
        longitude: -90.819998501,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 82370,
        pop_min: 82370,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 3598572,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-90.81999850096992, 14.66199914210614],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Antigua Guatemala",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Antigua Guatemala",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Guatemala",
        sov_a3: "GTM",
        adm0name: "Guatemala",
        adm0_a3: "GTM",
        adm1name: "Sacatepéquez",
        iso_a2: "GT",
        note: null,
        latitude: 14.5666980957,
        longitude: -90.7333016119,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 39368,
        pop_min: 39368,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 3599699,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-90.73330161191143, 14.566698095670745],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Solola",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Solola",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Guatemala",
        sov_a3: "GTM",
        adm0name: "Guatemala",
        adm0_a3: "GTM",
        adm1name: "Sololá",
        iso_a2: "GT",
        note: null,
        latitude: 14.7729959827,
        longitude: -91.1829955126,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 45373,
        pop_min: 45373,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 3588698,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-91.18299551259099, 14.772995982650002],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Totonicapan",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Totonicapan",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Guatemala",
        sov_a3: "GTM",
        adm0name: "Guatemala",
        adm0_a3: "GTM",
        adm1name: "Totonicapán",
        iso_a2: "GT",
        note: null,
        latitude: 14.9139989822,
        longitude: -91.3580006051,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 69734,
        pop_min: 69734,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 3588258,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-91.35800060509786, 14.913998982215958],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "El Progreso",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "El Progreso",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Guatemala",
        sov_a3: "GTM",
        adm0name: "Guatemala",
        adm0_a3: "GTM",
        adm1name: "El Progreso",
        iso_a2: "GT",
        note: null,
        latitude: 14.8499999973,
        longitude: -90.0167035854,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 147197,
        pop_min: 147197,
        pop_other: 0,
        rank_max: 9,
        rank_min: 9,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-90.01670358535551, 14.849999997337704],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Cuilapa",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Cuilapa",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Guatemala",
        sov_a3: "GTM",
        adm0name: "Guatemala",
        adm0_a3: "GTM",
        adm1name: "Santa Rosa",
        iso_a2: "GT",
        note: null,
        latitude: 14.2790040922,
        longitude: -90.2979985012,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 16484,
        pop_min: 16484,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 3597804,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-90.29799850120372, 14.279004092204502],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Chiquimula",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Chiquimula",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Guatemala",
        sov_a3: "GTM",
        adm0name: "Guatemala",
        adm0_a3: "GTM",
        adm1name: "Chiquimula",
        iso_a2: "GT",
        note: null,
        latitude: 14.7969990565,
        longitude: -89.5439965341,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 41521,
        pop_min: 41521,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 3598465,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-89.54399653412463, 14.796999056450602],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Jalapa",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Jalapa",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Guatemala",
        sov_a3: "GTM",
        adm0name: "Guatemala",
        adm0_a3: "GTM",
        adm1name: "Jalapa",
        iso_a2: "GT",
        note: null,
        latitude: 14.6330011095,
        longitude: -89.9890016208,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 45834,
        pop_min: 45834,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 3595237,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-89.98900162080429, 14.63300110953594],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Zacapa",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Zacapa",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Guatemala",
        sov_a3: "GTM",
        adm0name: "Guatemala",
        adm0_a3: "GTM",
        adm1name: "Zacapa",
        iso_a2: "GT",
        note: null,
        latitude: 14.9720039835,
        longitude: -89.5290025562,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 36088,
        pop_min: 36088,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 3587587,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-89.52900255621495, 14.972003983473769],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Puerto Plata",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Puerto Plata",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Dominican Republic",
        sov_a3: "DOM",
        adm0name: "Dominican Republic",
        adm0_a3: "DOM",
        adm1name: "Puerto Plata",
        iso_a2: "DO",
        note: null,
        latitude: 19.7902171033,
        longitude: -70.6902474735,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 119897,
        pop_min: 119897,
        pop_other: 0,
        rank_max: 9,
        rank_min: 9,
        geonameid: 3493175,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-70.69024747349448, 19.790217103345583],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Dajabon",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Dajabon",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Dominican Republic",
        sov_a3: "DOM",
        adm0name: "Dominican Republic",
        adm0_a3: "DOM",
        adm1name: "Dajabón",
        iso_a2: "DO",
        note: null,
        latitude: 19.5479999952,
        longitude: -71.7049975743,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 16398,
        pop_min: 16398,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 3508952,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-71.70499757432736, 19.547999995233397],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Moca",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Moca",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Dominican Republic",
        sov_a3: "DOM",
        adm0name: "Dominican Republic",
        adm0_a3: "DOM",
        adm1name: "Espaillat",
        iso_a2: "DO",
        note: null,
        latitude: 19.396998142,
        longitude: -70.5230005859,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 61834,
        pop_min: 61834,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 3496331,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-70.52300058589856, 19.396998142011],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Salcedo",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Salcedo",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Dominican Republic",
        sov_a3: "DOM",
        adm0name: "Dominican Republic",
        adm0_a3: "DOM",
        adm1name: "Hermanas",
        iso_a2: "DO",
        note: null,
        latitude: 19.3830030235,
        longitude: -70.4167014955,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 45299,
        pop_min: 45299,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-70.41670149547224, 19.383003023468177],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Jimani",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Jimani",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Dominican Republic",
        sov_a3: "DOM",
        adm0name: "Dominican Republic",
        adm0_a3: "DOM",
        adm1name: "Independencia",
        iso_a2: "DO",
        note: null,
        latitude: 18.4930021172,
        longitude: -71.8509955327,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 6567,
        pop_min: 6567,
        pop_other: 0,
        rank_max: 5,
        rank_min: 5,
        geonameid: 3504077,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-71.85099553266275, 18.493002117193953],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Comendador",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Elias Pina",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Dominican Republic",
        sov_a3: "DOM",
        adm0name: "Dominican Republic",
        adm0_a3: "DOM",
        adm1name: "Elias Pina",
        iso_a2: "DO",
        note: null,
        latitude: 18.8759970016,
        longitude: -71.7069954585,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital.",
        pop_max: 43894,
        pop_min: 27908,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: -1,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-71.7069954585449, 18.875997001611722],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Pedernales",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Pedernales",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Dominican Republic",
        sov_a3: "DOM",
        adm0name: "Dominican Republic",
        adm0_a3: "DOM",
        adm1name: "Pedernales",
        iso_a2: "DO",
        note: null,
        latitude: 18.0379980114,
        longitude: -71.7409975515,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 11072,
        pop_min: 11072,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 3495137,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-71.7409975514858, 18.0379980113741],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Azua",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Azua",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Dominican Republic",
        sov_a3: "DOM",
        adm0name: "Dominican Republic",
        adm0_a3: "DOM",
        adm1name: "Azua",
        iso_a2: "DO",
        note: null,
        latitude: 18.4539961294,
        longitude: -70.7290015952,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 59139,
        pop_min: 59139,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 3512208,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-70.72900159519315, 18.453996129365805],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Bonao",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Bonao",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Dominican Republic",
        sov_a3: "DOM",
        adm0name: "Dominican Republic",
        adm0_a3: "DOM",
        adm1name: "Monseñor Nouel",
        iso_a2: "DO",
        note: null,
        latitude: 18.9420031378,
        longitude: -70.4089975692,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 73269,
        pop_min: 73269,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 3511233,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-70.40899756920146, 18.94200313779237],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Bani",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Bani",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Dominican Republic",
        sov_a3: "DOM",
        adm0name: "Dominican Republic",
        adm0_a3: "DOM",
        adm1name: "Peravia",
        iso_a2: "DO",
        note: null,
        latitude: 18.2799989978,
        longitude: -70.3310034658,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 66709,
        pop_min: 66709,
        pop_other: 0,
        rank_max: 8,
        rank_min: 8,
        geonameid: 3512067,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-70.33100346578061, 18.279998997827192],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Hato Mayor",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Hato Mayor",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Dominican Republic",
        sov_a3: "DOM",
        adm0name: "Dominican Republic",
        adm0_a3: "DOM",
        adm1name: "Hato Mayor",
        iso_a2: "DO",
        note: null,
        latitude: 18.7640011362,
        longitude: -69.2569974727,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 35999,
        pop_min: 35999,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 3504765,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-69.25699747270988, 18.764001136217246],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Monte Plata",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Monte Plata",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Dominican Republic",
        sov_a3: "DOM",
        adm0name: "Dominican Republic",
        adm0_a3: "DOM",
        adm1name: "Monte Plata",
        iso_a2: "DO",
        note: null,
        latitude: 18.807003058,
        longitude: -69.7840015328,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 15532,
        pop_min: 15532,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 3496134,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-69.78400153284673, 18.807003057964096],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Nagua",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Nagua",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Dominican Republic",
        sov_a3: "DOM",
        adm0name: "Dominican Republic",
        adm0_a3: "DOM",
        adm1name: "María Trinidad Sánchez",
        iso_a2: "DO",
        note: null,
        latitude: 19.3760010789,
        longitude: -69.8470014929,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 33862,
        pop_min: 33862,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 3496021,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-69.8470014928742, 19.376001078879767],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Samana",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Samana",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Dominican Republic",
        sov_a3: "DOM",
        adm0name: "Dominican Republic",
        adm0_a3: "DOM",
        adm1name: "Samaná",
        iso_a2: "DO",
        note: null,
        latitude: 19.212003132,
        longitude: -69.3320036032,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 11432,
        pop_min: 11432,
        pop_other: 0,
        rank_max: 6,
        rank_min: 6,
        geonameid: 3492997,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-69.33200360318011, 19.212003131965105],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "San Cristobal",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "San Cristobal",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Dominican Republic",
        sov_a3: "DOM",
        adm0name: "Dominican Republic",
        adm0_a3: "DOM",
        adm1name: "San Cristóbal",
        iso_a2: "DO",
        note: null,
        latitude: 18.4159981025,
        longitude: -70.1090005176,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 154040,
        pop_min: 154040,
        pop_other: 0,
        rank_max: 9,
        rank_min: 9,
        geonameid: 3511540,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-70.10900051760797, 18.41599810250608],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "El Seibo",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "El Seibo",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Dominican Republic",
        sov_a3: "DOM",
        adm0name: "Dominican Republic",
        adm0_a3: "DOM",
        adm1name: "El Seybo",
        iso_a2: "DO",
        note: null,
        latitude: 18.7640011362,
        longitude: -69.0350034607,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 23547,
        pop_min: 23547,
        pop_other: 0,
        rank_max: 7,
        rank_min: 7,
        geonameid: 3492984,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-69.03500346065482, 18.764001136217246],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 10,
        natscale: 1,
        labelrank: 8,
        name: "Higuey",
        namepar: null,
        namealt: null,
        diffascii: 0,
        nameascii: "Higuey",
        adm0cap: 0,
        capalt: 0,
        capin: null,
        worldcity: 0,
        megacity: 0,
        sov0name: "Dominican Republic",
        sov_a3: "DOM",
        adm0name: "Dominican Republic",
        adm0_a3: "DOM",
        adm1name: "La Altagracia",
        iso_a2: "DO",
        note: null,
        latitude: 18.6159960266,
        longitude: -68.7079974901,
        changed: 4,
        namediff: 1,
        diffnote: "Added missing admin-1 capital. Population from GeoNames.",
        pop_max: 123787,
        pop_min: 123787,
        pop_other: 0,
        rank_max: 9,
        rank_min: 9,
        geonameid: 3493240,
        meganame: null,
        ls_name: null,
        ls_match: 0,
        checkme: 0,
        featureclass: "Admin-1 capital",
      },
      geometry: {
        type: "Point",
        coordinates: [-68.70799749007472, 18.615996026579296],
      },
    },
  ],
};
