import React, { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux'
import { FeatureGroup, MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import { Link } from "react-router-dom";
import { getOrgLocations } from "features/orgLocations/orgLocationsSlice";
import { getOrgFarmersMarkets } from "features/farmersMarkets/orgFarmersMarketsSlice";
import Spinner from '../../components/Spinner'
import {Status} from "../../../types/slice";
import { cities } from "data/cities";
import "leaflet/dist/leaflet.css";
import LeafletControlGeocoder from "./LeafletControlGeocoder";
import L from "leaflet";
// Searches
import SearchBox from '../Map/SearchBox-1'
import SearchableMap from '../Map/SearchableMap-1'

const onClick = () => {
  console.log('Pop Up Button Hit' )
}

const DEFAULT_RADIUS = 80

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png",
});

const CustomPopUp = ({data, setRadiusFilter}) => {

  const {listing_name, location_address, specialproductionmethods, listing_id} = data.properties

  return(
    <>
    <Popup className="">
        <div className=" flex flex-col py-3 px-2 rounded-md text-gray-500 text-center justify-center" >
          <div className="flex flex-col text-start">
            <b className="text-lg font-bold">Name: </b>
            <b className="text-xl font-light">{`${listing_name}`}</b>
          </div>
          <div className="flex flex-col text-start pt-4">
            <b className="text-lg font-bold">Address: </b>
            <b className="text-xl font-light" >{`${location_address}`}</b>
          </div>
          <div className="flex flex-col text-start pt-4">
            <b className="text-lg font-bold">Certifications: </b>
            <b className="text-xl font-light" >{`${specialproductionmethods}`}</b>
          </div>
          
          <div className="flex justify-evenly mt-7">
              <Link class="text-white py-2 px-8 rounded-md hover:bg-[#295b39ec] hover:shadow-lg bg-[#37774bec] " to={`/farmersmarket/${listing_id}`}>
                <h1 className="text-white text-lg" >Details</h1>
              </Link>


            
              <Link class="text-white  py-2 px-8 rounded-md hover:bg-[#295b39ec] hover:shadow-lg bg-[#37774bec] " to={`/farmersmarket/${listing_id}`}>
                <h1 className="text-white text-lg" >Follow</h1>
              </Link>            
          </div>

        </div>
    </Popup>
    </>
  )
}


const MarkerLayer = ({data}) => {

  let centerPoint 

  const inputsIcon = L.icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/512/2776/2776067.png",
    iconSize: [25, 25],
  });
  return data

  .map(feature => {
    const {coordinates} = feature.geometry
      return (
        <>
        {coordinates[1] === null ? <></>  : <>
        <Marker key={String(coordinates)} position={[coordinates[1], coordinates[0]]} icon={inputsIcon}>
          <CustomPopUp data={feature}/>
        </Marker>
        </>}
        </>
      )
  }
  )
} 

const icon = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});

function ResetCenterView(props) {
  const { selectPosition } = props;
  const map = useMap();

  useEffect(() => {
    if (selectPosition) {
      map.setView(
        L.latLng(selectPosition?.lat, selectPosition?.lon),
        map.getZoom(),
        {
          animate: true
        }
      )
    }
  }, [selectPosition]);

  return null;
}


export default function FarmersMarkets(props) {

  const { selectPosition, data } = props;
  const locationSelection = [selectPosition?.lat, selectPosition?.lon];

  return (
    <>
        <MapContainer center={[41.05181527192488, -73.59345225093897]} zoom={11} minZoom={10}    style={{ width: "100%", height: "100%" }}>
          {/* Light Mode */}
          <TileLayer url='https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.{ext}' minZoom= {10} maxZoom= {20}
            attribution= '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            ext= 'png' />
          {/* <TileLayer minZoom= {10} maxZoom= {20}
            url='https://api.mapbox.com/styles/v1/purpledragons/cl9kzio97001v14pe6ahvafbd/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicHVycGxlZHJhZ29ucyIsImEiOiJjbDlqMGp3cHkwZDJsM25wa2I2dnBxMXU2In0.m0DYm_idO-8_I7OeSBuMnQ'
            /> */}
          <MarkerLayer data={data}/>
          {selectPosition && (
            <Marker position={locationSelection} icon={icon}>
              <Popup>
                Your Location
              </Popup>
            </Marker>
          )}
          <ResetCenterView selectPosition={selectPosition} />
        </MapContainer>
    </>
  );
}