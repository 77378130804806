import {createAsyncThunk, createSlice, PayloadAction,} from "@reduxjs/toolkit";
import cropService from "./cropService";
import {FixMeLater} from "../../types/dev";
import {CropState} from "../../types/states";
import HandleAxiosError from "../../utils/AxiosErrorHandler";
import {DefaultThunkApiConfig, Status} from "../../types/slice";
import {CropModel} from "@asirisos/types";

const CONTEXT = "Crop"

const initialState: CropState = {
    crop: undefined,
    crops: [],
    status: Status.Unknown
}

export const addCrop = createAsyncThunk<any, CropModel, DefaultThunkApiConfig>('crops/addcrop', async (cropData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await cropService.addCrop(cropData, account);
        if (response.status !== 201)
            return thunkAPI.rejectWithValue()

        return response.data;
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getCrops = createAsyncThunk<CropModel[], void, DefaultThunkApiConfig>('crops/getcrops', async (_, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account
        if (!account)
            return thunkAPI.rejectWithValue()

        const response = await cropService.getCrops(account);
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getCrop = createAsyncThunk<CropModel, string, DefaultThunkApiConfig>('crops/getcrop/:cropId', async (cropId: string, thunkAPI) => {
    try {
        const response = await cropService.getCrop(cropId)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const updateCrop = createAsyncThunk<CropModel, CropModel, DefaultThunkApiConfig>('crops/updatecrop/:cropId', async (cropData: CropModel, thunkAPI) => {
    try {
        const response = await cropService.updateCrop(cropData)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const deleteCrop = createAsyncThunk<any, string, DefaultThunkApiConfig>('crops/deletecrop/:cropId', async (cropId: string, thunkAPI) => {
    try {
        const response = await cropService.deleteCrop(cropId);
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const cropSlice = createSlice({
    name: 'crop',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers(builder) {
        builder
            .addCase(addCrop.pending, (state) => {
                state.status = Status.Pending
            })
            .addCase(addCrop.fulfilled, (state) => {
                state.status = Status.Success
            })
            .addCase(addCrop.rejected, (state: CropState) => {
                state.status = Status.Failure
            })
            .addCase(getCrops.pending, (state: CropState) => {
                state.status = Status.Pending
            })
            .addCase(getCrops.fulfilled, (state: CropState, action: PayloadAction<CropModel[], any, any>) => {
                state.status = Status.Success
                state.crops = action.payload
            })
            .addCase(getCrops.rejected, (state: CropState) => {
                state.status = Status.Failure
            })
            .addCase(getCrop.pending, (state: CropState) => {
                state.status = Status.Pending
            })
            .addCase(getCrop.fulfilled, (state: CropState, action: FixMeLater) => {
                state.status = Status.Success
                state.crop = action.payload
            })
            .addCase(getCrop.rejected, (state: CropState) => {
                state.status = Status.Failure
            })
            .addCase(updateCrop.fulfilled, (state: CropState, action: PayloadAction<CropModel, any, any>) => {
                state.status = Status.Success
                state.crops.map((crop) => crop._id === action.payload._id ? (state.crop = crop) : crop)
            })
            .addCase(deleteCrop.fulfilled, (state: CropState) => {
                state.status = Status.Success
                state.crop = undefined
            })
    }
})

export const {reset} = cropSlice.actions
export default cropSlice.reducer