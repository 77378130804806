import React from 'react'
import InventoryCategoryPieChart from './InventoryCategoryPieChart'

function InventoryDashboard() {
  return (
    <>
        <div className='flex flex-row justify-around bg-white drop-shadow-lg rounded-tl-none py-8' >
        <div className=' bg-slate-200 rounded-lg px-10 py-4 shadow-[2px_1px_15px_2px_rgba(201,201,201,0.47)] ' >
          <h1 className='text-xl '>Inventory By Category</h1>
          <InventoryCategoryPieChart/>
        </div>
        

        <div className=' bg-slate-200 rounded-lg px-10 py-4 shadow-[2px_1px_15px_2px_rgba(201,201,201,0.47)] ' >
          <h1 className='text-xl '>Total Equipment Value</h1>
          {/* <EquipmentTotalValue/> */}
        </div>
      </div>
    </>
    
  )
}

export default InventoryDashboard