import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getCrops } from '../../../features/crops/cropSlice'
import { getCustomers } from '../../../features/customers/customerSlice'
import { getAllEquipment } from '../../../features/equipment/equipmentSlice'
import { addSale } from '../../../features/sales/saleSlice'
import {useSelector, useDispatch} from 'react-redux'
import {toast} from 'react-toastify'
import Spinner from '../../components/Spinner'
import NumberInputInUnits from '../../components/shared/NumberInputInUnits'
import FormCard from '../../components/shared/FormCard'
import FormTwoInputs from '../../components/shared/FormTwoInputs'
import LegacyDropDownInput from '../../components/shared/LegacyDropDownInput'
import LargeTextInput from '../../components/shared/LargeTextInput'
import FormSelectionRangeInput from '../../components/shared/card/FormSelectionRangeInput'
import {AppDispatch, RootState} from "../../../app/store";
import {InputSelection} from "../../../types/ui";
import {Status} from "../../../types/slice";
import {useTranslation} from "react-i18next";
import {SaleModel} from "@asirisos/types";

function SaleForm() {

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const {t} = useTranslation('sale');
    const {status} = useSelector((state: RootState) => state.sale)

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    USE SELECTOR   ///////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const {user} = useSelector((state : RootState) => state.auth)
    // const [name, setName] = useState(user?.name)

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    USE EFFECT - this section was used to retrieve data in order to populate drop down form selections   /////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    useEffect(() => {
        dispatch(getCrops())
        dispatch(getAllEquipment())
        dispatch(getCustomers())
        },[dispatch] )


    const {equipments} = useSelector((state : RootState) => state.equipment )
    const {crops} = useSelector((state : RootState) => state.crop )
    const {customers} = useSelector((state : RootState) => state.customer )
    

    const cropSelections = crops.map((crop) => {
        console.log('printing crops', crop.cropName, crop._id)
        return {name: crop.cropName, id:crop._id}
    })

    const equipmentSelections = equipments.map((equipment) => {
        console.log('printing equipment', equipment.name, equipment._id)
        return {name: equipment.name, id:equipment._id}
    })

    const customerSelections = customers.map((customer) => {
        console.log('printing customers', customer.firstName, customer._id)
        return {name: customer.firstName, id:customer._id}
    })
    
    const categorySelection = [new InputSelection('general', 'general'), new InputSelection('crops', 'crops'), new InputSelection('equipment', 'equipment'), new InputSelection('services', 'services')]
    
    const emptySelection = [{name: '', typeId:''}]




    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    USE STATE      ///////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const [formData, setFormData] = useState({
        customer: '',
        category: '',
        item: '',
        quantity: '',
        saleStatus: '',
        comments: '',
    })

    const {
        customer, category, item, quantity, saleStatus, comments
    } = formData


    const [formStep, setFormStep] = useState(0)


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    USE EFFECT     ///////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // useEffect(() => {
    //     if(isError) {
    //         toast.error(message) }
    //     if(isSuccess) {
            
    //     }
        
    // },[dispatch, isError, isSuccess, message, naviagate] )

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    ON   CHANGE    ///////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    ON   SUBMIT    ///////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
            const saleData: SaleModel =
                {
                    customer,
                    category,
                    item,
                    quantity,
                    saleStatus,
                    comments,
                }

            dispatch(addSale(saleData))
                .unwrap()
                .then(() => {
                    toast.success("sale:saleAdded");
                    navigate("/sales")
                })
                .catch(() => {
                    toast.error(t("sale:saleAddFailed"))
                    navigate("/sale-form")
                })
        
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    COMPONENT      ///////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    

    if (status === Status.Pending) {
        return <Spinner/>
    }
  return (
    <>
    <FormCard title="Add Sale">
    <form onSubmit={onSubmit}>
        

        <FormTwoInputs>
            <LegacyDropDownInput onChange={(e) => onChange(e)} selections={customerSelections} value={customer} id={"customer"} type={'text'} group={"customer"} label={"customer"} name={"customer"} dataTitle="customer" />
            <LegacyDropDownInput onChange={(e) => onChange(e)} selections={categorySelection} value={category} id={"category"} type={'text'} group={"category"} label={"category"} name={"category"} dataTitle="category" />
        </FormTwoInputs>

        <FormTwoInputs>
            <LargeTextInput onChange={(e) => onChange(e)} value={comments} id={"comments"} type={'text'} group={"comments"} label={"comments"} name={"comments"} dataTitle="comments" />
            <NumberInputInUnits onChange={(e) => onChange(e)} value={quantity} id={"quantity"} type={'number'} group={"quantity"} label={"quantity"} name={"quantity"} dataTitle="Quantity" />
        </FormTwoInputs>


        {/* ///////////////////////////////// CROP CATEGORY  //////////////////////////////// */}

        {category === 'crops' ? (
            <>
            <FormTwoInputs>
                <LegacyDropDownInput onChange={(e) => onChange(e)} selections={cropSelections} value={item} id={"item"} type={'text'} group={"item"} label={"item"} name={"item"} dataTitle="item" />
            </FormTwoInputs>
            </>
        ) : (
            <>
            </>
        )}
        {/* ///////////////////////////////////////////////////////////////// */}


        {/* ///////////////////////////////// EQUIPMENT CATEGORY  //////////////////////////////// */}
        {category === 'equipment' ? (
            <>
            <FormTwoInputs>
                <LegacyDropDownInput onChange={(e) => onChange(e)} selections={equipmentSelections} value={item} id={"item"} type={'text'} group={"item"} label={"item"} name={"item"} dataTitle="item" />
            </FormTwoInputs>
            </>
        ) : (
            <>
            </>
        )}

        {/* ///////////////////////////////////////////////////////////////// */}

        <FormSelectionRangeInput
            title="Status"
            range={<input onChange={onChange} name="saleStatus" id="saleStatus" type="range" min="0" max="100" value={saleStatus} className="range range-sm range-primary" step="25" />}>
                <span><h1 className={`text-base  ${saleStatus === "0" ? "text-primary text-2xl" : "text-gray-400" }`}>created</h1></span>
                <span><h1 className={`text-base  ${saleStatus === "25" ? "text-primary text-2xl" : "text-gray-400" }`} >in progress</h1></span>
                <span><h1 className={`text-base  ${saleStatus === "50" ? "text-primary text-2xl" : "text-gray-400" }`} >completed</h1></span>
                <span><h1 className={`text-base  ${saleStatus === "75" ? "text-primary text-2xl" : "text-gray-400" }`}  >review</h1></span>
                <span><h1 className={`text-base  ${saleStatus === "100" ? "text-primary text-2xl" : "text-gray-400" }`}  >closed</h1></span>
        </FormSelectionRangeInput>

    <div className='flex justify-center pt-8 p-2' >
        <button className="btn btn-wide btn-primar hover:bg-black">Add Sale</button>
    </div>    
    </form>
    </FormCard>
</>
  )
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   

export default SaleForm







