import {AccountModel} from "@asirisos/types";
import {RequestBody} from "@asirisos/types/network";

export default class RequestBodyFactory<P> {
    private account?: AccountModel;
    private farmId?: string;
    private payload: P;

    constructor(payload: P) {
        this.payload = payload;
    }

    public WithFarmId(farmId: string) : RequestBodyFactory<P> {
        this.farmId = farmId;
        return this;
    }

    public WithAccount(account: AccountModel) : RequestBodyFactory<P> {
        this.account = account;
        return this;
    }

    public Build(): RequestBody<P> {
        return {payload: this.payload, account: this.account, farmId: this.farmId}
    }
}