import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import {addEquipment} from '../../../features/equipment/equipmentSlice'
import Spinner from '../../components/Spinner'
import {AppDispatch, RootState} from "../../../app/store";
import FormCard from '../../components/shared/FormCard'
import FormTwoInputs from '../../components/shared/FormTwoInputs'
import NumberInputInUnits from '../../components/shared/NumberInputInUnits'
import LegacyDropDownInput from '../../components/shared/LegacyDropDownInput'
import LargeTextInput from 'UI/components/shared/LargeTextInput'
import {InputSelection} from "../../../types/ui";
import {useTranslation} from "react-i18next";
import {Status} from "../../../types/slice";
import {useNavigate} from "react-router-dom";
import {EquipmentModel} from "@asirisos/types";

function EquipmentForm() {
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const {t} = useTranslation('equipment');
    const {status} = useSelector((state: RootState) => state.equipment)
    const canSave = true;

    const [formData, setFormData] = useState({
        name: '',
        farmId: '',
        type: '',
        mileage: '',
        vin: '',
        usageUnit: '',
        value: '',
        features: ''
    })

    const categorySelection = [
        new InputSelection(t("equipment:farmMachinery"), 'Farm Machinery'),
        new InputSelection(t("equipment:liveStock"), 'Livestock'),
        new InputSelection(t("equipment:infrastructure"), 'Infrastructure'),
        new InputSelection(t("equipment:transportation"), 'Transportation'),
        new InputSelection(t("equipment:workShop"), 'Workshop'),
        new InputSelection(t("equipment:miscellaneous"), 'Miscellaneous'),]

    const {name, farmId, type, mileage, vin, 
        usageUnit, value, features
    } = formData

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        if (canSave) {
            const equipmentData: EquipmentModel =
                {
                    name,
                    farmId,
                    type,
                    mileage,
                    vin,
                    usageUnit,
                    value,
                    features
                }

            dispatch(addEquipment(equipmentData))
                .unwrap()
                .then(() => {
                    toast.success("equipment:equipmentAdded");
                    navigate("/equipment")
                })
                .catch(() => {
                    toast.error(t("equipment:equipmentAddFailed"))
                    navigate("/equipment-form")
                })
        }
    }
    if (status === Status.Pending) {
        return <Spinner/>
    }
    return (
        <div>
            <>
                <FormCard title={t("equipment:addEquipment")}>
                    <form onSubmit={onSubmit}>
                        <FormTwoInputs>
                            <NumberInputInUnits onChange={(e) => onChange(e)} value={name} id={"name"} type={'text'}
                                                group={"name"} label={"name"} name={"name"}
                                                dataTitle={t("translation:title")}/>
                        </FormTwoInputs>
                        <FormTwoInputs>
                            <NumberInputInUnits onChange={(e) => onChange(e)} value={vin} id={"vin"} type={'text'}
                                                group={"vin"} label={"vin"} name={"vin"}
                                                dataTitle={t("equipment:vin")}/>
                            <LegacyDropDownInput onChange={(e) => onChange(e)} selections={categorySelection} value={type}
                                                 id={"type"} type={'text'} group={"type"} label={"type"} name={"type"}
                                                 dataTitle={t("translation:category")}/>
                        </FormTwoInputs>
                        <FormTwoInputs>
                            <NumberInputInUnits denominations={['select', 'miles', 'hours']}
                                                    dataTitle={t("equipment:usage")} dName={"usageUnit"}
                                                    dValue={usageUnit} dId={"usageUnit"}
                                                    dType={'string'}
                                                    onChange={(e) => onChange(e)} value={mileage.toString()}
                                                    id={"mileage"} type={'number'} name={"mileage"}/>
                            <NumberInputInUnits onChange={(e) => onChange(e)} value={value} id={"value"} type={'text'}
                                                group={"value"} label={"value"} name={"value"} fixedInputItem={"$"}
                                                dataTitle={t("equipment:value")}/>
                        </FormTwoInputs>
                        <div className='mx-16' >
                            <LargeTextInput onChange={(e) => onChange(e)} value={features} id={"features"} type={'text'} group={"features"} label={"features"} name={"features"} dataTitle={t("equipment:features")} />
                        </div>
                        <div className='flex justify-center pt-8 p-2'>
                            <button
                                className="btn btn-wide btn-primar hover:bg-black">{t("equipment:addEquipment")}</button>
                        </div>
                    </form>
                </FormCard>
            </>
        </div>
    )
}

export default EquipmentForm