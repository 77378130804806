import axios from "axios"
import {AccountModel, CustomerModel} from "@asirisos/types";
import RequestBodyFactory from "../../factories/requestBodyFactory";

const API_URL = '/api/customers'


const addCustomer = async (customerData: CustomerModel, accountData: AccountModel) => {
    const body = new RequestBodyFactory(customerData).WithAccount(accountData).Build()
    return await axios.post(API_URL + '/', body)
}

const getCustomers = async (account: AccountModel) => {
    return await axios.get(API_URL + '/all/' + account.farm._id + '/' + account.user._id)
}

const getCustomer = async (customerId: string) => {
    return await axios.get(API_URL + '/' + customerId)
}

const updateCustomer = async (customerData: CustomerModel, account: AccountModel) => {
    const {_id} = customerData
    
    const body = new RequestBodyFactory(customerData).WithAccount(account).Build()
    return await axios.put(API_URL + '/' + _id, body)
}

const deleteCustomer = async (customerId: string[]) => {
    return await axios.delete(API_URL + '/' + customerId)
}

const customerService = {
    addCustomer,
    getCustomers,
    getCustomer,
    updateCustomer,
    deleteCustomer
}

export default customerService