import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import farmJoinService from "./farm-joinService";
import {FixMeLater} from "../../types/dev";
import {FarmJoinState} from "../../types/states";
import HandleAxiosError from "../../utils/AxiosErrorHandler";
import {FarmJoinModel} from "@asirisos/types";

const CONTEXT = "Farm-join"

const initialState: FarmJoinState = {
    farmJoin: undefined,
    farmJoins: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ""
}

export const addFarmJoin = createAsyncThunk<FarmJoinModel, FixMeLater>('farmjoin/add', async (farmJoinData, thunkAPI: FixMeLater) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await farmJoinService.addFarmJoin(farmJoinData, token)
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue("message")
    }
})

// Get All FarmJoin Requests for the selected User
export const getUserFarmJoins = createAsyncThunk<FixMeLater, any>('farmjoin/getalluser', async (UserId, thunkAPI: FixMeLater) => {
    try {
        const token = thunkAPI.getState().auth.user?.token
        console.log('Slice User Farm Joins', UserId, token)
        if (token !== undefined && UserId !== undefined)
            return await farmJoinService.getUserFarmJoins(UserId, token)
        else return thunkAPI.rejectWithValue("Failed to obtain the token")
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue("message")
    }
})

// Get All FarmJoin Requests for the selected Farm
export const getAllFarmJoin = createAsyncThunk<FarmJoinModel, FixMeLater>('farmjoin/getall', async (FarmId, thunkAPI: FixMeLater) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        console.log('farm id in slice for join by FARM', FarmId)
        return await farmJoinService.getAllFarmJoin(FarmId, token)
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue("message")
    }
})

export const getFarmJoin = createAsyncThunk<string, FixMeLater>('farmjoin/getone/:FarmJoinId', async (farmJoinId, thunkAPI: FixMeLater) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await farmJoinService.getFarmJoin(farmJoinId, token)
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue("message")
    }
})

export const updateFarmJoin = createAsyncThunk<FarmJoinModel, FixMeLater>('farmjoin/update', async (farmJoinData, thunkAPI: FixMeLater) => {
    try {
        console.log(farmJoinData)
        const token = thunkAPI.getState().auth.user.token
        return await farmJoinService.updateFarmJoin(farmJoinData, token)
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue("message")
    }
})

export const deleteFarmJoin = createAsyncThunk<string, FixMeLater>('farmjoin/delete', async (farmJoinId, thunkAPI: FixMeLater) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await farmJoinService.deleteFarmJoin(farmJoinId, token)
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue("message")
    }
})

export const farmJoinSlice = createSlice({
    name: 'farmJoin',
    initialState,
    reducers: {
        reset: (state: FarmJoinState) => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(addFarmJoin.pending, (state: FarmJoinState) => {
                state.isLoading = true
            })
            .addCase(addFarmJoin.fulfilled, (state: FarmJoinState, action: FixMeLater) => {
                state.isLoading = false
                state.isSuccess = true
                state.farmJoin = action.payload
            })
            .addCase(addFarmJoin.rejected, (state: FarmJoinState, action: FixMeLater) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getAllFarmJoin.pending, (state: FarmJoinState) => {
                state.isLoading = true
            })
            .addCase(getAllFarmJoin.fulfilled, (state: FarmJoinState, action: FixMeLater) => {
                state.isLoading = false
                state.isSuccess = true
                state.farmJoins = action.payload
            })
            .addCase(getAllFarmJoin.rejected, (state: FarmJoinState, action: FixMeLater) => {
                state.isLoading = false
                state.message = action.payload
            })
            .addCase(getFarmJoin.pending, (state: FarmJoinState) => {
                state.isLoading = true
            })
            .addCase(getFarmJoin.fulfilled, (state: FarmJoinState, action: FixMeLater) => {
                state.isLoading = false
                state.isSuccess = true
                state.farmJoin = action.payload
            })
            .addCase(getFarmJoin.rejected, (state: FarmJoinState, action: FixMeLater) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(updateFarmJoin.pending, (state: FarmJoinState) => {
                state.isLoading = true
            })
            .addCase(updateFarmJoin.fulfilled, (state: FarmJoinState, action: FixMeLater) => {
                state.isLoading = false
                state.isSuccess = true
                state.farmJoins.map((farmJoin: FarmJoinModel) => farmJoin._id === action.payload._id ?
                    (state.farmJoin = action.payload) : farmJoin)

            })
            .addCase(updateFarmJoin.rejected, (state: FarmJoinState, action: FixMeLater) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getUserFarmJoins.pending, (state: FarmJoinState) => {
                state.isLoading = true
            })
            .addCase(getUserFarmJoins.fulfilled, (state: FarmJoinState, action: FixMeLater) => {
                state.isLoading = false
                state.isSuccess = true
                state.farmJoins = action.payload
            })
            .addCase(getUserFarmJoins.rejected, (state: FarmJoinState, action: FixMeLater) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    }
})

export const {reset} = farmJoinSlice.actions
export default farmJoinSlice.reducer