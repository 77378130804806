import React, {ChangeEvent, useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useParams, Link, useNavigate} from 'react-router-dom'
import { getDevice, deleteDevice } from 'features/devices/deviceSlice'
import {toast} from 'react-toastify'
import Spinner from '../../components/Spinner'
import {AppDispatch, RootState} from "../../../app/store";
import {useTranslation} from "react-i18next";
import {Status} from "../../../types/slice";
import DeleteModal from 'UI/components/DeleteModal'
import ItemPageDeleteButton from 'UI/components/ItemPageDeleteButton'
import mqtt from 'mqtt'

function DevicePage() {
    
    const {t} = useTranslation("device")
    const navigate = useNavigate()
    const {deviceId} = useParams()
    const dispatch = useDispatch<AppDispatch>()
    const {device, status} = useSelector((state: RootState) => state.device)


    useEffect(() => {
        if (deviceId) {
            dispatch(getDevice(deviceId)) }
    }, [dispatch, deviceId])

    if (status === Status.Failure) {
        toast.error(t("error"))
    }

    const [sensorData, setSeonsorData] = useState(Object)

    var options :any = {
        protocol: "ws",
        username: "esp32-mqtt-server",
        password: "DigitalTest-45+rasmad?",
        keepalive: 20,
        clientId: "mqttjs_" + Math.random().toString(16).substr(2, 8),
      };

    const onSubscribe = () => {

        var client = mqtt.connect("mqtt://24.144.93.208:8080", options);

        client.subscribe(`${device?.deviceMAC}`);
        console.log("Client subscribed");

        var note : any;
        client.on("message", function (topic, message: any) {
            note = message.toString();
            const object = JSON.parse(note)
            console.log(object.temperature);
            console.log(object.readingId);
            setSeonsorData(object.readingId)
            console.log(sensorData)

            client.end();
            // setTimeout('20000')
      });

    }

    const onAlarmOn = () => {
        var client = mqtt.connect("mqtt://24.144.93.208:8080", options);
        const topic = 'downlink'
        const message : any = '2'
        client.publish(topic, message, options)
        console.log('Alarm On')
  }

  const onAlarmOff = () => {
        var client = mqtt.connect("mqtt://24.144.93.208:8080", options);
        const topic = 'downlink'
        const message : any = '1'
        client.publish(topic, message, options)
        console.log('Alarm Off')
  }


    ////////////////////////////////////////////
    // Delete and Modal CODE
    ////////////////////////////////////////////

    const onDelete = () => {
        dispatch(deleteDevice(deviceId!))
        .unwrap()
        .then(() => {
            toast.success(t("device:deviceDeleted"));
            navigate("/mqtt-dashboard")
        })
        .catch(() => {
            toast.error(t("device:failedToDeletedevice"))
        })
    }

    const [modalIsOpen, setModalIsOpen] = useState(false)

    const onDeleteClick = (e: any) => {
        setModalIsOpen(!modalIsOpen)
    }


    if (status === Status.Pending && device?.deviceName != '' && device?.deviceType != '') {
        return <Spinner/>
    } else {
        return (
            <>
            <div className='lg:hidden sm:visible' >
                <div className="bg-white mt-5 py-5 rounded-lg #drop-shadow-xl #shadow-lg relative px-9 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] mx-5  ">

                    <div className="grid grid-cols-2 gap-3">
                        <div className='-my-1 col-span-1 justify-self-center self-center'>
                            <img className="h-20 w-20 bg-indigo-400 shadow-xl rounded-xl"
                                 src="https://cdn-icons-png.flaticon.com/512/2833/2833675.png" alt=""/>
                        </div>

                        <div className='justify-start justify-self-start'>

                            <div>
                                <h1>Device name</h1>
                                {/* <h1>{device?.deviceName}</h1> */}
                                <h1>Device Description</h1>
                                {/* <h1>{device?.deviceDescription}</h1> */}
                            </div>
                           
                        </div>

                    </div>
                    {/* ////////////////////////////////////////////////////////////////////// */}
                    {/* //////////////////////////// DELETE BUTTON /////////////////////////// */}

                </div>

                <div className="bg-white mt-5 py-5 rounded-lg #drop-shadow-xl #shadow-lg relative px-9 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] mx-5  ">
                    <h1 className='pb-4 text-xl font-semibold'>Device Actions</h1>
                    {device?.deviceType === "sensor" ? (<>
                    <div>
                        <h1>Read Sensor Data</h1>
                        <button onClick={onSubscribe}  className='w-full bg-[#FFC500] rounded-md py-2'>Get Data</button>
                        <div>
                            <h1>{sensorData}</h1>
                            {sensorData.map((data: any) => {
                                <>
                                    <div>
                                        <h1 className='py-2 text-lg'>{data.dataPointName}</h1>
                                    </div>
                                    <div>
                                        <h1 className='py-5 text-2xl font-semibold'>{data.dataPointValue}</h1>
                                    </div>
                                </>
                            } )}
                        </div>
                    </div>
                        
                    </>) : (<></>) }
                    {device?.deviceType === "controller" ? (<>

                    <div className='pt-3'>
                        <h1 className='py-2'>Control Device</h1>
                        <button onClick={onAlarmOn}  className='w-2/6 py-2 bg-[#FFC500] rounded-md mx-2'>Turn On</button>
                        <button onClick={onAlarmOff}  className='w-2/6 py-2 bg-[#FFC500] rounded-md mx-2'>Turn Off</button>
                    </div>

                    </>) : (<></>) }
                </div>

                {/* ////////////////////////////////////////////////////////////////////// */}
                {/* //////////////////////////// DELETE MODAL //////////////////////////// */}

                <DeleteModal modalIsOpen={modalIsOpen} name={device?.deviceName!} onDeleteClick={onDeleteClick} onDelete={onDelete} />

            </div>
            
                
            </>
        )
    }
}


export default DevicePage