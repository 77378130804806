import React from 'react'
import {BrowserRouter as Router, Routes, Route, useLocation} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Header from './UI/components/Header'
import Sidebar from 'UI/components/sidebar/Sidebar'
import PrivateRoute from './UI/components/PrivateRoute'
import UserPrivateRoute from 'UI/components/UserPrivateRoute'
// Landing Page
import LandingPage from 'UI/pages/LandingPage/LandingPage'
import SignUpLandingPage from 'UI/pages/LandingPage/SignUpLandingPage'
import SignUpFormPage from 'UI/pages/LandingPage/SignUpFormPage'
import ThankYouForSignUp from 'UI/pages/LandingPage/ThankYouForSignUp'
// Auth
import Home from './UI/pages/Home/Home'
import Landing from './UI/pages/Auth/Landing'
// import HomeWidgets from './UI/pages/HomeWidgets'
import Login from './UI/pages/Auth/Login'
import Register from './UI/pages/Auth/Register'
import ForgotPassword from './UI/pages/Auth/ForgotPassword'
// Activity
import Activity from './UI/pages/Activity/Activity'
import ActivityPage from './UI/pages/Activity/ActivityPage'
import ActivityForm from './UI/pages/Activity/ActivityForm'
// Crops
import Crops from './UI/pages/Crops/Crops'
import CropForm from './UI/pages/Crops/CropForm'
import CropPage from './UI/pages/Crops/CropPage'
// Live Stock
import LiveStock from './UI/pages/LiveStock/LiveStock'
import LiveStockPage from './UI/pages/LiveStock/LiveStockPage'
import LiveStockForm from './UI/pages/LiveStock/LiveStockForm'
//Sales
import Sales from './UI/pages/Sales/Sales'
import SaleForm from './UI/pages/Sales/SaleForm'
import SalePage from 'UI/pages/Sales/SalesPage'
//Expenses
import Expenses from 'UI/pages/Expenses/Expenses'
import ExpenseForm from 'UI/pages/Expenses/ExpenseForm'
import ExpensePage from 'UI/pages/Expenses/ExpensePage'
//Customer
import Customers from './UI/pages/Customers/Customers'
import CustomerPage from './UI/pages/Customers/CustomerPage'
import CustomerForm from './UI/pages/Customers/CustomerForm'
// Tasks
import Task from './UI/pages/Tasks/Task'
import TaskForm from './UI/pages/Tasks/TaskForm'
import TaskPage from './UI/pages/Tasks/TaskPage'
// import TaskFormConnections from 'UI/pages/Tasks/TaskFormConnections'
// Employees
import Employees from './UI/pages/Employees/Employees'
import EmployeeForm from './UI/pages/Employees/EmployeeForm'
import EmployeePage from './UI/pages/Employees/EmployeePage'
import ClaimAccount from 'UI/pages/Accounts/ClaimAccount'
// Equipment
import EquipmentForm from './UI/pages/Equipment/EquipmentForm'
import EquipmentPage from './UI/pages/Equipment/EquipmentPage'
import Equipment from './UI/pages/Equipment/Equipment'
// Inventory
import Inventory from './UI/pages/Inventory/Inventory'
import InventoryPage from './UI/pages/Inventory/InventoryPage'
import InventoryForm from './UI/pages/Inventory/InventoryForm'
// Storage
import Storage from './UI/pages/Storage/Storage'
import StoragePage from './UI/pages/Storage/StoragePage'
import StorageForm from './UI/pages/Storage/StorageForm'
// Farm
import Farm from './UI/pages/Farm/Farm'
import FarmAddForm from './UI/pages/Farm/FarmAddForm'
import FarmList from './UI/pages/Farm/FarmList'
import FarmPage from './UI/pages/Farm/FarmPage'
import MyFarmPage from './UI/pages/Farm/MyFarmPage'
import SearchFarm from './UI/pages/Farm/SearchFarm'
import FarmProfile from 'UI/pages/Farm/FarmProfile'
// Yield
import Yield from 'UI/pages/Yield/Yield'
import YieldForm from 'UI/pages/Yield/YieldForm'
import YieldPage from 'UI/pages/Yield/YieldPage'
// import YieldPage from 'UI/pages/Yield/YieldPage'
// Field
import FieldForm from 'UI/pages/Fields/FieldForm'
import Field from 'UI/pages/Fields/Field'
import FieldPage from 'UI/pages/Fields/FieldPage'
// Infrastructure
import Infrastructure from 'UI/pages/Infrastructure/Infrastructure'
import InfrastructurePage from 'UI/pages/Infrastructure/InfrastructurePage'
// Profile
import NewProfile from './UI/pages/Profile/NewProfile'
// Kanban
import Kanban from './UI/pages/Kanban/Kanban'
// Agri Power
import AgriPowerLanding from 'UI/pages/Farm/AgriPowerLanding'
// Sidebar 2.0
import SetUpStart from './UI/pages/SetUp/SetUpStart'
import SelectOrganization from 'UI/pages/SetUp/SelectOrganization'
import FarmServiceForm from 'UI/pages/SetUp/FarmServicesForm'
import FarmInputsForm from 'UI/pages/SetUp/FarmInputsForm'
import FarmTechForm from 'UI/pages/SetUp/FarmTechForm'
import WholesaleBuyerForm from 'UI/pages/SetUp/WholesaleBuyerForm'
import ProcessingForm from 'UI/pages/SetUp/ProcessingForm'
import WarehousingForm from 'UI/pages/SetUp/WarehousingForm'
import TransportationForm from 'UI/pages/SetUp/TransportationForm'
import RetailBuyerForm from 'UI/pages/SetUp/RetailBuyerForm'
import {store} from "./app/store";
import axios from "axios";
import FarmersMarketsProfile from 'UI/pages/FarmersMarkets/FarmersMarketsProfile'
import ApplyAsVendor from 'UI/pages/FarmersMarkets/ApplyAsVendor'
import GeneralUserLanding from 'UI/pages/LandingPage/GeneralUserLanding'
import FarmProfileForMarket from 'UI/pages/Farm/FarmProfileForMarket'
import ContactFarm from 'UI/pages/Farm/ContactFarm'
import JoinTheTeam from 'UI/pages/LandingPage/JoinTheTeam'
import JoinTheWaitlist from 'UI/pages/LandingPage/JoinTheWaitlist'
import Success from 'UI/pages/LandingPage/Success'
import Fail from 'UI/pages/LandingPage/Fail'

// Combat Link
import CombatFighterProfile from 'UI/pages/CombatLink/CombatLinkProfile'
import CombatLanding from 'UI/pages/LandingPage/CombatLanding'
import CombatContact from 'UI/pages/CombatLink/CombatContact'
import CombatSponsor from 'UI/pages/CombatLink/CombatSponsor'
import JoinAsFighter from 'UI/pages/CombatLink/JoinAsFighter'
import JoinAsFan from 'UI/pages/CombatLink/JoinAsFan'
import CombatLogin from 'UI/pages/CombatLink/CombatLogin'
import RecoLanding from 'UI/pages/RecoLandings/RecoLanding'
import RecoResearch from 'UI/pages/RecoLandings/RecoResearch'
import RecoProducts from 'UI/pages/RecoLandings/RecoProducts'
import SearchByZip from 'UI/pages/LandingPage/SearchByZip'
import DisplayOrgsSearchResults from 'UI/pages/LandingPage/DisplayOrgsSearchResults'
import ContactLanding from 'UI/pages/LandingPage/ContactLanding'
import JoinForOrganizations from 'UI/pages/LandingPage/JoinForOrganizations'
import MqttDashboard from 'UI/pages/MQTT/MqttDashboard'
import AddDevice from 'UI/pages/MQTT/AddDevice'
import DevicePage from 'UI/pages/MQTT/devicePage'
import AsirisV2 from 'UI/pages/Demos/AsirisV2'
import AsirisV2RoundRockDemo from 'UI/pages/Demos/AsirisV2RoundRockDemo'
import Family from 'UI/pages/Demos/DemoPages/Family'
import AssetsDemo from 'UI/pages/Demos/DemoPages/AssetsDemo'
import Community from 'UI/pages/Demos/DemoPages/Community'
import FatherProfile from 'UI/pages/Demos/DemoPages/FamilyMembers/FatherProfile'
import VehicleProfile from 'UI/pages/Demos/DemoPages/Assets/VehicleProfile'
import Demographics from 'UI/pages/Demos/DemoPages/Community/Demographics'
import EverythingDb from 'UI/pages/Demos/DemoPages/Assets/EverythingDb'
import AsirisV2Landing from 'UI/pages/LandingPage/AsirisV2Landing'
import AnswerYes from 'UI/pages/LandingPage/V2LandingPages/AnswerYes'
import ExploreClasses from 'UI/pages/LandingPage/V2LandingPages/ExploreClasses'
import ExploreTools from 'UI/pages/LandingPage/V2LandingPages/ExploreTools'
import GetStarted from 'UI/pages/LandingPage/V2LandingPages/GetStarted'
import AnswerNo from 'UI/pages/LandingPage/V2LandingPages/AnswerNo'
import ReserveSpot from 'UI/pages/LandingPage/V2LandingPages/ReserveSpot'
import ReserveSuccess from 'UI/pages/LandingPage/V2LandingPages/ReserveSuccess'
import ShowAllImagesPage from 'UI/pages/LandingPage/V2LandingPages/ShowAllImages'
import SignUpV2 from 'UI/pages/LandingPage/SignUpV2'
import SignUpSuccess from 'UI/pages/LandingPage/SignUpSuccess'
import StripeCheckout from 'UI/pages/LandingPage/Payment/PreSalePayment'
import CheckoutSuccess from 'UI/pages/LandingPage/Payment/CheckoutSuccess'
import CheckoutFail from 'UI/pages/LandingPage/Payment/CheckoutFail'
import DonateSignUpSuccess from 'UI/pages/LandingPage/DonateSignUpSuccess'

export function SetAxiosAuthHeader() {
    let token = store.getState().auth.user?.token;
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        axios.defaults.headers.common['Authorization'] = null;
    }
}

function App() {
    SetAxiosAuthHeader();

    return (
        <>
            <Router>
                <div className='flex flex-row'>


                    {/* <Sidebar2 /> */}

                    <Sidebar/>
                    <div className="w-full text-center bg-white">
                        
                        <Header/>
                        
                        <Routes>
                            {/* <Route path='/' element={<Home/>} /> */}
                            <Route path='/farm-profile/:FarmId' element={<FarmProfile/>}/>
                            <Route path='/field-form' element={<FieldForm/>} />
                            <Route path='/agri-power-home' element={<AgriPowerLanding/>} />
                            <Route path='/landing-general' element={<GeneralUserLanding/>} />
                            <Route path='/search-by-zip' element={<SearchByZip/>} />
                            <Route path='/search-results' element={<DisplayOrgsSearchResults/>} />
                            <Route path='/contact' element={<ContactLanding/>} />
                            <Route path='/organizations-join' element={<JoinForOrganizations/>} />
                            <Route path='/mqtt-dashboard' element={<MqttDashboard/>} />
                            <Route path='/add-device' element={<AddDevice/>} />
                            {/* <Route path='/device/:deviceId' element={<DevicePage/>}/> */}
                            <Route path='/device' element={<DevicePage/>}/>



                            {/* Reco Link */}
                            <Route path='/reco-landing' element={<RecoLanding/>} />
                            <Route path='/reco-research' element={<RecoResearch/>} />
                            <Route path='/reco-products' element={<RecoProducts/>} />
                            {/* Reco Link */}

                            {/* Asiris-V2 Link */}
                            <Route path='/asiris-v2-landing' element={<AsirisV2Landing/>} />
                            <Route path='/asiris-v2-landing-yes' element={<AnswerYes/>} />
                            <Route path='/asiris-v2-landing-no' element={<AnswerNo/>} />
                            <Route path='/asiris-v2-explore-classes' element={<ExploreClasses/>} />
                            <Route path='/asiris-v2-explore-tools' element={<ExploreTools/>} />
                            <Route path='/asiris-v2-get-started' element={<GetStarted/>} />
                            <Route path='/asiris-v2-reserve-spot' element={<ReserveSpot/>} />
                            <Route path='/asiris-v2-reserve-success' element={<ReserveSuccess/>} />
                            <Route path='/asiris-v2-all-images' element={<ShowAllImagesPage/>} />

                            <Route path='/asiris-v2' element={<AsirisV2/>} />
                            <Route path='/asiris-v2-demo' element={<AsirisV2RoundRockDemo/>} />
                            <Route path='/asiris-v2-demo-family' element={<Family/>} />
                            <Route path='/asiris-v2-demo-assets' element={<AssetsDemo/>} />
                            <Route path='/asiris-v2-demo-community' element={<Community/>} />
                            <Route path='/asiris-v2-demo-john-profile' element={<FatherProfile/>} />
                            <Route path='/asiris-v2-demo-vehicle-profile' element={<VehicleProfile/>} />
                            <Route path='/asiris-v2-demo-demographics' element={<Demographics/>} />
                            <Route path='/asiris-v2-demo-everything-db' element={<EverythingDb/>} />
                            
                            {/* Asiris-V2 Link */}

                            {/* Combat Link */}
                            <Route path='/combat-landing' element={<CombatLanding/>} />
                            <Route path='/combat-fighter-profile' element={<CombatFighterProfile/>} />
                            <Route path='/combat-contact' element={<CombatContact/>} />
                            <Route path='/combat-sponsor' element={<CombatSponsor/>} />
                            <Route path='/combat-join-as-fighter' element={<JoinAsFighter/>} />
                            <Route path='/combat-join-as-fan' element={<JoinAsFan/>} />
                            <Route path='/combat-login' element={<CombatLogin/>} />
                            {/* Combat Link */}

                            <Route path='/join-the-team' element={<JoinTheTeam/>} />
                            <Route path='/success' element={<Success/>} />
                            <Route path='/fail' element={<Fail/>} />
                            <Route path='/join-the-waitlist' element={<JoinTheWaitlist/>} />
                            <Route path='/' element={<GeneralUserLanding/>} />
                            {/* Latest sign up version 05/15/24 */}
                            <Route path='/sign-up' element={<SignUpV2/>} />

                            {/* STRIPE CHECKOUT */}
                            <Route path='/stripe-checkout' element={<StripeCheckout/>} />
                            <Route path='/stripe-checkout/success' element={<CheckoutSuccess/>} />
                            <Route path='/stripe-checkout/fail' element={<CheckoutFail/>} />
                            {/* STRIPE CHECKOUT */}

                            {/* Removed on 05/15/24 - when added donate */}
                            {/* <Route path='/sign-up-success' element={<SignUpSuccess/>} />    */}
                            <Route path='/sign-up-success' element={<DonateSignUpSuccess/>} />                                           
                            {/* <Route path='/' element={<LandingPage/>} /> */}
                            {/* 11/19/23 - Retired <Route path='/' element={<SignUpLandingPage/>} /> */}
                            <Route path='/sign-up-form' element={<SignUpFormPage/>} />
                            <Route path='/thanks-for-signup' element={<ThankYouForSignUp/>} />
                            {/* <Route path='/landing2' element={<Landing/>} /> */}
                            <Route path='/register' element={<Register/>}/>
                            {/* <Route path='/landing' element={<Landing/>}/> */}
                            <Route path='/login' element={<Login/>}/>
                            <Route path='/forgotpassword' element={<ForgotPassword/>}/>
                            <Route path='/farm-profile-for-market' element={<FarmProfileForMarket/>}/>
                            <Route path='/contact-farm' element={<ContactFarm/>}/>

                            {/* Protected Routes */}
                            <Route element={<UserPrivateRoute allowedRoles={['admin', 'hr', 'new']}/>}>
                                <Route path='/setup-start' element={<SetUpStart/>}/>
                                <Route path='/select-org' element={<SelectOrganization/>}/>
                                <Route path='/farm-service-add-form' element={<FarmServiceForm/>}/>
                                <Route path='/farm-inputs-add-form' element={<FarmInputsForm/>}/>
                                <Route path='/farm-tech-add-form' element={<FarmTechForm/>}/>
                                <Route path='/wholesale-add-form' element={<WholesaleBuyerForm/>}/>
                                <Route path='/processing-add-form' element={<ProcessingForm/>}/>
                                <Route path='/warehousing-add-form' element={<WarehousingForm/>}/>
                                <Route path='/transport-add-form' element={<TransportationForm/>}/>
                                <Route path='/retail-add-form' element={<RetailBuyerForm/>}/>

                                <Route path='/farm' element={<Farm/>}/>
                                <Route path='/farm-add-form' element={<FarmAddForm/>}/>
                                <Route path='/farm-list' element={<FarmList/>}/>
                                <Route path='/my-farm' element={<MyFarmPage/>}/>
                                <Route path='/farm/:FarmId' element={<FarmPage/>}/>
                                <Route path='/farm-search' element={<SearchFarm/>}/>
                                <Route path='/claim-account' element={<ClaimAccount/>}/>
                                <Route path='/home' element={<Home/>}/>
                                <Route path='/farmersmarket/:listing_id' element={<FarmersMarketsProfile/>}/>
                                <Route path='/apply/:listing_id' element={<ApplyAsVendor/>}/>
                                {/* Profile */}
                                <Route path='/profile' element={<NewProfile/>}/>
                                <Route element={<PrivateRoute allowedRoles={['activity', 'activityEdit']}/>}>
                                    {/* <Route path='/' element={<Home/>} /> */}
                                    {/* ACTIVITY */}
                                    <Route path='/activity' element={<Activity/>}/>
                                    <Route path='/activity/:activityId' element={<ActivityPage/>}/>
                                    <Route path='/activity-form' element={<ActivityForm/>}/>
                                </Route>

                                    {/* Yield */} 
                                <Route element={<PrivateRoute allowedRoles={['customers', 'customersEdit']}/>}>
                                    <Route path='/yield' element={<Yield/>}/>
                                    <Route path='/yield-form' element={<YieldForm/>}/>
                                    <Route path='/yield/:yieldId' element={<YieldPage/>}/>
                                </Route>

                                    {/* Field */} 
                                <Route element={<PrivateRoute allowedRoles={['customers', 'customersEdit']}/>}>
                                    <Route path='/field' element={<Field/>}/>
                                    {/* <Route path='/yield-form' element={<YieldForm/>}/> */}
                                    <Route path='/field/:fieldId' element={<FieldPage/>}/>
                                </Route>

                                    {/* Infrastructure */} 
                                <Route element={<PrivateRoute allowedRoles={['customers', 'customersEdit']}/>}>
                                    <Route path='/infrastructure' element={<Infrastructure/>}/>
                                    {/* <Route path='/yield-form' element={<YieldForm/>}/> */}
                                    <Route path='/infrastructure/:infrastructureId' element={<InfrastructurePage/>}/>
                                </Route>

                                    {/* INVENTORY */}
                                <Route element={<PrivateRoute allowedRoles={['inventory', 'inventoryEdit']}/>}>
                                    <Route path='/inventory' element={<Inventory/>}/>
                                    <Route path='/inventory-form' element={<InventoryForm/>}/>
                                    <Route path='/inventory/:inventoryId' element={<InventoryPage/>}/>
                                </Route>

                                    {/* CUSTOMERS */}
                                <Route element={<PrivateRoute allowedRoles={['customers', 'customersEdit']}/>}>
                                    <Route path='/customers' element={<Customers/>}/>
                                    <Route path='/customer/:customerId' element={<CustomerPage/>}/>
                                    <Route path='/customer-form' element={<CustomerForm/>}/>
                                </Route>

                                    {/* SALE */}
                                <Route element={<PrivateRoute allowedRoles={['sales', 'salesEdit']}/>}>
                                    <Route path='/sales' element={<Sales/>}/>
                                    <Route path='/sale-form' element={<SaleForm/>}/>
                                    <Route path='/sale/:saleId' element={<SalePage/>} />
                                </Route>

                                    {/* Expense */}
                                <Route element={<PrivateRoute allowedRoles={['sales', 'salesEdit']}/>}>
                                    <Route path='/expenses' element={<Expenses/>}/>
                                    <Route path='/expense-form' element={<ExpenseForm/>}/>
                                    <Route path='/expense/:expenseId' element={<ExpensePage/>} />
                                </Route>

                                    {/* CROPS */}
                                <Route element={<PrivateRoute allowedRoles={['crops', 'cropsEdit']}/>}>
                                    <Route path='/crops' element={<Crops/>}/>
                                    <Route path='/crop-form' element={<CropForm/>}/>
                                    <Route path='/crop/:cropId' element={<CropPage/>}/>
                                </Route>

                                    {/* LIVE STOCK */}
                                <Route element={<PrivateRoute allowedRoles={['liveStock', 'liveStockEdit']}/>}>
                                    <Route path='/livestock' element={<LiveStock/>}/>
                                    <Route path='/livestock/:liveStockId' element={<LiveStockPage/>}/>
                                    <Route path='/livestock-form' element={<LiveStockForm/>}/>
                                </Route>

                                    {/* TASK */}
                                <Route element={<PrivateRoute allowedRoles={['tasks', 'tasksEdit']}/>}>
                                    <Route path='/tasks' element={<Task/>}/>
                                    <Route path='/task-form' element={<TaskForm/>}/>
                                    <Route path='/task/:taskId' element={<TaskPage/>}/>
                                    {/* <Route path='/task-form-connections' element={<TaskFormConnections/>}/> */}
                                </Route>

                                    {/* EQUIPMENT */}
                                <Route element={<PrivateRoute allowedRoles={['equipment', 'equipmentEdit']}/>}>
                                    <Route path='/equipment' element={<Equipment/>}/>
                                    <Route path='/equipment-form' element={<EquipmentForm/>}/>
                                    <Route path='/equipment/:equipmentId' element={<EquipmentPage/>}/>
                                </Route>

                                    {/* EMPLOYEES */}
                                <Route element={<PrivateRoute allowedRoles={['employees', 'employeesEdit']}/>}>
                                    <Route path='/employees' element={<Employees/>}/>
                                    <Route path='/employee-form' element={<EmployeeForm/>}/>
                                    <Route path='/employee/:accountID' element={<EmployeePage/>}/>
                                </Route>

                                    {/* STORAGE */}
                                <Route element={<PrivateRoute allowedRoles={['storage', 'storageEdit']}/>}>
                                    <Route path='/storage' element={<Storage/>}/>
                                    <Route path='/storage/:storageId' element={<StoragePage/>}/>
                                    <Route path='/storage-form' element={<StorageForm/>}/>
                                </Route>

                                    {/* OPERATIONS */}
                                    <Route path='/kanban' element={<Kanban/>}/>

                                    {/* FARM */}
                                    <Route path='/farm' element={<Farm/>}/>
                                    <Route path='/farm-add-form' element={<FarmAddForm/>}/>
                                    <Route path='/farm-list' element={<FarmList/>}/>
                                    <Route path='/farm/:FarmId' element={<FarmPage/>}/>
                                    {/* Cath All */}
                                    {/* <Route path="*" element={<Missing/>} /> */}
                            </Route>
                            {/* <Route element={<PrivateRoute />}>
            </Route> */}
                        </Routes>
                    </div>
                </div>
            </Router>
            <ToastContainer/>
        </>
    )
}

export default App
