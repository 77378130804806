import React, {ChangeEvent} from 'react'
import { Link } from 'react-router-dom'
import SearchBox from '../../Map/SearchBox-1'
import {useTabPersistence} from "../../../../hooks/useTabPersistence";
import {useTranslation} from "react-i18next";
import {PageId} from "../../../../types/pages";
import { useState } from 'react'
import DisplayAllFarmersMarkets from '../..//Map/DisplayAllFarmersMarkets';
import Button from "../../../components/shared/Button"

function Family() {

    // const animation = require ('./farm_large.png')
    // const animation2 = require ('./adaptive-icon.png')
    // const vegetables_in_basket = require ('./vegetables_in_basket.jpg')
    // const vegetable_market = require ('./vegetable_market.jpg')

    const {t} = useTranslation(["landing", "translation"])
    const {selection, toggleTab} = useTabPersistence(PageId.Equipment);

    const [formData, setFormData] = useState({
        addressObject: {},
      })

    const {addressObject} = formData

    const [selectedPosition, setSelectPosition] = useState()


    const onSetAddress = (selectedPosition: any) => {
        setFormData((prevState) => ({
            ...prevState,
            addressObject: selectedPosition
        }) )
      }

    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
      }


  return (
    <>
    {/********************************************/}
    {/********************************************/}
    {/********************************************/}
    {/********************* MOBILE VERSION ***********************/}
    {/********************************************/}
    {/********************************************/}
    {/********************************************/}

    <div className='lg:hidden sm:visible' >

        <div className='flex flex-col'>

            {/********************************************/}
            {/********************* FIRST PAGE MOBILE - START ***********************/}
            {/********************************************/}

            <div className='w-screen h-screen flex flex-col justify-start align-middle bg-[#fff9e1]'>

                {/********************************************/}
                {/********************* MENU BAR - START ***********************/}
                {/********************************************/}

                <div className='bg-[#16341fec] flex justify-around py-2 drop-shadow-md shadow-[2px_1px_20px_3px_rgba(201,201,201,0.97)] '> 
                    <Link to="/">
                        <div className='flex flex-row justify-center py-2' >
                            <h1 className='text-md font-light text-white' >AsirisOS</h1>                        
                        </div>
                    </Link>
                    <Link className=" text-slate-700 hover:bg-[#FFB100] hover:shadow-lg rounded-sm px-4 py-1 bg-[#FFC500] flex justify-center " to={"/login"} >
                        <h1 className=' text-lg font-normal justify-center align-middle'>Login</h1>
                    </Link>
                </div>

                {/********************************************/}
                {/********************* MENU BAR - END ***********************/}
                {/********************************************/}


                <div className='w-full flex items-center justify-center  '>
                    <div className=' flex  border-t-0 rounded-xl rounded-t-none mb-4 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.67)]'>

                    </div>
                </div>


                {/********************************************/}
                {/********************* FIRST PAGE MOBILE - START ***********************/}
                {/********************************************/}

                <div className='flex flex-col justify-start h-full bg-[#fff9e1] px-2 pt-5'>

                    <div className='flex items-start pb-2 justify-center px-3'>
                        <h1 className=' max-w-xl text-2xl text-start font-semibold text-slate-800'> 
                            Reliable AI engines that provide Speed, Security, Scalability at the Edge
                        </h1>
                    </div>

                    <div className='flex justify-center'>
                        <div className='flex flex-col items-start pb-10'>                    
                            <h1 className='text-center font-semibold text-lg pt-2 text-slate-700'></h1>
                            <div className='flex'>
                                <h1 className='sm:py-1 max-sm:text-lg max-sm:font-normal text-left text-slate-700 px-3 pb-1'>Software for local food producers and consumers to build independent and healthy food systems for all.</h1>
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-col justify-center items-center py-5 pb-14' >
                        {/* <img className='rounded-2xl w-5/6 drop-shadow-2xl' src={vegetables_in_basket} alt="" />        */}
                    </div>

                    <div className=' flex flex-row gap-4 justify-center' >

                        <Link to="/join-the-waitlist">
                            <button className='rounded-md drop-shadow-xl hover:drop-shadow-2xl bg-[#FFC500]  py-3 font-normal text-slate-700 px-4 text-xl hover:bg-[#FFB100] hover:text-slate-700'>
                                Join the waitlist
                            </button>
                        </Link>

                        <Link to="/join-the-team">
                            <button className='rounded-md drop-shadow-xl hover:drop-shadow-2xl bg-[#FFC500]  py-3 font-normal text-slate-700 px-4 text-xl hover:bg-[#FFB100] hover:text-slate-700'>
                                Join the team
                            </button>
                        </Link>

                    </div>

                </div>

            </div>

            {/********************************************/}
            {/********************* FIRST PAGE MOBILE - END ***********************/}
            {/********************************************/}

            {/********************************************/}
            {/********************* SECOND PAGE MOBILE - START ***********************/}
            {/********************************************/}


            <div className='h-screen py-6 flex bg-[#fff9e192] w-full'>

                <div className='flex flex-col justify-center w-full gap-10' >

                    <div className='bg-[#16341fec] text-[#fff9e1] py-4  w-48 rounded-r-xl bg-[#fff9e1] drop-shadow-2xl '>
                        <div className='' >
                            <h1 className='text-2xl font-bold'>6989</h1>
                            <h1 className='text-lg font-light' >Farmers Markets</h1>
                        </div>
                    </div>


                    <div className=' flex flex-row justify-between'>                
                        <div className=' self-center pl-3'>
                            {/* <h1>This is what it is</h1> */}
                        </div>

                        <div className='bg-[#16341fec] text-[#fff9e1] py-4  w-48 rounded-l-xl bg-[#fff9e1] self-end drop-shadow-2xl' >
                            <h1 className='text-2xl font-bold'>1217</h1>
                            <h1 className='text-lg font-light' >Agricultural Co-ops</h1>
                        </div>
                    </div>

                    <div className='bg-[#16341fec] text-[#fff9e1] py-4  w-48 rounded-r-xl bg-[#fff9e1] drop-shadow-2xl '>
                        <div className='' >
                            <h1 className='text-2xl font-bold'>994</h1>
                            <h1 className='text-lg font-light' >CSAs</h1>
                        </div>
                    </div>

                    <div className='bg-[#16341fec] text-[#fff9e1] py-4  w-48 rounded-l-xl bg-[#fff9e1] self-end drop-shadow-2xl '>
                        <div className='' >
                            <h1 className='text-2xl font-bold'>1710</h1>
                            <h1 className='text-lg font-light' >On-Farm Markets</h1>
                        </div>
                    </div>

                    <div className='bg-[#16341fec] text-[#fff9e1] py-4  w-48 rounded-r-xl bg-[#fff9e1] drop-shadow-2xl '>
                        <div className='' >
                            <h1 className='text-2xl font-bold'>230</h1>
                            <h1 className='text-lg font-light' >Food Hubs</h1>
                        </div>
                    </div>

                </div>    

            </div>

            {/********************************************/}
            {/********************* SECOND PAGE MOBILE - END ***********************/}
            {/********************************************/}

        </div>

    </div>

    {/********************************************/}
    {/********************************************/}
    {/********************* MOBILE VERSION END ***********************/}
    {/********************************************/}
    {/********************************************/}




    {/********************************************/}
    {/********************************************/}
    {/********************* DESKTOP VERSION START ***********************/}
    {/********************************************/}
    {/********************************************/}

    <div className='lg:visible invisible max-sm:hidden items-center justify-center ' >

        {/********************************************/}
        {/********************* FIRST PAGE DESKTOP - START ***********************/}
        {/********************************************/}
    <div className='' >

        <div className='w-screen h-full flex flex-col max-sm:justify-start justify-start '>

            <div className=' w-screen h-full pb-6 max-sm:pb-10 flex flex-row bg-slate-50'>

                    {/* ----------------------- SIDEBAR MENU - START  ----------------------- */}

                    <div className='sticky top-0 border-r-2 h-screen bg-white border-slate-600 flex flex-col justify-between pt-5 text-lg font-light'>

                        <div>
                            <div className=' pb-10' >
                                <h1 className='max-sm:text-2xl max-sm:font-light text-4xl font-semibold text-slate-700 align-middle' >AsirisOS</h1>
                            </div>

                            <Link to='/asiris-v2-demo-family' className='bg-slate-100 py-1 pl-4 pr-16 flex justify-start drop-shadow-lg'  > 
                                    <h1 className=''>Family</h1>
                            </Link>

                            <Link to='/asiris-v2-demo-assets' className='  py-1 pl-4 pr-16 flex justify-start' > 
                                <h1 className=''>Assets</h1>
                            </Link>

                            <Link to='/asiris-v2-demo-community' className='  py-1 pl-4 pr-16 flex justify-start' > 
                                <h1>Community</h1>
                            </Link>
                        </div>

                        <div className='pb-5'>
                            <Link to='/asiris-v2-demo-settings' className='  py-1 pl-4 pr-16 flex justify-start' > 
                                <h1>Settings</h1>
                            </Link>

                            <Link to='/asiris-v2-demo-payments' className='  py-1 pl-4 pr-16 flex justify-start' > 
                                <h1>Payments</h1>
                            </Link>

                            <Link to='/asiris-v2-sign-out' className='  py-1 pl-4 pr-16 flex justify-start' > 
                                <h1>Sign-out</h1>
                            </Link>
                        </div>

                    </div>

                    {/* ----------------------- SIDEBAR MENU - END  ----------------------- */}


                    
                    <div className=' w-full h-full flex flex-col'>


                        {/* ----------------------- FAMILY MEMBERS - START  ----------------------- */}

                        <div className='flex flex-row justify-center  gap-10 pt-10 '>
         
                            <Link to='/asiris-v2-demo-john-profile'>
                                <div id="item1" className='w-44'>
                                    <div className='text-[#16341fec] bg-[#ffffff] drop-shadow-xl rounded-lg'>
                                        <div className=''>
                                            <img className='rounded-t-lg' src='https://t4.ftcdn.net/jpg/02/24/86/95/360_F_224869519_aRaeLneqALfPNBzg0xxMZXghtvBXkfIA.jpg' alt="" />                                
                                        </div>

                                        <div className='flex flex-col '>
                                            <div className=' pt-2 '>
                                                <h1 className='text-2xl font-normal'>John</h1>
                                                <h1 className='text-sm font-semibold' ></h1>
                                            </div>
                                            <hr/>
                                            <div className='self-center  pb-3 rounded-b-lg px-2'>
                                                <h1 className='pt-2 text-md font-light text-center'>  </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>

                                <div id="item2" className='w-44'>
                                    <div className='text-[#16341fec] bg-[#ffffff] drop-shadow-xl rounded-lg    '>
                                        <div className=''>
                                            <img className='rounded-t-lg' src='https://media.istockphoto.com/id/1326417862/photo/young-woman-laughing-while-relaxing-at-home.webp?b=1&s=612x612&w=0&k=20&c=NtACO2C_PaNixTAiOwnbi81cAJE7QnUdjwnZboaCElo=' alt="" />                                
                                        </div>

                                        <div className='flex flex-col '>
                                            <div className=' pt-2 '>
                                                <h1 className='text-2xl font-normal'>Lilly</h1>
                                                <h1 className='text-lg font-semibold' ></h1>
                                            </div>
                                            <hr/>
                                            <div className='self-center  pb-3 rounded-b-lg px-2'>
                                                <h1 className='pt-2 text-md font-light text-center' ></h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="item3" className='w-44'>
                                    <div className='text-[#16341fec] bg-[#ffffff] drop-shadow-xl rounded-lg    '>
                                        <div className=''>
                                            <img className='rounded-t-lg' src='https://media.istockphoto.com/id/1271410473/photo/portrait-of-a-happy-latin-american-boy-smiling.jpg?s=612x612&w=0&k=20&c=qF3aC7zbtN_VRnJaomaG25L0zXpFaX1EMUy_OXiLPN8=   ' alt="" />                                
                                        </div>

                                        <div className='flex flex-col '>
                                            <div className=' pt-2 '>
                                                <h1 className='text-2xl font-normal'>Alex</h1>
                                                <h1 className='text-lg font-semibold' ></h1>
                                            </div>
                                            <hr/>
                                            <div className='self-center  pb-3 rounded-b-lg px-2'>
                                                <h1 className='pt-2 text-md font-light text-center' ></h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="item3" className='w-44'>
                                    <div className='text-[#16341fec] bg-[#ffffff] drop-shadow-xl rounded-lg    '>
                                        <div className=''>
                                            <img className='rounded-t-lg' src='https://media.istockphoto.com/id/502132678/photo/funny-baby-girl.jpg?s=612x612&w=0&k=20&c=3WIUKnZhOtbsxRqdqdUhq9l05Q6EpFlK08Ps-bLYt5Y=' alt="" />                                
                                        </div>

                                        <div className='flex flex-col '>
                                            <div className=' pt-2 '>
                                                <h1 className='text-2xl font-normal'>Mia</h1>
                                                <h1 className='text-lg font-semibold' ></h1>
                                            </div>
                                            <hr/>
                                            <div className='self-center  pb-3 rounded-b-lg px-2'>
                                                <h1 className='pt-2 text-md font-light text-center' ></h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="item3" className='w-44'>
                                    <div className='text-[#16341fec] bg-[#ffffff] drop-shadow-xl rounded-lg    '>
                                        <div className=''>
                                            <img className='rounded-t-lg' src='https://cf.ltkcdn.net/dogs/dog-breeds/images/orig/324775-1600x1066-labrador-retriever-breed.jpg' alt="" />                                
                                        </div>

                                        <div className='flex flex-col '>
                                            <div className=' pt-2 '>
                                                <h1 className='text-2xl font-normal'>Luna</h1>
                                                <h1 className='text-lg font-semibold' ></h1>
                                            </div>
                                            <hr/>
                                            <div className='self-center  pb-3 rounded-b-lg px-2'>
                                                <h1 className='pt-2 text-md font-light text-center' ></h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              
                        </div>

                        {/* ----------------------- FAMILY MEMBERS - END  ----------------------- */}


                        {/* ----------------------- TOPIC SELECTION - START  ----------------------- */}

                        <div className='h-screen flex flex-col items-center justify-start py-5  '>

                            {/* *********************** TAB SELECTIONS *********************** */}

                            <div className='mb-5  border-t-0 w-fit rounded-xl drop-shadow-lg pt-10 '>

                                <ul className="flex felx-row justify-left cursor-pointer">
                                    <div onClick={() => toggleTab(1)} className={`${selection === 1 ? 'bg-[#161a7b] text-white  pb-3  w-40 rounded-l-lg' : 'bg-[#c3ceea] text-[#11627]  pb-3  w-40 rounded-l-lg'}`}>
                                        <div className='pt-3' >
                                            <h1 className='text-xl font-bold'>Callendar</h1>
                                            <h1 className='text-md font-light' ></h1>
                                        </div>
                                    </div>

                                    <div onClick={() => toggleTab(2)} className={`${selection === 2 ? 'bg-[#161a7b] text-white  pb-3  w-40' : 'bg-[#c3ceea] text-[#11627] pb-3  w-40 '}`}>
                                        <div className='pt-3' >
                                            <h1 className='text-xl font-bold'>Tasks List</h1>
                                            <h1 className='text-md font-light' ></h1>
                                        </div>
                                    </div>

                                    <div onClick={() => toggleTab(3)} className={`${selection === 3 ? 'bg-[#161a7b] text-white  pb-3  w-40' : 'bg-[#c3ceea] text-[#11627] pb-3  w-40 '}`}>
                                        <div className='pt-3' >
                                            <h1 className='text-xl font-bold'>Kan Ban</h1>
                                            <h1 className='text-md font-light' ></h1>
                                        </div>
                                    </div>

                                    <div onClick={() => toggleTab(4)} className={`${selection === 4 ? 'bg-[#161a7b] text-white  pb-3 rounded-r-lg   w-40' : 'bg-[#c3ceea] rounded-r-lg text-[#11627] pb-3   w-40 '}`}>
                                        <div className='pt-3' >
                                            <h1 className='text-xl font-bold'>Finances</h1>
                                            <h1 className='text-md font-light' ></h1>
                                        </div>
                                    </div>


                                </ul>
                            </div>


                            {/* *********************** TAB SELECTIONS *********************** */}

                            {selection === 1 ? (
                            <>
                            <div className='  py-3 px-5 rounded-xl ' >
                                <h1 className='text-2xl font-light max-w-3xl text-start'>Our engines are developed entirely in a low-level language to squeeze every bit of performance from the hardware we run on.</h1>
                                <h1 className='text-md font-light max-w-3xl text-start pt-3' >Running on an average laptop - Lenovo usung Intel i7 @3Ghz - our engine can process speech transcription 7-17 times faster than realtime</h1>
                            </div>
                            </>
                            ) : (<></>)}



                            {selection === 2 ? (
                            <>
                            <div className='  py-3 px-5 rounded-xl ' >
                                <h1 className='text-2xl font-light max-w-3xl text-start'>Our engines do not require any transmission of data protecting you from security and privacy volnurabilities</h1>
                                <h1 className='text-md font-light max-w-2xl text-start pt-3' >Lightweight nature of our engines allow for the entire operation to run on your device</h1>
                            </div>
                            </>
                            ) : (<></>)}


                            {selection === 3 ? (
                            <>
                            <div className='  py-3 px-5 rounded-xl ' >
                                <h1 className='text-2xl font-light max-w-3xl text-start'>Developed over 20 years for maximum efficiency our engines can be instantly scaled to meet your demands</h1>
                                <h1 className='text-md font-light max-w-2xl text-start pt-3' >All you need to do is extend the number of devices our engines run on</h1>
                            </div>
                            </>
                            ) : (<></>)}


                            {selection === 4 ? (
                            <>
                            <div className=' py-3 px-5 rounded-xl' >
                                <h1 className='text-2xl font-light max-w-3xl text-start' >Our extensive API with detailed documentation allow you to easily integrate our AI solutions into your products</h1>
                                {/* <h1 className='text-lg font-light' >Manage your food supply chain with local hubs</h1> */}
                            </div>
                            </>
                            ) : (<></>)}


                        </div>

                        {/* ----------------------- TOPIC SELECTION - END  ----------------------- */}


                    </div>
            </div>

        </div>

        {/********************************************/}
        {/********************* FIRST PAGE DESKTOP - END ***********************/}
        {/********************************************/}

    </div>
    </div>
    {/********************************************/}
    {/********************************************/}
    {/********************* DESKTOP VERSION END ***********************/}
    {/********************************************/}
    {/********************************************/}
    </>
  )
}

export default Family