import axios from "axios";
import { UserV2Model} from "@asirisos/types";
import RequestBodyFactory from "../../factories/requestBodyFactory";

const API_URL = '/api/user-v2'

// Add User V2

const addUserV2 = async(addUserData: UserV2Model) => {
    console.log("Join Team service - add join team -  join team data", addUserData)
    const body = new RequestBodyFactory(addUserData).Build()
    console.log("Join Team Market service - sending data - add join team - body", body)
    return await axios.post(API_URL + '/add-user-v2', body)
}

const countUserV2 = async () => {
    return await axios.get(API_URL + '/countReservedSpots')
}

const userV2Service = {
    addUserV2,
    countUserV2
}

export default userV2Service