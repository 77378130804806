import React from 'react'
import { Link } from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {AppDispatch, RootState} from "../../../app/store";
import TaskKanBanCard from './TaskKanBanCard';

function TaskKanBan() {

    const {tasks, status} = useSelector((state : RootState) => state.taskTask )

    const tasks25 = tasks?.filter((task) => task.taskStatus === '25' )
    const tasks50 = tasks?.filter((task) => task.taskStatus === '50' )
    const tasks75 = tasks?.filter((task) => task.taskStatus === '75' )
    const tasks100 = tasks?.filter((task) => task.taskStatus === '100' )

  return (
    <>
        <div className='bg-white drop-shadow-lg rounded-tl-none py-8' >
            <div className='grid grid-cols-4 gap-3  ' >

                {/*/////////////// STARTED //////////////////*/}

                <div className='py-7 my-3 mx-3 rounded-lg shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]' >
                    <h1>Started</h1>
                    {tasks25.map((task) => (
                    <>
                        <TaskKanBanCard id={task._id} title={task.title} description={task.description} taskOwner={task.assignedToObject!.name} deadline={task.deadline}  />
                    </>
                    ) )}
                </div>

                {/*/////////////// IN PROGRESS //////////////////*/}
                <div className='py-7 my-3 mx-3 rounded-lg shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]' >
                    <h1>In Progress</h1>
                    {tasks50.map((task) => (
                    <>
                        <TaskKanBanCard id={task._id} title={task.title} description={task.description} taskOwner={task.assignedToObject!.name} deadline={task.deadline}  />
                    </>
                    ) )}
                </div>

                {/*/////////////// REVIEW //////////////////*/}
                <div className='py-7 my-3 mx-3 rounded-lg shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]' >
                    <h1>Review</h1>
                    {tasks75.map((task) => (
                    <>
                        <TaskKanBanCard id={task._id} title={task.title} description={task.description} taskOwner={task.assignedToObject!.name} deadline={task.deadline}  />
                    </>
                    ) )}
                </div>

                {/*/////////////// COMPLETED //////////////////*/}
                <div className='py-7 my-3 mx-3 rounded-lg shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] ' > 
                    <h1>Completed</h1>
                    {tasks100.map((task) => (
                    <>
                        <TaskKanBanCard id={task._id} title={task.title} description={task.description} taskOwner={task.assignedToObject!.name} deadline={task.deadline}  />
                    </>
                    ) )}
                </div>
            </div>
              </div>
    </>
  )
}

export default TaskKanBan