import React, {ChangeEvent} from 'react'
import InlineEditCheckBox from 'UI/components/shared/InlineEditCheckBox'
import InlineEdit from 'UI/components/shared/InlineEdit'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import FormTwoInputs from 'UI/components/shared/FormTwoInputs'
import FormCard from 'UI/components/shared/FormCard'
import NumberInputInUnits from 'UI/components/shared/NumberInputInUnits'
import LargeTextInput from 'UI/components/shared/LargeTextInput'

function ApplyAsVendor() {

    const {t} = useTranslation(["farmersmarket", "translation"])


    const [preAppData, setPreAppData] = useState({
        //   FarmId: '',
        insurance: 'false',
        stateLicense: 'false',
        cityApproval: 'false'
    })

    const {
        insurance,
        stateLicense,
        cityApproval
    } = preAppData


    const onPreAppChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {

        // console.log(e.target)
        // console.log(e, 'e')
        // console.log('name', e.target.name)
        // console.log('value', e.target.value)
        

        if (e.target.value === 'false') {

            setPreAppData((prevState) => ({
                ...prevState,
                [e.target.name]: 'true'
            }))
            // Blur(e)
        }
       

        if (e.target.value === 'true') {

            setPreAppData((prevState) => ({
                ...prevState,
                [e.target.name]: 'false'
            }))
            // Blur(e)
        }
        const target = e.target as HTMLInputElement
    }

    const [formData, setFormData] = useState({
        name: '',
        farmName: '',
        email: '',
        phone: '',
        products: '',
        additionalInfo: '',
        checkList: preAppData
    })

    const {name, farmName, email, phone, products, additionalInfo} = formData


    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {

        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
    }


  return (
    <>
    <div>
        <h1>Apply to become a Vendor at </h1>
        <div>
            <h1>Pre Application Checklist</h1>
            <h2>Make sure you complete all of the following before you apply</h2>

            <FormCard title='Apply as Vendor'>
            <form onSubmit={onSubmit}>
                {/* ----------------- PRE-APP CHECKLIST -----------------  */}
                <div>

                    {/* //////////////////////////////// INSURANCE /////////////////////////////////////// */}
                    <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                        <div>
                            <h1 className='font-regular text-2xl text-start pl-8'>Insurance</h1>
                        </div>

                        <div>
                            <InlineEditCheckBox onChange={(e) => onPreAppChange(e)} value={insurance} id={"insurance"} name={"insurance"} label={""} />
                        </div>
                    </div>
                    {/* //////////////////////////////// INSURANCE /////////////////////////////////////// */}


                    {/* //////////////////////////////// STATE LICENSE  /////////////////////////////////////// */}
                    <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                        <div>
                            <h1 className='font-regular text-2xl text-start pl-8'>State license</h1>
                        </div>

                        <div>
                            <InlineEditCheckBox onChange={(e) => onPreAppChange(e)} value={stateLicense} id={"stateLicense"} name={"stateLicense"} label={""} />
                        </div>
                    </div>
                    {/* //////////////////////////////// STATE LICENSE /////////////////////////////////////// */}

                    {/* //////////////////////////////// CITY APPROVAL  /////////////////////////////////////// */}
                    <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                        <div>
                            <h1 className='font-regular text-2xl text-start pl-8'>City approval</h1>
                        </div>

                        <div>
                            <InlineEditCheckBox onChange={(e) => onPreAppChange(e)} value={cityApproval} id={"cityApproval"} name={"cityApproval"} label={""} />
                        </div>
                    </div>
                    {/* //////////////////////////////// CITY APPROVAL /////////////////////////////////////// */}

                </div>
                {/* ----------------- PRE-APP CHECKLIST -----------------  */}




                {/* ----------------- APPLICATION FORM -----------------  */}
                <div>
                
                <div className='col-span-2 justify-start justify-self-start w-2/3 '>
                    <div className='py-2' ><NumberInputInUnits onChange={(e) => onChange(e)} value={name} id={"name"} type={'text'} group={"name"} label={"name"} name={"name"} dataTitle="Name"/></div>
                    <div className='py-2' ><NumberInputInUnits onChange={(e) => onChange(e)} value={email} id={"email"} type={'text'} group={"email"} label={"email"} name={"Email"} dataTitle="Email"/></div>
                    <div className='py-2' ><NumberInputInUnits onChange={(e) => onChange(e)} value={phone} id={"phone"} type={'text'} group={"phone"} label={"phone"} name={"phone"} dataTitle="Phone"/></div>
                    <div className='py-2' ><NumberInputInUnits onChange={(e) => onChange(e)} value={products} id={"products"} type={'text'} group={"products"} label={"products"} name={"products"} dataTitle="Products"/></div>
                    <div className='py-2' ><NumberInputInUnits onChange={(e) => onChange(e)} value={farmName} id={"farmName"} type={'text'} group={"farmName"} label={"farmName"} name={"farmName"} dataTitle="Farm Name"/></div>
                    <div className='py-2' ><LargeTextInput onChange={(e) => onChange(e)} value={additionalInfo} id={"additionalInfo"} type={'text'} group={"additionalInfo"} label={"additionalInfo"} name={"additionalInfo"} dataTitle="Additional Info"  /></div>
                </div> 
                </div>

                {/* ----------------- APPLICATION FORM -----------------  */}

                <div className='flex justify-center pt-8 p-2'>
                    <button className="btn btn-wide btn-primar hover:bg-indigo-700">Apply</button>
                </div>


            </form>
            </FormCard>
        </div>
        
    </div>
    </>
  )
}

export default ApplyAsVendor