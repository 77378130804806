import React from 'react'
import {FaSignInAlt, FaSignOutAlt, FaUser} from 'react-icons/fa'
import {Link, useNavigate} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
// import {logout, reset} from '../../features/auth/authSlice'
// import {reset as resetAccounts} from '../../features/accounts/accountSlice'
// import {AppDispatch, RootState, store} from "../../app/store";
// import {useAuthStatus} from "../../hooks/useAuthStatus";

function FarmProfileHeader() {

    const navigate = useNavigate()
    // const dispatch = useDispatch<AppDispatch>();
    // const {loggedIn} = useAuthStatus()


    const onLogout = () => {
        localStorage.clear()
        navigate('/login')
        // dispatch(reset())
        // dispatch(resetAccounts())
    }

  return (
    <>
    <div className='justify-center flex' >
    <header className="w-3/4 flex flex-row justify-between border-b-2  px-10 pt-5 pb-2 mb-2">
        <div className="flex self-center">
            <h1 className='font-normal text-3xl'>Your Farm LOGO</h1>
        </div>
        <div >
            <ul className='flex flex-row space-x-14 ' >
                    <li className='font-light text-2xl'>
                        About
                    </li>
                    <li className='font-light text-2xl'>
                        Products
                    </li>
                    <li className='font-light text-2xl'>
                        Blog
                    </li>
                    <li className='font-light text-2xl'>
                        Contact
                    </li>
                    
            </ul>
        </div>
    </header>
    </div>
    
    </>


  )
}

export default FarmProfileHeader