import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useParams, useNavigate} from 'react-router-dom'
import { deleteSale, getSale, updateSale } from 'features/sales/saleSlice'
import {toast} from 'react-toastify'
import Spinner from '../../components/Spinner'
import InlineEdit from '../../components/shared/InlineEdit'
import InlineEditDropDown from '../../components/shared/InlineEditDropDown'
import {AppDispatch, RootState} from "../../../app/store";
import { getActivityByConnectionId } from 'features/activity/activitySlice'
import {Status} from "../../../types/slice";
import {useTranslation} from "react-i18next";
import ItemPageDeleteButton from 'UI/components/ItemPageDeleteButton'
import DeleteModal from 'UI/components/DeleteModal'
import {SaleModel} from "@asirisos/types";
import ChangeActivityList from '../Activity/ChangeActivityList'

function SalePage() {
    const {t} = useTranslation("sale");
    const navigate = useNavigate()

    const categorySelection = [
        {name: t("sale:general"), id: 'general'},
        {name: t("sale:crop"), id: 'crop'},
        {name: t("sale:equipment"), id: 'equipment'},
        {name: t("sale:services"), id: 'services'}]

        

    const {saleId} = useParams()
    const dispatch = useDispatch<AppDispatch>()
    const {sale, status} = useSelector((state: RootState) => state.sale)
    const {allActivity} = useSelector((state: RootState) => state.activity)

    useEffect(() => {
        if (saleId)
            dispatch(getSale(saleId))
            dispatch(getActivityByConnectionId(saleId!))
    }, [dispatch, saleId])



    if (status === Status.Failure) {
        toast.error(t("sale:saleGetFailed"))
    }

    const [formData, setFormData] = useState({
        customer: '',
        category: '',
        item: '',
        quantity: '',
        saleStatus: '',
        comments: '',
    })

    const [changedData, setChangedData] = useState({
        field: ''
    })

    const {field} = changedData

    let changedField : string

    useEffect(() => {
        if (status !== Status.Pending && sale !== undefined)
            dispatch(getActivityByConnectionId(saleId!))
    }, [saleId, status])

    useEffect(() => {
        if (status !== Status.Pending && sale !== undefined) setFormData((prevState) => ({
            ...prevState,
            customer: sale.customer,
            category: sale.category,
            item: sale.item,
            quantity: sale.quantity,
            saleStatus: sale.saleStatus,
            comments: sale.comments,
        }));
    }, [status])

    const {customer, category, item, quantity, saleStatus, comments} = formData

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setChangedData(() => ({
            field: e.target.name 
        }))

        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))

    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        e.preventDefault()
        console.log('handle Change', e.target.name, e.target.value)
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))

        const saleData: SaleModel = {
            // type: e.target.value,
            customer,
            category: e.target.value,
            item,
            quantity,
            saleStatus,
            comments,
            _id:saleId,
            changedField: e.target.id,
        }

        dispatch(updateSale(saleData))
            .unwrap()
            .then(() => {
                toast.success(t("sale:saleUpdated"))
            })
            .catch(() => {
                toast.error(t("sale:saleUpdateFailed"))
            })
    }

    const onKeyDown = (e: React.KeyboardEvent) => {
        console.log(e)
        if (e.key === "Enter" || e.key === "Escape") {
            console.log('Key Down Hit');
            (e.target as HTMLElement).blur();
        }
    }

    const onBlur = (e: React.FocusEvent) => {
        e.preventDefault()
        console.log('On Blur Hit')
        const saleData : SaleModel = {
            customer,
            category,
            item,
            quantity,
            saleStatus,
            comments,
            _id:saleId,
            changedField: changedData.field,
        }

        dispatch(updateSale(saleData))
            .unwrap()
            .then(() => {
                toast.success(t("sale:saleUpdated"))
            })
            .catch(() => {
                toast.error(t("sale:saleUpdateFailed"))
            })
    }

    const onDelete = () => {
        dispatch(deleteSale([saleId!]))
        .unwrap()
        .then(() => {
            toast.success(t("sale:saleDeleted"));
            navigate("/sales")
        })
        .catch(() => {
            toast.error(t("sale:failedToDeletesale"))
        })
    }

    const [modalIsOpen, setModalIsOpen] = useState(false)

    const onDeleteClick = (e: any) => {
        setModalIsOpen(!modalIsOpen)
        console.log(modalIsOpen)
    }

    if (status === Status.Pending && sale?.customer != '' && sale?.comments != '') {
        return <Spinner/>
    } else {
        return (
            <>
                <div className="bg-white py-5 rounded-lg #drop-shadow-xl #shadow-lg relative px-9 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] mx-5  ">
                    <div className="grid grid-cols-3 gap-3">
                        <div className='-my-1 col-span-1 justify-self-center self-center'>
                            <img className="h-36 w-34 bg-indigo-400 shadow-xl rounded-xl"
                                 src="https://www.pngmart.com/files/22/Robot-Arm-PNG-Free-Download.png" alt=""/>
                        </div>
                        <div className='col-span2 justify-start justify-self-start'>
                            <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                        onKeyDown={(e) => onKeyDown(e)} title={t("translation:customer")} value={customer} id={"customer"}
                                        name={"customer"}/>
                            <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                        onKeyDown={(e) => onKeyDown(e)} title={t("equipment:quantity")} value={quantity} id={"quantity"}
                                        name={"quantity"}/>
                            <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                        onKeyDown={(e) => onKeyDown(e)} title={t("equipment:comments")} value={comments} id={"comments"}
                                        name={"comments"}/>
                            <InlineEditDropDown onChange={(e) => handleChange(e)}
                                                onKeyDown={(e) => onKeyDown(e)} selections={categorySelection}
                                                value={category} id={"category"} title={"category"} name={t("translation:category")}/>
                        </div>
                    </div>
                {/* ////////////////////////////////////////////////////////////////////// */}
                {/* //////////////////////////// DELETE BUTTON /////////////////////////// */}

                <ItemPageDeleteButton onDeleteClick={onDeleteClick} />
                </div>

                {/* ////////////////////////////////////////////////////////////////////// */}
                {/* //////////////////////////// DELETE MODAL //////////////////////////// */}

                <DeleteModal modalIsOpen={modalIsOpen} name={sale?.quantity!} onDeleteClick={onDeleteClick} onDelete={onDelete} />

                {/* ////////////////////////////////////////////////////////////////////// */}
                {/* //////////////////////////// ACTIVITY LOG //////////////////////////// */}
                
                <ChangeActivityList allActivity={allActivity} page={'Sale'} />
            </>
        )
    }
}


export default SalePage