import {createSlice, createAsyncThunk, PayloadAction} from "@reduxjs/toolkit";
import activityService from "./activityService";
import {FixMeLater} from "../../types/dev";
import {ActivityState} from "../../types/states";
import HandleAxiosError from "../../utils/AxiosErrorHandler";
import {DefaultThunkApiConfig, Status} from "../../types/slice";
import {ActivityModel} from "@asirisos/types";
import {ActivityCategory} from "@asirisos/types/data";

const CONTEXT = "Activity"

const initialState: ActivityState = {
    activity: undefined,
    allActivity: [],
    status: Status.Unknown,
    message: ''
}

export const addActivity = createAsyncThunk<any, ActivityModel, DefaultThunkApiConfig>('activity/add', async (activityData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await activityService.addActivity(activityData, account)
        if (response.status !== 201)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getAllActivity = createAsyncThunk<ActivityModel[], void, DefaultThunkApiConfig>('activity/getall', async (_, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await activityService.getAllActivity(account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getActivity = createAsyncThunk<ActivityModel, string, DefaultThunkApiConfig>('activity/getone/:activityId', async (activityId, thunkAPI) => {
    try {
        const response = await activityService.getActivity(activityId)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getActivityByConnectionId = createAsyncThunk<ActivityModel[], string,  DefaultThunkApiConfig>('activityByConnId/:connId', async (connectionId, thunkAPI) => {
    try {
        const response = await activityService.getActivityByConnectionId(connectionId)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const updateActivity = createAsyncThunk<ActivityModel, ActivityModel, DefaultThunkApiConfig>('activity/update/:activityid', async (activityData, thunkAPI) => {
    try {
        const response = await activityService.updateActivity(activityData)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const deleteActivity = createAsyncThunk<any, string, DefaultThunkApiConfig>('activity/delete', async (activityId, thunkAPI) => {
    try {
        const response = await activityService.deleteActivity(activityId)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getCategoryActivity = createAsyncThunk<ActivityModel[], ActivityCategory, DefaultThunkApiConfig>('activity/getallcategory', async (activityCategory, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await activityService.getCategoryActivity(activityCategory, account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const activitySlice = createSlice({
    name: 'activity',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder
        .addCase(addActivity.pending, (state: ActivityState) => {
            state.status = Status.Pending
        })
        .addCase(addActivity.fulfilled, (state: ActivityState) => {
            state.status = Status.Success
        })
        .addCase(addActivity.rejected, (state: ActivityState) => {
            state.status = Status.Failure
        })
        .addCase(getAllActivity.pending, (state: ActivityState) => {
            state.status = Status.Pending
        })
        .addCase(getAllActivity.fulfilled, (state: ActivityState, action: PayloadAction<ActivityModel[], any, any>) => {
            state.status = Status.Success
            state.allActivity = action.payload
        })
        .addCase(getAllActivity.rejected, (state: ActivityState, action: FixMeLater) => {
            state.status = Status.Failure
            state.message = action.payload
        })
        .addCase(getActivityByConnectionId.pending, (state: ActivityState) => {
            state.status = Status.Pending
        })
        .addCase(getActivityByConnectionId.fulfilled, (state: ActivityState, action: PayloadAction<ActivityModel[], any, any>) => {
            state.status = Status.Success
            state.allActivity = action.payload
        })
        .addCase(getActivityByConnectionId.rejected, (state: ActivityState, action: FixMeLater) => {
            state.status = Status.Failure
            state.message = action.payload
        })
        .addCase(getCategoryActivity.pending, (state: ActivityState) => {
            state.status = Status.Pending
        })
        .addCase(getCategoryActivity.fulfilled, (state: ActivityState, action: PayloadAction<ActivityModel[], any, any>) => {
            state.status = Status.Success
            state.allActivity = action.payload
        })
        .addCase(getCategoryActivity.rejected, (state: ActivityState) => {
            state.status = Status.Failure
        })
        .addCase(getActivity.pending, (state: ActivityState) => {
            state.status = Status.Pending
        })
        .addCase(getActivity.fulfilled, (state: ActivityState, action: PayloadAction<ActivityModel, any, any>) => {
            state.status = Status.Success
            state.activity = action.payload
        })
        .addCase(getActivity.rejected, (state: ActivityState, action: FixMeLater) => {
            state.status = Status.Failure
            state.message = action.payload
        })
        .addCase(updateActivity.pending, (state: ActivityState) => {
            state.status = Status.Pending
        })
        .addCase(updateActivity.fulfilled, (state: ActivityState, action: PayloadAction<ActivityModel, any, any>) => {
            state.status = Status.Success
            state.allActivity.map((activity: ActivityModel) => activity._id === action.payload._id ?
                (state.activity = activity) : activity)
            state.activity = action.payload
        })
        .addCase(updateActivity.rejected, (state: ActivityState) => {
            state.status = Status.Failure
        })
        .addCase(deleteActivity.pending, (state: ActivityState) => {
            state.status = Status.Pending
        })
        .addCase(deleteActivity.fulfilled, (state: ActivityState) => {
            state.status = Status.Success
            state.activity = undefined
        })
        .addCase(deleteActivity.rejected, (state: ActivityState) => {
            state.status = Status.Failure
        })
    }
})

export const {reset} = activitySlice.actions
export default activitySlice.reducer