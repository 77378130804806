import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import organizationService from "./organizationService";
import {FixMeLater} from "../../types/dev";
import {OrganizationState} from "../../types/states";
import HandleAxiosError from "../../utils/AxiosErrorHandler";
import {DefaultThunkApiConfig, Status} from "../../types/slice";
import {OrganizationModel} from "@asirisos/types";

const CONTEXT = "Organization"

const initialState: OrganizationState = {
    organization: undefined,
    organizations: [],
    status: Status.Unknown,
    message: ''
}

export const addOrganization = createAsyncThunk<any, OrganizationModel, DefaultThunkApiConfig>('organization/add', async (organizationData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await organizationService.addOrganization(organizationData, account)
        if (response.status !== 201)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getOrganizations = createAsyncThunk<OrganizationModel[], void, DefaultThunkApiConfig>('organization/getall', async (_, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await organizationService.getOrganizations(account)
        console.log("Organization Slice - Get All - response", response)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getOrganization = createAsyncThunk<OrganizationModel, string, DefaultThunkApiConfig>('organization/getone/:organizationId', async (organizationId, thunkAPI) => {
    try {
        const response = await organizationService.getOrganization(organizationId)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const updateOrganization = createAsyncThunk<OrganizationModel, OrganizationModel, DefaultThunkApiConfig>('organization/updateorganization/:organizationId', async (organizationData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if(!account)
            return thunkAPI.rejectWithValue();
            
        const response = await organizationService.updateOrganization(organizationData, account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
    // try {
    //     const token = thunkAPI.getState().auth.user.token
    //     return await organizationService.updateOrganization(organizationData, token)
    // } catch (error) {
    //     HandleAxiosError(error, CONTEXT);
    //     return thunkAPI.rejectWithValue("message")
    // }
})

export const deleteOrganization = createAsyncThunk<any, string[], DefaultThunkApiConfig>('organization/delete/:organizationId', async (organizationId, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if(!account)
            return thunkAPI.rejectWithValue();

            console.log('Test account in delete Storage', account, organizationId)
        const response = await organizationService.deleteOrganization(organizationId, account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const organizationSlice = createSlice({
    name: 'organization',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(addOrganization.pending, (state: OrganizationState) => {
                state.status = Status.Pending
            })
            .addCase(addOrganization.fulfilled, (state: OrganizationState) => {
                state.status = Status.Success
            })
            .addCase(addOrganization.rejected, (state: OrganizationState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            .addCase(getOrganizations.pending, (state: OrganizationState) => {
                state.status = Status.Pending
            })
            .addCase(getOrganizations.fulfilled, (state: OrganizationState, action: PayloadAction<OrganizationModel[], any, any>) => {
                state.status = Status.Success
                state.organizations = action.payload
            })
            .addCase(getOrganizations.rejected, (state: OrganizationState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            .addCase(getOrganization.pending, (state: OrganizationState) => {
                state.status = Status.Pending
            })
            .addCase(getOrganization.fulfilled, (state: OrganizationState, action: PayloadAction<OrganizationModel, any, any>) => {
                state.status = Status.Success
                state.organization = action.payload
            })
            .addCase(getOrganization.rejected, (state: OrganizationState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            .addCase(updateOrganization.pending, (state: OrganizationState) => {
                state.status = Status.Pending
            })
            .addCase(updateOrganization.fulfilled, (state: OrganizationState, action: PayloadAction<OrganizationModel, any, any>) => {
                state.status = Status.Success
                state.organizations.map((organization: OrganizationModel) => organization._id === action.payload._id ?
                    (state.organization = organization) : organization)
                state.organization = action.payload
            })
            .addCase(updateOrganization.rejected, (state: OrganizationState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            // .addCase(updateOrganization.fulfilled, (state: OrganizationState, action: FixMeLater) => {
            //     state.isLoading = false
            //     state.organizations.map((field) => organization._id === action.payload._id ? (state.organization = field) : field)
            // })
            .addCase(deleteOrganization.pending, (state: OrganizationState) => {
                state.status = Status.Pending
            })
            .addCase(deleteOrganization.fulfilled, (state: OrganizationState) => {
                state.status = Status.Success
                state.organization = undefined
            })
            .addCase(deleteOrganization.rejected, (state: OrganizationState) => {
                state.status = Status.Failure
            })
    }
})

export const {reset} = organizationSlice.actions
export default organizationSlice.reducer