import axios from "axios"
import {AccountModel, ExpenseModel} from "@asirisos/types";
import RequestBodyFactory from "../../factories/requestBodyFactory";

const API_URL = '/api/expenses'


const addExpense = async (expenseData: ExpenseModel, accountData: AccountModel) => {
    const body = new RequestBodyFactory(expenseData).WithAccount(accountData).Build();
    return await axios.post(API_URL + '/', body)
}

const getExpenses = async (account: AccountModel) => {
    return await axios.get(API_URL + '/all/' + account.farm._id + '/' + account.user._id)
}

const getExpense = async (expenseId: string) => {
    return await axios.get(API_URL + '/' + expenseId)
}

const updateExpense = async (expenseData: ExpenseModel , account: AccountModel) => {
    const {_id} = expenseData
    
    const data ={
        expenseData: expenseData,
        account: account
    }

    return await axios.put(API_URL + '/' + _id, data)
}

const deleteExpense = async (expenseId: string[]) => {
    const ids = expenseId
    const config = {
        data: ids
    }

    return await axios.delete(API_URL + '/' + expenseId, config)
}

const expenseService = {
    addExpense,
    getExpenses,
    getExpense,
    updateExpense,
    deleteExpense
}

export default expenseService