import React, {useState} from 'react'
import {FaSignInAlt} from 'react-icons/fa'
import {useNavigate, Link, useLocation} from 'react-router-dom'
import {toast} from 'react-toastify'
import {useSelector, useDispatch} from 'react-redux'
import { getOrgZipLocations } from 'features/orgLocations/orgLocationsSlice'
import Spinner from '../../components/Spinner'
import {AppDispatch, RootState} from "../../../app/store";
import FormCard from 'UI/components/shared/FormCard'
import NumberInputInUnits from 'UI/components/shared/NumberInputInUnits'
import {useTranslation} from "react-i18next";
import {SetAxiosAuthHeader} from "../../../App";
import {UserData} from "@asirisos/types/data";

function SearchByZip() {

    const {t} = useTranslation()
    const [formData, setFormData] = useState({
        zip: '',
    })

    const {zip} = formData

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const location = useLocation()

    const {isLoading} = useSelector((state: RootState) => state.auth)
    const {orgLocations, status} = useSelector((state: RootState) => state.orgLocation)

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        dispatch(getOrgZipLocations(zip))
            .unwrap()
            .then(() => {
                toast.success(t("success"));
                SetAxiosAuthHeader();
                navigate("/search-results")
            })
            .catch(() => {
                toast.error(t("errors:generaError"))
            })
    }


    if(status === 2) {
        return (
            <Spinner/>
        )
    }

  return (
    <>

    {/********************************************/}
    {/********************* MOBILE - START ***********************/}
    {/********************************************/}
    <div className='lg:hidden sm:visible h-fit' >


        <div className=' flex flex-col w-full h-screen items-center align-middle justify-center bg-[#F2F0E6] px-5 '>  

            {/* <div className='pt-10' >
                <h1 className=' font-normal text-2xl text-slate-700 max-w-2xl'>
                    Enter your zip-code below
                </h1>
            </div> */}

            <div className='pt-4'>
                <form onSubmit={onSubmit} className='flex flex-col items-center  justify-center gap-4'>
                    <div className='text-slate-800 '>
                        <NumberInputInUnits onChange={(e) => onChange(e)} value={zip} id={"zip"} type={'text'} group={"zip"} label={"zip"} name={"zip"} dataTitle="" placeholder='Enter your ZIP code'/>
                    </div>

                    <div className='w-full'>
                        <button className=' rounded-md drop-shadow-xl bg-[#395A30] font-semibold text-white w-full px-4 text-xl py-3'>
                            Search
                        </button>
                    </div>
                </form>

            </div>
        </div>

    </div>

{/********************************************/}
{/********************* MOBILE - END ***********************/}
{/********************************************/}




    {/* DESKTOP VERSION */}
    </>
  )
}

export default SearchByZip