import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import FormCard from '../../components/shared/FormCard'
import FormTwoInputs from '../../components/shared/FormTwoInputs'
import NumberInputInUnits from '../../components/shared/NumberInputInUnits'
import LargeTextInput from '../../components/shared/LargeTextInput'
import DropDownInput from '../../components/shared/LegacyDropDownInput'
import FormSelectionRangeInput from '../../components/shared/card/FormSelectionRangeInput'
import { getCrops } from '../../../features/crops/cropSlice'
import { getAllInventory } from 'features/inventory/inventorySlice'
import { getCustomers } from 'features/customers/customerSlice'
import { getAllStorage } from 'features/storage/storageSlice'
import { getFarmAccounts } from 'features/accounts/accountSlice'
import { addTask } from '../../../features/task/taskSlice'
import {AppDispatch, RootState} from "../../../app/store";
import {InputSelection} from "../../../types/ui";
import { getAllLiveStock } from 'features/live-stock/live-stockSlice'
import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import { getAllEquipment } from 'features/equipment/equipmentSlice'
import {TaskModel} from "@asirisos/types";
import { getYields } from 'features/yield/yieldSlice'
import { getFields } from 'features/fields/fieldSlice'
import { getInfrastructures } from 'features/infrastructure/infrastructureSlice'

function TaskForm() {

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    Initiate Hooks   /////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    USE EFFECT   /////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    useEffect(() => {
        dispatch(getCrops())
        dispatch(getFarmAccounts())
        dispatch(getAllEquipment())
        dispatch(getAllLiveStock())
        dispatch(getAllInventory())
        dispatch(getAllStorage())
        dispatch(getCustomers())
        dispatch(getFields())
        dispatch(getInfrastructures())
        dispatch(getYields())
    },[dispatch] )

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    USE SELECTOR   ///////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const {t} = useTranslation('task');
    const {crops} = useSelector((state : RootState) => state.crop )
    const {accounts} = useSelector((state: RootState) => state.account)
    const {equipments} = useSelector((state: RootState) => state.equipment)
    const {allLiveStock} = useSelector((state : RootState) => state.livestock )
    const {allInventory} = useSelector((state : RootState) => state.inventory ) 
    const {allStorage} = useSelector((state: RootState) => state.storage )
    const {customers} = useSelector((state: RootState) => state.customer )
    const {yields} = useSelector((state: RootState) => state.yield)
    const {fields} = useSelector((state: RootState) => state.field)
    const {infrastructures} = useSelector((state: RootState) => state.infrastructure)

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  CONNECTION SELECTIONS   ////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
    const cropSelections = crops.map((crop) => {return {name: crop.cropName, id:crop._id}})

    const equipmentSelections = equipments.map((equipment) => {return {name: equipment.name, id:equipment._id}})

    const employeeSelections = accounts.map((account) => {return {name: account.name, id:account._id}})

    const liveStockSelections = allLiveStock.map((livestock) => {return {name: livestock.name, id:livestock._id}})

    const inventorySelections = allInventory.map((inventory) => {return {name: inventory.name, id:inventory._id}})

    const storageSelections = allStorage.map((storage) => {return {name: storage.name, id:storage._id}})

    const customerSelections = customers.map((customer) => {return  new InputSelection(customer.firstName, customer._id)})

    const yieldSelections = yields.map((singleYield) => {return  new InputSelection(singleYield.title, singleYield._id)})

    const fieldSelections = fields.map((field) => {return  new InputSelection(field.fieldName, field._id)})

    const infrastructureSelections = infrastructures.map((infrastructure) => {return  new InputSelection(infrastructure.infrastructureName, infrastructure._id)})


    const connectionSelection = [
        new InputSelection('crop', 'crop'),
        new InputSelection('live stock', 'live stock'), 
        new InputSelection('storage', 'storage'),
        new InputSelection('employee', 'employee'),
        new InputSelection('equipment', 'equipment'),
        new InputSelection('inventory', 'inventory'),
        new InputSelection('yield', 'yield'),
        new InputSelection('field', 'field'),
        new InputSelection('infrastructure', 'infrastructure'),
        new InputSelection('customer', 'customer')]


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    USE STATE      ///////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const [formData, setFormData] = useState({
        title: '',
        deadline: '',
        description: '',
        connectionCategory: '',
        crop: '',
        employee: '',
        liveStock: '',
        equipment: '',
        inventory: '',
        storage: '',
        customer: '',
        yieldConnection: '',
        field: '',
        infrastructure: '',
        assignedTo: '',
        taskStatus: '',
    })

    const {
        title,
        deadline,
        description,
        connectionCategory,
        crop,
        employee,
        equipment,
        liveStock,
        customer,
        inventory,
        yieldConnection,
        field,
        infrastructure,
        storage,
        assignedTo,
        taskStatus,
            } = formData

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    CONNECTION TOGGLE      ///////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const [connectionAdded, setConnectionAdded] = React.useState('false');

    const onToggleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const toggleValue = e.target.value

        if (toggleValue === 'true') { setConnectionAdded('false') }
        if (toggleValue === 'false') { setConnectionAdded('true') }
        
   }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    ON CHANGE & ON SUBMIT      ///////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }) )
    }

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        const taskData: TaskModel = {
            title,
            deadline,
            description,
            connectionAdded,
            connectionCategory,
            crop,
            employee,
            equipment,
            inventory,
            storage,
            customer,
            yieldConnection,
            liveStock,
            assignedTo,
            field,
            infrastructure,
            taskStatus,

        }
        dispatch(addTask(taskData))
            .unwrap()
            .then(() => {
                toast.success(t("task:taskAdded"));
                navigate("/tasks")
            })
            .catch(() => {
                toast.error(t("task:taskAddFailed"))
                navigate("/task-form")
            })
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    COMPONENT      ///////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
  return (
    <>
        <FormCard title="Add Task">
        <form onSubmit={onSubmit}>
            <FormTwoInputs>
                <NumberInputInUnits onChange={(e) => onChange(e)} value={title} id={"title"} type={'text'} group={"title"} label={"title"} name={"title"} dataTitle="Title" />                
                <NumberInputInUnits onChange={(e) => onChange(e)} value={deadline} id={"deadline"} type={'date'} group={"deadline"} label={"deadline"} name={"deadline"} dataTitle="Deadline" />
            </FormTwoInputs>
            
            <div className='mx-16' >
                <LargeTextInput onChange={(e) => onChange(e)} value={description} id={"description"} type={'text'} group={"description"} label={"description"} name={"description"} dataTitle="Description" />
            </div>

            <div className='pt-4 mt-8 pb-3' >
                <label className="relative inline-flex items-center cursor-pointer">
                    <input type="checkbox" onChange={(e) => onToggleChange(e)} value={connectionAdded} className="sr-only peer"/>
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <h1 className='ml-3 text-slate-500 text-md' >Add Reference</h1>
                </label>
                
            {connectionAdded === 'true' ? (
                <>
                    <div className='' >
                        
                    <FormTwoInputs>
                        <DropDownInput onChange={(e) => onChange(e)} selections={connectionSelection} value={connectionCategory} id={"connectionCategory"} type={'text'} group={"connectionCategory"} label={"connectionCategory"} name={"connectionCategory"} dataTitle="Category" />
                        {connectionCategory === 'crop' ? (
                            <>
                                <DropDownInput onChange={(e) => onChange(e)} selections={cropSelections} value={crop} id={"crop"} type={'text'} group={"crop"} label={"crop"} name={"crop"} dataTitle="Crop" />
                            </>) : (<></>) }

                        {connectionCategory === 'live stock' ? (
                            <>
                                <DropDownInput onChange={(e) => onChange(e)} selections={liveStockSelections} value={liveStock} id={"liveStock"} type={'text'} group={"liveStock"} label={"liveStock"} name={"liveStock"} dataTitle="Live Stock" />
                            </>) : (<></>) }

                        {connectionCategory === 'employee' ? (
                            <>
                                <DropDownInput onChange={(e) => onChange(e)} selections={employeeSelections} value={employee} id={"employee"} type={'text'} group={"employee"} label={"employee"} name={"employee"} dataTitle="Employee" />
                            </>) : (<></>) }

                        {connectionCategory === 'equipment' ? (
                            <>
                                <DropDownInput onChange={(e) => onChange(e)} selections={equipmentSelections} value={equipment} id={"equipment"} type={'text'} group={"equipment"} label={"equipment"} name={"equipment"} dataTitle="Equipment" />
                            </>) : (<></>) }

                        {connectionCategory === 'inventory' ? (
                            <>
                                <DropDownInput onChange={(e) => onChange(e)} selections={inventorySelections} value={inventory} id={"inventory"} type={'text'} group={"inventory"} label={"inventory"} name={"inventory"} dataTitle="Inventory" />
                            </>) : (<></>) }

                        {connectionCategory === 'storage' ? (
                            <>
                                <DropDownInput onChange={(e) => onChange(e)} selections={storageSelections} value={storage} id={"storage"} type={'text'} group={"storage"} label={"storage"} name={"storage"} dataTitle="Storage" />
                            </>) : (<></>) }

                        {connectionCategory === 'customer' ? (
                            <>
                                <DropDownInput onChange={(e) => onChange(e)} selections={customerSelections} value={customer} id={"customer"} type={'text'} group={"customer"} label={"customer"} name={"customer"} dataTitle="Customer" />
                            </>) : (<></>) }
                        
                        {connectionCategory === 'yield' ? (
                        <>
                            <DropDownInput onChange={(e) => onChange(e)} selections={yieldSelections} value={yieldConnection} id={"yieldConnection"} type={'text'} group={"yieldConnection"} label={"yieldConnection"} name={"yieldConnection"} dataTitle="Yield" />
                        </>) : (<></>) }

                        {connectionCategory === 'field' ? (
                        <>
                            <DropDownInput onChange={(e) => onChange(e)} selections={fieldSelections} value={field} id={"field"} type={'text'} group={"field"} label={"field"} name={"field"} dataTitle="Field" />
                        </>) : (<></>) }

                        {connectionCategory === 'infrastructure' ? (
                        <>
                            <DropDownInput onChange={(e) => onChange(e)} selections={infrastructureSelections} value={infrastructure} id={"infrastructure"} type={'text'} group={"infrastructure"} label={"infrastructure"} name={"infrastructure"} dataTitle="Yield" />
                        </>) : (<></>) }


                    </FormTwoInputs>
                    </div>
                </>) : (<></>) }
            </div>

            

            <FormTwoInputs>
                <DropDownInput onChange={(e) => onChange(e)} selections={employeeSelections} value={assignedTo} id={"assignedTo"} type={'text'} group={"assignedTo"} label={"assignedTo"} name={"assignedTo"} dataTitle="Assign To" />         
            </FormTwoInputs>

            <FormSelectionRangeInput
                title="Status"
                range={<input onChange={onChange} name="taskStatus" id="taskStatus" type="range" min="0" max="100" value={taskStatus} className="range range-sm range-primary" step="25" />}>
                    <span><h1 className={`text-base  ${taskStatus === "0" ? "text-primary text-2xl" : "text-gray-400" }`}>created</h1></span>
                    <span><h1 className={`text-base  ${taskStatus === "25" ? "text-primary text-2xl" : "text-gray-400" }`} >in progress</h1></span>
                    <span><h1 className={`text-base  ${taskStatus === "50" ? "text-primary text-2xl" : "text-gray-400" }`} >completed</h1></span>
                    <span><h1 className={`text-base  ${taskStatus === "75" ? "text-primary text-2xl" : "text-gray-400" }`}  >review</h1></span>
                    <span><h1 className={`text-base  ${taskStatus === "100" ? "text-primary text-2xl" : "text-gray-400" }`}  >closed</h1></span>
            </FormSelectionRangeInput>

        <div className='flex justify-center pt-8 p-2' >
            <button className="btn btn-wide btn-primar hover:bg-black">Add Task</button>
        </div>    
        </form>
        </FormCard>
    </>
  )
}

export default TaskForm