import React from 'react'

function TableMultiDeleteButton(props : {onDeleteClick : React.MouseEventHandler<HTMLButtonElement>}) {
  return (
    <div className='flex justify-center mb-5'>
        <div className='bg-red-300 w-full py-3 rounded-md' >
            <button onClick={props.onDeleteClick} className='border-2 border-black px-3 py-2 shadow-lg rounded-lg hover:bg-red-400 ' >{`Delete Selected Items`}</button>
        </div>
    </div>
  )
}

export default TableMultiDeleteButton