import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import orgFarmersMarketService from "./orgFarmersMarketsService";
import {FixMeLater} from "../../types/dev";
import { OrgFarmersMarketState } from "../../types/states";
import HandleAxiosError from "../../utils/AxiosErrorHandler";
import {DefaultThunkApiConfig, Status} from "../../types/slice";
import { OrgFarmersMarketModel } from "@asirisos/types";

const CONTEXT = "OrgFarmersMarket"

const initialState: OrgFarmersMarketState = {
    orgFarmersMarket: undefined,
    orgFarmersMarkets: [],
    status: Status.Unknown,
    message: ''
}

export const addOrgFarmersMarket = createAsyncThunk<any, OrgFarmersMarketModel, DefaultThunkApiConfig>('organization/add', async (orgFarmersMarketData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await orgFarmersMarketService.addOrgFarmersMarket(orgFarmersMarketData, account)
        if (response.status !== 201)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getOrgFarmersMarkets = createAsyncThunk<OrgFarmersMarketModel[], void, DefaultThunkApiConfig>('orgFarmersMarket/getall', async (_, thunkAPI) => {
    try {
        // const account = thunkAPI.getState().account.account;
        // if (!account)
        //     return thunkAPI.rejectWithValue();

        const response = await orgFarmersMarketService.getOrgFarmersMarkets()
        // console.log('--------------------------------- FARMERS MARKET GET ALLL - SLICE --------------------', response)
        // console.log("Organization Slice - Get All - response", response)
        if (response.status !== 201)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getOrgFarmersMarket = createAsyncThunk<OrgFarmersMarketModel, string, DefaultThunkApiConfig>('farmersmarket/getone/:listing_id', async (listing_id, thunkAPI) => {
    try {
        const response = await orgFarmersMarketService.getOrgFarmersMarket(listing_id)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const updateOrgFarmersMarket = createAsyncThunk<OrgFarmersMarketModel, OrgFarmersMarketModel, DefaultThunkApiConfig>('organization/updateOrgFarmersMarket/:orgFarmersMarketId', async (orgFarmersMarketData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if(!account)
            return thunkAPI.rejectWithValue();
            
        const response = await orgFarmersMarketService.updateOrgFarmersMarket(orgFarmersMarketData, account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
    // try {
    //     const token = thunkAPI.getState().auth.user.token
    //     return await orgFarmersMarketService.updateOrgFarmersMarket(orgFarmersMarketData, token)
    // } catch (error) {
    //     HandleAxiosError(error, CONTEXT);
    //     return thunkAPI.rejectWithValue("message")
    // }
})

export const deleteOrgFarmersMarket = createAsyncThunk<any, string[], DefaultThunkApiConfig>('organization/delete/:orgFarmersMarketId', async (orgFarmersMarketId, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if(!account)
            return thunkAPI.rejectWithValue();

            console.log('Test account in delete Storage', account, orgFarmersMarketId)
        const response = await orgFarmersMarketService.deleteOrgFarmersMarket(orgFarmersMarketId, account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const orgFarmersMarketSlice = createSlice({
    name: 'orgFarmersMarket',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(addOrgFarmersMarket.pending, (state: OrgFarmersMarketState) => {
                state.status = Status.Pending
            })
            .addCase(addOrgFarmersMarket.fulfilled, (state: OrgFarmersMarketState) => {
                state.status = Status.Success
            })
            .addCase(addOrgFarmersMarket.rejected, (state: OrgFarmersMarketState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            .addCase(getOrgFarmersMarkets.pending, (state: OrgFarmersMarketState) => {
                state.status = Status.Pending
            })
            .addCase(getOrgFarmersMarkets.fulfilled, (state: OrgFarmersMarketState, action: PayloadAction<OrgFarmersMarketModel[], any, any>) => {
                state.status = Status.Success
                state.orgFarmersMarkets = action.payload
            })
            .addCase(getOrgFarmersMarkets.rejected, (state: OrgFarmersMarketState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            .addCase(getOrgFarmersMarket.pending, (state: OrgFarmersMarketState) => {
                state.status = Status.Pending
            })
            .addCase(getOrgFarmersMarket.fulfilled, (state: OrgFarmersMarketState, action: PayloadAction<OrgFarmersMarketModel, any, any>) => {
                state.status = Status.Success
                state.orgFarmersMarket = action.payload
            })
            .addCase(getOrgFarmersMarket.rejected, (state: OrgFarmersMarketState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            .addCase(updateOrgFarmersMarket.pending, (state: OrgFarmersMarketState) => {
                state.status = Status.Pending
            })
            .addCase(updateOrgFarmersMarket.fulfilled, (state: OrgFarmersMarketState, action: PayloadAction<OrgFarmersMarketModel, any, any>) => {
                state.status = Status.Success
                state.orgFarmersMarkets.map((orgFarmersMarket: OrgFarmersMarketModel) => orgFarmersMarket._id === action.payload._id ?
                    (state.orgFarmersMarket = orgFarmersMarket) : orgFarmersMarket)
                state.orgFarmersMarket = action.payload
            })
            .addCase(updateOrgFarmersMarket.rejected, (state: OrgFarmersMarketState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            // .addCase(updateOrgFarmersMarket.fulfilled, (state: OrgFarmersMarketState, action: FixMeLater) => {
            //     state.isLoading = false
            //     state.orgFarmersMarkets.map((field) => orgFarmersMarket._id === action.payload._id ? (state.orgFarmersMarket = field) : field)
            // })
            .addCase(deleteOrgFarmersMarket.pending, (state: OrgFarmersMarketState) => {
                state.status = Status.Pending
            })
            .addCase(deleteOrgFarmersMarket.fulfilled, (state: OrgFarmersMarketState) => {
                state.status = Status.Success
                state.orgFarmersMarket = undefined
            })
            .addCase(deleteOrgFarmersMarket.rejected, (state: OrgFarmersMarketState) => {
                state.status = Status.Failure
            })
    }
})

export const {reset} = orgFarmersMarketSlice.actions
export default orgFarmersMarketSlice.reducer