import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import { deleteExpense, getExpenses } from 'features/expenses/expenseSlice';
import Spinner from '../../components/Spinner'
import {DataGrid, GridColumns} from '@mui/x-data-grid';
import {Link, useNavigate} from 'react-router-dom'
import {AppDispatch, RootState} from "../../../app/store";
import {Status} from "../../../types/slice";
import {useTranslation} from "react-i18next";
import {toast} from 'react-toastify'
import DeleteModal from 'UI/components/DeleteModal';
import TableMultiDeleteButton from 'UI/components/TableMultiDeleteButton';

const actionColumn : GridColumns = [
    {
        field: 'action',
        headerName: "Action",
        width: 200,
        renderCell: (params) => {
            return (
                <div className='flex flex-row px-2 pl-4 justify-center items-center gap-2' >
                    <Link to={`/expense/${params.row._id}`} className="border-2 rounded-lg border-blue-500 px-2">View</Link>
                    <div className='border-2 rounded-lg border-red-400 px-2' >Delete</div>
                </div>
            )
        }
    }
]

const columns : GridColumns = [
  { field: '_id', headerName: 'ID', width: 70 },
  {
    field: 'img',
    headerName: 'Category',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    renderCell:(params)=> {
        return(
            
                <div className='flex flex-row items-center' >
                    <img className=' rounded-xl  w-9 h-9 mr-4'  src="https://png.pngtree.com/element_our/png_detail/20181229/sprout-plant-graphic-icon-design-template-png_301529.jpg" alt="" />
                    <div className='text-indigo' >
                    {params.row.category}
                    </div>
                </div>
            
        )
    }
  },
  { field: 'customer', headerName: 'customer', width: 120 },
  { field: 'item', headerName: 'item', width: 120 },
  { field: 'quantity', headerName: 'quantity', width: 120 },

  {
    field: 'status',
    headerName: 'Status',
    description: 'test render return',
    renderCell:(params)=> {
        return(
            
            <div className={` text-center justify-self-center bg-opacity-40 rounded-lg p-1 m-1 ${params.row.status === 'created' ? 'bg-indigo-400' : '' } 
            ${params.row.status === 'in progress' ? 'bg-green-400' : '' } ${params.row.status === 'completed' ? 'bg-orange-400' : '' }
            ${params.row.status === 'review' ? 'bg-orange-400' : '' } ${params.row.status === 'closed' ? 'bg-orange-400' : '' }`}>
                
                    {params.row.status}
                
            </div>
            
        )
    }
  },
];

function ExpenseTable() {
  const {expenses, status} = useSelector((state: RootState) => state.expense)
  const {t} = useTranslation(["expense", "translation"])
  const dispatch = useDispatch<AppDispatch>();

  const [modalIsOpen, setModalIsOpen] = useState(false)

const onDeleteClick = (e: any) => {
    setModalIsOpen(!modalIsOpen)
    console.log(modalIsOpen)
}

const [deleteEvent, setDeleteEvent] = useState<boolean>(true)
const [selectedRows, setSelectedRows] = useState<string[]>([])

const handleSelectionChange = (newSelectionModel : string[]) => {
    setSelectedRows(newSelectionModel)
}

const navigate = useNavigate()

const onDelete = () => {
    dispatch(deleteExpense(selectedRows))
    .unwrap()
    .then(() => {
        toast.success(t("expense:expenseDeleted"));
        navigate("/expenses")
        setModalIsOpen(false)
    })
    .catch(() => {
        toast.error(t("expense:failedToDeleteexpense"))
    })
    setDeleteEvent(!deleteEvent)
    setDeleteEvent(!deleteEvent)
    setSelectedRows([])
}

    useEffect(() => {
      if (status !== Status.Pending || deleteEvent === true || deleteEvent === false)
        dispatch(getExpenses())
    }, [dispatch, deleteEvent])

    if (status === Status.Pending) {
        return <Spinner/>
    }
  return (
    <>

        {selectedRows.length !== 0 ? (<>
        <TableMultiDeleteButton onDeleteClick={onDeleteClick} />
        </>) :(<></>) }
      
      <div style={{ height: 400, width: 'full' }}>
      <DataGrid
        getRowId={(row) => row._id}
        rows={expenses}
        columns={columns.concat(actionColumn)}
        pageSize={10}
        rowsPerPageOptions={[5]}
        checkboxSelection
        onSelectionModelChange={(newSelectionModel : any) => {
          handleSelectionChange(newSelectionModel)
        }}
      />
    </div>
    {/* ////////////////////////////////////////////////////////////////////// */}
    {/* //////////////////////////// DELETE MODAL //////////////////////////// */}

    <DeleteModal modalIsOpen={modalIsOpen} onDeleteClick={onDeleteClick} onDelete={onDelete} />
    </>
  )
}

export default ExpenseTable