import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {deleteInventory, getAllInventory} from '../../../features/inventory/inventorySlice'
import Spinner from '../../components/Spinner'
import {DataGrid, GridColumns} from '@mui/x-data-grid';
import {Link, useNavigate} from 'react-router-dom'
import {AppDispatch, RootState} from "../../../app/store";
import {Status} from "../../../types/slice";
import {useTranslation} from "react-i18next";
import TableMultiDeleteButton from 'UI/components/TableMultiDeleteButton';
import DeleteModal from 'UI/components/DeleteModal';
import {toast} from "react-toastify";

const columns = (t: any):GridColumns => [
    {field: '_id', headerName: t("translation:id"), width: 70},
    {
        field: 'img',
        headerName: 'User',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        width: 160,
        renderCell: (params) => {
            return (

                <div className='flex flex-row items-center'>
                    <img className=' rounded-xl  w-9 h-9 mr-4'
                         src="https://icons.veryicon.com/png/o/education-technology/passenger-flow-analysis-icon-library/activity-assessment.png"
                         alt=""/>
                    <div className='text-indigo'>
                        {params.row.name}
                    </div>
                </div>

            )
        }
    },

    {field: 'description', headerName: t("translation:description"), width: 230},
    {
        field: 'type',
        headerName: t("translation:type"),
        type: 'string',
        width: 90,
    },
    {
        field: 'quantity',
        headerName: t("translation:quantity"),
        type: 'number',
        width: 90,
    },
    {
        field: 'inventory',
        headerName: t("translation:action"),
        width: 200,
        renderCell: (params) => {
            return (
                <div className='flex flex-row px-2 pl-4 justify-center items-center gap-2'>
                    <Link to={`/inventory/${params.row._id}`} className="border-2 rounded-lg text-blue-500 px-2">View</Link>
                </div>
            )
        }
    }

];

function InventoryTable() {

    const navigate = useNavigate()
    const {t} = useTranslation(["inventory", "translation"])
    const dispatch = useDispatch<AppDispatch>()


    const [modalIsOpen, setModalIsOpen] = useState(false)

    const onDeleteClick = (e: any) => {
        setModalIsOpen(!modalIsOpen)
        console.log(modalIsOpen)
    }
    
    const [deleteEvent, setDeleteEvent] = useState<boolean>(true)
    const [selectedRows, setSelectedRows] = useState<string[]>([])
    
    const handleSelectionChange = (newSelectionModel : string[]) => {
        setSelectedRows(newSelectionModel)
    }
    
    
    const onDelete = () => {
        dispatch(deleteInventory(selectedRows))
        .unwrap()
        .then(() => {
            toast.success(t("inventory:inventoryDeleted"));
            navigate("/inventory")
            setModalIsOpen(false)
        })
        .catch(() => {
            toast.error(t("inventory:failedToDeleteinventory"))
        })
        setDeleteEvent(!deleteEvent)
        setDeleteEvent(!deleteEvent)
        setSelectedRows([])
    }



    useEffect(() => {
        if (status !== Status.Pending || deleteEvent === true || deleteEvent === false)
            dispatch(getAllInventory())
    }, [dispatch, deleteEvent])

    const {allInventory, status} = useSelector((state: RootState) => state.inventory)

    if (status === Status.Pending) {
        return <Spinner/>
    }

    return (
        <>
        {selectedRows.length !== 0 ? (<>
        <TableMultiDeleteButton onDeleteClick={onDeleteClick} />
        </>) :(<></>) }

            <div style={{height: 600, width: 'full'}}>
                <DataGrid
                    getRowId={(row) => row._id}
                    rows={allInventory}
                    columns={columns(t)}
                    pageSize={10}
                    rowsPerPageOptions={[5]}
                    checkboxSelection
                    onSelectionModelChange={(newSelectionModel : any) => {
                        handleSelectionChange(newSelectionModel)
                      }}
                />
            </div>

            {/* ////////////////////////////////////////////////////////////////////// */}
            {/* //////////////////////////// DELETE MODAL //////////////////////////// */}

            <DeleteModal modalIsOpen={modalIsOpen} onDeleteClick={onDeleteClick} onDelete={onDelete} />

        </>
    )
}

export default InventoryTable