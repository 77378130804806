import React, { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux'
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import { getOrgLocations } from "features/orgLocations/orgLocationsSlice";
import { getOrgFarmersMarkets } from "features/farmersMarkets/orgFarmersMarketsSlice";
import { cities } from "data/cities";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

const onClick = () => {
  console.log('Pop Up Button Hit' )
}

const DEFAULT_RADIUS = 800

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png",
});

const CustomPopUp = ({data, setRadiusFilter}) => {
  const [radius, setRadius] = useState(DEFAULT_RADIUS)
  const {organizationName} = data.properties
  return(
    <>
    <Popup className="">
        <div className=" flex flex-col py-10 px-3 rounded-md text-white bg-indigo-500 text-center justify-center" >

          <div>
            <b>{`${organizationName}`}</b>
          </div>

          <div>
            {/* <b>{` Population: ${pop_max}`}</b> */}
          </div>

          <div className="flex flex-col pt-4">
            <input className="text-black" type="text" defaultValue={3000} min={0} onChange={(e) => setRadius(e.target.value) } >

            </input>
            <button className="text-black p-2 mt-2 rounded-md bg-white shadow-xl"
              onClick={() => setRadiusFilter((prevState) => {
                let newFilter
                if (prevState) {
                  if (radius === '0') {
                    console.log('onFilter - radius' ,radius)
                    console.log('onFilter - prev state',prevState)
                    newFilter = prevState
                  } else {
                  const sameFeature = prevState.data === data
                  const sameRadius = prevState.radius === radius
                  if(!sameFeature || !sameRadius) {
                    newFilter = {data, radius}
                  }
                }
                } else if (radius !== 0 ) {
                  newFilter = {data, radius}
                }
                return newFilter
                }) } >
              Filter By KM
            </button>
          </div>

          <button onClick={onClick} className="text-black p-2 mt-8 rounded-md bg-white shadow-xl" >
            Click Me
          </button>
        </div>
    </Popup>
    </>
  )
}


const MarkerLayer = ({data, setRadiusFilter, getRadiusFilter}) => {
  const radiusFilter = getRadiusFilter()

  console.log('MarkerLayer')
  let centerPoint 
  if(radiusFilter) {
    const { coordinates} = radiusFilter.data.geometry
    centerPoint = L.latLng(coordinates[1], coordinates[0])
    console.log('MarkerLayer - center point', centerPoint)

  }
  

  console.log('MarkerLayer - data type', data)
  console.log('MarkerLayer - data type', typeof(data))
  return data.filter((currentFeature) => {
    console.log('MarkerLayer - data', currentFeature)
    if(centerPoint) {
      const {coordinates} = currentFeature.geometry
      const currentPoint = L.latLng(coordinates[1], coordinates[0])
      return centerPoint.distanceTo(currentPoint) / 1000 < radiusFilter.radius
    } else {
      return true
    }
  } ) 
  .map(feature => {
    const {coordinates} = feature.geometry
    // if(feature.type === "feature") {
    //   return (
    //     <Marker key={String(coordinates)} position={[coordinates[1], coordinates[0]]} icon={icon}>
    //       <CustomPopUp data={feature} setRadiusFilter={setRadiusFilter}/>
    //       {/* <Popup>
    //           A pretty CSS3 popup. <br /> Easily customizable.
    //       </Popup> */}
    //     </Marker>
    //   )
    // } 
    if (feature.properties?.urlPath === "/farm-inputs-add-form") {
      return (
        <Marker key={String(coordinates)} position={[coordinates[1], coordinates[0]]} icon={inputsIcon}>
          <CustomPopUp data={feature} setRadiusFilter={setRadiusFilter}/>
          {/* <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
          </Popup> */}
        </Marker>
      )
    }
    if (feature.properties?.urlPath === "/farm-service-add-form") {
      return (
        <Marker key={String(coordinates)} position={[coordinates[1], coordinates[0]]} icon={farmIcon}>
          <CustomPopUp data={feature} setRadiusFilter={setRadiusFilter}/>
          {/* <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
          </Popup> */}
        </Marker>
      )
    }
    if (feature.properties?.urlPath === "/warehousing-add-form") {
      return (
        <Marker key={String(coordinates)} position={[coordinates[1], coordinates[0]]} icon={warehouseIcon}>
          <CustomPopUp data={feature} setRadiusFilter={setRadiusFilter}/>
          {/* <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
          </Popup> */}
        </Marker>
      )
    }
    if (feature.properties?.urlPath === "/farm-tech-add-form") {
      return (
        <Marker key={String(coordinates)} position={[coordinates[1], coordinates[0]]} icon={technologyIcon}>
          <CustomPopUp data={feature} setRadiusFilter={setRadiusFilter}/>
          {/* <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
          </Popup> */}
        </Marker>
      )
    }
    //  return (
    //  <Marker key={String(coordinates)} position={[coordinates[1], coordinates[0]]} icon={icon}>
    //   <CustomPopUp data={feature} setRadiusFilter={setRadiusFilter}/>
    //   {/* <Popup>
    //       A pretty CSS3 popup. <br /> Easily customizable.
    //   </Popup> */}
    // </Marker>
    //  )
  }
  )
} 

const farmIcon = L.icon({
  iconUrl: "https://img.icons8.com/cotton/2x/farm--v1.png",
  iconSize: [40, 40],
});

const inputsIcon = L.icon({
  iconUrl: "https://rodaleinstitute.org/wp-content/uploads/grain-icon-450x450.png",
  iconSize: [40, 40],
});

const technologyIcon = L.icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/512/1579/1579568.png",
  iconSize: [50, 50],
});

const warehouseIcon = L.icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/512/3774/3774895.png",
  iconSize: [50, 50],
});

const position = [51.505, -0.09];

function ResetCenterView(props) {
  const { selectPosition } = props;
  const map = useMap();


  useEffect(() => {
    if (selectPosition.lat != '') {
      map.setView(
        L.latLng(selectPosition?.lat, selectPosition?.lon),
        map.getZoom(),
        {
          animate: true
        }
      )
    }
  }, [selectPosition]);

  return null;
}

export default function DisplayAllOrgs(props) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getOrgLocations())
    dispatch(getOrgFarmersMarkets())
}, [dispatch])

const {orgLocations, status} = useSelector((state) => state.orgLocation)
  const [radiusFilter, setRadiusFilter] = useState(null)

  const getRadiusFilter = () => radiusFilter

  // const { selectPosition } = props;

  // const locationSelection = [selectPosition?.lat, selectPosition?.lon];


  return (
    <>
      <h1 className="text-xl font-bold py-3" >Display Multiple Locations / Custom Pop Ups / Radius Filter</h1>
        <MapContainer center={[41.05181527192488, -73.59345225093897]} zoom={6} style={{ width: "80vw", height: "70vh" }}>
       
        {/* <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright"></a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/> */}
          <TileLayer
            url='https://api.mapbox.com/styles/v1/purpledragons/cl9kzio97001v14pe6ahvafbd/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicHVycGxlZHJhZ29ucyIsImEiOiJjbDlqMGp3cHkwZDJsM25wa2I2dnBxMXU2In0.m0DYm_idO-8_I7OeSBuMnQ'
            maxZoom= {13}/>

          <MarkerLayer data={orgLocations} setRadiusFilter={setRadiusFilter} getRadiusFilter={getRadiusFilter} />
          

          {/* {farm?.lat && (
              <Marker position={locationCoordinatesBackend} icon={icon}>
              <Popup>
                  A pretty CSS3 popup. <br /> Easily customizable.
              </Popup>
              </Marker>
          )} */}
          {/* <ResetCenterView selectPosition={selectPosition}  /> */}
        </MapContainer>
        </>
  );
}