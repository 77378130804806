import React from 'react'
import { useEffect } from 'react'
import {useParams, useNavigate, Link} from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {AppDispatch, RootState} from "../../../app/store";
import { getOrgFarmersMarket } from 'features/farmersMarkets/orgFarmersMarketsSlice';
import FarmersMarketsProfileMap from '../Map/FarmersMarketsProfileMap';
import { useSelector } from 'react-redux';
import Spinner from '../../components/Spinner'
import {Status} from "../../../types/slice";
import {useTabPersistence} from "../../../hooks/useTabPersistence";
import {PageId} from "../../../types/pages";
import {useTranslation} from "react-i18next";
import {Translation} from "react-i18next";

import BackButton from 'UI/components/shared/BackButton';
import AboutFarmersMarket from './AboutFarmersMarket';
import ApplyAsVendor from './ApplyAsVendor';
import ContactFarmersMarket from './ContactFarmersMarket';


function FarmersMarketsProfile() {

    const {selection, toggleTab} = useTabPersistence(PageId.Equipment);
    const {t} = useTranslation(["farmersmarket", "translation"])

    const {listing_id} = useParams()
    const dispatch = useDispatch<AppDispatch>()
    const {orgFarmersMarket, status} = useSelector((state: RootState) => state.orgFarmersMarket)

    

    useEffect(() => {
        if(listing_id)
            dispatch(getOrgFarmersMarket(listing_id))
    }, [dispatch, listing_id])


    if (status === Status.Pending ) {
        return <Spinner/>
    }
  return (
    <>


    <div className=" flex flex-col py-6 px-3 rounded-md text-gray-600 items-center justify-center w-full " >

        {/* ---------------------- NAME AND SAVE ---------------------- */}
        <div className="flex justify-between w-3/4">
            <div className='align-middle'>
                <b className="text-3xl font-normal">{`${orgFarmersMarket?.properties.listing_name}`}</b>
            </div>
            <div className='align-middle'>
                
                <button className='bg-indigo-500 py-3 px-4 rounded-lg shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] hover:bg-indigo-700 mx-3'>
                    <h1 className='text-xl text-white'>Save</h1>
                </button>
                
                <Link to={`/apply/${listing_id}`}>
                <button className='bg-indigo-500 py-3 px-4 rounded-lg shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] hover:bg-indigo-700'>
                    <h1 className='text-xl text-white'>Become a Vendor</h1>
                </button>
                </Link>
            </div>
        </div>

        <div className='border-2 border-slate-500 w-3/4 my-5' />
        {/* ---------------------- NAME AND SAVE ---------------------- */}




        <div className='pb-8 mb-6 rounded-lg bg-slate-100 drop-shadow-lg w-3/4'>
            <ul className="flex felx-row justify-left cursor-pointer">
                <li onClick={() => toggleTab(1)}
                    className={`rounded-t-lg px-12 py-2 ${selection === 1 ? 'bg-indigo-500 text-white' : ''}`}>{t("farmersmarket:home")}
                </li>
                <li onClick={() => toggleTab(2)}
                    className={`rounded-t-lg px-12 py-2 ${selection === 2 ? 'bg-indigo-500 text-white' : ''}`}>{t("farmersmarket:about")}
                </li>
                <li onClick={() => toggleTab(3)}
                    className={`rounded-t-lg px-12 py-2 ${selection === 3 ? 'bg-indigo-500 text-white' : ''}`}>{t("farmersmarket:contact")}
                </li>
                <li onClick={() => toggleTab(4)}
                    className={`rounded-t-lg px-12 py-2 ${selection === 4 ? 'bg-indigo-500 text-white' : ''}`}>{t("farmersmarket:posts")}
                </li>
            </ul>

            {selection === 1 ? (
                <div className='bg-white drop-shadow-lg rounded-tl-none py-8 items-center justify-center flex flex-col '>

                    {/* ---------------------- MAP VIEW ---------------------- */}
                    <div className='pb-10' style={{ width: "55vw", height: "50vh" }}>
                        <FarmersMarketsProfileMap selectPosition={orgFarmersMarket}/>
                    </div>
                    {/* ---------------------- MAP VIEW ---------------------- */}


                    {/* ---------------------- ADDRESS AND LOCATION SECTION ----------------------  */}
                    <div className=' flex flex-col text-start items-start justify-start bg-white px-5 rounded-lg shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] py-4 my-2 w-11/12' >
                        <div className="flex flex-col text-start ">
                            <b className="text-2xl font-bold pb-2"> Address: </b>
                            <b className="text-xl font-light" >{`${orgFarmersMarket?.properties.location_address}`}</b>
                        </div>   

                        {orgFarmersMarket?.properties.location_desc !== null ? <>
                            <div className="flex flex-col text-start py-3">
                                <b className="text-lg font-bold">Location Desc: </b>
                                <b className="text-xl font-light" >{`${orgFarmersMarket?.properties.location_desc}`} </b> 
                            </div>
                        </> : <><b className="text-lg font-bold">Location Desc: </b></> }

                        {orgFarmersMarket?.properties.location_desc !== null ? <>
                            <div className="flex flex-col text-start py-3">
                                <b className="text-lg font-bold">Location Site: </b>
                                <b className="text-xl font-light" >{`${orgFarmersMarket?.properties.location_site}`} </b> 
                            </div>
                        </> : <><b className="text-lg font-bold">Location Site: </b></> }

                        {orgFarmersMarket?.properties.location_desc !== null ? <>
                            <div className="flex flex-col text-start py-3">
                                <b className="text-lg font-bold">Location Site: </b>
                                {/* <b className="text-xl font-light" >{`${orgFarmersMarket?.properties.location_site}`} </b>  */}
                            </div>
                        </> : <><b className="text-lg font-bold">Location Indoor: </b></> } 

                        {orgFarmersMarket?.properties.location_desc !== null ? <>
                            <div className="flex flex-col text-start py-3">
                                <b className="text-lg font-bold">Season: </b>
                                {/* <b className="text-xl font-light" >{`${orgFarmersMarket?.properties.season}`} </b>  */}
                            </div>
                        </> : <><b className="text-lg font-bold">Season: </b></> }
                    </div>
                    {/* ---------------------- ADDRESS AND LOCATION SECTION ----------------------  */}


                    {/* ---------------------- PRODUCTS SECTION ----------------------  */}
                    <div className=' flex flex-col text-start items-start justify-start bg-white px-5 rounded-lg shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] py-4 my-2 w-11/12' >
                        <div className="flex flex-col text-start ">
                            <b className="text-2xl font-bold pb-2"> Products: </b>
                        </div>   

                        {orgFarmersMarket?.properties.location_desc !== null ? <>
                            <div className="flex flex-col text-start py-3">
                                <b className="text-lg font-bold">Production/Practice Methods: </b>
                                {/* <b className="text-xl font-light" >{`${orgFarmersMarket?.properties.specialproductionmethods}`} </b>  */}
                            </div>
                        </> : <><b className="text-lg font-bold">Production/Practice Methods: </b></> }
                    </div>
                    {/* ---------------------- PRODUCTS SECTION ----------------------  */}



                    {/* ---------------------- PAYMENTS SECTION ----------------------  */}
                    <div className=' flex flex-col text-start items-start justify-start bg-white px-5 rounded-lg shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] py-4 my-2  w-11/12' >
                        <div className="flex flex-col text-start ">
                            <b className="text-2xl font-bold pb-2"> Payments: </b>
                        </div>   

                        {orgFarmersMarket?.properties.location_desc !== null ? <>
                            <div className="flex flex-col text-start py-3">
                                <b className="text-lg font-bold">Accepted Payments: </b>
                                {/* <b className="text-xl font-light" >{`${orgFarmersMarket?.properties.specialproductionmethods}`} </b>  */}
                            </div>
                        </> : <><b className="text-lg font-bold">Accepted Payments: </b></> }

                        {orgFarmersMarket?.properties.location_desc !== null ? <>
                            <div className="flex flex-col text-start py-3">
                                <b className="text-lg font-bold">Food and Nutrition Assistance Programs: </b>
                                {/* <b className="text-xl font-light" >{`${orgFarmersMarket?.properties.specialproductionmethods}`} </b>  */}
                            </div>
                        </> : <><b className="text-lg font-bold">Food and Nutrition Assistance Programs: </b></> }
                    </div>
                    {/* ---------------------- PAYMENTS SECTION ----------------------  */}


                    {/* ---------------------- VENDORS SECTION ----------------------  */}
                    <div className=' flex flex-col text-start items-start justify-start bg-white px-5 rounded-lg shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] py-4 my-2  w-11/12' >
                        <div className="flex flex-col text-start ">
                            <b className="text-2xl font-bold pb-2"> Vendors: </b>
                        </div>   

                        {/* ---------------------- VENDOR CARD ----------------------  */}
                        <Link className='flex flex-col items-center justify-center shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] my-2 w-1/4 rounded-lg' to="/farm-profile/637c6932bcfec95cb78060e8">
                        
                            <div className=''>
                                <img className='rounded-lg rounded-b-none' src="https://img.freepik.com/free-vector/flat-farm-landscape_52683-9442.jpg" alt="" />
                            </div>
                            <div className='py-3'>
                                <h1 className='text-xl font-normal' >Schrute Farms</h1>
                            </div>
                            <div className='py-3 justify-start items-start w-4/5'>
                                <h1 className='text-lg font-semibold' >Produce</h1>
                            </div>
                            <div className='py-3 justify-start items-start w-4/5'>
                                <h1 className='text-lg font-semibold' >Certifications</h1>
                            </div>                            
                        
                        </Link>
                        {/* ---------------------- VENDOR CARD ----------------------  */}


                    </div>
                    {/* ---------------------- ADDRESS AND LOCATION SECTION ----------------------  */}
                </div> ) : (<></>)}



        {/* OTHER TABS */}

                {selection === 2 ? (
                    <div className='bg-white drop-shadow-lg rounded-tl-none py-8'>
                        <AboutFarmersMarket/>
                    </div>
                ) : (<></>)}

                {selection === 3 ? (
                    <div className='bg-white drop-shadow-lg rounded-tl-none py-8'>
                        <ContactFarmersMarket/>

                    </div>
                ) : (<></>)}

                {selection === 4 ? (
                    <div className='bg-white drop-shadow-lg rounded-tl-none py-8'>

                      {/* <CropActivity/>
                      <Link className="btn mx-9 mt-6" to={"/activity-form"}>
                          Add Activity
                      </Link> */}
                    </div>
                ) : (<></>)}

            </div>
            {/* OTHER TABS */}



    </div>
    </>

  )
}

export default FarmersMarketsProfile