import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
// import liveStockService from "./live-stockService";
import fileUploadService from "./fileUploadService";
import {FileUploadState} from "../../types/states";
import HandleAxiosError from "../../utils/AxiosErrorHandler";
import {DefaultThunkApiConfig, Status} from "../../types/slice";
import {UploadImageModel} from "@asirisos/types";
import {LiveStockWeightData} from "@asirisos/types/data";

const CONTEXT = "File Upload"

const initialState: FileUploadState = {
    file: undefined,
    files: [],
    status: Status.Unknown,
    message: ''
}

export const uploadImage = createAsyncThunk<any, any, DefaultThunkApiConfig>('ImageUpload/uploadImage', async (imageData, thunkAPI) => {
    try {

        const response = await fileUploadService.uploadImage(imageData)
        if (response.status !== 201)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getAllImages = createAsyncThunk<any, void, DefaultThunkApiConfig>('ImageUpload/getAllImages', async (_, thunkAPI) => {
    try {
        const response = await fileUploadService.getAllImages()
        console.log('This is the response', response.data)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

// export const getLiveStock = createAsyncThunk<LiveStockModel, string, DefaultThunkApiConfig>('LiveStock/getLiveStock/:liveStockId', async (liveStockId: string, thunkAPI) => {
//     try {
//         const response = await liveStockService.getLiveStock(liveStockId)
//         if (response.status !== 200)
//             return thunkAPI.rejectWithValue()

//         return response.data
//     } catch (error) {
//         HandleAxiosError(error, CONTEXT);
//         return thunkAPI.rejectWithValue()
//     }
// })

// export const updateLiveStock = createAsyncThunk<LiveStockModel, LiveStockModel, DefaultThunkApiConfig>('LiveStock/updateLiveStock/:liveStockId', async (liveStockData, thunkAPI) => {
//     try {
//         const account = thunkAPI.getState().account.account
//         if (!account)
//             return thunkAPI.rejectWithValue();

//         const response = await liveStockService.updateLiveStock(liveStockData, account)
//         if (response.status !== 200)
//             return thunkAPI.rejectWithValue()

//         return response.data
//     } catch (error) {
//         HandleAxiosError(error, CONTEXT);
//         return thunkAPI.rejectWithValue()
//     }
// })

// export const deleteLiveStock = createAsyncThunk<any, string[], DefaultThunkApiConfig>('LiveStock/deleteLiveStock/:liveStockId', async (liveStockId, thunkAPI) => {
//     try {
//         const response = await liveStockService.deleteLiveStock(liveStockId)
//         if (response.status !== 200)
//             return thunkAPI.rejectWithValue()

//         return response.data
//     } catch (error) {
//         HandleAxiosError(error, CONTEXT);
//         return thunkAPI.rejectWithValue()
//     }
// })

export const fileUploadSlice = createSlice({
    name: 'fileUpload',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadImage.pending, (state) => {
                state.status = Status.Pending;
            })
            .addCase(uploadImage.fulfilled, (state) => {
                state.status = Status.Success;
            })
            .addCase(uploadImage.rejected, (state) => {
                state.status = Status.Failure;
            })
            .addCase(getAllImages.pending, (state) => {
                state.status = Status.Pending;
            })
            .addCase(getAllImages.fulfilled, (state, action: PayloadAction<any[], any, any>) => {
                state.status = Status.Success;
                state.files = action.payload
                console.log("Action Payload", action.payload)
            })
            .addCase(getAllImages.rejected, (state) => {
                state.status = Status.Failure;
            })
            // .addCase(getLiveStock.pending, (state) => {
            //     state.status = Status.Pending;
            // })
            // .addCase(getLiveStock.fulfilled, (state, action: PayloadAction<LiveStockModel, any, any>) => {
            //     state.status = Status.Success;
            //     state.liveStock = action.payload
            // })
            // .addCase(getLiveStock.rejected, (state) => {
            //     state.status = Status.Failure;
            // })
            // .addCase(updateLiveStock.fulfilled, (state, action: PayloadAction<LiveStockModel, any, any>) => {
            //     state.status = Status.Success
            //     state.allLiveStock.map((liveStock) => liveStock._id === action.payload._id ? (state.liveStock = liveStock) : liveStock)
            // })
            // .addCase(deleteLiveStock.fulfilled, (state) => {
            //     state.status = Status.Success
            //     state.liveStock = undefined
            // })
    }
})

export const {reset} = fileUploadSlice.actions
export default fileUploadSlice.reducer