import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import Spinner from '../../components/Spinner'
import {DataGrid, GridColumns} from '@mui/x-data-grid';
import {Link, useNavigate} from 'react-router-dom'
import { Status } from 'types/slice';
import {toast} from 'react-toastify'
import {AppDispatch, RootState} from "../../../app/store";
import {useTranslation} from "react-i18next";
import DeleteModal from 'UI/components/DeleteModal';
import TableMultiDeleteButton from 'UI/components/TableMultiDeleteButton';
import { deleteTask, getTasks } from 'features/task/taskSlice';

const actionColumn : GridColumns = [
    {
        field: 'action',
        headerName: "Action",
        width: 200,
        renderCell: (params) => {
            return (
                <div className='flex flex-row px-2 pl-4 justify-center items-center gap-2' >
                    <Link to={`/task/${params.row._id}`} className="border-2 rounded-lg border-blue-500 px-2">View</Link>
                </div>
            )
        }
    }
]

const columns : GridColumns = [
  { field: '_id', headerName: 'ID', width: 70 },
  {
    field: 'img',
    headerName: 'Task',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    renderCell:(params)=> {
        return(
          <div className='flex flex-row items-center' >
              <img className=' rounded-xl  w-9 h-9 mr-4'  src="https://pngimage.net/wp-content/uploads/2018/06/prepare-icon-png-4.png" alt="" />
              <div className='text-indigo' >
              {params.row.taskName}
              </div>
          </div>
            
        )
    }
  },
  { field: 'deadline', headerName: 'Deadline', width: 120 },
  { field: 'taskStatus', headerName: 'status', width: 120 },
  { field: 'taskType', headerName: 'Category', width: 120 },
  { field: 'taskOwner', headerName: 'Owner', width: 120 },
  { field: 'taskCrop', headerName: 'Crop', width: 120 },
];




function TasksTable() {

  const {tasks, status} = useSelector((state : RootState) => state.taskTask )
  const [modalIsOpen, setModalIsOpen] = useState(false)

const onDeleteClick = (e: any) => {
    setModalIsOpen(!modalIsOpen)
    console.log(modalIsOpen)
}

const [deleteEvent, setDeleteEvent] = useState<boolean>(true)
const [selectedRows, setSelectedRows] = useState<string[]>([])

const handleSelectionChange = (newSelectionModel : string[]) => {
    setSelectedRows(newSelectionModel)
}

const navigate = useNavigate()

const onDelete = () => {
    dispatch(deleteTask(selectedRows))
    .unwrap()
    .then(() => {
        toast.success(t("task:taskDeleted"));
        navigate("/tasks")
        setModalIsOpen(false)
    })
    .catch(() => {
        toast.error(t("task:failedToDeletetask"))
    })
    setDeleteEvent(!deleteEvent)
    setDeleteEvent(!deleteEvent)
    setSelectedRows([])
}
    const {t} = useTranslation(["equipment", "translation"])
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        // if (status !== Status.Pending || deleteEvent === true || deleteEvent === false)
            // dispatch(getTasks())
    }, [dispatch, deleteEvent])

    // useEffect(() => {
    //     dispatch(getTasks())
    //     if (isSuccess) {
    //     }
    // },[dispatch] )

    if (status === Status.Pending) {
        return<Spinner/>
    }
  return (
    <>

      {selectedRows.length !== 0 ? (<>
      <TableMultiDeleteButton onDeleteClick={onDeleteClick} />
      </>) :(<></>) }
      
      <div style={{ height: 650, width: 'full' }}>
      <DataGrid
        getRowId={(row) => row._id}
        rows={tasks}
        columns={columns.concat(actionColumn)}
        pageSize={20}
        rowsPerPageOptions={[5]}
        checkboxSelection
        onSelectionModelChange={(newSelectionModel : any) => {
          handleSelectionChange(newSelectionModel)
        }}
      />
    </div>
    {/* ////////////////////////////////////////////////////////////////////// */}
    {/* //////////////////////////// DELETE MODAL //////////////////////////// */}

    <DeleteModal modalIsOpen={modalIsOpen} onDeleteClick={onDeleteClick} onDelete={onDelete} />
   
    </>
  )
}

export default TasksTable