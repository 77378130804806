import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import inventoryService from "./inventoryService";
import {InventoryState} from "../../types/states";
import HandleAxiosError from "../../utils/AxiosErrorHandler";
import {DefaultThunkApiConfig, Status} from "../../types/slice";
import {InventoryModel} from "@asirisos/types";

const CONTEXT = "Inventory"

const initialState: InventoryState = {
    inventory: undefined,
    allInventory: [],
    status: Status.Unknown
}

export const addInventory = createAsyncThunk<any, InventoryModel, DefaultThunkApiConfig>('Inventory/addInventory', async (inventoryData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await inventoryService.addInventory(inventoryData, account)
        if (response.status !== 201)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getAllInventory = createAsyncThunk<InventoryModel[], void, DefaultThunkApiConfig>('Inventory/getAllInventory', async (_, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await inventoryService.getAllInventory(account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getInventory = createAsyncThunk<InventoryModel, string, DefaultThunkApiConfig>('Inventory/getInventory/:inventoryId', async (inventoryId: string, thunkAPI) => {
    try {
        const response = await inventoryService.getInventory(inventoryId)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const updateInventory = createAsyncThunk<InventoryModel, InventoryModel, DefaultThunkApiConfig>('Inventory/updateInventory/:inventoryId', async (inventoryData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if(!account)
            return thunkAPI.rejectWithValue();
            
        const response = await inventoryService.updateInventory(inventoryData, account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const deleteInventory = createAsyncThunk<any, string[], DefaultThunkApiConfig>('Inventory/deleteInventory/:inventoryId', async (inventoryId, thunkAPI) => {
    try {
        const response = await inventoryService.deleteInventory(inventoryId)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const inventorySlice = createSlice({
    name: 'inventory',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(addInventory.pending, (state: InventoryState) => {
                state.status = Status.Pending;
            })
            .addCase(addInventory.fulfilled, (state: InventoryState) => {
                state.status = Status.Success
            })
            .addCase(addInventory.rejected, (state: InventoryState) => {
                state.status = Status.Failure
            })
            .addCase(getAllInventory.pending, (state: InventoryState) => {
                state.status = Status.Pending;
            })
            .addCase(getAllInventory.fulfilled, (state: InventoryState, action: PayloadAction<InventoryModel[]>) => {
                state.status = Status.Success
                state.allInventory = action.payload
            })
            .addCase(getAllInventory.rejected, (state: InventoryState) => {
                state.status = Status.Failure
            })
            .addCase(getInventory.pending, (state: InventoryState) => {
                state.status = Status.Pending;
            })
            .addCase(getInventory.fulfilled, (state: InventoryState, action: PayloadAction<InventoryModel>) => {
                state.status = Status.Success
                state.inventory = action.payload
            })
            .addCase(getInventory.rejected, (state: InventoryState) => {
                state.status = Status.Failure
            })
            .addCase(updateInventory.pending, (state: InventoryState) => {
                state.status = Status.Pending
            })
            .addCase(updateInventory.fulfilled, (state: InventoryState, action: PayloadAction<InventoryModel, any, any>) => {
                state.status = Status.Success
                state.allInventory.map((inventory: InventoryModel) => inventory._id === action.payload._id ?
                    (state.inventory = inventory) : inventory)
                state.inventory = action.payload
            })
            .addCase(updateInventory.rejected, (state: InventoryState) => {
                state.status = Status.Failure
            })
            .addCase(deleteInventory.fulfilled, (state: InventoryState) => {
                state.status = Status.Success
                state.inventory = undefined
            })
    }
})

export const {reset} = inventorySlice.actions
export default inventorySlice.reducer