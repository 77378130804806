import axios from "axios"
import {ColumnModel, TaskModel} from "@asirisos/types";

const API_URL = '/api/test'

// //Add Crop
// const addCrop = async (cropData, token) => { 
//     const config = {
//         headers: {
//             Authorization: `Bearer ${token}`
//         }
//     }
//     const response = await axios.post(API_URL+'/addcrop', cropData, config)

//     return response.data
// }


/////////   Add Task to Column

const addTaskToColumn = async (newTaskData: TaskModel, token: string) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }


    const task = await axios.post(API_URL+'/addtasktocolumn', newTaskData, config)

    return task
}


//Get Tasks
const getAllTest = async (token: string) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    console.log('making the request get All Tasks')

    const tasks = await axios.get(API_URL+'/tasks', config)
    // const columns = await axios.get(API_URL+'/columns', config, farmData)
    // const columnOrder = await axios.get(API_URL+'/columnorder', config, farmData)

    // console.log(tasks.data, columns.data, columnOrder.data)

    const response = tasks.data


    return response
}

//Get Columns
const getAllColumns = async (token: string) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    console.log('making the request get All Columns')

    const columns = await axios.get(API_URL+'/columns', config)
    // const columnOrder = await axios.get(API_URL+'/columnorder', config, farmData)

    // console.log(tasks.data, columns.data, columnOrder.data)

    const response = columns.data


    return response
}

const getColumnOrder = async (token: string) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    console.log('making the request to get all columns')

    const columnOrder = await axios.get(API_URL+'/columnorder', config)

    // console.log(tasks.data, columns.data, columnOrder.data)

    const response = columnOrder.data


    return response
}

/////    Update Column

const updateColumn = async(column: ColumnModel, token: string) => {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        // const {} = cropData

        console.log('making request to update column')
    
        const response = await axios.put(API_URL+'/updatecolumn/'+ column._id, column, config)
    
        return response.data
    }

////// Add Column

const addColumn = async(column: ColumnModel, token: string) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    console.log('making request to add column')
    const response = await axios.post(API_URL+'/addcolumn', column, config)

    console.log(response.data)

    return response.data
}

// ////// Add Task

// const addTask = async(task, token) => {
//     const config = {
//         headers: {
//             Authorization: `Bearer ${token}`
//         }
//     }

//     console.log('making request to add task')
//     const response = await axios.post(API_URL+'/addtask', task, config)

//     console.log(response.data)

//     return response.data
// }

// //Get Crop
// const getCrop = async (cropId, token) => {
//     const config = {
//         headers: {
//             Authorization: `Bearer ${token}`
//         }
//     }

//     const response = await axios.get(API_URL+'/getcrop/'+ cropId, config)

//     return response.data
// }

// // Update Crop
// const updateCrop = async(cropData, token) => {
//     const config = {
//         headers: {
//             Authorization: `Bearer ${token}`
//         }
//     }
    
//     const {cropName,
//         landArea,
//         stock,
//         cropStage,
//         cropId} = cropData

//     const response = await axios.put(API_URL+'/updatecrop/'+ cropId, cropData, config)

//     return response.data
// }

// // Delete Crop
// const deleteCrop = async(cropId, token) => {
//     const config = {
//         headers: {
//             Authorization: `Bearer ${token}`
//         }
//     }

//     const response = await axios.delete(API_URL+'/deletecrop/'+cropId, config)
// }

const testService = {
    getAllTest,
    getAllColumns,
    getColumnOrder,
    updateColumn,
    addColumn,
    addTaskToColumn
}

export default testService