import React, { useCallback, useEffect, useState } from "react";
import { PieChart, Pie, Sector } from "recharts";
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Spinner from '../../components/Spinner'
import {AppDispatch, RootState} from "../../../app/store";
import { Status } from "types/slice";
import { useTranslation } from "react-i18next";
import { getAllEquipment } from "features/equipment/equipmentSlice";


export default function EquipmentTotalValue() {

  const {t} = useTranslation("equipment");

  const {equipments, status} = useSelector((state: RootState) => state.equipment)

    // const params = useParams()
    // const {taskId} = useParams()
    const dispatch = useDispatch<AppDispatch>()
    // const naviagate = useNavigate()

    useEffect(() => {
        dispatch(getAllEquipment())
        if (status === Status.Failure) {
            toast.error(t("equipment:equipmentGetFailed"))
        }
    },[dispatch] )


    console.log('EquipmentTotalValue - all activity', equipments)

    
    let totalValue : number = 0
    const countTotalValue = function (arr: any[]) {
        let values : any []
        values = arr.map(entry => {
            const number = parseInt(entry.value)
            return number
        })
        
        totalValue = values.reduce(add, 0 )
        console.log("Equipment Total Value - total value",totalValue)
    }

    function add(accumulator : any, a: any) {
        return accumulator + a;
      }

    countTotalValue(equipments)

    // const countUnique = function (arr : any = []) {
    //     let res : any = [];
    //     console.log('arr', arr, typeof(typeof(arr)))
    //     res = arr?.reduce( (r :any , o: any) => {
    //     //    let category = o.category;
    //        (r[o.type])? ++r[o.type] : r[o.type] = 1;
    //        return r
    //     }, {})
    //     res = Object.keys(res).map(function (k) {
    //        return {"category": k, "count": res[k]};
    //     });
    //     return res;
    //  }
    //  console.log('counting',countUnique(equipments));
    //  const data = countUnique(equipments)
    //  console.log('data', data)


  const [activeIndex, setActiveIndex] = useState(0);
  

  // if (isLoading && data.length !== 0) {
  if (status === Status.Pending && equipments.length === 0 && typeof(equipments) == 'object') {
    <Spinner/>
}

  return (
    <>
        <div className=' rounded-lg shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]  my-1 py-1 col-span-1 grid  #justify-self-center #self-center'>
            <div className='self-center' >
            <div className='flex flex-row justify-center' >
                <div className='text-indigo-500 text-4xl p-6 rounded-xl self-center' >
                <h1 className='text-lg' ></h1>
                    {`$ ${totalValue}`}
                </div>
            </div>   
            </div>
        </div>
    </>
   
  );
}
