import React, {ChangeEventHandler} from "react";
import Select, {ActionMeta} from 'react-select'

type MultiDropDownInputProps = {
    dataTitle: string,
    isSingleSelect: boolean,
    value: any,
    id: string,
    name: string,
    options: Array<any>
    onChange: (option: DropDownOption | null, actionMeta: ActionMeta<DropDownOption>) => void
}

export type DropDownOption = {
    label: string,
    value: string
}

function MultiDropDownInput(props: MultiDropDownInputProps) {
    return (
        <div className="mt-8 relative rounded-xl width250">
            <label className="absolute left-3 insert-y-0 bottom-14 text-slate-500 text-sm">{props.dataTitle}</label>
            <Select
                options={props.options}
                id={props.id}
                isSearchable={false}
                name={props.name}
                className="w-64 shadow-md"
                onChange={props.onChange}
            />
        </div>
    )
}

export default MultiDropDownInput