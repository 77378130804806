import React, {ChangeEvent} from 'react'
import { Link } from 'react-router-dom'
import SearchBox from '../../Map/SearchBox-1'
import {useTabPersistence} from "../../../../hooks/useTabPersistence";
import {useTranslation} from "react-i18next";
import {PageId} from "../../../../types/pages";
import { useState } from 'react'
import DisplayAllFarmersMarkets from '../../Map/DisplayAllFarmersMarkets';
import Button from "../../../components/shared/Button"
import NumberInputInUnits from 'UI/components/shared/NumberInputInUnits';
import { ReactTyped } from 'react-typed';
import Modal from 'react-modal'
// @ts-ignore
import {animations} from 'react-animation';

function ExploreTools() {

    const animation = require ('.././farm_large.png')
    const animation2 = require ('.././adaptive-icon.png')
    const vegetables_in_basket = require ('.././vegetables_in_basket.jpg')
    const vegetable_market = require ('.././vegetable_market.jpg')

    const {t} = useTranslation(["landing", "translation"])
    const {selection, toggleTab} = useTabPersistence(PageId.Equipment);

    const [modalNutritionIsOpen, setModalNutritionIsOpen] = useState(false)
    const onModalNutritionClick = (e: any) => {
        setModalNutritionIsOpen(!modalNutritionIsOpen)
    }

    const [modalHealthInsuranceIsOpen, setModalHealthInsuranceIsOpen] = useState(false)
    const onModalHealthInsuranceClick = (e: any) => {
        setModalHealthInsuranceIsOpen(!modalHealthInsuranceIsOpen)
    }

    const [modalPersonalFinanceIsOpen, setModalPersonalFinanceIsOpen] = useState(false)
    const onModalPersonalFinanceClick = (e: any) => {
        setModalPersonalFinanceIsOpen(!modalPersonalFinanceIsOpen)
    }

    const [modalChildCareIsOpen, setModalChildCareIsOpen] = useState(false)
    const onModalChildCareClick = (e: any) => {
        setModalChildCareIsOpen(!modalChildCareIsOpen)
    }

    const [modalLocalPolicyIsOpen, setModalLocalPolicyIsOpen] = useState(false)
    const onModalLocalPolicyClick = (e: any) => {
        setModalLocalPolicyIsOpen(!modalLocalPolicyIsOpen)
    }

    const [modalVehicleOwnershipIsOpen, setModalVehicleOwnershipIsOpen] = useState(false)
    const onModalVehicleOwnershipClick = (e: any) => {
        setModalVehicleOwnershipIsOpen(!modalVehicleOwnershipIsOpen)
    }

    const [modalHomeOwnershipIsOpen, setModalHomeOwnershipIsOpen] = useState(false)
    const onModalHomeOwnershipClick = (e: any) => {
        setModalHomeOwnershipIsOpen(!modalHomeOwnershipIsOpen)
    }

  return (
    <>

    {/***************************************************************************************************************************************************************************/}
    {/************************************************************************************ MOBILE VERSION START *****************************************************************/}
    {/************************************************************************************ MOBILE VERSION START *****************************************************************/}
    {/************************************************************************************ MOBILE VERSION START *****************************************************************/}
    {/************************************************************************************ MOBILE VERSION START *****************************************************************/}
    {/***************************************************************************************************************************************************************************/}

    <div className='lg:hidden sm:visible' >

        <div className='flex flex-col'>

            {/********************************************/}
            {/********************* FIRST PAGE MOBILE - START ***********************/}
            {/********************************************/}

            <div className='w-screen h-full flex flex-col justify-start align-top '>

                {/********************************************/}
                {/********************* MENU BAR - START ***********************/}
                {/********************************************/}
                {/* <div className='bg-[#1763db] flex flex-col justify-between  drop-shadow-md shadow-[2px_1px_20px_3px_rgba(201,201,201,0.97)]'>

                    <div className='  flex flex-col justify-left px-3 '> 

                        <Link to="/asiris-v2-landing" className='flex'>
                            <div className='flex flex-row ' >
                                <h1 className='text-2xl font-normal text-white' >AsirisOS</h1>                        
                            </div>
                        </Link>

                        <div className='flex '>
                            <h1 className='max-w-xl text-md text-start font-thin text-white'> 
                                Teaching things that Schools should
                            </h1>
                        </div>
                    </div>

                </div> */}

                {/********************************************/}
                {/********************* MENU BAR - END ***********************/}
                {/********************************************/}


                {/********************************************/}
                {/********************* FIRST PAGE MOBILE - START ***********************/}
                {/********************************************/}

                <div className='flex flex-col justify-center items-center w-full shadow-[2px_1px_20px_3px_rgba(201,201,201,0.97)]' style={{animation: animations.fadeInUp}}>        
                    <Link to={'/asiris-v2-get-started'} className='w-full'>
                        <button className='flex justify-center w-full hover:drop-shadow-2xl text-white bg-[#f58217] hover:text-white py-3 font-medium  text-xl'>
                            <h1>Get Started</h1>
                            <img className='w-7 ml-2' src="https://media3.giphy.com/media/EtLdAZH3I56suXvQO9/giphy.gif?cid=6c09b95209o4gml4nwi7za7epihn9hf3qtkwiepr1plrd81e&ep=v1_stickers_related&rid=giphy.gif&ct=s" alt=" " />
                        </button>
                    </Link>                 
                </div>

                <div className='flex flex-col justify-start h-full w-full' style={{animation: animations.fadeInUp}} >

                    <div className='flex justify-left px-3 pt-2 w-full'>                  
                        <div className='flex flex-col '>
                            <h1 className='sm:py-1 max-sm:text-4xl max-sm:font-light text-left text-slate-500 pb-1 '>Tools <i className="fa-solid fa-screwdriver-wrench pl-1 text-2xl"></i></h1>
                            <h1 className='max-sm:text-xl max-sm:font-normal text-left  pb-3'>Manage and track your everday life in one place</h1>
                            {/* <h1 className=' max-sm:text-2xl max-sm:font-normal text-left  pb-3' style={{animation: animations.fadeInUp}}>Organiz and track<br/>
                            <a className='font-bold text-2xl text-[#1763db]'>
                                <ReactTyped
                                    strings = {[
                                        " Taxes",
                                        " Child Care",
                                        " Personal Finances",
                                        " Mental Health",
                                        " Credit Cards",
                                        " Career Building"
                                    ]}                    
                                    typeSpeed = {70}
                                    backSpeed = {50}   
                                    loop
                                /></a><br/>all in one place
                                
                            </h1> */}
                        </div>
                    </div>
                
                </div>

                {/********************************************/}
                {/********************* CLASSES CARDS - START ***********************/}
                {/********************************************/}


                <div className='pl-2 py-4' style={{animation: animations.fadeInUp}}>
                
                    <div className='carousel py-3 pb-6'>

                        <div id="item1" className='carousel-item w-4/6 pl-2'>
                            <div className='text-[#16341fec] bg-[#ffffff] w-10/12 drop-shadow-xl flex flex-col justify-between ' onClick={onModalPersonalFinanceClick}>
                                <div>
                                    <img className='' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYCPTwAxSx8PvFiH4cx4Xcjb71Amk16rf6suce4yH2C4j6aJVn8CvWwTt8IUcKsvfkjIo&usqp=CAU' alt="" />
                                </div>

                                <div className='px-2 mb-2'>
                                    <div className=' pt-2 '>
                                        <h1 className='text-start text-xl font-medium'>Financial Planning</h1>
                                    </div>
                                    <div className='py-1 my-1 mt-2 flex flex-col w-fit text-[#1763db] hover:bg-[#e2edff]' onClick={onModalPersonalFinanceClick}>
                                        <h1 className='text-sm font-light px-1'>LEARN MORE</h1>
                                    </div>
                                </div>
                            </div>
                            <Modal className={'my-5 mx-5 pb-5 px-3 py-2 drop-shadow-2xl bg-[#f8f8f8] rounded-xl '} isOpen={modalPersonalFinanceIsOpen}>
                                <div className='relative'>
                                    <div className=' absolute top-0 right-0' onClick={onModalPersonalFinanceClick}>
                                        <h1 className=' bg-[#1763db] text-xl rounded-xl text-white font-normal px-2'> X </h1>
                                    </div>

                                    <div className='pt-5 flex flex-col '>
                                        <div className=' flex flex-col justify-center'>
                                            <h1 className='text-2xl font-semibold'>Financial Planning</h1>
                                            <h1 className='text-sm font-medium'>Create budgets, track spending, debt, and net worth</h1>
                                        </div>

                                        <div className='py-3' style={{animation: animations.fadeInUp}}>
                                            <div className='carousel py-2 pb-4 ml-5'>

                                                <div id="item1-1" className='carousel-item w-5/6 pl-2' >
                                                    <div className='text-[#16341fec] bg-[#ffffff] w-11/12 drop-shadow-xl rounded-lg    '>
                                                        <div className='flex justify-center'>
                                                            <img className='rounded-t-lg w-20 py-2' src='https://static.thenounproject.com/png/986292-200.png' alt="" />
                                                        </div>

                                                        <div className='flex flex-col '>
                                                            <div className=' pt-2 '>
                                                                <h1 className='text-xl text-center font-bold'>Budgeting</h1>
                                                            </div>
                                                            <hr/>
                                                            <div className='self-center  pb-1 rounded-b-lg px-2'>
                                                                <h1 className='pt-2 text-md font-light text-left' >Create and track your budget to stay on track and reach your financial goals</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div id="item1-2" className='carousel-item w-5/6 pl-2' >
                                                    <div className='text-[#16341fec] bg-[#ffffff] w-11/12 drop-shadow-xl rounded-lg    '>
                                                        <div className='flex justify-center'>
                                                            <img className='rounded-t-lg w-20 py-2' src='https://static.thenounproject.com/png/3052444-200.png' alt="" />
                                                        </div>

                                                        <div className='flex flex-col '>
                                                            <div className=' pt-2 '>
                                                                <h1 className='text-xl text-center font-bold'>Spending Tracker</h1>
                                                            </div>
                                                            <hr/>
                                                            <div className='self-center  pb-1 rounded-b-lg px-2'>
                                                                <h1 className='pt-2 text-md font-light text-left' >We make it easy to track every spending with easy to use spend reports for all your family members</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div id="item1-3" className='carousel-item w-5/6 pl-2' >
                                                    <div className='text-[#16341fec] bg-[#ffffff] w-11/12 drop-shadow-xl rounded-lg    '>
                                                        <div className='flex justify-center'>
                                                            <img className='rounded-t-lg w-20 py-2' src='https://cdn-icons-png.freepik.com/512/1472/1472623.png' alt="" />
                                                        </div>

                                                        <div className='flex flex-col '>
                                                            <div className=' pt-2 '>
                                                                <h1 className='text-xl text-center font-bold'>Dedicated Funds</h1>
                                                            </div>
                                                            <hr/>
                                                            <div className='self-center  pb-1 rounded-b-lg px-2'>
                                                                <h1 className='pt-2 text-md font-light text-left' >Create dedicated fund wallets to track your savings for specific events</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div id="item1-4" className='carousel-item w-5/6 '>
                                                    <div className='text-[#16341fec] flex flex-col justify-center w-11/12 drop-shadow-xl rounded-lg bg-white   '>
                                                        <div className='flex justify-center'>
                                                            <div className='pb-3'>
                                                                <h1 className='text-3xl text-white bg-[#1763db] px-3 pb-1 rounded-full'>+</h1>
                                                            </div>
                                                        </div>

                                                        <div className='flex flex-col '>
                                                            <div className=' pt-2 '>
                                                                <h1 className='text-xl font-bold text-center'>More to Come</h1>
                                                                <h1 className='text-lg font-semibold' ></h1>
                                                            </div>
                                                            <hr/>
                                                            <div className='self-center  pb-1 rounded-b-lg px-2 pt-2 flex flex-col text-start'>
                                                                <h1 className=' text-sm font-light text-start' >- Financial Tool Directory</h1>
                                                                <h1 className=' text-sm font-light text-start' >- Resource Directory</h1>
                                                                <h1 className=' text-sm font-light text-start' >- Net Worth Tracker</h1>
                                                                <h1 className=' text-sm font-light text-start' >- Debt Tracker</h1>
                                                                <h1 className=' text-sm font-light text-start' >- Emergency Planner</h1>
                                                                <h1 className=' text-sm font-light text-start' >- Insurance Negotiation</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> 

                                            </div>    

                                            <div className='flex justify-center w-full pb-1 gap-5 '>
                                                <a href="#item1-1" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg active:bg-gray-500'></a>
                                                <a href="#item1-2" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg active:bg-gray-500'></a>
                                                <a href="#item1-3" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg active:bg-gray-500'></a>
                                                <a href="#item1-4" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg active:bg-gray-500'></a>
                                            </div>                                        
                                        </div> 


                                    </div>
                                </div>
                                <Link to={'/asiris-v2-get-started'} className='w-full justify-center flex flex-row pt-4'>
                                    <button className='flex justify-center w-2/3 hover:drop-shadow-2xl rounded-md text-white bg-[#f58217] hover:text-white py-1 font-medium  text-xl'>
                                        <h1>Get Started</h1>                                        
                                    </button>
                                </Link>                                
                            </Modal>
                        </div>

                        <div id="item2" className='carousel-item w-4/6 pl-2'>
                            <div className='text-[#16341fec] bg-[#ffffff] w-10/12 drop-shadow-xl flex flex-col justify-between' onClick={onModalHealthInsuranceClick} >
                                <div className=''>
                                    <img className='' src='https://healthexec.com/sites/default/files/styles/top_stories/public/2018-07/istock-940167624_super.jpg.webp?itok=tIG3Z4m4' alt="" />                                
                                </div>

                                <div className='px-2 mb-2'>
                                    <div className=' pt-2 '>
                                        <h1 className='text-start text-xl font-medium'>Health</h1>
                                    </div>
                                    <div className='py-1 my-1 mt-2 flex flex-col w-fit text-[#1763db] hover:bg-[#e2edff]' onClick={onModalHealthInsuranceClick}>
                                        <h1 className='text-sm font-light px-1'>LEARN MORE</h1>
                                    </div>
                                </div>
                            </div>
                            <Modal className={'my-5 mx-5 pb-5 px-3 py-2 drop-shadow-2xl bg-[#f8f8f8] rounded-xl '} isOpen={modalHealthInsuranceIsOpen}>
                                <div className='relative'>
                                    <div className=' absolute top-0 right-0' onClick={onModalHealthInsuranceClick}>
                                        <h1 className=' bg-[#1763db] text-xl rounded-xl text-white font-normal px-2'> X </h1>
                                    </div>

                                    <div className='pt-5 flex flex-col '>
                                        <div className=' flex flex-col justify-center'>
                                            <h1 className='text-2xl font-semibold'>Health & Wellness</h1>
                                            <h1 className='text-sm font-medium'>Track your most vital health markers to improve the quality and longevity of your life</h1>
                                        </div>

                                        <div className='py-3' style={{animation: animations.fadeInUp}}>
                                            <div className='carousel py-2 pb-4 ml-5'>

                                                <div id="item1-1" className='carousel-item w-5/6 pl-2' >
                                                    <div className='text-[#16341fec] bg-[#ffffff] w-11/12 drop-shadow-xl rounded-lg    '>
                                                        <div className='flex justify-center'>
                                                            <img className='rounded-t-lg w-20 py-2' src='https://cdn0.iconfinder.com/data/icons/medicine-outline-part-3-v-2/128/ic_analytics-512.png' alt="" />
                                                        </div>

                                                        <div className='flex flex-col '>
                                                            <div className=' pt-2 '>
                                                                <h1 className='text-xl text-center font-bold'>Health Markers</h1>
                                                            </div>
                                                            <hr/>
                                                            <div className='self-center  pb-1 rounded-b-lg px-2'>
                                                                <h1 className='pt-2 text-md font-light text-left'>Track the most significant tests and markers to solve the root cause of your problems and prevent debilitating chronic illnesses</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div id="item1-2" className='carousel-item w-5/6 pl-2' >
                                                    <div className='text-[#16341fec] bg-[#ffffff] w-11/12 drop-shadow-xl rounded-lg    '>
                                                        <div className='flex justify-center'>
                                                            <img className='rounded-t-lg w-20 py-2' src='https://cdn.iconscout.com/icon/free/png-256/free-health-literacy-5136110-4285282.png?f=webp' alt="" />
                                                        </div>

                                                        <div className='flex flex-col '>
                                                            <div className=' pt-2 '>
                                                                <h1 className='text-xl text-center font-bold'>Health Directory</h1>
                                                            </div>
                                                            <hr/>
                                                            <div className='self-center  pb-1 rounded-b-lg px-2'>
                                                                <h1 className='pt-2 text-md font-light text-left'>Find existing tools and resources - software, services, podcasts - to extend your understanding </h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div id="item1-3" className='carousel-item w-5/6 pl-2' >
                                                    <div className='text-[#16341fec] bg-[#ffffff] w-11/12 drop-shadow-xl rounded-lg    '>
                                                        <div className='flex justify-center'>
                                                            <img className='rounded-t-lg w-20 py-2' src='https://cdn-icons-png.flaticon.com/512/4003/4003747.png' alt="" />
                                                        </div>

                                                        <div className='flex flex-col '>
                                                            <div className=' pt-2 '>
                                                                <h1 className='text-xl text-center font-bold'>Preventive Care</h1>
                                                            </div>
                                                            <hr/>
                                                            <div className='self-center  pb-1 rounded-b-lg px-2'>
                                                                <h1 className='pt-2 text-md font-light text-left' >Regular check-ups, screenings, vaccinations, healthy habits that prevent chronic diseases and improve quality of life</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div id="item1-4" className='carousel-item w-5/6 '>
                                                    <div className='text-[#16341fec] flex flex-col justify-center w-11/12 drop-shadow-xl rounded-lg bg-white   '>
                                                        <div className='flex justify-center'>
                                                            <div className='pb-3'>
                                                                <h1 className='text-3xl text-white bg-[#1763db] px-3 pb-1 rounded-full'>+</h1>
                                                            </div>
                                                        </div>

                                                        <div className='flex flex-col '>
                                                            <div className=' pt-2 '>
                                                                <h1 className='text-xl font-bold text-center'>More to Come</h1>
                                                                <h1 className='text-lg font-semibold' ></h1>
                                                            </div>
                                                            <hr/>
                                                            <div className='self-center  pb-1 rounded-b-lg px-1 pt-2 flex flex-col text-start'>
                                                                <h1 className=' text-sm font-light text-start' >- Health Insurance Tools</h1>
                                                                <h1 className=' text-sm font-light text-start' >- Health Billing Tool</h1>
                                                                <h1 className=' text-sm font-light text-start' >- Fitness Tracking</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> 

                                            </div>    

                                            <div className='flex justify-center w-full pb-1 gap-5 '>
                                                <a href="#item1-1" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg active:bg-gray-500'></a>
                                                <a href="#item1-2" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg active:bg-gray-500'></a>
                                                <a href="#item1-3" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg active:bg-gray-500'></a>
                                                <a href="#item1-4" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg active:bg-gray-500'></a>
                                            </div>                                        
                                        </div> 


                                    </div>
                                </div>
                                <Link to={'/asiris-v2-get-started'} className='w-full justify-center flex flex-row pt-4'>
                                    <button className='flex justify-center w-2/3 hover:drop-shadow-2xl rounded-md text-white bg-[#f58217] hover:text-white py-1 font-medium  text-xl'>
                                        <h1>Get Started</h1>                                        
                                    </button>
                                </Link>                                 
                            </Modal>
                        </div>

                        <div id="item3" className='carousel-item w-4/6 pl-2' >
                            <div className='text-[#16341fec] bg-[#ffffff] w-10/12 drop-shadow-xl  flex flex-col justify-between  ' onClick={onModalChildCareClick}>
                                <div className=''>
                                    <div className=''>
                                        <img className='' src='https://www.gacdc.org/uploads/8/1/9/9/8199054/teacher-and-child_orig.jpg' alt="" />                            
                                    </div>
                                </div>

                                <div className='px-2 mb-2'>
                                    <div className=' pt-2 '>
                                        <h1 className='text-start text-xl font-medium'>Child Care</h1>
                                    </div>
                                    <div className='py-1 my-1 mt-2 flex flex-col w-fit text-[#1763db] hover:bg-[#e2edff]' onClick={onModalChildCareClick}>
                                        <h1 className='text-sm font-light px-1'>LEARN MORE</h1>
                                    </div>
                                </div>
                            </div>
                            <Modal className={'my-5 mx-5 pb-5 px-3 py-2 drop-shadow-2xl bg-white rounded-xl '} isOpen={modalChildCareIsOpen}>
                                <div className='relative'>
                                    <div className=' absolute top-0 right-0' onClick={onModalChildCareClick}>
                                        <h1 className=' bg-[#1763db] text-xl rounded-xl text-white font-normal px-2'> X </h1>
                                    </div>

                                    <div className='pt-5 flex flex-col '>
                                        <div className=' flex flex-col justify-center  '>
                                            <h1 className='text-2xl font-semibold'>Child Care</h1>
                                            <h1 className='text-sm font-medium'>Track and improve your child's health - physical, mental, social</h1>
                                        </div>
                                        <div className='py-3' style={{animation: animations.fadeInUp}}>
                                            <div className='carousel py-2 pb-4 ml-5'>

                                                <div id="item1-1" className='carousel-item w-5/6 pl-2' >
                                                    <div className='text-[#16341fec] bg-[#ffffff] w-11/12 drop-shadow-xl rounded-lg    '>
                                                        <div className='flex justify-center'>
                                                            <img className='rounded-t-lg w-20 py-2' src='https://cdn4.iconfinder.com/data/icons/baby-90/512/baby-kid-child-19-512.png' alt="" />
                                                        </div>

                                                        <div className='flex flex-col '>
                                                            <div className=' pt-2 '>
                                                                <h1 className='text-xl text-center font-bold'>Child Development Tracker</h1>
                                                            </div>
                                                            <hr/>
                                                            <div className='self-center  pb-1 rounded-b-lg px-2'>
                                                                <h1 className='pt-2 text-md font-light text-left' >Track your childs development with a simple tool and find the best practices to help and promote development on every step.</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div id="item1-2" className='carousel-item w-5/6 pl-2' >
                                                    <div className='text-[#16341fec] bg-[#ffffff] w-11/12 drop-shadow-xl rounded-lg    '>
                                                        <div className='flex justify-center'>
                                                            <img className='rounded-t-lg w-20 py-2' src='https://static.thenounproject.com/png/2695807-200.png' alt="" />
                                                        </div>

                                                        <div className='flex flex-col '>
                                                            <div className=' pt-2 '>
                                                                <h1 className='text-xl text-center font-bold'>Prenatal Care</h1>
                                                            </div>
                                                            <hr/>
                                                            <div className='self-center  pb-1 rounded-b-lg px-2'>
                                                                <h1 className='pt-2 text-md font-light text-left' >Track the timeline and your preparedness when concieving a child and caring for prenatal development</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div id="item1-3" className='carousel-item w-5/6 pl-2' >
                                                    <div className='text-[#16341fec] bg-[#ffffff] w-11/12 drop-shadow-xl rounded-lg    '>
                                                        <div className='flex justify-center'>
                                                            <img className='rounded-t-lg w-20 py-2' src='https://cdn1.iconfinder.com/data/icons/medicine-and-health-4/66/37-512.png' alt="" />
                                                        </div>

                                                        <div className='flex flex-col '>
                                                            <div className=' pt-2 '>
                                                                <h1 className='text-xl text-center font-bold'>Nutrition Schedule</h1>
                                                            </div>
                                                            <hr/>
                                                            <div className='self-center  pb-1 rounded-b-lg px-2'>
                                                                <h1 className='pt-2 text-md font-light text-left' >Track your child's feeding schedule to make sure they get plenty of neccessary nutrition</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div id="item1-4" className='carousel-item w-5/6 '>
                                                    <div className='text-[#16341fec] flex flex-col justify-center w-11/12 drop-shadow-xl rounded-lg bg-white   '>
                                                        <div className='flex justify-center'>
                                                            <div className='pb-3'>
                                                                <h1 className='text-3xl text-white bg-[#1763db] px-3 pb-1 rounded-full'>+</h1>
                                                            </div>
                                                        </div>

                                                        <div className='flex flex-col '>
                                                            <div className=' pt-2 '>
                                                                <h1 className='text-xl font-bold text-center'>More to Come</h1>
                                                                <h1 className='text-lg font-semibold' ></h1>
                                                            </div>
                                                            <hr/>
                                                            <div className='self-center  pb-1 rounded-b-lg px-1 pt-2 flex flex-col text-start'>
                                                                <h1 className=' text-sm font-light text-start' >- Educational Tracker</h1>
                                                                <h1 className=' text-sm font-light text-start' >- Khan Academy Tracker</h1>
                                                                <h1 className=' text-sm font-light text-start' >- Resource Directory</h1>
                                                                <h1 className=' text-sm font-light text-start' >- Child Activity Tacking</h1>
                                                                <h1 className=' text-sm font-light text-start' >- Child Care Support Programms</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> 

                                            </div>    

                                            <div className='flex justify-center w-full pb-1 gap-5 '>
                                                <a href="#item1-1" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg active:bg-gray-500'></a>
                                                <a href="#item1-2" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg active:bg-gray-500'></a>
                                                <a href="#item1-3" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg active:bg-gray-500'></a>
                                                <a href="#item1-4" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg active:bg-gray-500'></a>
                                            </div>                                        
                                        </div> 


                                    </div>
                                </div>
                                <Link to={'/asiris-v2-get-started'} className='w-full justify-center flex flex-row pt-4'>
                                    <button className='flex justify-center w-2/3 hover:drop-shadow-2xl rounded-md text-white bg-[#f58217] hover:text-white py-1 font-medium  text-xl'>
                                        <h1>Get Started</h1>                                        
                                    </button>
                                </Link>                            
                            </Modal>
                        </div>

                        <div id="item4" className='carousel-item w-4/6 pl-2' >
                            <div className='text-[#16341fec] bg-[#ffffff] w-10/12 drop-shadow-xl   flex flex-col justify-between ' onClick={onModalNutritionClick}>
                                <div>
                                    <img className='' src='https://medlineplus.gov/images/Nutrition_share.jpg' />
                                </div>

                                <div className='px-2 mb-2'>
                                    <div className=' pt-2 '>
                                        <h1 className='text-start text-xl font-medium'>Nutrition Tools</h1>
                                    </div>
                                    <div className='py-1 my-1 mt-2 flex flex-col w-fit text-[#1763db] hover:bg-[#e2edff]' onClick={onModalNutritionClick}>
                                        <h1 className='text-sm font-light px-1'>LEARN MORE</h1>
                                    </div>
                                </div>
                            </div>
                            <Modal className={'my-5 mx-5 pb-5 px-3 py-2 drop-shadow-2xl bg-white rounded-xl '} isOpen={modalNutritionIsOpen}>
                                <div className='relative'>
                                    <div className=' absolute top-0 right-0' onClick={onModalNutritionClick}>
                                        <h1 className=' bg-[#1763db] text-xl rounded-xl text-white font-normal px-2'> X </h1>
                                    </div>

                                    <div className='pt-5 flex flex-col '>
                                        <div className=' flex flex-col justify-center  '>
                                            <h1 className='text-2xl font-semibold'>Nutrition Tools</h1>
                                            <h1 className='text-sm font-medium'>Keep more money in your pocket</h1>
                                        </div>

                                        <div className='py-3' style={{animation: animations.fadeInUp}}>
                                            <div className='carousel py-2 pb-4 ml-5'>

                                                <div id="item1-1" className='carousel-item w-5/6 pl-2' >
                                                    <div className='text-[#16341fec] bg-[#ffffff] w-11/12 drop-shadow-xl rounded-lg    '>
                                                        <div className='flex justify-center'>
                                                            <img className='rounded-t-lg w-20 py-2' src='https://cdn-icons-png.flaticon.com/512/1147/1147873.png' alt="" />
                                                        </div>

                                                        <div className='flex flex-col '>
                                                            <div className=' pt-2 '>
                                                                <h1 className='text-xl text-center font-bold'>Local Food Search</h1>
                                                            </div>
                                                            <hr/>
                                                            <div className='self-center  pb-1 rounded-b-lg px-2'>
                                                                <h1 className='pt-2 text-md font-light text-left'>Find your local food producers and consumer goods brands</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div id="item1-2" className='carousel-item w-5/6 pl-2' >
                                                    <div className='text-[#16341fec] bg-[#ffffff] w-11/12 drop-shadow-xl rounded-lg    '>
                                                        <div className='flex justify-center'>
                                                            <img className='rounded-t-lg w-20 py-2' src='https://static.thenounproject.com/png/3215708-200.png' alt="" />
                                                        </div>

                                                        <div className='flex flex-col '>
                                                            <div className=' pt-2 '>
                                                                <h1 className='text-xl text-center font-bold'>Food Health Score</h1>
                                                            </div>
                                                            <hr/>
                                                            <div className='self-center  pb-1 rounded-b-lg px-2'>
                                                                <h1 className='pt-2 text-md font-light text-left'>Scan your food with analyzer and see it's health score</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div id="item1-3" className='carousel-item w-5/6 pl-2' >
                                                    <div className='text-[#16341fec] bg-[#ffffff] w-11/12 drop-shadow-xl rounded-lg    '>
                                                        <div className='flex justify-center'>
                                                            <img className='rounded-t-lg w-20 py-2' src='https://bariatricmealprep.com/wp-content/uploads/2022/09/Bariatric-Meal-Prep-Icon.png' alt="" />
                                                        </div>

                                                        <div className='flex flex-col '>
                                                            <div className=' pt-2 '>
                                                                <h1 className='text-xl text-center font-bold'>Meal Prep</h1>
                                                            </div>
                                                            <hr/>
                                                            <div className='self-center  pb-1 rounded-b-lg px-2'>
                                                                <h1 className='pt-2 text-md font-light text-left'>Find the best whole food meal prep schedules to eat healthy and save money</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div id="item1-4" className='carousel-item w-5/6 '>
                                                    <div className='text-[#16341fec] flex flex-col justify-center w-11/12 drop-shadow-xl rounded-lg bg-white   '>
                                                        <div className='flex justify-center'>
                                                            <div className='pb-3'>
                                                                <h1 className='text-3xl text-white bg-[#1763db] px-3 pb-1 rounded-full'>+</h1>
                                                            </div>
                                                        </div>

                                                        <div className='flex flex-col '>
                                                            <div className=' pt-2 '>
                                                                <h1 className='text-xl font-bold text-center'>More to Come</h1>
                                                                <h1 className='text-lg font-semibold' ></h1>
                                                            </div>
                                                            <hr/>
                                                            <div className='self-center  pb-1 rounded-b-lg px-1 pt-2 flex flex-col text-start'>
                                                                <h1 className=' text-sm font-light text-start' >- Tax Brackets</h1>
                                                                <h1 className=' text-sm font-light text-start' >- Tax Bracket Benefits</h1>
                                                                <h1 className=' text-sm font-light text-start' >- Tax Withholding</h1>
                                                                <h1 className=' text-sm font-light text-start' >- Tax-Advantaged Accounts</h1>
                                                                <h1 className=' text-sm font-light text-start' >- State & Local Taxes</h1>
                                                                <h1 className=' text-sm font-light text-start' >- Tax Resources</h1>
                                                                <h1 className=' text-sm font-light text-start' >- Tax Scams and Fraud</h1>                                                            
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> 

                                            </div>    

                                            <div className='flex justify-center w-full pb-1 gap-5 '>
                                                <a href="#item1-1" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg active:bg-gray-500'></a>
                                                <a href="#item1-2" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg active:bg-gray-500'></a>
                                                <a href="#item1-3" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg active:bg-gray-500'></a>
                                                <a href="#item1-4" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg active:bg-gray-500'></a>
                                            </div>                                        
                                        </div> 


                                    </div>
                                </div>
                                <Link to={'/asiris-v2-get-started'} className='w-full justify-center flex flex-row pt-4'>
                                    <button className='flex justify-center w-2/3 hover:drop-shadow-2xl rounded-md text-white bg-[#f58217] hover:text-white py-1 font-medium  text-xl'>
                                        <h1>Get Started</h1>                                        
                                    </button>
                                </Link>                                
                            </Modal>
                        </div>

                        <div id="item5" className='carousel-item w-4/6 pl-2'>
                            <div className='text-[#16341fec] bg-[#ffffff] w-10/12 drop-shadow-xl  flex flex-col justify-between ' onClick={onModalLocalPolicyClick}>
                                <div>
                                    <img className='' src='https://www.networkforphl.org/wp-content/uploads/2022/08/iStock-1272497403-1392x0-c-default-q40.jpg' />
                                </div>

                                <div className='px-2 mb-2'>
                                    <div className=' pt-2 '>
                                        <h1 className='text-start text-xl font-medium'>Civic Engagement</h1>
                                    </div>
                                    <div className='py-1 my-1 mt-2 flex flex-col w-fit text-[#1763db] hover:bg-[#e2edff]' onClick={onModalPersonalFinanceClick}>
                                        <h1 className='text-sm font-light px-1'>LEARN MORE</h1>
                                    </div>
                                </div>
                            </div>
                            <Modal className={'my-5 mx-5 px-3 py-2 drop-shadow-2xl bg-white rounded-xl '} isOpen={modalLocalPolicyIsOpen}>
                                <div className='relative'>
                                    <div className=' absolute top-0 right-0' onClick={onModalLocalPolicyClick}>
                                        <h1 className=' bg-[#1763db] text-xl rounded-xl text-white font-normal px-2'> X </h1>
                                    </div>

                                    <div className='pt-5 flex flex-col '>
                                        <div className=' flex flex-col justify-center'>
                                            <h1 className='text-2xl font-semibold'>Civiv Engagement</h1>
                                            <h1 className='text-sm font-medium'>Understand how government works and how to make impact on pilicies</h1>
                                        </div>

                                        <div className='py-3' style={{animation: animations.fadeInUp}}>
                                            <div className='carousel py-2 pb-4 ml-5'>

                                                <div id="item1-1" className='carousel-item w-5/6 pl-2' >
                                                    <div className='text-[#16341fec] bg-[#ffffff] w-11/12 drop-shadow-xl rounded-lg    '>
                                                        <div className='flex justify-center'>
                                                            <img className='rounded-t-lg w-20 py-2' src='https://cdn-icons-png.flaticon.com/512/6820/6820898.png' alt="" />
                                                        </div>

                                                        <div className='flex flex-col '>
                                                            <div className=' pt-2 '>
                                                                <h1 className='text-xl text-center font-bold px-2'>Local Dashboard</h1>
                                                            </div>
                                                            <hr/>
                                                            <div className='self-center  pb-1 rounded-b-lg px-2'>
                                                                <h1 className='pt-2 text-sm font-light text-left' >See how your city is performing on various metrics, track changes, and stay informed about long-term trends</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div id="item1-2" className='carousel-item w-5/6 pl-2' >
                                                    <div className='text-[#16341fec] bg-[#ffffff] w-11/12 drop-shadow-xl rounded-lg    '>
                                                        <div className='flex justify-center'>
                                                            <img className='rounded-t-lg w-20 py-2' src='https://cdn-icons-png.flaticon.com/512/3079/3079254.png' alt="" />
                                                        </div>

                                                        <div className='flex flex-col '>
                                                            <div className=' pt-2 '>
                                                                <h1 className='text-xl text-center font-bold'>Local Governance</h1>
                                                            </div>
                                                            <hr/>
                                                            <div className='self-center  pb-1 rounded-b-lg px-2'>
                                                                <h1 className='pt-2 text-sm font-light text-left' >Provides insight into your local political office, elections, and new candidates</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div id="item1-3" className='carousel-item w-5/6 pl-2' >
                                                    <div className='text-[#16341fec] bg-[#ffffff] w-11/12 drop-shadow-xl rounded-lg    '>
                                                        <div className='flex justify-center'>
                                                            <img className='rounded-t-lg w-20 py-2' src='https://www.axians.com/app/uploads/sites/11/2020/11/GovLA_Icon.png' alt="" />
                                                        </div>

                                                        <div className='flex flex-col '>
                                                            <div className=' pt-2 '>
                                                                <h1 className='text-xl text-center font-bold'>Local Reports</h1>
                                                            </div>
                                                            <hr/>
                                                            <div className='self-center  pb-1 rounded-b-lg px-2'>
                                                                <h1 className='pt-2 text-sm font-light text-left' >Shine a spotlight on important issues in your neighbourhood to help locel government find and fix what's important to you</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div id="item1-4" className='carousel-item w-5/6 '>
                                                    <div className='text-[#16341fec] flex flex-col justify-center w-11/12 drop-shadow-xl rounded-lg bg-white   '>
                                                        <div className='flex justify-center'>
                                                            <div className='pb-3'>
                                                                <h1 className='text-3xl text-white bg-[#1763db] px-3 pb-1 rounded-full'>+</h1>
                                                            </div>
                                                        </div>

                                                        <div className='flex flex-col '>
                                                            <div className=' pt-2 '>
                                                                <h1 className='text-xl font-bold text-center'>More to Come</h1>
                                                                <h1 className='text-lg font-semibold' ></h1>
                                                            </div>
                                                            <hr/>
                                                            <div className='self-center  pb-1 rounded-b-lg px-1 pt-2 flex flex-col text-start'>
                                                                <h1 className=' text-sm font-light text-start' >- Advocacy Groups</h1>
                                                                <h1 className=' text-sm font-light text-start' >- Local Volunteering</h1>
                                                                <h1 className=' text-sm font-light text-start' >- Non-profits</h1>                                                                                                       
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> 

                                            </div>    

                                            <div className='flex justify-center w-full pb-1 gap-5 '>
                                                <a href="#item1-1" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg active:bg-gray-500'></a>
                                                <a href="#item1-2" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg active:bg-gray-500'></a>
                                                <a href="#item1-3" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg active:bg-gray-500'></a>
                                                <a href="#item1-4" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg active:bg-gray-500'></a>
                                            </div>                                        
                                        </div> 


                                    </div>
                                </div>
                                <Link to={'/asiris-v2-get-started'} className='w-full justify-center flex flex-row pt-4'>
                                    <button className='flex justify-center w-2/3 hover:drop-shadow-2xl rounded-md text-white bg-[#f58217] hover:text-white py-1 font-medium  text-xl'>
                                        <h1>Get Started</h1>                                        
                                    </button>
                                </Link>                                 
                            </Modal>
                        </div>

                        <div id="item6" className='carousel-item w-4/6 pl-2'>
                            <div className='text-[#16341fec] bg-[#ffffff] w-10/12 drop-shadow-xl flex flex-col justify-between' onClick={onModalVehicleOwnershipClick}>
                                <div>
                                    <img className='' src='https://empire-s3-production.bobvila.com/articles/wp-content/uploads/2022/01/diy-car-maintenance.jpg' alt="" />
                                </div>

                                <div className='px-2 mb-2'>
                                    <div className=' pt-2 '>
                                        <h1 className='text-start text-xl font-medium'>Vehicle Tools</h1>
                                    </div>
                                    <div className='py-1 my-1 mt-2 flex flex-col w-fit text-[#1763db] hover:bg-[#e2edff]' onClick={onModalVehicleOwnershipClick}>
                                        <h1 className='text-sm font-light px-1'>LEARN MORE</h1>
                                    </div>
                                </div>
                            </div>
                            <Modal className={'my-5 mx-5 pb-5 px-3 py-2 drop-shadow-2xl bg-white rounded-xl '} isOpen={modalVehicleOwnershipIsOpen}>
                                <div className='relative'>
                                    <div className=' absolute top-0 right-0' onClick={onModalVehicleOwnershipClick}>
                                        <h1 className=' bg-[#1763db] text-xl rounded-xl text-white font-normal px-2'> X </h1>
                                    </div>

                                    <div className='pt-5 flex flex-col '>
                                        <div className=' flex flex-col justify-center'>
                                            <h1 className='text-2xl font-semibold'>Vehicle Tracker</h1>
                                            <h1 className='text-sm font-medium'>Maintain your vehicle records, make sure your vehicle is safe for your family</h1>
                                        </div>

                                        <div className='py-3' style={{animation: animations.fadeInUp}}>
                                            <div className='carousel py-2 pb-4 ml-5'>

                                                <div id="item1-1" className='carousel-item w-5/6 pl-2' >
                                                    <div className='text-[#16341fec] bg-[#ffffff] w-11/12 drop-shadow-xl rounded-lg    '>
                                                        <div className='flex justify-center'>
                                                            <img className='rounded-t-lg w-20 py-2' src='https://cdn2.iconfinder.com/data/icons/smart-business-3-line/128/business-record_business_record_storage_document-512.png' alt="" />
                                                        </div>

                                                        <div className='flex flex-col '>
                                                            <div className=' pt-2 '>
                                                                <h1 className='text-xl text-center font-bold px-2'>Records Store</h1>
                                                            </div>
                                                            <hr/>
                                                            <div className='self-center  pb-1 rounded-b-lg px-2'>
                                                                <h1 className='pt-2 text-sm font-light text-left' >Keep track of everything happeing to your vehicle, this will help you complete vehicle maintenance on time and save you high costs of inatentive ownership</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div id="item1-2" className='carousel-item w-5/6 pl-2' >
                                                    <div className='text-[#16341fec] bg-[#ffffff] w-11/12 drop-shadow-xl rounded-lg    '>
                                                        <div className='flex justify-center'>
                                                            <img className='rounded-t-lg w-20 py-2' src='https://cdn-icons-png.flaticon.com/512/4961/4961543.png' alt="" />
                                                        </div>

                                                        <div className='flex flex-col '>
                                                            <div className=' pt-2 '>
                                                                <h1 className='text-xl text-center font-bold'>Fair Pricing</h1>
                                                            </div>
                                                            <hr/>
                                                            <div className='self-center  pb-1 rounded-b-lg px-2'>
                                                                <h1 className='pt-2 text-sm font-light text-left' >Do not feel inadequate at the mechanic store and find market rates for the repairs your require</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div id="item1-3" className='carousel-item w-5/6 pl-2' >
                                                    <div className='text-[#16341fec] bg-[#ffffff] w-11/12 drop-shadow-xl rounded-lg    '>
                                                        <div className='flex justify-center'>
                                                            <img className='rounded-t-lg w-20 py-2' src='https://pictures.dealer.com/t/trophynissanvtg/0948/10bbe486b5885d86dc65f6471536c780x.jpg?impolicy=downsize&w=568' alt="" />
                                                        </div>

                                                        <div className='flex flex-col '>
                                                            <div className=' pt-2 '>
                                                                <h1 className='text-xl text-center font-bold'>Maintenance Tracker</h1>
                                                            </div>
                                                            <hr/>
                                                            <div className='self-center  pb-1 rounded-b-lg px-2'>
                                                                <h1 className='pt-2 text-sm font-light text-left'>Know what your vehicle needs before you do to make sure your car stays safe and serves well over a long time</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div id="item1-4" className='carousel-item w-5/6'>
                                                    <div className='text-[#16341fec] flex flex-col justify-center w-11/12 drop-shadow-xl rounded-lg bg-white   '>
                                                        <div className='flex justify-center'>
                                                            <div className='pb-3'>
                                                                <h1 className='text-3xl text-white bg-[#1763db] px-3 pb-1 rounded-full'>+</h1>
                                                            </div>
                                                        </div>

                                                        <div className='flex flex-col '>
                                                            <div className=' pt-2 '>
                                                                <h1 className='text-xl font-bold text-center'>More to Come</h1>
                                                                <h1 className='text-lg font-semibold' ></h1>
                                                            </div>
                                                            <hr/>
                                                            <div className='self-center  pb-1 rounded-b-lg px-1 pt-2 flex flex-col text-start'>
                                                                <h1 className=' text-sm font-light text-start' >- OBD2 Tracking</h1>
                                                                <h1 className=' text-sm font-light text-start' >- Car GEO Tracker</h1>
                                                                <h1 className=' text-sm font-light text-start' >- Purchasing Guide</h1>
                                                                <h1 className=' text-sm font-light text-start' >- Lease Guide</h1>                                                            
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> 

                                            </div>    

                                            <div className='flex justify-center w-full pb-1 gap-5 '>
                                                <a href="#item1-1" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg active:bg-gray-500'></a>
                                                <a href="#item1-2" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg active:bg-gray-500'></a>
                                                <a href="#item1-3" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg active:bg-gray-500'></a>
                                                <a href="#item1-4" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg active:bg-gray-500'></a>
                                            </div>                                        
                                        </div> 


                                    </div>
                                </div>
                                <Link to={'/asiris-v2-get-started'} className='w-full justify-center flex flex-row pt-4'>
                                    <button className='flex justify-center w-2/3 hover:drop-shadow-2xl rounded-md text-white bg-[#f58217] hover:text-white py-1 font-medium  text-xl'>
                                        <h1>Get Started</h1>                                        
                                    </button>
                                </Link>                                     
                            </Modal>                        
                        </div>

                        <div id="item7" className='carousel-item w-4/6 pl-2'>
                            <div className='text-[#16341fec] bg-[#ffffff] w-10/12 drop-shadow-xl flex flex-col justify-between' onClick={onModalHomeOwnershipClick}>
                                <div>
                                    <img className='' src='https://cdn.apartmenttherapy.info/image/upload/v1560354944/stock/shutterstock_1067659397.jpg' alt="" />
                                </div>

                                <div className='px-2 mb-2'>
                                    <div className=' pt-2 '>
                                        <h1 className='text-start text-xl font-medium'>Financial Literacy</h1>
                                    </div>
                                    <div className='py-1 my-1 mt-2 flex flex-col w-fit text-[#1763db] hover:bg-[#e2edff]' onClick={onModalPersonalFinanceClick}>
                                        <h1 className='text-sm font-light px-1'>LEARN MORE</h1>
                                    </div>
                                </div>
                            </div>
                            <Modal className={'my-5 mx-5 pb-5 px-3 py-2 drop-shadow-2xl bg-white rounded-xl '} isOpen={modalHomeOwnershipIsOpen}>
                                <div className='relative'>
                                    <div className=' absolute top-0 right-0' onClick={onModalHomeOwnershipClick}>
                                        <h1 className=' bg-[#1763db] text-xl rounded-xl text-white font-normal px-2'> X </h1>
                                    </div>

                                    <div className='pt-5 flex flex-col '>
                                        <div className=' flex flex-col justify-center'>
                                            <h1 className='text-2xl font-semibold'>Home Ownership</h1>
                                            <h1 className='text-sm font-medium'>Track your home health and find out how to extend the life of your home while saving money</h1>
                                        </div>

                                        <div className='py-3' style={{animation: animations.fadeInUp}}>
                                            <div className='carousel py-2 pb-4 ml-5'>

                                                <div id="item1-1" className='carousel-item w-5/6 pl-2' >
                                                    <div className='text-[#16341fec] bg-[#ffffff] w-11/12 drop-shadow-xl rounded-lg    '>
                                                        <div className='flex justify-center'>
                                                            <img className='rounded-t-lg w-20 py-2' src='https://png.pngtree.com/png-vector/20230302/ourmid/pngtree-dashboard-line-icon-vector-png-image_6626604.png' alt="" />
                                                        </div>

                                                        <div className='flex flex-col '>
                                                            <div className=' pt-2 '>
                                                                <h1 className='text-xl text-center font-bold px-2'>Home Dashboard</h1>
                                                            </div>
                                                            <hr/>
                                                            <div className='self-center  pb-1 rounded-b-lg px-2'>
                                                                <h1 className='pt-2 text-sm font-light text-left' >View your home systems all from a single dashboard to understand which areas need your attention</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div id="item1-2" className='carousel-item w-5/6 pl-2' >
                                                    <div className='text-[#16341fec] bg-[#ffffff] w-11/12 drop-shadow-xl rounded-lg    '>
                                                        <div className='flex justify-center'>
                                                            <img className='rounded-t-lg w-20 py-2' src='https://static.thenounproject.com/png/3764448-200.png' alt="" />
                                                        </div>

                                                        <div className='flex flex-col '>
                                                            <div className=' pt-2 '>
                                                                <h1 className='text-xl text-center font-bold'>HVAC System</h1>
                                                            </div>
                                                            <hr/>
                                                            <div className='self-center  pb-1 rounded-b-lg px-2'>
                                                                <h1 className='pt-2 text-sm font-light text-left' >Track your HVAC system status and learn how to imporve air quality and efficiency of yoru system</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div id="item1-3" className='carousel-item w-5/6 pl-2' >
                                                    <div className='text-[#16341fec] bg-[#ffffff] w-11/12 drop-shadow-xl rounded-lg    '>
                                                        <div className='flex justify-center'>
                                                            <img className='rounded-t-lg w-20 py-2' src='https://static.thenounproject.com/png/3039943-200.png' alt="" />
                                                        </div>

                                                        <div className='flex flex-col '>
                                                            <div className=' pt-2 '>
                                                                <h1 className='text-xl text-center font-bold'>Electical System</h1>
                                                            </div>
                                                            <hr/>
                                                            <div className='self-center  pb-1 rounded-b-lg px-2'>
                                                                <h1 className='pt-2 text-sm font-light text-left' >Track your Electrical system health to avoid any unfortunate accidents and keep your home safe and secure</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div id="item1-4" className='carousel-item w-5/6'>
                                                    <div className='text-[#16341fec] flex flex-col justify-center w-11/12 drop-shadow-xl rounded-lg bg-white   '>
                                                        <div className='flex justify-center'>
                                                            <div className='pb-3'>
                                                                <h1 className='text-3xl text-white bg-[#1763db] px-3 pb-1 rounded-full'>+</h1>
                                                            </div>
                                                        </div>

                                                        <div className='flex flex-col '>
                                                            <div className=' pt-2 '>
                                                                <h1 className='text-xl font-bold text-center'>More to Come</h1>
                                                                <h1 className='text-lg font-semibold' ></h1>
                                                            </div>
                                                            <hr/>
                                                            <div className='self-center  pb-1 rounded-b-lg px-1 pt-2 flex flex-col text-start'>
                                                                <h1 className=' text-sm font-light text-start' >- Plumbing System</h1>
                                                                <h1 className=' text-sm font-light text-start' >- Structural Integrity</h1>
                                                                <h1 className=' text-sm font-light text-start' >- Roof & Gutter Status</h1>
                                                                <h1 className=' text-sm font-light text-start' >- Appliance Tracker</h1>
                                                                <h1 className=' text-sm font-light text-start' >- Structural Integrity</h1>
                                                                <h1 className=' text-sm font-light text-start' >- Energy Efficiency Tracking</h1>                                                            
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> 

                                            </div>    

                                            <div className='flex justify-center w-full pb-1 gap-5 '>
                                                <a href="#item1-1" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg active:bg-gray-500'></a>
                                                <a href="#item1-2" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg active:bg-gray-500'></a>
                                                <a href="#item1-3" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg active:bg-gray-500'></a>
                                                <a href="#item1-4" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg active:bg-gray-500'></a>
                                            </div>                                        
                                        </div> 


                                    </div>
                                </div>
                                <Link to={'/asiris-v2-get-started'} className='w-full justify-center flex flex-row pt-4'>
                                    <button className='flex justify-center w-2/3 hover:drop-shadow-2xl rounded-md text-white bg-[#f58217] hover:text-white py-1 font-medium  text-xl'>
                                        <h1>Get Started</h1>                                        
                                    </button>
                                </Link>                                
                            </Modal>
                        </div>                                    

                        <div id="item8" className='carousel-item w-1/2'>
                            <div className='text-[#16341fec] flex flex-col justify-center w-fit  rounded-t-lg bg-white   '>
                                <div className='flex justify-center'>
                                    <div className='pb-3'>
                                        <h1 className='text-6xl text-white bg-[#1763db] px-3 pb-1 rounded-full'>+</h1>
                                    </div>
                                </div>

                                <div className=''>
                                    <div className=' pt-2 '>
                                        <h1 className='text-center text-xl font-medium'>More to Come</h1>
                                    </div>
                                </div>
                            </div>
                        </div>                        

                    </div>

                    <div className='flex justify-center w-full pb-1 gap-3 '>
                        <a href="#item1" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg'></a>
                        <a href="#item2" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg'></a>
                        <a href="#item3" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg'></a>
                        <a href="#item4" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg'></a>
                        <a href="#item5" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg'></a>
                        <a href="#item6" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg'></a>
                        <a href="#item7" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg'></a>
                        <a href="#item8" className='bg-[#1763db] text-lg w-3 h-3 rounded-lg'></a>
                    </div>
                </div>


                {/********************************************/}
                {/********************* CLASSES CARDS - END ***********************/}
                {/********************************************/}



                <div className='flex flex-col justify-center items-center drop-shadow-md shadow-[2px_1px_10px_3px_rgba(201,201,201,0.97)] mt-4' style={{animation: animations.fadeInUp}}>    
                    <div className=' flex flex-row justify-around w-full'>                 
                        <div className='w-full'>
                            <Link to={'/asiris-v2-explore-classes'} className=''>
                                <button className=' drop-shadow-xl w-full hover:drop-shadow-2xl border-y-2  border-[#1763db]  text-black py-2 font-normal bg  text-xl hover:bg-[#1724db] hover:text-white'>
                                    Explore Classes
                                </button>
                            </Link>
                        </div>
                    </div>                        
                </div>

            </div>

            {/********************************************/}
            {/********************* FIRST PAGE MOBILE - END ***********************/}
            {/********************************************/}


            {/* <div className='h-full pt-10 flex flex-col  w-full'>


                <div className=' bg-[#1763db]  flex flex-row py-3 justify-center align-middle   drop-shadow-xl  ' >

                    <div className='text-xl text-white font-semibold px-4 py-2'>
                        <h1>Take your first step</h1>
                    </div>

                    <Link to={'/asiris-v2-get-started'}>
                        <div className=' flex self-align-bottom'>
                            <button className=' rounded-md drop-shadow-xl hover:drop-shadow-2xl bg-[#FFC500]  py-2 font-normal text-balck px-2 text-xl hover:bg-[#2f6942] '>
                                Get Started!
                            </button>
                        </div>
                    </Link>

                </div>

            </div> */}


            {/********************************************/}
            {/********************* SECOND PAGE MOBILE - END ***********************/}
            {/********************************************/}


            {/********************************************/}
            {/********************* THIRD PAGE MOBILE - END ***********************/}
            {/********************************************/}


        </div>

    </div>

    {/********************************************/}
    {/********************************************/}
    {/************************************************************************************ MOBILE VERSION END *****************************************************************/}
    {/************************************************************************************ MOBILE VERSION END *****************************************************************/}
    {/************************************************************************************ MOBILE VERSION END *****************************************************************/}
    {/************************************************************************************ MOBILE VERSION END *****************************************************************/}
    {/********************************************/}
    {/********************************************/}









    {/********************************************/}
    {/********************************************/}
    {/************************************************************************************ DESKTOP VERSION START *****************************************************************/}
    {/************************************************************************************ DESKTOP VERSION START *****************************************************************/}
    {/************************************************************************************ DESKTOP VERSION START *****************************************************************/}
    {/************************************************************************************ DESKTOP VERSION START *****************************************************************/}
    {/********************************************/}
    {/********************************************/}

    <div className='lg:visible invisible max-sm:hidden items-center justify-center ' >

        {/********************************************/}
        {/********************* FIRST PAGE DESKTOP - START ***********************/}
        {/********************************************/}
    <div className='' >

        <div className='w-screen h-screen flex flex-col max-sm:justify-start justify-start bg-[#fff9e1]  '>

            {/* ----------------------- NAVIGATION BAR  ----------------------- */}

            <div className='flex justify-around py-3 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.67)] bg-[#16341fec]'>    
                <Link to="/">
                    <div className='flex justify-center align-middle'>
                        <h1 className='max-sm:text-2xl max-sm:font-light text-4xl font-thin text-white align-middle' >AsirisOS</h1>
                        <h1 className='max-sm:pt-1 max-sm:text-xl max-sm:font-light text-2xl font-thin text-slate-600 pt-1 pl-3' ></h1>
                    </div>
                </Link>
                
                <div className='flex gap-2'>

                    <Link className=" text-slate-700 hover:bg-[#FFB100] hover:shadow-lg rounded-sm px-4 py-1 bg-[#FFC500] flex justify-center  " to={"/join-the-waitlist"} >
                        <h1 className=' text-lg font-normal justify-center align-middle'>Sign up</h1>                        
                    </Link>

                    <Link className=" text-white hover:text-slate-700 hover:bg-[#FFB100] hover:shadow-lg rounded-sm px-4 py-1 border-2 border-[#FFC500] hover:border-[#FFB100] flex justify-center " to={"/login"} >
                        <h1 className=' text-lg font-normal justify-center align-middle'>Login</h1>
                    </Link>

                </div>

            </div>

            {/* ----------------------- NAVIGATION BAR END  ----------------------- */}

            <div className='  h-screen pb-6 max-sm:pb-10 flex flex-col justify-evenly items-center bg-gradient-to-b from-[#f9edbc] to-[#ffffff]'>
                <div className='w-full flex flex-row justify-center gap-5' >

                    <div className='px-3'>
                        <div className='items-start pb-4'>
                            <h1 className=' text-left font-semibold text-4xl pt-1 text-slate-700 max-w-xl'>
                                We make it easy to shop local and healthy foods
                            </h1>
                        </div>
                    
                        <div className='flex flex-col items-start pb-5'>
                            <h1 className='text-left font-light text-xl pt-2 max-w-2xl text-slate-600'>We create software for local food producers and consumers to build independent and healthy food systems for all.</h1>
                        </div>

                        <div className='pb-2'>
                            <h1 className='text-left font-semibold text-md pt-2 max-w-2xl text-slate-700'>Join 1,000+ local shoppers</h1>
                        </div>

                        <Link to={'/search-by-zip'}>
                            <div className=' flex self-align-bottom'>
                                <button className=' rounded-md drop-shadow-xl hover:drop-shadow-2xl bg-[#FFC500]  py-3 font-normal text-slate-700 px-4 text-xl hover:bg-[#FFB100] hover:text-slate-700'>
                                    Get Started!
                                </button>
                            </div>
                        </Link>
                    </div>

                    <div className='flex px-3'  >
                        <img className='rounded-sm right-0 max-w-lg drop-shadow-2xl' src={vegetable_market} alt="" />
                    </div>

                </div>


                {/********************************************/}
                {/********************* LOGO SECTION ***********************/}
                {/********************************************/}
                <div className='w-full flex justify-around align-middle'>
                    <div>
                        <a className='text-gray-400 font-bold text-4xl drop-shadow-sm '>USDA - Organic</a>
                    </div>

                    <div>
                        <a className='text-gray-400 font-bold text-4xl drop-shadow-sm '>Non-GMO</a>
                    </div>

                    <div>
                        <a className='text-gray-400 font-bold text-4xl drop-shadow-sm '>Pesticide-Free</a>
                    </div>
                </div>

            </div>

        </div>

        {/********************************************/}
        {/********************* FIRST PAGE DESKTOP - END ***********************/}
        {/********************************************/}


        {/********************************************/}
        {/********************* SECOND PAGE DESKTOP - START ***********************/}
        {/********************************************/}


        <div className=' bg-[#ffffff] max-sm:pb-10 flex flex-col ' >

            <div className='w-full h-full flex flex-col items-center justify-around pb-16 '>

                <div className='items-center pt-8'>
                    <h1 className=' text-center font-bold text-3xl text-slate-700 max-w-2xl'>
                        Discover 12,228 local organizations
                    </h1>
                </div>

                {/* *********************** TAB SELECTIONS *********************** */}

                <div className='mb-5  border-t-0 w-fit rounded-xl drop-shadow-lg pt-10 '>

                    <ul className="flex felx-row justify-left cursor-pointer">
                        <div onClick={() => toggleTab(1)} className={`${selection === 1 ? 'bg-[#16341fec] text-[#fff9e1]  pb-7  w-40 rounded-l-lg' : 'bg-[#fff9e1] text-[#16341fec]  pb-7  w-40 rounded-l-lg'}`}>
                            <div className='pt-5' >
                                <h1 className='text-xl font-bold'>6989</h1>
                                <h1 className='text-md font-light' >Farmers Markets</h1>
                            </div>
                        </div>

                        <div onClick={() => toggleTab(2)} className={`${selection === 2 ? 'bg-[#16341fec] text-[#fff9e1]  pb-7  w-40' : 'bg-[#fff9e1] text-[#16341fec] pb-7  w-40 '}`}>
                            <div className='pt-5' >
                                <h1 className='text-xl font-bold'>224</h1>
                                <h1 className='text-md font-light' >Co-op Groceries</h1>
                            </div>
                        </div>

                        <div onClick={() => toggleTab(3)} className={`${selection === 3 ? 'bg-[#16341fec] text-[#fff9e1]  pb-7  w-40' : 'bg-[#fff9e1] text-[#16341fec] pb-7  w-40 '}`}>
                            <div className='pt-5' >
                                <h1 className='text-xl font-bold'>1710</h1>
                                <h1 className='text-md font-light' >On-Farm Markets</h1>
                            </div>
                        </div>

                        <div onClick={() => toggleTab(4)} className={`${selection === 4 ? 'bg-[#16341fec] text-[#fff9e1]  pb-7  w-40' : 'bg-[#fff9e1] text-[#16341fec] pb-7  w-40 '}`}>
                            <div className='pt-5' >
                                <h1 className='text-xl font-bold'>994</h1>
                                <h1 className='text-md font-light' >CSAs</h1>
                            </div>
                        </div>

                        <div onClick={() => toggleTab(5)} className={`${selection === 5 ? 'bg-[#16341fec] text-[#fff9e1]  pb-7  w-40' : 'bg-[#fff9e1] text-[#16341fec] pb-7  w-40 '}`}>
                            <div className='pt-5' >
                                <h1 className='text-xl font-bold'>2141</h1>
                                <h1 className='text-md font-light' >CPG Brands</h1>
                            </div>
                        </div>

                        <div onClick={() => toggleTab(6)} className={`${selection === 6 ? 'bg-[#16341fec] text-[#fff9e1]  pb-7 rounded-r-md   w-40' : 'bg-[#fff9e1] text-[#16341fec] pb-7 rounded-r-md   w-40 '}`}>
                            <div className='pt-5' >
                                <h1 className='text-xl font-bold'>230</h1>
                                <h1 className='text-md font-light' >Food Hubs</h1>
                            </div>
                        </div>

                    </ul>
                </div>


                {/* *********************** TAB SELECTIONS *********************** */}

                {selection === 1 ? (
                <>
                <div className=' py-3 ' >
                    <h1 className='text-2xl font-normal' >Farmers Markets</h1>
                    <div className=''>
                        <h1 className='pt-2 text-lg font-light text-center max-w-2xl' >Discover and connect with your local farmers markets. Follow them to stay up-to date with latest news and your favourite local vendors.</h1>
                    </div>
                </div>
                </>
                ) : (<></>)}



                {selection === 2 ? (
                <>
                <div className=' py-3' >
                    <h1 className='text-2xl font-normal' >Co-op Groceries</h1>
                    <h1 className='pt-2 text-lg font-light text-center max-w-2xl' >Cooperative groceries are owned and managed by your local community to deliver you the best quality produce while supporting local communities.</h1>
                </div>
                </>
                ) : (<></>)}


                {selection === 3 ? (
                <>
                <div className=' py-3' >
                    <h1 className='text-2xl font-normal' >On-Farm Markets</h1>
                    <h1 className='pt-2 text-lg font-light text-center max-w-2xl'>Buy your food right where it grows. Visit your local farms that work hard to grow healthy and nutritious foods for you.</h1>
                </div>
                </>
                ) : (<></>)}



                {selection === 4 ? (
                <>
                <div className=' py-3' >
                    <h1 className='text-2xl font-normal' >CSA</h1>
                    <h1 className='pt-2 text-lg font-light text-center max-w-2xl' >CSAs allow you to purchase a share of farm's harvest to ensure that you get as much locally grown and nutritious food as you need.</h1>
                </div>
                </>
                ) : (<></>)}

                {selection === 5 ? (
                <>
                <div className=' py-3' >
                    <h1 className='text-2xl font-normal' >CPG Brands</h1>
                    <h1 className='pt-2 text-lg font-light text-center max-w-2xl' >Discover local brands that make unique consumer products like healthy snacks and natural cosmetics while supporting local economy</h1>
                </div>
                </>
                ) : (<></>)}

                {selection === 6 ? (
                <>
                <div className=' py-3' >
                    <h1 className='text-2xl font-normal' >Food Hubs</h1>
                    <h1 className='pt-2 text-lg font-light text-center max-w-2xl' >Food Hubs help local farms and food producers market and deliver their products to local consumers, making it easier for you to shop locally</h1>
                </div>
                </>
                ) : (<></>)}

            </div>

            <div className='flex flex-col  items-center bg-gradient-to-t from-[#fff3c4] to-[#ffffff] '   >

                <div className=' justify-self-center pt-6 pb-4'>
                    <h1 className=' text-center font-bold text-3xl text-slate-700 max-w-2xl'>
                        <a className='text-[#16341fec] font-bold '></a>Start in 3 simple steps
                    </h1>
                </div>

                <div className='flex flex-row justify-evenly  gap-3 py-7 pb-12'>

                    <div className='text-[#16341fec] bg-[#ffffff] w-1/6 drop-shadow-2xl rounded-lg pb-2   '>
                        <div>
                            <img className='rounded-t-lg' src={vegetables_in_basket} alt="" />
                        </div>
                        <div className='px-2 pt-3 flex flex-col '>
                            <h1 className='text-xl font-light pb-1'>Discover</h1>
                            <hr/>
                            <h1 className='text-sm font-bold pt-1 '>Find your local food producers</h1>
                        </div>
                    </div>

                    <div className='text-[#16341fec] bg-[#ffffff] w-1/6 drop-shadow-2xl rounded-lg pb-2 ' >
                        <div>
                            <img className='rounded-t-lg' src={vegetables_in_basket} alt="" />
                        </div>
                        <div className='px-2 pt-3 flex flex-col '>
                            <h1 className='text-xl font-light pb-1'>Connect</h1>
                            <hr/>
                            <h1 className='text-sm font-bold pt-1'>Follow your local food organizations</h1>
                        </div>
                    </div>

                    <div className='text-[#16341fec] bg-[#ffffff] w-1/6 drop-shadow-2xl rounded-lg pb-2 ' >
                        <div>
                            <img className='rounded-t-lg' src={vegetables_in_basket} alt="" />
                        </div>
                        <div className='px-2 pt-3 flex flex-col '>
                            <h1 className='text-xl font-light pb-1'>Shop</h1>
                            <hr/>
                            <h1 className='text-sm font-bold pt-1'>Start eating local and healthy food</h1>
                        </div>
                    </div>

                </div>

                <div className=' bg-[#FFC500] w-1/2  flex flex-row py-3 justify-center align-middle mt-3 mb-8 rounded-xl drop-shadow-xl  ' >

                    <div className='text-2xl text-slate-800 font-semibold px-8 py-1'>
                        <h1>Take your first step</h1>
                    </div>

                    <Link to={'/search-by-zip'}>
                        <div className=' flex self-align-bottom'>
                            <button className=' rounded-md drop-shadow-xl hover:drop-shadow-2xl bg-[#37774bec]  py-2 font-normal text-slate-50 px-4 text-xl hover:bg-[#2f6942] '>
                                Get Started!
                            </button>
                        </div>
                    </Link>

                </div>

            </div>
        </div>




        <div className='  bg-[#37774bec] text-[#fff9e1] flex flex-row py-4 justify-around align-middle  ' >
            <div>
                <Link to={"/"}>
                    <h1 className='text-xl py-1'>AsirisOS</h1>
                </Link>
            </div>

            <div className='flex gap-3 justify-start py-2'>
                <Link to={"/contact"}>
                    <h1 className='text-sm'> Contact</h1>            
                </Link>

                <Link to={"/organizations-join"}>
                    <h1 className='text-sm'>Organizations</h1>            
                </Link>

                <Link to={"/join-the-team"}>
                    <h1 className='text-sm'>Team</h1>            
                </Link>
            </div>

        </div>

        {/********************************************/}
        {/********************* SECOND PAGE DESKTOP - END ***********************/}
        {/********************************************/}
    </div>
    </div>
    {/********************************************/}
    {/********************************************/}
    {/************************************************************************************ DESKTOP VERSION END *****************************************************************/}
    {/************************************************************************************ DESKTOP VERSION END *****************************************************************/}
    {/************************************************************************************ DESKTOP VERSION END *****************************************************************/}
    {/************************************************************************************ DESKTOP VERSION END *****************************************************************/}
    {/********************************************/}
    {/********************************************/}
    </>
  )
}

export default ExploreTools