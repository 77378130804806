import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import deviceService from "./deviceService";
import {DeviceState} from "../../types/states";
import HandleAxiosError from "../../utils/AxiosErrorHandler";
import {DefaultThunkApiConfig, Status} from "../../types/slice";
import {DeviceModel} from "@asirisos/types";

const CONTEXT = "Devices"

const initialState: DeviceState = {
    device: undefined,
    devices: [],
    message: '', 
    status: Status.Unknown
}

export const addDevice = createAsyncThunk<any, DeviceModel, DefaultThunkApiConfig>('Device/add', async (deviceData, thunkAPI) => {
    try {
        // const account = thunkAPI.getState().account.account;
        // if (!account)
        //     return thunkAPI.rejectWithValue();

        const response = await deviceService.addDevice(deviceData)
        if (response.status !== 201)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getAllDevices = createAsyncThunk<DeviceModel[], void, DefaultThunkApiConfig>('Device/getAllDevices', async (_, thunkAPI) => {
    try {
        // const account = thunkAPI.getState().account.account
        // if (!account)
        //     return thunkAPI.rejectWithValue();

        const response = await deviceService.getDevices()
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getDevice = createAsyncThunk<DeviceModel, string, DefaultThunkApiConfig>('Device/getDevice/:DeviceId', async (deviceId: string, thunkAPI) => {
    try {
        const response = await deviceService.getDevice(deviceId)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const deleteDevice = createAsyncThunk<any, string, DefaultThunkApiConfig>('Device/deleteDevice/:DeviceId', async (deviceId: string, thunkAPI) => {
    try {
        const response = await deviceService.deleteDevice(deviceId)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})



export const deviceSlice = createSlice({
    name: 'device',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(addDevice.pending, (state: DeviceState) => {
                state.status = Status.Pending
            })
            .addCase(addDevice.fulfilled, (state: DeviceState) => {
                state.status = Status.Success
            })
            .addCase(addDevice.rejected, (state: DeviceState) => {
                state.status = Status.Failure
            })
            .addCase(getAllDevices.pending, (state: DeviceState) => {
                state.status = Status.Pending;
            })
            .addCase(getAllDevices.fulfilled, (state: DeviceState, action: PayloadAction<DeviceModel[]>) => {
                state.status = Status.Success
                state.devices = action.payload
            })
            .addCase(getAllDevices.rejected, (state: DeviceState) => {
                state.status = Status.Failure
            })
            .addCase(getDevice.pending, (state: DeviceState) => {
                state.status = Status.Pending;
            })
            .addCase(getDevice.fulfilled, (state: DeviceState, action: PayloadAction<DeviceModel>) => {
                state.status = Status.Success
                state.device = action.payload
            })
            .addCase(getDevice.rejected, (state: DeviceState) => {
                state.status = Status.Failure
            })
            .addCase(deleteDevice.fulfilled, (state: DeviceState) => {
                state.status = Status.Success
                state.device = undefined
            })
    }
})

export const {reset} = deviceSlice.actions
export default deviceSlice.reducer