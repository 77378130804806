import { title } from 'process'
import React, {ChangeEventHandler, FocusEventHandler, MouseEventHandler, KeyboardEventHandler} from 'react'

type CheckBoxButtonSelectionProps = {
  value? : string,
  name? : string,
  id? : string,
  title? : string,
  label? : string,
  onChange? : ChangeEventHandler<any>,
  onKeyDown? : KeyboardEventHandler<any>,
  onBlur? : FocusEventHandler<any>,
  onClick? : MouseEventHandler<any>,
 }

function CheckBoxButtonSelection({value, name, id, title, label, onChange, onKeyDown, onClick, onBlur} : CheckBoxButtonSelectionProps) {
    return(
        <>
            <div className={` py-2 px-5 my-2 mx-4 rounded-lg shadow-xl ${value === 'false' ? 'bg-indigo-400': 'bg-indigo-500'} `} >
                <div className="flex flex-row justify-center ">
                    <input
                        className={`form-check-input appearance-none h-7 w-7 drop-shadow-md border border-gray-300 rounded-md bg-white checked:bg-blue-500 checked:border-blue-600 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer`}
                        type="checkbox" 
                        value={value} 
                        name={name}
                        id={id} 
                        onChange={onChange}/>
                    <label
                        className="form-check-label inline-block self-center text-white font-regular pl-2 text-xl">
                        {title}
                    </label>
                </div>
            </div>        
        </>
    )
}

export default CheckBoxButtonSelection
