import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import storageService from "./storageService";
import {FixMeLater} from "../../types/dev";
import {StorageState} from "../../types/states";
import HandleAxiosError from "../../utils/AxiosErrorHandler";
import {DefaultThunkApiConfig, Status} from "../../types/slice";
import {StorageModel} from "@asirisos/types";

const CONTEXT = "Storage"

const initialState: StorageState = {
    storage: undefined,
    allStorage: [],
    status: Status.Unknown,
    message: ''
}

export const addStorage = createAsyncThunk<any, StorageModel, DefaultThunkApiConfig>('storage/add', async (storageData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await storageService.addStorage(storageData, account)
        if (response.status !== 201)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getAllStorage = createAsyncThunk<StorageModel[], void, DefaultThunkApiConfig>('Storage/getall', async (_, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await storageService.getAllStorage(account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getStorage = createAsyncThunk<StorageModel, string, DefaultThunkApiConfig>('storage/getone/:storageId', async (storageId, thunkAPI) => {
    try {
        const response = await storageService.getStorage(storageId)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const updateStorage = createAsyncThunk<StorageModel, StorageModel, DefaultThunkApiConfig>('storage/updateStorage/:storageId', async (storageData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if(!account)
            return thunkAPI.rejectWithValue();
            
        const response = await storageService.updateStorage(storageData, account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
    // try {
    //     const token = thunkAPI.getState().auth.user.token
    //     return await storageService.updateStorage(storageData, token)
    // } catch (error) {
    //     HandleAxiosError(error, CONTEXT);
    //     return thunkAPI.rejectWithValue("message")
    // }
})

export const deleteStorage = createAsyncThunk<any, string[], DefaultThunkApiConfig>('storage/delete/:storageId', async (storageId, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if(!account)
            return thunkAPI.rejectWithValue();

            console.log('Test account in delete Storage', account, storageId)
        const response = await storageService.deleteStorage(storageId, account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const storageSlice = createSlice({
    name: 'storage',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(addStorage.pending, (state: StorageState) => {
                state.status = Status.Pending
            })
            .addCase(addStorage.fulfilled, (state: StorageState) => {
                state.status = Status.Success
            })
            .addCase(addStorage.rejected, (state: StorageState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            .addCase(getAllStorage.pending, (state: StorageState) => {
                state.status = Status.Pending
            })
            .addCase(getAllStorage.fulfilled, (state: StorageState, action: PayloadAction<StorageModel[], any, any>) => {
                state.status = Status.Success
                state.allStorage = action.payload
            })
            .addCase(getAllStorage.rejected, (state: StorageState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            .addCase(getStorage.pending, (state: StorageState) => {
                state.status = Status.Pending
            })
            .addCase(getStorage.fulfilled, (state: StorageState, action: PayloadAction<StorageModel, any, any>) => {
                state.status = Status.Success
                state.storage = action.payload
            })
            .addCase(getStorage.rejected, (state: StorageState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            .addCase(updateStorage.pending, (state: StorageState) => {
                state.status = Status.Pending
            })
            .addCase(updateStorage.fulfilled, (state: StorageState, action: PayloadAction<StorageModel, any, any>) => {
                state.status = Status.Success
                state.allStorage.map((storage: StorageModel) => storage._id === action.payload._id ?
                    (state.storage = storage) : storage)
                state.storage = action.payload
            })
            .addCase(updateStorage.rejected, (state: StorageState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            // .addCase(updateStorage.fulfilled, (state: StorageState, action: FixMeLater) => {
            //     state.isLoading = false
            //     state.allStorage.map((storage) => storage._id === action.payload._id ? (state.storage = storage) : storage)
            // })
            .addCase(deleteStorage.pending, (state: StorageState) => {
                state.status = Status.Pending
            })
            .addCase(deleteStorage.fulfilled, (state: StorageState) => {
                state.status = Status.Success
                state.storage = undefined
            })
            .addCase(deleteStorage.rejected, (state: StorageState) => {
                state.status = Status.Failure
            })
    }
})

export const {reset} = storageSlice.actions
export default storageSlice.reducer