import React, {ChangeEvent, useEffect} from 'react'
import { Link } from 'react-router-dom'
import {Status} from "../../../../types/slice";
import SearchBox from '../../Map/SearchBox-1'
import {useTabPersistence} from "../../../../hooks/useTabPersistence";
import {useTranslation} from "react-i18next";
import {PageId} from "../../../../types/pages";
import { useState } from 'react'
import { addUserV2 } from 'features/userV2/userV2Slice';
import Spinner from '../../../components/Spinner'
import { countUserV2 } from 'features/userV2/userV2Slice';
import {
    useWindowSize,
    useWindowWidth,
    useWindowHeight,
  } from '@react-hook/window-size/throttled'
import Confetti from 'react-confetti'
// @ts-ignore
import {animations} from 'react-animation';
import NumberInputInUnits from 'UI/components/shared/NumberInputInUnits';
import EmailInput from 'UI/components/shared/EmailInput';
import {toast} from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import {AppDispatch, RootState} from "../../../../app/store";
import {useNavigate} from 'react-router-dom'
import axios from 'axios';
import { error } from 'console';
import { createCheckoutSession } from 'features/stripe/stripeSlice';


type PurchaseItemProps = {
    purchaseItem: string,
    price: string
}

function PayButton({purchaseItem, price}: PurchaseItemProps) {

    const [width, height] = useWindowSize()

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()

    const {t} = useTranslation(["landing", "translation"])

    const {userV2, status} = useSelector((state: RootState) => state.userV2)

    // const url = 'http://localhost:5000/api'
    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        dispatch(createCheckoutSession(userV2))
        .unwrap()
        .then(() => {
            toast.success(t("success"));
        })
        .catch(() => {
            toast.error(t("errors:generalError"))
        })

        // axios.post(`${url}/stripe/create-checkout-session`, {purchaseItem, price}).then(
        //     (res) => {
        //         if(res.data.url){
        //             window.location.href = res.data.url
        //         }
        //     }
        // ).catch( (err) => {
        //     console.log(err.message)
        // } )
        }


  return (
    <>

    {/***************************************************************************************************************************************************************************/}
    {/************************************************************************************ MOBILE VERSION START *****************************************************************/}
    {/************************************************************************************ MOBILE VERSION START *****************************************************************/}
    {/************************************************************************************ MOBILE VERSION START *****************************************************************/}
    {/************************************************************************************ MOBILE VERSION START *****************************************************************/}
    {/***************************************************************************************************************************************************************************/}

    <div className='lg:hidden sm:visible' >

        <div className='flex flex-col'>

            {/********************************************/}
            {/********************* FIRST PAGE MOBILE - START ***********************/}
            {/********************************************/}                                                

            <div className='flex flex-col justify-center '>

                {/********************************************/}
                {/********************* FIRST PAGE MOBILE - START ***********************/}
                {/********************************************/}


                    <div className='' style={{animation: animations.fadeInUp}}>
                    </div>
                    <form onSubmit={onSubmit} className=' px-5'>  
                        <div  className=' mt-3 mb-6'>
                            <button style={{animation: animations.fadeInUp}} className=' flex justify-center drop-shadow-md shadow-[2px_1px_20px_3px_rgba(201,201,201,0.97)] w-full hover:drop-shadow-2xl bg-[#1763db]  py-3 font-normal text-white text-xl hover:bg-[#1741db] hover:text-white'>
                                <h1>Purchase</h1>
                                <img className='w-6 pt-1 ml-2' src="https://media3.giphy.com/media/EtLdAZH3I56suXvQO9/giphy.gif?cid=6c09b95209o4gml4nwi7za7epihn9hf3qtkwiepr1plrd81e&ep=v1_stickers_related&rid=giphy.gif&ct=s" alt=" " />
                            </button>
                        </div> 
                    </form>

            </div>


        </div>

    </div>

    {/********************************************/}
    {/********************************************/}
    {/************************************************************************************ MOBILE VERSION END *****************************************************************/}
    {/************************************************************************************ MOBILE VERSION END *****************************************************************/}
    {/************************************************************************************ MOBILE VERSION END *****************************************************************/}
    {/************************************************************************************ MOBILE VERSION END *****************************************************************/}
    {/********************************************/}
    {/********************************************/}
    </>
  )
  }
export default PayButton