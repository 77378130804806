import React, {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import Spinner from '../../components/Spinner'
import {toast} from 'react-toastify'
import { getTasks, reset } from '../../../features/task/taskSlice'
import {AnyAction, ThunkDispatch} from "@reduxjs/toolkit";
import {UserState} from "../../../types/states";
import {RootState} from "../../../app/store";

function NewProfile() {

    const {user, isError, isSuccess} = useSelector((state : RootState) => state.auth)
    // const {tasks, isLoading} = useSelector((state : RootState) => state.taskTask )

    const dispatch : ThunkDispatch<UserState, any, AnyAction> = useDispatch()


    //This section breakes for newly registered person bc they are not authorized to access the task route
    //Need to add section to only run this if the user has an employee and farm ids attached
    // useEffect(() => {
    //     dispatch(getTasks())
    //     if (isSuccess) {
          
    //       dispatch(reset())
    //     }
    // },[dispatch] )

//   let taskOwners
//   taskOwners = tasks?.map(task => {
//     return task.taskOwner
//   })
//   console.log('List of Task Owners', taskOwners)


//   let FindTasks = tasks?.filter(task => task.employee[0] === user._id)
//   console.log("Find Tasks", FindTasks)

//   let userTasks 
//   userTasks = tasks?.map(userTask => {
//     if (userTask.taskOwner === "Osman") {
//       return userTask}
// })
// console.log('getting specific user tasks', userTasks)

// let userTaskFind
// userTaskFind = tasks?.find(task => {
//   return task.taskOwner === "Osman"})
// console.log("Direct find function for users Tasks", userTaskFind, typeof(userTaskFind))



    
// if() {
//   return <Spinner/>
// }
  return (
    <div className="#bg-indigo-300 py-5" >
      <div className="bg-white py-3 rounded-lg #drop-shadow-xl #shadow-lg relative mx-9 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]  " >
        <div className="grid grid-cols-3 gap-3">
          <div className='my-1 py-1 col-span-1 justify-self-center self-center'>
            <img className="h-36 w-34 bg-indigo-400 shadow-xl  rounded-xl" src="https://cdn-icons-png.flaticon.com/512/843/843349.png" alt=""/>
          </div>
          <div className="col-span-2 px-1 mx-1 py-2 rounded-md text-left">
            <div className='flex flex-row'>
              <h1 className="text-2xl my-0 #bg-indigo-500 font-thin" >{`${user?.name}`}</h1>
              <h1 className="text-md pt-1 ml-2 text-gray-500 #bg-indigo-400 font-normla" >{`${user?.roles}`}</h1>
            </div>
          <div className="stats drop-shadow-xl bg-slate-100">
  
            <div className="stat w-32 justify-items-center ">            
                <div className="stat-title">Open</div>
                <div className="stat-value text-green-500">10</div>
                {/* <div className="stat-title ">↗︎ 400 (22%)</div> */}
            </div>
            
            <div className="stat w-32 justify-items-center ">
                <div className="stat-title ">Overdue</div>
                <div className="stat-value text-red-500">4</div>
                {/* <div className="stat-title ">↗︎ 600 (15%)</div> */}
            </div>
            
            <div className="stat w-32 justify-items-center">
                <div className="stat-title ">Completed</div>
                <div className="stat-value ">36</div>
                {/* <div className="stat-title ">↘︎ 90 (14%)</div> */}
            </div>
          </div>
        </div>
        </div>
      </div>

      


    </div>
  )
}

export default NewProfile