import {PageId} from "../types/pages";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../app/store";
import {utilsSlice} from "../features/utils/utilsSlice";

export function useTabPersistence(page: PageId) {
    const [selection, setSelection] = useState(1);
    const {selectedTab} = useSelector((state: RootState) => state.utils)
    const dispatch = useDispatch<AppDispatch>()

    const toggleTab = (index: number) => {
        dispatch(utilsSlice.actions.setSelectedTab({page: page, tabId: index}));
        setSelection(index);
    }

    useEffect(() => {
            if ((selectedTab.page == page || selectedTab.page == PageId.Unknown)
                && selection != selectedTab.tabId)
                setSelection(selectedTab.tabId)
        },
        [selection, selectedTab])

    return {selection, toggleTab}
}