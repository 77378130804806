import { TaskModel } from '@asirisos/types'
import {Link} from 'react-router-dom'
import React from 'react'

function TaskConnectionsDisplay(props: {task : TaskModel | undefined}) {
  return (
    <>
    {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}
    {/* ///////////////////////////////////    TASK CONNECTION DISPLAY ////////////////////////////////////////// */}
    {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}
    <div className="bg-white mb-10 mt-10 py-5 rounded-lg #drop-shadow-xl #shadow-lg relative px-9 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] mx-5 ">
        <div className='flex flex-col' >
        <h1>TASK CONNECTION</h1>

        {/* /////////////////// CONNECTION = CROP ///////////////// */}

            {props.task?.connectionCategory === 'crop' ? (
            <>
            <Link to={`/crop/${props.task.cropObject?._id}`} className="border-2 rounded-lg border-blue-500 px-2">
                <h1>Connection Crop</h1>
                <h1>{props.task.cropObject?.cropName}</h1>
                <h1>{props.task.cropObject?.developmentStage}</h1>
                <h1>{props.task.cropObject?.stageProgress}</h1>
                <h1>{props.task.cropObject?.waterNutrition}</h1>
                <h1>{props.task.cropObject?.nitrogenNutrition}</h1>
                <h1>{props.task.cropObject?.phosphorusNutrition}</h1>
                <h1>{props.task.cropObject?.potassiumNutrition}</h1>
            </Link>
            </>) : (<></>) }

        {/* /////////////////// CONNECTION = LIVE STOCK ///////////////// */}

            {props.task?.connectionCategory === 'live stock' ? (
            <>
            <Link to={`/liveStock/${props.task.liveStockObject?._id}`} className="border-2 rounded-lg border-blue-500 px-2">
                <h1>Connection Live Stock</h1>
                <h1>{props.task.liveStockObject?.name}</h1>
                <h1>{props.task.liveStockObject?.category}</h1>
                <h1>{props.task.liveStockObject?._id}</h1>
                <h1>{props.task.liveStockObject?.sex}</h1>
                <h1>{props.task.liveStockObject?.weight}</h1>
                <h1>{props.task.liveStockObject?.description}</h1>
            </Link>
            </>) : (<></>) }

        {/* /////////////////// CONNECTION = EMPLOYEE ///////////////// */}

            {props.task?.connectionCategory === 'employee' ? (
            <>
            <Link to={`/account/${props.task.employeeObject?._id}`} className="border-2 rounded-lg border-blue-500 px-2">
                <h1>Connection Employee</h1>
                <h1>{props.task.employeeObject?.name}</h1>
                <h1>{props.task.employeeObject?.job_title}</h1>
                <h1>{props.task.employeeObject?.qualifications}</h1>
                <h1>{props.task.employeeObject?.email}</h1>
                <h1>{props.task.employeeObject?.phone}</h1>
            </Link>
            </>) : (<></>) }

        {/* /////////////////// CONNECTION = EQUIPMENT ///////////////// */}

        {props.task?.connectionCategory === 'equipment' ? (
            <>
            <Link to={`/equipment/${props.task.equipmentObject?._id}`} className="border-2 rounded-lg border-blue-500 px-2">
                <h1>Connection Equipment</h1>
                <h1>{props.task.equipmentObject?.name}</h1>
                <h1>{props.task.equipmentObject?.type}</h1>
                <h1>{props.task.equipmentObject?.mileage}</h1>
            </Link>
            </>) : (<></>) }

        {/* /////////////////// CONNECTION = INVENTORY ///////////////// */}

        {props.task?.connectionCategory === 'inventory' ? (
            <>
            <Link to={`/inventory/${props.task.inventoryObject?._id}`} className="border-2 rounded-lg border-blue-500 px-2">
                <h1>Connection Inventory</h1>
                <h1>{props.task.inventoryObject?.name}</h1>
                <h1>{props.task.inventoryObject?.type}</h1>
                <h1>{props.task.inventoryObject?.quantity}</h1>
            </Link>
            </>) : (<></>) }

        {/* /////////////////// CONNECTION = YIELD ///////////////// */}

        {props.task?.connectionCategory === 'yield' ? (
            <>
            <Link to={`/yield/${props.task.yieldObject?._id}`} className="border-2 rounded-lg border-blue-500 px-2">
                <h1>Connection Yield</h1>
                <h1>{props.task.yieldObject?.title}</h1>
                {/* <h1>{props.task.yieldObject?.type}</h1>
                <h1>{props.task.yieldObject?.quantity}</h1> */}
            </Link>
            </>) : (<></>) }

        {/* /////////////////// CONNECTION = FIELD ///////////////// */}

        {props.task?.connectionCategory === 'field' ? (
            <>
            <Link to={`/field/${props.task.fieldObject?._id}`} className="border-2 rounded-lg border-blue-500 px-2">
                <h1>Connection Field</h1>
                <h1>{props.task.fieldObject?.fieldName}</h1>
                {/* <h1>{props.task.yieldObject?.type}</h1>
                <h1>{props.task.yieldObject?.quantity}</h1> */}
            </Link>
            </>) : (<></>) }

        {/* /////////////////// CONNECTION = INFRASTRUCTURE ///////////////// */}

        {props.task?.connectionCategory === 'infrastructure' ? (
            <>
            <Link to={`/infrastructure/${props.task.infrastructureObject?._id}`} className="border-2 rounded-lg border-blue-500 px-2">
                <h1>Connection Infrastructure</h1>
                <h1>{props.task.infrastructureObject?.infrastructureName}</h1>
                {/* <h1>{props.task.yieldObject?.type}</h1>
                <h1>{props.task.yieldObject?.quantity}</h1> */}
            </Link>
            </>) : (<></>) }
        
        {/* /////////////////// CONNECTION = STORAGE ///////////////// */}

        {props.task?.connectionCategory === 'storage' ? (
            <>
            <Link to={`/storage/${props.task.storageObject?._id}`} className="border-2 rounded-lg border-blue-500 px-2">
                <h1>Connection Storage</h1>
                <h1>{props.task.storageObject?.name}</h1>
                <h1>{props.task.storageObject?.category}</h1>
                <h1>{props.task.storageObject?.description}</h1>
                <h1>{props.task.storageObject?.quantity}</h1>
            </Link>
            </>) : (<></>) }

        {/* /////////////////// CONNECTION = CUSTOMER ///////////////// */}

        {props.task?.connectionCategory === 'customer' ? (
            <>
            <Link to={`/customer/${props.task.customerObject?._id}`} className="border-2 rounded-lg border-blue-500 px-2">
                <h1>Connection Customer</h1>
                <h1>{props.task.customerObject?.firstName}</h1>
                <h1>{props.task.customerObject?.lastName}</h1>
                <h1>{props.task.customerObject?.email}</h1>
            </Link>
            </>) : (<></>) }
        </div>

    </div>
    </>
  )
}

export default TaskConnectionsDisplay