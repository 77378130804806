import React, {ChangeEvent} from 'react'
import InlineEditCheckBox from 'UI/components/shared/InlineEditCheckBox'
import InlineEdit from 'UI/components/shared/InlineEdit'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import FormTwoInputs from 'UI/components/shared/FormTwoInputs'
import FormCard from 'UI/components/shared/FormCard'
import NumberInputInUnits from 'UI/components/shared/NumberInputInUnits'
import LargeTextInput from 'UI/components/shared/LargeTextInput'

function ContactFarm() {

    const {t} = useTranslation(["farmersmarket", "translation"])


    const [contactEntity, setContactEntity] = useState({
        business: 'false',
        localShopper: 'false',
        farmersMarket: 'false'
    })

    const {
        business,
        localShopper,
        farmersMarket
    } = contactEntity

    const [contactPurpose, setContactPurpose] = useState({
        feedback: 'false',
        purchaseAssistance: 'false',
        general: 'false'
    })

    const {
        feedback,
        purchaseAssistance,
        general
    } = contactPurpose


    const onContactEntityChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {  

        if (e.target.value === 'false') {

            setContactEntity((prevState) => ({
                ...prevState,
                [e.target.name]: 'true'
            }))
            // Blur(e)
        }
       
        if (e.target.value === 'true') {

            setContactEntity((prevState) => ({
                ...prevState,
                [e.target.name]: 'false'
            }))
            // Blur(e)
        }
        const target = e.target as HTMLInputElement
    }

    const onContactPurposeChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {  

        if (e.target.value === 'false') {

            setContactPurpose((prevState) => ({
                ...prevState,
                [e.target.name]: 'true'
            }))
            // Blur(e)
        }
       
        if (e.target.value === 'true') {

            setContactPurpose((prevState) => ({
                ...prevState,
                [e.target.name]: 'false'
            }))
            // Blur(e)
        }
        const target = e.target as HTMLInputElement
    }

    const [formData, setFormData] = useState({
        name: '',
        farmName: '',
        email: '',
        phone: '',
        products: '',
        additionalInfo: '',
        contactEntity: contactEntity,
        contactPurpose: contactPurpose
    })

    const {name, email, phone, additionalInfo} = formData


    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {

        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
    }


  return (
    <>
    <div>
        <h1>Contact Farm "FARM NAME" </h1>
        <div>
            <h1>Pre Application Checklist</h1>
            <h2>Make sure you complete all of the following before you apply</h2>
            <FormCard title='Contact Farm'>
            <form onSubmit={onSubmit}>
                {/* ----------------- DESCRIBE YOUORSELF -----------------  */}
                <div className='pb-3'>
                    <div className='text-start py-4'>
                        <h1 className='text-2xl font-normal'>What describes you best</h1>
                    </div>
                    {/* //////////////////////////////// BUSINESS /////////////////////////////////////// */}
                    <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                        <div>
                            <h1 className='font-regular text-xl text-start pl-8'>Business</h1>
                        </div>
                        <div>
                            <InlineEditCheckBox onChange={(e) => onContactEntityChange(e)} value={business} id={"business"} name={"business"} label={""} />
                        </div>
                    </div>
                    {/* //////////////////////////////// BUSINESS /////////////////////////////////////// */}

                    {/* //////////////////////////////// LOCAL SHOPPER  /////////////////////////////////////// */}
                    <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                        <div>
                            <h1 className='font-regular text-xl text-start pl-8'>Local Shopper</h1>
                        </div>
                        <div>
                            <InlineEditCheckBox onChange={(e) => onContactEntityChange(e)} value={localShopper} id={"localShopper"} name={"localShopper"} label={""} />
                        </div>
                    </div>
                    {/* //////////////////////////////// LOCAL SHOPPER /////////////////////////////////////// */}

                    {/* //////////////////////////////// FARMERS MARKET  /////////////////////////////////////// */}
                    <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                        <div>
                            <h1 className='font-regular text-xl text-start pl-8'>Farmers Market</h1>
                        </div>
                        <div>
                            <InlineEditCheckBox onChange={(e) => onContactEntityChange(e)} value={farmersMarket} id={"farmersMarket"} name={"farmersMarket"} label={""} />
                        </div>
                    </div>
                    {/* //////////////////////////////// FARMERS MARKET /////////////////////////////////////// */}
                </div>
                {/* ----------------- DESCRIBE YOUORSELF -----------------  */}


                {/* ----------------- PURPOSE OF YOUR MESSAGE -----------------  */}
                <div className='pt-6'>
                    <div className='text-start py-4'>
                        <h1 className='text-2xl font-normal'>Reason for your message</h1>
                    </div>
                    {/* //////////////////////////////// BUSINESS /////////////////////////////////////// */}
                    <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                        <div>
                            <h1 className='font-regular text-xl text-start pl-8'>Feedback</h1>
                        </div>
                        <div>
                            <InlineEditCheckBox onChange={(e) => onContactPurposeChange(e)} value={feedback} id={"feedback"} name={"feedback"} label={""} />
                        </div>
                    </div>
                    {/* //////////////////////////////// BUSINESS /////////////////////////////////////// */}

                    {/* //////////////////////////////// LOCAL SHOPPER  /////////////////////////////////////// */}
                    <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                        <div>
                            <h1 className='font-regular text-xl text-start pl-8'>Purchase Assistance</h1>
                        </div>
                        <div>
                            <InlineEditCheckBox onChange={(e) => onContactPurposeChange(e)} value={purchaseAssistance} id={"purchaseAssistance"} name={"purchaseAssistance"} label={""} />
                        </div>
                    </div>
                    {/* //////////////////////////////// LOCAL SHOPPER /////////////////////////////////////// */}

                    {/* //////////////////////////////// FARMERS MARKET  /////////////////////////////////////// */}
                    <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                        <div>
                            <h1 className='font-regular text-xl text-start pl-8'>General</h1>
                        </div>
                        <div>
                            <InlineEditCheckBox onChange={(e) => onContactPurposeChange(e)} value={general} id={"general"} name={"general"} label={""} />
                        </div>
                    </div>
                    {/* //////////////////////////////// FARMERS MARKET /////////////////////////////////////// */}
                </div>
                {/* ----------------- PURPOSE OF YOUR MESSAGE -----------------  */}





                {/* ----------------- APPLICATION FORM -----------------  */}
                <div>
                
                <div className='col-span-2 justify-start justify-self-start w-2/3 pt-4 '>

                    <div className='text-start py-4'>
                        <h1 className='text-2xl font-normal'>Complete the form below</h1>
                    </div>
                    <div className='py-2' ><NumberInputInUnits onChange={(e) => onChange(e)} value={name} id={"name"} type={'text'} group={"name"} label={"name"} name={"name"} dataTitle="Name"/></div>
                    <div className='py-2' ><NumberInputInUnits onChange={(e) => onChange(e)} value={email} id={"email"} type={'text'} group={"email"} label={"email"} name={"Email"} dataTitle="Email"/></div>
                    <div className='py-2' ><NumberInputInUnits onChange={(e) => onChange(e)} value={phone} id={"phone"} type={'text'} group={"phone"} label={"phone"} name={"phone"} dataTitle="Phone"/></div>
                    <div className='py-2' ><LargeTextInput onChange={(e) => onChange(e)} value={additionalInfo} id={"additionalInfo"} type={'text'} group={"additionalInfo"} label={"additionalInfo"} name={"additionalInfo"} dataTitle="Message"  /></div>
                </div> 
                </div>

                {/* ----------------- APPLICATION FORM -----------------  */}

                <div className='flex justify-center pt-8 p-2'>
                    <button className="btn btn-wide btn-primar hover:bg-indigo-700">Send Message</button>
                </div>


            </form>
            </FormCard>
            
        </div>
        
    </div>
    </>
  )
}

export default ContactFarm