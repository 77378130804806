import React, {ChangeEvent, useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useParams, Link, useNavigate} from 'react-router-dom'
import {deleteInventory, getInventory, updateInventory} from '../../../features/inventory/inventorySlice'
import {toast} from 'react-toastify'
import Spinner from '../../components/Spinner'
import InlineEdit from '../../components/shared/InlineEdit'
import InlineEditDropDown from '../../components/shared/InlineEditDropDown'
import {AppDispatch, RootState} from "../../../app/store";
import {useTranslation} from "react-i18next";
import { getActivityByConnectionId } from 'features/activity/activitySlice'
import {Status} from "../../../types/slice";
import {InventoryModel} from "@asirisos/types";
import ChangeActivityList from '../Activity/ChangeActivityList'
import DeleteModal from 'UI/components/DeleteModal'
import {InputSelection} from "../../../types/ui";
import Modal from 'react-modal'
import ItemPageDeleteButton from 'UI/components/ItemPageDeleteButton'

function InventoryPage() {
    
    const {t} = useTranslation("inventory")
    const navigate = useNavigate()
    const {inventoryId} = useParams()
    const dispatch = useDispatch<AppDispatch>()
    const {inventory, status} = useSelector((state: RootState) => state.inventory)
    const {allActivity} = useSelector((state: RootState) => state.activity)

    const categorySelection = [
        new InputSelection(t("inventory:categoryFeed"), 'feed'),
        new InputSelection(t("inventory:categoryChemicals"), 'chemicals'),
        new InputSelection(t("inventory:categoryFertilizerAndConditioners"), 'fertilizerAndConditioners'),
        new InputSelection(t("inventory:categoryFuel"), 'fuel'),
        new InputSelection(t("inventory:categoryFarmSupplies"), 'farmSupplies'),
        new InputSelection(t("inventory:categoryEquipmentParts"), 'equipmentParts'),
        new InputSelection(t("inventory:categorySeedsAndPlants"), 'seedsAndPlants'),
        new InputSelection(t("inventory:categoryEquipmentMaintenance"), 'equipmentMaintenance'),
        new InputSelection(t("inventory:categoryInfrastructureMaintenance"), 'infrastructureMaintenance'),
        new InputSelection(t("inventory:categoryLivestockMaintenance"), 'livestockMaintenance'),
        new InputSelection(t("inventory:categoryOther"), 'other'),]

    useEffect(() => {
        if (inventoryId) {
            dispatch(getInventory(inventoryId))
            dispatch(getActivityByConnectionId(inventoryId!)) }
    }, [dispatch, inventoryId])

    if (status === Status.Failure) {
        toast.error(t("inventory:inventoryGetFailed"))
    }

    const [formData, setFormData] = useState({
        name: '',
        description: '',
        type: '',
        quantity: 0,
        value: '',
        primaryUse: '',
        unit: ''
    })


    //////////// CHANGED DATA STATE ///////////

    const [changedData, setChangedData] = useState({
        field: ''
    })

    const {field} = changedData
    let changedField : string

    useEffect(() => {
        if (status !== Status.Pending && inventory !== undefined)
            dispatch(getActivityByConnectionId(inventoryId!))
    }, [inventoryId, status])

    useEffect(() => {
        if (status !== Status.Pending && inventory !== undefined) setFormData((prevState) => ({
            ...prevState,
            name: inventory.name,
            description: inventory.description,
            type: inventory.type,
            quantity: inventory.quantity,
            value: inventory.value!
            
        }));
    }, [status])

    const {name, description, type, quantity, value } = formData

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setChangedData(() => ({
            field: e.target.name 
        }))
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        e.preventDefault()
        changedField = e.target.name

        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))

        const inventoryData: InventoryModel = {
            name,
            description,
            type: e.target.value,
            quantity,
            value,
            _id: inventoryId,
            changedField: e.target.id
        }

        dispatch(updateInventory(inventoryData))
            .unwrap()
            .then(() => {
                toast.success(t("inventory:inventoryUpdated"))
            })
            .catch(() =>{
                toast.error(t("inventory:inventoryUpdateFailed"))
            })
    }

    const onKeyDown = (e: React.KeyboardEvent) => {
        console.log(e)
        if (e.key === "Enter" || e.key === "Escape") {
            (e.target as HTMLElement).blur();
        }
    }

    const onBlur = (e: React.FocusEvent) => {
        e.preventDefault()
        const inventoryData: InventoryModel = {
            name,
            description,
            type,
            quantity,
            value,
            _id: inventoryId,
            changedField: changedData.field
        }

        dispatch(updateInventory(inventoryData))
            .unwrap()
            .then(() => {
                toast.success(t("inventory:inventoryUpdated"))
            })
            .catch(() =>{
                toast.error(t("inventory:inventoryUpdateFailed"))
            })
    }


    ////////////////////////////////////////////
    // Delete and Modal CODE
    ////////////////////////////////////////////

    const onDelete = () => {
        dispatch(deleteInventory([inventoryId!]))
        .unwrap()
        .then(() => {
            toast.success(t("inventory:inventoryDeleted"));
            navigate("/inventory")
        })
        .catch(() => {
            toast.error(t("inventory:failedToDeleteinventory"))
        })
    }

    const [modalIsOpen, setModalIsOpen] = useState(false)

    const onDeleteClick = (e: any) => {
        setModalIsOpen(!modalIsOpen)
    }


    if (status === Status.Pending && inventory?.name != '' && inventory?.type != '') {
        return <Spinner/>
    } else {
        return (
            <>
                <div className="bg-white py-5 rounded-lg #drop-shadow-xl #shadow-lg relative px-9 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] mx-5  ">

                    <div className="grid grid-cols-3 gap-3">
                        <div className='-my-1 col-span-1 justify-self-center self-center'>
                            <img className="h-36 w-34 bg-indigo-400 shadow-xl rounded-xl"
                                 src="https://cdn-icons-png.flaticon.com/512/2833/2833675.png" alt=""/>
                        </div>
                        <div className='col-span2 justify-start justify-self-start'>
                            <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                        onKeyDown={(e) => onKeyDown(e)} title={t("translation:name")} value={name} id={"name"}
                                        name={"name"}/>
                            <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                        onKeyDown={(e) => onKeyDown(e)} title={t("translation:description")} value={description}
                                        id={"description"} name={"description"}/>
                            <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                        onKeyDown={(e) => onKeyDown(e)} title={t("translation:quantity")} value={quantity.toString()}
                                        id={"quantity"} name={"quantity"}/>
                            <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                        onKeyDown={(e) => onKeyDown(e)} title={t("inventory:value")} value={value} id={"value"}
                                        name={"value"}/>
                            <InlineEditDropDown onChange={(e) => handleChange(e)} 
                                                onKeyDown={(e) => onKeyDown(e)} selections={categorySelection}
                                                value={type} id={"type"} title={t("translation:type")} name={t("translation:type")}/>
                        </div>
                    </div>
                    {/* ////////////////////////////////////////////////////////////////////// */}
                    {/* //////////////////////////// DELETE BUTTON /////////////////////////// */}

                    <ItemPageDeleteButton onDeleteClick={onDeleteClick} />
                </div>

                {/* ////////////////////////////////////////////////////////////////////// */}
                {/* //////////////////////////// DELETE MODAL //////////////////////////// */}

                <DeleteModal modalIsOpen={modalIsOpen} name={inventory?.name!} onDeleteClick={onDeleteClick} onDelete={onDelete} />

                {/* ////////////////////////////////////////////////////////////////////// */}
                {/* //////////////////////////// ACTIVITY LOG //////////////////////////// */}
                
                <ChangeActivityList allActivity={allActivity} page={'Inventory'} />
                
            </>
        )
    }
}


export default InventoryPage