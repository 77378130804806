import React, {useState} from 'react'
import FormCard from './shared/FormCard'
import {useNavigate, useParams} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import FormTwoInputs from './shared/FormTwoInputs'
import NumberInputInUnits from './shared/NumberInputInUnits'
import LegacyDropDownInput from './shared/LegacyDropDownInput'
import {AppDispatch} from "../../app/store";
import {InputSelection} from "./../../types/ui";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import MultiDropDownInput, {DropDownOption} from "./shared/MultiDropDownInput";
import {ActionMeta} from "react-select";
import { addTimeSeriesData } from 'features/timeSeriesData/timeSeriesDataSlice'
import {DataTopicModel, LiveStockModel} from "@asirisos/types";

function AddDataTopic() {
    const {t} = useTranslation(['dataTopicAdd']);
    const {liveStockId} = useParams()
    const dispatch = useDispatch<AppDispatch>()

    const [formData, setFormData] = useState({
        dataTopic: '',
        description: ''
    })

    const { description, dataTopic } = formData

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
       
        const dataTopicData : DataTopicModel = {

            connectedItemId: liveStockId!,
            description,
            dataTopic            
        }

        // console.log('Live Stock Weight Data',liveStockWeightData)

        dispatch(addTimeSeriesData(dataTopicData))
            .unwrap()
            .then(() => {
                toast.success("data:WeightAdded");
            })
            .catch(() => {
                toast.error("data:FailedToAddWeight")
            })
        }

    const [addDataModalIsOpen, setaddDataModalIsOpen] = useState(false)

    const onAddData = (e: any) => {
        setaddDataModalIsOpen(!addDataModalIsOpen)
    }

  return (
    <>
    
    {addDataModalIsOpen === false ? ( <div className=''>
        <button onClick={onAddData}  className='mt-10 bg-indigo-500 py-3 px-10' >
            <h1 className='text-white'>Add Data</h1>
        </button>
    </div>) : (<></>) }
    {addDataModalIsOpen === true ? (<> 


    <FormCard title={t("dataTopicAdd:AddData")}>
        <form onSubmit={onSubmit} >
            
            {/* <NumberInputInUnits  onChange={(e) => onChange(e)} value={data} id={"data"} type={'numbr'}
                    group={"data"} label={"data"} name={"data"}
                    dataTitle={t("translation:Data")}/> */}
            <div className='absolute -right-6 -top-6 p-2 ' >
                <button onClick={onAddData} className="btn rounded-full hover:bg-black">X</button>
            </div>
            <div className='pb-5 w-1/3 ml-8' >
                <NumberInputInUnits onChange={(e) => onChange(e)} value={dataTopic} id={"dataTopic"} type={'text'}
                                    group={"dataTopic"} label={"dataTopic"} name={"dataTopic"} dataTitle={t("translation:title")}/>
            </div>

            <div className='pb-8 w-2/3 ml-8' >
                <NumberInputInUnits onChange={(e) => onChange(e)} value={description} id={"description"} type={'text'}
                    group={"description"} label={"description"} name={"description"}
                    dataTitle={t("translation:description")}/>
            </div>   

            <button className="mt-6 text-lg text-white p-3 rounded-md bg-indigo-500  hover:bg-indigo-600">
                Add Data Topic
            </button>
        </form>
    </FormCard>
    </>) : (<>  </>)}

    </>
  )
}

export default AddDataTopic