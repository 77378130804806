import axios from "axios"
import {AccountModel, ActivityModel} from "@asirisos/types";
import {ActivityCategory} from "@asirisos/types/data";
import RequestBodyFactory from "../../factories/requestBodyFactory";
import {FixMeLater} from "../../types/dev";
import {RequestBody} from "@asirisos/types/network";

const API_URL = '/api/activity'

//Add Activity
const addActivity = async (activityData: ActivityModel, accountData: AccountModel) => {
    const body = new RequestBodyFactory(activityData).WithAccount(accountData).Build();
    return await axios.post<any, FixMeLater, RequestBody<ActivityModel>>(API_URL + '/', body)
}

const getAllActivity = async (account: AccountModel) => {
    return await axios.get(API_URL + '/all/' + account.farm._id + '/' + account.user._id)
}

const getActivity = async (activityId: string) => {
    return await axios.get(API_URL + '/' + activityId)
}

const getActivityByConnectionId = async (connectionId: string) => {
    return await axios.get(API_URL + '/activitybyconnection/' + connectionId)
}

const updateActivity = async (activityData: ActivityModel) => {
    const body = new RequestBodyFactory(activityData).Build();
    return await axios.put<any, FixMeLater, RequestBody<ActivityModel>>(API_URL + '/' + activityData._id, body)
}

const deleteActivity = async (activityId: string) => {
    return await axios.delete(API_URL + '/' + activityId)
}

const getCategoryActivity = async (activityCategory: ActivityCategory, account: AccountModel) => {

    const body = new RequestBodyFactory(activityCategory).WithAccount(account).Build()
    return await axios.post<any, FixMeLater, RequestBody<ActivityCategory>>(API_URL + '/allcategory/', body)
}

const activityService = {
    getActivityByConnectionId,
    addActivity,
    getAllActivity,
    getCategoryActivity,
    getActivity,
    updateActivity,
    deleteActivity
}

export default activityService