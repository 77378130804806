import React from 'react'
import YieldCategoryPieChart from './YieldCategoryPieChart'


function YieldDashboard() {
  return (
    <>
        <div className='flex flex-row justify-around bg-white drop-shadow-lg rounded-tl-none py-8' >
        <div className=' bg-slate-200 rounded-lg px-10 py-4 shadow-[2px_1px_15px_2px_rgba(201,201,201,0.47)] ' >
          <h1 className='text-xl '>Yield By Category</h1>
          <YieldCategoryPieChart/>
        </div>
        

       
      </div>
    </>
  )
}

export default YieldDashboard