import {RootState} from "../app/store";

export enum Status {
    Unknown,
    Failure,
    Pending,
    Success
}

export interface ApiError {
    message: string
}

export type DefaultThunkApiConfig = {
    state: RootState,
    rejectValue: void
}