import React from 'react'
import ExpenseTable from './ExpenseTable'
import { Link } from 'react-router-dom'

function Expenses() {
  return (
    <>
    <div className='px-5'>
        <ExpenseTable/>
        <Link className="btn mb-8" to={"/expense-form"} >
        Add Expense
        </Link>
    </div>
    </>
  )
}

export default Expenses