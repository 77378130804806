import React, {ReactNode} from 'react'

type FormTwoInputsProps = {
  children: ReactNode
}

function FormTwoInputs(props: FormTwoInputsProps) {
  return (
    <div className="py-3 flex flex-row justify-around" >
        {props.children}
    </div>
  )
}

export default FormTwoInputs