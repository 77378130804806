import React, {ChangeEventHandler} from "react";
import {InputSelection} from "../../../types/ui";

type DropDownInputProps =
    {
        dataTitle: string,
        selections: Array<InputSelection>,
        denominations?: Array<string>,
        label?: string,
        group: string,
        type: string,
        placeholder? : string,
        value: string,
        id: string,
        onChange: ChangeEventHandler<any>,
        name: string,
        fixedInputItem? :string,
        dName? : string,
        dValue? : string,
        dId?: string,
        dType?: string,
        dPlaceholder?: string
    }

function LegacyDropDownInput({
                         dataTitle,
                         selections,
                         denominations,
                         label,
                         group,
                         type,
                         placeholder,
                         value,
                         id,
                         onChange,
                         name,
                         fixedInputItem,
                         dName,
                         dValue,
                         dId,
                         dType,
                         dPlaceholder
                       } : DropDownInputProps) {
  return (
    <div>
      <div className="mt-8 relative rounded-xl shadow-md">


        {/*************************************   FIXED ENTRY IN INPUT   **************************************/}
        {fixedInputItem ? <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm">{fixedInputItem}</span>
        </div> : <></>}


        {/*************************************   MAIN INPUT FIELD       **************************************/}
        <label className="absolute left-3 insert-y-0 bottom-14 text-slate-500 text-sm">{dataTitle}</label>
        <select
          className={`focus:ring-indigo-500 focus:border-indigo-500 bg-white ${value === '' ? "text-gray-400" : ''} pt-4 sm:text-sm  border-none rounded-xl select select-bordered w-full`}
          id={id}
          name={name}
          value={value}
          onChange={onChange}>
          <option value="" disabled selected>select</option>

          {selections.map((selection => {
            return (
              <option value={selection.id}>{selection.name}</option>
            )
          }))}
          {/* <option value="Richard">Richard</option>
              <option value="Jack">Jack</option>
              <option value="Jeremy">Jeremy</option> */}
        </select>
        {/* <input
            onChange={onChange} name={name} value={value} id={id} type={type} 
            
            className="focus:ring-indigo-500 focus:border-indigo-500 w-full pl-9 pr-32 pt-4 pb-4 sm:text-sm  border-none rounded-xl"
            
          /> */}


        {/*************************************   DENOMINATION INPUT       **************************************/}
        {denominations ? (
            <div className="absolute inset-y-0 right-0 flex items-center">
              <select
                onChange={onChange} name={dName} value={dValue} id={dId} itemType={dType} placeholder={dPlaceholder}
                //   id="currency"
                //   name="currency"
                className="focus:ring-indigo-500 focus:border-indigo-500 h-full pl-2 pr-8 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md">
                {denominations?.map(denomination => {
                  return (<option>{denomination}</option>)
                })}
              </select>
            </div>) :
          (<> </>)}

      </div>
    </div>
  )
}

export default LegacyDropDownInput