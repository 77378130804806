import axios from "axios";
import {FarmJoinModel} from "@asirisos/types";
import RequestBodyFactory from "../../factories/requestBodyFactory";

const API_URL = '/api/farmjoin'

// Add FarmJoin

const addFarmJoin = async (farmJoinData: FarmJoinModel, token: string) => {

    const body = new RequestBodyFactory(farmJoinData).Build()
    const response = await axios.post(API_URL + '/add', body)

    return response.data
}

// Get FarmJoins for a user

const getUserFarmJoins = async (UserId: string, token: string) => {

    const body = new RequestBodyFactory(UserId).Build()
    const response = await axios.post(API_URL + '/alluser', body)
    return response.data
}

// Get FarmJoins for a specific Farm

const getAllFarmJoin = async (FarmId: string, token: string) => {
    const body = new RequestBodyFactory(FarmId).Build()
    const response = await axios.post(API_URL + '/allfarm', body)

    return response.data
}

// Get Single FarmJoin

const getFarmJoin = async (farmJoinId: string, token: string) => {
    const response = await axios.get(API_URL + '/getone/' + farmJoinId)

    return response.data
}

// Update FarmJoin

const updateFarmJoin = async (farmJoinData: FarmJoinModel, token: string) => {
    const {_id: farmJoinId} = farmJoinData
    const body = new RequestBodyFactory(farmJoinData).Build()

    const response = await axios.put(API_URL + '/update', body)

    return response.data

}

// Delete FarmJoin

const deleteFarmJoin = async (farmJoinId: string, token: string) => {
    const response = await axios.delete(API_URL + '/delete/' + farmJoinId)
    return response.data
}

const farmJoinService = {
    addFarmJoin,
    getAllFarmJoin,
    getFarmJoin,
    updateFarmJoin,
    deleteFarmJoin,
    getUserFarmJoins
}

export default farmJoinService