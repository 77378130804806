import React, {ChangeEventHandler, KeyboardEventHandler, FocusEventHandler} from "react";

type LargeTextInputProps = {
    dataTitle: string,
    denominations? : Array<string>,
    label: string,
    group: string,
    type: string,
    placeholder?: string,
    value : string,
    id: string,
    onChange: ChangeEventHandler<any>,
    onKeyDown? : KeyboardEventHandler,
    onBlur? : FocusEventHandler,
    name: string,
    fixedInputItem?: string,
    dName?: string,
    dValue?: string,
    dId?: string,
    dType? :string,
    dPlaceholder? :string
}

function LargeTextInput({
                            dataTitle,
                            denominations,
                            label,
                            group,
                            type,
                            placeholder,
                            value,
                            id,
                            onChange,
                            onKeyDown,
                            onBlur,
                            name,
                            fixedInputItem,
                            dName,
                            dValue,
                            dId,
                            dType,
                            dPlaceholder
                        }: LargeTextInputProps) {
    return (
        <div>
            <div className="mt-10 relative rounded-xl shadow-md">


                {/*************************************   FIXED ENTRY IN INPUT   **************************************/}
                {fixedInputItem ? <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">{fixedInputItem}</span>
                </div> : <></>}


                {/*************************************   MAIN INPUT FIELD       **************************************/}
                <label className="absolute left-3 insert-y-0 bottom-3 mb-16 text-slate-500 text-sm">{dataTitle}</label>
                <input
                    onChange={onChange} name={name} value={value} id={id} type={type} onKeyDown={onKeyDown} maxLength={60}

                    className="focus:ring-indigo-500 focus:border-indigo-500 w-full pl-5 pt-5 pb-5 text-lg break-words border-none rounded-xl"
                    // placeholder={dataTitle}
                />


                {/*************************************   DENOMINATION INPUT       **************************************/}
                {/* {denominations ? (
          <div className="absolute inset-y-0 right-0 flex items-center">
            <select
            onChange={onChange} name={dName} value={dValue} id={dId} type={dType} placeholder={dPlaceholder}
            //   id="currency"
            //   name="currency"
              className="focus:ring-indigo-500 focus:border-indigo-500 h-full pl-2 pr-8 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md">
                {denominations?.map(denomination => {
                    return( <option>{denomination}</option> ) 
                    })}
            </select>
          </div> ) :
          (<> </>) } */}

            </div>
        </div>
    )
}

export default LargeTextInput