import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {toast} from 'react-toastify'
import FormCard from '../../components/shared/FormCard'
import FormTwoInputs from '../../components/shared/FormTwoInputs'
import NumberInputInUnits from '../../components/shared/NumberInputInUnits'
import { addCustomer } from '../../../features/customers/customerSlice'
import {AppDispatch, RootState} from "../../../app/store";
import {Status} from "../../../types/slice";
import {useTranslation} from "react-i18next";
import Spinner from 'UI/components/Spinner'
import {CustomerModel} from "@asirisos/types";

function CustomerForm() {
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const {t} = useTranslation('customer');

    const {user} = useSelector((state : RootState) => state.auth)
    const {status} = useSelector((state : RootState) => state.customer)

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
    })

    const {
        firstName, lastName, email, phone} = formData


    const onChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }) )
    }

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        // TODO this was included in customerData but probably shouldn't
        // Random farm id doesn't sound right and we shouldn't be setting ID's for things on the frontend anyways
        let farmId = crypto.randomUUID();
        let customerId = crypto.randomUUID();

        const customerData : CustomerModel = {
            firstName,
            lastName,
            email,
            phone
        }
        console.log(customerData)
        dispatch(addCustomer(customerData))
            .unwrap()
            .then(() => {
                toast.success("customer:customerAdded");
                navigate("/customers")
            })
            .catch(() => {
                toast.error(t("customer:customerAddFailed"))
                navigate("/customer-form")
            })
        
    }
    if (status === Status.Pending) {
        return <Spinner/>
    }
  return (
    <>
        <FormCard title="Add Customer" >
            <form onSubmit={onSubmit}>
                <FormTwoInputs>
                    <NumberInputInUnits onChange={(e) => onChange(e)} value={firstName} id={"firstName"} type={'text'} group={"firstName"} label={"firstName"} name={"firstName"} dataTitle="First Name" />
                    <NumberInputInUnits onChange={(e) => onChange(e)} value={lastName} id={"lastName"} type={'text'} group={"lastName"} label={"lastName"} name={"lastName"} dataTitle="Last Name" />
                </FormTwoInputs>

                <FormTwoInputs>
                    <NumberInputInUnits onChange={(e) => onChange(e)} value={email} id={"email"} type={'text'} group={"email"} label={"email"} name={"email"} dataTitle="Email" />
                    <NumberInputInUnits onChange={(e) => onChange(e)} value={phone} id={"phone"} type={'text'} group={"phone"} label={"phone"} name={"phone"} dataTitle="Phone" />
                </FormTwoInputs>

            <div className='flex justify-center pt-8 p-2' >
                <button className="btn btn-wide btn-primar hover:bg-black">Add Customer</button>
            </div>
            </form>
        </FormCard>
    </>
  )
}

export default CustomerForm