import axios from "axios";
import {AccountModel, OrgFarmersMarketModel, JoinTeamModel} from "@asirisos/types";
import RequestBodyFactory from "../../factories/requestBodyFactory";

const API_URL = '/api/jointeam'

// Add Organization

const addJoinTeam = async(joinTeamData: JoinTeamModel,) => {
    console.log("Join Team service - add join team -  join team data", joinTeamData)
    const body = new RequestBodyFactory(joinTeamData).Build()
    console.log("Join Team Market service - sending data - add join team - body", body)
    return await axios.post(API_URL + '/add', body)
}


const joinTeamService = {
    addJoinTeam,
}

export default joinTeamService