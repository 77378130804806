import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import joinWaitlistService from "./waitlistService";
import {FixMeLater} from "../../types/dev";
import { JoinWaitlistState } from "../../types/states";
import HandleAxiosError from "../../utils/AxiosErrorHandler";
import {DefaultThunkApiConfig, Status} from "../../types/slice";
import { JoinOrgWaitlistModel, JoinShopperWaitlistModel } from "@asirisos/types";

const CONTEXT = "JoinWaitlist"

const initialState: JoinWaitlistState = {
    joinOrgWaitlist: undefined,
    joinShopperWaitlist: undefined,
    status: Status.Unknown,
    message: ''
}

export const addJoinOrgWaitlist = createAsyncThunk<any, JoinOrgWaitlistModel, DefaultThunkApiConfig>('join-Org-Waitlist/add', async (joinOrgWaitlistData, thunkAPI) => {
    try {

        const response = await joinWaitlistService.addJoinOrgWaitlist(joinOrgWaitlistData)
        if (response.status !== 201)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const addJoinShopperWaitlist = createAsyncThunk<any, JoinShopperWaitlistModel, DefaultThunkApiConfig>('join-Shoppper-Waitlist/add', async (joinShoppperWaitlistData, thunkAPI) => {
    try {

        const response = await joinWaitlistService.addJoinShopperWaitlist(joinShoppperWaitlistData)
        if (response.status !== 201)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const joinWaitlistSlice = createSlice({
    name: 'joinWaitlist',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(addJoinOrgWaitlist.pending, (state: JoinWaitlistState) => {
                state.status = Status.Pending
            })
            .addCase(addJoinOrgWaitlist.fulfilled, (state: JoinWaitlistState) => {
                state.status = Status.Success
            })
            .addCase(addJoinOrgWaitlist.rejected, (state: JoinWaitlistState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            .addCase(addJoinShopperWaitlist.pending, (state: JoinWaitlistState) => {
                state.status = Status.Pending
            })
            .addCase(addJoinShopperWaitlist.fulfilled, (state: JoinWaitlistState) => {
                state.status = Status.Success
            })
            .addCase(addJoinShopperWaitlist.rejected, (state: JoinWaitlistState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
    }
})

export const {reset} = joinWaitlistSlice.actions
export default joinWaitlistSlice.reducer