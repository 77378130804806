import React, {ReactNode} from 'react'

type FromRangeInputProps = {
  children: ReactNode,
  title: string
}

function FormRangeInput(props: FromRangeInputProps) {
  return (
    <div className="px-12 pt-6"  >
              <h1 className="flex text-lg text-gray-500" >{props.title}</h1>
              <div className="flex flex-row gap-4">
                {props.children}
              </div>
    </div>
  )
}

export default FormRangeInput