import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import customerService from "./customerService";
import {CustomerState} from "../../types/states";
import HandleAxiosError from "../../utils/AxiosErrorHandler";
import {DefaultThunkApiConfig, Status} from "../../types/slice";
import {CustomerModel} from "@asirisos/types";

const CONTEXT = "Customer"

const initialState: CustomerState = {
    customer: undefined,
    customers: [],
    status: Status.Unknown,
    message: ''
}

//Add Customer
export const addCustomer = createAsyncThunk<any, CustomerModel, DefaultThunkApiConfig>('customer/add', async (customerData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await customerService.addCustomer(customerData, account)
        if (response.status !== 201)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

//Get Customers

export const getCustomers = createAsyncThunk<CustomerModel[], void, DefaultThunkApiConfig>('customer/getall', async (_, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await customerService.getCustomers(account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getCustomer = createAsyncThunk<CustomerModel, string, DefaultThunkApiConfig>('customer/getone/:customerId', async (customerId, thunkAPI) => {
    try {
        const response = await customerService.getCustomer(customerId)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const updateCustomer = createAsyncThunk<CustomerModel, CustomerModel, DefaultThunkApiConfig>('customer/update/:customerId', async (customerData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if(!account)
            return thunkAPI.rejectWithValue();

        const response = await customerService.updateCustomer(customerData, account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const deleteCustomer = createAsyncThunk<any, string[], DefaultThunkApiConfig>('customer/delete', async (customerId, thunkAPI) => {
    try {
        const response = await customerService.deleteCustomer(customerId)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

// Add Customer
// export const addCustomer = createAsyncThunk<CustomerModel, CustomerData, { state: RootState }>('Customers/addCustomer', async(customerData, thunkAPI : FixMeLater) => {
//     try {
//         const token = thunkAPI.getState().auth.user.token
//         return await customerService.addCustomer(customerData, token)
//     } catch (error) {
//         HandleAxiosError(error, CONTEXT);
//         return thunkAPI.rejectWithValue("message")
//     }
// } )

// Get Customers
// export const getCustomers = createAsyncThunk<any, void, { state: RootState }>('Customers/getCustomers', async(_,thunkAPI : FixMeLater) => {
//     try {
//         const token = thunkAPI.getState().auth.user.token
//         return await customerService.getCustomers(token)
//     } catch (error) {
//         HandleAxiosError(error, CONTEXT);
//         return thunkAPI.rejectWithValue("message")
//     }
// } )

// Get Customer
// export const getCustomer = createAsyncThunk<CustomerData, string, { state: RootState }>('Customers/getCustomer/:customerId', async(customerId, thunkAPI : FixMeLater) => {
//     try {
//         const token = thunkAPI.getState().auth.user.token
//         return await customerService.getCustomer(customerId, token)
//     } catch (error) {
//         HandleAxiosError(error, CONTEXT);
//         return thunkAPI.rejectWithValue("message")
//     }
// } )

// Update Customer
// export const updateCustomer = createAsyncThunk<CustomerData, CustomerData, { state: RootState }>('Customers/updateCustomer/:customerId', async(customerData, thunkAPI : FixMeLater) => {
//     try {
//     const token = thunkAPI.getState().auth.user.token
//     return await customerService.updateCustomer(customerData, token)
//     } catch (error) {
//         HandleAxiosError(error, CONTEXT);
//         return thunkAPI.rejectWithValue("message")
//     }
// } )

// Delete Customer
// export const deleteCustomer = createAsyncThunk<CustomerData, string, { state: RootState }>('Customers/deleteCustomer/:customerId', async(customerId : string, thunkAPI : FixMeLater) => {
//     try {
//         const token = thunkAPI.getState().auth.user.token
//         return await customerService.deleteCustomer(customerId, token)
//     } catch (error) {
//         HandleAxiosError(error, CONTEXT);
//         return thunkAPI.rejectWithValue("message")
//     }
// } )

export const customerSlice = createSlice ({
    name: 'customer',
    initialState,
    reducers: {
        reset: () => initialState
        },
    extraReducers: (builder) => {
        builder
        .addCase(addCustomer.pending, (state: CustomerState) => {
            state.status = Status.Pending
        })
        .addCase(addCustomer.fulfilled, (state: CustomerState) => {
            state.status = Status.Success
        })
        .addCase(addCustomer.rejected, (state: CustomerState) => {
            state.status = Status.Failure
        })
        .addCase(getCustomers.pending, (state: CustomerState) => {
            state.status = Status.Pending
        })
        .addCase(getCustomers.fulfilled, (state: CustomerState, action: PayloadAction<CustomerModel[], any, any>) => {
            state.status = Status.Success
            state.customers = action.payload
        })
        .addCase(getCustomers.rejected, (state: CustomerState) => {
            state.status = Status.Failure
        })
        .addCase(getCustomer.pending, (state: CustomerState) => {
            state.status = Status.Pending
        })
        .addCase(getCustomer.fulfilled, (state: CustomerState, action: PayloadAction<CustomerModel, any, any>) => {
            state.status = Status.Success
            state.customer = action.payload
        })
        .addCase(getCustomer.rejected, (state: CustomerState) => {
            state.status = Status.Failure
        })
        .addCase(updateCustomer.pending, (state: CustomerState) => {
            state.status = Status.Pending
        })
        .addCase(updateCustomer.fulfilled, (state: CustomerState, action: PayloadAction<CustomerModel, any, any>) => {
            state.status = Status.Success
            state.customers.map((customer: CustomerModel) => customer._id === action.payload._id ?
                (state.customer = customer) : customer)
            state.customer = action.payload
        })
        .addCase(updateCustomer.rejected, (state: CustomerState) => {
            state.status = Status.Failure
        })
        .addCase(deleteCustomer.pending, (state: CustomerState) => {
            state.status = Status.Pending
        })
        .addCase(deleteCustomer.fulfilled, (state: CustomerState) => {
            state.status = Status.Success
            state.customer = undefined
        })
        .addCase(deleteCustomer.rejected, (state: CustomerState) => {
            state.status = Status.Failure
        })
    }
})

export const {reset} = customerSlice.actions
export default customerSlice.reducer