import React, { useState, useEffect} from "react";
import { MapContainer, TileLayer, FeatureGroup, Polygon, Popup, Marker, LayerGroup, LayersControl, } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import {useNavigate, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from "react-i18next";
import {InputSelection} from "../../../types/ui";
import { useRef } from "react";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import LegacyDropDownInput from '../../components/shared/LegacyDropDownInput'
import LeafletControlGeocoder from "./LeafletControlGeocoder";
import FormCard from "UI/components/shared/FormCard";
import FormTwoInputs from "UI/components/shared/FormTwoInputs";
import LargeTextInput from "UI/components/shared/LargeTextInput";
import NumberInputInUnits from "UI/components/shared/NumberInputInUnits";
import { getCrops } from "features/crops/cropSlice";
import { addField } from "features/fields/fieldSlice";
import { getFields } from "features/fields/fieldSlice";
import { addInfrastructure, getInfrastructures } from "features/infrastructure/infrastructureSlice";
import { RootState } from "../../../app/store";
import { deleteField, getField, updateField } from 'features/fields/fieldSlice'
import {toast} from "react-toastify";
import { t } from "i18next";

const SingleFieldPageLeafletPolygon = (field) => {
  
  const ref1 = useRef(null);
  const doClick = () => ref1.current?.scrollIntoView({behavior: 'smooth'})
  const {t} = useTranslation(["field", "translation"], ["infrastructure", "translation"])
  console.log("Field Data from the map display section", field)




  
  
  const [center, setCenter] = useState({ lat: 36.985206, lng: -120.292997 });

  const ZOOM_LEVEL = 15;
  const mapRef = useRef();


  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////    MAP OBJECT OPTIONS   /////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const redOptions = { color: 'blue', weight: 1, dashArray: 'polygon', opacity: 0.8, fill: true, fillOpacity: 0.1 }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////    RETURN OF THE OBJECT   ///////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div className="" >
        <div className="" >

          {/* //////////////////////////////////////////////////////////////////////////// */}
          {/* //////////////////////////   MAP DISPLAY   ///////////////////////////////// */}
          {/* //////////////////////////////////////////////////////////////////////////// */}

          <MapContainer center={center} zoom={ZOOM_LEVEL} ref={mapRef}>

            <TileLayer url='https://api.mapbox.com/styles/v1/purpledragons/cl9kzio97001v14pe6ahvafbd/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicHVycGxlZHJhZ29ucyIsImEiOiJjbDlqMGp3cHkwZDJsM25wa2I2dnBxMXU2In0.m0DYm_idO-8_I7OeSBuMnQ'
            maxZoom= {20}/>


            <TileLayer url='https://api.mapbox.com/styles/v1/purpledragons/cl9kzio97001v14pe6ahvafbd/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicHVycGxlZHJhZ29ucyIsImEiOiJjbDlqMGp3cHkwZDJsM25wa2I2dnBxMXU2In0.m0DYm_idO-8_I7OeSBuMnQ'
            maxZoom= {20}/>

            {/* <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright"></a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/> */}
            
            <TileLayer url='https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
            maxZoom= {20}/>

            <Polygon pathOptions={redOptions} positions={field?.field?.latlngs}>
              <Popup>
                A pretty CSS3 popup. <br /> Easily customizable. Field ID
                <h1>{field?._id}</h1>
                <h1>{field?.description}</h1>
              </Popup>
            </Polygon>

            {/* <LayersControl position="topright">
              <LayersControl.Overlay name="Fields">
                <LayerGroup>
                  
                      

                </LayerGroup>
              </LayersControl.Overlay>
            </LayersControl> */}
            {/* <LeafletControlGeocoder/> */}
          </MapContainer>
        </div>
      </div>
    </>
  );
};

export default SingleFieldPageLeafletPolygon;