import React from 'react'
import { Link } from 'react-router-dom'

function FarmPost() {
  return (
    <>
    <Link to={`/post/id`} className='flex flex-col text-start items-start justify-start bg-white px-5 rounded-lg shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] py-4 my-2  w-11/12' >
        <div className="flex flex-col text-start ">
            <h1 className='text-3xl font-light'>This is the title to your first post</h1>
        </div>
        <div className='px-4 py-3 w-1/3 flex gap-5'>
            <img className=' shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] ' src="https://img.freepik.com/free-vector/flat-farm-landscape_52683-9442.jpg" alt="" />
            <img className=' shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] ' src="https://img.freepik.com/free-vector/flat-farm-landscape_52683-9442.jpg" alt="" />
            <img className=' shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] ' src="https://img.freepik.com/free-vector/flat-farm-landscape_52683-9442.jpg" alt="" />
        </div>
        <div className='flex flex-col text-start py-2'>
            <p className='text-lg'>Now this will be the text of your post. You can talk about what this post is about. You can give updates about the progress of your produce, 
            update users on any new developments. You can make posts about your animals, new offerings, and projects.</p>
        </div>
        <div className='flex flex-col text-start py-2'>
            <p className='font-light text-slate-500 text-md'>09 21 2023</p>
        </div>
    </Link>
    </>
  )
}

export default FarmPost