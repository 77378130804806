import React from 'react'
import {Link} from 'react-router-dom'

function SelectOrganization() {
  return (
    <>
    <div className='content-center' >


        <div className='flex justify-center pb-10'>    
        <div className='flex flex-col justify-between items-start rounded-lg drop-shadow-xl mt-12 py-8 px-12 bg-slate-100 min-w-md max-w-lg ' >
            <div className='flex flex-row justify-center' >
                <div className='flex' >
                    <h1 className='text-2xl pb-8' >What Describes you Best?</h1>
                </div>
            </div>
            <div>
            <Link className=" btn mb-4" to={"/farm-add-form"} >
                <i className="fa-solid fa-wheat-awn pr-4 "></i>
                Farmer
            </Link>
            </div>
            <div>
            <Link className=" btn mb-4" to={"/farm-service-add-form"} >
                <i className="fa-solid fa-user-gear pr-4"></i>  
                Farm Services Provider
            </Link>
            </div>
            <div>
            <Link className=" btn mb-4" to={"/farm-inputs-add-form"} >
                <i className="fa-solid fa-seedling pr-4"></i>  
                Farm Inputs
            </Link>
            </div>
            <div>
            <Link className=" btn mb-4" to={"/farm-tech-add-form"} >
                <i className="fa-solid fa-computer pr-4"></i>
                Farm Technology
            </Link>
            </div>
            <div>
            <Link className="btn mb-4" to={"/wholesale-add-form"} >
                <i className="fa-solid fa-cart-shopping pr-4"></i>
                Wholesale buyer
            </Link>
            </div>
            <div>
            <Link className="btn mb-4" to={"/processing-add-form"} >
                <i className="fa-solid fa-industry pr-4"></i>
                Processing
            </Link>
            </div>
            <div>
            <Link className="btn mb-4" to={"/warehousing-add-form"} >
                <i className="fa-solid fa-warehouse pr-4"></i>
                Warehouse
            </Link>
            </div>
            <div>
            <Link className="btn mb-4" to={"/transport-add-form"} >
                <i className="fa-solid fa-truck-moving pr-4"></i>
                Transportation
            </Link>
            </div>
            <div>
            <Link className="btn mb-4" to={"/retail-add-form"} >
                <i className="fa-solid fa-basket-shopping pr-4"></i>
                Retail Buyer
            </Link>
            </div>
        </div>
        </div>
        
    </div>
    </>
  )
}

export default SelectOrganization