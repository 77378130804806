import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import authService from "./authService";
import {UserState} from "../../types/states";
import {FixMeLater} from "../../types/dev";
import HandleAxiosError from "../../utils/AxiosErrorHandler";
import {DefaultThunkApiConfig} from "../../types/slice";
import {EmailSignUpModel, UserModel} from "@asirisos/types";
import {UserData} from "@asirisos/types/data";

const CONTEXT = "Auth"

// Get user from local storage
let user = null;
let localUser = localStorage.getItem('user');
if (localUser !== null)
    user = JSON.parse(localUser)

const initialState: UserState = {
    user: user ? user : undefined,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}

// Register User
export const register = createAsyncThunk<UserData, UserData>('auth/register', async (user: UserData, thunkAPI) => {
    try {
        return await authService.register(user)
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue("message")
    }
})

// Email SignUp User
export const emailSignUp = createAsyncThunk<any, EmailSignUpModel, DefaultThunkApiConfig>('auth/emailSignUp', async (emailSignUpData, thunkAPI) => {
    try {

        const response = await authService.emailSignUp(emailSignUpData)
        if (response.status !== 201)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue() 
    }
})

// Login User
export const signin = createAsyncThunk<UserModel, UserData, DefaultThunkApiConfig>('auth/login', async (user, thunkAPI) => {
    try {
        const response = await authService.signin(user)

        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        localStorage.setItem('user', JSON.stringify(response.data))
        localStorage.setItem('account', JSON.stringify(response.data.account))

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

// Logout User
export const logout = createAsyncThunk<void>('auth/logout', async () => {
        localStorage.removeItem('user')
        localStorage.removeItem('account')
})

// Reset Password
export const forgotpassword = createAsyncThunk<UserData, FixMeLater>('auth/forgotpassword', async (user: UserData, thunkAPI: FixMeLater) => {
    try {
        return await authService.forgotpassword(user)
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue("message")
    }
})

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(register.pending, (state: UserState) => {
                state.isLoading = true
            })
            .addCase(register.fulfilled, (state: UserState, action: FixMeLater) => {
                state.isLoading = false
                state.isSuccess = true
                state.user = action.payload
            })
            .addCase(register.rejected, (state: UserState, action: FixMeLater) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.user = undefined
            })
            .addCase(signin.pending, (state: UserState) => {
                state.isLoading = true
            })
            .addCase(emailSignUp.pending, (state: UserState) => {
                state.isLoading = true
            })
            .addCase(emailSignUp.fulfilled, (state: UserState) => {
                state.isLoading = false
                state.isSuccess = true
            })
            .addCase(emailSignUp.rejected, (state: UserState) => {
                state.isLoading = false
                state.isSuccess = false
            })
            .addCase(signin.fulfilled, (state: UserState, action: FixMeLater) => {
                state.isLoading = false
                state.isSuccess = true
                state.user = action.payload
            })
            .addCase(signin.rejected, (state: UserState, action: FixMeLater) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.user = undefined
            })
            .addCase(logout.fulfilled, (state: UserState) => {
                state.user = undefined
            })
            .addCase(forgotpassword.fulfilled, (state: UserState, action: FixMeLater) => {
                state.message = action.payload.data
            })
    },
})

export const {reset} = authSlice.actions

export default authSlice.reducer