import React from 'react'
import DisplayAllFarmersMarkets from '../Map/DisplayAllFarmersMarkets'
import {useTabPersistence} from "../../../hooks/useTabPersistence";
import {PageId} from "../../../types/pages";
import DisplayMapOrgs from '../Map/DisplayMapOrgs';
import DisplayAllBrandsSearchResults from './DisplayAllBrandsSearchResult';
import MobileDisplayMapOrgs from '../Map/MobileDisplayMapOrgs';
import { Link } from 'react-router-dom';

function DisplayOrgsSearchResults() {

  const {selection, toggleTab} = useTabPersistence(PageId.SearchResults);

  return (
    <>

    {/********************************************/}
    {/********************************************/}
    {/********************* MOBILE VERSION START ***********************/}
    {/********************************************/}
    {/********************************************/}

    <div className='lg:hidden sm:visible' >

        <div className='bg-gradient-to-b from-[#ffffff] via-[#ffffff] to-[#ffffff] flex flex-col items-center'>

          <div className='w-screen   drop-shadow-lg flex flex-col items-center'>

            {/* *********************** TAB SELECTIONS *********************** */}

            <div className=' flex flex-col w-full '>

                <div>
                    <Link className=" text-slate-700 hover:bg-[#FFB100] hover:shadow-lg rounded-sm px-4 py-2 bg-[#FFC500] flex justify-center " to={"/search-by-zip"} >
                      <h1 className=' text-lg font-semibold justify-center align-middle'>New Search</h1>
                    </Link>
                </div>

                <ul className="flex felx-row justify-left cursor-pointer">
                    <div onClick={() => toggleTab(1)} className={`${selection === 1 ? 'bg-[#ffffff] text-slate-900 drop-shadow-lg pb-3  w-1/2 ' : 'bg-slate-200 text-slate-400  pb-3  w-1/2 '}`}>
                        <div className='pt-3' >
                            <h1 className='text-xl font-bold'>Organizations</h1>
                            {/* <h1 className='text-md font-light' >Farmers Markets</h1> */}
                        </div>
                    </div>

                    <div onClick={() => toggleTab(2)} className={`${selection === 2 ? 'bg-[#ffffff] text-slate-900 drop-shadow-lg  pb-3  w-1/2 ' : 'bg-slate-200 text-slate-400  pb-3  w-1/2 '}`}>
                        <div className='pt-3' >
                            <h1 className='text-xl font-bold'>Brands</h1>
                            <h1 className='text-md font-light' ></h1>
                        </div>
                    </div>

                </ul>
            


            {/* *********************** TAB SELECTIONS *********************** */}

            {selection === 1 ? (
            <>
            <div className='' >
                <MobileDisplayMapOrgs/>
            </div>
            </>
            ) : (<></>)}

            {selection === 2 ? (
            <>
            <div className='w-full' >
                <DisplayAllBrandsSearchResults/>
            </div>
            </>
            ) : (<></>)}


            </div>

          </div>
        </div>


    </div>



    {/********************************************/}
    {/********************************************/}
    {/********************* MOBILE VERSION END ***********************/}
    {/********************************************/}
    {/********************************************/}




    {/********************************************/}
    {/********************************************/}
    {/********************* DESKTOP VERSION START ***********************/}
    {/********************************************/}
    {/********************************************/}
      <div className='lg:visible invisible max-sm:hidden bg-gradient-to-b from-[#37774bec] via-[#ffffff] to-[#ffffff] flex flex-col items-center h-screen pt-5  '>

        <div className='w-5/6 bg-white  drop-shadow-lg flex flex-col items-center h-screen rounded-t-lg  '>

          {/* *********************** TAB SELECTIONS *********************** */}

          <div className='  w-full rounded-t-lg  '>

              <ul className="flex felx-row justify-left cursor-pointer  ">
                  <div onClick={() => toggleTab(1)} className={`${selection === 1 ? 'bg-[#ffffff] text-slate-900 drop-shadow-lg rounded-tl-lg pb-7  w-1/2 ' : 'bg-slate-200 text-slate-400 rounded-tl-lg  pb-7  w-1/2 '}`}>
                      <div className='pt-5' >
                          <h1 className='text-xl font-bold'>Organizations</h1>
                          {/* <h1 className='text-md font-light' >Farmers Markets</h1> */}
                      </div>
                  </div>

                  <div onClick={() => toggleTab(2)} className={`${selection === 2 ? 'bg-[#ffffff] text-slate-900 drop-shadow-lg rounded-tr-lg pb-7  w-1/2 ' : 'bg-slate-200 text-slate-400 rounded-tr-lg  pb-7  w-1/2 '}`}>
                      <div className='pt-5' >
                          <h1 className='text-xl font-bold'>Brands</h1>
                          <h1 className='text-md font-light'></h1>
                      </div>
                  </div>

              </ul>
          </div>


          {/* *********************** TAB SELECTIONS *********************** */}

          {selection === 1 ? (
          <>
          <div className='  ' >
            <div className=''>
              {/* <div className='flex justify-evenly py-3'>

                <div>
                  <h1 className='text-sm font-semibold'>Farmers Market</h1>
                </div>                 

                <div>
                  <h1 className='text-sm font-semibold' >On-Farm Market</h1>
                </div>

                <div>
                  <h1 className='text-sm font-semibold' >CSA</h1>
                </div>

                <div>
                  <h1 className='text-sm font-semibold' >Grocery Co-op</h1>
                </div>

                <div>
                  <h1 className='text-sm font-semibold' >Food Hubs</h1>
                </div>

              </div> */}
              <DisplayMapOrgs/>
            </div>
          </div>
          </>
          ) : (<></>)}

          {selection === 2 ? (
          <>
          <div className='w-full h-full' >
              <DisplayAllBrandsSearchResults/>
          </div>
          </>
          ) : (<></>)}




        </div>
      </div>
    {/********************************************/}
    {/********************************************/}
    {/********************* DESKTOP VERSION END ***********************/}
    {/********************************************/}
    {/********************************************/}


    </>
  )
}

export default DisplayOrgsSearchResults