import React, {useState, useEffect, ChangeEvent} from 'react'
import NumberInputInUnits from 'UI/components/shared/NumberInputInUnits'
import LegacyDropDownInput from 'UI/components/shared/LegacyDropDownInput';
import {InputSelection} from "../../../types/ui";
import {useTabPersistence} from "../../../hooks/useTabPersistence";
import {PageId} from "../../../types/pages";
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import {AppDispatch, RootState} from "../../../app/store";
import { addJoinOrgWaitlist, addJoinShopperWaitlist } from 'features/waitlist/waitlistSlice';
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import LargeTextInput from 'UI/components/shared/LargeTextInput';
import SearchBox from '../Map/SearchBox-1';
import InlineEditDropDown from 'UI/components/shared/InlineEditDropDown';
import MultiDropDownInput from 'UI/components/shared/MultiDropDownInput';
import joinWaitlistService from 'features/waitlist/waitlistService';
import {useTranslation} from "react-i18next";

function JoinTheWaitlist() {

  const {selection, toggleTab} = useTabPersistence(PageId.Crops);

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const {t} = useTranslation('crop');

    const [formData, setFormData] = useState({
      addressObject: {},
    })

    const {addressObject} = formData

    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setFormData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
      }))
    }

    const onSetAddress = (selectPosition : object) => {
      setShopperFormData((prevState) => ({
          ...prevState,
          addressObject: selectPosition!
      }) )
    }

    const [shopperFormData, setShopperFormData] = useState({
        name: '',
        email: '',
        phone: '',
        location: {},
        shopperCity: '',
        shopperZip: ''
      })

    const [orgFormData, setOrgFormData] = useState({
        orgName: '',
        orgEmail: '',
        orgPhone: '',
        orgLocation: '',
        orgSkills: '',
        orgCategory: '',
        orgZip: '',
        orgCity: ''
      })

      const {name,  email, phone, location, shopperCity, shopperZip} = shopperFormData

      const {orgName, orgEmail, orgPhone, orgLocation, orgSkills, orgCategory, orgZip, orgCity} = orgFormData

      const categorySelection = [new InputSelection('farmer', 'farmer'), new InputSelection('rancher', 'rancher'), new InputSelection('distribution', 'distribution'),new InputSelection('food processing', 'food processing'), new InputSelection('farm services', 'farm services'), 
      new InputSelection('farmers market', 'farmers market'), new InputSelection('inputs', 'inputs'), new InputSelection('equipment and machinery', 'equipment and machinery'), new InputSelection('seeds', 'seeds'),
      new InputSelection('storage', 'storage'), new InputSelection('other', 'other')]

    const onChangeShopper = (e: React.ChangeEvent<HTMLInputElement>) => {
        setShopperFormData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }))
      }
    
    const onChangeOrg = (e: React.ChangeEvent<HTMLInputElement>) => {
        setOrgFormData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }))
      }

    const onSubmitShopper = (e: React.FormEvent) => {
        e.preventDefault()

        if (email === '' || name === '' || phone === '' || shopperZip === '' || shopperCity === '' )  {
          toast.error('please complete all fields')
        } else {

          const joinShopperWaitlistData = {
            name,
            email,
            phone,
            shopperCity,
            shopperZip
          }
          dispatch(addJoinShopperWaitlist(joinShopperWaitlistData))
          .unwrap()
          .then(() => {
              toast.success(t("success"));
              navigate("/success")
          })
          .catch(() => {
            toast.error(t("fail"))
            navigate("/fail")
        })
        }
    }
    
    const onSubmitOrg = (e: React.FormEvent) => {

      e.preventDefault()

      if (orgEmail === '' || orgName === '' || orgPhone === '' || orgZip === '' )  {
        toast.error('please complete all fields')
      } else {
        {
          const joinOrgWaitlistData = {
            orgName,
            orgEmail,
            orgPhone,
            orgLocation,
            orgSkills,
            orgCategory,
            orgZip,
            orgCity
          }

          dispatch(addJoinOrgWaitlist(joinOrgWaitlistData))
            .unwrap()
            .then(() => {
                toast.success(t("success"));
                navigate("/success")
            })
            .catch(() => {
              toast.error(t("fail"))
              navigate("/fail")
          })
        }
      }
    }
      

  return (
    <>
    {/********************* MOBILE VERSION - START ***********************/}
    {/********************************************/}
    {/********************************************/}

    <div className='lg:hidden sm:visible '>

      <div className='flex flex-col '>

        {/********************************************/}
        {/********************* FIRST PAGE MOBILE - START ***********************/}
        {/********************************************/}

        <div className='w-screen h-screen flex flex-col justify-middle align-middle'>

          {/* <ul className="flex felx-row justify-around cursor-pointer bg-[#fff9e1] ">
              <li className={` rounded-b-md text-xl px-10 py-2 text-white bg-[#16341fec]  drop-shadow-' : ''}`}>{("Sign up")} </li>
          </ul> */}


          <div className='flex flex-col justify-center items-center h-full bg-slate-50' >

            {/* <div className='pb-8 w-full pt-4' >

              <ul className="flex felx-row justify-around">
                <li onClick={() => toggleTab(1)}
                    className={`rounded-r-md text-xl w-full py-2 ${selection === 1 ? ' text-white bg-[#16341fec] shadow-[2px_1px_20px_3px_rgba(201,201,201,0.87)] mr-2' : 'mr-2 border-2 border-l-none border-slate-600'}`}>{("For Local Shopper")}
                </li>
                <li onClick={() => toggleTab(2)}
                    className={`rounded-l-md text-xl w-full py-2 ${selection === 2 ? ' text-white bg-[#16341fec] shadow-[2px_1px_20px_3px_rgba(201,201,201,0.87)] ml-2' : 'ml-2 border-2 border-l-none border-slate-600'}`}>{("For Organization")}
                </li>
              </ul>

            </div> */}
              
            {/* {selection === 1? ( */}
              <div className='flex flex-col justify-evenly bg-slate-50 pb-10'>

                <div className='pb-5'>
                  <h1 className='text-3xl font-semibold pb-2' >Sign Up</h1>
                  {/* <h1 className='text-md font-semibold text-gray-500' > <a className='underline text-lg'>Free</a> lifetime account - Limited availability</h1> */}
                  <h1 className='text-lg font-semibold text-gray-500' ></h1>
                </div>

                <form onSubmit={onSubmitShopper}>

                  <div className='flex justify-center ' >

                    <div className='max-w-xl px-5'>
                      <NumberInputInUnits onChange={(e) => onChangeShopper(e)} value={name} id={"name"} type={'text'} group={"name"} label={"name"} name={"name"} dataTitle="Name" />
                      <NumberInputInUnits onChange={(e) => onChangeShopper(e)} value={email} id={"email"} type={'text'} group={"email"} label={"email"} name={"email"} dataTitle="Email" />
                      <NumberInputInUnits onChange={(e) => onChangeShopper(e)} value={phone} id={"phone"} type={'text'} group={"phone"} label={"phone"} name={"phone"} dataTitle="Phone Number" />
                      <NumberInputInUnits onChange={(e) => onChangeShopper(e)} value={shopperZip} id={"shopperZip"} type={'text'} group={"shopperZip"} label={"shopperZip"} name={"shopperZip"} dataTitle="Zip code" />
                      {/* <NumberInputInUnits onChange={(e) => onChangeShopper(e)} value={shopperCity} id={"shopperCity"} type={'text'} group={"shopperCity"} label={"shopperCity"} name={"shopperCity"} dataTitle="City" /> */}
                    </div>

                  </div>

                  <div className="max-w-xl px-5 pt-8">
                    <button className="btn btn-block bg hover:bg-[#FFB100]">
                        Sign Up
                    </button>
                  </div>

                  {/* <div className='flex justify-center pt-8 p-2' >
                    <button className="btn btn-wide btn-primar hover:bg-black">Sign Up</button>
                  </div> */}

                </form>
              </div>
            {/* ) : (<></>)}

            {selection === 2 ? (
              <div className='flex flex-col justify-center bg-[#fff9e1] pb-10'>

                <form onSubmit={onSubmitOrg}>

                  <div className='flex justify-center ' >

                    <div className='max-w-xl px-5'>
                      <NumberInputInUnits onChange={(e) => onChangeOrg(e)} value={orgName} id={"orgName"} type={'text'} group={"orgName"} label={"orgName"} name={"orgName"} dataTitle="Organization Name" />
                      <NumberInputInUnits onChange={(e) => onChangeOrg(e)} value={orgEmail} id={"orgEmail"} type={'text'} group={"orgEmail"} label={"orgEmail"} name={"orgEmail"} dataTitle="Email" />
                      <NumberInputInUnits onChange={(e) => onChangeOrg(e)} value={orgPhone} id={"orgPhone"} type={'text'} group={"orgPhone"} label={"orgPhone"} name={"orgPhone"} dataTitle="Phone Number" />
                      <NumberInputInUnits onChange={(e) => onChangeOrg(e)} value={orgZip} id={"orgZip"} type={'text'} group={"orgZip"} label={"orgZip"} name={"orgZip"} dataTitle="Zip code" />
                      <NumberInputInUnits onChange={(e) => onChangeOrg(e)} value={orgCity} id={"orgCity"} type={'text'} group={"orgCity"} label={"orgCity"} name={"orgCity"} dataTitle="City" />
                      <LegacyDropDownInput onChange={(e) => onChangeOrg(e)} selections={categorySelection} value={orgCategory} id={"orgCategory"} type={'text'} group={"orgCategory"} label={"orgCategory"} name={"orgCategory"} dataTitle="Category" />
                    </div>

                  </div>

                  <div className='flex justify-center pt-8 p-2' >
                      <button className="btn btn-wide btn-primar hover:bg-black">Join</button>
                  </div>

                </form>
              </div>
            ) : (<></>)} */}

          </div>
        </div>

        {/********************************************/}
        {/********************* FIRST PAGE MOBILE - END ***********************/}
        {/********************************************/}

      </div>
    </div>

    {/********************* MOBILE VERSION - END ***********************/}
    {/********************************************/}
    {/********************************************/}





    {/*********************** DESKTOP VERSION - START *************************/}
    {/********************************************/}
    {/********************************************/}

    <div className='lg:visible invisible max-sm:hidden w-screen h-screen flex flex-col max-sm:justify-start justify-start bg-[#fff9e1]'>


      {/* ----------------------- NAVIGATION BAR - START  ----------------------- */}
      <div className='flex justify-around py-3 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.67)] bg-[#16341fec]'>    
        <Link to="/">
            <div className='flex justify-center align-middle'>
                <h1 className='max-sm:text-2xl max-sm:font-light text-4xl font-thin text-white align-middle' >AsirisOS</h1>
                <h1 className='max-sm:pt-1 max-sm:text-xl max-sm:font-light text-2xl font-thin text-slate-600 pt-1 pl-3' ></h1>
            </div>
        </Link>
        
        <Link className=" text-slate-700 hover:bg-[#FFB100] hover:shadow-lg rounded-sm px-4 py-1 bg-[#FFC500] flex justify-center " to={"/login"} >
            <h1 className=' text-lg font-normal justify-center align-middle'>Login</h1>
        </Link>
      </div>
      {/* ----------------------- NAVIGATION BAR - END  ----------------------- */}



      <div className=' h-full flex flex-col justify-center items-center'>

        <div className=' px-10 pb-5 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.87)] bg-slate-100 rounded-xl' >

          <div className='pb-12' >
            <div className='rounded-b-md text-xl w-full py-2 text-white bg-[#16341fec] drop-shadow-xl '>
              <ul className="flex felx-row justify-around cursor-pointer">
                <li className={`    ' : ''}`}>{("Sign Up")} </li>
              </ul>
            </div>
          </div>

          {/* <div className='pb-8' >
            <ul className="flex felx-row justify-around cursor-pointer">

              <li onClick={() => toggleTab(1)}
                  className={` rounded-b-md text-xl w-full py-2 ${selection === 1 ? ' text-white bg-[#16341fec] shadow-[2px_1px_20px_3px_rgba(201,201,201,0.87)]' : ''}`}>{("Local Shopper")}
              </li>
              <li onClick={() => toggleTab(2)}
                  className={` rounded-b-md text-xl w-full py-2 ${selection === 2 ? ' text-white bg-[#16341fec] shadow-[2px_1px_20px_3px_rgba(201,201,201,0.87)]' : ''}`}>{("Organization")}
              </li>

            </ul>
          </div> */}
          
          {/* {selection === 1 ? ( */}
            <form onSubmit={onSubmitShopper}>

              <div className='flex justify-center ' >

                  <div className='max-w-2xl'>
                    <NumberInputInUnits onChange={(e) => onChangeShopper(e)} value={name} id={"name"} type={'text'} group={"name"} label={"name"} name={"name"} dataTitle="Name" />
                    <NumberInputInUnits onChange={(e) => onChangeShopper(e)} value={email} id={"email"} type={'text'} group={"email"} label={"email"} name={"email"} dataTitle="Email" />
                    <NumberInputInUnits onChange={(e) => onChangeShopper(e)} value={phone} id={"phone"} type={'text'} group={"phone"} label={"phone"} name={"phone"} dataTitle="Phone Number" />
                    <NumberInputInUnits onChange={(e) => onChangeShopper(e)} value={shopperZip} id={"shopperZip"} type={'text'} group={"shopperZip"} label={"shopperZip"} name={"shopperZip"} dataTitle="Zip code" />
                    <NumberInputInUnits onChange={(e) => onChangeShopper(e)} value={shopperCity} id={"shopperCity"} type={'text'} group={"shopperCity"} label={"shopperCity"} name={"shopperCity"} dataTitle="City" />
                  </div>

              </div>

              <div className='flex justify-center pt-8 p-2' >
                  <button className="rounded-xl drop-shadow-xl hover:drop-shadow-2xl bg-[#FFC500] py-3 font-semibold text-slate-700 px-12 text-xl shadow-[2px_1px_20px_3px_rgba(201,201,201,0.87) hover:bg-[#FFB100] hover:text-slate-70">Join</button>
              </div>

            </form>
          {/* ) : (<></>)}

          {selection === 2 ? (
            <form onSubmit={onSubmitOrg}>

              <div className='flex justify-center ' >

                <div className='max-w-xl px-5'>
                  <NumberInputInUnits onChange={(e) => onChangeOrg(e)} value={orgName} id={"orgName"} type={'text'} group={"orgName"} label={"orgName"} name={"orgName"} dataTitle="Organization Name" />
                  <NumberInputInUnits onChange={(e) => onChangeOrg(e)} value={orgEmail} id={"orgEmail"} type={'text'} group={"orgEmail"} label={"orgEmail"} name={"orgEmail"} dataTitle="Email" />
                  <NumberInputInUnits onChange={(e) => onChangeOrg(e)} value={orgPhone} id={"orgPhone"} type={'text'} group={"orgPhone"} label={"orgPhone"} name={"orgPhone"} dataTitle="Phone Number" />
                  <NumberInputInUnits onChange={(e) => onChangeOrg(e)} value={orgZip} id={"orgZip"} type={'text'} group={"orgZip"} label={"orgZip"} name={"orgZip"} dataTitle="Zip code" />
                  <NumberInputInUnits onChange={(e) => onChangeOrg(e)} value={orgCity} id={"orgCity"} type={'text'} group={"orgCity"} label={"orgCity"} name={"orgCity"} dataTitle="City" />
                  <LegacyDropDownInput onChange={(e) => onChangeOrg(e)} selections={categorySelection} value={orgCategory} id={"orgCategory"} type={'text'} group={"orgCategory"} label={"orgCategory"} name={"orgCategory"} dataTitle="Category" />
                </div>

              </div>

              <div className='flex justify-center pt-8 p-2' >
                  <button className="rounded-xl drop-shadow-xl hover:drop-shadow-2xl bg-[#FFC500] py-3 font-semibold text-slate-700 px-12 text-xl shadow-[2px_1px_20px_3px_rgba(201,201,201,0.87) hover:bg-[#FFB100] hover:text-slate-70">Join</button>
              </div>

            </form>
          ) : (<></>)} */}

        </div>


      </div>

    </div>
    {/*********************** DESKTOP VERSION - START *************************/}
    {/********************************************/}
    {/********************************************/}
    </>
  )
}

export default JoinTheWaitlist