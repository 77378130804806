import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import yieldService from "./yieldService";
import {FixMeLater} from "../../types/dev";
import {YieldState} from "../../types/states";
import HandleAxiosError from "../../utils/AxiosErrorHandler";
import {DefaultThunkApiConfig, Status} from "../../types/slice";
import {YieldModel} from "@asirisos/types";

const CONTEXT = "Yield"

const initialState: YieldState = {
    singleYield: undefined,
    yields: [],
    status: Status.Unknown,
    message: ''
}

export const addYield = createAsyncThunk<any, YieldModel, DefaultThunkApiConfig>('yield/add', async (yieldData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await yieldService.addYield(yieldData, account)
        if (response.status !== 201)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getYields = createAsyncThunk<YieldModel[], void, DefaultThunkApiConfig>('yield/getall', async (_, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await yieldService.getYields(account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getYield = createAsyncThunk<YieldModel, string, DefaultThunkApiConfig>('yield/getone/:yieldId', async (yieldId, thunkAPI) => {
    try {
        const response = await yieldService.getYield(yieldId)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const updateYield = createAsyncThunk<YieldModel, YieldModel, DefaultThunkApiConfig>('yield/updateyield/:yieldId', async (yieldData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if(!account)
            return thunkAPI.rejectWithValue();
            
        const response = await yieldService.updateYield(yieldData, account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
    // try {
    //     const token = thunkAPI.getState().auth.user.token
    //     return await yieldService.updateYield(yieldData, token)
    // } catch (error) {
    //     HandleAxiosError(error, CONTEXT);
    //     return thunkAPI.rejectWithValue("message")
    // }
})

export const deleteYield = createAsyncThunk<any, string[], DefaultThunkApiConfig>('yield/delete/:yieldId', async (yieldId, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if(!account)
            return thunkAPI.rejectWithValue();

        const response = await yieldService.deleteYield(yieldId, account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const yieldSlice = createSlice({
    name: 'yield',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(addYield.pending, (state: YieldState) => {
                state.status = Status.Pending
            })
            .addCase(addYield.fulfilled, (state: YieldState) => {
                state.status = Status.Success
            })
            .addCase(addYield.rejected, (state: YieldState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            .addCase(getYields.pending, (state: YieldState) => {
                state.status = Status.Pending
            })
            .addCase(getYields.fulfilled, (state: YieldState, action: PayloadAction<YieldModel[], any, any>) => {
                state.status = Status.Success
                state.yields = action.payload
            })
            .addCase(getYields.rejected, (state: YieldState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            .addCase(getYield.pending, (state: YieldState) => {
                state.status = Status.Pending
            })
            .addCase(getYield.fulfilled, (state: YieldState, action: PayloadAction<YieldModel, any, any>) => {
                state.status = Status.Success
                state.singleYield = action.payload
            })
            .addCase(getYield.rejected, (state: YieldState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            .addCase(updateYield.pending, (state: YieldState) => {
                state.status = Status.Pending
            })
            // .addCase(updateYield.fulfilled, (state: YieldState, action: PayloadAction<YieldModel, any, any>) => {
            //     state.status = Status.Success
            //     state.yields.map((yield: YieldModel) => yield._id === action.payload._id ?
            //         (state.yield = yield) : yield)
            //     state.yield = action.payload
            // })
            .addCase(updateYield.rejected, (state: YieldState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            // .addCase(updateYield.fulfilled, (state: YieldState, action: FixMeLater) => {
            //     state.isLoading = false
            //     state.yields.map((yield) => yield._id === action.payload._id ? (state.yield = yield) : yield)
            // })
            .addCase(deleteYield.pending, (state: YieldState) => {
                state.status = Status.Pending
            })
            .addCase(deleteYield.fulfilled, (state: YieldState) => {
                state.status = Status.Success
                state.singleYield = undefined
            })
            .addCase(deleteYield.rejected, (state: YieldState) => {
                state.status = Status.Failure
            })
    }
})

export const {reset} = yieldSlice.actions
export default yieldSlice.reducer