import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'
import {getFarm} from '../../../features/farms/farmSlice'
import {addFarmJoin, getAllFarmJoin, updateFarmJoin} from '../../../features/farm-join/farm-joinSlice'
import {toast} from 'react-toastify'
import Spinner from '../../components/Spinner'
import InlineEdit from '../../components/shared/InlineEdit'
import { FarmJoinModel } from '@asirisos/types'
// import {FarmJoinData} from "../../../types/data";
import {AppDispatch, RootState} from "../../../app/store";
import {Status} from "../../../types/slice";
import {useTranslation} from "react-i18next";

function FarmPage() {
    const {t} = useTranslation("farm")
    const {FarmId} = useParams()
    const AllJoinRequests = {
        FarmId: FarmId
    }

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (status === Status.Failure) {
            toast.error(t("farm:farmGetFailed"))
        }
        if (FarmId) {
            dispatch(getFarm(FarmId))
            dispatch(getAllFarmJoin(AllJoinRequests))
        }
    }, [dispatch, FarmId])

    const {farm, status} = useSelector((state: RootState) => state.farm)
    const {user} = useSelector((state: RootState) => state.auth)
    const {farmJoins} = useSelector((state: RootState) => state.farmJoin)
    console.log('farmJoins', farmJoins)

    const UserId = user?._id

    const [formData, setFormData] = useState({
        //   FarmId: '',
        name: '',
        location: '',
    })

    useEffect(() => {
        if (status !== Status.Pending && farm !== undefined) setFormData((prevState) => ({
            ...prevState,
            name: farm.name,
            location: farm.location,
        }));
    }, [status])

    const {name, location} = formData

    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        console.log(e.target)
        console.log(e)
        console.log('name', e.target.name)
        console.log('value', e.target.value)

        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))

    }

    const onKeyDown = (e: React.KeyboardEvent) => {
        console.log(e)
        if (e.key === "Enter" || e.key === "Escape") {
            console.log('Key Down Hit');
            (e.target as HTMLElement).blur();
        }
    }

    const onBlur = (e: React.FocusEvent) => {
        e.preventDefault()
        console.log('On Blur Hit')
        const farmData = {
            name, location, FarmId
        }
    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault()

        const farmJoinData: FarmJoinModel =
            {
                FarmId: FarmId!,
                UserId: UserId!
            }

        dispatch(addFarmJoin(farmJoinData))
        toast.success('Join Request Sent')
    }

    const onSubmitApprove = (e: FormEvent) => {
        const status = 'approved'
        const farmJoinId = (e.target as HTMLButtonElement).value
        const farmJoinData: FarmJoinModel = {
            FarmId: FarmId!,
            status,
            _id: farmJoinId
        }
        console.log('Approve Farm Join', farmJoinData)
        dispatch(updateFarmJoin(farmJoinData))
    }

    if (status === Status.Pending && farm?.name !== '' && farm?.location !== '') {
        return <Spinner/>
    } else {
        return (
            <>
                <div
                    className="bg-white py-5 rounded-lg #drop-shadow-xl #shadow-lg relative px-9 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]  ">

                    <div className="grid grid-cols-3 gap-3">
                        <div className='-my-1 col-span-1 justify-self-center self-center'>
                            <img className="h-36 w-34 bg-indigo-400 shadow-xl rounded-xl"
                                 src="https://www.pngmart.com/files/22/Robot-Arm-PNG-Free-Download.png" alt=""/>
                        </div>
                        <div className='col-span2 justify-start justify-self-start'>
                            <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                        onKeyDown={(e) => onKeyDown(e)} title={"Name"} value={name} id={"name"}
                                        name={"name"}/>
                            <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                        onKeyDown={(e) => onKeyDown(e)} title={"location"} value={location}
                                        id={"location"} name={"location"}/>
                        </div>
                    </div>
                    <button onClick={onSubmit}
                            className="px-3 py-2 border-2 border-indigo-500 text-black rounded-lg disabled:hover:bg-gray-100 disabled:text-gray-300 hover:bg-indigo-500 hover:text-white ">Join
                        Farm
                    </button>

                </div>

                {/* /////////////////////////////////////// */}
                {/* POLYON MAP */}
                {/* /////////////////////////////////////// */}
                <div className='pb-9'>
                    {/* <PolygonMap/> */}
                    
                </div>

                {/* /////////////////////////////////////// */}
                
                <div>
                    Join Requests
                    {farmJoins?.map((farmJoin) => {
                        return (
                            <>
                                <div className='border-2 border-black'>
                                    <h1>
                                        {`User ID ${farmJoin.UserId}`}
                                    </h1>
                                    <h1>
                                        {`Status ${farmJoin.status} `}
                                    </h1>
                                    <button onClick={onSubmitApprove} value={farmJoin._id}
                                            className="px-3 py-2 border-2 border-indigo-500 text-black rounded-lg disabled:hover:bg-gray-100 disabled:text-gray-300 hover:bg-indigo-500 hover:text-white ">Approve
                                    </button>
                                </div>
                                <div>

                                </div>
                            </>
                        )
                    })}
                </div>
            </>
        )
    }
}


export default FarmPage