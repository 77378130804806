import React, {} from 'react'
import Modal from 'react-modal'

function DeleteModal(props : {modalIsOpen: boolean, name?: string | undefined, onDeleteClick : React.MouseEventHandler<HTMLButtonElement>, onDelete: React.MouseEventHandler<HTMLButtonElement> } ) {
  return (
    <>
        <Modal isOpen={props.modalIsOpen} className="justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none" >
            <div className='max-w-1/4 bg-slate-50 w-1/2 h-1/4 rounded-xl flex flex-col justify-center items-center shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]' >
                {props.name !== undefined ? ( <>
                <h1 className='text-xl' > {`Are you sure you want to delete ${props.name}`}</h1>
                </>) : (<>
                <h1 className='text-xl' > {`Are you sure you want to delete selected items?`}</h1>
                </>)
                }
                <div className='' > 
                    <button className='px-4 py-3 border-2 mx-5 my-5 shadow-lg rounded-lg border-slate-400 hover:bg-slate-200'  
                    onClick={props.onDeleteClick}> Cancel</button>

                    <button className='px-4 py-3 border-2 mx-5 my-5 shadow-lg rounded-lg border-red-500 hover:bg-red-100 '  
                    onClick={props.onDelete}> Delete </button>
                </div>
            </div>
        </Modal>
    </>
  )
}

export default DeleteModal