import axios from "axios";
import {AccountModel, OrganizationModel} from "@asirisos/types";
import RequestBodyFactory from "../../factories/requestBodyFactory";

const API_URL = '/api/org-locations'

// Get Organizations
const getOrgLocations = async() => {
    return await axios.get(API_URL + '/getall' )
}

// Get Organization With Zip Code
const getOrgZipLocations = async(zip: String) => {
    console.log("organization location service - get by zip ", zip)
    const body = new RequestBodyFactory(zip).Build()
    console.log("organization service - sending data - add organization - body", body)
    return await axios.post(API_URL + '/byzip', body)
}


const orgLocationService = {
    getOrgLocations,
    getOrgZipLocations
}

export default orgLocationService