import React, {useState, useEffect, Fragment} from 'react'
import {useNavigate, Link, useLocation} from 'react-router-dom'
import { subscribeMqttTopic } from '../../../features/mqtt/mqttSubscribe'
import {toast} from 'react-toastify'

import mqtt from "mqtt";

function Success() {
    const [sensorData, setSeonsorData] = useState('')

    const navigate = useNavigate()

    const onShare = () => {
        if (navigator.share) {
            navigator.share({
                text: 'Check this thing out',
                url: 'https://asirisos.com'
            })
        } else {
            navigator.clipboard.writeText('Text for coppy')
            toast.success(("Link copied to clipboard"));
        }
    }

//     var options :any = {
//         protocol: "ws",
//         username: "esp32-mqtt-server",
//         password: "DigitalTest-45+rasmad?",
//         keepalive: 20,
//         clientId: "mqttjs_" + Math.random().toString(16).substr(2, 8),
//       };
//       var client = mqtt.connect("mqtt://24.144.93.208:8080", options);
       
//       client.subscribe("test");
//       console.log("Client subscribed ");

//       var note : any;
//       client.on("message", function (topic, message: any) {
//         note = message.toString();
//         const object = JSON.parse(note)
//         console.log(object.temperature);
//         console.log(object.readingId);
//         setSeonsorData(object.readingId)
//         console.log(sensorData)

//         // client.end();
//         setTimeout('20000')
//       });
      

//     useEffect(() => {
//         console.log("New Note")
//   }, [])

//   const onAlarmOn = () => {
//     const topic = 'downlink'
//     const message : any = '2'
//     client.publish(topic, message, options)
//     console.log('Alarm On')
//   }

//   const onAlarmOff = () => {
//     const topic = 'downlink'
//     const message : any = '1'
//     client.publish(topic, message, options)
//     console.log('Alarm Off')
//   }
      

       


    
  return (
    <>
    {/* MOBILE VERSION */}
    <div className='lg:hidden sm:visible' >

        <div className='h-screen flex flex-col'>

            {/********************************************/}
            {/********************* MENU BAR - START ***********************/}
            {/********************************************/}

            <div className='bg-[#16341fec] flex justify-around py-2 drop-shadow-md shadow-[2px_1px_20px_3px_rgba(201,201,201,0.97)] justify-items-start '> 
                <Link to="/">
                    <div className='flex flex-row justify-center py-2' >
                        <h1 className='text-md font-light text-white' >AsirisOS</h1>                        
                    </div>
                </Link>
                <Link className=" text-slate-700 hover:bg-[#FFB100] hover:shadow-lg rounded-sm px-4 py-1 bg-[#FFC500] flex justify-center " to={"/login"} >
                    <h1 className=' text-lg font-normal justify-center align-middle'>Login</h1>
                </Link>
            </div>

            {/********************************************/}
            {/********************* MENU BAR - END ***********************/}
            {/********************************************/}

            <div className='flex flex-col'>

                <div className='w-screen flex flex-col justify-start align-middle bg-[#ffffff] pt-20'>
                    <div className=' pb-6 max-sm:pb-10 flex flex-col justify-center items-center px-5'>
                        <h1 className='text-center font-bold text-5xl pt-1 underline text-[#16341fec] decoration-[#FFC500] animate-bounce'>Congrats!</h1>
                        <h2 className='text-center font-light text-md pt-2 text-slate-600'>Your sign-up was successfull</h2>
                        
                    </div>
                </div>

                <div className='flex flex-col justify-center items-center pt-8'>
                    <h2 className='text-center font-light text-2xl pt-2 text-slate-600'>You're <a className='underline'># 34</a> on the waitlist</h2>
                    <h2 className='text-center font-light text-md pt-2 text-slate-600 w-2/3'>We will reach out to you once your account is ready</h2>
                </div>

                <div className='pt-10'>
                    <h2 className='text-center font-normal text-xl pt-2 text-slate-600'>Share with your firends and family</h2>
                    <button className=' px-1 py-3 w-3/4 rounded-md bg-[#FFC500] mt-4' onClick={onShare}>
                        Share
                    </button>
                </div>
            </div>

        </div>

    </div>


    {/* DESKTOP VERSION */}
    <div className='lg:visible invisible max-sm:hidden items-center justify-center' >

        {/* ----------------------- NAVIGATION BAR  ----------------------- */}

        <div className='flex justify-around py-3 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.67)] bg-[#16341fec]'>    
            <Link to="/">
                <div className='flex justify-center align-middle'>
                    <h1 className='max-sm:text-2xl max-sm:font-light text-4xl font-thin text-white align-middle' >AsirisOS</h1>
                    <h1 className='max-sm:pt-1 max-sm:text-xl max-sm:font-light text-2xl font-thin text-slate-600 pt-1 pl-3' ></h1>
                </div>
            </Link>
            
            <Link className=" text-slate-700 hover:bg-[#FFB100] hover:shadow-lg rounded-sm px-4 py-1 bg-[#FFC500] flex justify-center " to={"/login"} >
                <h1 className=' text-lg font-normal justify-center align-middle'>Login</h1>
            </Link>
        </div>

        {/* ----------------------- NAVIGATION BAR  ----------------------- */}

        <div className='w-screen h-screen flex flex-col max-sm:justify-start justify-start bg-[#ffffff]'>
            <div className='h-screen pb-6 max-sm:pb-10 flex flex-col justify-center items-center px-5'>
                <h1 className='max-sm:py-1 max-sm:text-xl max-sm:font-thin text-center font-bold text-7xl pt-1 underline text-indigo-400 decoration-green-500 animate-bounce'>Congrats!</h1>
                <h2 className='max-sm:py-1 max-sm:text-xl max-sm:font-thin text-center font-light text-3xl pt-8 text-slate-600' >Your form has been successfully submitted</h2>
                {sensorData}
                {/* <button onClick={onAlarmOn} >
                    <h1>Alarm Off</h1>
                </button>
                <button onClick={onAlarmOff} >
                    <h1>Alarm On</h1>
                </button> */}
            </div>
        </div>

    </div>
    </>
  )
}

export default Success