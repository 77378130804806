import axios from "axios";
import {AccountModel, EquipmentModel} from "@asirisos/types";
import RequestBodyFactory from "../../factories/requestBodyFactory";

const API_URL = '/api/equipment'

const addEquipment = async (equipmentData: EquipmentModel, accountData: AccountModel) => {
    const body = new RequestBodyFactory(equipmentData).WithAccount(accountData).Build()

    return await axios.post(API_URL + '/', body)
}

const getAllEquipment = async (account: AccountModel) => {
    return await axios.get(API_URL + '/all/' + account.farm._id + '/' + account.user._id)
}

const getEquipment = async (equipmentId: string) => {
    return await axios.get(API_URL + '/' + equipmentId)
}

const updateEquipment = async (equipmentData: EquipmentModel, account: AccountModel) => {
    const {_id} = equipmentData
    const body = new RequestBodyFactory(equipmentData).WithAccount(account).Build();

    return await axios.put(API_URL + '/' + _id, body)
}

const deleteEquipment = async (equipmentId: string[]) => {
    const ids = equipmentId
    const config = {
        data: ids
    }
    return await axios.delete(API_URL + '/' + equipmentId, config )
}

const equipmentService = {
    addEquipment,
    getAllEquipment,
    getEquipment,
    updateEquipment,
    deleteEquipment
}

export default equipmentService