import React, {ChangeEvent} from 'react'
import { Link } from 'react-router-dom'
import SearchBox from '../Map/SearchBox-1'
import {useTabPersistence} from "../../../hooks/useTabPersistence";
import {useTranslation} from "react-i18next";
import {PageId} from "../../../types/pages";
import { useState } from 'react'
import axios from "axios";
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'app/store';
import {useNavigate} from 'react-router-dom'
import { uploadImage } from 'features/fileUpload/fileUploadSlice';
import {toast} from 'react-toastify'
// @ts-ignore
import {animations} from 'react-animation';
import {ReactTyped} from 'react-typed'
import DisplayAllFarmersMarkets from '../Map/DisplayAllFarmersMarkets';
import Button from "../../components/shared/Button"
import NumberInputInUnits from 'UI/components/shared/NumberInputInUnits';
import imageCompression from 'browser-image-compression';


function AsirisV2Landing() {

    const animation = require ('./farm_large.png')
    const animation2 = require ('./adaptive-icon.png')
    const vegetables_in_basket = require ('./vegetables_in_basket.jpg')
    const vegetable_market = require ('./vegetable_market.jpg')

    const {t} = useTranslation(["landing", "translation"])
    const {selection, toggleTab} = useTabPersistence(PageId.Equipment);

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()

      /////// IMAGE UPLOAD SECTION
      const [image, setImage] = useState({ preview: '', data: '' })


        //////////// IMAGE UPLOAD SECTION TEST FOR 2

        const [file, setFile] = useState<any>("")
        const [caption, setCaption] = useState("")

        const handleFileChange = async (e: any) => {

            const ImageFileBefore = e.target.files[0]

            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
              }

            try {
            const compressedFile = await imageCompression(ImageFileBefore, options);
            console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
            console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
        
            const img = {
                preview: URL.createObjectURL(compressedFile),
                data: compressedFile,}
    
                console.log('Image Print Size')
                setFile(compressedFile)

            } catch (error) {
            console.log(error);
            }
        }

        const onSubmit = async (e: React.FormEvent) => {
            e.preventDefault()

            const formData = new FormData();
            formData.append("image", file)
            formData.append("caption", caption)
            console.log("Checking FormData Section From Image Uplaod", formData )
            dispatch(uploadImage(formData))
                .unwrap()
                .then(() => {
                    toast.success(t("Image Uploaded"));
                    // navigate("/asiris-v2-reserve-success")
                })
                    .catch(() => {
                    toast.error(t("fail"))
                })

            // await axios.post("http://localhost:5000/api/image-upload/upload", formData, { headers: {'Content-Type': 'multipart/form-data'}})
        }



  return (
    <>

    {/***************************************************************************************************************************************************************************/}
    {/************************************************************************************ MOBILE VERSION START *****************************************************************/}
    {/************************************************************************************ MOBILE VERSION START *****************************************************************/}
    {/************************************************************************************ MOBILE VERSION START *****************************************************************/}
    {/************************************************************************************ MOBILE VERSION START *****************************************************************/}
    {/***************************************************************************************************************************************************************************/}

    <div className='lg:hidden sm:visible' >

        <div className='flex flex-col'>

            {/********************************************/}
            {/********************* FIRST PAGE MOBILE - START ***********************/}
            {/********************************************/}

            <div className='w-screen h-screen flex flex-col justify-start align-middle '>

                {/********************************************/}
                {/********************* MENU BAR - START ***********************/}
                {/********************************************/}
                <div className='bg-[#1763db] drop-shadow-md shadow-[2px_1px_20px_3px_rgba(201,201,201,0.97)]'>

                    <div className='  flex flex-col  px-3'> 

                        <Link to="/asiris-v2-landing" className='flex mt-2'>
                            <div className='flex flex-row ' >
                                <h1 className='text-2xl font-medium text-white' >AsirisOS</h1>                        
                            </div>
                        </Link>

                        <div className='flex pb-1  '>
                            <h1 className='max-w-xl text-sm font-light  text-white'> 
                                Teaching things that Schools should
                            </h1>
                        </div>

                    </div>

                </div>

                {/********************************************/}
                {/********************* MENU BAR - END ***********************/}
                {/********************************************/}


                {/********************************************/}
                {/********************* FIRST PAGE MOBILE - START ***********************/}
                {/********************************************/}

                <div className='flex flex-col justify-start h-full bg-gradient-to-b to-[#ffffff]   '>

                    <div className='flex justify-center px-6 py-10 mt-10'>
                        <div className='flex flex-col items-start pb-3'>                    
                            <h1 className='text-center font-semibold text-lg pt-2 text-black'></h1>
                            <div className='flex' style={{animation: animations.fadeInUp}}>
                                <h1 className='sm:py-1 max-sm:text-4xl max-sm:font-semibold text-center text-black pb-1'>Do you wish you were taught about <br/>
                                    <a className='font-semibold text-4xl text-[#1763db]'>
                                    <ReactTyped
                                        strings = {[
                                            "Taxes",
                                            "Child Care",
                                            "Personal Finances",
                                            "Mental Health",
                                            "Credit Cards",
                                            "Career Building"
                                        ]}                    
                                        typeSpeed = {70}
                                        backSpeed = {20}   
                                        loop
                                    />                                    
                                    </a>
                                    <br/> in School?</h1>
                            </div>
                        </div>
                    </div>




                    <div className='flex flex-col justify-center items-center pb-6'>    

        
                        <div className=' flex gap-5 bg-green-100 w-1/2' style={{animation: animations.fadeInUp}}>
                            <Link to={'/asiris-v2-landing-yes'} className='w-full'>
                                <button className=' drop-shadow-xl w-full hover:drop-shadow-2xl bg-[#f58217]  text-2xl  py-2 font-normal text-white px-14 hover:bg-[#1724db] hover:text-white'>
                                    Yes!
                                </button>
                            </Link>
                        </div>
                        
                    </div>

                    <div>

{/********************************************/}{/********************************************/}
{/********************************************/}{/********************************************/}
                        {/* UPLOAD SECTION */}
{/********************************************/}{/********************************************/}
{/********************************************/}{/********************************************/}
                    {/* <h1>Upload to server</h1>
                        {image.preview && <img src={image.preview} width='100' height='100' />}
                        <hr></hr>
                        <form onSubmit={handleImageSubmit} encType='multipart/form-data'>
                            <input type='file' name='image' onChange={handleFileChange}></input>
                            <button type='submit'>Submit</button>
                        </form>
                        {status && <h4>{status}</h4>} */}
                    </div>

                    {/* <div className='pt-10'>
                    <h1>Upload Image</h1>
                        {image.preview && <img src={image.preview} width='100' height='100' />}
                        <form className='flex flex-col px-10 gap-3 pt-5' onSubmit={submit}>
                            <input onChange={handleFileChange} type="file" accept="image/*"></input>
                            <input value={caption} onChange={e => setCaption(e.target.value)} type="text" placeholder='Caption'></input>
                        <button className='flex bg-[#f58217] text-white px-4 py-2 text-center' type="submit" onClick={submit}><h1>Submit</h1></button>
                        </form>
                        {status && <h4>{status}</h4>}
                    </div> */}
                    
                    <div className='pt-10'>
                        <h1 className='pb-5'>Version 2.0 of Image Upload</h1>
                        {image.preview && <img src={image.preview} width='100' height='100' />}
                        <form onSubmit={onSubmit} className=' px-5'>
                            <div className='flex flex-col justify-center gap-4' >
                                <input onChange={handleFileChange} type="file" accept="image/*"></input>
                                <input value={caption} onChange={e => setCaption(e.target.value)} type="text" placeholder='Caption'></input>
                            </div>

                            <div  className=' mt-6 mb-20'>
                                <button type='submit' style={{animation: animations.fadeInUp}} className=' flex justify-center drop-shadow-md shadow-[2px_1px_20px_3px_rgba(201,201,201,0.97)] w-full hover:drop-shadow-2xl bg-[#1763db]  py-3 font-normal text-white text-xl hover:bg-[#1741db] hover:text-white'>
                                    <h1>Upload Image</h1>
                                    <img className='w-6 pt-1 ml-2' src="https://media3.giphy.com/media/EtLdAZH3I56suXvQO9/giphy.gif?cid=6c09b95209o4gml4nwi7za7epihn9hf3qtkwiepr1plrd81e&ep=v1_stickers_related&rid=giphy.gif&ct=s" alt=" " />
                                </button>
                            </div> 
                        </form>
                    </div>



                </div>

            </div>

            {/********************************************/}
            {/********************* FIRST PAGE MOBILE - END ***********************/}
            {/********************************************/}


        </div>

    </div>

    {/********************************************/}
    {/********************************************/}
    {/************************************************************************************ MOBILE VERSION END *****************************************************************/}
    {/************************************************************************************ MOBILE VERSION END *****************************************************************/}
    {/************************************************************************************ MOBILE VERSION END *****************************************************************/}
    {/************************************************************************************ MOBILE VERSION END *****************************************************************/}
    {/********************************************/}
    {/********************************************/}









    {/********************************************/}
    {/********************************************/}
    {/************************************************************************************ DESKTOP VERSION START *****************************************************************/}
    {/************************************************************************************ DESKTOP VERSION START *****************************************************************/}
    {/************************************************************************************ DESKTOP VERSION START *****************************************************************/}
    {/************************************************************************************ DESKTOP VERSION START *****************************************************************/}
    {/********************************************/}
    {/********************************************/}

    <div className='lg:visible invisible max-sm:hidden items-center justify-center ' >

        {/********************************************/}
        {/********************* FIRST PAGE DESKTOP - START ***********************/}
        {/********************************************/}
    <div className='' >

        <div className='w-screen h-screen flex flex-col max-sm:justify-start justify-start bg-[#fff9e1]  '>

            {/* ----------------------- NAVIGATION BAR  ----------------------- */}

            <div className='flex justify-around py-3 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.67)] bg-[#16341fec]'>    
                <Link to="/">
                    <div className='flex justify-center align-middle'>
                        <h1 className='max-sm:text-2xl max-sm:font-light text-4xl font-thin text-white align-middle' >AsirisOS</h1>
                        <h1 className='max-sm:pt-1 max-sm:text-xl max-sm:font-light text-2xl font-thin text-slate-600 pt-1 pl-3' ></h1>
                    </div>
                </Link>
                
                <div className='flex gap-2'>

                    <Link className=" text-slate-700 hover:bg-[#FFB100] hover:shadow-lg rounded-sm px-4 py-1 bg-[#FFC500] flex justify-center  " to={"/join-the-waitlist"} >
                        <h1 className=' text-lg font-normal justify-center align-middle'>Sign up</h1>                        
                    </Link>

                    <Link className=" text-white hover:text-slate-700 hover:bg-[#FFB100] hover:shadow-lg rounded-sm px-4 py-1 border-2 border-[#FFC500] hover:border-[#FFB100] flex justify-center " to={"/login"} >
                        <h1 className=' text-lg font-normal justify-center align-middle'>Login</h1>
                    </Link>

                </div>

            </div>

            {/* ----------------------- NAVIGATION BAR END  ----------------------- */}

            <div className='  h-screen pb-6 max-sm:pb-10 flex flex-col justify-evenly items-center bg-gradient-to-b from-[#f9edbc] to-[#ffffff]'>
                <div className='w-full flex flex-row justify-center gap-5' >

                    <div className='px-3'>
                        <div className='items-start pb-4'>
                            <h1 className=' text-left font-semibold text-4xl pt-1 text-slate-700 max-w-xl'>
                                We make it easy to shop local and healthy foods
                            </h1>
                        </div>
                    
                        <div className='flex flex-col items-start pb-5'>
                            <h1 className='text-left font-light text-xl pt-2 max-w-2xl text-slate-600'>We create software for local food producers and consumers to build independent and healthy food systems for all.</h1>
                        </div>

                        <div className='pb-2'>
                            <h1 className='text-left font-semibold text-md pt-2 max-w-2xl text-slate-700'>Join 1,000+ local shoppers</h1>
                        </div>

                        <Link to={'/search-by-zip'}>
                            <div className=' flex self-align-bottom'>
                                <button className=' rounded-md drop-shadow-xl hover:drop-shadow-2xl bg-[#FFC500]  py-3 font-normal text-slate-700 px-4 text-xl hover:bg-[#FFB100] hover:text-slate-700'>
                                    Get Started!
                                </button>
                            </div>
                        </Link>
                    </div>

                    <div className='flex px-3'  >
                        <img className='rounded-sm right-0 max-w-lg drop-shadow-2xl' src={vegetable_market} alt="" />
                    </div>

                </div>


                {/********************************************/}
                {/********************* LOGO SECTION ***********************/}
                {/********************************************/}
                <div className='w-full flex justify-around align-middle'>
                    <div>
                        <a className='text-gray-400 font-bold text-4xl drop-shadow-sm '>USDA - Organic</a>
                    </div>

                    <div>
                        <a className='text-gray-400 font-bold text-4xl drop-shadow-sm '>Non-GMO</a>
                    </div>

                    <div>
                        <a className='text-gray-400 font-bold text-4xl drop-shadow-sm '>Pesticide-Free</a>
                    </div>
                </div>

            </div>

        </div>

        {/********************************************/}
        {/********************* FIRST PAGE DESKTOP - END ***********************/}
        {/********************************************/}


        {/********************************************/}
        {/********************* SECOND PAGE DESKTOP - START ***********************/}
        {/********************************************/}


        <div className=' bg-[#ffffff] max-sm:pb-10 flex flex-col ' >

            <div className='w-full h-full flex flex-col items-center justify-around pb-16 '>

                <div className='items-center pt-8'>
                    <h1 className=' text-center font-bold text-3xl text-slate-700 max-w-2xl'>
                        Discover 12,228 local organizations
                    </h1>
                </div>

                {/* *********************** TAB SELECTIONS *********************** */}

                <div className='mb-5  border-t-0 w-fit rounded-xl drop-shadow-lg pt-10 '>

                    <ul className="flex felx-row justify-left cursor-pointer">
                        <div onClick={() => toggleTab(1)} className={`${selection === 1 ? 'bg-[#16341fec] text-[#fff9e1]  pb-7  w-40 rounded-l-lg' : 'bg-[#fff9e1] text-[#16341fec]  pb-7  w-40 rounded-l-lg'}`}>
                            <div className='pt-5' >
                                <h1 className='text-xl font-bold'>6989</h1>
                                <h1 className='text-md font-light' >Farmers Markets</h1>
                            </div>
                        </div>

                        <div onClick={() => toggleTab(2)} className={`${selection === 2 ? 'bg-[#16341fec] text-[#fff9e1]  pb-7  w-40' : 'bg-[#fff9e1] text-[#16341fec] pb-7  w-40 '}`}>
                            <div className='pt-5' >
                                <h1 className='text-xl font-bold'>224</h1>
                                <h1 className='text-md font-light' >Co-op Groceries</h1>
                            </div>
                        </div>

                        <div onClick={() => toggleTab(3)} className={`${selection === 3 ? 'bg-[#16341fec] text-[#fff9e1]  pb-7  w-40' : 'bg-[#fff9e1] text-[#16341fec] pb-7  w-40 '}`}>
                            <div className='pt-5' >
                                <h1 className='text-xl font-bold'>1710</h1>
                                <h1 className='text-md font-light' >On-Farm Markets</h1>
                            </div>
                        </div>

                        <div onClick={() => toggleTab(4)} className={`${selection === 4 ? 'bg-[#16341fec] text-[#fff9e1]  pb-7  w-40' : 'bg-[#fff9e1] text-[#16341fec] pb-7  w-40 '}`}>
                            <div className='pt-5' >
                                <h1 className='text-xl font-bold'>994</h1>
                                <h1 className='text-md font-light' >CSAs</h1>
                            </div>
                        </div>

                        <div onClick={() => toggleTab(5)} className={`${selection === 5 ? 'bg-[#16341fec] text-[#fff9e1]  pb-7  w-40' : 'bg-[#fff9e1] text-[#16341fec] pb-7  w-40 '}`}>
                            <div className='pt-5' >
                                <h1 className='text-xl font-bold'>2141</h1>
                                <h1 className='text-md font-light' >CPG Brands</h1>
                            </div>
                        </div>

                        <div onClick={() => toggleTab(6)} className={`${selection === 6 ? 'bg-[#16341fec] text-[#fff9e1]  pb-7 rounded-r-md   w-40' : 'bg-[#fff9e1] text-[#16341fec] pb-7 rounded-r-md   w-40 '}`}>
                            <div className='pt-5' >
                                <h1 className='text-xl font-bold'>230</h1>
                                <h1 className='text-md font-light' >Food Hubs</h1>
                            </div>
                        </div>

                    </ul>
                </div>


                {/* *********************** TAB SELECTIONS *********************** */}

                {selection === 1 ? (
                <>
                <div className=' py-3 ' >
                    <h1 className='text-2xl font-normal' >Farmers Markets</h1>
                    <div className=''>
                        <h1 className='pt-2 text-lg font-light text-center max-w-2xl' >Discover and connect with your local farmers markets. Follow them to stay up-to date with latest news and your favourite local vendors.</h1>
                    </div>
                </div>
                </>
                ) : (<></>)}



                {selection === 2 ? (
                <>
                <div className=' py-3' >
                    <h1 className='text-2xl font-normal' >Co-op Groceries</h1>
                    <h1 className='pt-2 text-lg font-light text-center max-w-2xl' >Cooperative groceries are owned and managed by your local community to deliver you the best quality produce while supporting local communities.</h1>
                </div>
                </>
                ) : (<></>)}


                {selection === 3 ? (
                <>
                <div className=' py-3' >
                    <h1 className='text-2xl font-normal' >On-Farm Markets</h1>
                    <h1 className='pt-2 text-lg font-light text-center max-w-2xl'>Buy your food right where it grows. Visit your local farms that work hard to grow healthy and nutritious foods for you.</h1>
                </div>
                </>
                ) : (<></>)}



                {selection === 4 ? (
                <>
                <div className=' py-3' >
                    <h1 className='text-2xl font-normal' >CSA</h1>
                    <h1 className='pt-2 text-lg font-light text-center max-w-2xl' >CSAs allow you to purchase a share of farm's harvest to ensure that you get as much locally grown and nutritious food as you need.</h1>
                </div>
                </>
                ) : (<></>)}

                {selection === 5 ? (
                <>
                <div className=' py-3' >
                    <h1 className='text-2xl font-normal' >CPG Brands</h1>
                    <h1 className='pt-2 text-lg font-light text-center max-w-2xl' >Discover local brands that make unique consumer products like healthy snacks and natural cosmetics while supporting local economy</h1>
                </div>
                </>
                ) : (<></>)}

                {selection === 6 ? (
                <>
                <div className=' py-3' >
                    <h1 className='text-2xl font-normal' >Food Hubs</h1>
                    <h1 className='pt-2 text-lg font-light text-center max-w-2xl' >Food Hubs help local farms and food producers market and deliver their products to local consumers, making it easier for you to shop locally</h1>
                </div>
                </>
                ) : (<></>)}

            </div>

            <div className='flex flex-col  items-center bg-gradient-to-t from-[#fff3c4] to-[#ffffff] '   >

                <div className=' justify-self-center pt-6 pb-4'>
                    <h1 className=' text-center font-bold text-3xl text-slate-700 max-w-2xl'>
                        <a className='text-[#16341fec] font-bold '></a>Start in 3 simple steps
                    </h1>
                </div>

                <div className='flex flex-row justify-evenly  gap-3 py-7 pb-12'>

                    <div className='text-[#16341fec] bg-[#ffffff] w-1/6 drop-shadow-2xl rounded-lg pb-2   '>
                        <div>
                            <img className='rounded-t-lg' src={vegetables_in_basket} alt="" />
                        </div>
                        <div className='px-2 pt-3 flex flex-col '>
                            <h1 className='text-xl font-light pb-1'>Discover</h1>
                            <hr/>
                            <h1 className='text-sm font-bold pt-1 '>Find your local food producers</h1>
                        </div>
                    </div>

                    <div className='text-[#16341fec] bg-[#ffffff] w-1/6 drop-shadow-2xl rounded-lg pb-2 ' >
                        <div>
                            <img className='rounded-t-lg' src={vegetables_in_basket} alt="" />
                        </div>
                        <div className='px-2 pt-3 flex flex-col '>
                            <h1 className='text-xl font-light pb-1'>Connect</h1>
                            <hr/>
                            <h1 className='text-sm font-bold pt-1'>Follow your local food organizations</h1>
                        </div>
                    </div>

                    <div className='text-[#16341fec] bg-[#ffffff] w-1/6 drop-shadow-2xl rounded-lg pb-2 ' >
                        <div>
                            <img className='rounded-t-lg' src={vegetables_in_basket} alt="" />
                        </div>
                        <div className='px-2 pt-3 flex flex-col '>
                            <h1 className='text-xl font-light pb-1'>Shop</h1>
                            <hr/>
                            <h1 className='text-sm font-bold pt-1'>Start eating local and healthy food</h1>
                        </div>
                    </div>

                </div>

                <div className=' bg-[#FFC500] w-1/2  flex flex-row py-3 justify-center align-middle mt-3 mb-8 rounded-xl drop-shadow-xl  ' >

                    <div className='text-2xl text-slate-800 font-semibold px-8 py-1'>
                        <h1>Take your first step</h1>
                    </div>

                    <Link to={'/search-by-zip'}>
                        <div className=' flex self-align-bottom'>
                            <button className=' rounded-md drop-shadow-xl hover:drop-shadow-2xl bg-[#37774bec]  py-2 font-normal text-slate-50 px-4 text-xl hover:bg-[#2f6942] '>
                                Get Started!
                            </button>
                        </div>
                    </Link>

                </div>

            </div>
        </div>




        <div className='  bg-[#37774bec] text-[#fff9e1] flex flex-row py-4 justify-around align-middle  ' >
            <div>
                <Link to={"/"}>
                    <h1 className='text-xl py-1'>AsirisOS</h1>
                </Link>
            </div>

            <div className='flex gap-3 justify-start py-2'>
                <Link to={"/contact"}>
                    <h1 className='text-sm'> Contact</h1>            
                </Link>

                <Link to={"/organizations-join"}>
                    <h1 className='text-sm'>Organizations</h1>            
                </Link>

                <Link to={"/join-the-team"}>
                    <h1 className='text-sm'>Team</h1>            
                </Link>
            </div>

        </div>

        {/********************************************/}
        {/********************* SECOND PAGE DESKTOP - END ***********************/}
        {/********************************************/}
    </div>
    </div>
    {/********************************************/}
    {/********************************************/}
    {/************************************************************************************ DESKTOP VERSION END *****************************************************************/}
    {/************************************************************************************ DESKTOP VERSION END *****************************************************************/}
    {/************************************************************************************ DESKTOP VERSION END *****************************************************************/}
    {/************************************************************************************ DESKTOP VERSION END *****************************************************************/}
    {/********************************************/}
    {/********************************************/}
    </>
  )
}

export default AsirisV2Landing