import LargeTextInput from '../../components/shared/LargeTextInput'
import FormCard from 'UI/components/shared/FormCard'
import React, {useState, useEffect} from 'react'
import { getConnectionIdComments } from 'features/comments/commentSlice'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { addComment } from 'features/comments/commentSlice'
import {toast} from 'react-toastify'
import { CommentModel } from '@asirisos/types'
import {AppDispatch, RootState} from "../../../app/store";
import {useTranslation} from 'react-i18next'
import SingleComment from './SingleComment'
import DropDownInput from '../../components/shared/LegacyDropDownInput'
import FormTwoInputs from 'UI/components/shared/FormTwoInputs'
import { InputSelection } from 'types/ui'
import { useNavigate } from 'react-router-dom'

function ListOfComments() {

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()

    const {comments} = useSelector((state: RootState) => state.comment)


  return (
    
    <>
    {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}
    {/* ///////////////////////////////////    LIST OF ALL COMMENTS    ////////////////////////////////////////// */}
    {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}
    <div className='pt-10'>
            <h1>Existing Comments Will be found here</h1>

            {comments.map((comment) => (
                <>
                <SingleComment comment={comment} />
                {/* <SingleComment createdAt={comment.createdAt} commentText={comment.commentText} commenterName={comment.commenterAccountObject?.name} 
                employeeTag={comment.commentEmployeeConnectionAdded} connectionAdd={comment.commentConnectionAdded} connectionCategory={comment.commentConnectionCategory} /> */}
                </>
            ) )}
        </div>
    
    </>
    
  )
}

export default ListOfComments