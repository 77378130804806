import React, { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux'
import { FeatureGroup, MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import { Link } from "react-router-dom";
import { getOrgLocations } from "features/orgLocations/orgLocationsSlice";
import { getOrgFarmersMarkets } from "features/farmersMarkets/orgFarmersMarketsSlice";
import Spinner from '../../components/Spinner'
import {Status} from "../../../types/slice";
import { cities } from "data/cities";
import "leaflet/dist/leaflet.css";
import LeafletControlGeocoder from "./LeafletControlGeocoder";
import L from "leaflet";
// Searches
import SearchBox from '../Map/SearchBox-1'
import SearchableMap from '../Map/SearchableMap-1'

const onClick = () => {
  console.log('Pop Up Button Hit' )
}

const DEFAULT_RADIUS = 80

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png",
});

const CustomPopUp = ({data, setRadiusFilter}) => {

  const {organizationName, addressObject, organizationBadges, _id} = data.properties

  return(
    <>
    <Popup className="">

        <div className=" flex flex-col py-3 px-2 rounded-md text-gray-500 text-center justify-center" >
        <div className="flex flex-col text-start">
            <b className="text-xl font-light underline pb-2 ">
              {data?.organizationType === 'localFarmsAndRanches' ? <>Farm or Ranch</> : <>
                </>}
              {data?.organizationType === 'localCommunityGardens' ? <>Community Garden</> : <>
                </>}
              {data?.organizationType === 'seedOilFreeRestaurants' ? <>Seed Oil Free Restaurant</> : <>
                </>}
              {data?.organizationType === 'localMealPrep' ? <>Meal Prep</> : <>
                </>}
              {data?.organizationType === 'farmersMarket' ? <>Farmers Market</> : <>
                </>}
              {data?.organizationType === 'onFarmMarket' ? <>On-Farm Market</> : <>
              </>}
              {data?.organizationType === 'coopGroceries' ? <>Co-op Grocery</> : <>
              </>}
              {data?.organizationType === 'CSA' ? <>CSA</> : <>
              </>}
              {data?.organizationType === 'foodHub' ? <>Food Hub</> : <>
              </>}
              {data?.organizationType === 'cpgBrands' ? <>CPG Brand</> : <>
              </>}
              
            </b>
          </div>

          <div className="flex flex-col text-start">
            <b className="text-md font-bold">Name: </b>
            <b className="text-xl font-light">{`${organizationName}`}</b>
          </div>

          {/* <div className="flex flex-col text-start pt-4">
            <b className="text-lg font-bold">Address: </b>
            <b className="text-xl font-light" >{`${addressObject}`}</b>
          </div> */}

          {/* <div className="flex flex-col text-start pt-4">
            <b className="text-lg font-bold">Badges: </b>
            <b className="text-xl font-light" >{`${organizationBadges}`}</b>
          </div> */}
          
          <div className="flex justify-evenly gap-3 mt-7">
              <Link class="text-white py-2 px-5 rounded-md hover:bg-[#295b39ec] hover:shadow-lg bg-[#37774bec] " to={`/sign-up-v2`}>
                <h1 className="text-white text-lg" >Details</h1>
              </Link>
            
              <Link class="text-white  py-2 px-5 rounded-md hover:bg-[#295b39ec] hover:shadow-lg bg-[#37774bec] " to={`/sign-up-v2`}>
                <h1 className="text-white text-lg" >Follow</h1>
              </Link>            
          </div>

        </div>
    </Popup>
    </>
  )
}


const MarkerLayer = ({data}) => {

  let centerPoint 

  const farmersMarketIcon = L.icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/512/2776/2776067.png",
    iconSize: [25, 25],
  });

  const onFarmMarketIcon = L.icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/512/2776/2776067.png",
    iconSize: [25, 25],
  });

  const CSAIcon = L.icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/512/2776/2776067.png",
    iconSize: [25, 25],
  });

  const coopGroceryIcon = L.icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/512/2776/2776067.png",
    iconSize: [25, 25],
  });

  const foodHubIcon = L.icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/512/2776/2776067.png",
    iconSize: [25, 25],
  });

  
  return data.map(feature => {
    const {coordinates} = feature.geometry
    const {organizationType} = feature
      return (
        <>           

        {organizationType === 'farmersMarket' ? <></> : <>
          {coordinates[1] === null ? <></>  : <>
          <Marker key={String(coordinates)} position={[coordinates[1], coordinates[0]]} icon={farmersMarketIcon}>
            <CustomPopUp data={feature}/>
          </Marker>
          </>}
        </>}

        {organizationType === 'onFarmMarket' ? <></> : <>
          {coordinates[1] === null ? <></>  : <>
          <Marker key={String(coordinates)} position={[coordinates[1], coordinates[0]]} icon={onFarmMarketIcon}>
            <CustomPopUp data={feature}/>
          </Marker>
          </>}
        </>}

        {organizationType === 'coopGroceries' ? <></> : <>
          {coordinates[1] === null ? <></>  : <>
          <Marker key={String(coordinates)} position={[coordinates[1], coordinates[0]]} icon={CSAIcon}>
            <CustomPopUp data={feature}/>
          </Marker>
          </>}
        </>}

        {organizationType === 'CSA' ? <></> : <>
          {coordinates[1] === null ? <></>  : <>
          <Marker key={String(coordinates)} position={[coordinates[1], coordinates[0]]} icon={coopGroceryIcon}>
            <CustomPopUp data={feature}/>
          </Marker>
          </>}
        </>}

        {organizationType === 'foodHub' ? <></> : <>
          {coordinates[1] === null ? <></>  : <>
          <Marker key={String(coordinates)} position={[coordinates[1], coordinates[0]]} icon={foodHubIcon}>
            <CustomPopUp data={feature}/>
          </Marker>
          </>}
        </>}

        {organizationType === 'cpgBrands' ? <></> : <>
          {coordinates[1] === null ? <></>  : <>
          <Marker key={String(coordinates)} position={[coordinates[1], coordinates[0]]} icon={foodHubIcon}>
            <CustomPopUp data={feature}/>
          </Marker>
          </>}
        </>}

        </>
      )
  }
  )
} 

const icon = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});

function ResetCenterView(props) {
  const { selectPosition } = props;
  const map = useMap();

  useEffect(() => {
    if (selectPosition) {
      map.setView(
        L.latLng(selectPosition?.lat, selectPosition?.lon),
        map.getZoom(),
        {
          animate: true
        }
      )
    }
  }, [selectPosition]);

  return null;
}


export default function OrgsMap(props) {

  const { selectPosition, lat, lon, data } = props;
  const locationSelection = [selectPosition?.lat, selectPosition?.lon];

  return (
    <>
        <MapContainer center={[lat, lon]} zoom={10} minZoom={10}    style={{ width: "100%", height: "100%" }}>
          {/* Light Mode */}
          {/* <TileLayer url='https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.{ext}' minZoom= {10} maxZoom= {20}
            attribution= '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            ext= 'png'/> */}
          <TileLayer minZoom= {10} maxZoom= {20}
            url='https://api.mapbox.com/styles/v1/purpledragons/cl9kzio97001v14pe6ahvafbd/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicHVycGxlZHJhZ29ucyIsImEiOiJjbDlqMGp3cHkwZDJsM25wa2I2dnBxMXU2In0.m0DYm_idO-8_I7OeSBuMnQ'
            />
          <MarkerLayer data={data}/>
          {selectPosition && (
            <Marker position={locationSelection} icon={icon}>
              <Popup>
                Your Location
              </Popup>
            </Marker>
          )}
          <ResetCenterView selectPosition={selectPosition} />
        </MapContainer>
    </>
  );
}