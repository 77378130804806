import React, {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'
import {getFarmProfile} from '../../../features/farms/farmSlice'
import {toast} from 'react-toastify'
import Spinner from '../../components/Spinner'
import {AppDispatch, RootState} from "../../../app/store";
import {useTranslation} from "react-i18next";
import {Status} from "../../../types/slice";
import FarmProfileHeader from './FarmProfileHeader'

function FarmProfile() {
    const {FarmId} = useParams()
    const {t} = useTranslation("farm")
    const dispatch = useDispatch<AppDispatch>()
    const {farm, status} = useSelector((state: RootState) => state.farm)

    useEffect(() => {
        // if (status === Status.Failure) {
        //     toast.error(t("farm:farmGetFailed"))
        // }
        if (FarmId)
            dispatch(getFarmProfile(FarmId))
        // else{
        //     toast.error(t("farm:farmGetFailed"))
        // }

    }, [FarmId])

    if (status === Status.Failure) {
        toast.error(t("farm:farmGetFailed"))
    }

    if (status === Status.Pending) {
        return <Spinner/>
    } else {
        return (
            <>
            <div className='' >
            <FarmProfileHeader/>
            <div className='flex flex-row justify-center h-screen bg-gradient-to-t from-blue-400 to-transparent pt-20 ' >
            {/* <h1 className='text-3xl' >{`${farm?.name} wlcomes you to our profile page`}</h1>
            <h1 className='text-3xl' >{` We are located Here ${farm?.location}`}</h1> */}
            <div className='h-1/2 self-center pr-40 text-start'>
                <div>
                    <h1 className='  text-5xl font-thin' >Welcome to our Farm</h1>
                    <h1 className='  text-3xl font-thin' >where we grow local food with care and love</h1>
                </div>
                <div className='pt-12 ' >
                    <div className='flex justify-center mb-5 bg-gradient-to-r shadow-lg from-cyan-400 to-blue-300 py-3 rounded-sm hover:bg-gradient-to-r hover:from-cyan-500 hover:to-blue-300'>
                        <h1 className='font-light text-lg text-white'>ABOUT</h1>
                    </div>
                    <div className='flex justify-center mb-5 bg-gradient-to-r shadow-lg  from-blue-300 to-cyan-400 py-3 rounded-sm hover:bg-gradient-to-r hover:from-blue-300 hover:to-cyan-500'>
                        <h1 className='font-light text-lg text-white' >PRODUCTS</h1>
                    </div>
                </div>
            </div>
            <div className='pl-16 flex h-3/4 z-0' >
            <img className="shadow-2xl rounded-xl"
                src="https://images.unsplash.com/photo-1523741543316-beb7fc7023d8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80" alt=""/>
            </div>
            {/* <div className=' w-1/3 absolute bottom-32' >
            <img className="shadow-2xl rounded-xl"
                src="https://images.unsplash.com/photo-1532509774891-141d37f25ae9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" alt=""/>
            </div> */}
            </div>
            </div>
            </>
        )
    }
}


export default FarmProfile