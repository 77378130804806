import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {FixMeLater} from "../../types/dev";
import {OrgLocationState} from "../../types/states";
import HandleAxiosError from "../../utils/AxiosErrorHandler";
import {DefaultThunkApiConfig, Status} from "../../types/slice";
import { OrgMapLocationModel } from "@asirisos/types";
import orgLocationService from "./orgLocationsService";

const CONTEXT = "OrgLocation"

const initialState: OrgLocationState = {
    orgLocation: undefined,
    orgLocations: [],
    status: Status.Unknown,
    message: ''
}


export const getOrgLocations = createAsyncThunk<OrgMapLocationModel[], void, DefaultThunkApiConfig>('organization/getall', async (_, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await orgLocationService.getOrgLocations()
        console.log("Organization Slice - Get All - response", response)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getOrgZipLocations = createAsyncThunk<OrgMapLocationModel[], String, DefaultThunkApiConfig>('organization/getByZip', async (zip, thunkAPI) => {
    try {
        // const account = thunkAPI.getState().account.account;
        // if (!account)
        //     return thunkAPI.rejectWithValue();

        const response = await orgLocationService.getOrgZipLocations(zip)
        console.log("OrgZipLocation - Response From Organization Slice - Get By Zip - response.data", response.data)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})


export const orgLocationSlice = createSlice({
    name: 'orgLocation',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder
          
            .addCase(getOrgLocations.pending, (state: OrgLocationState) => {
                state.status = Status.Pending
            })
            .addCase(getOrgLocations.fulfilled, (state: OrgLocationState, action: PayloadAction<OrgMapLocationModel[], any, any>) => {
                state.status = Status.Success
                state.orgLocations = action.payload
            })
            .addCase(getOrgLocations.rejected, (state: OrgLocationState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            .addCase(getOrgZipLocations.pending, (state: OrgLocationState) => {
                state.status = Status.Pending
            })
            .addCase(getOrgZipLocations.fulfilled, (state: OrgLocationState, action: PayloadAction<OrgMapLocationModel[], any, any>) => {
                state.status = Status.Success
                state.orgLocations = action.payload
            })
            .addCase(getOrgZipLocations.rejected, (state: OrgLocationState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
    }
})

export const {reset} = orgLocationSlice.actions
export default orgLocationSlice.reducer