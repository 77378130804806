import React, {useState, ChangeEvent, FormEvent} from 'react'
import {useNavigate} from 'react-router-dom'
import {addFarm} from '../../../features/farms/farmSlice'
import {useSelector, useDispatch} from 'react-redux'
import {toast} from 'react-toastify'
import Spinner from '../../components/Spinner'
import {AppDispatch, RootState} from "../../../app/store";
import FormCard from '../../components/shared/FormCard'
import NumberInputInUnits from '../../components/shared/NumberInputInUnits'
import {useTranslation} from "react-i18next";
import {Status} from "../../../types/slice";
import SearchBox from '../Map/SearchBox-1'
import SearchableMap from '../Map/SearchableMap-1'
import {FarmModel} from "@asirisos/types";
import CheckBoxButtonSelection from 'UI/components/shared/CheckBoxButtonSelection'
import InlineEditCheckBox from 'UI/components/shared/InlineEditCheckBox'
import FormTwoInputs from 'UI/components/shared/FormTwoInputs'

function FarmAddForm() {
    const {t} = useTranslation("farm")
    const {status} = useSelector((state: RootState) => state.farm)
    const {user} = useSelector((state:RootState) => state.auth)

    const [selectPosition, setSelectPosition] = useState<any>()


    const [formData, setFormData] = useState({
        name: '',
        location: '',
        alias: '',
        addressObject: {},
        crops: 'false',
        liveStock: 'false',
        fieldCrops: 'false',
        vegetables: 'false',
        fruits: 'false',
        nuts: 'false',
        poultry: 'false',
        beef: 'false',
        hogs: 'false',
        eggs: 'false',
        otherAnimalProducts: 'false', 
        revenueFifty: 'false',
        revenueOneHundred: 'false',
        revenueThreeHundred: 'false',
        revenueFiveHundred: 'false',
        revenueOneMillion: 'false',
        revenueOverMillion: 'false',
    })

    const {name, location, alias, addressObject, crops, fieldCrops, vegetables, fruits, nuts, poultry, revenueFifty,revenueOneHundred, revenueOneMillion,
        revenueThreeHundred,revenueFiveHundred,revenueOverMillion, beef, hogs, eggs, otherAnimalProducts, liveStock} = formData
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()


    const onRoleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        if (e.target.value === 'false') {
            setFormData((prevState) => ({
                ...prevState,
                [e.target.name]: 'true'
            }))
        }

        if (e.target.value === 'true') {
            setFormData((prevState) => ({
                ...prevState,
                [e.target.name]: 'false'
            }))
        }
    }




    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }


    const onSetAddress = (selectPosition : object) => {
        setFormData((prevState) => ({
            ...prevState,
            addressObject: selectPosition!
        }) )
    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault()

        const farmData : FarmModel = {
            revenueFifty,
            revenueOneHundred,
            revenueThreeHundred,
            revenueFiveHundred,
            revenueOverMillion,
            revenueOneMillion,
            user,
            name,
            crops,
            location,
            alias,
            addressObject,
            liveStock,
            fieldCrops, vegetables, fruits, nuts, poultry, 
        beef, hogs, eggs, otherAnimalProducts,

        }

        dispatch(addFarm(farmData))
            .unwrap()
            .then(() => {
                toast.success(t("farm:farmAdded"))
                navigate('/')
            })
            .catch(() => {
                toast.error(t("farm:farmAddFailed"))
                navigate('/farm-add-form')
            })
    }

    if (status === Status.Pending) {
        return <Spinner/>
    }

    return (
        <>
            <>
                <FormCard title={t("farm:addFarm")}>
                    <form onSubmit={onSubmit}>
                        <div className='pb-8 w-1/2 ml-8' >
                            <NumberInputInUnits onChange={(e) => onChange(e)} value={name} id={"name"} type={'text'}
                                                group={"name"} label={"name"} name={"name"} dataTitle={t("farm:farmName")}/>
                        </div>   
                        
                        <h1 className= "pt-3 flex justify-start ml-10 text-md text-slate-500" >Location</h1>      

                        <div style={{ display: "flex", flexDirection: "row",  width: "80vw", height: "30vh" }} >
                            <div className="pl-8 flex flex-col justify-items-start" style={{ width: "50vw" }}>
                                <SearchBox  selectPosition={selectPosition} setSelectPosition={setSelectPosition} onSetAddress={onSetAddress} name={"addressObject"} value={addressObject} onChange={(e : any) => onChange(e) } />
                            </div>
                            <div style={{ width: "50vw", height: "100%" }}>
                                <SearchableMap lat ={selectPosition?.lat} lon={selectPosition?.lon} selectPosition={selectPosition} />
                            </div>
                        </div>

                
                        {/* //////////////////////////////// CROPS /////////////////////////////////////// */}
                    <div className='flex flex-col pt-6' >
                     

                        <div className='flex flex-row pt-6' >
                            
                            <CheckBoxButtonSelection onChange={(e) => onRoleChange(e)} value={crops} id={"crops"} name={"crops"} title={t("farm:crops")}   />

                            <CheckBoxButtonSelection onChange={(e) => onRoleChange(e)} value={fieldCrops} id={"fieldCrops"} name={"fieldCrops"} title={t("farm:fieldCrops")}/>

                            <CheckBoxButtonSelection onChange={(e) => onRoleChange(e)} value={vegetables} id={"vegetables"} name={"vegetables"} title={t("farm:vegetables")}/>

                            <CheckBoxButtonSelection onChange={(e) => onRoleChange(e)} value={fruits} id={"fruits"} name={"fruits"} title={t("farm:fruits")}/>

                            <CheckBoxButtonSelection onChange={(e) => onRoleChange(e)} value={nuts} id={"nuts"} name={"nuts"} title={t("farm:nuts")}/>

                        </div>

                        {/* //////////////////////////////// LIVE STOCK /////////////////////////////////////// */}


                        <div className='flex flex-row pt-6' >

                            <CheckBoxButtonSelection onChange={(e) => onRoleChange(e)} value={liveStock} id={"liveStock"} name={"liveStock"} title={t("farm:liveStock")}/>

                            <CheckBoxButtonSelection onChange={(e) => onRoleChange(e)} value={poultry} id={"poultry"} name={"poultry"} title={t("farm:poultry")}/>

                            <CheckBoxButtonSelection onChange={(e) => onRoleChange(e)} value={eggs} id={"eggs"} name={"eggs"} title={t("farm:eggs")}/>

                            <CheckBoxButtonSelection onChange={(e) => onRoleChange(e)} value={beef} id={"beef"} name={"beef"} title={t("farm:beef")}/>

                            <CheckBoxButtonSelection onChange={(e) => onRoleChange(e)} value={hogs} id={"hogs"} name={"hogs"} title={t("farm:hogs")}/>

                            <CheckBoxButtonSelection onChange={(e) => onRoleChange(e)} value={otherAnimalProducts} id={"otherAnimalProducts"} name={"otherAnimalProducts"} title={t("farm:otherAnimalProducts")}/>

                        </div>


                        {/* //////////////////////////////// REVENUE /////////////////////////////////////// */}


                        <div className='flex flex-row pt-6' >

                            <CheckBoxButtonSelection onChange={(e) => onRoleChange(e)} value={revenueFifty} id={"revenueFifty"} name={"revenueFifty"} title={t("farm:revenueFifty")}/>

                            <CheckBoxButtonSelection onChange={(e) => onRoleChange(e)} value={revenueOneHundred} id={"revenueOneHundred"} name={"revenueOneHundred"} title={t("farm:revenueOneHundred")}/>

                            <CheckBoxButtonSelection onChange={(e) => onRoleChange(e)} value={revenueThreeHundred} id={"revenueThreeHundred"} name={"revenueThreeHundred"} title={t("farm:revenueThreeHundred")}/>

                            <CheckBoxButtonSelection onChange={(e) => onRoleChange(e)} value={revenueFiveHundred} id={"revenueFiveHundred"} name={"revenueFiveHundred"} title={t("farm:revenueFiveHundred")}/>

                            <CheckBoxButtonSelection onChange={(e) => onRoleChange(e)} value={revenueOneMillion} id={"revenueOneMillion"} name={"revenueOneMillion"} title={t("farm:revenueOneMillion")}/>

                            <CheckBoxButtonSelection onChange={(e) => onRoleChange(e)} value={revenueOverMillion} id={"revenueOverMillion"} name={"revenueOverMillion"} title={t("farm:revenueOverMillion")}/>
                        
                        </div>
                      
                    </div>

                        <div className='flex justify-center pt-8 p-2'>
                            <button className="btn btn-wide btn-primar hover:bg-black">{t("farm:addFarm")}</button>
                        </div>
                    </form>
                </FormCard>
            </>
        </>
    )
}

export default FarmAddForm