import axios from "axios"
import {AccountModel, TaskModel} from "@asirisos/types";
import {TaskCategory} from "@asirisos/types/data";
import RequestBodyFactory from "../../factories/requestBodyFactory";

const API_URL = '/api/tasks'

const addTask = async (taskData: TaskModel, accountData: AccountModel) => {
    const body = new RequestBodyFactory(taskData).WithAccount(accountData).Build();
    return await axios.post(API_URL + '/', body)
}

const getTasks = async (account: AccountModel) => {
    return await axios.get(API_URL + '/all/' + account.farm._id + '/' + account.user._id)
}

const getCategoryTasks = async (taskCategory: TaskCategory, account: AccountModel) => {
    const body = new RequestBodyFactory(taskCategory).WithAccount(account).Build();
    return await axios.post(API_URL + '/getCategoryTasks', body)
}

const getTask = async (taskId: string) => {
    console.log('Task Service - get task - task Id', taskId)
    return await axios.get(API_URL + '/' + taskId)
}

const updateTask = async (taskData: TaskModel, account: AccountModel) => {
    const {_id} = taskData
    const body = new RequestBodyFactory(taskData).WithAccount(account).Build();
    return await axios.put(API_URL + '/' + _id, body)
}

const deleteTask = async (taskId: string[]) => {
    return await axios.delete(API_URL + '/' + taskId)
}

const taskService = {
    addTask,
    getTasks,
    getCategoryTasks,
    getTask,
    updateTask,
    deleteTask
}

export default taskService