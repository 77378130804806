import axios from "axios";
import {AccountModel, JoinOrgWaitlistModel, JoinShopperWaitlistModel} from "@asirisos/types";
import RequestBodyFactory from "../../factories/requestBodyFactory";

const API_URL = '/api/join-waitlist'

// Add Organization

const addJoinOrgWaitlist = async(joinOrgWaitlistData: JoinOrgWaitlistModel,) => {
    console.log("Join Team service - add join team -  join team data", joinOrgWaitlistData)
    const body = new RequestBodyFactory(joinOrgWaitlistData).Build()
    console.log("Join Team Market service - sending data - add join team - body", body)
    return await axios.post(API_URL + '/add-org', body)
}

const addJoinShopperWaitlist= async(joinShopperWaitlistData: JoinShopperWaitlistModel,) => {
    console.log("Join Team service - add join team -  join team data", joinShopperWaitlistData)
    const body = new RequestBodyFactory(joinShopperWaitlistData).Build()
    console.log("Join Team Market service - sending data - add join team - body", body)
    return await axios.post(API_URL + '/add-shopper', body)
}

const joinWaitlistService = {
    addJoinOrgWaitlist,
    addJoinShopperWaitlist
}

export default joinWaitlistService