import React from 'react'
import { Link } from 'react-router-dom'

const graphic = require ('./AgriPower2.png')

function AgriPowerLanding() {
  return (
    <>
        {/********************************************/}
        {/********************************************/}
        {/********************************************/}
        {/********************* MOBILE VERSION ***********************/}
        {/********************************************/}
        {/********************************************/}
        {/********************************************/}

        <div className='lg:hidden sm:visible' >


            {/********************************************/}
            {/********************* FIRST SCREEN ***********************/}
            {/********************************************/}

            <div className=' w-screen flex flex-col justify-start bg-[#1e2222] pb-10'>
                {/* <div className='bg-indigo-500 flex justify-around'>    
                    <div className='flex flex-row justify-center py-2' >
                        <h1 className='text-2xl font-light text-white' >AgriPower - </h1>
                        <h1 className='text-lg font-light text-white pt-1 pl-1' >your farm assistant</h1>
                    </div>
                    <Link className="w-1/5 my-1 rounded-tl-none rounded-br-none rounded-lg bg-indigo-500 border-2 border-green-500 flex justify-center " to={"/login"} >
                        <h1 className='text-white text-xl pt-1 font-bold'>Login</h1>
                    </Link>
                </div> */}

                <div className='text-start px-3'>
                    <h1 className=' text-5xl font-bold text-white pt-8' >Everything you need 
                    to <a className="underline text-indigo-300 decoration-green-500">digitize</a> and <a className="underline text-indigo-300 decoration-green-500">grow</a> your 
                    farm in <a className="underline text-indigo-300 decoration-green-500">one place</a> </h1>
                </div>

                <div className='flex flex-row justify-center items-center pt-5'>
                    {/* <img className='w-full justify-center items-center' src={animation} alt="" /> */}
                </div>

                <div className='pt-10 flex justify-center'>
                    <Link className="w-4/5 px-4 py-3 rounded-tl-none rounded-br-none rounded-lg bg-indigo-500 border-2 border-green-400 flex justify-center " to={"/sign-up-form"} >
                    
                        <h1 className='text-white text-2xl font-bold'>Free sign up</h1>
                        <i className="fa-solid fa-arrow-right fa-beat text-3xl text-green-500 pl-8"></i>
                    
                    </Link>
                </div>
            </div>
        </div>

        {/********************************************/}
        {/********************* FIRST SCREEN END ***********************/}
        {/********************************************/}

        {/********************************************/}
        {/********************************************/}
        {/********************************************/}
        {/********************* MOBILE VERSION ***********************/}
        {/********************************************/}
        {/********************************************/}
        {/********************************************/}





        {/********************************************/}
        {/********************************************/}
        {/********************************************/}
        {/********************* DESKTOP VERSION ***********************/}
        {/********************************************/}
        {/********************************************/}
        {/********************************************/}

        <div className='lg:visible invisible max-sm:hidden' >
                {/* ********************************* */}
                {/* FIRST SCREEN */}
                {/* ********************************* */}

                <div className=' w-screen h-screen flex flex-col max-sm:justify-start justify-start bg-[#ffff] bg-gradient-to-tl from-green-100 to-transparent'>

                    <div className='bg-green-500 flex justify-around'>    
                        <div className='flex flex-row justify-center py-4' >
                            <h1 className='max-sm:text-3xl max-sm:font-light text-5xl font-bols text-white' >AgriPower</h1>
                            {/* <h1 className='max-sm:pt-1 max-sm:text-xl max-sm:font-light text-2xl font-thin text-white pt-1 pl-3' >your farm assistant</h1> */}
                        </div>
                        <li className='flex flex-row gap-4 py-4'>
                        <Link className="px-5 my-1 border-white-500 " to={"/login"} >
                            <h1 className='text-gray-700 text-2xl font-semi-bold'>About</h1>
                        </Link>
                        <Link className=" px-5 my-1 border-white-500 " to={"/login"} >
                            <h1 className='text-gray-700 text-2xl font-semi-bold'>Contact</h1>
                        </Link>
                        <Link className=" px-5 my-1 border-white-500 " to={"/login"} >
                            <h1 className='text-gray-700 text-2xl font-semi-bold'>Sign In</h1>
                        </Link>
                        </li>
                    </div>
                    
                    <div className='pb-6 max-sm:pb-10 flex flex-col justify-center items-center'>

                        <div className='items-center pt-12 '>
                            <h1 className='max-sm:py-1 max-sm:text-xl max-sm:font-thin text-start font-bold text-6xl pt-1 text-gray-700'>We <a className="underline text-green-500 decoration-blue-500">connect</a> farmers and manufacturers
                            <br /> <a className="underline text-green-500 decoration-blue-500"></a> with <a className="underline text-green-500 decoration-blue-500"> government-sponsored</a> energy programs</h1>
                        </div>

                        {/* <Link className="hover:bg-green-400 mt-16 w-1/5 px-4 py-4
                            rounded-lg bg-green-500 border-1 border-slate-500 flex justify-center shadow-[2px_1px_40px_1px_rgba(201,201,201,0.20)] " to={"/sign-up-form"} >
                            <h1 className='text-white text-3xl font-bold hover:text-4xl'>Start here</h1>
                        </Link> */}

                        {/* <div className='pt-12 absolute bottom-0' >
                            <img className=""
                                src="https://images.squarespace-cdn.com/content/v1/63bc0049fac6b23f3a77e446/a8455aac-4214-4633-b464-fbd518163f4c/Screenshot+2023-07-04+at+10.17.16.png" alt=""/>
                        </div> */}

                        <img className=' w-3/5 justify-center items-center' src={graphic} alt="" />

                        <Link className="hover:bg-green-400 mt-16 w-1/5 px-4 py-4
                            rounded-lg bg-green-500 border-1 border-slate-500 flex justify-center shadow-[2px_1px_40px_1px_rgba(201,201,201,0.20)] " to={"/sign-up-form"} >
                            <h1 className='text-white text-3xl font-bold hover:text-4xl'>Start here</h1>
                        </Link>

                    </div>

                    <div className='flex flex-row justify-center items-center'>
                        {/* <img className='mt-4 max-sm:w-full w-5/12 justify-center items-center' src={animation} alt="" /> */}

                        {/* <div className='shadow-[2px_1px_70px_3px_rgba(201,201,201,0.40)] rounded-md w-1/4'>
                            <div className='bg-indigo-500 max-sm:mt-4 rounded-t-md py-4 px-6 '>
                                <h1 className='max-sm:py-1 max-sm:text-xl max-sm:font-thin font-light text-3xl pt-1  text-white'>1 YEAR FREE</h1>
                                <h1 className='max-sm:py-1 max-sm:text-xl max-sm:font-thin font-thin text-2xl text-white'>when you sign up today</h1>
                            </div>

                            <div className='bg-slate-200 rounded-md rounded-t-none'>
                                <form onSubmit={e => onSubmit(e)} className='flex-row py-10 '>
                                    
                                    <div className='py-8'>
                                        <input className="focus:ring-indigo-500 max-sm:w-3/4 max-sm:py-3 max-sm:rounded-md max-sm:mx-10 focus:border-indigo-500 w-11/12 py-4 pl-7 rounded-md text-xl border-none shadow-[2px_1px_10px_3px_rgba(201,201,201,0.47)]" placeholder='Email' onChange={onChange} name="email" value={email} id='email'/>    
                                    </div>

                                    <div className='py-3'>
                                        <input className="focus:ring-indigo-500 max-sm:w-3/4 max-sm:py-3 max-sm:rounded-md max-sm:mx-10 focus:border-indigo-500 w-11/12 py-4 pl-7 rounded-md text-xl border-none shadow-[2px_1px_10px_3px_rgba(201,201,201,0.47)]" placeholder='Phone' onChange={onChange} name="email" value={email} id='email'/>    
                                    </div>
                                        
                                    <div className='pt-10'>
                                        <button className="bg-indigo-500 max-sm:text-2xl max-sm:px-16 border-0 px-10 py-2 w-2/4 rounded-lg text-white text-2xl shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)] font-medium hover:bg-indigo-700 ">{t("organization:signup")}</button>
                                    </div>     

                                </form>
                            </div>
                        </div> */}

                    </div> 
                </div>
            </div>

        {/********************************************/}
        {/********************************************/}
        {/********************************************/}
        {/********************* DESKTOP VERSION END ***********************/}
        {/********************************************/}
        {/********************************************/}
        {/********************************************/}

    </>
  )
}

export default AgriPowerLanding