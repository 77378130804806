import axios from "axios";
import {AccountModel, StorageModel} from "@asirisos/types";
import RequestBodyFactory from "../../factories/requestBodyFactory";

const API_URL = '/api/storage'

// Add Storage

const addStorage = async (storageData: StorageModel, account: AccountModel) => {
    const body = new RequestBodyFactory(storageData).WithAccount(account).Build();
    return await axios.post(API_URL + '/add', body)
}

// Get Storage

const getAllStorage = async (account: AccountModel) => {
    return await axios.get(API_URL + '/all/' + account.farm._id)
}

// Get Single Storage

const getStorage = async (storageId: string) => {
    return await axios.get(API_URL + '/' + storageId)
}

// Update Storage

const updateStorage = async (storageData: StorageModel, account: AccountModel) => {
    const {_id} = storageData
    const body = new RequestBodyFactory(storageData).WithAccount(account).Build();
    return await axios.put(API_URL + '/update/' + _id, body)
}

// Delete Storage

const deleteStorage = async (storageId: string[], account: AccountModel) => {
    const ids = storageId
    const config = {
        data: {ids, account}
    }
    console.log('delete storage config', config)
    return await axios.delete(API_URL + '/' + storageId, config)
}

const storageService = {
    addStorage,
    getAllStorage,
    getStorage,
    updateStorage,
    deleteStorage
}

export default storageService