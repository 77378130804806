import React, {useState, useEffect} from 'react'
import ActivityTable from './ActivityTable'
import ActivityCategoryPieChart from './ActivityCategoryPieChart'
import {getAllActivity} from '../../../features/activity/activitySlice'
import ActivityTypePieChart from './ActivityTypePieChart'
import {useSelector, useDispatch} from 'react-redux'
import {useTranslation} from "react-i18next";
import {AppDispatch, RootState} from "../../../app/store";
import { Link } from 'react-router-dom'

function Activity() {

  const {allActivity, status} = useSelector((state : RootState) => state.activity )

  const {t} = useTranslation(["activity", "translation"])
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getAllActivity())
  }, [dispatch])


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////  TOGGLE PAGE - PAGE TABS   /////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const [toggleState, setToggleState] = useState(1)

const toggleTab = (index: any) => {
  setToggleState(index)
}
  return (
    <>
    <div className='px-5'>

  {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}
  {/* ///////////////////////////////////    TOGGLE PAGE TABS     ///////////////////////////////////////////// */}
  {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}

  <div className='pb-8 mb-6 rounded-lg bg-slate-100 drop-shadow-lg'>
    <ul className="flex felx-row justify-left cursor-pointer">
      <li onClick={() => toggleTab(1)}className={`rounded-t-lg px-12 py-2 ${toggleState === 1 ? 'bg-indigo-500 text-white' : ''}`}>Home</li>
      <li onClick={() => toggleTab(2)} className={`rounded-t-lg px-12 py-2 ${toggleState === 2 ? 'bg-indigo-500 text-white' : ''}`}>Activities Table</li>
    </ul>


    {/* ///////////////////////////////////    TAB 1 - STATS     ///////////////////////////////////////////// */}
    {toggleState === 1 ? (
      <div className='flex flex-row justify-around bg-white drop-shadow-lg rounded-tl-none py-8' >
        <div className=' bg-slate-200 rounded-lg px-10 py-4 shadow-[2px_1px_15px_2px_rgba(201,201,201,0.47)] ' >
          <h1 className='text-xl '>Activities By Category</h1>
          <ActivityCategoryPieChart/>
        </div>

        <div className=' bg-slate-200 rounded-lg px-10 py-4 shadow-[2px_1px_15px_2px_rgba(201,201,201,0.47)] ' >
          <h1 className='text-xl '>Activities By Type</h1>
          <ActivityTypePieChart/>
        </div>
      </div>
    ) : (<></>)}

    {/* ///////////////////////////////////    TAB 2 - TABLE     ///////////////////////////////////////////// */}

    {toggleState === 2 ? (
    <div className='bg-white drop-shadow-lg rounded-tl-none py-8' >
      <ActivityTable/>
    </div>
) : (<></>)}
  </div>

      <Link className="btn mb-8" to={"/activity-form"} >
        Add Activity
      </Link>
      </div>
    </>
  )
}

export default Activity