import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {deleteLiveStock, getAllLiveStock} from '../../../features/live-stock/live-stockSlice'
import Spinner from '../../components/Spinner'
import {DataGrid, GridColumns} from '@mui/x-data-grid';
import {AppDispatch, RootState} from "../../../app/store";
import {Status} from "../../../types/slice";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import TableMultiDeleteButton from 'UI/components/TableMultiDeleteButton';
import DeleteModal from 'UI/components/DeleteModal';
import {Link, useNavigate} from "react-router-dom";

const columns = (t: any): GridColumns => [
    {field: '_id', headerName: t("translation:id"), width: 70},
    {
        field: 'img',
        headerName: t("translation:user"),
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        width: 160,
        renderCell: (params) => {
            return (

                <div className='flex flex-row items-center'>
                    <img className=' rounded-xl  w-9 h-9 mr-4'
                         src="https://static.thenounproject.com/png/2717675-200.png"
                         alt=""/>
                    <div className='text-indigo'>
                        {params.row.name}
                    </div>
                </div>

            )
        }
    },

    {field: 'description', headerName: t("translation:description"), width: 230},
    {
        field: 'category',
        headerName: t("translation:category"),
        type: 'string',
        width: 90,
    },
    {
        field: 'quantity',
        headerName: t("translation:quantity"),
        type: 'number',
        width: 90,
    },
    {
        field: 'live-stock',
        headerName: t("translation:action"),
        width: 200,
        renderCell: (params) => {
            return (
                <div className='flex flex-row px-2 pl-4 justify-center items-center gap-2'>
                    <Link to={`/livestock/${params.row._id}`}
                          className="border-2 rounded-lg border-blue-500 px-2">{t("translation:view")}</Link>
                    <div className='border-2 rounded-lg border-red-400 px-2'>{t("translation:delete")}</div>
                </div>
            )
        }
    }
];


function LiveStockTable() {

    const navigate = useNavigate()
    const {t} = useTranslation(["livestock", "translation"])
    const dispatch = useDispatch<AppDispatch>()

const [modalIsOpen, setModalIsOpen] = useState(false)

const onDeleteClick = (e: any) => {
    setModalIsOpen(!modalIsOpen)
    console.log(modalIsOpen)
}

const [deleteEvent, setDeleteEvent] = useState<boolean>(true)
const [selectedRows, setSelectedRows] = useState<string[]>([])

const handleSelectionChange = (newSelectionModel : string[]) => {
    setSelectedRows(newSelectionModel)
}


const onDelete = () => {
    dispatch(deleteLiveStock(selectedRows))
    .unwrap()
    .then(() => {
        toast.success(t("liveStock:liveStockDeleted"));
        navigate("/liveStock")
        setModalIsOpen(false)
    })
    .catch(() => {
        toast.error(t("liveStock:failedToDeleteliveStock"))
    })
    setDeleteEvent(!deleteEvent)
    setDeleteEvent(!deleteEvent)
    setSelectedRows([])
}





    useEffect(() => {
        if (status !== Status.Pending || deleteEvent === true || deleteEvent === false)
            dispatch(getAllLiveStock())
    }, [dispatch, deleteEvent])

    const {allLiveStock, status} = useSelector((state: RootState) => state.livestock)

    if (status === Status.Failure)
        toast.error(t("livestock:failedGetLivestock"))

    if (status === Status.Pending)
        return <Spinner/>


    return (
        <>


        {selectedRows.length !== 0 ? (<>
        <TableMultiDeleteButton onDeleteClick={onDeleteClick} />
        </>) :(<></>) }


            <div style={{height: 400, width: 'full'}}>
                <DataGrid
                    getRowId={(row) => row._id}
                    rows={allLiveStock}
                    columns={columns(t)}
                    pageSize={10}
                    rowsPerPageOptions={[5]}
                    checkboxSelection
                    onSelectionModelChange={(newSelectionModel : any) => {
                        handleSelectionChange(newSelectionModel)
                      }}
                />
            </div>

            {/* ////////////////////////////////////////////////////////////////////// */}
            {/* //////////////////////////// DELETE MODAL //////////////////////////// */}

            <DeleteModal modalIsOpen={modalIsOpen} onDeleteClick={onDeleteClick} onDelete={onDelete} />

        </>
    )
}

export default LiveStockTable