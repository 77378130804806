import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import FormCard from '../../components/shared/FormCard'
import FormTwoInputs from '../../components/shared/FormTwoInputs'
import NumberInputInUnits from '../../components/shared/NumberInputInUnits'
import LargeTextInput from '../../components/shared/LargeTextInput'
import LegacyDropDownInput from '../../components/shared/LegacyDropDownInput'
import { getCrops } from '../../../features/crops/cropSlice'
import { getAllEquipment } from '../../../features/equipment/equipmentSlice'
import { addActivity } from '../../../features/activity/activitySlice'
import {AppDispatch, RootState} from "../../../app/store";
import {InputSelection} from "../../../types/ui";
import { getFarmAccounts } from 'features/accounts/accountSlice'
import { getAllLiveStock } from 'features/live-stock/live-stockSlice'
import { getAllInventory } from 'features/inventory/inventorySlice'
import { getAllStorage } from 'features/storage/storageSlice'
import { getCustomers } from 'features/customers/customerSlice'
import DropDownInput from '../../components/shared/LegacyDropDownInput'
import {ActivityModel, CropModel, EquipmentModel, FieldModel} from "@asirisos/types";
import { addField } from 'features/fields/fieldSlice'

function FieldForm() {
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    Initiate Hooks   /////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const dispatch = useDispatch<AppDispatch>()

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    USE EFFECT   /////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    useEffect(() => {
        dispatch(getCrops())
        dispatch(getFarmAccounts())
        dispatch(getAllEquipment())
        dispatch(getAllLiveStock())
        dispatch(getAllInventory())
        dispatch(getAllStorage())
        dispatch(getCustomers())
    },[dispatch] )

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    USE SELECTOR - CONNECTION SELECTION   ////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const {crops} = useSelector((state : RootState) => state.crop )
    const {equipments} = useSelector((state : RootState) => state.equipment )
    const {accounts} = useSelector((state: RootState) => state.account)
    const {allLiveStock} = useSelector((state : RootState) => state.livestock )
    const {allInventory} = useSelector((state : RootState) => state.inventory )
    const {allStorage} = useSelector((state: RootState) => state.storage )
    const {customers} = useSelector((state: RootState) => state.customer )


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  CONNECTION SELECTIONS - DROP DOWN   ////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const cropSelections = crops.map((crop: CropModel) => { return new InputSelection(crop.cropName, crop._id)})

    const equipmentSelections = equipments.map((equipment: EquipmentModel) => {return new InputSelection(equipment.name, equipment._id)})

    const employeeSelections = accounts.map((account) => {return {name: account.name, id:account._id}})

    const liveStockSelections = allLiveStock.map((livestock) => {return {name: livestock.name, id:livestock._id}})

    const inventorySelections = allInventory.map((inventory) => {return {name: inventory.name, id:inventory._id}})

    const storageSelections = allStorage.map((storage) => {return {name: storage.name, id:storage._id}})

    const customerSelections = customers.map((customer) => {return  new InputSelection(customer.firstName, customer._id)})


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    CONNECTION CATEGORY SELECTIONS      //////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const connectionSelection = [
        new InputSelection('crop', 'crop'),
        new InputSelection('live stock', 'live stock'),
        new InputSelection('storage', 'storage'),
        new InputSelection('employee', 'employee'),
        new InputSelection('equipment', 'equipment'),
        new InputSelection('inventory', 'inventory'),
        new InputSelection('customer', 'customer')]

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    FIELD TYPE SELECTIONS      ///////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const fieldTypeSelection = [
        new InputSelection('crop', 'crop'),
        new InputSelection('live stock', 'live stock'),
        new InputSelection('infrastructure', 'infrastructure')]


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    CONNECTION TOGGLE      ///////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const [connectionAdded, setConnectionAdded] = React.useState('false');

    const onToggleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const toggleValue = e.target.value

        if (toggleValue === 'true') { setConnectionAdded('false') }
        if (toggleValue === 'false') { setConnectionAdded('true') }

   }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  USE STATE - ACTIVITY FORM  /////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const [formData, setFormData] = useState({
        farmId: '',
        coordinates: [[]] ,
        connectionCategory: '',
        assetValue: '',
        description: '',
        fieldCategory: '',
        fieldName: '',
        employee: '',
        deadline: '',
        equipment:'',
        crop: '',
        liveStock: '',
        inventory: '',
        storage: '',
        customer: '',
        sale: '',
        expense: '',
        fieldType: '',
        latlngs: '',
        mapSection: '',
    })

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  STATE ENTRIES - DESTRUCTURE   //////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
    const {
      latlngs,
      mapSection,
      farmId,
      coordinates ,
      connectionCategory,
      assetValue,
      description,
      fieldName,
      employee,
      deadline,
      equipment,
      crop,
      liveStock,
      inventory,
      storage,
      customer,
      sale,
      expense,
      fieldType,
      fieldCategory
    } = formData


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  ON CHANGE   ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }) )
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  ON SUBMIT   ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        const fieldData : FieldModel = {
          assetValue,
          mapSection,
          latlngs,
          farmId,
          coordinates,
          connectionCategory,
          description,
          fieldName,
          employee,
          deadline,
          equipment,
          crop,
          liveStock,
          inventory,
          storage,
          customer,
          sale,
          expense,
          fieldCategory
        }
        console.log(fieldData)
        dispatch(addField(fieldData))
    }

    
  return (
    <>

    {/* //////////////////////////   New Field Form  /////////////////////////////// */}
    {/* /////////////////////////////////////////////////////////////////////////////// */}

    <FormCard title="Add Field">
        <form onSubmit={onSubmit}>
            <FormTwoInputs>
                <NumberInputInUnits onChange={(e) => onChange(e)} value={fieldName} id={"fieldName"} type={'text'} group={"fieldName"} label={"fieldName"} name={"fieldName"} dataTitle="Name" />
                <NumberInputInUnits onChange={(e) => onChange(e)} value={description} id={"description"} type={'text'} group={"description"} label={"description"} name={"description"} dataTitle="Description" />
            </FormTwoInputs>

            <div className='pt-4 mt-8 pb-3' >
                <label className="relative inline-flex items-center cursor-pointer">
                    <input type="checkbox" onChange={(e) => onToggleChange(e)} value={connectionAdded} className="sr-only peer"/>
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <h1 className='ml-3 text-slate-500 text-md' >Add Reference</h1>
                </label>
                
            {/* //////////////////////////   FIELD CONNECTION ////////////////////////////// */}
            {/* /////////////////////////////////////////////////////////////////////////////// */}


            {connectionAdded === 'true' ? (
                <>
                    <div className='' >

                    <FormTwoInputs>
                        <DropDownInput onChange={(e) => onChange(e)} selections={connectionSelection} value={connectionCategory} id={"connectionCategory"} type={'text'} group={"connectionCategory"} label={"connectionCategory"} name={"connectionCategory"} dataTitle="Category" />
                        {connectionCategory === 'crop' ? (
                            <>
                                <DropDownInput onChange={(e) => onChange(e)} selections={cropSelections} value={crop} id={"crop"} type={'text'} group={"crop"} label={"crop"} name={"crop"} dataTitle="Crop" />
                            </>) : (<></>) }

                        {connectionCategory === 'live stock' ? (
                            <>
                                <DropDownInput onChange={(e) => onChange(e)} selections={liveStockSelections} value={liveStock} id={"liveStock"} type={'text'} group={"liveStock"} label={"liveStock"} name={"liveStock"} dataTitle="Live Stock" />
                            </>) : (<></>) }

                        {connectionCategory === 'employee' ? (
                            <>
                                <DropDownInput onChange={(e) => onChange(e)} selections={employeeSelections} value={employee} id={"employee"} type={'text'} group={"employee"} label={"employee"} name={"employee"} dataTitle="Employee" />
                            </>) : (<></>) }

                        {connectionCategory === 'equipment' ? (
                            <>
                                <DropDownInput onChange={(e) => onChange(e)} selections={equipmentSelections} value={equipment} id={"equipment"} type={'text'} group={"equipment"} label={"equipment"} name={"equipment"} dataTitle="Equipment" />
                            </>) : (<></>) }

                        {connectionCategory === 'inventory' ? (
                            <>
                                <DropDownInput onChange={(e) => onChange(e)} selections={inventorySelections} value={inventory} id={"inventory"} type={'text'} group={"inventory"} label={"inventory"} name={"inventory"} dataTitle="Inventory" />
                            </>) : (<></>) }

                        {connectionCategory === 'storage' ? (
                            <>
                                <DropDownInput onChange={(e) => onChange(e)} selections={storageSelections} value={storage} id={"storage"} type={'text'} group={"storage"} label={"storage"} name={"storage"} dataTitle="Storage" />
                            </>) : (<></>) }

                        {connectionCategory === 'customer' ? (
                            <>
                                <DropDownInput onChange={(e) => onChange(e)} selections={customerSelections} value={customer} id={"customer"} type={'text'} group={"customer"} label={"customer"} name={"customer"} dataTitle="Customer" />
                            </>) : (<></>) }


                    </FormTwoInputs>
                    </div>
                </>) : (<></>) }
            </div>

            {/* //////////////////////////   NEW FIELD FORM - CONTINUED  /////////////////// */}
            {/* /////////////////////////////////////////////////////////////////////////////// */}

            <FormTwoInputs>
                <DropDownInput onChange={(e) => onChange(e)} selections={fieldTypeSelection} value={fieldType} id={"fieldType"} type={'text'} group={"fieldType"} label={"fieldType"} name={"fieldType"} dataTitle="Field Type" />
            </FormTwoInputs>

        <div className='flex justify-center pt-8 p-2' >
            <button className="btn btn-wide btn-primar hover:bg-black">Add Activity</button>
        </div>    
        </form>
    </FormCard>
</>
  )
}

export default FieldForm