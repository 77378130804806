import React, {ChangeEventHandler, FocusEventHandler, MouseEventHandler, KeyboardEventHandler} from 'react'

type InlineEditCheckBoxProps ={
  value? : string,
  name? : string,
  id? : string,
  title? : string,
  label? : string,
  onChange? : ChangeEventHandler<any>,
  onKeyDown? : KeyboardEventHandler<any>,
  onBlur? : FocusEventHandler<any>,
  onClick? : MouseEventHandler<any>,
 }

function InlineEditCheckBox({value, name, id, label, onChange, onKeyDown, onClick, onBlur} : InlineEditCheckBoxProps) {


  return (
    <>
        <div className="flex flex-row justify-center ">
            <input
                className="form-check-input appearance-none h-7 w-7 drop-shadow-md border border-gray-300 rounded-md bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="checkbox"
                value={value}
                name={name}
                id={id}
                checked={value === 'true' ? true : false}
                onChange={onChange}
                onClick={onClick}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                // onChange={onRoleChange}
                />
            <label
                className="form-check-label inline-block self-center text-gray-800 font-regular pl-3 text-xl">
                {label}
            </label>
        </div>
    </>
  )
}

export default InlineEditCheckBox