import React, { useState, useEffect} from "react";
import { MapContainer, TileLayer, FeatureGroup, Polygon, Popup, Marker, LayerGroup, LayersControl, } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from "react-i18next";
import {InputSelection} from "../../../types/ui";
import { useRef } from "react";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import LegacyDropDownInput from '../../components/shared/LegacyDropDownInput'
import LeafletControlGeocoder from "./LeafletControlGeocoder";
import FormCard from "UI/components/shared/FormCard";
import FormTwoInputs from "UI/components/shared/FormTwoInputs";
import LargeTextInput from "UI/components/shared/LargeTextInput";
import NumberInputInUnits from "UI/components/shared/NumberInputInUnits";
import { getCrops } from "features/crops/cropSlice";
import { addField } from "features/fields/fieldSlice";
import { getFields } from "features/fields/fieldSlice";
import { addInfrastructure, getInfrastructures } from "features/infrastructure/infrastructureSlice";
import { RootState } from "../../../app/store";
import {toast} from "react-toastify";
import { t } from "i18next";

const FarmPageLeafletPolygon = () => {

  const ref1 = useRef(null);
  const doClick = () => ref1.current?.scrollIntoView({behavior: 'smooth'})
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {t} = useTranslation(["field", "translation"], ["infrastructure", "translation"])


  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////    USE EFFECT   /////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    dispatch(getFields())
    dispatch(getInfrastructures())
  }, [dispatch])


  const {crops} = useSelector((state) => state.crop )

  const {fields, status} = useSelector((state) => state.field)
  const {infrastructures} = useSelector((state) => state.infrastructure)

  const cropSelections = crops.map((crop) => {return {name: crop.cropName, id:crop._id}})

  const categorySelection = [
    new InputSelection(t("field:crops"), 'crops'),
    new InputSelection(t("field:storage"), 'storage'),
    new InputSelection(t("field:livestock"), 'livestock'),
    new InputSelection(t("field:inventory"), 'inventory'),
    new InputSelection(t("field:equipment"), 'equipment'),
    new InputSelection(t("field:personal"), 'personal')]

  const mapObjectSelection = [
    new InputSelection(t("field:infrastructure"), 'Infrastructure'),
    new InputSelection(t("field:field"), 'Field')]

  const connectionSelection = [
    new InputSelection('crop', 'crop'),
    new InputSelection('live stock', 'live stock'), 
    new InputSelection('storage', 'storage'),
    new InputSelection('employee', 'employee'),
    new InputSelection('equipment', 'equipment'),
    new InputSelection('inventory', 'inventory'),
    new InputSelection('customer', 'customer')]


  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////    USE STATE - FIELD   //////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [mapObjectCategory, setMapObjectCategory] = useState("")

  const onObjectCategoryChange = (e) => {
    setMapObjectCategory(e.target.value)
    console.log("Object Category", mapObjectCategory)
  }


  const [formData, setFormData] = useState({
    name: '',
    farmId: "",
    mapSection: [],
    // coordinates: string[][],
    assetValue: "",
    category: '',
    totalArea: "",
    totalAreaUnit: '',
    description: "",
    connectionCategory: "" ,
    fieldName: "" ,
    fieldType: "" ,
    employee: "" ,
    deadline: "" ,
    equipment: "",
    crop: "" ,
    liveStock: "" ,
    inventory: "" ,
    storage: "" ,
    customer: "" ,
    sale: "" ,
    expense: "" ,
    
  })

  const { name,  description, connectionCategory , fieldName , fieldType , employee , deadline , assetValue, totalArea, totalAreaUnit, category,
    equipment, crop , liveStock , inventory , storage , customer , sale , expense , mapSection } = formData


  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////    ON CHANGE   //////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const onChange = (e) => {
    setFormData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value
    }))
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////    ON SUBMIT   //////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const onSubmit = async (e) => {
  e.preventDefault()
    
  if (mapObjectCategory === "Field") {
    const fieldData = {
      fieldName: name,
      fieldCategory: category,
      description,
      assetValue,
      totalArea,
      totalAreaUnit,
      mapSection: polygon1,
      
    }

    dispatch(addField(fieldData))
      .unwrap()
      .then(() => {
        toast.success(t("fieldAddSuccess:fieldAddSuccess"));
      })
      .catch(() => {
          toast.error(t("fieldAddFailed:fieldAddFailed"))
      })
  }

  if (mapObjectCategory === "Infrastructure") {
    const infrastructureData = {
      infrastructurename: name,
      infrastructureCategory: category,
      description,
      assetValue,
      totalArea,
      totalAreaUnit,
      mapSection: polygon1,
    }

    dispatch(addInfrastructure(infrastructureData))
    .unwrap()
    .then(() => {
        toast.success(t("infrastructureAddSuccess:infrastructureAddSuccess"));
    })
    .catch(() => {
        toast.error(t("infrastructureAddFailed:infrastructureAddFailed"))
    })
  }
  }
  const [center, setCenter] = useState({ lat: 36.985206, lng: -120.292997 });
  const [mapLayers, setMapLayers] = useState([]);
  const [polygon1, setPolygon] = useState([])

  const ZOOM_LEVEL = 15;
  const mapRef = useRef();

  let polygonCoordinates = []

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////    ON CREATE MAP OBJECT   ///////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const _onCreate = (e) => {
    doClick()

    const { layerType, layer } = e;
    if (layerType === "polygon") {
      const { _leaflet_id } = layer;

      setMapLayers((layers) => [
        ...layers,
        { id: _leaflet_id, latlngs: layer.getLatLngs()[0] },
      ]);
    }

    let coordinateArray = layer.getLatLngs()[0]
    if (coordinateArray.length !== 0) {
      coordinateArray.map( (coordinate) =>  {
        let singleArray = []
        singleArray.push(coordinate.lat)
        singleArray.push(coordinate.lng)
        polygonCoordinates.push(singleArray)
        setPolygon((coords) => [
          ...coords,
          singleArray
        ] )
      })
    }
  };


  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////    ON EDIT MAP OBJECT   /////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  const _onEdited = (e) => { 
    const {
      layers: { _layers },
    } = e;

    Object.values(_layers).map(({ _leaflet_id, editing }) => {
      setMapLayers((layers) =>
        layers.map((l) => 
        
        l.id === _leaflet_id
          ? { ...l, latlngs: { ...editing.latlngs[0] } }
          : l
        )
      );
    });
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////    ON DELETE MAP OBJECT   ///////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const _onDeleted = (e) => {  
    const {
      layers: { _layers },
    } = e;

    Object.values(_layers).map(({ _leaflet_id }) => {
      setMapLayers((layers) => layers.filter((l) => l.id !== _leaflet_id));
    });
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////    MAP OBJECT OPTIONS   /////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const purpleOptions = { color: 'purple' }
  const redOptions = { color: 'blue', weight: 1, dashArray: 'polygon', opacity: 0.8, fill: true, fillOpacity: 0.1 }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////    RETURN OF THE OBJECT   ///////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div className="" >
        <div className="" >

          {/* //////////////////////////////////////////////////////////////////////////// */}
          {/* //////////////////////////   MAP DISPLAY   ///////////////////////////////// */}
          {/* //////////////////////////////////////////////////////////////////////////// */}

          <MapContainer center={center} zoom={ZOOM_LEVEL} ref={mapRef}>
              
            <FeatureGroup>
              <EditControl
                position="topright"
                onCreated={_onCreate}
                onEdited={_onEdited}
                onDeleted={_onDeleted}
                draw={{
                  rectangle: true,
                  polyline: true,
                  circle: true,
                  circlemarker: false,
                  marker: true,
                }} />
            </FeatureGroup>

            <TileLayer url='https://api.mapbox.com/styles/v1/purpledragons/cl9kzio97001v14pe6ahvafbd/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicHVycGxlZHJhZ29ucyIsImEiOiJjbDlqMGp3cHkwZDJsM25wa2I2dnBxMXU2In0.m0DYm_idO-8_I7OeSBuMnQ'
            maxZoom= {20}/>


            <TileLayer url='https://api.mapbox.com/styles/v1/purpledragons/cl9kzio97001v14pe6ahvafbd/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicHVycGxlZHJhZ29ucyIsImEiOiJjbDlqMGp3cHkwZDJsM25wa2I2dnBxMXU2In0.m0DYm_idO-8_I7OeSBuMnQ'
            maxZoom= {20}/>

            {/* <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright"></a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/> */}
            
            <TileLayer url='https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
            maxZoom= {20}/>

            <LayersControl position="topright">
              <LayersControl.Overlay name="Fields">
                <LayerGroup>
                  {fields?.map(((field) => (
                      <Polygon pathOptions={redOptions} positions={field?.latlngs}>
                        <Popup>
                          A pretty CSS3 popup. <br /> Easily customizable. Field ID
                          <h1>{field?._id}</h1>
                          <h1>{field?.description}</h1>
                        </Popup>
                      </Polygon>
                  ) ))}
                </LayerGroup>
              </LayersControl.Overlay>
              <LayersControl.Overlay name="Infrastructure">
                <LayerGroup>
                  {fields?.map(((field) => (
                    (field.fieldType === "Infrastructure" ?
                    <>
                      <Polygon pathOptions={redOptions} positions={field?.latlngs}>
                        <Popup>
                          A pretty CSS3 popup. <br /> Easily customizable. Field ID
                          <h1>{field?._id}</h1>
                        </Popup>
                      </Polygon>
                    </> : <></>)
                  ) ))}
                </LayerGroup>
              </LayersControl.Overlay>

              <LayersControl.Overlay name="Crops">
                <LayerGroup>
                  {fields?.map(((field) => (
                    (field.fieldType === "Crops" ?
                    <>
                      <Polygon pathOptions={redOptions} positions={field?.latlngs}>
                        <Popup>
                              A pretty CSS3 popup. <br /> Easily customizable. Field ID
                              <h1>{field?._id}</h1>
                        </Popup>
                      </Polygon>
                      </> : <></>)
                  ) ))}
                </LayerGroup>
              </LayersControl.Overlay>
            </LayersControl>
            <LeafletControlGeocoder/>
          </MapContainer>
        </div>

        {/* //////////////////////////////////////////////////////////////////////////// */}
        {/* //////////////////////////   FORM RETURN   ///////////////////////////////// */}
        {/* //////////////////////////////////////////////////////////////////////////// */}

        <div className="pt-5" >
          <div ref={ref1} className="red"></div>
          {mapLayers.length !== 0 ? (<>
            <div>
                <FormCard title ="Add Field">
                  <form onSubmit={(e) => onSubmit(e)}>
                    <FormTwoInputs>
                      <LegacyDropDownInput onChange={onObjectCategoryChange} selections={mapObjectSelection} value={mapObjectCategory}
                                                        id={"mapObjectCategory"} type={'text'} group={"mapObjectCategory"} label={"mapObjectCategory"} name={"mapObjectCategory"}
                                                        dataTitle={t("translation:category")}/>
                    </FormTwoInputs>
                    {mapObjectCategory === 'Infrastructure' ? (<> 
                      <FormTwoInputs>
                        <NumberInputInUnits onChange={(e) => onChange(e) } value={name} id={"name"} type={'text'} group={"name"} label={"name"} name={"name"} dataTitle={t("field:infrastructureName")} />
                        
                        <LegacyDropDownInput onChange={(e) => onChange(e)} selections={categorySelection} value={category}
                                                      id={"category"} type={'text'} group={"category"} label={"category"} name={"category"}
                                                      dataTitle={t("translation:category")}/>
                      </FormTwoInputs>

                      <div className='mx-16' >
                        <LargeTextInput onChange={(e) => onChange(e)} value={description} id={"description"} type={'text'} group={"description"} label={"description"}
                            name={"description"} dataTitle={t("translation:description")} />
                      </div>

                      <FormTwoInputs>
                        <NumberInputInUnits onChange={(e) => onChange(e) } value={assetValue} id={"assetValue"} type={'text'} group={"assetValue"} label={"assetValue"} name={"assetValue"} dataTitle={t("field:assetValue")} />

                        <NumberInputInUnits denominations={['select', 'ac', 'ha', 'sq.ft']}
                                                dataTitle={t("field:value")} dName={"totalAreaUnit"}
                                                dValue={totalAreaUnit} dId={"totalAreaUnit"}
                                                dType={'string'}
                                                onChange={(e) => onChange(e)} value={totalArea.toString()}
                                                id={"totalArea"} type={'number'} name={"totalArea"}/>
                        
                      </FormTwoInputs>

                      <div className='flex justify-center pt-6 p-2'>
                        <button className="btn btn-wide btn-primar hover:bg-black">{t("field:addInfrastructure")}</button>
                      </div>
                    </>) : (<></>) }

                    {mapObjectCategory === 'Field' ? (<> 
                      <FormTwoInputs>
                        <NumberInputInUnits onChange={(e) => onChange(e) } value={fieldName} id={"fieldName"} type={'text'} group={"fieldName"} label={"fieldName"} name={"fieldName"} dataTitle={t("field:fieldName")} />
                        
                        <LegacyDropDownInput onChange={(e) => onChange(e)} selections={categorySelection} value={fieldType}
                                                      id={"fieldType"} type={'text'} group={"fieldType"} label={"fieldType"} name={"fieldType"}
                                                      dataTitle={t("translation:category")}/>
                      </FormTwoInputs>

                      <div className='mx-16' >
                        <LargeTextInput onChange={(e) => onChange(e)} value={description} id={"description"} type={'text'} group={"description"} label={"description"}
                            name={"description"} dataTitle={t("translation:description")} />
                      </div>

                      <FormTwoInputs>
                        <NumberInputInUnits onChange={(e) => onChange(e) } value={assetValue} id={"assetValue"} type={'text'} group={"assetValue"} label={"assetValue"} name={"assetValue"} dataTitle={t("field:assetValue")} />

                        <NumberInputInUnits denominations={['select', 'ac', 'ha', 'sq.ft']}
                                                dataTitle={t("field:value")} dName={"totalAreaUnit"}
                                                dValue={totalAreaUnit} dId={"totalAreaUnit"}
                                                dType={'string'}
                                                onChange={(e) => onChange(e)} value={totalArea.toString()}
                                                id={"totalArea"} type={'number'} name={"totalArea"}/>
                        
                      </FormTwoInputs>

                      <div className='flex justify-center pt-6 p-2'>
                      <button className="btn btn-wide btn-primar hover:bg-black">{t("field:addField")}</button>
                    </div>
                    </>) : (<></>) }

                    
                   
                  </form>
                </FormCard>
            </div>
          </>) : (<> </>) }
        </div> 
      </div>
    </>
  );
};

export default FarmPageLeafletPolygon;