import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import testService from "./testService";
import {FixMeLater} from "../../types/dev";
import {KanbanSate} from "../../types/states";
import HandleAxiosError from "../../utils/AxiosErrorHandler";
import {ColumnModel, TaskModel} from "@asirisos/types";

const CONTEXT = "Test"

const initialState: KanbanSate = {
    task: undefined,
    tasks: [],
    columns: [],
    columnOrder: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}

export const addTaskToColumn = createAsyncThunk<TaskModel, FixMeLater>('test/addtasktocol', async (newTaskData: TaskModel, thunkAPI: FixMeLater) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await testService.addTaskToColumn(newTaskData, token)
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue("message")
    }
})

export const getAllTest = createAsyncThunk<FixMeLater>('test/getall', async (_, thunkAPI: FixMeLater) => {
    try {
        console.log('making Tasks request in Slice')
        const token = thunkAPI.getState().auth.user.token
        return await testService.getAllTest(token)
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue("message")
    }
})

export const getAllColumns = createAsyncThunk<FixMeLater>('test/getcolumns', async (_, thunkAPI: FixMeLater) => {
    try {
        console.log('making Columns request in Slice')
        const token = thunkAPI.getState().auth.user.token
        return await testService.getAllColumns(token)
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue("message")
    }
})

export const getColumnOrder = createAsyncThunk<FixMeLater>('test/getcolumnorder', async (_, thunkAPI: FixMeLater) => {
    try {
        console.log('making ColumnOrder request in Slice')
        const token = thunkAPI.getState().auth.user.token
        return await testService.getColumnOrder(token)
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue("message")
    }
})

export const addColumn = createAsyncThunk<ColumnModel, FixMeLater>('test/addcolumn', async (column: ColumnModel, thunkAPI: FixMeLater) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await testService.addColumn(column, token)
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue("message")
    }
})

export const updateColumn = createAsyncThunk<ColumnModel, FixMeLater>('test/updatecolumn', async (column: ColumnModel, thunkAPI: FixMeLater) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await testService.updateColumn(column, token)
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue("message")
    }
})

export const testSlice = createSlice({
    name: 'test',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllTest.pending, (state: KanbanSate) => {
                state.isLoading = true
            })
            .addCase(getAllTest.fulfilled, (state: KanbanSate, action: FixMeLater) => {
                state.isLoading = false
                state.isSuccess = true
                state.tasks = action.payload
            })
            .addCase(getAllTest.rejected, (state: KanbanSate, action: FixMeLater) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getAllColumns.pending, (state: KanbanSate) => {
                state.isLoading = true
            })
            .addCase(getAllColumns.fulfilled, (state: KanbanSate, action: FixMeLater) => {
                state.isLoading = false
                state.isSuccess = true
                state.columns = action.payload
            })
            .addCase(getAllColumns.rejected, (state: KanbanSate, action: FixMeLater) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getColumnOrder.pending, (state: KanbanSate) => {
                state.isLoading = true
            })
            .addCase(getColumnOrder.fulfilled, (state: KanbanSate, action: FixMeLater) => {
                state.isLoading = false
                state.isSuccess = true
                state.columnOrder = action.payload
            })
            .addCase(getColumnOrder.rejected, (state: KanbanSate, action: FixMeLater) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(updateColumn.fulfilled, (state: KanbanSate, action: FixMeLater) => {
                state.columns = action.payload
            })
            .addCase(addColumn.pending, (state: KanbanSate) => {
                state.isLoading = true
            })
            .addCase(addColumn.fulfilled, (state: KanbanSate, action: FixMeLater) => {
                state.columns = action.payload.columns
                state.columnOrder = action.payload.updatedColumnOrder2
                state.isLoading = false
            })
            .addCase(addTaskToColumn.fulfilled, (state: KanbanSate, action: FixMeLater) => {
                state.columns = action.payload.data.newColumns
                state.tasks = action.payload.data.newTasks
                state.isLoading = false
            })
    }
})

export const {reset} = testSlice.actions
export default testSlice.reducer