import React from 'react'

function ContactFarmersMarket() {
  return (
    <>
        <div>
            <h1> Contact </h1>
            <h2>Phone Number</h2>
            <h2>Email</h2>
        </div>
        <div>
            <h1>Socials</h1>
            <h2>TikTok</h2>
            <h2>Insta</h2>
            <h2>YouTube</h2>
        </div>
    </>
  )
}

export default ContactFarmersMarket