import React, {useEffect, ChangeEvent, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Spinner from '../../components/Spinner'
import {useParams} from 'react-router-dom'
import InlineEdit from '../../components/shared/InlineEdit'
import {AppDispatch, RootState} from "../../../app/store";
import {useTranslation} from "react-i18next";
import {Status} from "../../../types/slice";
import { getEmployeeAccount, updateEmployeeAccount } from 'features/accounts/accountSlice'
import InlineEditCheckBox from 'UI/components/shared/InlineEditCheckBox'
import ChangeActivityList from '../Activity/ChangeActivityList'
import { getActivityByConnectionId } from 'features/activity/activitySlice'
import {EmployeeModel} from "@asirisos/types";

function EmployeePage() {

    const [toggleState, setToggleState] = useState(1)

  const toggleTab = (index: any) => {
    setToggleState(index)
  }

    const {accountID}  = useParams()
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        
        if (accountID !== undefined )
            dispatch(getEmployeeAccount(accountID))
            dispatch(getActivityByConnectionId(accountID!))
    }, [dispatch, accountID])

    const {tasks} = useSelector((state: RootState) => state.taskTask)
    const {employeeAccount, status } = useSelector((state: RootState) => state.account)
    const {allActivity} = useSelector((state: RootState) => state.activity)
    const {t} = useTranslation("employee")

    const [permissionsView, setPermissionsView] = useState(false)

    const onPermissionClick = () => {
        setPermissionsView(!permissionsView)
    }

    const account = employeeAccount

    const [roleFormData, setRoleFormData] = useState({
        //   FarmId: '',
        farms: 'false',
        crops: 'false',
        joinApprovals: 'false',
        liveStock: 'false',
        employees: 'false',
        equipment: 'false',
        inventory: 'false',
        tasksRole: 'false',
        activity: 'false',
        customers: 'false',
        sales: 'false',
        storage: 'false',
        joinApprovalsEdit: 'false',
        farmsEdit: 'false',
        cropsEdit: 'false',
        liveStockEdit: 'false',
        employeesEdit: 'false',
        equipmentEdit: 'false',
        inventoryEdit: 'false',
        tasksEdit: 'false',
        activityEdit: 'false',
        customersEdit: 'false',
        salesEdit: 'false',
        storageEdit: 'false'
    })

    const {
        farms,
        crops,
        liveStock,
        employees,
        equipment,
        inventory,
        tasksRole,
        joinApprovals,
        activity,
        customers,
        sales,
        storage,
        joinApprovalsEdit,
        farmsEdit,
        cropsEdit,
        liveStockEdit,
        employeesEdit,
        equipmentEdit,
        inventoryEdit,
        tasksEdit,
        activityEdit,
        customersEdit,
        salesEdit,
        storageEdit,
    } = roleFormData


    const onRoleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {

        // console.log(e.target)
        // console.log(e, 'e')
        // console.log('name', e.target.name)
        // console.log('value', e.target.value)
        

        if (e.target.value === 'false') {
            setChangedData(() => ({
                field: 'access'
            }))

            setRoleFormData((prevState) => ({
                ...prevState,
                [e.target.name]: 'true'
            }))
            // Blur(e)
        }
       

        if (e.target.value === 'true') {
            setChangedData(() => ({
                field: 'access'
            }))

            setRoleFormData((prevState) => ({
                ...prevState,
                [e.target.name]: 'false'
            }))
            // Blur(e)
        }

        // console.log(typeof (e.target.value))
        const target = e.target as HTMLInputElement
        // console.log(target, 'target')
        // console.log(roleFormData)

    }

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        date_of_hire: '',
        job_title: '',
        recruitment_details: '',
        salary: '',
        qualifications: '',
        access: roleFormData
    })
    

    if (status === Status.Failure) {
        toast.error(t("employee:employeeGetFailed"))
    }

    //////////// CHANGED DATA STATE ///////////

    const [changedData, setChangedData] = useState({
        field: ''
    })

    const {field} = changedData
    let changedField : string

    useEffect(() => {
        if (status !== Status.Pending && account !== undefined) setFormData((prevState) => ({
            ...prevState,
            name: account.name,
            email: account.email,
            phone: account.phone,
            date_of_hire: account.date_of_hire,
            job_title: account.job_title,
            recruitment_details: account.recruitment_details,
            salary: account.salary,
            qualifications: account.qualifications,
        }));
    }, [status])

    
    useEffect(() => {
        if (status !== Status.Pending && account !== undefined) setRoleFormData((prevState) => ({
            ...prevState,
            farms: account.accessObject.farms,
            crops: account.accessObject.crops,
            liveStock: account.accessObject.liveStock,
            employees: account.accessObject.employees,
            equipment: account.accessObject.equipment,
            inventory: account.accessObject.inventory,
            tasksRole: account.accessObject.tasksRole,
            joinApprovals: account.accessObject.joinApprovals,
            activity: account.accessObject.activity,
            customers: account.accessObject.customers,
            sales: account.accessObject.sales,
            storage: account.accessObject.storage,
            joinApprovalsEdit: account.accessObject.joinApprovalsEdit,
            farmsEdit: account.accessObject.farmsEdit,
            cropsEdit: account.accessObject.cropsEdit,
            liveStockEdit: account.accessObject.liveStockEdit,
            employeesEdit: account.accessObject.employeesEdit,
            equipmentEdit: account.accessObject.equipmentEdit,
            inventoryEdit: account.accessObject.inventoryEdit,
            tasksEdit: account.accessObject.tasksEdit,
            activityEdit: account.accessObject.activityEdit,
            customersEdit: account.accessObject.customersEdit,
            salesEdit: account.accessObject.salesEdit,
            storageEdit: account.accessObject.storageEdit,
        }
        ));
    }, [status])

    const {name, email, phone, date_of_hire, job_title, recruitment_details, access, salary, qualifications} = formData

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setChangedData(() => ({
            field: e.target.name 
        }))
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))

    }

    const accessChangeSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        console.log('On Access Change Submitted Hit')
        const employeeData : EmployeeModel = {
            name,
            email, 
            phone,
            date_of_hire,
            job_title,
            recruitment_details,
            salary,
            qualifications,
            _id: accountID,
            access: roleFormData,
            changedField: changedData.field
        }

        dispatch(updateEmployeeAccount(employeeData))
            .unwrap()
            .then(() => {
                toast.success(t("employee:employeeUpdated"))
            })
            .catch(() => {
                toast.error(t("employee:employeeUpdateFailed"))
            })
    }
    const onKeyDown = (e: React.KeyboardEvent) => {
        console.log(e)
        if (e.key === "Enter" || e.key === "Escape") {
            console.log('Key Down Hit');
            (e.target as HTMLElement).blur();
        }
    }

    const onBlur = (e: React.FocusEvent) => {
        e.preventDefault()
        console.log('On Blur Hit')
        const employeeData : EmployeeModel = {
            name,
            email, 
            phone,
            date_of_hire,
            job_title,
            recruitment_details,
            salary,
            qualifications,
            _id: accountID,
            access: roleFormData,
            changedField: changedData.field
        }

        dispatch(updateEmployeeAccount(employeeData))
            .unwrap()
            .then(() => {
                toast.success(t("employee:employeeUpdated"))
            })
            .catch(() => {
                toast.error(t("employee:employeeUpdateFailed"))
            })
    }

    let FindTasks = tasks.filter(task => task.assignedToObject?.name === "Osman")

    if (status === Status.Pending && name != '' && email != '') {
        return <Spinner/>
    } else {
    return (
        <>
            <div className='pb-8 mb-6 rounded-lg bg-slate-100 drop-shadow-lg mx-5'>
              <ul className="flex felx-row justify-left cursor-pointer border-b-2 border-slate-700">
                <li onClick={() => toggleTab(1)}className={`rounded-t-lg px-12 py-2  ${toggleState === 1 ? 'bg-indigo-500 text-white border-2 border-slate-700 ' : ''}`}>Home</li>
                <li onClick={() => toggleTab(2)} className={`rounded-t-lg px-12 py-2 ${toggleState === 2 ? 'bg-indigo-500 text-white border-2 border-slate-700 ' : ''}`}>Access</li>
                {/* <li onClick={() => toggleTab(3)} className={`rounded-t-lg px-12 py-2 ${toggleState === 3 ? 'bg-indigo-500 text-white' : ''}`}>Tasks</li>
                <li onClick={() => toggleTab(4)} className={`rounded-t-lg px-12 py-2 ${toggleState === 4 ? 'bg-indigo-500 text-white' : ''}`}>Activity</li> */}
            </ul>

            {toggleState === 1 ? (
               <div className='' >
               
             
            <div
                className="bg-white py-5  #drop-shadow-xl #shadow-lg relative px-9 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]  ">

                <div className="grid grid-cols-3 gap-3">
                    <div className='-my-1 col-span-1 justify-self-center self-center'>
                        <img className="h-36 w-34 bg-indigo-400 shadow-xl rounded-xl"
                             src="https://cdn-icons-png.flaticon.com/512/843/843349.png" alt=""/>
                    </div>
                    <div className='col-span-2 justify-start justify-self-start  '>
                        
                        <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                    onKeyDown={(e) => onKeyDown(e)} title={t("translation:name")} value={name}
                                    id={"name"}
                                    name={"name"}/>
                        <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                    onKeyDown={(e) => onKeyDown(e)} title={t("account:email")} value={email}
                                    id={"email"}
                                    name={"email"}/>
                        <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                    onKeyDown={(e) => onKeyDown(e)} title={t("translation:phone")} value={phone}
                                    id={"phone"}
                                    name={"phone"}/>
                        <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                    onKeyDown={(e) => onKeyDown(e)} title={t("translation:date_of_hire")} value={date_of_hire}
                                    id={"date_of_hire"}
                                    name={"date_of_hire"}/>
                        <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                    onKeyDown={(e) => onKeyDown(e)} title={t("translation:job_title")} value={job_title}
                                    id={"job_title"}
                                    name={"job_title"}/>
                        {/* <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                    onKeyDown={(e) => onKeyDown(e)} title={t("translation:recruitment_details")} value={recruitment_details}
                                    id={"recruitment_details"}
                                    name={"recruitment_details"}/> */}
                        <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                    onKeyDown={(e) => onKeyDown(e)} title={t("translation:salary")} value={salary}
                                    id={"salary"}
                                    name={"salary"}/>
                        <InlineEdit onBlur={(e) => onBlur(e)} onChange={(e) => onChange(e)}
                                    onKeyDown={(e) => onKeyDown(e)} title={t("translation:qualifications")} value={qualifications}
                                    id={"qualifications"}
                                    name={"qualifications"}/>

                    </div>
                    
                </div>

                {permissionsView ? (<>


{account?.accessObject?.crops ? ( <>

<div className='mt-5  bg-white rounded-lg py-5  px-4 ' >
    {/* <button className='bg-white rounded-lg py-5 hover:bg-indigo-200  px-4 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]' onClick={onPermissionClick} >Close Permission View</button> */}


{/* Employee Access Selections */}
<div className='flex justify-center pt-6 '>
            <div className='flex flex-col bg-white justify-center w-1/2 rounded-lg  px-4 mb-8 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]'>

                <div className='flex flex-row justify-end' >
                    <button className='border-indigo-500 border-2 text-indigo-600 w-10 rounded-lg mt-4 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]
                    hover:text-white hover:border-2 hover:border-indigo-500 hover:bg-indigo-500 ' onClick={onPermissionClick} >X</button>
                </div>
                <h1 className='font-regular text-2xl text-start self-center pb-7'>Select Employee Permisions</h1>
                <div className='divide-y-2 pb-4'>

                    {/* //////////////////////////////// CROPS /////////////////////////////////////// */}
                    <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                        <div>
                            <h1 className='font-regular text-2xl text-start pl-8'>Crops</h1>
                        </div>

                        <div>
                            <InlineEditCheckBox onChange={(e) => onRoleChange(e)} value={crops} id={"crops"} name={"crops"} label={"View"} />
                        </div>

                        {/* //////////////////////////////// CROPS EDIT /////////////////////////////////////// */}
                        <div>
                            <InlineEditCheckBox onChange={(e) => onRoleChange(e)} value={cropsEdit} id={"cropsEdit"} name={"cropsEdit"} label={"Edit"} />
                        </div>
                    </div>


                    {/* //////////////////////////////// LIVE STOCK /////////////////////////////////////// */}

                    <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                        <div>
                            <h1 className='font-regular text-2xl text-start pl-8'>Live Stock</h1>
                        </div>

                        {/* //////////////////////////////// LIVE STOCK /////////////////////////////////////// */}
                        <div>
                            <InlineEditCheckBox onChange={(e) => onRoleChange(e)} value={liveStock} id={"liveStock"} name={"liveStock"} label={"View"} />
                        </div>

                        {/* //////////////////////////////// LIVE STOCK EDIT /////////////////////////////////////// */}
                        <div>
                            <InlineEditCheckBox onChange={(e) => onRoleChange(e)} value={liveStockEdit} id={"liveStockEdit"} name={"liveStockEdit"} label={"Edit"} />
                        </div>
                    </div>


                    {/* //////////////////////////////// EMPLOYEES /////////////////////////////////////// */}

                    <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                        <div>
                            <h1 className='font-regular text-2xl text-start pl-8'>Employees</h1>
                        </div>

                        {/* //////////////////////////////// EMPLOYEES /////////////////////////////////////// */}
                        <div>
                            <InlineEditCheckBox  onChange={(e) => onRoleChange(e)} value={employees} id={"employees"} name={"employees"} label={"View"} />
                        </div>

                        {/* //////////////////////////////// EMPLOYEES EDIT /////////////////////////////////////// */}
                        <div>
                            <InlineEditCheckBox  onChange={(e) => onRoleChange(e)} value={employeesEdit} id={"employeesEdit"} name={"employeesEdit"} label={"Edit"} />
                        </div>
                    </div>



                   {/* //////////////////////////////// EQUIPMENT /////////////////////////////////////// */}

                   <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                        <div>
                            <h1 className='font-regular text-2xl text-start pl-8'>Equipment</h1>
                        </div>

                        {/* //////////////////////////////// EQUIPMENT /////////////////////////////////////// */}
                        <div>
                            <InlineEditCheckBox  onChange={(e) => onRoleChange(e)} value={equipment} id={"equipment"} name={"equipment"} label={"View"} />
                        </div>

                        {/* //////////////////////////////// EQUIPMENT EDIT /////////////////////////////////////// */}
                        <div>
                            <InlineEditCheckBox  onChange={(e) => onRoleChange(e)} value={equipmentEdit} id={"equipmentEdit"} name={"equipmentEdit"} label={"Edit"} />
                        </div>
                    </div>



                    {/* //////////////////////////////// INVENTORY /////////////////////////////////////// */}

                   <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                        <div>
                            <h1 className='font-regular text-2xl text-start pl-8'>Inventory</h1>
                        </div>

                        {/* //////////////////////////////// INVENTORY /////////////////////////////////////// */}
                        <div>
                            <InlineEditCheckBox  onChange={(e) => onRoleChange(e)} value={inventory} id={"inventory"} name={"inventory"} label={"View"} />
                        </div>

                        {/* //////////////////////////////// INVENTORY EDIT /////////////////////////////////////// */}
                        <div>
                            <InlineEditCheckBox  onChange={(e) => onRoleChange(e)} value={inventoryEdit} id={"inventoryEdit"} name={"inventoryEdit"} label={"Edit"} />
                        </div>
                    </div>


                    {/* //////////////////////////////// TASKS /////////////////////////////////////// */}

                   <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                        <div>
                            <h1 className='font-regular text-2xl text-start pl-8'>Tasks</h1>
                        </div>

                        {/* //////////////////////////////// TASKS /////////////////////////////////////// */}
                        <div>
                            <InlineEditCheckBox  onChange={(e) => onRoleChange(e)} value={tasksRole} id={"tasksRole"} name={"tasksRole"} label={"View"} />
                        </div>

                        {/* //////////////////////////////// TASKS EDIT /////////////////////////////////////// */}
                        <div>
                            <InlineEditCheckBox  onChange={(e) => onRoleChange(e)} value={tasksEdit} id={"tasksEdit"} name={"tasksEdit"} label={"Edit"} />
                        </div>
                    </div>


                    {/* //////////////////////////////// ACTIVITY /////////////////////////////////////// */}

                   <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                        <div>
                            <h1 className='font-regular text-2xl text-start pl-8'>Activity</h1>
                        </div>

                        {/* //////////////////////////////// ACTIVITY /////////////////////////////////////// */}
                        <div>
                            <InlineEditCheckBox  onChange={(e) => onRoleChange(e)} value={activity} id={"activity"} name={"activity"} label={"View"} />
                        </div>

                        {/* //////////////////////////////// ACTIVITY EDIT /////////////////////////////////////// */}
                        <div>
                            <InlineEditCheckBox  onChange={(e) => onRoleChange(e)} value={activityEdit} id={"activityEdit"} name={"activityEdit"} label={"Edit"} />
                        </div>
                    </div>


                   {/* //////////////////////////////// CUSTOMERS /////////////////////////////////////// */}

                   <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                        <div>
                            <h1 className='font-regular text-2xl text-start pl-8'>Customers</h1>
                        </div>

                        {/* //////////////////////////////// CUSTOMERS /////////////////////////////////////// */}
                        <div>
                            <InlineEditCheckBox  onChange={(e) => onRoleChange(e)} value={customers} id={"customers"} name={"customers"} label={"View"} />
                        </div>

                        {/* //////////////////////////////// CUSTOMERS EDIT /////////////////////////////////////// */}
                        <div>
                            <InlineEditCheckBox  onChange={(e) => onRoleChange(e)} value={customersEdit} id={"customersEdit"} name={"customersEdit"} label={"Edit"} />
                        </div>
                    </div>


                    {/* //////////////////////////////// SALES /////////////////////////////////////// */}

                   <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                        <div>
                            <h1 className='font-regular text-2xl text-start pl-8'>Sales</h1>
                        </div>

                        {/* //////////////////////////////// SALES /////////////////////////////////////// */}
                        <div>
                            <InlineEditCheckBox  onChange={(e) => onRoleChange(e)} value={sales} id={"sales"} name={"sales"} label={"View"} />
                        </div>

                        {/* //////////////////////////////// SALES EDIT /////////////////////////////////////// */}
                        <div>
                            <InlineEditCheckBox  onChange={(e) => onRoleChange(e)} value={salesEdit} id={"salesEdit"} name={"salesEdit"} label={"Edit"} />
                        </div>
                    </div>


                    {/* //////////////////////////////// STORAGE /////////////////////////////////////// */}

                   <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                        <div>
                            <h1 className='font-regular text-2xl text-start pl-8'>Storage</h1>
                        </div>

                        {/* //////////////////////////////// STORAGE /////////////////////////////////////// */}
                        <div>
                            <InlineEditCheckBox onChange={(e) => onRoleChange(e)} value={storage} id={"storage"} name={"storage"} label={"View"} />
                        </div>

                        {/* //////////////////////////////// STORAGE EDIT /////////////////////////////////////// */}
                        <div>
                            <InlineEditCheckBox onChange={(e) => onRoleChange(e)} value={storageEdit} id={"storageEdit"} name={"storageEdit"} label={"Edit"} />
                        </div>
                    </div>


                   {/* //////////////////////////////// FARM /////////////////////////////////////// */}

                   <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                        <div>
                            <h1 className='font-regular text-2xl text-start pl-8'>Farm</h1>
                        </div>

                        {/* //////////////////////////////// FARM /////////////////////////////////////// */}
                        <div>
                            <InlineEditCheckBox onChange={(e) => onRoleChange(e)} value={farms} id={"farms"} name={"farms"} label={"View"} />
                        </div>

                        {/* //////////////////////////////// FARM EDIT /////////////////////////////////////// */}
                        <div>
                            <InlineEditCheckBox onChange={(e) => onRoleChange(e)} value={farmsEdit} id={"farmsEdit"} name={"farmsEdit"} label={"Edit"} />
                        </div>
                    </div>


                    {/* //////////////////////////////// JOIN REQUESTS /////////////////////////////////////// */}

                   <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                        <div>
                            <h1 className='font-regular text-2xl text-start pl-8'>Join Requests</h1>
                        </div>

                        {/* //////////////////////////////// JOIN REQUESTS /////////////////////////////////////// */}
                        <div>
                            <InlineEditCheckBox onChange={(e) => onRoleChange(e)} value={joinApprovals} id={"joinApprovals"} name={"joinApprovals"} label={"View"} />
                        </div>

                        {/* //////////////////////////////// JOIN REQUESTS EDIT /////////////////////////////////////// */}
                        <div>
                            <InlineEditCheckBox onChange={(e) => onRoleChange(e)} value={joinApprovalsEdit} id={"joinApprovalsEdit"} name={"joinApprovalsEdit"} label={"Edit"} />
                        </div>
                    </div>


                    <div className='flex flex-row justify-center' >
                        <button className='flex flex-row justify-center py-3 my-4 w-1/4 bg-indigo-500 rounded-md shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]'
                            onClick={accessChangeSubmit} >
                            <h1 className='text-white text-xl' >Submit</h1>
                        </button>
                    </div>

                    {/* {farmJoin.status === 'pending' ? (<></>) : (<>
                        <div className='py-3'>
                            <button onClick={onSubmitApprove} value={farmJoin._id}
                                    className="px-3 py-2 border-2 border-indigo-500 text-black rounded-lg disabled:hover:bg-gray-100 disabled:text-gray-300 hover:bg-indigo-500 hover:text-white ">Approve
                            </button>
                        </div>
                    </>)} */}


                </div>
            </div>
        </div>
        </div>
        </>) : (<></>) }
        </>) : (<>

{/* <div className='' >
    <button className='border-indigo-500 border-2 text-slate-700 mt-6 bg-white rounded-lg py-3 px-4 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]
                    hover:text-white hover:border-2 hover:border-indigo-500 hover:bg-indigo-500 '
     onClick={onPermissionClick} >Employee Access</button>
</div> */}
</>)}
            </div>
            </div>
            ) : (<></>)}
            
            {toggleState === 2 ? (
               <div className='mt-5  rounded-lg py-5  px-4 ' >
               {/* <button className='bg-white rounded-lg py-5 hover:bg-indigo-200  px-4 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]' onClick={onPermissionClick} >Close Permission View</button> */}
           
           
           {/* Employee Access Selections */}
           <div className='flex justify-center pt-6 '>
                       <div className='flex flex-col bg-white justify-center w-1/2 rounded-lg  px-4 mb-8 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]'>
           
                           {/* <div className='flex flex-row justify-end' >
                               <button className='border-indigo-500 border-2 text-indigo-600 w-10 rounded-lg mt-4 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]
                               hover:text-white hover:border-2 hover:border-indigo-500 hover:bg-indigo-500 ' onClick={onPermissionClick} >X</button>
                           </div> */}
                           <h1 className='font-regular text-2xl text-start self-center py-7'>Select Employee Permisions</h1>
                           <div className='divide-y-2 pb-4'>
           
                               {/* //////////////////////////////// CROPS /////////////////////////////////////// */}
                               <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                                   <div>
                                       <h1 className='font-regular text-2xl text-start pl-8'>Crops</h1>
                                   </div>
           
                                   <div>
                                       <InlineEditCheckBox onChange={(e) => onRoleChange(e)} value={crops} id={"crops"} name={"crops"} label={"View"} />
                                   </div>
           
                                   {/* //////////////////////////////// CROPS EDIT /////////////////////////////////////// */}
                                   <div>
                                       <InlineEditCheckBox onChange={(e) => onRoleChange(e)} value={cropsEdit} id={"cropsEdit"} name={"cropsEdit"} label={"Edit"} />
                                   </div>
                               </div>
           
           
                               {/* //////////////////////////////// LIVE STOCK /////////////////////////////////////// */}
           
                               <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                                   <div>
                                       <h1 className='font-regular text-2xl text-start pl-8'>Live Stock</h1>
                                   </div>
           
                                   {/* //////////////////////////////// LIVE STOCK /////////////////////////////////////// */}
                                   <div>
                                       <InlineEditCheckBox onChange={(e) => onRoleChange(e)} value={liveStock} id={"liveStock"} name={"liveStock"} label={"View"} />
                                   </div>
           
                                   {/* //////////////////////////////// LIVE STOCK EDIT /////////////////////////////////////// */}
                                   <div>
                                       <InlineEditCheckBox onChange={(e) => onRoleChange(e)} value={liveStockEdit} id={"liveStockEdit"} name={"liveStockEdit"} label={"Edit"} />
                                   </div>
                               </div>
           
           
                               {/* //////////////////////////////// EMPLOYEES /////////////////////////////////////// */}
           
                               <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                                   <div>
                                       <h1 className='font-regular text-2xl text-start pl-8'>Employees</h1>
                                   </div>
           
                                   {/* //////////////////////////////// EMPLOYEES /////////////////////////////////////// */}
                                   <div>
                                       <InlineEditCheckBox  onChange={(e) => onRoleChange(e)} value={employees} id={"employees"} name={"employees"} label={"View"} />
                                   </div>
           
                                   {/* //////////////////////////////// EMPLOYEES EDIT /////////////////////////////////////// */}
                                   <div>
                                       <InlineEditCheckBox  onChange={(e) => onRoleChange(e)} value={employeesEdit} id={"employeesEdit"} name={"employeesEdit"} label={"Edit"} />
                                   </div>
                               </div>
           
           
           
                              {/* //////////////////////////////// EQUIPMENT /////////////////////////////////////// */}
           
                              <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                                   <div>
                                       <h1 className='font-regular text-2xl text-start pl-8'>Equipment</h1>
                                   </div>
           
                                   {/* //////////////////////////////// EQUIPMENT /////////////////////////////////////// */}
                                   <div>
                                       <InlineEditCheckBox  onChange={(e) => onRoleChange(e)} value={equipment} id={"equipment"} name={"equipment"} label={"View"} />
                                   </div>
           
                                   {/* //////////////////////////////// EQUIPMENT EDIT /////////////////////////////////////// */}
                                   <div>
                                       <InlineEditCheckBox  onChange={(e) => onRoleChange(e)} value={equipmentEdit} id={"equipmentEdit"} name={"equipmentEdit"} label={"Edit"} />
                                   </div>
                               </div>
           
           
           
                               {/* //////////////////////////////// INVENTORY /////////////////////////////////////// */}
           
                              <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                                   <div>
                                       <h1 className='font-regular text-2xl text-start pl-8'>Inventory</h1>
                                   </div>
           
                                   {/* //////////////////////////////// INVENTORY /////////////////////////////////////// */}
                                   <div>
                                       <InlineEditCheckBox  onChange={(e) => onRoleChange(e)} value={inventory} id={"inventory"} name={"inventory"} label={"View"} />
                                   </div>
           
                                   {/* //////////////////////////////// INVENTORY EDIT /////////////////////////////////////// */}
                                   <div>
                                       <InlineEditCheckBox  onChange={(e) => onRoleChange(e)} value={inventoryEdit} id={"inventoryEdit"} name={"inventoryEdit"} label={"Edit"} />
                                   </div>
                               </div>
           
           
                               {/* //////////////////////////////// TASKS /////////////////////////////////////// */}
           
                              <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                                   <div>
                                       <h1 className='font-regular text-2xl text-start pl-8'>Tasks</h1>
                                   </div>
           
                                   {/* //////////////////////////////// TASKS /////////////////////////////////////// */}
                                   <div>
                                       <InlineEditCheckBox  onChange={(e) => onRoleChange(e)} value={tasksRole} id={"tasksRole"} name={"tasksRole"} label={"View"} />
                                   </div>
           
                                   {/* //////////////////////////////// TASKS EDIT /////////////////////////////////////// */}
                                   <div>
                                       <InlineEditCheckBox  onChange={(e) => onRoleChange(e)} value={tasksEdit} id={"tasksEdit"} name={"tasksEdit"} label={"Edit"} />
                                   </div>
                               </div>
           
           
                               {/* //////////////////////////////// ACTIVITY /////////////////////////////////////// */}
           
                              <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                                   <div>
                                       <h1 className='font-regular text-2xl text-start pl-8'>Activity</h1>
                                   </div>
           
                                   {/* //////////////////////////////// ACTIVITY /////////////////////////////////////// */}
                                   <div>
                                       <InlineEditCheckBox  onChange={(e) => onRoleChange(e)} value={activity} id={"activity"} name={"activity"} label={"View"} />
                                   </div>
           
                                   {/* //////////////////////////////// ACTIVITY EDIT /////////////////////////////////////// */}
                                   <div>
                                       <InlineEditCheckBox  onChange={(e) => onRoleChange(e)} value={activityEdit} id={"activityEdit"} name={"activityEdit"} label={"Edit"} />
                                   </div>
                               </div>
           
           
                              {/* //////////////////////////////// CUSTOMERS /////////////////////////////////////// */}
           
                              <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                                   <div>
                                       <h1 className='font-regular text-2xl text-start pl-8'>Customers</h1>
                                   </div>
           
                                   {/* //////////////////////////////// CUSTOMERS /////////////////////////////////////// */}
                                   <div>
                                       <InlineEditCheckBox  onChange={(e) => onRoleChange(e)} value={customers} id={"customers"} name={"customers"} label={"View"} />
                                   </div>
           
                                   {/* //////////////////////////////// CUSTOMERS EDIT /////////////////////////////////////// */}
                                   <div>
                                       <InlineEditCheckBox  onChange={(e) => onRoleChange(e)} value={customersEdit} id={"customersEdit"} name={"customersEdit"} label={"Edit"} />
                                   </div>
                               </div>
           
           
                               {/* //////////////////////////////// SALES /////////////////////////////////////// */}
           
                              <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                                   <div>
                                       <h1 className='font-regular text-2xl text-start pl-8'>Sales</h1>
                                   </div>
           
                                   {/* //////////////////////////////// SALES /////////////////////////////////////// */}
                                   <div>
                                       <InlineEditCheckBox  onChange={(e) => onRoleChange(e)} value={sales} id={"sales"} name={"sales"} label={"View"} />
                                   </div>
           
                                   {/* //////////////////////////////// SALES EDIT /////////////////////////////////////// */}
                                   <div>
                                       <InlineEditCheckBox  onChange={(e) => onRoleChange(e)} value={salesEdit} id={"salesEdit"} name={"salesEdit"} label={"Edit"} />
                                   </div>
                               </div>
           
           
                               {/* //////////////////////////////// STORAGE /////////////////////////////////////// */}
           
                              <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                                   <div>
                                       <h1 className='font-regular text-2xl text-start pl-8'>Storage</h1>
                                   </div>
           
                                   {/* //////////////////////////////// STORAGE /////////////////////////////////////// */}
                                   <div>
                                       <InlineEditCheckBox onChange={(e) => onRoleChange(e)} value={storage} id={"storage"} name={"storage"} label={"View"} />
                                   </div>
           
                                   {/* //////////////////////////////// STORAGE EDIT /////////////////////////////////////// */}
                                   <div>
                                       <InlineEditCheckBox onChange={(e) => onRoleChange(e)} value={storageEdit} id={"storageEdit"} name={"storageEdit"} label={"Edit"} />
                                   </div>
                               </div>
           
           
                              {/* //////////////////////////////// FARM /////////////////////////////////////// */}
           
                              <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                                   <div>
                                       <h1 className='font-regular text-2xl text-start pl-8'>Farm</h1>
                                   </div>
           
                                   {/* //////////////////////////////// FARM /////////////////////////////////////// */}
                                   <div>
                                       <InlineEditCheckBox onChange={(e) => onRoleChange(e)} value={farms} id={"farms"} name={"farms"} label={"View"} />
                                   </div>
           
                                   {/* //////////////////////////////// FARM EDIT /////////////////////////////////////// */}
                                   <div>
                                       <InlineEditCheckBox onChange={(e) => onRoleChange(e)} value={farmsEdit} id={"farmsEdit"} name={"farmsEdit"} label={"Edit"} />
                                   </div>
                               </div>
           
           
                               {/* //////////////////////////////// JOIN REQUESTS /////////////////////////////////////// */}
           
                              <div className='grid grid-cols-3 gap-4 pt-4 pb-4 max-w-2xl '>
                                   <div>
                                       <h1 className='font-regular text-2xl text-start pl-8'>Join Requests</h1>
                                   </div>
           
                                   {/* //////////////////////////////// JOIN REQUESTS /////////////////////////////////////// */}
                                   <div>
                                       <InlineEditCheckBox onChange={(e) => onRoleChange(e)} value={joinApprovals} id={"joinApprovals"} name={"joinApprovals"} label={"View"} />
                                   </div>
           
                                   {/* //////////////////////////////// JOIN REQUESTS EDIT /////////////////////////////////////// */}
                                   <div>
                                       <InlineEditCheckBox onChange={(e) => onRoleChange(e)} value={joinApprovalsEdit} id={"joinApprovalsEdit"} name={"joinApprovalsEdit"} label={"Edit"} />
                                   </div>
                               </div>
           
           
                               <div className='flex flex-row justify-center' >
                                   <button className='flex flex-row justify-center py-3 my-4 w-1/4 bg-indigo-500 rounded-md shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]'
                                       onClick={accessChangeSubmit} >
                                       <h1 className='text-white text-xl' >Submit</h1>
                                   </button>
                               </div>
           
                               {/* {farmJoin.status === 'pending' ? (<></>) : (<>
                                   <div className='py-3'>
                                       <button onClick={onSubmitApprove} value={farmJoin._id}
                                               className="px-3 py-2 border-2 border-indigo-500 text-black rounded-lg disabled:hover:bg-gray-100 disabled:text-gray-300 hover:bg-indigo-500 hover:text-white ">Approve
                                       </button>
                                   </div>
                               </>)} */}
           
           
                           </div>
                       </div>
                   </div>
                   </div>
            ) : (<></>)}
            </div>

            {tasks.length !== 0 ? (<>
                <div className="#bg-indigo-300 py-5">
                <div
                    className="bg-white py-3 rounded-lg #drop-shadow-xl #shadow-lg relative mx-9 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]  ">

                    <div className=''>
                        <h1 className='text-md text-right mr-6 my-3 font-thin '>{`Edit`}</h1>
                    </div>

                    <div className="grid grid-cols-3 gap-3">
                        <div className='my-1 py-1 col-span-1 justify-self-center self-center'>
                            <img className="h-36 w-34 bg-white shadow-xl rounded-xl"
                                 src="https://cdn-icons-png.flaticon.com/512/843/843349.png" alt=""/>
                        </div>
                        <div className="col-span-2 px-1 mx-1 py-2 rounded-md text-left">
                            <div className='flex flex-row'>
                                {/* <h1 className="text-2xl my-0 #bg-indigo-500 font-thin">{`${employee?.name}`}</h1>
                                <h1 className="text-md pt-1 ml-2 text-gray-500 #bg-indigo-400 font-normla">{`${employee?.role}`}</h1> */}
                            </div>
                            <div className="stats drop-shadow-xl bg-slate-100">

                                <div className="stat w-32 justify-items-center ">
                                    <div className="stat-title">{t("translation:open")}</div>
                                    <div className="stat-value text-green-500">10</div>
                                </div>

                                <div className="stat w-32 justify-items-center ">
                                    <div className="stat-title ">{t("translation:overdue")}</div>
                                    <div className="stat-value text-red-500">4</div>
                                </div>

                                <div className="stat w-32 justify-items-center">
                                    <div className="stat-title ">{t("translation:completed")}</div>
                                    <div className="stat-value ">36</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                </div>

                <div>
                    <div className="grid grid-cols-3 gap-4 pt-8 pb-10 ">
                        {FindTasks.map((task) => (
                            <>
                                <div
                                    className="bg-white  shadow-[2px_1px_15px_2px_rgba(201,201,201,0.47)] rounded-xl drop-shadow-sm pt-3 flex flex-col items-start pl-3 pb-2">
                                    <h4 className="text-xl">{task.title}</h4>
                                    <h6 className=' text-gray-500 pt-1 '>{task.description}</h6>
                                    <div>
                                        {task.assignedToObject?.name}
                                    </div>
                                    <div className=''>
                                        {/* Goal {task.goal} */}
                                    </div>
                                    <div>
                                        {task.assignedToObject?.job_title}
                                    </div>
                                    <div className='absolute bottom-0 right-0 pr-2 pb-2 text-sm text-gray-400'>
                                        {task.deadline}
                                    </div>
                                    <div className='absolute top-0 right-0 pr-2 text-sm pt-4'>
                                        {task.status === '0' ? (
                                            <>
                                                <div className='text-red-500'>
                                                    created
                                                </div>
                                            </>
                                        ) : <></>}
                                        {task.status === '25' ? (
                                            <>
                                                <div className='text-orange-500'>
                                                    in progress
                                                </div>
                                            </>
                                        ) : <></>}
                                        {task.status === '50' ? (
                                            <>
                                                <div className='text-green-600'>
                                                    completed
                                                </div>
                                            </>
                                        ) : <></>}
                                        {task.status === '75' ? (
                                            <>
                                                <div className=' text-green-600 '>
                                                    review
                                                </div>
                                            </>
                                        ) : <></>}
                                        {task.status === '100' ? (
                                            <>
                                                <div>
                                                    closed
                                                </div>
                                            </>
                                        ) : <></>}

                                    </div>

                                </div>
                            </>
                        ))}
                    </div>
                </div>
                <div>
                    <table className="table w-full">
                        <thead>
                        <tr>
                            <th>{t("translation:title")}</th>
                            <th>{t("translation:deadline")}</th>
                            <th>{t("translation:crop")}</th>
                            <th>{t("translation:goal")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {FindTasks.map((task) => (
                            <>
                                <tr>
                                    <th>{task.title}</th>
                                    <th>{task.deadline}</th>
                                    <th>{task.description}</th>
                                    {/* <th>{task.goal}</th> */}
                                </tr>
                            </>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
            </>) : (<></>) }

            {/* ////////////////////////////////////////////////////////////////////// */}
            {/* //////////////////////////// ACTIVITY LOG //////////////////////////// */}
            
            <ChangeActivityList allActivity={allActivity} page={'Employee'} />
                    
        </>
    )
}}

export default EmployeePage
