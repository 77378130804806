import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {getAllStorage, deleteStorage} from '../../../features/storage/storageSlice'
import Spinner from '../../components/Spinner'
import {DataGrid, GridColumns} from '@mui/x-data-grid';
import {Link, useNavigate} from 'react-router-dom'
import {AppDispatch, RootState} from "../../../app/store";
import TableMultiDeleteButton from 'UI/components/TableMultiDeleteButton';
import DeleteModal from 'UI/components/DeleteModal';
import { useTranslation } from 'react-i18next';
import {toast} from "react-toastify";
import {Status} from "../../../types/slice";

const actionColumn : GridColumns = [
    {
        field: 'storage',
        headerName: "Action",
        width: 100,
        renderCell: (params) => {
            return (
                <div className='flex flex-row px-2 pl-4 justify-center items-center gap-2' >
                    <Link to={`/storage/${params.row._id}`} className="border-2 rounded-lg text-blue-500 px-2">View</Link>
                </div>
            )
        }
    }
]

const columns : GridColumns = [
  { field: '_id', headerName: 'ID', width: 70 },
  {
    field: 'img',
    headerName: 'User',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    renderCell:(params)=> {
        return(
            
                <div className='flex flex-row items-center' >
                    <img className=' rounded-xl  w-9 h-9 mr-4'  src="https://icons.veryicon.com/png/o/education-technology/passenger-flow-analysis-icon-library/activity-assessment.png" alt="" />
                    <div className='text-indigo' >
                    {params.row.name}
                    </div>
                </div>
            
        )
    }
  },

  { field: 'description', headerName: 'description', width: 230 },
  {
    field: 'category',
    headerName: 'category',
    type: 'string',
    width: 90,
  },
  {
    field: 'quantity',
    headerName: 'quantity',
    type: 'number',
    width: 90,
  },

];




function StorageTable() {

  const navigate = useNavigate()
  const {t} = useTranslation(["livestock", "translation"])
  const dispatch = useDispatch<AppDispatch>()


  const [modalIsOpen, setModalIsOpen] = useState(false)

const onDeleteClick = (e: any) => {
    setModalIsOpen(!modalIsOpen)
    console.log(modalIsOpen)
}

const [deleteEvent, setDeleteEvent] = useState<boolean>(true)
const [selectedRows, setSelectedRows] = useState<string[]>([])

const handleSelectionChange = (newSelectionModel : string[]) => {
    setSelectedRows(newSelectionModel)
}


const onDelete = () => {
    dispatch(deleteStorage(selectedRows))
    .unwrap()
    .then(() => {
        toast.success(t("storage:storageDeleted"));
        navigate("/storage")
        setModalIsOpen(false)
    })
    .catch(() => {
        toast.error(t("storage:failedToDeletestorage"))
    })
    setDeleteEvent(!deleteEvent)
    setDeleteEvent(!deleteEvent)
    setSelectedRows([])
}



useEffect(() => {
  if ( status !== Status.Pending || deleteEvent === true || deleteEvent === false)
      dispatch(getAllStorage())
}, [dispatch, deleteEvent])
   

    // useEffect(() => {
    //     dispatch(getAllStorage())
    //     if (isSuccess) {
            
    //     }
    // },[dispatch] )

    const {allStorage, status} = useSelector((state : RootState) => state.storage )
    console.log(allStorage)

    // if (isLoading) {
    //     return<Spinner/>
    // }
  return (
    <>

        {selectedRows.length !== 0 ? (<>
        <TableMultiDeleteButton onDeleteClick={onDeleteClick} />
        </>) :(<></>) }
      
      <div style={{ height: 400, width: 'full' }}>
      <DataGrid
        getRowId={(row) => row._id}
        rows={allStorage}
        columns={columns.concat(actionColumn)}
        pageSize={10}
        rowsPerPageOptions={[5]}
        checkboxSelection
        onSelectionModelChange={(newSelectionModel : any) => {
          handleSelectionChange(newSelectionModel)
        }}
      />
    </div>


    {/* ////////////////////////////////////////////////////////////////////// */}
    {/* //////////////////////////// DELETE MODAL //////////////////////////// */}

    <DeleteModal modalIsOpen={modalIsOpen} onDeleteClick={onDeleteClick} onDelete={onDelete} />
   
    </>
  )
}

export default StorageTable