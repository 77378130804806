import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {deleteLiveStock, getAllLiveStock} from '../../../features/live-stock/live-stockSlice'
import Spinner from '../../components/Spinner'
import {DataGrid, GridColumns} from '@mui/x-data-grid';
import {AppDispatch, RootState} from "../../../app/store";
import {Status} from "../../../types/slice";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import TableMultiDeleteButton from 'UI/components/TableMultiDeleteButton';
import DeleteModal from 'UI/components/DeleteModal';
import {Link, useNavigate} from "react-router-dom";

const columns = (t: any): GridColumns => [
    // {field: '_id', headerName: t("translation:id"), width: 70},
    // {
    //     field: 'live-stock',
    //     headerName: t("translation:action"),
    //     width: 100,
    //     renderCell: (params) => {
    //         return (
    //             <div className='flex flex-row px-2 pl-2 justify-center items-center gap-2'>
    //                 <Link to={`/livestock/${params.row._id}`}
    //                       className="border-2 rounded-lg border-blue-500 px-1">{t("translation:view")}</Link>
    //                 {/* <div className='border-2 rounded-lg border-red-400 px-2'>{t("translation:delete")}</div> */}
    //             </div>
    //         )
    //     }
    // },
    {
        field: 'link',
        headerName: t("translation:Brand"),
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        width: 70,
        renderCell: (params) => {
            return (

                <div className='flex flex-row items-center'>
                    <img className=' rounded-xl  w-9 h-9 mr-4'
                         src={`${params.row.properties.organizationBadges[4].brand_logo_source}`}
                         alt=""/>
                    <div className='text-indigo'>
                        
                        
                    </div>
                </div>

            )
        }
    },
    {
        field: 'img',
        headerName: t("translation:Name"),
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        width: 250,
        renderCell: (params) => {
            return (

                <div className='flex flex-row items-center'>
                    <div className='text-indigo'>
                        {params.row.properties.organizationName}
                    </div>
                </div>

            )
        }
    },
    {
        field: 'live-stock',
        headerName: t("translation:action"),
        width: 80,
        renderCell: (params) => {
            return (
                <div className='flex flex-row px-2 pl-2 justify-center items-center gap-2'>
                    <Link to={`/sign-up-v2`}
                          className="border-2 rounded-lg border-blue-500 px-1">{t("translation:view")}</Link>
                    {/* <div className='border-2 rounded-lg border-red-400 px-2'>{t("translation:delete")}</div> */}
                </div>
            )
        }
    },
    // {
    //     field: 'organizationType',
    //     headerName: t("translation:category"),
    //     type: 'string',
    //     width: 90,
    // },

];


function DisplayAllBrandsSearchResults() {

const navigate = useNavigate()
const {t} = useTranslation(["livestock", "translation"])
const dispatch = useDispatch<AppDispatch>()

const [modalIsOpen, setModalIsOpen] = useState(false)
const [selectedRows, setSelectedRows] = useState<string[]>([])

const handleSelectionChange = (newSelectionModel : string[]) => {
    setSelectedRows(newSelectionModel)}

    // useEffect(() => {
    // if (status !== Status.Pending || deleteEvent === true || deleteEvent === false)
        // dispatch(getAllLiveStock())
// }, [dispatch, deleteEvent])


const {orgLocations, status} = useSelector((state: RootState) => state.orgLocation)

let brandsList : any = []
    orgLocations.map(org => {
        const {organizationType} = org
        if (organizationType === "cpgBrands" ) {
            brandsList.push(org)
        }
    })

console.log("PRINTING LIST OF BRANDS IN", brandsList)

    if (status === Status.Failure)
        toast.error(t("livestock:failedGetLivestock"))

    if (status === Status.Pending)
        return <Spinner/>


    return (
        <>


        {selectedRows.length !== 0 ? (<>
        </>) :(<></>) }


            <div style={{height: 700, width: 'full'}}>
                <DataGrid
                    getRowId={(row) => row._id}
                    rows={brandsList}
                    columns={columns(t)}
                    pageSize={10}
                    rowsPerPageOptions={[5]}
                />
            </div>

            {/* ////////////////////////////////////////////////////////////////////// */}
            {/* //////////////////////////// DELETE MODAL //////////////////////////// */}

            {/* <DeleteModal modalIsOpen={modalIsOpen}/> */}

        </>
    )
}

export default DisplayAllBrandsSearchResults