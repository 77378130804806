import React from 'react'

function ItemPageDeleteButton(props : {onDeleteClick : React.MouseEventHandler<HTMLButtonElement> }) {
  return (
    <>
        <div className='absolute top-4 right-4' >
            <button 
            onClick={props.onDeleteClick}
            className='px-3 py-2 border-2 border-black text-black rounded-md shadow-lg hover:text-white hover:bg-indigo-600 ' >
                Delete
            </button>
        </div>
    </>
  )
}

export default ItemPageDeleteButton