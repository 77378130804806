import React, {ChangeEventHandler, FocusEventHandler, KeyboardEventHandler} from 'react'

type InlineEditProps ={
  value? : string,
  name? : string,
  id? : string,
  title? : string,
  preText?: string,
  afterText?: string,
  onChange? : ChangeEventHandler<any>,
  onKeyDown? : KeyboardEventHandler<any>,
  onBlur? : FocusEventHandler<any>
 }

function InlineEdit({value, name, id, title, afterText, preText, onChange, onKeyDown, onBlur} : InlineEditProps) {


  return (
    <>
    <div className='flex flex-row justify-start border-2 rounded-md m-2 p-1' >
      <h1 className='text-left self-left pt-3 text-slate-500 text-sm font-thin mr-4' >{title}</h1>
      <h1 className='text-left self-left pt-3 text-slate-500 text-sm font-thin mr-4' >{preText}</h1>
      <input className=' w-full pl-3 ml-1 pt-2 mb-1 text-left self-left text-lg cursor-pointer hover:border-slate-300 border-1 border-white rounded-md focus:border-indigo-600 focus:text-black hover:text-gray-600 text-black'
        aria-label="Field name"
        value={value}
        onChange={onChange} name={name}  id={id}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
      />
      <h1 className='pt-2' >{afterText}</h1>
    </div>
    </>
  )
}

export default InlineEdit

