import React, { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux'
import { FeatureGroup, MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import { getOrgLocations } from "features/orgLocations/orgLocationsSlice";
import { getOrgFarmersMarkets } from "features/farmersMarkets/orgFarmersMarketsSlice";
import Spinner from '../../components/Spinner'
import {Status} from "../../../types/slice";
import { cities } from "data/cities";
import "leaflet/dist/leaflet.css";
import LeafletControlGeocoder from "./LeafletControlGeocoder";
import OrgsMap from "./OrgsMap";
import L from "leaflet";
// Searches
import SearchBox from '../Map/SearchBox-1'
import SearchableMap from '../Map/SearchableMap-1'
import FarmersMarkets from "./FarmersMarkets";

const onClick = () => {
  console.log('Pop Up Button Hit' )
}

const DEFAULT_RADIUS = 60

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png",
});



export default function MobileDisplayMapOrgs(props) {

  function ResetCenterView(props) {
    const { selectedPosition } = props;
    const map = useMap();
  
    useEffect(() => {
      if (selectedPosition) {
        map.setView(
          L.latLng(selectedPosition?.lat, selectedPosition?.lon),
          map.getZoom(),
          {
            animate: true
          }
        )
      }
    }, [selectedPosition]);
  
    return null;
  }
  const dispatch = useDispatch()



const [selectedPosition, setSelectPosition] = useState()


//////////
  useEffect(() => {
      // dispatch(getOrgFarmersMarkets())
}, [dispatch])

const {orgLocations, status} = useSelector((state) => state.orgLocation)

  if (status === Status.Pending ) {
    return <Spinner/>
}
  return (
    <>
    <>
      <div className="" style={{ display: "flex", flexDirection: "row", rounded:"lg",  width: "100vw", height: "70vh" }} >
          {/* <div style={{ width: "50vw", height: "100%" }}> */}
              {/* <SearchableMap lat={selectedPosition?.lat} lon={selectedPosition?.lon} selectPosition={selectedPosition} /> */}
          {/* </div> */}
          <div className="" style={{ width: "100vw", height: "100%" }}>
            <OrgsMap data={orgLocations}  lat={orgLocations[0]?.geometry.coordinates[1]} lon={orgLocations[0]?.geometry.coordinates[0]} selectPosition={selectedPosition}/>
          </div>
      </div>
    </>
        {/* <MapContainer center={[41.05181527192488, -73.59345225093897]} zoom={12} minZoom={12} style={{ width: "80vw", height: "70vh" }}> */}
          {/* Light Mode */}
          {/* <TileLayer url='https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.{ext}' minZoom= {10} maxZoom= {20}
            attribution= '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            ext= 'png' />
          <MarkerLayer data={orgFarmersMarkets} setRadiusFilter={setRadiusFilter} getRadiusFilter={getRadiusFilter} />
          <ResetCenterView selectPosition={selectedPosition} />
        </MapContainer> */}
        </>
  );
}






{/* <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright"></a> contributors'url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/> */}

{/* <TileLayer
  url='https://api.mapbox.com/styles/v1/purpledragons/cl9kzio97001v14pe6ahvafbd/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicHVycGxlZHJhZ29ucyIsImEiOiJjbDlqMGp3cHkwZDJsM25wa2I2dnBxMXU2In0.m0DYm_idO-8_I7OeSBuMnQ'
  maxZoom= {13}/> */}


{/* DARK MODE */}
{/* <TileLayer url='https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.{ext}' minZoom= {10} maxZoom= {20}
  attribution= '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' ext= 'png' /> */}            
