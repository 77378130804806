import {createSlice, createAsyncThunk, PayloadAction} from "@reduxjs/toolkit";
import taskService from "./taskService";
import {TaskState} from "../../types/states";
import HandleAxiosError from "../../utils/AxiosErrorHandler";
import {DefaultThunkApiConfig, Status} from "../../types/slice";
import {TaskModel} from "@asirisos/types";
import {TaskCategory} from "@asirisos/types/data";

const CONTEXT = "Task"

const initialState : TaskState = {
    task: undefined,
    tasks: [],
   status: Status.Unknown
}

export const addTask = createAsyncThunk<any, TaskModel, DefaultThunkApiConfig>('task/add', async (taskData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await taskService.addTask(taskData, account)
        if (response.status !== 201)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getTasks = createAsyncThunk<TaskModel[], void, DefaultThunkApiConfig>('task/getall', async (_, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await taskService.getTasks(account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getCategoryTasks = createAsyncThunk<any, TaskCategory, DefaultThunkApiConfig>('task/getallcategory', async (taskCategory, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await taskService.getCategoryTasks(taskCategory, account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getTask = createAsyncThunk<TaskModel, string, DefaultThunkApiConfig>('task/getone/:taskId', async (taskId, thunkAPI) => {
    try {
        const response = await taskService.getTask(taskId)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const updateTask = createAsyncThunk<TaskModel, TaskModel, DefaultThunkApiConfig>('task/update/:taskId', async (taskData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if(!account)
            return thunkAPI.rejectWithValue();

        const response = await taskService.updateTask(taskData, account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const deleteTask = createAsyncThunk<any, string[], DefaultThunkApiConfig>('task/delete', async (taskId, thunkAPI) => {
    try {
        const response = await taskService.deleteTask(taskId)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const taskTaskSlice = createSlice ({
    name: 'task',
    initialState,
    reducers: {
        reset: () => initialState
        },
    extraReducers: (builder) => {
        builder
            .addCase(addTask.pending, (state: TaskState) => {
                state.status = Status.Pending
            })
            .addCase(addTask.fulfilled, (state: TaskState, action: PayloadAction<TaskModel, any, any>) => {
                state.status = Status.Success
                state.task = action.payload
            })
            .addCase(addTask.rejected, (state: TaskState) => {
                state.status = Status.Failure
            })
            .addCase(getTasks.pending, (state: TaskState) => {
                state.status = Status.Pending
            })
            .addCase(getTasks.fulfilled, (state: TaskState, action: PayloadAction<TaskModel[], any, any>) => {
                state.status = Status.Success
                state.tasks = action.payload
            })
            .addCase(getTasks.rejected, (state: TaskState) => {
                state.status = Status.Failure
            })
            .addCase(getCategoryTasks.pending, (state: TaskState) => {
                state.status = Status.Pending
            })
            .addCase(getCategoryTasks.fulfilled, (state: TaskState, action: PayloadAction<TaskModel[], any, any>) => {
                state.status = Status.Success
                state.tasks = action.payload
            })
            .addCase(getCategoryTasks.rejected, (state: TaskState) => {
                state.status = Status.Failure
            })
            .addCase(getTask.pending, (state: TaskState) => {
                state.status = Status.Pending
            })
            .addCase(getTask.fulfilled, (state: TaskState, action: PayloadAction<TaskModel, any, any>) => {
                state.status = Status.Success
                state.task = action.payload
            })
            .addCase(getTask.rejected, (state: TaskState) => {
                state.status = Status.Failure
            })
            .addCase(updateTask.pending, (state: TaskState) => {
                state.status = Status.Pending
            })
            .addCase(updateTask.fulfilled, (state: TaskState, action: PayloadAction<TaskModel, any, any>) => {
                state.status = Status.Success
                state.tasks.map((task: TaskModel) => task._id === action.payload._id ?
                    (state.task = task) : task)
                state.task = action.payload
            })
            .addCase(updateTask.rejected, (state: TaskState) => {
                state.status = Status.Failure
            })
            .addCase(deleteTask.pending, (state: TaskState) => {
                state.status = Status.Pending
            })
            .addCase(deleteTask.fulfilled, (state: TaskState) => {
                state.status = Status.Success
                state.task = undefined
            })
            .addCase(deleteTask.rejected, (state: TaskState) => {
                state.status = Status.Failure
            })
    }
})

export const {reset} = taskTaskSlice.actions
export default taskTaskSlice.reducer