import React, { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux'
import { FeatureGroup, MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import { getOrgLocations } from "features/orgLocations/orgLocationsSlice";
import { getOrgFarmersMarkets } from "features/farmersMarkets/orgFarmersMarketsSlice";
import Spinner from '../../components/Spinner'
import {Status} from "../../../types/slice";
import { cities } from "data/cities";
import "leaflet/dist/leaflet.css";
import LeafletControlGeocoder from "./LeafletControlGeocoder";
import L from "leaflet";
// Searches
import SearchBox from '../Map/SearchBox-1'
import SearchableMap from '../Map/SearchableMap-1'
import FarmersMarkets from "./FarmersMarkets";

const onClick = () => {
  console.log('Pop Up Button Hit' )
}

const DEFAULT_RADIUS = 80

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png",
});

const CustomPopUp = ({data, setRadiusFilter}) => {

  const {listing_name, location_address} = data.properties

  return(
    <>
    <Popup className="">
        <div className=" flex flex-col py-10 px-3 rounded-md text-white bg-indigo-500 text-center justify-center" >
          <div>
            <b>{`${listing_name}`}</b>
          </div>
          <div>
            <b>{`${location_address}`}</b>
          </div>
          <button onClick={onClick} className="text-black p-2 mt-8 rounded-md bg-white shadow-xl" >
            Click Me
          </button>
        </div>
    </Popup>
    </>
  )
}


const MarkerLayer = ({data, setRadiusFilter, getRadiusFilter}) => {
  const radiusFilter = getRadiusFilter()

  let centerPoint 
  if(radiusFilter) {
    const { coordinates} = radiusFilter.data.geometry
    centerPoint = L.latLng(coordinates[1], coordinates[0])
  }

  const inputsIcon = L.icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/512/2776/2776067.png",
    iconSize: [50, 50],
  });
  

  return data.filter((currentFeature) => {
    if(centerPoint) {
      const {coordinates} = currentFeature.geometry
      const currentPoint = L.latLng(coordinates[1], coordinates[0])
      return centerPoint.distanceTo(currentPoint) / 1000 < radiusFilter.radius
    } else {
      return true
    }
  } )

  .map(feature => {
    const {coordinates} = feature.geometry
      return (
        <>
        {coordinates[1] === null ? <></>  : <>
        <Marker key={String(coordinates)} position={[coordinates[1], coordinates[0]]} icon={inputsIcon}>
          <CustomPopUp data={feature} setRadiusFilter={setRadiusFilter}/>
        </Marker>
        </>}
        </>
      )
  }
  )
} 
const position = [51.505, -0.09];


export default function DisplayAllFarmersMarkets(props) {

  function ResetCenterView(props) {
    const { selectedPosition } = props;
    const map = useMap();
  
    useEffect(() => {
      if (selectedPosition) {
        map.setView(
          L.latLng(selectedPosition?.lat, selectedPosition?.lon),
          map.getZoom(),
          {
            animate: true
          }
        )
      }
    }, [selectedPosition]);
  
    return null;
  }
  const dispatch = useDispatch()

/// Searchable Map
const [formData, setFormData] = useState({
  addressObject: {},
})

const {addressObject} = formData

const [selectedPosition, setSelectPosition] = useState()

const onSetAddress = (selectedPosition) => {
  setFormData((prevState) => ({
      ...prevState,
      addressObject: selectedPosition
  }) )
}

const onChange = (e) => {
  setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
  }))
}

//////////
  useEffect(() => {
      dispatch(getOrgFarmersMarkets())
}, [dispatch])

const {orgFarmersMarkets, status} = useSelector((state) => state.orgFarmersMarket)
  const [radiusFilter, setRadiusFilter] = useState(null)

  const getRadiusFilter = () => radiusFilter
  const { selectPosition } = props;
  const locationSelection = [selectPosition?.lat, selectPosition?.lon];

  if (status === Status.Pending ) {
    return <Spinner/>
}
  return (
    <>
    <>
      <div style={{ display: "flex", flexDirection: "column",  width: "70vw", height: "75vh" }} >
          <div className="pl-8 flex flex-col justify-items-start pb-10" style={{ width: "50vw" }}>
              <SearchBox  selectPosition={selectedPosition} setSelectPosition={setSelectPosition} onSetAddress={onSetAddress} name={"addressObject"} value={addressObject} onChange={(e) => onChange(e) } />
          </div>
          {/* <div style={{ width: "50vw", height: "100%" }}> */}
              {/* <SearchableMap lat={selectedPosition?.lat} lon={selectedPosition?.lon} selectPosition={selectedPosition} /> */}
          {/* </div> */}
          <div style={{ width: "80vw", height: "100%" }}>
            <FarmersMarkets data={orgFarmersMarkets}  lat={selectedPosition?.lat} lon={selectedPosition?.lon} selectPosition={selectedPosition}/>
          </div>
      </div>
    </>
        {/* <MapContainer center={[41.05181527192488, -73.59345225093897]} zoom={12} minZoom={12} style={{ width: "80vw", height: "70vh" }}> */}
          {/* Light Mode */}
          {/* <TileLayer url='https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.{ext}' minZoom= {10} maxZoom= {20}
            attribution= '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            ext= 'png' />
          <MarkerLayer data={orgFarmersMarkets} setRadiusFilter={setRadiusFilter} getRadiusFilter={getRadiusFilter} />
          <ResetCenterView selectPosition={selectedPosition} />
        </MapContainer> */}
        </>
  );
}






{/* <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright"></a> contributors'url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/> */}

{/* <TileLayer
  url='https://api.mapbox.com/styles/v1/purpledragons/cl9kzio97001v14pe6ahvafbd/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicHVycGxlZHJhZ29ucyIsImEiOiJjbDlqMGp3cHkwZDJsM25wa2I2dnBxMXU2In0.m0DYm_idO-8_I7OeSBuMnQ'
  maxZoom= {13}/> */}


{/* DARK MODE */}
{/* <TileLayer url='https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.{ext}' minZoom= {10} maxZoom= {20}
  attribution= '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' ext= 'png' /> */}            
