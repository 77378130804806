import React from 'react'
import {Link} from 'react-router-dom'
import {FarmModel} from "@asirisos/types";

type FarmListItemProps = {
  farm: FarmModel
}

function FarmListItem({farm}: FarmListItemProps) {
  return (
      <>

    <tr>
        <th>{farm.name}</th>
        <th>{farm.location}</th>
        <th>
            <Link to={`/farm/${farm._id}`} className="badge-xs">Join</Link>
        </th>
    </tr>
    </>
  )
}

export default FarmListItem