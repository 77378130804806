import React from 'react'
import {useLocation, Navigate, Outlet} from 'react-router'
import {useAuthStatus} from '../../hooks/useAuthStatus'
import {useSelector} from 'react-redux'
import Spinner from './Spinner'
import {RootState} from "../../app/store";
import {access} from 'fs'

type PrivateRouteProps = {
    allowedRoles: Array<string>
}

const UserPrivateRoute = (props: PrivateRouteProps) => {

    const {loggedIn, checkingStatus} = useAuthStatus()
    const location = useLocation()

    const {user} = useSelector((state: RootState) => state.auth)

    if (checkingStatus) {
        return <Spinner/>
    }

    return (
        user?.roles?.find(role => props.allowedRoles?.includes(role))
            ? <Outlet/>
            : user?._id
                ? <Navigate to="/unauthorized" state={{from: location}} replace/>
                : <Navigate to="/login" state={{from: location}} replace/>
    )
}
export default UserPrivateRoute