import axios from "axios"
import { CommentModel, CommentCategoryModel, CommentConnectionIdModel } from "@asirisos/types";
import {AccountModel} from "@asirisos/types";
import RequestBodyFactory from "../../factories/requestBodyFactory";

const API_URL = '/api/comment'

const addComment = async (commentData: CommentModel, accountData: AccountModel) => {
    const body = new RequestBodyFactory(commentData).WithAccount(accountData).Build();
    console.log('Comment Service - Add Comment - data', body)

    return await axios.post(API_URL + '/', body)
}

// Get Comments

const getComments = async (account: AccountModel) => {
    return await axios.get(API_URL + '/all/' + account.farm._id + '/' + account.user._id)
}

// get Category Comments
const getCategoryComments = async (commentCategory: CommentCategoryModel, account: AccountModel) => {
    const body = new RequestBodyFactory(commentCategory).WithAccount(account).Build()
    return await axios.post(API_URL + '/getCategoryComments', body)
}


//////////////////////////     # 1  //////////////////////////////////////
// get Connection Id Comments
// const getConnectionIdComments = async (commentConnectionId: CommentConnectionIdModel, account: AccountModel) => {
//     const data = {
//         commentConnectionId: commentConnectionId,
//         account: account
//     }

//     return await axios.post(API_URL + '/getConnectionIdComments', data)
// }
//////////////////////////     # 1  //////////////////////////////////////



//////////////////////////     # 2  //////////////////////////////////////
// get Connection Id Comments
const getConnectionIdComments = async ( commentConnectionId: string) => {

    return await axios.get(API_URL + '/getConnectionIdComments/' + commentConnectionId  )
}
//////////////////////////     # 2  //////////////////////////////////////



//////////////////////////     # 3  //////////////////////////////////////
//////////////////////////////// Get Connection Comments lates - copy from Get Live Stock ////////////////////////////////

// const getConnectionComment = async (connectionCommentId: string) => {
//     return await axios.get(API_URL + '/connectionComment' + connectionCommentId)
// }

//////////////////////////     # 3  //////////////////////////////////////



// Get 

const getTasks = async (account: AccountModel) => {
    return await axios.get(API_URL + '/all/' + account.farm._id + '/' + account.user._id)
}


// Get Comment

const getComment = async (commentId: string) => {
    return await axios.get(API_URL + '/' + commentId)
}


const updateComment = async (commentData: CommentModel) => {
    const {_id} = commentData
    return await axios.put(API_URL + '/' + _id, commentData)
}

// Delete Comment

const deleteComment = async (commentId: string) => {
    return await axios.delete(API_URL + '/' + commentId)
}


const commentService = {
    addComment,
    getComments,
    getCategoryComments,
    getConnectionIdComments,
    getComment,
    updateComment,
    deleteComment
}

export default commentService