import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import equipmentService from "./equipmentService"
import {EquipmentState} from "../../types/states";
import HandleAxiosError from "../../utils/AxiosErrorHandler";
import {DefaultThunkApiConfig, Status} from "../../types/slice";
import {EquipmentModel} from "@asirisos/types";

const CONTEXT = "Equipment"

const initialState: EquipmentState = {
    equipment: undefined,
    equipments: [],
    status: Status.Unknown
}

export const addEquipment = createAsyncThunk<any, EquipmentModel, DefaultThunkApiConfig>('equipment/add', async (equipmentData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await equipmentService.addEquipment(equipmentData, account)
        if (response.status !== 201)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getAllEquipment = createAsyncThunk<EquipmentModel[], void, DefaultThunkApiConfig>('equipment/getall', async (_, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await equipmentService.getAllEquipment(account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getEquipment = createAsyncThunk<EquipmentModel, string, DefaultThunkApiConfig>('equipment/getone/:equipmentId', async (equipmentId, thunkAPI) => {
    try {
        const response = await equipmentService.getEquipment(equipmentId)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const updateEquipment = createAsyncThunk<EquipmentModel, EquipmentModel, DefaultThunkApiConfig>('equipment/update/:equipmentid', async (equipmentData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if(!account)
            return thunkAPI.rejectWithValue();
        
        const response = await equipmentService.updateEquipment(equipmentData, account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const deleteEquipment = createAsyncThunk<any, string[], DefaultThunkApiConfig>('equipment/delete', async (equipmentId, thunkAPI) => {
    try {
        const response = await equipmentService.deleteEquipment(equipmentId)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const equipmentSlice = createSlice({
    name: 'equipment',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(addEquipment.pending, (state: EquipmentState) => {
                state.status = Status.Pending
            })
            .addCase(addEquipment.fulfilled, (state: EquipmentState) => {
                state.status = Status.Success
            })
            .addCase(addEquipment.rejected, (state: EquipmentState) => {
                state.status = Status.Failure
            })
            .addCase(getAllEquipment.pending, (state: EquipmentState) => {
                state.status = Status.Pending
            })
            .addCase(getAllEquipment.fulfilled, (state: EquipmentState, action: PayloadAction<EquipmentModel[], any, any>) => {
                state.status = Status.Success
                state.equipments = action.payload
            })
            .addCase(getAllEquipment.rejected, (state: EquipmentState) => {
                state.status = Status.Failure
            })
            .addCase(getEquipment.pending, (state: EquipmentState) => {
                state.status = Status.Pending
            })
            .addCase(getEquipment.fulfilled, (state: EquipmentState, action: PayloadAction<EquipmentModel, any, any>) => {
                state.status = Status.Success
                state.equipment = action.payload
            })
            .addCase(getEquipment.rejected, (state: EquipmentState) => {
                state.status = Status.Failure
            })
            .addCase(updateEquipment.pending, (state: EquipmentState) => {
                state.status = Status.Pending
            })
            .addCase(updateEquipment.fulfilled, (state: EquipmentState, action: PayloadAction<EquipmentModel, any, any>) => {
                state.status = Status.Success
                state.equipments.map((equipment: EquipmentModel) => equipment._id === action.payload._id ?
                    (state.equipment = equipment) : equipment)
                state.equipment = action.payload
            })
            .addCase(updateEquipment.rejected, (state: EquipmentState) => {
                state.status = Status.Failure
            })
            .addCase(deleteEquipment.pending, (state: EquipmentState) => {
                state.status = Status.Pending
            })
            .addCase(deleteEquipment.fulfilled, (state: EquipmentState) => {
                state.status = Status.Success
                state.equipment = undefined
            })
            .addCase(deleteEquipment.rejected, (state: EquipmentState) => {
                state.status = Status.Failure
            })
    }
})

export const {reset} = equipmentSlice.actions
export default equipmentSlice.reducer