import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import fieldService from "./fieldService";
import {FixMeLater} from "../../types/dev";
import {FieldState} from "../../types/states";
import HandleAxiosError from "../../utils/AxiosErrorHandler";
import {DefaultThunkApiConfig, Status} from "../../types/slice";
import {FieldModel} from "@asirisos/types";

const CONTEXT = "Field"

const initialState: FieldState = {
    field: undefined,
    fields: [],
    status: Status.Unknown,
    message: ''
}

export const addField = createAsyncThunk<any, FieldModel, DefaultThunkApiConfig>('field/add', async (fieldData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await fieldService.addField(fieldData, account)
        if (response.status !== 201)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getFields = createAsyncThunk<FieldModel[], void, DefaultThunkApiConfig>('field/getall', async (_, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await fieldService.getFields(account)
        console.log("Field Slice - Get All - response", response)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getField = createAsyncThunk<FieldModel, string, DefaultThunkApiConfig>('field/getone/:fieldId', async (fieldId, thunkAPI) => {
    try {
        const response = await fieldService.getField(fieldId)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const updateField = createAsyncThunk<FieldModel, FieldModel, DefaultThunkApiConfig>('field/updatefield/:fieldId', async (fieldData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if(!account)
            return thunkAPI.rejectWithValue();
            
        const response = await fieldService.updateField(fieldData, account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
    // try {
    //     const token = thunkAPI.getState().auth.user.token
    //     return await fieldService.updateField(fieldData, token)
    // } catch (error) {
    //     HandleAxiosError(error, CONTEXT);
    //     return thunkAPI.rejectWithValue("message")
    // }
})

export const deleteField = createAsyncThunk<any, string[], DefaultThunkApiConfig>('field/delete/:fieldId', async (fieldId, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if(!account)
            return thunkAPI.rejectWithValue();

            console.log('Test account in delete Storage', account, fieldId)
        const response = await fieldService.deleteField(fieldId, account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const fieldSlice = createSlice({
    name: 'field',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(addField.pending, (state: FieldState) => {
                state.status = Status.Pending
            })
            .addCase(addField.fulfilled, (state: FieldState) => {
                state.status = Status.Success
            })
            .addCase(addField.rejected, (state: FieldState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            .addCase(getFields.pending, (state: FieldState) => {
                state.status = Status.Pending
            })
            .addCase(getFields.fulfilled, (state: FieldState, action: PayloadAction<FieldModel[], any, any>) => {
                state.status = Status.Success
                state.fields = action.payload
            })
            .addCase(getFields.rejected, (state: FieldState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            .addCase(getField.pending, (state: FieldState) => {
                state.status = Status.Pending
            })
            .addCase(getField.fulfilled, (state: FieldState, action: PayloadAction<FieldModel, any, any>) => {
                state.status = Status.Success
                state.field = action.payload
            })
            .addCase(getField.rejected, (state: FieldState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            .addCase(updateField.pending, (state: FieldState) => {
                state.status = Status.Pending
            })
            .addCase(updateField.fulfilled, (state: FieldState, action: PayloadAction<FieldModel, any, any>) => {
                state.status = Status.Success
                state.fields.map((field: FieldModel) => field._id === action.payload._id ?
                    (state.field = field) : field)
                state.field = action.payload
            })
            .addCase(updateField.rejected, (state: FieldState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            // .addCase(updateField.fulfilled, (state: FieldState, action: FixMeLater) => {
            //     state.isLoading = false
            //     state.fields.map((field) => field._id === action.payload._id ? (state.field = field) : field)
            // })
            .addCase(deleteField.pending, (state: FieldState) => {
                state.status = Status.Pending
            })
            .addCase(deleteField.fulfilled, (state: FieldState) => {
                state.status = Status.Success
                state.field = undefined
            })
            .addCase(deleteField.rejected, (state: FieldState) => {
                state.status = Status.Failure
            })
    }
})

export const {reset} = fieldSlice.actions
export default fieldSlice.reducer