import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { getCategoryTasks } from '../../../features/task/taskSlice'
import { getAllLiveStock } from 'features/live-stock/live-stockSlice'
import {toast} from 'react-toastify'
import Spinner from '../../components/Spinner'
import {useParams, useNavigate} from 'react-router-dom'
import { getCategoryActivity } from 'features/activity/activitySlice'
import {AppDispatch, RootState} from "../../../app/store";
import TotalPieChart from './TotalPieChart'
import {Status} from "../../../types/slice";
import {useTranslation} from "react-i18next";




function LiveStockDashboard() {


    const {tasks, status} = useSelector((state : RootState) => state.taskTask)
    const {allLiveStock} = useSelector((state : RootState) => state.livestock)
    const {allActivity} = useSelector((state : RootState) => state.activity )

    const {t} = useTranslation("livestock");

    const params = useParams()
    const {taskId} = useParams()
    const dispatch = useDispatch<AppDispatch>()
    const naviagate = useNavigate()

    const taskCategory = {category: 'liveStock'}
    const activityCategory = {category: "live stock"}

    useEffect(() => {
        if (status === Status.Failure) {
            toast.error(t("equipment:equipmentGetFailed"))
        }
            dispatch(getCategoryTasks(taskCategory))
            dispatch(getCategoryActivity(activityCategory))
            dispatch(getAllLiveStock())
    },[dispatch, taskId] )

    console.log('tasks', tasks, tasks.length)

    
    if (status === Status.Pending && tasks.length != 0 && allActivity.length != 0 && allLiveStock.length != 0) {
        <Spinner/>
    }
  return (
      <>

    {/* ///////////////////////////// Block Section Start ///////////////////////////// */}

    <div className="grid grid-cols-3 gap-4 pt-1 pb-10 px-4 " >




        {/* ///////////////////////////// Block Section - First Box ///////////////////////////// */}
        <div className=' rounded-lg shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]  my-1 py-1 col-span-1 bg-white #justify-self-center #self-center'>
            <div className='flex flex-row justify-center' >
                <h1 className='text-xl pl-3 pt-2' >Live Stock by Total Count</h1>
            </div>
            <div className='flex flex-row justify-center' >
                <TotalPieChart/>
            </div>   
        </div>
        {/* ///////////////////////////// Block Section - First Box ///////////////////////////// */}


        {/* ///////////////////////////// Block Section - Second Box ///////////////////////////// */}
        <div className=' rounded-lg shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]  my-1 py-1 col-span-1 grid bg-white #justify-self-center #self-center'>
            <div className='self-center' >
            <div className='flex flex-row justify-center' >
                <div className='bg-indigo-500 text-white text-8xl p-6 rounded-xl self-center' >
                <h1 className='text-xl' >Open Tasks</h1>
                    {tasks.length}
                </div>
            </div>   
            </div>
        </div>
        {/* ///////////////////////////// Block Section - Second Box ///////////////////////////// */}


        {/* ///////////////////////////// Block Section - Third Box ///////////////////////////// */}
        <div className=' rounded-lg shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]  my-1 py-1 col-span-1 grid bg-white #justify-self-center #self-center'>
            <div className='self-center' >
            <div className='flex flex-row justify-center' >
                <div className='bg-indigo-500 text-white text-8xl p-6 rounded-xl self-center' >
                <h1 className='text-xl' >Activity Events</h1>
                    {allActivity.length}
                </div>
            </div>   
            </div>
        </div>
        {/* ///////////////////////////// Block Section - Third Box ///////////////////////////// */}





        {/* ///////////////////////////// Block Section - Third Box ///////////////////////////// */}
        {/* <div className=' rounded-lg shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]  my-1 py-1 col-span-1 bg-white #justify-self-center #self-center'>
        <div className='grid grid-cols-2' >
                    <div className='col-span-1' >
                        <h1 className='text-lg text-left ml-5 mb-3 pt-2 font-normal' >{`Progress`}</h1>
                    </div>
                    <div className='col-span-1' >
                        <h1 className='text-md text-right mr-6 my-3 pb-3 font-thin ' >{`Edit`}</h1>
                    </div>
                    </div>
            <div className="flex px-8 pb-5" >
              <progress className="progress progress-primary bg-gray-300 h-2 mr-5 mt-3" value={task?.status} max="100"></progress>
              <h1 className="text-xl" >{task?.status}%</h1>
            </div>

            <h1 className='text-lg text-left ml-5 my-0 font-normal' >{`Deadline`}</h1>
            <h1 className='text-md text-left my-0 py-0 ml-8 font-thin' >{`${task?.deadline}`}</h1>
        </div> */}
        {/* ///////////////////////////// Block Section - Third Box ///////////////////////////// */}


      </div>
      
    </>
  )
}

export default LiveStockDashboard