import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import FormCard from '../../components/shared/FormCard'
import FormTwoInputs from '../../components/shared/FormTwoInputs'
import NumberInputInUnits from '../../components/shared/NumberInputInUnits'
import LargeTextInput from '../../components/shared/LargeTextInput'
import LegacyDropDownInput from '../../components/shared/LegacyDropDownInput'
import { getCrops } from '../../../features/crops/cropSlice'
import { getAllEquipment } from '../../../features/equipment/equipmentSlice'
import { addActivity } from '../../../features/activity/activitySlice'
import {AppDispatch, RootState} from "../../../app/store";
import {InputSelection} from "../../../types/ui";
import { getFarmAccounts } from 'features/accounts/accountSlice'
import { getAllLiveStock } from 'features/live-stock/live-stockSlice'
import { getAllInventory } from 'features/inventory/inventorySlice'
import { getAllStorage } from 'features/storage/storageSlice'
import { getCustomers } from 'features/customers/customerSlice'
import DropDownInput from '../../components/shared/LegacyDropDownInput'
import {ActivityModel, CropModel, EquipmentModel} from "@asirisos/types";
import { getYields } from 'features/yield/yieldSlice'
import { getInfrastructures } from 'features/infrastructure/infrastructureSlice'
import { getFields } from 'features/fields/fieldSlice'

function ActivityForm() {
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    Initiate Hooks   /////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const dispatch = useDispatch<AppDispatch>()

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    USE EFFECT   /////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    useEffect(() => {
        dispatch(getCrops())
        dispatch(getFarmAccounts())
        dispatch(getAllEquipment())
        dispatch(getAllLiveStock())
        dispatch(getAllInventory())
        dispatch(getAllStorage())
        dispatch(getCustomers())
        dispatch(getYields())
        dispatch(getInfrastructures())
        dispatch(getFields())
    },[dispatch] )

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    USE SELECTOR - CONNECTION SELECTION   ////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const {crops} = useSelector((state : RootState) => state.crop )
    const {equipments} = useSelector((state : RootState) => state.equipment )
    const {accounts} = useSelector((state: RootState) => state.account)
    const {allLiveStock} = useSelector((state : RootState) => state.livestock )
    const {allInventory} = useSelector((state : RootState) => state.inventory )
    const {allStorage} = useSelector((state: RootState) => state.storage )
    const {customers} = useSelector((state: RootState) => state.customer )
    const {fields} = useSelector((state: RootState) => state.field)
    const {infrastructures} = useSelector((state: RootState) => state.infrastructure)
    const {yields} = useSelector((state: RootState) => state.yield)


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  CONNECTION SELECTIONS - DROP DOWN   ////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const cropSelections = crops.map((crop: CropModel) => { return new InputSelection(crop.cropName, crop._id)})

    const equipmentSelections = equipments.map((equipment: EquipmentModel) => {return new InputSelection(equipment.name, equipment._id)})

    const employeeSelections = accounts.map((account) => {return {name: account.name, id:account._id}})

    const liveStockSelections = allLiveStock.map((livestock) => {return {name: livestock.name, id:livestock._id}})

    const inventorySelections = allInventory.map((inventory) => {return {name: inventory.name, id:inventory._id}})

    const storageSelections = allStorage.map((storage) => {return {name: storage.name, id:storage._id}})

    const customerSelections = customers.map((customer) => {return  new InputSelection(customer.firstName, customer._id)})

    const yieldSelections = yields.map((singleYield) => {return  new InputSelection(singleYield.title, singleYield._id)})

    const fieldSelections = fields.map((field) => {return  new InputSelection(field.fieldName, field._id)})

    const infrastructureSelections = infrastructures.map((infrastructure) => {return  new InputSelection(infrastructure.infrastructureName, infrastructure._id)})


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    CONNECTION CATEGORY SELECTIONS      //////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const connectionSelection = [
        new InputSelection('crop', 'crop'),
        new InputSelection('live stock', 'live stock'),
        new InputSelection('storage', 'storage'),
        new InputSelection('employee', 'employee'),
        new InputSelection('equipment', 'equipment'),
        new InputSelection('inventory', 'inventory'),
        new InputSelection('yield', 'yield'),
        new InputSelection('field', 'field'),
        new InputSelection('infrastructure', 'infrastructure'),
        new InputSelection('customer', 'customer')]

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    ACTIVITY TYPE SELECTIONS      ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const activityTypeSelection = [
        new InputSelection('add', 'add'),
        new InputSelection('chnage', 'chnage'),
        new InputSelection('remove', 'remove')]


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    CONNECTION TOGGLE      ///////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const [connectionAdded, setConnectionAdded] = React.useState('false');

    const onToggleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const toggleValue = e.target.value

        if (toggleValue === 'true') { setConnectionAdded('false') }
        if (toggleValue === 'false') { setConnectionAdded('true') }

   }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  USE STATE - ACTIVITY FORM  /////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const [formData, setFormData] = useState({
        farmId: '',
        title: '',
        connectionCategory: '',
        description: '',
        employee: '',
        deadline: '',
        equipment:'',
        crop: '',
        yieldConnection: '',
        infrastructure: '',
        field: '',
        liveStock: '',
        inventory: '',
        storage: '',
        customer: '',
        assignedTo: '',
        activityType: ''
    })

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  STATE ENTRIES - DESTRUCTURE   //////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
    const {
        farmId,
        title,
        connectionCategory,
        description,
        crop,
        employee,
        deadline,
        equipment,
        yieldConnection,
        field,
        infrastructure,
        liveStock,
        inventory,
        storage,
        customer,
        assignedTo,
        activityType
    } = formData


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  ON CHANGE   ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }) )
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////  ON SUBMIT   ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        const activityData : ActivityModel = {
            farmId,
            title,
            connectionAdded,
            connectionCategory,
            description,
            employee,
            deadline,
            equipment,
            field,
            infrastructure,
            yieldConnection,
            crop,
            liveStock,
            inventory,
            storage,
            customer,
            assignedTo,
            activityType
        }
        console.log(activityData)
        dispatch(addActivity(activityData))
    }

    
  return (
    <>

    {/* //////////////////////////   New Activity Form  /////////////////////////////// */}
    {/* /////////////////////////////////////////////////////////////////////////////// */}

    <FormCard title="Add Activity">
        <form onSubmit={onSubmit}>
            <FormTwoInputs>
                <NumberInputInUnits onChange={(e) => onChange(e)} value={title} id={"title"} type={'text'} group={"title"} label={"title"} name={"title"} dataTitle="Title" />
                <NumberInputInUnits onChange={(e) => onChange(e)} value={deadline} id={"deadline"} type={'date'} group={"deadline"} label={"deadline"} name={"deadline"} dataTitle="Deadline" />
            </FormTwoInputs>

            <div className='mx-16' >
                <LargeTextInput onChange={(e) => onChange(e)} value={description} id={"description"} type={'text'} group={"description"} label={"description"} name={"description"} dataTitle="Description" />
            </div>

            <div className='pt-4 mt-8 pb-3' >
                <label className="relative inline-flex items-center cursor-pointer">
                    <input type="checkbox" onChange={(e) => onToggleChange(e)} value={connectionAdded} className="sr-only peer"/>
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <h1 className='ml-3 text-slate-500 text-md' >Add Reference</h1>
                </label>
                
            {/* //////////////////////////   ACTIVITY CONNECTION ////////////////////////////// */}
            {/* /////////////////////////////////////////////////////////////////////////////// */}


            {connectionAdded === 'true' ? (
                <>
                    <div className='' >

                    <FormTwoInputs>
                        <DropDownInput onChange={(e) => onChange(e)} selections={connectionSelection} value={connectionCategory} id={"connectionCategory"} type={'text'} group={"connectionCategory"} label={"connectionCategory"} name={"connectionCategory"} dataTitle="Category" />
                        {connectionCategory === 'crop' ? (
                            <>
                                <DropDownInput onChange={(e) => onChange(e)} selections={cropSelections} value={crop} id={"crop"} type={'text'} group={"crop"} label={"crop"} name={"crop"} dataTitle="Crop" />
                            </>) : (<></>) }

                        {connectionCategory === 'live stock' ? (
                            <>
                                <DropDownInput onChange={(e) => onChange(e)} selections={liveStockSelections} value={liveStock} id={"liveStock"} type={'text'} group={"liveStock"} label={"liveStock"} name={"liveStock"} dataTitle="Live Stock" />
                            </>) : (<></>) }

                        {connectionCategory === 'employee' ? (
                            <>
                                <DropDownInput onChange={(e) => onChange(e)} selections={employeeSelections} value={employee} id={"employee"} type={'text'} group={"employee"} label={"employee"} name={"employee"} dataTitle="Employee" />
                            </>) : (<></>) }

                        {connectionCategory === 'equipment' ? (
                            <>
                                <DropDownInput onChange={(e) => onChange(e)} selections={equipmentSelections} value={equipment} id={"equipment"} type={'text'} group={"equipment"} label={"equipment"} name={"equipment"} dataTitle="Equipment" />
                            </>) : (<></>) }

                        {connectionCategory === 'inventory' ? (
                            <>
                                <DropDownInput onChange={(e) => onChange(e)} selections={inventorySelections} value={inventory} id={"inventory"} type={'text'} group={"inventory"} label={"inventory"} name={"inventory"} dataTitle="Inventory" />
                            </>) : (<></>) }

                        {connectionCategory === 'storage' ? (
                            <>
                                <DropDownInput onChange={(e) => onChange(e)} selections={storageSelections} value={storage} id={"storage"} type={'text'} group={"storage"} label={"storage"} name={"storage"} dataTitle="Storage" />
                            </>) : (<></>) }

                        {connectionCategory === 'customer' ? (
                            <>
                                <DropDownInput onChange={(e) => onChange(e)} selections={customerSelections} value={customer} id={"customer"} type={'text'} group={"customer"} label={"customer"} name={"customer"} dataTitle="Customer" />
                            </>) : (<></>) }

                        {connectionCategory === 'yield' ? (
                            <>
                                <DropDownInput onChange={(e) => onChange(e)} selections={yieldSelections} value={yieldConnection} id={"yieldConnection"} type={'text'} group={"yieldConnection"} label={"yieldConnection"} name={"yieldConnection"} dataTitle="yieldConnection" />
                            </>) : (<></>) }

                        {connectionCategory === 'field' ? (
                            <>
                                <DropDownInput onChange={(e) => onChange(e)} selections={fieldSelections} value={field} id={"field"} type={'text'} group={"field"} label={"field"} name={"field"} dataTitle="field" />
                            </>) : (<></>) }

                        {connectionCategory === 'infrastructure' ? (
                            <>
                                <DropDownInput onChange={(e) => onChange(e)} selections={infrastructureSelections} value={infrastructure} id={"infrastructure"} type={'text'} group={"infrastructure"} label={"infrastructure"} name={"infrastructure"} dataTitle="infrastructure" />
                            </>) : (<></>) }


                    </FormTwoInputs>
                    </div>
                </>) : (<></>) }
            </div>

            {/* //////////////////////////   NEW ACTIVITY FORM - CONTINUED  /////////////////// */}
            {/* /////////////////////////////////////////////////////////////////////////////// */}

            <FormTwoInputs>
                <DropDownInput onChange={(e) => onChange(e)} selections={employeeSelections} value={assignedTo} id={"assignedTo"} type={'text'} group={"assignedTo"} label={"assignedTo"} name={"assignedTo"} dataTitle="Activity Contact" />
                <DropDownInput onChange={(e) => onChange(e)} selections={activityTypeSelection} value={activityType} id={"activityType"} type={'text'} group={"activityType"} label={"activityType"} name={"activityType"} dataTitle="Activity Type" />
            </FormTwoInputs>

        <div className='flex justify-center pt-8 p-2' >
            <button className="btn btn-wide btn-primar hover:bg-black">Add Activity</button>
        </div>    
        </form>
    </FormCard>
</>
  )
}

export default ActivityForm