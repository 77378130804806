import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {toast} from 'react-toastify'
import Spinner from '../../components/Spinner'
import NumberInputInUnits from '../../components/shared/NumberInputInUnits'
import FormCard from '../../components/shared/FormCard'
import FormTwoInputs from '../../components/shared/FormTwoInputs'
import FormRangeInput from '../../components/shared/FormRangeInput'
import FormSelectionRangeInput from '../../components/shared/card/FormSelectionRangeInput'
import {AppDispatch, RootState} from "../../../app/store";
import {useTranslation} from "react-i18next";
import {Status} from "../../../types/slice";
import {addCrop} from "../../../features/crops/cropSlice";
import {CropModel} from "@asirisos/types";

function CropForm() {

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const {t} = useTranslation('crop');
    const {crop, status} = useSelector((state: RootState) => state.crop)

    const [formData, setFormData] = useState({
        cropName: "",
        farmId: "",
        developmentStage: "0",
        stageProgress: "",
        landArea: 0,
        areaDenomination: '',
        numberOfTrees: 0,
        expectedYield: 0,
        expectedYieldDenom: "",
        stockHarvested: 0,
        stockFulfilment: 0,
        salePrice: 0,
        salePriceDenom: "",
        expectedCost: 0,
        expectedCostDenom: "",
        waterNutrition: "",
        nitrogenNutrition: "",
        phosphorusNutrition: "",
        potassiumNutrition: "",
    })

    const {
        cropName,
        farmId,
        developmentStage,
        stageProgress,
        landArea,
        areaDenomination,
        numberOfTrees,
        expectedYield,
        expectedYieldDenom,
        stockHarvested,
        stockFulfilment,
        salePrice,
        salePriceDenom,
        expectedCost,
        expectedCostDenom,
        waterNutrition,
        nitrogenNutrition,
        phosphorusNutrition,
        potassiumNutrition
    } = formData


    const [formStep, setFormStep] = useState(0)

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault()

        const cropData: CropModel = {
            _id: crop?._id ? crop._id : "",
            cropName,
            farmId,
            developmentStage,
            stageProgress,
            landArea,
            areaDenomination,
            numberOfTrees,
            expectedYield,
            expectedYieldDenom,
            stockHarvested,
            stockFulfilment,
            salePrice,
            salePriceDenom,
            expectedCost,
            expectedCostDenom,
            waterNutrition,
            nitrogenNutrition,
            phosphorusNutrition,
            potassiumNutrition
        }

        dispatch(addCrop(cropData))
            .unwrap()
            .then(() => {
                toast.success(t("crop:cropAdded"));
                navigate("/crops")
            })
            .catch(() => {
                toast.error(t("crop:cropAddFailed"))
                navigate("/crop-form")
            })
    }

    if (status === Status.Pending) {
        return <Spinner/>
    }

    return (
        <>
            {/*************************************     FORM STEP NAVIGATION DISPLAY   **************************************/}

            <ul className="steps">
                <li className={`step px-12 ${formStep == 0 || 1 || 2 ? 'step-primary' : ''}`}>{t("crop:step-general")}</li>
                <li className={`step ${formStep == 1 || formStep == 2 ? 'step-primary' : ''}`}>{t("crop:step-land-finance")}</li>
                <li className={`step ${formStep == 2 ? 'step-primary' : ''}`}>{t("crop:step-nutrition")}</li>
            </ul>

            {/*************************************      FORM COMPONENT   **************************************/}
            <FormCard title={t("crop:addCrop")}>
                <form onSubmit={onSubmit}>

                    {/*************************************  STEP 0  NAVIGATION ****************************************/}

                    {formStep === 0 ? (
                        <>
                            <FormTwoInputs>
                                <NumberInputInUnits onChange={(e) => onChange(e)} value={cropName} id={"cropName"}
                                                    type={'text'} group={"cropName"} label={"cropName"}
                                                    name={"cropName"} dataTitle={t("crop:cropName")}/>
                                <NumberInputInUnits onChange={(e) => onChange(e)} value={farmId} id={"farmId"}
                                                    type={'text'} group={"farmId"} label={"farmId"} name={"farmId"}
                                                    dataTitle={t("translation:farmId")}/>
                            </FormTwoInputs>

                            <FormSelectionRangeInput
                                title={t("crop:cropStage")}
                                range={<input onChange={onChange} name="developmentStage" id="developmentStage"
                                              type="range" min="0" max="100" value={developmentStage}
                                              className="range range-sm range-primary" step="20"/>}>
                                <span><h1
                                    className={`text-base  ${developmentStage === "0" ? "text-primary text-2xl" : "text-gray-400"}`}>{t("crop:stage-dormant")}</h1></span>
                                <span><h1
                                    className={`text-base  ${developmentStage === "20" ? "text-primary text-2xl" : "text-gray-400"}`}>{t("crop:stage-budding")}</h1></span>
                                <span><h1
                                    className={`text-base  ${developmentStage === "40" ? "text-primary text-2xl" : "text-gray-400"}`}>{t("crop:stage-flowering")}</h1></span>
                                <span><h1
                                    className={`text-base  ${developmentStage === "60" ? "text-primary text-2xl" : "text-gray-400"}`}>{t("crop:stage-fruit-set")}</h1></span>
                                <span><h1
                                    className={`text-base  ${developmentStage === "80" ? "text-primary text-2xl" : "text-gray-400"}`}>{t("crop:stage-fruit-dev")}</h1></span>
                                <span><h1
                                    className={`text-base  ${developmentStage === "100" ? "text-primary text-2xl" : "text-gray-400"}`}>{t("crop:stage-harvest")}</h1></span>
                            </FormSelectionRangeInput>

                            <FormRangeInput title={t("crop:stageProgress")}>
                                <input onChange={onChange} name="stageProgress" id="stageProgress" type="range" min="0"
                                       max="100" value={stageProgress}
                                       className={`range mt-1 range-sm range-primary ${parseInt(stageProgress) <= 50 ? 'range-base-100' : 'range-primary'} `}/>
                                <h1 className="text-3xl">{stageProgress}%</h1>
                            </FormRangeInput>
                        </>
                    ) : <></>}

                    {/*************************************  STEP 1  NAVIGATION ****************************************/}

                    {formStep === 1 ? (
                        <>
                            <FormTwoInputs>
                                <NumberInputInUnits denominations={['select', 'ha', 'ac', 'sq m']}
                                                    dataTitle={t("crop:landArea")}
                                                    dName={"areaDenomination"} dValue={areaDenomination}
                                                    dId={"areaDenomination"} dType={'string'}
                                                    onChange={(e) => onChange(e)} value={landArea.toString()}
                                                    id={"landArea"} type={'number'} name={"landArea"}/>
                                <NumberInputInUnits onChange={(e) => onChange(e)} value={numberOfTrees.toString()}
                                                    id={"numberOfTrees"} type={'text'} group={"numberOfTrees"}
                                                    placeholder={"Number of Trees"} dataTitle={t("crop:numberOfTrees")}
                                                    label={"numberOfTrees"} name={"numberOfTrees"}/>
                            </FormTwoInputs>

                            <FormTwoInputs>
                                <NumberInputInUnits onChange={(e) => onChange(e)} value={stockHarvested.toString()}
                                                    fixedInputItem={"kg"} id={"stockHarvested"} type={'number'}
                                                    group={"stockHarvested"} dataTitle={t("crop:harvestedStock")}
                                                    label={"stockHarvested"} name={"stockHarvested"}/>
                                <NumberInputInUnits onChange={(e) => onChange(e)} value={stockFulfilment.toString()}
                                                    fixedInputItem={"kg"} id={"stockFulfilment"} type={'number'}
                                                    group={"stockFulfilment"} placeholder={"Stock in Fulfilment"}
                                                    dataTitle={t("crop:stockInFulfilment")} label={"stockFulfilment"}
                                                    name={"stockFulfilment"}/>
                            </FormTwoInputs>

                            <FormTwoInputs>
                                <NumberInputInUnits denominations={['select', '/kg', '/ton']}
                                                    dataTitle={t("crop:expectedSalePrice")} dName={"salePriceDenom"}
                                                    fixedInputItem={"$"} dValue={salePriceDenom} dId={"salePriceDenom"}
                                                    dType={'string'}
                                                    onChange={(e) => onChange(e)} value={salePrice.toString()}
                                                    id={"salePrice"} type={'string'} name={"salePrice"}/>

                                <NumberInputInUnits denominations={['select', '/kg', '/ton']}
                                                    dataTitle={t("crop:expectedCost")}
                                                    dName={"expectedCostDenom"} fixedInputItem={"$"}
                                                    dValue={expectedCostDenom} dId={"expectedCostDenom"}
                                                    dType={'string'}
                                                    onChange={(e) => onChange(e)} value={expectedCost.toString()}
                                                    id={"expectedCost"} type={'string'} name={"expectedCost"}/>
                            </FormTwoInputs>

                            <FormTwoInputs>
                                <NumberInputInUnits denominations={['select', '/tree', '/ha', '/sq m']}
                                                    dataTitle={t("crop:expectedYield")} dName={"expectedYieldDenom"}
                                                    fixedInputItem={"kg"} dValue={expectedYieldDenom}
                                                    dId={"expectedYieldDenom"} dType={'string'}
                                                    onChange={(e) => onChange(e)} value={expectedYield.toString()}
                                                    id={"expectedYield"} type={'string'} name={"expectedYield"}/>
                            </FormTwoInputs>
                        </>
                    ) : (<></>)}

                    {/*************************************  STEP 2  NAVIGATION ****************************************/}

                    {formStep === 2 ? (
                        <>
                            <FormRangeInput title={t("crop:waterNutritionLevel")}>
                                <input onChange={onChange} name="waterNutrition" id="waterNutrition" type="range"
                                       min="0" max="100" value={waterNutrition}
                                       className={`range mt-1 range-sm range-primary ${parseInt(waterNutrition) <= 50 ? 'range-base-100' : 'range-primary'} `}/>
                                <h1 className="text-3xl">{waterNutrition}%</h1>
                            </FormRangeInput>

                            <FormRangeInput title={t("crop:nitrogenNutritionLevel")}>
                                <input onChange={onChange} name="nitrogenNutrition" id="nitrogenNutrition" type="range"
                                       min="0" max="100" value={nitrogenNutrition}
                                       className={`range mt-1 range-sm range-primary ${parseInt(nitrogenNutrition) <= 50 ? 'range-base-100' : 'range-primary'} `}/>
                                <h1 className="text-3xl">{nitrogenNutrition}%</h1>
                            </FormRangeInput>

                            <FormRangeInput title={t("crop:phosphorusNutritionLevel")}>
                                <input onChange={onChange} name="phosphorusNutrition" id="phosphorusNutrition"
                                       type="range" min="0" max="100" value={phosphorusNutrition}
                                       className={`range mt-1 range-sm range-primary ${parseInt(phosphorusNutrition) <= 50 ? 'range-base-100' : 'range-primary'} `}/>
                                <h1 className="text-3xl">{phosphorusNutrition}%</h1>
                            </FormRangeInput>

                            <FormRangeInput title={t("crop:potassiumNutritionLevel")}>
                                <input onChange={onChange} name="potassiumNutrition" id="potassiumNutrition"
                                       type="range" min="0" max="100" value={potassiumNutrition}
                                       className={`range mt-1 range-sm range-primary ${parseInt(potassiumNutrition) <= 50 ? 'range-base-100' : 'range-primary'} `}/>
                                <h1 className="text-3xl">{potassiumNutrition}%</h1>
                            </FormRangeInput>

                            <div className='flex justify-center pt-8 p-2'>
                                <button className="btn btn-wide btn-primar hover:bg-black">{t("crop:addCrop")}</button>
                            </div>
                        </>
                    ) : (<></>)}

                </form>

                {/************************************* FORM STEP NAVIGATION ****************************************/}
                <div className="flex justify-center pt-8 p-2">
                    <button
                        className="px-3 py-2 border-2 border-indigo-500 border-r-0 text-black rounded-l-lg disabled:hover:bg-gray-100 disabled:text-gray-300 hover:bg-indigo-500 hover:text-white "
                        disabled={formStep == 0} onClick={() => {
                        setFormStep(curStep => (curStep - 1))
                    }}>{t("translation:previous")}
                    </button>
                    <button
                        className="px-6 py-2 border-2 border-indigo-500 border-l text-black rounded-r-lg disabled:hover:bg-gray-100 disabled:text-gray-300 hover:bg-indigo-500 hover:text-white"
                        disabled={formStep == 2} onClick={() => {
                        setFormStep(curStep => (curStep + 1))
                    }}>{t("translation:next")}
                    </button>
                </div>
            </FormCard>
        </>
    )
}


export default CropForm