import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {FixMeLater} from "../../types/dev";
import {TimeSeriesDataState} from "../../types/states";
import HandleAxiosError from "../../utils/AxiosErrorHandler";
import {DefaultThunkApiConfig, Status} from "../../types/slice";
import timeSeriesDataService from "./timeSeriesDataService";
import {StorageModel, DataTopicModel, TimeSeriesDataModel} from "@asirisos/types";

const CONTEXT = "TimeSeriesData"

const initialState: TimeSeriesDataState = {
    timeSeriesData: undefined,
    allTimeSeriesData: [],
    status: Status.Unknown,
    message: ''
}

export const addTimeSeriesData = createAsyncThunk<any, DataTopicModel, DefaultThunkApiConfig>('storage/add', async (timeSeriesData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await timeSeriesDataService.addTimeSeriesData(timeSeriesData, account)
        if (response.status !== 201)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const addTimeSeriesDataPoint = createAsyncThunk<DataTopicModel, TimeSeriesDataModel, DefaultThunkApiConfig>('TSData/data', async (dataPoint, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account
        if (!account)
            return thunkAPI.rejectWithValue()


        const response = await timeSeriesDataService.addTimeSeriesDataPoint(dataPoint, account)
        // console.log('Live Stock Slice - Add Weight - response', response)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        // console.log('Live Stock Slice - Add Weight - catch error', error)
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getAllTimeSeriesData = createAsyncThunk<DataTopicModel[], void, DefaultThunkApiConfig>('Storage/getall', async (_, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await timeSeriesDataService.getAllTimeSeriesData(account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getTimeSeriesDataByItemId = createAsyncThunk<DataTopicModel[], string, DefaultThunkApiConfig>('TSData/byItemId/getall', async (connectedItemId, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if (!account)
            return thunkAPI.rejectWithValue();

        const response = await timeSeriesDataService.getTimeSeriesDataByItemId(connectedItemId, account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const getTimeSeriesData= createAsyncThunk<DataTopicModel, string, DefaultThunkApiConfig>('storage/getone/:storageId', async (connectedItemId, thunkAPI) => {
    try {
        const response = await timeSeriesDataService.getTimeSeriesData(connectedItemId)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const updateTimeSeriesData= createAsyncThunk<DataTopicModel, DataTopicModel, DefaultThunkApiConfig>('storage/updateStorage/:storageId', async (timeSeriesData, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if(!account)
            return thunkAPI.rejectWithValue();
            
        const response = await timeSeriesDataService.updateTimeSeriesData(timeSeriesData, account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue()

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
    // try {
    //     const token = thunkAPI.getState().auth.user.token
    //     return await timeSeriesDataService.updateStorage(timeSeriesData, token)
    // } catch (error) {
    //     HandleAxiosError(error, CONTEXT);
    //     return thunkAPI.rejectWithValue("message")
    // }
})

export const deleteTimeSeriesData= createAsyncThunk<any, string[], DefaultThunkApiConfig>('storage/delete/:storageId', async (storageId, thunkAPI) => {
    try {
        const account = thunkAPI.getState().account.account;
        if(!account)
            return thunkAPI.rejectWithValue();

            console.log('Test account in delete Storage', account, storageId)
        const response = await timeSeriesDataService.deleteTimeSeriesData(storageId, account)
        if (response.status !== 200)
            return thunkAPI.rejectWithValue();

        return response.data
    } catch (error) {
        HandleAxiosError(error, CONTEXT);
        return thunkAPI.rejectWithValue()
    }
})

export const storageSlice = createSlice({
    name: 'timeSeriesData',
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(addTimeSeriesData.pending, (state: TimeSeriesDataState) => {
                state.status = Status.Pending
            })
            .addCase(addTimeSeriesData.fulfilled, (state: TimeSeriesDataState) => {
                state.status = Status.Success
            })
            .addCase(addTimeSeriesData.rejected, (state: TimeSeriesDataState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            .addCase(getAllTimeSeriesData.pending, (state: TimeSeriesDataState) => {
                state.status = Status.Pending
            })
            .addCase(getAllTimeSeriesData.fulfilled, (state: TimeSeriesDataState, action: PayloadAction<DataTopicModel[], any, any>) => {
                state.status = Status.Success
                state.allTimeSeriesData = action.payload
            })
            .addCase(getAllTimeSeriesData.rejected, (state: TimeSeriesDataState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            .addCase(getTimeSeriesDataByItemId.pending, (state: TimeSeriesDataState) => {
                state.status = Status.Pending
            })
            .addCase(getTimeSeriesDataByItemId.fulfilled, (state: TimeSeriesDataState, action: PayloadAction<DataTopicModel[], any, any>) => {
                state.status = Status.Success
                state.allTimeSeriesData = action.payload
            })
            .addCase(getTimeSeriesDataByItemId.rejected, (state: TimeSeriesDataState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            .addCase(getTimeSeriesData.pending, (state: TimeSeriesDataState) => {
                state.status = Status.Pending
            })
            .addCase(getTimeSeriesData.fulfilled, (state: TimeSeriesDataState, action: PayloadAction<DataTopicModel, any, any>) => {
                state.status = Status.Success
                state.timeSeriesData = action.payload
            })
            .addCase(getTimeSeriesData.rejected, (state: TimeSeriesDataState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            .addCase(updateTimeSeriesData.pending, (state: TimeSeriesDataState) => {
                state.status = Status.Pending
            })
            .addCase(updateTimeSeriesData.fulfilled, (state: TimeSeriesDataState, action: PayloadAction<DataTopicModel, any, any>) => {
                state.status = Status.Success
                state.allTimeSeriesData.map((storage: DataTopicModel) => storage._id === action.payload._id ?
                    (state.timeSeriesData = storage) : storage)
                state.timeSeriesData = action.payload
            })
            .addCase(updateTimeSeriesData.rejected, (state: TimeSeriesDataState, action: FixMeLater) => {
                state.status = Status.Failure
                state.message = action.payload
            })
            // .addCase(updateTimeSeriesData.fulfilled, (state: TimeSeriesDataState, action: FixMeLater) => {
            //     state.isLoading = false
            //     state.allTimeSeriesData.map((storage) => TimeSeriesData._id === action.payload._id ? (state.timeSeriesData = storage) : storage)
            // })
            .addCase(deleteTimeSeriesData.pending, (state: TimeSeriesDataState) => {
                state.status = Status.Pending
            })
            .addCase(deleteTimeSeriesData.fulfilled, (state: TimeSeriesDataState) => {
                state.status = Status.Success
                state.timeSeriesData = undefined
            })
            .addCase(deleteTimeSeriesData.rejected, (state: TimeSeriesDataState) => {
                state.status = Status.Failure
            })
    }
})

export const {reset} = storageSlice.actions
export default storageSlice.reducer