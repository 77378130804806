import axios from "axios"
import {AccountModel, CropModel} from "@asirisos/types";
import RequestBodyFactory from "../../factories/requestBodyFactory";

const API_URL = '/api/crops'

const addCrop = async (cropData: CropModel, account: AccountModel) => {
    const body = new RequestBodyFactory(cropData).WithAccount(account).Build();
    return await axios.post(API_URL, body)
}

const getCrops = async (account: AccountModel) => {
    return await axios.get(API_URL + '/all/' + account.farm._id)
}

const getCrop = async (cropId: string) => {
    return await axios.get(API_URL + '/' + cropId)
}

const updateCrop = async (cropData: CropModel) => {
    const body = new RequestBodyFactory(cropData).Build();
    return await axios.put(API_URL + '/' + cropData._id, body)
}

const deleteCrop = async (cropId: string) => {
    return await axios.delete(API_URL + '/' + cropId)
}

const cropService = {
    addCrop,
    getCrops,
    getCrop,
    updateCrop,
    deleteCrop
}

export default cropService