import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {getAllEquipment, deleteEquipment} from '../../../features/equipment/equipmentSlice'
import Spinner from '../../components/Spinner'
import {DataGrid, GridColumns} from '@mui/x-data-grid';
import {Link, useNavigate} from 'react-router-dom'
import {AppDispatch, RootState} from "../../../app/store";
import {Status} from "../../../types/slice";
import {useTranslation} from "react-i18next";
import {toast} from 'react-toastify'
import DeleteModal from 'UI/components/DeleteModal';
import TableMultiDeleteButton from 'UI/components/TableMultiDeleteButton';


const columns = (t: any): GridColumns => [
    {field: '_id', headerName: t("translation:id"), width: 70},
    {
        field: 'img',
        headerName: t("translation:user"),
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        width: 160,
        renderCell: (params) => {
            return (

                <div className='flex flex-row items-center'>
                    <img className=' rounded-xl  w-9 h-9 mr-4'
                         src="https://www.pngkey.com/png/detail/71-719747_tractor-clipart-smoke-tractors-icons-png.png"
                         alt=""/>
                    <div className='text-indigo'>
                        {params.row.name}
                    </div>
                </div>

            )
        }
    },
    {field: 'type', headerName: t("translation:type"), width: 120},
    {field: 'milage', headerName: t("equipment:mileage"), width: 120},
    {
        field: 'action',
        headerName: t("translation:action"),
        width: 100,
        renderCell: (params) => {
            return (
                <div className='flex flex-row px-2 pl-4 justify-center items-center gap-2'>
                    <Link to={`/equipment/${params.row._id}`}
                        className="border-2 rounded-lg text-blue-500 px-2">{t("translation:view")}
                    </Link>
                </div>
            )
        }
    }
];




function EquipmentTable() {

const [modalIsOpen, setModalIsOpen] = useState(false)

const onDeleteClick = (e: any) => {
    setModalIsOpen(!modalIsOpen)
    console.log(modalIsOpen)
}

const [deleteEvent, setDeleteEvent] = useState<boolean>(true)
const [selectedRows, setSelectedRows] = useState<string[]>([])

const handleSelectionChange = (newSelectionModel : string[]) => {
    setSelectedRows(newSelectionModel)
}

const navigate = useNavigate()

const onDelete = () => {
    dispatch(deleteEquipment(selectedRows))
    .unwrap()
    .then(() => {
        toast.success(t("equipment:equipmentDeleted"));
        navigate("/equipment")
        setModalIsOpen(false)
    })
    .catch(() => {
        toast.error(t("equipment:failedToDeleteequipment"))
    })
    setDeleteEvent(!deleteEvent)
    setDeleteEvent(!deleteEvent)
    setSelectedRows([])
}

    const {equipments, status} = useSelector((state: RootState) => state.equipment)
    const {t} = useTranslation(["equipment", "translation"])
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (status !== Status.Pending || deleteEvent === true || deleteEvent === false)
            dispatch(getAllEquipment())
    }, [dispatch, deleteEvent])


    if (status === Status.Pending) {
        return <Spinner/>
    }
    return (
        <>

        {selectedRows.length !== 0 ? (<>
        <TableMultiDeleteButton onDeleteClick={onDeleteClick} />
        </>) :(<></>) }


            <div style={{height: 800, width: 'full'}}>
                <DataGrid
                    getRowId={(row) => row._id}
                    rows={equipments}
                    columns={columns(t)}
                    pageSize={10}
                    rowsPerPageOptions={[25]}
                    checkboxSelection
                    onSelectionModelChange={(newSelectionModel : any) => {
                        handleSelectionChange(newSelectionModel)
                      }}
                />
            </div>

            {/* ////////////////////////////////////////////////////////////////////// */}
            {/* //////////////////////////// DELETE MODAL //////////////////////////// */}

            <DeleteModal modalIsOpen={modalIsOpen} onDeleteClick={onDeleteClick} onDelete={onDelete} />

        </>
    )
}

export default EquipmentTable