import React, {useState, useEffect, ChangeEvent} from 'react'
import NumberInputInUnits from 'UI/components/shared/NumberInputInUnits'
import LegacyDropDownInput from 'UI/components/shared/LegacyDropDownInput';
import {InputSelection} from "../../../types/ui";
import {useTabPersistence} from "../../../hooks/useTabPersistence";
import {PageId} from "../../../types/pages";
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import {AppDispatch, RootState} from "../../../app/store";
import { addJoinOrgWaitlist, addJoinShopperWaitlist } from 'features/waitlist/waitlistSlice';
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import LargeTextInput from 'UI/components/shared/LargeTextInput';
import SearchBox from '../Map/SearchBox-1';
import InlineEditDropDown from 'UI/components/shared/InlineEditDropDown';
import MultiDropDownInput from 'UI/components/shared/MultiDropDownInput';
import joinWaitlistService from 'features/waitlist/waitlistService';
import {useTranslation} from "react-i18next";

function JoinAsFan() {

  const {selection, toggleTab} = useTabPersistence(PageId.Crops);

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const {t} = useTranslation('crop');

    const [formData, setFormData] = useState({
      addressObject: {},
    })

    const {addressObject} = formData

    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setFormData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
      }))
    }

    const onSetAddress = (selectPosition : object) => {
      setShopperFormData((prevState) => ({
          ...prevState,
          addressObject: selectPosition!
      }) )
    }

    const [shopperFormData, setShopperFormData] = useState({
        name: '',
        email: '',
        phone: '',
        location: {},
        shopperCity: '',
        shopperZip: ''
      })

    const [orgFormData, setOrgFormData] = useState({
        orgName: '',
        orgEmail: '',
        orgPhone: '',
        orgLocation: '',
        orgSkills: '',
        orgCategory: '',
        orgZip: '',
        orgCity: ''
      })

      const {name,  email, phone, location, shopperCity, shopperZip} = shopperFormData

      const {orgName, orgEmail, orgPhone, orgLocation, orgSkills, orgCategory, orgZip, orgCity} = orgFormData

      const categorySelection = [new InputSelection('farmer', 'farmer'), new InputSelection('rancher', 'rancher'), new InputSelection('distribution', 'distribution'),new InputSelection('food processing', 'food processing'), new InputSelection('farm services', 'farm services'), 
      new InputSelection('farmers market', 'farmers market'), new InputSelection('inputs', 'inputs'), new InputSelection('equipment and machinery', 'equipment and machinery'), new InputSelection('seeds', 'seeds'),
      new InputSelection('storage', 'storage'), new InputSelection('other', 'other')]

    const onChangeShopper = (e: React.ChangeEvent<HTMLInputElement>) => {
        setShopperFormData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }))
      }
    
    const onChangeOrg = (e: React.ChangeEvent<HTMLInputElement>) => {
        setOrgFormData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }))
      }

    const onSubmitShopper = (e: React.FormEvent) => {
        e.preventDefault()

        if (email === '' || name === '' || phone === '' || shopperZip === '' || shopperCity === '' )  {
          toast.error('please complete all fields')
        } else {

          const joinShopperWaitlistData = {
            name,
            email,
            phone,
            shopperCity,
            shopperZip
          }
        //   dispatch(addJoinShopperWaitlist(joinShopperWaitlistData))
        //   .unwrap()
        //   .then(() => {
              toast.success(t("success"));
              navigate("/success")
        //   })
        //   .catch(() => {
            toast.error(t("fail"))
        //     navigate("/fail")
        // })
        }
    }
    
    const onSubmitOrg = (e: React.FormEvent) => {

      e.preventDefault()

      if (orgEmail === '' || orgName === '' || orgPhone === '' || orgZip === '' )  {
        toast.error('please complete all fields')
      } else {
        {
          const joinOrgWaitlistData = {
            orgName,
            orgEmail,
            orgPhone,
            orgLocation,
            orgSkills,
            orgCategory,
            orgZip,
            orgCity
          }

        //   dispatch(addJoinOrgWaitlist(joinOrgWaitlistData))
            // .unwrap()
            // .then(() => {
                toast.success(t("success"));
                // navigate("/success")
        //     })
        //     .catch(() => {
        //       toast.error(t("fail"))
        //       navigate("/fail")
        //   })
        }
      }
    }
      

  return (
    <>
    {/********************* MOBILE VERSION - START ***********************/}
    {/********************************************/}
    {/********************************************/}

    <div className='lg:hidden sm:visible '>

      <div className='flex flex-col '>

        {/********************************************/}
        {/********************* FIRST PAGE MOBILE - START ***********************/}
        {/********************************************/}

        <div className='w-screen h-screen flex flex-col justify-middle align-middle'>

          {/********************* MENU BAR - START ***********************/}
          {/********************************************/}
          <div className='bg-black flex justify-around py-2 drop-shadow-md  '> 
            <Link to="/combat-fighter-profile">
                <div className='flex flex-col justify-center py-2' >
                    <h1 className='text-xl font-semibold text-white font-sofachrome' >Combat Link</h1>   
                </div>
            </Link>
            <Link className=" text-white hover:bg-[#e50f0fa3] hover:shadow-lg rounded-lg px-4 items-center bg-[#e50f0f] flex justify-center " to={"/combat-login"} >
                <h1 className=' text-lg font-normal justify-center align-middle'>Login</h1>
            </Link>
            </div>
          {/********************* MENU BAR - END ***********************/}
          {/********************************************/}

          
            
          



          <div className='flex flex-col justify-center items-center h-full bg-slate-100'>
          <h1 className='text-slate-700 font-semibold text-3xl' >Register</h1>
          <h1 className='text-slate-700 font-normal text-xl pb-10' >to support your favourite athletes</h1>

            
              <div className='flex flex-col justify-center bg-slate-100 pb-10'>
              

                <form onSubmit={onSubmitOrg}>

                  <div className='flex justify-center ' >

                    <div className='max-w-xl px-5'>
                      <NumberInputInUnits onChange={(e) => onChangeOrg(e)} value={orgName} id={"orgName"} type={'text'} group={"orgName"} label={"orgName"} name={"orgName"} dataTitle="Name" />
                      <NumberInputInUnits onChange={(e) => onChangeOrg(e)} value={orgEmail} id={"orgEmail"} type={'text'} group={"orgEmail"} label={"orgEmail"} name={"orgEmail"} dataTitle="Email" />
                      <NumberInputInUnits onChange={(e) => onChangeOrg(e)} value={orgPhone} id={"orgPhone"} type={'text'} group={"orgPhone"} label={"orgPhone"} name={"orgPhone"} dataTitle="Phone Number" />
                      <NumberInputInUnits onChange={(e) => onChangeOrg(e)} value={orgZip} id={"orgZip"} type={'text'} group={"orgZip"} label={"orgZip"} name={"orgZip"} dataTitle="Password" />
                      
                    </div>

                  </div>

                  <div className='flex justify-center pt-8 p-2' >
                      <button className="text-white hover:bg-[#e50f0fa3] hover:shadow-lg rounded-lg px-14 py-4 items-center bg-[#e50f0f] flex justify-center">Register</button>
                  </div>

                </form>
              </div>
            

          </div>
        </div>

        {/********************************************/}
        {/********************* FIRST PAGE MOBILE - END ***********************/}
        {/********************************************/}

      </div>
    </div>

    {/********************* MOBILE VERSION - END ***********************/}
    {/********************************************/}
    {/********************************************/}





        {/********************************************/}
    {/********************************************/}
    {/********************* DESKTOP VERSION START ***********************/}
    {/********************************************/}
    {/********************************************/}

    <div className='lg:visible invisible max-sm:hidden items-center justify-center' >

        {/********************************************/}
        {/********************* FIRST PAGE DESKTOP - START ***********************/}
        {/********************************************/}

        <div className='w-screen h-screen flex flex-col max-sm:justify-start justify-center bg-[#fefefe]'>

            <h1>Desktop Version Coming Soon ...</h1>

        
        </div>

        {/********************************************/}
        {/********************* SECOND PAGE DESKTOP - END ***********************/}
        {/********************************************/}

    </div>
    {/********************************************/}
    {/********************************************/}
    {/********************* DESKTOP VERSION END ***********************/}
    {/********************************************/}
    {/********************************************/}
    </>
  )
}

export default JoinAsFan