import axios from "axios";
import {AccountModel, OrgFarmersMarketModel} from "@asirisos/types";
import RequestBodyFactory from "../../factories/requestBodyFactory";

const API_URL = '/api/orgfarmersmarkets'

// Add Organization

const addOrgFarmersMarket = async(orgFarmersMarketData: OrgFarmersMarketModel, account: AccountModel) => {
    console.log("Org Farmers Market service - sending data - add organization - organization Data", orgFarmersMarketData)
    const body = new RequestBodyFactory(orgFarmersMarketData).WithAccount(account).Build()
    console.log("Org Farmers Market service - sending data - add organization - body", body)
    return await axios.post(API_URL + '/add', body)
}

// Get Organizations
const getOrgFarmersMarkets = async() => {
    console.log('--------------------------------- FARMERS MARKET GET ALLL - SERVICE --------------------')
    return await axios.get(API_URL + '/all')
    
    // return await axios.get(API_URL + '/all/' + account.farm._id)
}

// Get Single OrgFarmersMarket
const getOrgFarmersMarket = async(listing_id: string) => {
    return await axios.get(API_URL + '/' + listing_id)
}

// Update Organization

const updateOrgFarmersMarket = async(orgFarmersMarketData: OrgFarmersMarketModel, account: AccountModel) => {
    const {_id} = orgFarmersMarketData
    const body = new RequestBodyFactory(orgFarmersMarketData).WithAccount(account).Build();

    return await axios.put(API_URL + 'update/' + _id, body)
}

// Delete OrgFarmersMarket

const deleteOrgFarmersMarket = async(orgFarmersMarketId: string[], account: AccountModel) => {
    const ids = orgFarmersMarketId
    const config = {
        data: {ids, account}
    }
    console.log('delete organization config',config)
    return await axios.delete(API_URL+ '/' + orgFarmersMarketId, config)
}

const orgFarmersMarketService = {
    addOrgFarmersMarket,
    getOrgFarmersMarkets,
    getOrgFarmersMarket,
    updateOrgFarmersMarket,
    deleteOrgFarmersMarket
}

export default orgFarmersMarketService