import React, { useState, useEffect } from 'react'
import {searchFarm} from '../../../features/farms/farmSlice'
import {useSelector, useDispatch} from 'react-redux'
import {toast} from 'react-toastify'
import Spinner from '../../components/Spinner'
import {RootState} from "../../../app/store";
import {AnyAction, ThunkDispatch} from "@reduxjs/toolkit";
import {FarmState} from "../../../types/states";
import { addFarmJoin } from '../../../features/farm-join/farm-joinSlice'
import { getUserFarmJoins } from '../../../features/farm-join/farm-joinSlice'
import FormCard from '../../components/shared/FormCard'
import {useTranslation} from "react-i18next";
import {Status} from "../../../types/slice";
import {FarmJoinModel} from "@asirisos/types";

function SearchFarm() {
    const {t} = useTranslation("farm")
    const {user} = useSelector((state : RootState) => state.auth)
    const {farm, status} = useSelector((state : RootState) => state.farm)
    const {farmJoins} = useSelector((state : RootState) => state.farmJoin)

    const UserId = { UserId : user?._id }
    const [formData, setFormData] = useState({
        farmAlias: '',
    })

    const {farmAlias} = formData

    const dispatch : ThunkDispatch<FarmState, any, AnyAction> = useDispatch()

    useEffect(() => {
        if (UserId)
            dispatch(getUserFarmJoins(UserId))
    },[dispatch, status] )

    if(status === Status.Failure) {
        toast.error(t("farm:farmGetFailed")) }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }) )
    }

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        dispatch(searchFarm(farmAlias))
        if(status === Status.Success) {
            toast.success('Farm Searched')
        }
    }

    const onSubmitJoin = (e: React.MouseEvent) => {
        e.preventDefault()

        const farmJoinData: FarmJoinModel =
            {
                FarmId : (e.target as HTMLButtonElement).value,
            }
        console.log((e.target as HTMLButtonElement).value)

        dispatch(addFarmJoin(farmJoinData))
        toast.success('Join Request Sent')
    }
  

    if(status === Status.Pending) {
        return <Spinner/>
    }
  return (
      <>
      
      <FormCard title='Search Farm'>
        
          <form onSubmit={onSubmit}>
        <div className="space-y-7 pt-8">
          <div className="flex flex-row justify-around" >
            <input 
            type="farmAlias"
            id="farmAlias"
            name="farmAlias"
            value={farmAlias}
            onChange={onChange}
            className="input input-bordered w-full max-w-xs"/>
          </div>

          <div className="flex flex-row justify-around" >
          </div>
      </div>
      <div className='flex justify-center pt-8 p-2' >
      <button className="btn btn-wide btn-primar hover:bg-black">Search Farm</button>
      </div>
      </form>
      </FormCard>
    
      {farm?._id ? (<>
        <div className='mt-12 bg-white py-5 rounded-lg #drop-shadow-xl #shadow-lg relative px-4 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]'> 
        Search Result
        <div className='flex mt-4 flex-row items-center justify-around bg-white py-4 rounded-lg #drop-shadow-xl #shadow-lg relative px-4 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]   '>{farm.name} ({farm.alias})
            <div>
            <button onClick={onSubmitJoin} value={farm._id}  className="px-3 py-2 border-2 border-indigo-500 text-black rounded-lg disabled:hover:bg-gray-100 disabled:text-gray-300 hover:bg-indigo-500 hover:text-white ">Join Farm</button>
            </div>
        </div>
        </div></>) : (<>  </>)}

        <div className='mt-12 bg-white py-5 rounded-lg #drop-shadow-xl #shadow-lg relative px-4 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]'>
            {farmJoins?.length !== 0 ? (<div> Join Requests
        {/* Join Requests  */}
    {farmJoins?.map((farmJoin) => {
        return(
            <>
           <div className='pt-5 ' >
              <div className=' flex flex-row items-center justify-around bg-white py-4 rounded-lg #drop-shadow-xl #shadow-lg relative px-4 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.47)]   ' >
            <h1 className={`font-bold ${farmJoin.status}`}  >
                {`${farmJoin.FarmId}`}
            </h1>
                <h1 className={` ${farmJoin.status === 'pending' ? 'text-red-400' : 'text-green-500'}`} >
                {` ${farmJoin.status} `}
            </h1>
            {/* <button onClick={onSubmitApprove} value = {farmJoin._id} className="px-3 py-2 border-2 border-indigo-500 text-black rounded-lg disabled:hover:bg-gray-100 disabled:text-gray-300 hover:bg-indigo-500 hover:text-white ">Approve</button> */}
              </div>
            </div>
            </>
        )
        } )} </div>) : (<><div>No farm joins</div></>)}
    </div>
    </>
  )
}

export default SearchFarm