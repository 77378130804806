import React, {useState, useEffect, ChangeEvent} from 'react'
import NumberInputInUnits from 'UI/components/shared/NumberInputInUnits'
import LegacyDropDownInput from 'UI/components/shared/LegacyDropDownInput';
import {InputSelection} from "../../../types/ui";
import {useTabPersistence} from "../../../hooks/useTabPersistence";
import {PageId} from "../../../types/pages";
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import {AppDispatch, RootState} from "../../../app/store";
import { addJoinOrgWaitlist, addJoinShopperWaitlist } from 'features/waitlist/waitlistSlice';
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import LargeTextInput from 'UI/components/shared/LargeTextInput';
import SearchBox from '../Map/SearchBox-1';
import InlineEditDropDown from 'UI/components/shared/InlineEditDropDown';
import MultiDropDownInput from 'UI/components/shared/MultiDropDownInput';
import joinWaitlistService from 'features/waitlist/waitlistService';
import {useTranslation} from "react-i18next";

function CombatSponsor() {

  const {selection, toggleTab} = useTabPersistence(PageId.Crops);

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const {t} = useTranslation('crop');

    const [formData, setFormData] = useState({
      addressObject: {},
    })

    const {addressObject} = formData

    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setFormData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
      }))
    }

    const onSetAddress = (selectPosition : object) => {
      setShopperFormData((prevState) => ({
          ...prevState,
          addressObject: selectPosition!
      }) )
    }

    const [shopperFormData, setShopperFormData] = useState({
        name: '',
        email: '',
        phone: '',
        location: {},
        shopperCity: '',
        shopperZip: ''
      })

    const [orgFormData, setOrgFormData] = useState({
        orgName: '',
        orgEmail: '',
        orgPhone: '',
        orgLocation: '',
        orgSkills: '',
        orgCategory: '',
        orgZip: '',
        orgCity: ''
      })

      const {name,  email, phone, location, shopperCity, shopperZip} = shopperFormData

      const {orgName, orgEmail, orgPhone, orgLocation, orgSkills, orgCategory, orgZip, orgCity} = orgFormData

      const categorySelection = [new InputSelection('farmer', 'farmer'), new InputSelection('rancher', 'rancher'), new InputSelection('distribution', 'distribution'),new InputSelection('food processing', 'food processing'), new InputSelection('farm services', 'farm services'), 
      new InputSelection('farmers market', 'farmers market'), new InputSelection('inputs', 'inputs'), new InputSelection('equipment and machinery', 'equipment and machinery'), new InputSelection('seeds', 'seeds'),
      new InputSelection('storage', 'storage'), new InputSelection('other', 'other')]

    const onChangeShopper = (e: React.ChangeEvent<HTMLInputElement>) => {
        setShopperFormData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }))
      }
    
    const onChangeOrg = (e: React.ChangeEvent<HTMLInputElement>) => {
        setOrgFormData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }))
      }

    const onSubmitShopper = (e: React.FormEvent) => {
        e.preventDefault()

        if (email === '' || name === '' || phone === '' || shopperZip === '' || shopperCity === '' )  {
          toast.error('please complete all fields')
        } else {

          const joinShopperWaitlistData = {
            name,
            email,
            phone,
            shopperCity,
            shopperZip
          }
        //   dispatch(addJoinShopperWaitlist(joinShopperWaitlistData))
        //   .unwrap()
        //   .then(() => {
              toast.success(t("success"));
              navigate("/success")
        //   })
        //   .catch(() => {
            toast.error(t("fail"))
        //     navigate("/fail")
        // })
        }
    }
    
    const onSubmitOrg = (e: React.FormEvent) => {

      e.preventDefault()

      if (orgEmail === '' || orgName === '' || orgPhone === '' || orgZip === '' )  {
        toast.error('please complete all fields')
      } else {
        {
          const joinOrgWaitlistData = {
            orgName,
            orgEmail,
            orgPhone,
            orgLocation,
            orgSkills,
            orgCategory,
            orgZip,
            orgCity
          }

        //   dispatch(addJoinOrgWaitlist(joinOrgWaitlistData))
            // .unwrap()
            // .then(() => {
                toast.success(t("success"));
                // navigate("/success")
        //     })
        //     .catch(() => {
        //       toast.error(t("fail"))
        //       navigate("/fail")
        //   })
        }
      }
    }
      

  return (
    <>
    {/********************* MOBILE VERSION - START ***********************/}
    {/********************************************/}
    {/********************************************/}

    <div className='lg:hidden sm:visible '>

      <div className='flex flex-col '>

        {/********************************************/}
        {/********************* FIRST PAGE MOBILE - START ***********************/}
        {/********************************************/}

        <div className='w-screen h-screen flex flex-col justify-middle align-middle'>

          {/********************* MENU BAR - START ***********************/}
          {/********************************************/}
          <div className='bg-black flex justify-around py-2 drop-shadow-md  '> 
            <Link to="/combat-fighter-profile">
                <div className='flex flex-col justify-center py-2' >
                    <h1 className='text-xl font-semibold text-white font-sofachrome' >Combat Link</h1>   
                </div>
            </Link>
            <Link className=" text-white hover:bg-[#e50f0fa3] hover:shadow-lg rounded-lg px-4 items-center bg-[#e50f0f] flex justify-center " to={"/combat-login"} >
                <h1 className=' text-lg font-normal justify-center align-middle'>Login</h1>
            </Link>
            </div>
          {/********************* MENU BAR - END ***********************/}
          {/********************************************/}

          <div className='flex w-full  shadow-[2px_1px_20px_3px_rgba(201,201,201,0.67)] pt-2'>
            <div className='flex flex-col' >
                <img className='rounded-tl-xl w-48' src="https://a.espncdn.com/combiner/i?img=/i/headshots/mma/players/full/2966188.png&w=350&h=254" alt="" />
            </div>

            <div className=' flex flex-col justify-start'>                                    
                <h1 className='text-lg font-light self-start font-sofachrome text-[#e50f0f]'>THE TIGER</h1>   
                <h1 className='self-start font-light text-md pb-3 pl-1 text-slate-600' >Bantamweight</h1>                     
                <h1 className='max-w-xl text-2xl font-normal text-slate-700 self-start pl-2'>Magomed</h1>
                <h1 className='max-w-xl text-2xl font-normal text-slate-700 self-start pl-2' >Magomedov</h1>                    
            </div>
        </div>

            <div className=' w-full' >

              <ul className="flex felx-row justify-around">
                <li
                    className={`text-2xl  w-full py-2 text-white bg-[#e50f0f] shadow-[2px_1px_20px_3px_rgba(201,201,201,0.87)]'`}>{("Support your fighter")}
                </li>
              </ul>

            </div>



        <div className='flex flex-col justify-center items-center h-full bg-slate-100' >


              
    
              <div className='flex flex-col justify-center pb-10'>
                <form onSubmit={onSubmitShopper}>

                  <div className='flex justify-center ' >

                    <div className='max-w-xl px-5'>
                      <NumberInputInUnits onChange={(e) => onChangeShopper(e)} value={name} id={"name"} type={'text'} group={"name"} label={"name"} name={"name"} dataTitle="$ Amount" />
                      
                      <LargeTextInput onChange={(e) => onChangeOrg(e)} value={orgCategory} id={"orgCategory"} type={'text'} group={"orgCategory"} label={"orgCategory"} name={"orgCategory"} dataTitle="Note" />                    
                    </div>

                  </div>

                  <div className='flex justify-center pt-8 p-2' >
                      <button className="text-white hover:bg-[#e50f0fa3] hover:shadow-lg rounded-lg px-14 py-4 items-center bg-[#e50f0f] flex justify-center">Support</button>
                  </div>

                </form>
              </div>

          </div>
        </div>

        {/********************************************/}
        {/********************* FIRST PAGE MOBILE - END ***********************/}
        {/********************************************/}

      </div>
    </div>

    {/********************* MOBILE VERSION - END ***********************/}
    {/********************************************/}
    {/********************************************/}





        {/********************************************/}
    {/********************************************/}
    {/********************* DESKTOP VERSION START ***********************/}
    {/********************************************/}
    {/********************************************/}

    <div className='lg:visible invisible max-sm:hidden items-center justify-center' >

        {/********************************************/}
        {/********************* FIRST PAGE DESKTOP - START ***********************/}
        {/********************************************/}

        <div className='w-screen h-screen flex flex-col max-sm:justify-start justify-center bg-[#fefefe]'>

            <h1>Desktop Version Coming Soon ...</h1>

        
        </div>

        {/********************************************/}
        {/********************* SECOND PAGE DESKTOP - END ***********************/}
        {/********************************************/}

    </div>
    {/********************************************/}
    {/********************************************/}
    {/********************* DESKTOP VERSION END ***********************/}
    {/********************************************/}
    {/********************************************/}
    </>
  )
}

export default CombatSponsor