import React, {ChangeEvent} from 'react'
import { Link } from 'react-router-dom'
import SearchBox from '../../../Map/SearchBox-1'
import {useTabPersistence} from "../../../../../hooks/useTabPersistence";
import {useTranslation} from "react-i18next";
import {PageId} from "../../../../../types/pages";
import { useState } from 'react'
import DisplayAllFarmersMarkets from '../../..//Map/DisplayAllFarmersMarkets';
import Button from "../../../../components/shared/Button"
import DataChart from 'UI/pages/TimeSeriesData/DataChart';

function FatherProfile() {

    // const animation = require ('./farm_large.png')
    // const animation2 = require ('./adaptive-icon.png')
    // const vegetables_in_basket = require ('./vegetables_in_basket.jpg')
    // const vegetable_market = require ('./vegetable_market.jpg')

    const {t} = useTranslation(["landing", "translation"])
    const {selection, toggleTab} = useTabPersistence(PageId.Equipment);

    const [formData, setFormData] = useState({
        addressObject: {},
      })

    const {addressObject} = formData

    const [selectedPosition, setSelectPosition] = useState()


    const onSetAddress = (selectedPosition: any) => {
        setFormData((prevState) => ({
            ...prevState,
            addressObject: selectedPosition
        }) )
      }

    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
      }


    const pulseData = {
        _id: "640eb697a36359042449d497",
        dataTopic: "",
        description: "This is Data Topic to test",
        connectedItemId: "63c2df843632becd58658e50",
        timeSeriesData: [
            {data: 68,
            _id: '640f97353ec6b459a5734126',
            createdAt: '2023-03-13T21:35:49.755+00:00' },
            {data: 72,
            _id: '640f97353ec6b459a5734',
            createdAt: '2023-03-15T21:35:49.755+00:00'},
            {data: 76,
            _id: '640f97353ec6b459',
            createdAt: '2023-03-17T21:35:49.755+00:00'},
            {data: 79,
            _id: '640f97353ec6b459',
            createdAt: '2023-03-20T21:35:49.755+00:00'},
            {data: 67,
            _id: '640f97353ec6b459',
            createdAt: '2023-03-23T21:35:49.755+00:00'}
        ]
    }

    const benchData = {
        _id: "640eb697a36359042449d497",
        dataTopic: "",
        description: "This is Data Topic to test",
        connectedItemId: "63c2df843632becd58658e50",
        timeSeriesData: [
            {data: 130,
            _id: '640f97353ec6b459a5734126',
            createdAt: '2023-03-13T21:35:49.755+00:00' },
            {data: 135,
            _id: '640f97353ec6b459a5734',
            createdAt: '2023-03-15T21:35:49.755+00:00'},
            {data: 142,
            _id: '640f97353ec6b459',
            createdAt: '2023-03-17T21:35:49.755+00:00'},
            {data: 139,
            _id: '640f97353ec6b459',
            createdAt: '2023-03-20T21:35:49.755+00:00'},
            {data: 143,
            _id: '640f97353ec6b459',
            createdAt: '2023-03-23T21:35:49.755+00:00'},
            {data: 141,
            _id: '640f97353ec6b459',
            createdAt: '2023-03-23T21:35:49.755+00:00'},
            {data: 144,
            _id: '640f97353ec6b459',
            createdAt: '2023-03-23T21:35:49.755+00:00'},
            {data: 146,
            _id: '640f97353ec6b459',
            createdAt: '2023-03-23T21:35:49.755+00:00'},
            {data: 148,
            _id: '640f97353ec6b459',
            createdAt: '2023-03-23T21:35:49.755+00:00'}
                
        ]
    }


  return (
    <>
    {/********************************************/}
    {/********************************************/}
    {/********************************************/}
    {/********************* MOBILE VERSION ***********************/}
    {/********************************************/}
    {/********************************************/}
    {/********************************************/}

    <div className='lg:hidden sm:visible' >

        <div className='flex flex-col'>

            {/********************************************/}
            {/********************* FIRST PAGE MOBILE - START ***********************/}
            {/********************************************/}

            <div className='w-screen h-screen flex flex-col justify-start align-middle bg-[#fff9e1]'>

                {/********************************************/}
                {/********************* MENU BAR - START ***********************/}
                {/********************************************/}

                <div className='bg-[#16341fec] flex justify-around py-2 drop-shadow-md shadow-[2px_1px_20px_3px_rgba(201,
                    201,201,0.97)] '> 
                    <Link to="/">
                        <div className='flex flex-row justify-center py-2' >
                            <h1 className='text-md font-light text-white' >AsirisOS</h1>                        
                        </div>
                    </Link>
                    <Link className=" text-slate-700 hover:bg-[#FFB100] hover:shadow-lg rounded-sm px-4 py-1 bg-[#FFC500] flex justify-center " to={"/login"} >
                        <h1 className=' text-lg font-normal justify-center align-middle'>Login</h1>
                    </Link>
                </div>

                {/********************************************/}
                {/********************* MENU BAR - END ***********************/}
                {/********************************************/}


                <div className='w-full flex items-center justify-center  '>
                    <div className=' flex  border-t-0 rounded-xl rounded-t-none mb-4 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.67)]'>

                    </div>
                </div>


                {/********************************************/}
                {/********************* FIRST PAGE MOBILE - START ***********************/}
                {/********************************************/}

                <div className='flex flex-col justify-start h-full bg-[#fff9e1] px-2 pt-5'>

                    <div className='flex items-start pb-2 justify-center px-3'>
                        <h1 className=' max-w-xl text-2xl text-start font-semibold text-slate-800'> 
                            Reliable AI engines that provide Speed, Security, Scalability at the Edge
                        </h1>
                    </div>

                    <div className='flex justify-center'>
                        <div className='flex flex-col items-start pb-10'>                    
                            <h1 className='text-center font-semibold text-lg pt-2 text-slate-700'></h1>
                            <div className='flex'>
                                <h1 className='sm:py-1 max-sm:text-lg max-sm:font-normal text-left text-slate-700 px-3 pb-1'>Software for local food producers and consumers to build independent and healthy food systems for all.</h1>
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-col justify-center items-center py-5 pb-14' >
                        {/* <img className='rounded-2xl w-5/6 drop-shadow-2xl' src={vegetables_in_basket} alt="" />        */}
                    </div>

                    <div className=' flex flex-row gap-4 justify-center' >

                        <Link to="/join-the-waitlist">
                            <button className='rounded-md drop-shadow-xl hover:drop-shadow-2xl bg-[#FFC500]  py-3 font-normal text-slate-700 px-4 text-xl hover:bg-[#FFB100] hover:text-slate-700'>
                                Join the waitlist
                            </button>
                        </Link>

                        <Link to="/join-the-team">
                            <button className='rounded-md drop-shadow-xl hover:drop-shadow-2xl bg-[#FFC500]  py-3 font-normal text-slate-700 px-4 text-xl hover:bg-[#FFB100] hover:text-slate-700'>
                                Join the team
                            </button>
                        </Link>

                    </div>

                </div>

            </div>

            {/********************************************/}
            {/********************* FIRST PAGE MOBILE - END ***********************/}
            {/********************************************/}

            {/********************************************/}
            {/********************* SECOND PAGE MOBILE - START ***********************/}
            {/********************************************/}


            <div className='h-screen py-6 flex bg-[#fff9e192] w-full'>

                <div className='flex flex-col justify-center w-full gap-10' >

                    <div className='bg-[#16341fec] text-[#fff9e1] py-4  w-48 rounded-r-xl bg-[#fff9e1] drop-shadow-2xl '>
                        <div className='' >
                            <h1 className='text-2xl font-bold'>6989</h1>
                            <h1 className='text-lg font-light' >Farmers Markets</h1>
                        </div>
                    </div>


                    <div className=' flex flex-row justify-between'>                
                        <div className=' self-center pl-3'>
                            {/* <h1>This is what it is</h1> */}
                        </div>

                        <div className='bg-[#16341fec] text-[#fff9e1] py-4  w-48 rounded-l-xl bg-[#fff9e1] self-end drop-shadow-2xl' >
                            <h1 className='text-2xl font-bold'>1217</h1>
                            <h1 className='text-lg font-light' >Agricultural Co-ops</h1>
                        </div>
                    </div>

                    <div className='bg-[#16341fec] text-[#fff9e1] py-4  w-48 rounded-r-xl bg-[#fff9e1] drop-shadow-2xl '>
                        <div className='' >
                            <h1 className='text-2xl font-bold'>994</h1>
                            <h1 className='text-lg font-light' >CSAs</h1>
                        </div>
                    </div>

                    <div className='bg-[#16341fec] text-[#fff9e1] py-4  w-48 rounded-l-xl bg-[#fff9e1] self-end drop-shadow-2xl '>
                        <div className='' >
                            <h1 className='text-2xl font-bold'>1710</h1>
                            <h1 className='text-lg font-light' >On-Farm Markets</h1>
                        </div>
                    </div>

                    <div className='bg-[#16341fec] text-[#fff9e1] py-4  w-48 rounded-r-xl bg-[#fff9e1] drop-shadow-2xl '>
                        <div className='' >
                            <h1 className='text-2xl font-bold'>230</h1>
                            <h1 className='text-lg font-light' >Food Hubs</h1>
                        </div>
                    </div>

                </div>    

            </div>

            {/********************************************/}
            {/********************* SECOND PAGE MOBILE - END ***********************/}
            {/********************************************/}

        </div>

    </div>

    {/********************************************/}
    {/********************************************/}
    {/********************* MOBILE VERSION END ***********************/}
    {/********************************************/}
    {/********************************************/}




    {/********************************************/}
    {/********************************************/}
    {/********************* DESKTOP VERSION START ***********************/}
    {/********************************************/}
    {/********************************************/}

    <div className='lg:visible invisible max-sm:hidden items-center justify-center ' >

        {/********************************************/}
        {/********************* FIRST PAGE DESKTOP - START ***********************/}
        {/********************************************/}
    <div className='' >

        <div className='w-screen h-full flex flex-col max-sm:justify-start justify-start '>

            <div className=' w-screen h-full max-sm:pb-10 flex flex-row bg-slate-50'>

                    {/* ----------------------- SIDEBAR MENU - START  ----------------------- */}

                    <div className='sticky top-0 border-r-2 h-screen bg-white border-slate-600 flex flex-col justify-between pt-5 text-lg font-light'>

                        <div>
                            <div className=' pb-10'>
                                <h1 className='max-sm:text-2xl max-sm:font-light text-4xl font-semibold text-slate-700 align-middle' >AsirisOS</h1>
                            </div>

                            <Link to='/asiris-v2-demo-family' className='bg-slate-100 drop-shadow-lg py-1 pl-4 pr-16 flex justify-start'  > 
                                    <h1 className=''>Family</h1>
                            </Link>

                            <Link to='/asiris-v2-demo-assets' className='  py-1 pl-4 pr-16 flex justify-start' > 
                                <h1 className=''>Assets</h1>
                            </Link>

                            <Link to='/asiris-v2-demo-community' className='  py-1 pl-4 pr-16 flex justify-start' > 
                                <h1>Community</h1>
                            </Link>
                        </div>

                        <div className='pb-5'>
                            <Link to='/asiris-v2-demo-settings' className='  py-1 pl-4 pr-16 flex justify-start' > 
                                <h1>Settings</h1>
                            </Link>

                            <Link to='/asiris-v2-demo-payments' className='  py-1 pl-4 pr-16 flex justify-start' > 
                                <h1>Payments</h1>
                            </Link>

                            <Link to='/asiris-v2-sign-out' className='  py-1 pl-4 pr-16 flex justify-start' > 
                                <h1>Sign-out</h1>
                            </Link>
                        </div>

                    </div>

                    {/* ----------------------- SIDEBAR MENU - END  ----------------------- */}


                    
                    <div className=' w-full h-full flex flex-col'>

                        {/* ----------------------- PROFILE - START  ----------------------- */}
                        <div className='flex flex-row justify-center bg-slate-50 gap-16 py-10 '>

                            <div id="item1" className=' w-2/3'>
                                <div className='text-[#16341fec] bg-[#ffffff] drop-shadow-xl rounded-lg flex'>

                                    <div className='w-52'>
                                        <img className='rounded-l-lg' src='https://t4.ftcdn.net/jpg/02/24/86/95/360_F_224869519_aRaeLneqALfPNBzg0xxMZXghtvBXkfIA.jpg' alt="" />                                
                                    </div>

                                    <div className='flex flex-col w-96 px-5'>
                                        <div className=' pt-2 flex-row text-start '>
                                            <h1 className='text-2xl font-normal'>John</h1>
                                            <h1 className='text-sm font-semibold' >229 Bebee Rd, Round Rock, TX 78640, USA </h1>
                                        </div>
                                        <hr/>
                                        <div className='flex pb-3 rounded-b-lg'>
                                            <h1 className=' pt-2 text-md italic font-thin'>"When our talents combine, magic happens"</h1>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        
                        </div>
                        {/* ----------------------- PROFILE - END  ----------------------- */}



                        {/* ----------------------- TOPIC SELECTION - START  ----------------------- */}

                        <div className='h-full flex flex-col items-center justify-start py-5'>

                            {/* *********************** TAB SELECTIONS *********************** */}

                            <div className='mb-5  border-t-0 w-fit rounded-xl drop-shadow-lg'>

                                <ul className="flex felx-row justify-left cursor-pointer">
                                    <div onClick={() => toggleTab(1)} className={`${selection === 1 ? 'bg-[#161a7b] text-white  pb-3  w-40 rounded-l-lg' : 'bg-[#c3ceea] text-[#11627]  pb-3  w-40 rounded-l-lg'}`}>
                                        <div className='pt-3' >
                                            <h1 className='text-xl font-bold'>Tasks</h1>
                                            <h1 className='text-md font-light' ></h1>
                                        </div>
                                    </div>

                                    <div onClick={() => toggleTab(2)} className={`${selection === 2 ? 'bg-[#161a7b] text-white  pb-3  w-40' : 'bg-[#c3ceea] text-[#11627] pb-3  w-40 '}`}>
                                        <div className='pt-3' >
                                            <h1 className='text-xl font-bold'>Health</h1>
                                            <h1 className='text-md font-light' ></h1>
                                        </div>
                                    </div>

                                    <div onClick={() => toggleTab(3)} className={`${selection === 3 ? 'bg-[#161a7b] text-white  pb-3  w-40' : 'bg-[#c3ceea] text-[#11627] pb-3  w-40 '}`}>
                                        <div className='pt-3' >
                                            <h1 className='text-xl font-bold'>Fitness</h1>
                                            <h1 className='text-md font-light' ></h1>
                                        </div>
                                    </div>

                                    <div onClick={() => toggleTab(4)} className={`${selection === 4 ? 'bg-[#161a7b] text-white  pb-3  w-40' : 'bg-[#c3ceea] text-[#11627] pb-3  w-40 '}`}>
                                        <div className='pt-3' >
                                            <h1 className='text-xl font-bold'>Goals</h1>
                                            <h1 className='text-md font-light' ></h1>
                                        </div>
                                    </div>

                                    <div onClick={() => toggleTab(5)} className={`${selection === 5 ? 'bg-[#161a7b] text-white  pb-3 rounded-r-lg   w-40' : 'bg-[#c3ceea] rounded-r-lg text-[#11627] pb-3   w-40 '}`}>
                                        <div className='pt-3' >
                                            <h1 className='text-xl font-bold'>Career</h1>
                                            <h1 className='text-md font-light' ></h1>
                                        </div>
                                    </div>


                                </ul>
                            </div>


                            {/* *********************** TAB SELECTIONS *********************** */}

                            {selection === 1 ? (
                            <>
                            <div className='px-5 rounded-2xl w-2/3 py-4 ' >
                                <div className='bg-white drop-shadow-xl rounded-lg text-sm flex flex-col gap-3 py-2'>

                                    <div className='flex justify-between px-3 font-medium'>
                                        <h1>Call plumber about a leak under the kitchen think</h1>

                                        <div className='flex gap-3'>
                                            <h1 className='font-light'>High Priority</h1>
                                            <div className='w-3 h-3 self-center bg-red-400'></div>
                                        </div>

                                        <div className=' font-light'>
                                            <h1>01/28/2024</h1>
                                        </div>
                                    </div>
                                    <hr/>


                                    <div className='flex justify-between px-3 font-medium'>
                                        <h1>Pick out new furniture and decor for guest bedroom</h1>

                                        <div className='flex gap-3'>
                                            <h1 className='font-light'>Low Priority</h1>                                            
                                            <div className='w-3 h-3 self-center bg-green-400'></div>
                                        </div>

                                        <div className=' font-light'>
                                            <h1>01/28/2024</h1>
                                        </div>                                    
                                    </div>
                                    <hr/>

                                    <div className='flex justify-between px-3 font-medium'>
                                        <h1>Take Alex to the doctor for the yearly physical test</h1>

                                        <div className='flex gap-3'>
                                            <h1 className='font-light'>Medium Priority</h1>
                                            <div className='w-3 h-3 self-center bg-yellow-400'></div>
                                        </div>

                                        <div className=' font-light'>
                                            <h1>01/28/2024</h1>
                                        </div>                                    
                                    </div>                                
                                </div>                            
                            </div>
                            </>
                            ) : (<></>)}



                            {selection === 2 ? (
                            <>

                            {/* ----------------------- HEALTH DASHBOARD - START  ----------------------- */}

                            <div className='flex flex-col justify-around w-2/3 bg-white drop-shadow-lg rounded-lg'>

                                {/* ----------------------- HEALTH DATA SECTION - START  ----------------------- */}

                                <div className='flex flex-col rounded-lg py-4 px-3'>

                                    {/* ----------------------- DATA VARIABLE #1 DISPLAY SECTION - START  ----------------------- */}

                                    <div>
                                        {/* ----------------------- DATA VARIABLE TITLE  ----------------------- */}                                    
                                        <div className=' text-left'>
                                            <h1 className='text-xl font-normal'>Blood Pressure</h1>
                                        </div> 


                                        <div className='flex justify-around py-4'>

                                            {/* ----------------------- LATEST DATA  ----------------------- */}

                                            <div className='flex justify-center  gap-5 w-1/3  '>                                            
                                                <div>
                                                    <div className=''>
                                                        <h1 className='text-4xl text-slate-600' >120</h1>
                                                    </div>
                                                    <div>
                                                        <h1 className='text-lg font-light'>Systolic</h1>
                                                    </div>
                                                </div>                                                             
                                                <div>
                                                    <div className=''>
                                                        <h1 className='text-4xl text-yellow-500' >80</h1>
                                                    </div>
                                                    <div>
                                                        <h1 className='text-lg font-light'>Diastolic</h1>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* ----------------------- HISTORICAL GRAPH DATA  ----------------------- */}

                                            <div className='w-2/3 '>
                                                <h1>Graph of the Data</h1>
                                            </div>

                                        </div>   
                                    </div>
                                    <hr/>
                                    {/* ----------------------- DATA VARIABLE DISPLAY SECTION - END  ----------------------- */}


                                    {/* ----------------------- DATA VARIABLE #2 DISPLAY SECTION - START  ----------------------- */}
                                    <div>
                                        <div className=' text-left pt-4'>
                                            <h1 className='text-xl font-normal'>Resting Pulse</h1>
                                        </div> 

                                        <div className='flex justify-around align-middle py-2'>

                                            {/* ----------------------- LATEST DATA  ----------------------- */}

                                            <div className='flex justify-center align-middle w-1/3  items-center '>                                                
                                                    <div className=''>
                                                        <h1 className='text-4xl text-slate-600' >72</h1>
                                                        <h1 className='text-xs font-light text-slate-600' >01/23/2024</h1>
                                                    </div>                                                
                                            </div>

                                            {/* ----------------------- HISTORICAL GRAPH DATA  ----------------------- */}

                                            <div className='w-full'>
                                                <DataChart chartData={pulseData}/>
                                            </div>

                                        </div>
                                    </div>   
                                    <hr/>
                                    {/* ----------------------- DATA VARIABLE #2 DISPLAY SECTION - END  ----------------------- */}


                                    {/* ----------------------- DATA VARIABLE #3 DISPLAY SECTION - START  ----------------------- */}
                                    <div>

                                        <div className=' text-left pt-4'>
                                            <h1 className='text-xl font-normal'>Weight</h1>
                                        </div> 

                                        <div className='flex justify-around py-4'>

                                            {/* ----------------------- LATEST DATA  ----------------------- */}

                                            <div className='flex justify-center  gap-5 w-1/3  '>
                                                <div>
                                                    <div className=''>
                                                        <h1 className='text-4xl text-slate-600' >168</h1>
                                                    </div>
                                                    <div>

                                                    </div>
                                                </div>                                                             
                                            </div>

                                            {/* ----------------------- HISTORICAL GRAPH DATA  ----------------------- */}

                                            <div className='w-2/3 '>
                                                <h1>Graph of the Data</h1>
                                            </div>

                                        </div>
                                    </div>   
                                    {/* ----------------------- DATA VARIABLE #3 DISPLAY SECTION - END  ----------------------- */}                                    

                                </div>                             
                                {/* ----------------------- HEALTH DATA SECTION - END  ----------------------- */}

                            </div>
                            {/* ----------------------- HEALTH DASHBOARD - END  ----------------------- */}

                            </>
                            ) : (<></>)}


                            {selection === 3 ? (
                            <>
                            
                                <div className='flex flex-col justify-around w-2/3 bg-white drop-shadow-lg rounded-lg my-3 py-2 '>
                                    <h1 className='text-2xl font-light  '>Cardio</h1>
                                    {/* ----------------------- DATA VARIABLE #2 DISPLAY SECTION - START  ----------------------- */}
                                    <div>
                                        <div className=' text-left pt-4'>
                                            <h1 className='text-xl px-5 ' >VO2 Max</h1>
                                            <h1 className='text-sm font-light px-5'>5 rep max</h1>
                                        </div> 

                                        <div className='flex justify-around align-middle py-2'>

                                            {/* ----------------------- LATEST DATA  ----------------------- */}

                                            {/* <div className='flex justify-center align-middle w-1/3  items-center '>                                                
                                                    <div className=''>
                                                        <h1 className='text-4xl text-slate-600' >72</h1>
                                                        <h1 className='text-xs font-light text-slate-600' >01/23/2024</h1>
                                                    </div>                                                
                                            </div> */}

                                            {/* ----------------------- HISTORICAL GRAPH DATA  ----------------------- */}

                                            <div className='w-full'>
                                                <DataChart chartData={benchData}/>
                                            </div>

                                        </div>
                                    </div>   
                                    {/* ----------------------- DATA VARIABLE #2 DISPLAY SECTION - END  ----------------------- */}                                                               
                                </div>

                                <div className='flex flex-col justify-around w-2/3 bg-white drop-shadow-lg rounded-lg my-3 py-2'>
                                    <h1 className='text-2xl font-light  '>Strength</h1>
                                    
                                    {/* ----------------------- DATA VARIABLE #1 DISPLAY SECTION - START  ----------------------- */}
                                    <div>
                                        <div className=' text-left pt-4'>
                                            <h1 className='text-xl px-5 ' >Bench Press</h1>
                                            <h1 className='text-sm font-light px-5'>5 rep max</h1>
                                        </div> 

                                        <div className='flex justify-around align-middle py-2'>

                                            {/* ----------------------- LATEST DATA  ----------------------- */}

                                            {/* <div className='flex justify-center align-middle w-1/3  items-center '>                                                
                                                    <div className=''>
                                                        <h1 className='text-4xl text-slate-600' >72</h1>
                                                        <h1 className='text-xs font-light text-slate-600' >01/23/2024</h1>
                                                    </div>                                                
                                            </div> */}

                                            {/* ----------------------- HISTORICAL GRAPH DATA  ----------------------- */}

                                            <div className='w-full'>
                                                <DataChart chartData={benchData}/>
                                            </div>

                                        </div>
                                    </div>   
                                    <hr/>
                                    {/* ----------------------- DATA VARIABLE #1 DISPLAY SECTION - END  ----------------------- */}


                                    {/* ----------------------- DATA VARIABLE #2 DISPLAY SECTION - START  ----------------------- */}
                                    <div>
                                        <div className=' text-left pt-4'>
                                            <h1 className='text-xl px-5 ' >Squat</h1>
                                            <h1 className='text-sm font-light px-5'>5 rep max</h1>
                                        </div> 

                                        <div className='flex justify-around align-middle py-2'>

                                            {/* ----------------------- LATEST DATA  ----------------------- */}

                                            {/* <div className='flex justify-center align-middle w-1/3  items-center '>                                                
                                                    <div className=''>
                                                        <h1 className='text-4xl text-slate-600' >72</h1>
                                                        <h1 className='text-xs font-light text-slate-600' >01/23/2024</h1>
                                                    </div>                                                
                                            </div> */}

                                            {/* ----------------------- HISTORICAL GRAPH DATA  ----------------------- */}

                                            <div className='w-full'>
                                                <DataChart chartData={benchData}/>
                                            </div>

                                        </div>
                                    </div>   
                                    <hr/>
                                    {/* ----------------------- DATA VARIABLE #2 DISPLAY SECTION - END  ----------------------- */}


                                    {/* ----------------------- DATA VARIABLE #3 DISPLAY SECTION - START  ----------------------- */}
                                    <div>
                                        <div className=' text-left pt-4'>
                                            <h1 className='text-xl px-5 ' >Squat</h1>
                                            <h1 className='text-sm font-light px-5'>5 rep max</h1>
                                        </div> 

                                        <div className='flex justify-around align-middle py-2'>

                                            {/* ----------------------- LATEST DATA  ----------------------- */}

                                            {/* <div className='flex justify-center align-middle w-1/3  items-center '>                                                
                                                    <div className=''>
                                                        <h1 className='text-4xl text-slate-600' >72</h1>
                                                        <h1 className='text-xs font-light text-slate-600' >01/23/2024</h1>
                                                    </div>                                                
                                            </div> */}

                                            {/* ----------------------- HISTORICAL GRAPH DATA  ----------------------- */}

                                            <div className='w-full'>
                                                <DataChart chartData={benchData}/>
                                            </div>

                                        </div>
                                    </div>   
                                    {/* ----------------------- DATA VARIABLE #3 DISPLAY SECTION - END  ----------------------- */}                                    
                            
                                </div>
                            

                            </>
                            ) : (<></>)}

                            {selection === 4 ? (
                            <>

                            <div className='h-full px-5 rounded-2xl w-2/3 py-5' >
                                <div className='bg-white drop-shadow-xl rounded-lg text-sm flex flex-col py-5'>
                                    <div className='flex justify-start px-3 py-1'>
                                        <h1 className='text-lg'>Morning walks 30 days in a row</h1>
                                    </div>

                                    <div>
                                        {/* ------------------------------ STREAK GOAL COMPLETION - START ------------------------------ */}
                                        <div className='flex flex-row justify-around font-light text-xs  px-8 pt-4 pb-2'>

                                            <div className='flex flex-col justify-center'>

                                                <div className='pb-2'>
                                                    <h1>1</h1>
                                                </div>

                                                <div className='w-7  '>
                                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3b/Eo_circle_green_checkmark.svg/2048px-Eo_circle_green_checkmark.svg.png" alt="" />
                                                </div>

                                            </div>

                                            <div className=''>

                                                <div className='pb-2'>
                                                    <h1>2</h1>
                                                </div>

                                                <div className='w-7'>
                                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3b/Eo_circle_green_checkmark.svg/2048px-Eo_circle_green_checkmark.svg.png" alt="" />
                                                </div>

                                            </div>

                                            <div className=''>

                                                <div className='pb-2'>
                                                    <h1>3</h1>
                                                </div>

                                                <div className='w-7'>
                                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3b/Eo_circle_green_checkmark.svg/2048px-Eo_circle_green_checkmark.svg.png" alt="" />
                                                </div>
                                            
                                            </div>

                                            <div className=''>

                                                <div className='pb-2'>
                                                    <h1>4</h1>
                                                </div>

                                                <div className='w-7'>
                                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3b/Eo_circle_green_checkmark.svg/2048px-Eo_circle_green_checkmark.svg.png" alt="" />
                                                </div>
                                                
                                            </div>

                                            <div className=''>

                                                <div className='pb-2'>
                                                    <h1>5</h1>
                                                </div>

                                                <div className='w-7'>
                                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3b/Eo_circle_green_checkmark.svg/2048px-Eo_circle_green_checkmark.svg.png" alt="" />
                                                </div>

                                            </div>

                                            <div className=''>

                                                <div className='pb-2'>
                                                    <h1>6</h1>
                                                </div>

                                                <div className='w-7'>
                                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3b/Eo_circle_green_checkmark.svg/2048px-Eo_circle_green_checkmark.svg.png" alt="" />
                                                </div>
                                                
                                            </div>

                                            <div className='flex flex-col justify-center'>

                                                <div className='pb-2'>
                                                    <h1>7</h1>
                                                </div>

                                                <div className='w-7'>
                                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3b/Eo_circle_green_checkmark.svg/2048px-Eo_circle_green_checkmark.svg.png" alt="" />
                                                </div>
                                                
                                            </div>

                                        </div>

                                        <div className='flex flex-row justify-around font-light text-xs px-8 pt-4 pb-2'>

                                            <div className='flex flex-col justify-center'>

                                                <div className='pb-2'>
                                                    <h1>8</h1>
                                                </div>

                                                <div className='w-7  '>
                                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3b/Eo_circle_green_checkmark.svg/2048px-Eo_circle_green_checkmark.svg.png" alt="" />
                                                </div>

                                            </div>

                                            <div className=''>

                                                <div className='pb-2'>
                                                    <h1>9</h1>
                                                </div>

                                                <div className='w-7'>
                                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3b/Eo_circle_green_checkmark.svg/2048px-Eo_circle_green_checkmark.svg.png" alt="" />
                                                </div>

                                            </div>

                                            <div className=''>

                                                <div className='pb-2'>
                                                    <h1>10</h1>
                                                </div>

                                                <div className='w-7'>
                                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3b/Eo_circle_green_checkmark.svg/2048px-Eo_circle_green_checkmark.svg.png" alt="" />
                                                </div>
                                            
                                            </div>

                                            <div className=''>

                                                <div className='pb-2'>
                                                    <h1>11</h1>
                                                </div>

                                                <div className='w-7'>
                                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3b/Eo_circle_green_checkmark.svg/2048px-Eo_circle_green_checkmark.svg.png" alt="" />
                                                </div>
                                                
                                            </div>

                                            <div className=''>

                                                <div className='pb-2'>
                                                    <h1>12</h1>
                                                </div>
 
                                                <div className='w-7'>
                                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3b/Eo_circle_green_checkmark.svg/2048px-Eo_circle_green_checkmark.svg.png" alt="" />
                                                </div>

                                            </div>

                                            <div className=''>

                                                <div className='pb-2'>
                                                    <h1>13</h1>
                                                </div>

                                                <div className='w-7'>
                                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/Eo_circle_grey_checkmark.svg/2048px-Eo_circle_grey_checkmark.svg.png" alt="" />
                                                </div>
                                                
                                            </div>

                                            <div className='flex flex-col justify-center'>

                                                <div className='pb-2'>
                                                    <h1>14</h1>
                                                </div>

                                                <div className='w-7'>
                                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/Eo_circle_grey_checkmark.svg/2048px-Eo_circle_grey_checkmark.svg.png" alt="" />
                                                </div>
                                                
                                            </div>

                                        </div>      

                                        <div className='flex flex-row justify-around font-light text-xs px-8 pt-4 pb-2'>

                                            <div className='flex flex-col justify-center'>

                                                <div className='pb-2'>
                                                    <h1>15</h1>
                                                </div>

                                                <div className='w-7  '>
                                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/Eo_circle_grey_checkmark.svg/2048px-Eo_circle_grey_checkmark.svg.png" alt="" />
                                                </div>

                                            </div>

                                            <div className=''>

                                                <div className='pb-2'>
                                                    <h1>16</h1>
                                                </div>

                                                <div className='w-7'>
                                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/Eo_circle_grey_checkmark.svg/2048px-Eo_circle_grey_checkmark.svg.png" alt="" />
                                                </div>

                                            </div>

                                            <div className=''>

                                                <div className='pb-2'>
                                                    <h1>17</h1>
                                                </div>

                                                <div className='w-7'>
                                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/Eo_circle_grey_checkmark.svg/2048px-Eo_circle_grey_checkmark.svg.png" alt="" />
                                                </div>
                                            
                                            </div>

                                            <div className=''>

                                                <div className='pb-2'>
                                                    <h1>18</h1>
                                                </div>

                                                <div className='w-7'>
                                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/Eo_circle_grey_checkmark.svg/2048px-Eo_circle_grey_checkmark.svg.png" alt="" />
                                                </div>
                                                
                                            </div>

                                            <div className=''>

                                                <div className='pb-2'>
                                                    <h1>19</h1>
                                                </div>

                                                <div className='w-7'>
                                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/Eo_circle_grey_checkmark.svg/2048px-Eo_circle_grey_checkmark.svg.png" alt="" />
                                                </div>

                                            </div>

                                            <div className=''>

                                                <div className='pb-2'>
                                                    <h1>20</h1>
                                                </div>

                                                <div className='w-7'>
                                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/Eo_circle_grey_checkmark.svg/2048px-Eo_circle_grey_checkmark.svg.png" alt="" />
                                                </div>
                                                
                                            </div>

                                            <div className='flex flex-col justify-center'>

                                                <div className='pb-2'>
                                                    <h1>21</h1>
                                                </div>

                                                <div className='w-7'>
                                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/Eo_circle_grey_checkmark.svg/2048px-Eo_circle_grey_checkmark.svg.png" alt="" />
                                                </div>
                                                
                                            </div>

                                        </div>                                        

                                        {/* ------------------------------ STREAK GOAL COMPLETION - END ------------------------------ */}
                                    </div>
                                </div>
                            </div>


                            <div className='  pt-5 px-5 rounded-2xl w-2/3 ' >
                                <div className='bg-white drop-shadow-xl py-2 rounded-lg text-sm flex flex-col'>
                                    <div className='flex justify-start px-3 py-1'>
                                        <h1 className='text-lg'>Complete Garage Renovation</h1>
                                    </div>
                                    <div className="flex px-8 pb-3" >
                                        <progress className="progress progress-success bg-gray-300 h-2 mr-5 mt-3" value={30} max="100"></progress>
                                        <h1 className="text-xl" >{30}%</h1>
                                    </div>
                                    <div className='flex justify-start px-8'>
                                        <h1 className='font-normal text-lg'>Milestones</h1>
                                    </div>

                                    <div className='flex flex-row px-8 pt-4 pb-2'>
                                        <div className='w-5 '>
                                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3b/Eo_circle_green_checkmark.svg/2048px-Eo_circle_green_checkmark.svg.png" alt="" />
                                        </div>
                                        <div className='pl-4'>
                                            <h1>Move everything out of the garage</h1>
                                        </div>
                                    </div>

                                    <div className='flex flex-row px-8 py-2'>
                                        <div className='w-5 '>
                                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3b/Eo_circle_green_checkmark.svg/2048px-Eo_circle_green_checkmark.svg.png" alt="" />
                                        </div>
                                        <div className='pl-4'>
                                            <h1>Pick a color and buy all the materials</h1>
                                        </div>
                                    </div>

                                    <div className='flex flex-row px-8 py-2'>
                                        <div className='w-5 '>
                                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/Eo_circle_grey_checkmark.svg/2048px-Eo_circle_grey_checkmark.svg.png" alt="" />
                                        </div>
                                        <div className='pl-4'>
                                            <h1>Brush out all the dust and cover up doors and windows</h1>
                                        </div>
                                    </div>

                                    <div className='flex flex-row px-8 py-2'>
                                        <div className='w-5 '>
                                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/Eo_circle_grey_checkmark.svg/2048px-Eo_circle_grey_checkmark.svg.png" alt="" />
                                        </div>
                                        <div className='pl-4'>
                                            <h1>Paint the space</h1>
                                        </div>
                                    </div>

                                    <div className='flex flex-row px-8 py-2'>
                                        <div className='w-5 '>
                                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/Eo_circle_grey_checkmark.svg/2048px-Eo_circle_grey_checkmark.svg.png" alt="" />
                                        </div>
                                        <div className='pl-4'>
                                            <h1>Let the paint dry</h1>
                                        </div>
                                    </div>

                                    <div className='flex flex-row px-8 py-2'>
                                        <div className='w-5 '>
                                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/Eo_circle_grey_checkmark.svg/2048px-Eo_circle_grey_checkmark.svg.png" alt="" />
                                        </div>
                                        <div className='pl-4'>
                                            <h1>Fix missed spots and imperfections</h1>
                                        </div>
                                    </div>

                                    <div className='flex flex-row px-8 py-2'>
                                        <div className='w-5 '>
                                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/Eo_circle_grey_checkmark.svg/2048px-Eo_circle_grey_checkmark.svg.png" alt="" />
                                        </div>
                                        <div className='pl-4'>
                                            <h1>Clean up and move everything back in</h1>
                                        </div>
                                    </div>


                                    <div className='flex justify-start px-8 py-2'>
                                        <h1 className='font-thin text-sm'>Complete by 04/30/2023</h1>
                                    </div>
                                </div>
                            </div>
                            </>
                            ) : (<></>)}


                            {selection === 5 ? (
                            <>
                            <div className='  px-5 rounded-xl' >
                                <h1 className='text-2xl font-light max-w-3xl text-start' >Career</h1>
                                {/* <h1 className='text-lg font-light' >Manage your food supply chain with local hubs</h1> */}
                            </div>
                            </>
                            ) : (<></>)}


                        </div>

                        {/* ----------------------- TOPIC SELECTION - END  ----------------------- */}


                    </div>
            </div>

        </div>

        {/********************************************/}
        {/********************* FIRST PAGE DESKTOP - END ***********************/}
        {/********************************************/}

    </div>
    </div>
    {/********************************************/}
    {/********************************************/}
    {/********************* DESKTOP VERSION END ***********************/}
    {/********************************************/}
    {/********************************************/}
    </>
  )
}

export default FatherProfile