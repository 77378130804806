import Card from '../../components/shared/card/Card'
import React from 'react'
import { Link } from 'react-router-dom'


function Farm() {
  return (
    <div className="flex flex-row justify-around" >
            <Card>
            <div className="p-10">
                <h1 className="mb-10" >Create A Farm</h1>
                <Link className="btn mb-8" to={"/farm-add-form"} >
                Add A Farm
                </Link>
            </div>
            </Card>
            <Card>
            <div className="p-10">
                <h1 className="mb-10" >Join A Farm</h1>
                <Link className="btn mb-8" to={"/farm-list"} >
                Join a Farm
                </Link>
            </div>
            </Card>

            <Card>
            <div className="p-10">
                <h1 className="mb-10" >Search A Farm</h1>
                <Link className="btn mb-8" to={"/farm-search"} >
                Search a Farm
                </Link>
            </div>
            </Card>
    </div>
  )
}

export default Farm