import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useParams, Link, useNavigate } from 'react-router-dom'
import { getAllImages } from 'features/fileUpload/fileUploadSlice'
import {toast} from 'react-toastify'
import Spinner from '../../../components/Spinner'
import InlineEdit from '../../../components/shared/InlineEdit'
import InlineEditDropDown from '../../../components/shared/InlineEditDropDown'
import {AppDispatch, RootState} from "../../../../app/store";
import {Status} from "../../../../types/slice";
import {useTranslation} from "react-i18next";
import ChangeActivityList from '../../Activity/ChangeActivityList'
import {EquipmentModel} from "@asirisos/types";
import ItemPageDeleteButton from 'UI/components/ItemPageDeleteButton'
import DeleteModal from 'UI/components/DeleteModal'


function ShowAllImagesPage() {
    const {t} = useTranslation("equipment");
    const navigate = useNavigate()

    const dispatch = useDispatch<AppDispatch>()
    const {files, status} = useSelector((state: RootState) => state.file)

    useEffect(() => {
        
        dispatch(getAllImages())
    }, [dispatch])


    if (status === Status.Pending) {
        return <Spinner/>
    } else {
        return (
            <>
                {files?.map((file) => (
                    
                    <>
                        <div className=''>
                            <div className='pt-10'>
                                <img src={file.imageUrl} alt="" />
                                <h1>{file.caption}</h1>
                            </div>
                        </div>
                    </>
                ) )}

            </>
        )
                
    }
}
export default ShowAllImagesPage