import React from 'react'
import {Link} from 'react-router-dom'
import {TaskModel} from "@asirisos/types";


type TaskKanBanCardProps = {
    task: TaskModel
}

function TaskKanBanCard(props: {id: string | undefined, title: string, description: string, taskOwner: string, deadline:string }) {
    console.log()
  return (
    <>
        <Link to={`/task/${props.id}`} className=" my-5 mx-5 bg-white   shadow-[2px_1px_15px_2px_rgba(201,201,201,0.47)] rounded-xl drop-shadow-sm pt-3 flex flex-col items-start pl-3 pb-2" >
        <h4 className="text-xl" >{props.title}</h4>
        <h6 className=' text-gray-500 pt-1 ' >{props.description}</h6>
        <div>
        {props.taskOwner}
        </div>
        <div className='absolute bottom-0 right-0 pr-2 pb-2 text-sm text-gray-400' >
        {props.deadline}
        </div>
        <div className='absolute top-0 right-0 pr-2 text-sm pt-4' >
        
        </div>
    </Link>
    </>
  )
}

export default TaskKanBanCard