import axios from "axios"
import {UploadImageModel} from "@asirisos/types";
import {LiveStockWeightData} from "@asirisos/types/data";
import RequestBodyFactory from "../../factories/requestBodyFactory";

const API_URL = '/api/image-upload'


const uploadImage = async (imageData: UploadImageModel) => {
    const body = new RequestBodyFactory(imageData).Build()
    return await axios.post(API_URL + '/upload', imageData, { headers: {'Content-Type': 'multipart/form-data'}})
}

const getAllImages = async () => {
    return await axios.get(API_URL + '/getAllImages')
}

// const getLiveStock = async (liveStockId: string) => {
//     return await axios.get(API_URL + '/' + liveStockId)
// }

// const updateLiveStock = async(liveStockData: LiveStockModel, account: AccountModel) => {
//     const {_id} = liveStockData

//     const body = new RequestBodyFactory(liveStockData).WithAccount(account).Build()
//     return await axios.put(API_URL + '/' + _id, body)
// }

// const deleteLiveStock = async(liveStockId: string[]) => {
//     const ids = liveStockId
//     const config = {
//         data: ids
//     }
//     return await axios.delete(API_URL+'/'+ liveStockId, config)
// }

const fileUploadService = {
    uploadImage,
    getAllImages
    
}

export default fileUploadService