import React from 'react'
import { Link } from 'react-router-dom'

function Fail() {
  return (
    <>

    {/* MOBILE VERSION */}
    <div className='lg:hidden sm:visible' >

        {/********************************************/}
        {/********************* MENU BAR - START ***********************/}
        {/********************************************/}

        <div className='bg-[#16341fec] flex justify-around py-2 drop-shadow-md shadow-[2px_1px_20px_3px_rgba(201,201,201,0.97)] '> 
            <Link to="/">
                <div className='flex flex-row justify-center py-2' >
                    <h1 className='text-md font-light text-white' >AsirisOS</h1>                        
                </div>
            </Link>
            <Link className=" text-slate-700 hover:bg-[#FFB100] hover:shadow-lg rounded-sm px-4 py-1 bg-[#FFC500] flex justify-center " to={"/login"} >
                <h1 className=' text-lg font-normal justify-center align-middle'>Login</h1>
            </Link>
        </div>

        {/********************************************/}
        {/********************* MENU BAR - END ***********************/}
        {/********************************************/}

        <div className='flex flex-col'>
            <div className='w-screen h-screen flex flex-col justify-start align-middle bg-[#ffffff]'>
                <div className='h-screen pb-6 max-sm:pb-10 flex flex-col justify-center items-center'>
                    <h1 className='text-center font-bold text-5xl pt-1 underline text-[#16341fec]  decoration-red-400 animate-bounce'>...Oooops!</h1>
                    <h2 className='text-center font-light text-2xl pt-8 text-slate-600'>Something went wrong</h2>
                </div>
            </div>
        </div>

    </div>




    {/* DESKTOP VERSION */}
    <div className='lg:visible invisible max-sm:hidden items-center justify-center' >

        {/* ----------------------- NAVIGATION BAR  ----------------------- */}

        <div className='flex justify-around py-3 shadow-[2px_1px_20px_3px_rgba(201,201,201,0.67)] bg-[#16341fec]'>    
            <Link to="/">
                <div className='flex justify-center align-middle'>
                    <h1 className='max-sm:text-2xl max-sm:font-light text-4xl font-thin text-white align-middle' >AsirisOS</h1>
                    <h1 className='max-sm:pt-1 max-sm:text-xl max-sm:font-light text-2xl font-thin text-slate-600 pt-1 pl-3' ></h1>
                </div>
            </Link>
            
            <Link className=" text-slate-700 hover:bg-[#FFB100] hover:shadow-lg rounded-sm px-4 py-1 bg-[#FFC500] flex justify-center " to={"/login"} >
                <h1 className=' text-lg font-normal justify-center align-middle'>Login</h1>
            </Link>
        </div>

        {/* ----------------------- NAVIGATION BAR  ----------------------- */}

        <div className='w-screen h-screen flex flex-col max-sm:justify-start justify-start bg-[#ffffff]'>
            <div className='h-screen pb-6 max-sm:pb-10 flex flex-col justify-center items-center'>
                <h1 className='text-center font-bold text-7xl pt-1 underline text-indigo-400 decoration-red-400 animate-bounce'>...Oooops!</h1>
                <h2 className='text-center font-light text-3xl pt-8 text-slate-600' >Something went wrong</h2>
            </div>
        </div>

    </div>
    </>
  )
}

export default Fail