import React from 'react'
import { useLocation, Navigate, Outlet } from 'react-router'
import { useAuthStatus } from '../../hooks/useAuthStatus'
import { useSelector } from 'react-redux'
import Spinner from './Spinner'
import {RootState} from "../../app/store";
import { access } from 'fs'

type PrivateRouteProps = {
    allowedRoles: Array<string>
}

const PrivateRoute = (props: PrivateRouteProps) => {

    const {loggedIn, checkingStatus} = useAuthStatus()
    const location = useLocation()

    const { user } = useSelector((state : RootState) => state.auth )
    const { account } = useSelector((state : RootState) => state.account )
    console.log('account from Private Route', account)

    if (checkingStatus) {
        return <Spinner/>
    }

    return (
        // user?.roles?.find(role => props.allowedRoles?.includes(role))
        account?.access?.find(acces => props.allowedRoles?.includes(acces))
            ? <Outlet/>
            : user?._id
                ? <Navigate to="/unauthorized" state={{from: location}} replace />
                : <Navigate to="/login" state={{from: location}} replace />
    )

    // return ( 
    //     loggedIn && user.roles? user.roles?.find(role => allowedRoles?.includes(role))
    //         ? <Outlet/>
    //         : loggedIn && user  
    //             ? <Navigate to="/unauthorized" state={{from: location}} replace />
    //             : <Navigate to="/login" state={{from: location}} replace />
        
    //     )

//     return loggedIn ? <Outlet/> : <Navigate to='/login' state={{from: location}} replace />
// }
    }
export default PrivateRoute