import React, {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import { getFarmAccounts } from 'features/accounts/accountSlice'
import Spinner from '../../components/Spinner'
import {DataGrid, GridColumns} from '@mui/x-data-grid';
import {Link} from 'react-router-dom'
import {AppDispatch, RootState} from "../../../app/store";
import {Status} from "../../../types/slice";
import {useTranslation} from "react-i18next";

const columns = (t: any): GridColumns => [
    {field: '_id', headerName: t("translation:id"), width: 100},
    {
        field: 'img',
        headerName: t("translation:Name"),
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        width: 160,
        renderCell: (params) => {
            return (

                <div className='flex flex-row items-center'>
                    <img className='bg-slate-300 rounded-xl  w-9 h-9 mr-4'
                         src="https://cdn-icons-png.flaticon.com/512/843/843349.png" alt=""/>
                    <div className='text-indigo'>
                        {params.row.name}
                    </div>
                </div>

            )
        }
    },
    {
        field: 'job_title',
        headerName: t("translation:Job_Title"),
        type: 'string',
        width: 120,
    },
    {
        field: 'qualifications',
        headerName: t("translation:Qualifications"),
        type: 'string',
        width: 120,
    },
    {field: 'email', headerName: t("translation:Email"), width: 160},
    {field: 'phone', headerName: t("translation:Phone"), width: 160},
    {
        field: 'date_of_hire',
        headerName: t("translation:Date_of_Hire"),
        width: 150,
        type: 'string',

        renderCell: (params) => {
            return (
                <div className={``}>
                    {params.row.createdAt.slice(0, 10)}
                </div>

            )
        }
    },
    {
        field: 'status',
        headerName: t("translation:status"),
        description: 'test render return',
        width: 130,
        renderCell: (params) => {
            return (

                <div
                    className={` text-center justify-self-center bg-opacity-40 rounded-lg p-1 m-1
                        ${params.row.accountType === 'active' ? 'bg-green-400' : 'bg-green-400'} 
                        ${params.row.accountType === 'Unclaimed' ? 'bg-red-400' : 'bg-green-400'}`}>
                    {params.row.accountType}

                </div>

            )
        }
    },
    {
        field: 'action',
        headerName: t("translation:action"),
        width: 200,
        renderCell: (params) => {
            return (
                <div className='flex flex-row px-2 pl-4 justify-center items-center gap-2'>
                    <Link to={`/employee/${params.row._id}`}
                          className="border-2 rounded-lg text-blue-500 px-2">View</Link>
                </div>
            )
        }
    }
];

function EmployeeTable() {
    const {accounts, status} = useSelector((state: RootState) => state.account)
    const {t} = useTranslation(["employee", "translation"])
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(getFarmAccounts())
    }, [dispatch])

    console.log('Employee Table - Accounts/Farm', accounts)

    if (status === Status.Pending) {
        return <Spinner/>
    }

    return (
        <>
            <div style={{height: 600, width: 'full'}}>
                <DataGrid
                    getRowId={(row) => row._id}
                    rows={accounts}
                    columns={columns(t)}
                    pageSize={10}
                    rowsPerPageOptions={[5]}
                    checkboxSelection
                />
            </div>
        </>
    )
}

export default EmployeeTable