import axios from "axios";
import {AccountModel, InfrastructureModel} from "@asirisos/types";
import RequestBodyFactory from "../../factories/requestBodyFactory";

const API_URL = '/api/infrastructures'

const addInfrastructure = async (infrastructureData: InfrastructureModel, account: AccountModel) => {
    const body = new RequestBodyFactory(infrastructureData).WithAccount(account).Build()
    return await axios.post(API_URL + '/add', body)
}

const getInfrastructures = async (account: AccountModel) => {
    return await axios.get(API_URL + '/all/' + account.farm._id)
}

const getInfrastructure = async (infrastructureId: string) => {
    return await axios.get(API_URL + '/' + infrastructureId)
}

const updateInfrastructure = async (infrastructureData: InfrastructureModel, account: AccountModel) => {
    const {_id} = infrastructureData
    const body = new RequestBodyFactory(infrastructureData).WithAccount(account).Build()
    return await axios.put(API_URL + '/update/' + _id, body)

}

const deleteInfrastructure = async (infrastructureId: string[], account: AccountModel) => {
    const ids = infrastructureId
    const config = {
        data: {ids, account}
    }

    return await axios.delete(API_URL + '/' + infrastructureId, config)
}

const infrastructureService = {
    addInfrastructure,
    getInfrastructures,
    getInfrastructure,
    updateInfrastructure,
    deleteInfrastructure
}

export default infrastructureService