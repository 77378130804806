import LargeTextInput from '../../components/shared/LargeTextInput'
import FormCard from 'UI/components/shared/FormCard'
import React, {useState, useEffect} from 'react'
import { getConnectionIdComments } from 'features/comments/commentSlice'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { addComment } from 'features/comments/commentSlice'
import {toast} from 'react-toastify'
import { CommentModel } from '@asirisos/types'
import {AppDispatch, RootState} from "../../../app/store";
import {useTranslation} from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ConnectionsDisplay from './ConnectionDisplay'


function SingleComment(props: {comment : CommentModel }) {
    const {t} = useTranslation('comment');

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////    ON CHANGE & ON SUBMIT      ///////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////






  return (
    <>
                <>
                <div className="bg-white border-2 border-b-0 hover:shadow-[2px_1px_15px_2px_rgba(201,201,201,0.47)] border-slate-200 drop-shadow-sm pt-3 flex flex-col items-start pl-3 pb-2"  >

                        <h4 className="text-xl" >{props.comment.commentText}</h4>
                        <div>
                            <h1>Commenter</h1>
                            <h1>{props.comment.commenterAccountObject?.name}</h1>
                        </div>
                        <div>
                            <h1>Commented Date</h1>
                            {props.comment.createdAt?.substr(0, 10)}
                        </div>
                        <div>
                            <h1>Commented Time</h1>
                            {props.comment.createdAt?.substr(11, 5)}
                        </div>

                        {props.comment.commentConnectionAdded === 'true' ? (
                            <>
                            <h1>Comment Connection</h1>
                            <ConnectionsDisplay comment={props.comment} />
                            </>
                        ) : (<></>) }

                        {props.comment.commentEmployeeConnectionAdded === 'true' ? (
                            <>
                            <h1>Comment Employee Connection</h1>
                            {props.comment.commentEmployeeConnectionObject?.name}
                            </>
                        ) : (<></>) }
                        <div className='absolute top-0 right-0 pr-2 text-sm pt-4' >
                        </div>
                    {/* </Link> */}
                    </div>
                </>
    </>
  )
}

export default SingleComment